import { createContext, useContext, useState } from 'react';

export type INavDrawerContext = {
  isOpenNavDrawer: boolean;
  closeNavDrawer: () => void;
  toggleNavDrawer: () => void;
};

const NavDrawerContext = createContext({});

export function NavDrawerProvider({ children }) {
  const [isOpenNavDrawer, setIsOpenNavDrawer] = useState<boolean>(false);

  function closeNavDrawer() {
    setIsOpenNavDrawer(false);
  }

  function toggleNavDrawer() {
    setIsOpenNavDrawer((prev) => !prev);
  }

  return (
    <NavDrawerContext.Provider
      value={{ isOpenNavDrawer, closeNavDrawer, toggleNavDrawer }}
    >
      {children}
    </NavDrawerContext.Provider>
  );
}

export function useNavDrawer() {
  return useContext(NavDrawerContext) as INavDrawerContext;
}
