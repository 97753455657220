import { css, cx } from '@emotion/css';
import { ClassNamesArg } from '@emotion/react';
import { ReactNode } from 'react';

import { useSideMenuGroup } from '../../../../hooks';
interface SideMenuGroupTitleProps {
  children?: ReactNode;
  className?: ClassNamesArg;
}

export default function SideMenuGroupTitle({
  children,
  className,
}: SideMenuGroupTitleProps) {
  const { isActive } = useSideMenuGroup();

  return (
    <div
      className={cx(
        styled.groupHeader,
        { [styled.activeLinkText]: isActive },
        className,
      )}
    >
      {children}
    </div>
  );
}
const styled = {
  groupHeader: css`
    user-select: none;
    color: var(--text-secondary);
    display: flex;
    align-items: center;
  `,
  activeLinkText: css`
    color: var(--link-active-text);
    font-weight: 700;
  `,
};
