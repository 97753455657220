import { css, cx } from '@emotion/css';
import { useRef } from 'react';

import { SkeletonWrapper } from '..';
import { CopyButton } from '../CopyButton';

export interface ICopyFieldProps {
  title: string;
  value: string;
  borderStyle: 'solid' | 'dashed';
  className?: string;
  valueClassName?: string;
  isLoading?: boolean;
  skeletonClassName?: string;
  copyLabel: string;
  onClick?: () => void;
}

const styled = {
  root: css`
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    .card {
      display: flex;
      flex: 1;
      align-items: center;
      padding: 0.625rem 0rem 0.625rem 1.25rem;
      .card-text {
        width: calc(100% - 3.375rem);
        .card-title {
          margin-bottom: 0.25rem;
          color: var(--text-secondary);
          font-size: var(--xs-font-size);
          font-size: clamp(
            var(--xs-font-size),
            0.7142857142857143rem + 0.17857142857142858vw,
            var(--sm-font-size)
          );
        }
        .value {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 3.375rem;
      }
    }
  `,
  solid: css`
    border: 1px solid var(--neutral-200);
  `,
  dashed: css`
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23D5E0E8' stroke-width='4' stroke-dasharray='6%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  `,
  skeleton: css`
    width: 100%;
    height: auto;
    border-radius: 10px;
  `,
};

function CopyField({
  title,
  value,
  borderStyle = 'solid',
  className,
  valueClassName,
  isLoading,
  skeletonClassName,
  copyLabel,
  onClick,
}: ICopyFieldProps) {
  const ref = useRef<HTMLInputElement>();

  function handleOnClick() {
    ref.current?.click();
  }

  return (
    <div className={cx(styled.root, className)} onClick={handleOnClick}>
      <div
        className={cx('card', {
          [styled.solid]: borderStyle === 'solid',
          [styled.dashed]: borderStyle === 'dashed',
        })}
      >
        <div className="card-text">
          <div className="card-title">
            <span>{title}</span>
          </div>
          <SkeletonWrapper
            className={cx(styled.skeleton, skeletonClassName)}
            loading={isLoading || !value}
          >
            <div className={cx('value', valueClassName)}>
              <span>{value}</span>
            </div>
          </SkeletonWrapper>
        </div>
        <div className="icon-wrapper">
          <CopyButton
            ref={ref}
            type="icon"
            data={value}
            label={copyLabel}
            size={24}
            onClick={onClick}
          />
        </div>
      </div>
    </div>
  );
}

export { CopyField };
export default CopyField;
