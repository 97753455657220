export enum KycAbadStatus {
  // UNVERIF
  UNVERIF_KYC_ABAD = 'UNVERIF_KYC_ABAD',
  UNVERIF_ABAD = 'UNVERIF_ABAD',

  // VERIFYING
  VERIFYING_KYC_ABAD = 'VERIFYING_KYC_ABAD',
  VERIFYING_ABAD = 'VERIFYING_ABAD',

  // REJECTED
  REJECTED_KYC = 'REJECTED_KYC',
  REJECTED_COMPLIANCE_KYC = 'REJECTED_COMPLIANCE_KYC',
  REJECTED_ABAD = 'REJECTED_ABAD',
  REJECTED_KYC_EMPTY_ABAD = 'REJECTED_KYC_EMPTY_ABAD',

  // APPROVE KYC AND ABAD
  APPROVE_KYC_ABAD = 'APPROVE_KYC_ABAD',

  // BLACKLIST
  BLACKLIST_SPAM_PROHIBITED = 'BLACKLIST_SPAM_PROHIBITED',
  BLACKLIST_OTHER = 'BLACKLIST_OTHER',
  BLACKLIST_REMOVED = 'BLACKLIST_REMOVED',
}

export interface FormChooseIdType {
  idType: string;
}

export interface FormPersonalId {
  idType: string;
  idNumber: string;
  idCountry: string;
  fullname: string;
  countryOfBirthIsoCode: string;
  dateOfBirth: string | Date;
  gender: string;
  nationality: string;
  idExpirationDate: string | Date;
  isLifetimeId: boolean;
  occupation: string;
  pep: string;
}

export interface FormPersonalAddress {
  idType: string;
  typeForm: string;
  sameAsEKtp: boolean;
  province: string;
  city: string;
  postalCode: string;
  address: string;
  provinceResidential: string;
  cityResidential: string;
  postalCodeResidential: string;
  addressResidential: string;
}

export interface FormAddBankAccount {
  accountName: string;
  accountNumber: string;
  bank: string;
  bankLogo: string;
}

export interface FormAddOtherBankAccount
  extends Omit<FormAddBankAccount, 'bankLogo'> {}

export enum KycStep {
  IDENTITY = 'IDENTITY',
  DATA = 'DATA',
  ADDRESS_DATA = 'ADDRESS_DATA',
  SELFIE = 'SELFIE',
  ABAD = 'ABAD',
}

export interface Step {
  step: string;
  subStep?: string[];
}

export interface FlattenStep {
  step: string;
  subStep?: string;
}
