import { createContext, useContext } from 'react';

import { IChooseCountryCurrencyContextValue } from '../types';

export const ChooseCountryCurrencyContext =
  createContext<IChooseCountryCurrencyContextValue | null>(null);

/**
 * Hook for using the choose country currency context.
 */
export const useChooseCountryCurrency =
  (): IChooseCountryCurrencyContextValue => {
    const context = useContext(ChooseCountryCurrencyContext);
    if (!context) {
      throw new Error(
        'useChooseCountryCurrency must be used within a ChooseCountryCurrencyContext.Provider',
      );
    }
    return context;
  };
