import { isNativeApp } from '@global-common/native-web-view-bridge';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { clarity } from 'react-microsoft-clarity';

enum tagProductName {
  REGISTER = 'Register',
  CREATE_TRANSACTION = 'Create Transaction',
  PAYMENT = 'Payment',
  ADD_BANK_ACC = 'Add Bank Account',
  REQ_REFUND = 'Request Refund',
}

const clarityId = process.env.NEXT_PUBLIC_CLARITY_ID;

const pathProduct = {
  [tagProductName.REGISTER]: ['/register', '/create-password'],
  [tagProductName.CREATE_TRANSACTION]: [
    '/quote',
    '/activity/transaction/create',
    '/activity/transaction/payment-validated',
    '/activity/transaction/validate',
  ],
  [tagProductName.PAYMENT]: [
    '/select-payment',
    '/payment-details',
    '/activity/transaction/paymentproof',
  ],
  [tagProductName.ADD_BANK_ACC]: ['/profile/bank-account-details'],
  [tagProductName.REQ_REFUND]: ['/activity/refund'],
};

function isSetTag(keyName: tagProductName, url: string) {
  return pathProduct[keyName].some((path) => url.includes(path));
}

function handleRouteChange(url: string) {
  let customTagName = '';
  if (isSetTag(tagProductName.REGISTER, url)) {
    customTagName = tagProductName.REGISTER;
  } else if (isSetTag(tagProductName.CREATE_TRANSACTION, url)) {
    customTagName = tagProductName.CREATE_TRANSACTION;
  } else if (isSetTag(tagProductName.PAYMENT, url)) {
    customTagName = tagProductName.PAYMENT;
  } else if (isSetTag(tagProductName.ADD_BANK_ACC, url)) {
    customTagName = tagProductName.ADD_BANK_ACC;
  } else if (isSetTag(tagProductName.REQ_REFUND, url)) {
    customTagName = tagProductName.REQ_REFUND;
  }

  if (customTagName !== '') {
    clarity.setTag('Product', customTagName);
  }
}

export default function useClarity() {
  const router = useRouter();

  useEffect(() => {
    if (typeof window !== 'undefined' && !isNativeApp() && clarityId) {
      clarity.init(clarityId);

      handleRouteChange(router.pathname); // for first render
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, []);
}
