import { forwardRef, ReactNode } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import DatePickerInput from './DatePickerInput';
import NumberInput from './NumberInput';
import PasswordInput from './PasswordInput';
import RadioInput from './RadioInput';
import RangePickerInput from './RangePickerInput';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import PhoneNumberInputV2 from './phone-number-input-v2';
import {
  SelectApiWrapper,
  SelectLegacyWrapper,
} from './select-input/SelectLegacyWrapper';

export type FieldType =
  | 'text'
  | 'radio'
  | 'email'
  | 'password'
  | 'date'
  | 'select'
  | 'phone'
  | 'textarea'
  | 'date-range';

export interface DynamicInputProps {
  type: FieldType | string;
  name: string;
  isOptional?: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  [key: string]: any;
}

const fieldRegistry = {
  text: (props) => <TextInput {...props} />,
  number: (props) => <NumberInput {...props} />,
  lookup: (props) => <TextInput {...props} />,
  radio: (props) => <RadioInput {...props} />,
  email: (props) => <TextInput type="email" {...props} />,
  api: (props) => (
    <SelectApiWrapper params={props.params} url={props.url}>
      <SelectLegacyWrapper {...props} />
    </SelectApiWrapper>
  ),
  password: (props) => <PasswordInput {...props} />,
  date: (props) => <DatePickerInput {...props} />,
  select: (props) => <SelectLegacyWrapper {...props} />,
  phone: (props) => <PhoneNumberInputV2 {...props} />,
  textarea: (props) => <TextAreaInput {...props} />,
  'date-range': (props) => <RangePickerInput {...props} />,
  hidden: (props) => <input {...props} type="hidden" />,
};

const DynamicInput = forwardRef(
  ({ type, name, ...resProps }: DynamicInputProps, ref) => {
    const field = fieldRegistry[type];
    const formContext = useFormContext();
    const { control } = formContext;

    const {
      fieldState: { error },
    } = useController({
      name,
      control,
    });
    if (field) {
      return field({
        ...resProps,
        ref,
        name,
        error: error?.message,
      });
    }
    throw new Error(
      `Renderer type ${type} not supported in ${resProps.name} field`,
    );
  },
);

export default DynamicInput;
