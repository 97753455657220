import { KycStatus, useGetMe, UserPersonal } from '@api-hooks/common';
import { css } from '@emotion/css';
import { useAuth } from '@hooks';
import { getStatusKycAbad } from '@modules';
import { useCurrentAccountStore } from '@stores';
import { BankAccountMatchingStatus } from '@topremit/shared-web/api-hooks/common';
import { handleClickOutside } from '@topremit/shared-web/common/helper';
import { breakpoint, screenSize } from '@topremit/shared-web/common/size';
import {
  Button,
  Flex,
  SkeletonWrapper,
  Text,
} from '@topremit/shared-web/components/elements';
import AnimatedDropdownArrow from '@topremit/shared-web/components/elements/AnimatedDropdownArrow';
import { PersonalUser } from '@topremit/shared-web/components/shapes';
import {
  useMediaQuery,
  useMounted,
  useNavbarDrawer,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { SizeScreenMobileLg } from '@topremit-ui/design-tokens';
import { AnimatePresence } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useReducer, useRef, useState } from 'react';

import ProfileDetail from './ProfileDetail';
import { initialPillsStatus, pillsHandler } from './helper';

const AnimatedProfileDetail = dynamic(() => import('./AnimatedProfileDetail'));

interface ActionMenuProps {
  hasBackground?: boolean;
}

export default function ActionMenu({
  hasBackground: hasBackgroundProps = true,
}: ActionMenuProps) {
  const { t } = useTranslation();

  const isMounted = useMounted();

  const { isAuthenticated, loginQuery } = useAuth();

  const { data: me, isLoading: isLoadingMe } = useGetMe();
  const { currentAccount } = useCurrentAccountStore.getState() || {};

  const { pathname, locale } = useRouter();
  const { isOpen: isOpenDrawer, close: closeDrawer } = useNavbarDrawer();

  const [isExpandDropdown, setExpandDropdown] = useState(false);

  const menuRef = useRef<any>();
  const [pillsStatus, dispatchPillsStatus] = useReducer(
    pillsHandler,
    initialPillsStatus,
  );

  const showDialog = useDialogStore((store) => store.show);

  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.mobileLg}px)`);
  const isHideBtnLogin = useMediaQuery(`(max-width: ${screenSize.tabletSm}px)`);
  const isShowBurger = useMediaQuery(
    `(max-width: ${breakpoint.navbarBurger}px)`,
  );
  const isLoading = loginQuery?.isLoading || isLoadingMe;
  const isShowBtnLogin = !isHideBtnLogin || isOpenDrawer;
  const isShowProfileIcon = isShowBurger || isOpenDrawer;
  const isBusinessNavbar = pathname.startsWith('/business');

  const hasBackground =
    hasBackgroundProps || (!hasBackgroundProps && isOpenDrawer);

  const actionButtonLabel = isBusinessNavbar
    ? t('contact_us')
    : t('auth:register');
  const actionButtonPath = isBusinessNavbar
    ? '/business/contact-us'
    : '/register/create-account';

  const {
    email,
    kycStatus,
    bankAccountVerificationInfo,
    blacklistReason,
    rejectedError,
  } = (me?.data as UserPersonal) || {};
  const matchingStatus = bankAccountVerificationInfo?.matchingStatus;

  function handleOpenProfile() {
    return showDialog({
      className: styled.bottomSheet,
      body: <ProfileDetail pillsStatus={pillsStatus} />,
    });
  }

  useEffect(() => {
    dispatchPillsStatus({
      kycAbadStatus: getStatusKycAbad(
        kycStatus as KycStatus,
        matchingStatus as BankAccountMatchingStatus,
        blacklistReason,
        rejectedError,
      ),
      t,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, kycStatus, matchingStatus, blacklistReason, rejectedError]);

  useEffect(() => {
    const handleBlur = (e) => {
      handleClickOutside(menuRef, isExpandDropdown, setExpandDropdown, e);
    };

    document.addEventListener('mouseup', handleBlur);

    return () => document.removeEventListener('mouseup', handleBlur);
  }, [isExpandDropdown, setExpandDropdown]);

  if (!isMounted) {
    return null;
  }

  if (isLoading) {
    return <SkeletonWrapper className={styled.skeletonAuth} />;
  }

  if (!isAuthenticated) {
    return (
      <Flex className={styled.actionButton}>
        {isShowBtnLogin && (
          <Link
            href="/login"
            className={styled.login(!hasBackground)}
            onClick={closeDrawer}
          >
            <Button
              type="secondary"
              fullWidth={isOpenDrawer}
              size={isMobileSize ? 'small' : 'medium'}
            >
              {t('auth:login')}
            </Button>
          </Link>
        )}
        <Link
          href={actionButtonPath}
          className={styled.link}
          onClick={closeDrawer}
        >
          <Button
            fullWidth={isOpenDrawer}
            size={isMobileSize ? 'small' : 'medium'}
          >
            {actionButtonLabel}
          </Button>
        </Link>
      </Flex>
    );
  }

  return (
    <>
      {isShowProfileIcon ? (
        <Flex
          justify="center"
          align="center"
          className={styled.circleInfo(isOpenDrawer)}
          onClick={handleOpenProfile}
        >
          <PersonalUser />
        </Flex>
      ) : (
        <Flex
          justify="center"
          align="center"
          onClick={() => setExpandDropdown((prev) => !prev)}
          className={styled.navProfile(!hasBackground)}
        >
          <Flex className="user-wrapper" ref={menuRef}>
            <Flex
              justify="center"
              align="center"
              className={styled.circleInfo(isOpenDrawer)}
            >
              <PersonalUser />
            </Flex>
            <Text className="name bold-text">
              {currentAccount?.name || email}
            </Text>
            <AnimatedDropdownArrow
              isExpand={isExpandDropdown}
              fill={!hasBackground ? 'var(--neutral-0)' : 'var(--neutral-500)'}
            />
            <AnimatePresence>
              {isExpandDropdown && (
                <AnimatedProfileDetail status={pillsStatus} />
              )}
            </AnimatePresence>
          </Flex>
        </Flex>
      )}
    </>
  );
}

const styled = {
  circleInfo: (isOpenDrawer: boolean) => css`
    background-color: var(--blue-100);
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.75rem;
    border-radius: ${isOpenDrawer ? '32px' : '50%'};
    aspect-ratio: 1;
  `,
  navProfile: (isTransparentNavbar?: boolean) => css`
    position: relative;
    .user-wrapper {
      cursor: pointer;
      align-items: center;
      .name {
        max-width: 7.5rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: ${isTransparentNavbar
          ? 'var(--neutral-0)'
          : 'var(--neutral-500)'};
        margin-right: 0.375rem;
      }
      .arrow {
        display: flex;
        justify-content: center;
      }
      .custom-card {
        position: absolute;
        min-width: 22.5rem;
        top: calc(100% + 35px);
        right: 0;
        overflow: hidden;
        box-shadow: 0 0px 8px rgba(46, 72, 101, 0.08);
        padding: 1rem 1.5rem;
      }
    }
  `,
  skeletonAuth: css`
    width: 6.25rem !important;
    min-width: unset !important;
    display: flex !important;
    align-items: center;
    @media (max-width: ${breakpoint.navbarBurger}px) {
      height: 2.625rem;
    }
    @media (max-width: ${SizeScreenMobileLg}px) {
      width: 5rem !important;
      height: 2.25rem;
    }
  `,
  bottomSheet: css`
    @media (max-width: ${breakpoint.navbarBurger}px) {
      bottom: 0;
      top: unset;
      left: unset;
      max-width: unset;
      transform: unset;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      animation: slideUp 0.3s ease-out;
    }
  `,
  actionButton: css`
    width: unset;
    align-items: center;
    @media (max-width: ${breakpoint.navbarBurger}px) {
      padding-left: 0;
      border-left: none;
    }
    @media (max-width: ${SizeScreenMobileLg}px) {
      flex-direction: column;
    }
    > :not(:last-child) {
      margin-right: 0.5rem;
      @media (max-width: ${breakpoint.navbarBurger}px) {
        margin-right: 0.75rem;
      }
      @media (max-width: ${screenSize.mobileLg}px) {
        margin-right: 0;
        margin-bottom: 0.75rem;
      }
    }
  `,
  link: css`
    width: 100%;
  `,
  login: (isTransparentNavbar: boolean) => css`
    width: 100%;
    button {
      background-color: ${isTransparentNavbar ? 'transparent !important' : ''};
      color: ${isTransparentNavbar ? 'var(--neutral-0) !important' : ''};
      border: ${isTransparentNavbar
        ? 'var(--neutral-0) solid 1px !important'
        : ''};
    }
  `,
};
