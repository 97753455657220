import * as flags from '@global-assets/flag';
import { color } from '@topremit/shared-web/styles/color';
import Image from 'next/image';

export type FlagCode = keyof typeof flags;

interface Props {
  code: FlagCode | undefined;
  size?: number;
  imgAlt?: string;
}

export default function Flag({ code, size = 24, imgAlt }: Props) {
  if (!code) {
    return (
      <div
        style={{
          width: size,
          height: size / 3 / 2,
          background: color.neutral300,
        }}
      />
    );
  }

  return (
    <Image
      key={code}
      width={size}
      alt={imgAlt || ''}
      height={size / (3 / 2)}
      src={flags[code.toLowerCase()]}
      /** expected, flag's shape is not square */
      style={{ background: code === 'np' ? color.neutral0 : color.neutral300 }}
    />
  );
}
