import { ReactNode } from 'react';

import { DrawerProvider } from '../../../hooks';

interface IDrawerProps {
  className?: string;
  children: ReactNode;
}

export default function Drawer({ className, children }: IDrawerProps) {
  return (
    <DrawerProvider>
      <div className={className}>{children}</div>
    </DrawerProvider>
  );
}
