import { TopupStatus } from '@api-hooks/wallet';
import { formatDate } from '@topremit/shared-web/common/helper';

export function formatCurrency({
  currency = 'IDR',
  amount = '0',
}: {
  currency: string;
  amount: string | number;
}) {
  const showDecimal = currency !== 'IDR';

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: showDecimal ? 2 : 0,
    maximumFractionDigits: showDecimal ? 2 : 0,
  }).format(parseFloat(String(amount)));
}

export function typeFileDownload(
  status: TopupStatus,
  beforePayment: string,
  afterPayment: string,
) {
  switch (status) {
    case 'requested':
    case 'waiting_approval':
      return beforePayment;
    case 'approved':
    case 'rejected':
    default:
      return afterPayment;
  }
}
export function getTopUpReceiptFileName(date: string, type: string) {
  const _formatDate = formatDate({ date, formatDate: 'yyyyMMdd' });
  return `Topremit_Topup_${type}_${_formatDate}.pdf`;
}
