import { UserStatus } from '@api-hooks/member';
import { ApiPaginationResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  BankAccountMatchingStatus,
  RecentTransactionCountries,
} from '@topremit/shared-web/api-hooks/common';
import {
  FAQModel,
  TransactionType,
} from '@topremit/shared-web/api-hooks/transaction';
import { FlagCode } from '@topremit/shared-web/components/elements/Flag';
import { VerificationMedia } from '@topremit/shared-web/typings/auth.model';

enum AppRatingStatusType {
  AVAILABLE = 'Available',
  NOT_AVAILABLE = 'Not Available',
  REVIEWED = 'Reviewed',
  POSTPONED = 'Postponed',
}

export type ClientSubmitType =
  | 'COMPANY_DETAILS'
  | 'BANK_ACCOUNT'
  | 'REGISTRATION_DOCUMENTS'
  | 'ADDITIONAL_DOCUMENT'
  | 'CONFIRM_VERIFICATION_DATA';

export type ClientEntityType =
  | 'PT'
  | 'CV'
  | 'PMA'
  | 'Yayasan'
  | 'Koperasi'
  | 'UD'
  | 'PT_PERORANGAN'
  | 'NGO'
  | 'Others';

export type DashboardType = 'BOTH' | 'RECEIVE_MONEY' | 'SEND_MONEY';

export enum UpdateDataStatusType {
  UNVERIFIED = 'UNVERIFIED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
  VERIFYING = 'VERIFYING',
}

/**
 * Note: Only the statuses with `//` comments apply to business-type accounts.
 */
export enum KycStatus {
  PENDING = 'PENDING', //Need revision
  SUBMITTED = 'SUBMITTED',
  DATA_REJECTED = 'DATA_REJECTED', //Verifying
  DATA_EXPIRED = 'DATA_EXPIRED',
  DATA_APPROVED = 'DATA_APPROVED', //Verified
  DATA_SUSPICIOUS = 'DATA_SUSPICIOUS',
  COMPLIANCE_APPROVED = 'COMPLIANCE_APPROVED',
  COMPLIANCE_WATCHED = 'COMPLIANCE_WATCHED',
  COMPLIANCE_REJECTED = 'COMPLIANCE_REJECTED', //Verification rejected
  BLACKLIST = 'BLACKLIST',
  DATA_WATCHED = 'DATA_WATCHED',
  DOCUMENT_SUBMITTED = 'DOCUMENT_SUBMITTED', //Verifying
  DATA_SUBMITTED = 'DATA_SUBMITTED', //Verifying
  //null = Not verified
}

export enum CommissionType {
  PERCENTAGE_SOURCE_AMOUNT = 'PERCENTAGE_SOURCE_AMOUNT',
  FLAT_AMOUNT = 'FLAT_AMOUNT',
  PERCENTAGE_FEE = 'PERCENTAGE_FEE',
}

export enum ServiceId {
  BANK_TRANSFER = 2,
  CASH_PICKUP = 3,
  HOME_DELIVERY = 4,
  INSTANT_TRANSFER = 5,
  EWALLET = 6,
  UNIONPAY = 8,
  ALIPAY = 9,
  SWIFT = 10,
  WECHAT_PAY = 11,
}

export interface LandingMeta {
  countrySum: number;
  lifetimeAmount: number;
  lifetimeMember: string;
  reviewRate: string;
  totalAirtimeCountries: string;
  totalAirtimeProviders: string;
  totalCurrencies: string;
  totalReview: string;
  totalDomesticBank: string;
  domesticFee: string;
  domesticDiscountFee: string;
}

export interface Announcement {
  title?: string;
  body?: string;
  icon?: string;
  color?: string;
  viewable: boolean;
}

export interface AnnouncementMeta {
  description: string;
  validUntil?: string;
  link: string;
  linkName: string;
  showBannerTimer: boolean;
}

interface RejectedReason {
  note?: string;
  reasonMessage: string;
  rejectedError?: string[];
}

interface TosPpAgreement {
  currentVersion: string;
  latestVersion: string;
  isShowable: boolean;
}

enum AbadRejectedReasonType {
  ACCOUNT_NAME_NOT_MATCH = 'ACCOUNT_NAME_NOT_MATCH',
  ACCOUNT_USED = 'ACCOUNT_USED',
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
}

export enum BlacklistReason {
  DATA_BLACKLIST_SPAM = 'DATA_BLACKLIST_SPAM',
  DATA_BLACKLIST_PROHIBITED = 'DATA_BLACKLIST_PROHIBITED',
  DATA_BLACKLIST_OTHER = 'DATA_BLACKLIST_OTHER',
}
export enum DASHBOARD_TYPE {
  SEND_MONEY = 'SEND_MONEY',
  RECEIVE_MONEY = 'RECEIVE_MONEY',
  BOTH = 'BOTH',
}

export enum DirectionType {
  REMIT = 'REMIT',
  AIRTIME = 'AIRTIME',
  DOMESTIC = 'DOMESTIC',
}

export enum TransactionReviewStatus {
  PENDING = 'PENDING',
  DISMISSED = 'DISMISSED',
  SKIPPED = 'SKIPPED',
  REVIEWED = 'REVIEWED',
}

export interface AddBankAccountDetailsResponse {
  accountName: string;
  accountNumber: string;
  accountType: 'INDIVIDUAL' | 'CORPORATE';
  bank: number | string;
  bankBranchCode?: any;
  checkedAccountName: string;
  createdAt?: string;
  deletedAt?: string;
  id: string;
  matchingStatus: BankAccountMatchingStatus;
  memberId: string | number;
  otherBank?: string;
  primary: boolean;
  rejectedReason?: string;
  updatedAt?: string;
  verifyAt?: string;
  verifyBy?: string;
}

interface BankAccountVerificationInfo {
  isVerified: boolean;
  daysLeft: number;
  isSkipped: boolean;
  isShowable: boolean;
  rejectedReasonQuotation: string | null;
  matchingStatus: BankAccountMatchingStatus;
  rejectedReason: string | null;
  rejectedReasonType: AbadRejectedReasonType | null;
}

interface UpdateData {
  status: UpdateDataStatusType;
  note?: string;
}

export interface UserPersonal {
  memberId: string;
  activated: boolean;
  updateData?: UpdateData;
  address?: string;
  affiliateStatus: string | null;
  announcements?: Announcement[];
  appRatingStatus: AppRatingStatusType;
  bankAccountVerificationInfo: BankAccountVerificationInfo;
  blacklistReason?: BlacklistReason;
  chatId: string | null;
  city?: {
    label: string;
    value: string;
  };
  countryOfBirthIsoCode?: {
    value: string;
    flagCode: string;
    label: string;
  };
  countryIsoCode?: {
    value: string;
    flagCode: string;
    label: string;
  };
  dateOfBirth?: string;
  dialCode?: string;
  dialIsoCode?: string;
  disableWithdrawAddBank: boolean;
  email: string;
  firstname?: string;
  name?: string;
  fullname?: string;
  gender?: {
    label: string;
    value: string;
  };
  hasUnreadNotification: boolean;
  hasVoucherUnread?: boolean;
  id: number;
  idCountry?: {
    value: string;
    flagCode: string;
    label: string;
  };
  idExpirationDate?: string;
  idNumber?: string;
  idType?: {
    label: string;
    value: string;
  };
  identity?: {
    address?: string;
    postalCode?: string;
    city?: {
      label: string;
      value: string;
    };
    countryIsoCode?: {
      value: string;
      flagCode: string;
      label: string;
    };
    province?: {
      label: string;
      value: string;
    };
  };
  isAffiliate: boolean;
  isDomicileSame?: boolean;
  isFirstVerified?: boolean;
  isEmailVerified: boolean;
  kycAt: string | null;
  kycStatus: KycStatus;
  lastname?: string;
  locale: string;
  msisdn?: string;
  nationality?: {
    value: string;
    flagCode: string;
    label: string;
  };
  occupation?: {
    label: string;
    value: string;
  };
  onboarding: Onboarding;
  onboardingHome?: string;
  postalCode?: string;
  province?: {
    label: string;
    value: string;
  };
  pushSubscribed: boolean;
  recentTransCountries: RecentTransactionCountries[];
  recentBulkTransCountries: RecentTransactionCountries[];
  referralInfo: {
    encodeLink: string;
    refCode: string;
  };
  rejectedError?: string;
  rejectedReasons?: RejectedReason[];
  showDomesticCoachmark: boolean;
  showEtaCoachmark: boolean;
  showHomepageCoachmark?: boolean;
  showInviteFriends: boolean;
  showTransactionRule: boolean;
  showUnderageError: boolean;
  timezone: string;
  tosPpAgreement: TosPpAgreement;
  tosPpBody?: string;
  tosPpTitle?: string;
  totalTransactionDomesticPerDay: number;
  transactionCount: TransactionCount;
  userId: string;
  verified: boolean;
  wasVerified: boolean;
  zeroFeeReminder: {
    title: string;
    description: string;
  };
}

interface Onboarding {
  domesticLlg: OnboardingShowable;
  mobileWebTrCoin: OnboardingShowable;
  mobileWebUniqueCode: OnboardingShowable;
}

interface OnboardingShowable {
  step: number;
  showable: boolean;
}

export interface UserMeta {
  announcements: Announcement[];
  bankAccountVerificationInfo: BankAccountVerificationInfo;
  idType: { label: string; value: string };
  verified: boolean;
  wasVerified: boolean;
  kycStatus: string;
  rejectedError: string[];
  rejectedReason: string;
  rejectedReasons: string[];
  appRatingStatus: string;
  blacklistReason: boolean;
  chatId: string;
  hasUnreadNotification: boolean;
}
export interface UserBusiness {
  memberId: string;
  updateData?: UpdateData;
  id: string;
  companyName: string;
  dashboardType: DashboardType;
  name: string;
  entityName: string;
  email: string;
  status: UserStatus;
  hasDealingCode: boolean;
  isRemittance: boolean;
  wallet: {
    currency: string;
    balance: string;
  };
  roles: AccountRole[];
  locale: string;
  timezone: string;
  isAffiliate: boolean;
  entityDomicileCountry: string;
  affiliateCounterNew: number;
  tosPpAgreement: TosPpAgreement;
  tosPpTitle: string;
  tosPpBody: string;
  autoWithdrawal: {
    isNewClient: boolean;
    isShowable: boolean;
    autoWithdrawActivated: boolean;
    activateAutoWithdrawalDateBefore: string | null;
  };
}

export interface RoutingChannelSpeed {
  type: string;
  speed: string;
  description: string;
  time: string;
  currency: string;
  fee: string;
  correspondentFee?: string;
}

export interface RoutingChannelFee {
  type: string;
  speed: string;
  fee: number;
  currency: string;
}

export interface SendMoneySpeed {
  serviceId: number;
  serviceName: string;
  routingChannels: RoutingChannelSpeed[];
  serviceIcon: string;
  country: string;
  currency: string[];
}

export interface SendMoneyFee {
  serviceId: number;
  serviceName: string;
  description: string;
  routingChannels: RoutingChannelFee[];
  serviceIcon: string;
  additionalDescription?: string;
  additionalSymbol?: {
    symbol: string;
    keyname: string;
  };
}

export interface CheapestFeeModel {
  country: string;
  currency: string;
  fee: string;
  serviceId: number;
  serviceName: string;
  time: string;
  longDescTime: string;
}

export interface SendMoney {
  speed: SendMoneySpeed[];
  fee: SendMoneyFee[];
  faq: FAQModel[];
  currency: string[];
  countryCount: number;
  fastestSpeed: string;
  cheapestFee: CheapestFeeModel;
  lifetimeMember: string;
}

export interface AirasiaPoint {
  country: string;
  maxPoint: number;
  minPoint: number;
}

export interface AffiliateCommission {
  commissionType: string;
  countedRecipient: string;
  commissionValue: number;
  countedServiceType: string;
  commissionMaxValue: number;
  countedMinSourceAmount: number;
  minimumAmountWithdraw: number;
  commissionExpired: number;
  defaultSelectedBank: boolean;
}

export interface Referree {
  referralStatus?: boolean;
  fullname: string;
  status: string;
  transactionStatus: string;
  message: string;
  createAt?: string;
}

export interface RewardsEarned {
  rewardsEarned: number;
}

export interface TransactionCount {
  incompleteTotal: number;
  successTotal: number;
}
export interface Affiliate {
  id: number;
  memberId: number;
  balance: number;
  affiliateCode: string;
  minWithdrawAmount: number;
  link: string;
  encodeLink: string;
  transactionSuccess: number;
  registered: number;
  canWithdraw: boolean;
  oldUser: boolean;
  showNotification: boolean;
  ongoingWithdraw: boolean;
  ongoingWithdrawal: number;
  successfulWithdrawal: number;
  latestPercentageRegistered: number;
  latestPercentageTransactionSuccess: number;
  affiliateSegment: string;
}
export interface ShowInviteFriend {
  showInviteFriends: boolean;
}

export interface ReadTransactionRule {
  showTransactionRule: boolean;
}

export interface AffiliateWithdraw {
  amount: number;
  taxConfirmation: string;
}
export interface ServicesFilter {
  label: string;
  value: string;
}

export interface ShowFeedback {
  link: string;
  showUserRequestForm: boolean;
}

export interface DiscoverySource {
  label: string;
  value: string;
}

export type NotificationSegment = 'need_action' | 'information';
export interface NotificationItemContent {
  title: string;
  body: string;
  icon: string;
}
export interface NotificationItem {
  id: string;
  subject: string;
  body: string;
  content: NotificationItemContent | null;
  // url can be empty string
  url: string;
  icon: string;
  readAt: string | null;
  segment: NotificationSegment;
  createdAt: string;
  createdTime: `${number}:${number}`;
}

export interface NotificationGroup {
  date: string;
  notifications: NotificationItem[];
}

export interface NotificationUnreadTab {
  hasUnreadTabNotification: {
    information: boolean;
    needAction: boolean;
  };
}

export type ApiNotificationWithMeta<T> = ApiPaginationResult<T> & {
  meta: NotificationUnreadTab;
};

export interface Banner {
  id: string;
  name: string;
  title: string;
  description: string;
  link: string;
  imageUrl: string;
  createdAt: string;
}

export interface ReadVoucher {
  voucherReadAt: string;
}

export interface TransactionReview {
  createdAt: string;
  transactionStatus: string;
  destinationCountry: string;
  destinationCountryFlagCode: FlagCode;
  destinationCurrency: string;
  destinationAmount: string;
  paymentDetail: {
    accountName: string;
    accountNo: string;
    amount: number;
    amountLabel: string;
    bank: string;
    bankCode: string;
    imageUrl: string;
    mailImageUrl: string;
    recipientName: string;
    senderName: string;
    sourceCurrencyIsoCode: string;
  };
  recipientName: string;
  refId: string;
  reviewStatus: TransactionReviewStatus;
  type: TransactionType;
  isDomestic: boolean;
  product: string;
  msisdn: string;
}

export interface FeedbackList {
  bad: { label: string; value: string }[];
  good: { label: string; value: string }[];
  neutral: { label: string; value: string }[];
  new: {
    bad: { title: string };
    good: { title: string };
    neutral: { title: string };
  };
}

export interface SubmitReviewParam {
  id: string;
  reviewRate: number;
  reviewReason: string[];
  reviewComment: string;
}

export interface BankAccountDetail {
  id: string;
  bank: string;
  accountName: string;
  accountNumber: string;
  primary: boolean;
  matchingStatus: BankAccountMatchingStatus;
  verifyAt: string;
  canDelete: boolean;
  payerLogo: string;
}

export interface SetPrimaryBankParam {
  id: string;
}

export enum AccountRole {
  OWNER = 'Owner',
  ADMIN = 'Admin',
  REPORTING = 'Reporting',
  MAKER = 'Maker',
  RELEASER = 'Releaser',
  AFFILIATE = 'Affiliate',
}

export enum AccountType {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export enum AccountEntityType {
  PT = 'PT',
  CV = 'CV',
  PMA = 'PMA',
  YAYASAN = 'YAYASAN',
  KOPERASI = 'KOPERASI',
  UD = 'UD',
  PT_PERORANGAN = 'PT_PERORANGAN',
  NGO = 'NGO',
  OTHERS = 'OTHERS',
}
export interface Account {
  type: AccountType;
  id: string;
  name: string;
  companyName?: string;
  roles: AccountRole[];
  entityType: AccountEntityType;
  hasUnreadNotification: boolean;
  isRemittance?: boolean;
}

export interface KycStatusInfo {
  label: string;
  value: KycStatus | null;
}

export interface AccountWithKycStatus extends Account {
  kycStatus?: KycStatusInfo;
}

export interface AccountInfo {
  fullname: string;
  email: string;
  pinEmpty: boolean;
  hasBusiness: boolean;
  hasPersonal: boolean;
  hasDealingCode: boolean;
  locale: string;
  timezone: string;
  isEmailVerified: boolean;
  chatId: string | null;
  hasUnreadNotification: boolean;
  dashboardStatus: boolean;
  accounts: Account[];
  phoneNumber: string;
  userId: string;
  isAbadApproved: boolean;
  isKycApproved: boolean;
  onboarding: Onboarding;
}

export interface AccountInfoBusiness extends AccountInfo {
  kycStatus: KycStatusInfo;
  rejectedError: ClientSubmitType[];
  completedKyc: number;
  wasApproved: boolean;
  wasRejected: boolean;
  dashboardType: DashboardType;
  entityType: ClientEntityType;
  dashboardStatus: boolean;
}

export interface TokenInfo {
  email: string;
  isSameAccount: boolean;
}

export interface CreateAccountPersonalParam {
  phoneNumber: string;
  referral: string;
}

export interface RequestOtpCreateAccountPersonalParam {
  media: VerificationMedia;
  phoneNumber: string;
  'g-recaptcha-response': string;
}

export interface ChangePhoneCreateAccountPersonal {
  oldPhoneNumber: string;
  phoneNumber: string;
}

export interface VerifyOtpCreateAccountPersonal {
  media: VerificationMedia;
  phoneNumber: string;
  code: string;
}

export interface VerificationConfigModel {
  otp: {
    enableOtpSms: boolean;
    enableOtpMiscall: boolean;
    enableOtpWhatsapp: boolean;
    enableOtpEmail: boolean;
  };
}

export interface CompanyDetail {
  code: string;
  email: string;
  phone: string;
  entityName: string;
}

export interface BusinessDetail {
  entityType: string;
  entityName: string;
  businessType: string;
  businessDesc: string;
  website: string | null;
  entityDomicileCountry: string;
  entityDomicileAddress: string;
  entityEstablishedAddress: string;
  entityEstablishedCountry: string | null;
}

export interface CompanyProfile {
  clientId: string;
  companyName: string;
  segment: AccountType;
  status: KycStatus;
  companyDetail: CompanyDetail;
  businessDetail: BusinessDetail;
}

export interface OptionModel<T = string> {
  label: string;
  value: T;
}

export interface HasSeenOnboardingsRequest {
  type: 'mobile_web_tr_coin' | 'mobile_web_unique_code' | 'domestic_llg';
}

export interface BankAccountVerified {
  id: string;
  bank: string;
  bankLogo: string;
  accountName: string;
  accountNumber: string;
}

export interface LandingPageInfoModel {
  isFirstTransactionFree: boolean;
}
