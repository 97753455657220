import {
  DevicePermissionsNativeData,
  PermissionValueEnum,
} from '@global-typings/native-web-view-bridge.model';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface MobilePermission {
  permission: Partial<DevicePermissionsNativeData>;
  setPermission: (
    key: keyof DevicePermissionsNativeData,
    value: PermissionValueEnum,
  ) => void;
}

export const useMobilePermissionStore = create<MobilePermission>()(
  persist(
    (set) => ({
      permission: {},
      setPermission: (key, value) =>
        set((state) => ({
          permission: {
            ...state.permission,
            [key]: value,
          },
        })),
    }),
    {
      name: 'mobile-permission',
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
