import { SVGProps } from 'react';

import { color } from '../../styles/color';

const CreditCardFill = ({
  fill = color.neutral500,
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8333 4.5H6.16667C3.83333 4.5 2 6.33333 2 8.66667H22C22 6.33333 20.1667 4.5 17.8333 4.5Z"
      fill={fill}
    />
    <path
      d="M2 15.3335C2 17.6668 3.83333 19.5002 6.16667 19.5002H17.8333C20.1667 19.5002 22 17.6668 22 15.3335V10.3335H2V15.3335ZM7.83333 14.9168C7.83333 15.5835 7.25 16.1668 6.58333 16.1668C5.91667 16.1668 5.33333 15.5835 5.33333 14.9168C5.33333 14.2502 5.91667 13.6668 6.58333 13.6668C7.25 13.6668 7.83333 14.2502 7.83333 14.9168Z"
      fill={fill}
    />
  </svg>
);
export default CreditCardFill;
