import { FlagCode } from '@global-elements-utils/Flag';

import { RoutingChannelType, TransactionType } from '../transaction';

export enum Continents {
  ALL_REGION = 'ALL_REGION',
  ASIA = 'ASIA',
  EUROPE = 'EUROPE',
  AMERICAS = 'AMERICAS',
  AFRICA = 'AFRICA',
  OCEANIA = 'OCEANIA',
}

export enum ServiceId {
  BANK_TRANSFER = 2,
  CASH_PICKUP = 3,
  INSTANT_TRANSFER = 5,
  EWALLET = 6,
  UNIONPAY = 8,
  ALIPAY = 9,
  SWIFT = 10,
}

/**
 * used for transaction, for analytics use TransactionRecipientType instead
 */
export enum TransactionMode {
  SINGLE = 'SINGLE',
  BULK = 'BULK',
}

export interface ISelectOptions {
  label: string | number;
  value: string | number;
  [key: string]: number | string;
}

export interface GetCurrencyParam {
  country: string;
  service?: number | string;
  type?: string;
}

export interface PaymentMethodsResponse {
  id: number;
  name: string;
  disabled: boolean;
  note: any;
  paymentProviders: PaymentProvider[];
}

export interface PaymentProvider {
  paymentMethodId: string;
  name: string;
  maintenance: boolean;
  maintenanceUntil: string;
  fasterApproval: boolean;
  code: string;
  accountNumber: string;
  enable: boolean;
  imageUrl: string;
}
export interface GetCountryParam {
  available?: boolean;
  type?: TransactionType;
  bulk?: boolean;
}

export interface GetUploadUrl {
  contentType?: string | undefined;
}

export interface GetUploadUrls {
  files: any[];
  validateFunc?: (e?: any) => boolean;
  onChange?: (e?: any) => void;
}

export interface GetServiceParam {
  type: TransactionType;
  country: string;
}

export interface ContinentsModel {
  [continent: string]: CountryModel[];
}

export interface CountryModel {
  specialNote: string | null;
  note: string | null;
  continent: string;
  dialCode: string | null;
  flagCode: FlagCode;
  isoCode: string;
  name: string;
  searchText: string;
  title: {
    en: string;
    id: string;
  };
  urlSlug: string;
}

export interface RoutingChannelModel {
  fee: number;
  note: string;
  label: string;
  maxVolume: number;
  minVolume: number;
  description: string;
  type: RoutingChannelType;
  correspondentFee?: number;
  volumeExceededDescription: string;
  hasMultiplier: boolean;
  disabled?: boolean;
  etaDescription?: string;
  discount?: RoutingChannelDiscount;
}

export interface RoutingChannelDiscount {
  value: number;
  discountType: 'DISCOUNT_PERCENTAGE_FEE' | 'DISCOUNT_VALUE_FEE';
  minimumAmount: number;
  validUntil?: string;
  userType: 'NEW' | 'OLD';
}

export interface ServiceModel {
  id: number;
  name: string;
  note?: string;
  description: string;
  volumeExceededDescription: string;
  routingChannels: RoutingChannelModel[];
  serviceIcon: string;
  dailyLimit: number;
}

export interface ServiceMeta {
  isFirstTransactionFree: boolean;
}

export interface UploadURLResponse {
  attributes: Attributes;
  inputs: Inputs;
  // ini tidak boleh camel case karena tidak di humps
  file_name: string;
  uid?: string;
}

export interface Attributes {
  action: string;
  method: string;
  enctype: string;
}
export interface Inputs {
  key: string;
}

export interface BankNameModel {
  id: number;
  name: string;
  currency: string;
  note: any;
  map: any;
  payerLogo: string;
}

export interface AddBankAccountDetailsParam {
  bank?: string;
  accountNumber: string;
  accountName: string;
  otherBank?: string;
  type?: 'PRIMARY' | 'ADDITIONAL' | 'REFUND';
}

export interface AddBusinessBankAccountDetailsParam
  extends AddBankAccountDetailsParam {
  currency: string;
  country: string;
  isBusiness: boolean;
}

export interface CheckAccountNameParam {
  bank: string;
  accountNumber: string;
  type?: 'PRIMARY' | 'ADDITIONAL' | 'REFUND';
  isBusiness?: boolean;
  clientAbadRegistration?: boolean;
}

export enum BankAccountMatchingStatus {
  APPROVED = 'APPROVED',
  HOLD = 'HOLD',
  REJECTED = 'REJECTED',
  WATCHED = 'WATCHED',
}

export interface CheckedAccountNameResponse {
  checkedAccountName: string;
  matchingStatus: BankAccountMatchingStatus;
}
export interface RecentTransactionCountries {
  memberId: number;
  currencies: string[];
  country: string;
  title: string;
  flag: FlagCode;
  urlSlug: string;
}

export type Language = 'en' | 'id';
