import { useMeasure } from '@topremit/shared-web/hooks';
import {
  AnimatePresence,
  AnimationProps,
  HTMLMotionProps,
  motion,
  MotionProps,
} from 'framer-motion';
import { ReactNode, useRef } from 'react';

interface AnimatedHeightWrapperProps extends HTMLMotionProps<'div'> {
  children: ReactNode;
}

/**
 * `AnimatedHeightWrapper` is a component that animates the height of its children
 * based on their content size. It uses Framer Motion for animations and smoothly adjusts
 * the height of the wrapper when the content changes.
 *
 * @example
 * ```tsx
 * <AnimatedHeightWrapper>
 *   <YourComponent />
 * </AnimatedHeightWrapper>
 * ```
 * @param {AnimatedHeightWrapperProps} props - Props to pass into the component.
 * @returns {JSX.Element} A wrapper component that animates its height based on the size of its content.
 */
function AnimatedHeightWrapper({
  style,
  children,
  ...rest
}: AnimatedHeightWrapperProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);
  const { height } = useMeasure(ref);

  const containerStyle: MotionProps['style'] = {
    overflow: 'hidden',
    position: 'relative',
    ...style,
  };

  const containerDivAnimation: AnimationProps['animate'] = {
    height: height > 0 ? height : undefined,
  };

  const containerDivTransition: AnimationProps['transition'] = {
    bounce: 0,
    duration: 0.8,
    type: 'spring',
  };

  const innerDivAnimation: AnimationProps['animate'] = {
    opacity: 1,
    transition: {
      delay: 0.2,
      type: 'ease',
      duration: 0.3,
      ease: 'easeInOut',
    },
  };

  const innerDivExit: AnimationProps['exit'] = {
    opacity: 0,
    transition: {
      type: 'ease',
      duration: 0.2,
      ease: 'easeInOut',
    },
  };

  return (
    <motion.div
      style={containerStyle}
      animate={containerDivAnimation}
      transition={containerDivTransition}
      {...rest}
    >
      <div ref={ref}>
        <AnimatePresence mode="popLayout" initial={false}>
          <motion.div
            exit={innerDivExit}
            initial={{ opacity: 0 }}
            animate={innerDivAnimation}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export default AnimatedHeightWrapper;
