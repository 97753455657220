import { css, cx } from '@emotion/css';
import { breakpoint } from '@topremit/shared-web/common/size';
import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';

interface Props extends LinkProps {
  isActive?: boolean;
  className?: string;
  children: ReactNode;
}

function NavLink({ isActive, className, children, ...props }: Props) {
  return (
    <Link
      className={cx(styled.root, { [styled.active]: isActive }, className)}
      {...props}
    >
      {children}
    </Link>
  );
}

const styled = {
  root: css`
    color: var(--neutral-800);
    padding: 0.5625rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15.625rem;
    :active,
    :hover {
      color: var(--blue-500);
      background-color: var(--blue-100);
    }
    @media (max-width: ${breakpoint.navbarBurger}px) {
      padding: 0.75rem 1rem;
      justify-content: unset;
      margin: 0.5rem 0;
      font-weight: var(--bold-font-weight);
    }
  `,
  active: css`
    background-color: var(--blue-100);
    color: var(--blue-500) !important;
  `,
};

export default NavLink;
