/**
 * SelectLegacyWrapper.tsx
 *
 * This file is used to create a wrapper for the select input field.
 * It is used in the DynamicInput.tsx file to create a select input field.
 *
 * This wrap the new select input field to compatible with the old select input field.
 */

import { css, cx } from '@emotion/css';
import { useGetApiUrl } from '@global-api-hooks/transaction';
import SkeletonWrapper from '@global-elements-utils/SkeletonWrapper';
import {
  Select,
  SelectItem,
  SelectList,
  SelectSearch,
} from '@global-elements-utils/select';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

interface SelectLegacyWrapperProps extends React.ComponentProps<typeof Select> {
  onSelectItem?: (item: any) => void;
  onClick?: () => void;
  className?: string;
  options?: { label: string; value: string }[];
  searchable?: boolean;
  id?: string;
}

export function SelectLegacyWrapper({
  name,
  className,
  onSelectItem,
  onClick,
  options,
  defaultValue,
  searchable = false,
  id,
  ...props
}: SelectLegacyWrapperProps) {
  const formContext = useFormContext();
  const { control } = formContext;

  React.useEffect(() => {
    if (defaultValue) {
      formContext.setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={cx(styled.field, className)}>
      <Select
        id={id}
        control={control}
        name={name}
        fullWidth
        onChange={(value) => {
          onSelectItem?.(value);
        }}
        onClick={onClick}
        {...props}
      >
        {searchable && <SelectSearch />}

        <SelectList>
          {options?.map(({ label, value }) => (
            <SelectItem key={value} value={String(value)} keywords={[label!]}>
              {label}
            </SelectItem>
          ))}
        </SelectList>
      </Select>
    </div>
  );
}

export function SelectApiWrapper({
  url,
  children,
  params,
}: {
  url: string;
  children: any;
  params: {
    [key: string]: any;
  };
}) {
  const { data: options, isLoading: apiLoad } = useGetApiUrl(
    { ...params, url },
    {
      refetchOnWindowFocus: false,
    },
  );

  if (apiLoad) {
    return <SkeletonWrapper className={styled.skeleton} />;
  }

  return React.cloneElement(children, { options });
}

const styled = {
  skeleton: css`
    min-height: 4rem;
    width: 100%;
    margin-bottom: 8px;
    .ant-skeleton-input {
      border-radius: 0.5rem;
      height: inherit;
    }
  `,
  field: css`
    margin-bottom: 1rem;
  `,
};
