import { requestFn } from '@common/client';
import { UpdateDataFormPayload } from '@modules/dashboard/profile/types';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { useNotification } from '@topremit/shared-web/hooks';
import { useMutation, UseMutationOptions } from 'react-query';

import {
  AddMemberParam,
  Member,
  MemberRequest,
  ReactivateMemberParam,
  RegistrationToken,
  UpdateMemberRequestParam,
  UpdateMembersRequestParam,
} from './member.model';

export function useAddMember(
  options?: UseMutationOptions<ApiResult<Member>, ApiError, AddMemberParam>,
) {
  return useMutation<ApiResult<Member>, ApiError, AddMemberParam>(
    async (param) =>
      await requestFn(
        {
          path: 'members/manage',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useDeactiveMember(
  options?: UseMutationOptions<ApiResult<null>, ApiError, string>,
) {
  return useMutation<ApiResult<null>, ApiError, string>(
    async (id) =>
      await requestFn({
        path: `members/manage/${id}`,
        method: 'delete',
      }),
    options,
  );
}

export function useDeactiveMembers(
  options?: UseMutationOptions<ApiResult<null>, ApiError, string[]>,
) {
  return useMutation<ApiResult<null>, ApiError, string[]>(
    async (ids: string[]) =>
      await requestFn(
        {
          path: 'members/manage',
          method: 'delete',
        },
        {
          json: { ids },
        },
      ),
    options,
  );
}

export function useReactivateMember(
  options?: UseMutationOptions<
    ApiResult<null>,
    ApiError,
    ReactivateMemberParam
  >,
) {
  return useMutation<ApiResult<null>, ApiError, ReactivateMemberParam>(
    async ({ id, roles }: ReactivateMemberParam) =>
      await requestFn(
        {
          path: `members/manage/${id}/reactive`,
          method: 'put',
        },
        {
          json: { roles },
        },
      ),
    options,
  );
}

export function useResendActivationMember(
  options?: UseMutationOptions<ApiResult<null>, ApiError, string>,
) {
  return useMutation<ApiResult<null>, ApiError, string>(
    async (id) =>
      await requestFn({
        path: `members/manage/${id}/resend-activation`,
        method: 'post',
      }),
    options,
  );
}

export function useUpdateMember(
  id: string,
  options?: UseMutationOptions<ApiResult<Member>, ApiError, AddMemberParam>,
) {
  return useMutation<ApiResult<Member>, ApiError, AddMemberParam>(
    async (param) =>
      await requestFn(
        {
          path: `members/manage/${id}`,
          method: 'put',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useUpdateMemberRequest(
  options?: UseMutationOptions<
    ApiResult<MemberRequest>,
    ApiError,
    UpdateMemberRequestParam
  >,
) {
  return useMutation<
    ApiResult<MemberRequest>,
    ApiError,
    UpdateMemberRequestParam
  >(
    async ({ id, type }: UpdateMemberRequestParam) =>
      await requestFn({
        path: `members/requests/${id}/actions/${type}`,
        method: 'put',
      }),
    options,
  );
}

export function useUpdateMembersRequest(
  options?: UseMutationOptions<
    ApiResult<MemberRequest>,
    ApiError,
    UpdateMembersRequestParam
  >,
) {
  return useMutation<
    ApiResult<MemberRequest>,
    ApiError,
    UpdateMembersRequestParam
  >(
    async ({ ids, type }: UpdateMembersRequestParam) =>
      await requestFn(
        {
          path: `members/requests/actions/${type}`,
          method: 'put',
        },
        {
          json: { ids },
        },
      ),
    options,
  );
}

export function useCheckRegistrationToken(
  options?: UseMutationOptions<ApiResult<RegistrationToken>, ApiError, string>,
) {
  return useMutation<ApiResult<RegistrationToken>, ApiError, string>(
    'registration-token',
    async (token: string) =>
      await requestFn(
        {
          path: 'check-registration-token-status',
          method: 'post',
        },
        {
          json: { token },
        },
      ),
    options,
  );
}
export function usePostUpdateData(
  options?: UseMutationOptions<ApiResult, ApiError, UpdateDataFormPayload>,
) {
  const { addNotification } = useNotification();
  return useMutation<ApiResult, ApiError, UpdateDataFormPayload>(
    (param) =>
      requestFn(
        {
          path: 'update-data',
          method: 'post',
        },
        { json: param },
      ),
    {
      onError: ({ message }) => {
        addNotification({ message, type: 'danger' });
      },
      ...options,
    },
  );
}

export function useDeleteUpdateDataDraft(
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  return useMutation<ApiResult, ApiError>(
    () =>
      requestFn({
        path: `update-data`,
        method: 'delete',
      }),
    { retry: false, ...options },
  );
}

export function useUpdateDataLater(
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  return useMutation<ApiResult, ApiError>(
    () =>
      requestFn({
        path: `update-data/later`,
        method: 'put',
      }),
    { retry: false, ...options },
  );
}
