import { cx } from '@emotion/css';

import { useChooseCountryCurrency } from './hooks/use-choose-country-currency';
import { styles } from './styles';

export default function ChooseCountryCurrencyLabel() {
  const { value, label, error, disabled, showLabelWhenHasValue } =
    useChooseCountryCurrency();

  const hasValue = !!value.country && !!value.currency;
  return (
    <label
      className={cx(
        styles.label({
          disabled,
          hasValue,
          error: !!error,
          showLabelWhenHasValue,
        }),
        'choose-country-currency-label',
      )}
    >
      {label}
    </label>
  );
}
