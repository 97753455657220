import * as React from 'react';
import { SVGProps } from 'react';

import { color } from '../../styles/color';
const Arrow = (
  props: SVGProps<SVGSVGElement> & {
    direction?: 'left' | 'right' | 'up' | 'down';
    width?: number;
    height?: number;
    fill?: string;
  },
) => {
  const { direction = 'right', width, height, fill } = props;
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform:
          direction === 'left'
            ? 'rotate(180deg)'
            : direction === 'up'
              ? 'rotate(-90deg)'
              : direction === 'down'
                ? 'rotate(90deg)'
                : 'rotate(0deg)',
      }}
      {...props}
    >
      <path
        d="M12.9335 7.24399C12.7773 7.40026 12.6895 7.61219 12.6895 7.83316C12.6895 8.05413 12.7773 8.26605 12.9335 8.42232L15.6768 11.1665L6.99935 11.1723C6.77834 11.1723 6.56637 11.2601 6.41009 11.4164C6.25381 11.5727 6.16602 11.7846 6.16602 12.0057C6.16602 12.2267 6.25381 12.4386 6.41009 12.5949C6.56637 12.7512 6.77834 12.839 6.99935 12.839L15.6777 12.8332L12.9335 15.5773C12.8539 15.6542 12.7904 15.7461 12.7468 15.8478C12.7031 15.9495 12.6801 16.0588 12.6791 16.1695C12.6782 16.2801 12.6993 16.3899 12.7412 16.4923C12.7831 16.5947 12.8449 16.6877 12.9232 16.766C13.0014 16.8442 13.0945 16.9061 13.1969 16.948C13.2993 16.9899 13.409 17.011 13.5197 17.01C13.6303 17.0091 13.7397 16.9861 13.8414 16.9424C13.943 16.8987 14.035 16.8352 14.1118 16.7557L17.1002 13.7682C17.3324 13.536 17.5166 13.2604 17.6422 12.957C17.7679 12.6537 17.8326 12.3286 17.8326 12.0002C17.8326 11.6719 17.7679 11.3468 17.6422 11.0434C17.5166 10.7401 17.3324 10.4645 17.1002 10.2323L14.1118 7.24399C13.9556 7.08776 13.7437 7 13.5227 7C13.3017 7 13.0898 7.08776 12.9335 7.24399Z"
        fill={fill || color.neutral500}
      />
    </svg>
  );
};
export default Arrow;
