import {
  PaymentStatus,
  TransactionRecipientType,
  TransactionStatus,
  TransactionType,
} from '@global-api-hooks/transaction';

import {
  AnalyticPagesNames,
  BankName,
  DownloadInvoiceType,
  DownloadType,
  PageDownloadInvoice,
  ShareTrackingMethod,
  TransactionTrackSource,
} from './analytics.model';
import { IdentifierType } from './auth.model';
import { EFoundUsSocialMedia } from './profile.model';
import { FailedAddToCartError, PaymentMethod } from './transaction.model';

enum Variables {
  AMOUNT = 'amount',
  BANK = 'bank',
  BATCH_ID = 'batch_id',
  CHANGE_FIELD = 'change_field',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  CURRENT_STEP = 'current_step',
  DATE = 'date',
  DATE_AFTER = 'date_after',
  DATE_BEFORE = 'date_before',
  DESTINATION = 'destination',
  DOWNLOAD_TYPE = 'download_type',
  ERROR = 'error',
  ID = 'id',
  ITEM_ID = 'item_id',
  ITEM_NAME = 'item_name',
  ITEM_TYPE = 'item_type',
  MEMBER_ID = 'member_id',
  METHOD = 'method',
  NAME = 'name',
  NOTE = 'note',
  PAGE_LOCATION = 'page_location',
  PAGE_PATH = 'page_path',
  PAGE_TITLE = 'page_title',
  PHONE_NUMBER = 'phone_number',
  PREV_BANK = 'prev_bank',
  PREV_COUNTRY = 'prev_country',
  PREV_CURRENCY = 'prev_currency',
  PREV_ITEM_NAME = 'prev_item_name',
  PREV_SOURCE = 'prev_source',
  REF_ID = 'ref_id',
  REFERRAL = 'referral',
  REFERRER = 'referrer',
  SECTION = 'section',
  SERVICE = 'service',
  SOCMED = 'socmed',
  SOURCE = 'source',
  STATUS = 'status',
  STEP_NAME = 'step_name',
  TAB = 'tab',
  TOPUP_ID = 'topup_id',
  TOTAL_STEP = 'total_step',
  TRANSACTION_ACTION = 'transaction_action',
  TRANSACTION_ID = 'transaction_id',
  TRANSACTION_STATUS = 'transaction_status',
  TYPE = 'type',
  USER_ID = 'user_id',
  VALUE = 'value',
}

export type FoundUsPageType = 'profile' | 'footer';

export type SourceWallet =
  | 'Wallet_History'
  | 'Wallet_Topup'
  | 'Wallet_Withdrawal';

export type SourceWalletHistory = 'View_Topup_Detail' | 'Cancel_Topup';

export enum SourcePages {
  SELECT_PAYMENT_METHOD = 'select_payment_method',
  PAYMENT_DETAILS = 'payment_details',
}

export enum DestinationPages {
  ACTIVITY_TRANSACTION = 'activity_transaction',
}

export enum AtlasAnalyticTriggerNames {
  // ABAD
  CHECK_ACCOUNT = 'check_account',
  CHOOSE_OTHER_BANK = 'choose_other_bank',
  SUBMIT_KYC_ABAD_FAILED = 'submit_kyc_abad_failed',

  // ACTIVITY
  FILTER_ACTIVITY_LIST = 'filter_activity_list',

  // AFFILIATE
  AFFILIATE_INVITE = 'affiliate_invite',
  AFFILIATE_BALANCE_STATEMENT_FILTER = 'affiliate_balance_statement_filter',
  AFFILIATE_CONTACT_US = 'affiliate_contact_us',
  AFFILIATE_JOIN = 'join_affiliate',

  // LOGIN
  LOGIN = 'login',
  LOGIN_SUBMIT_FAILED = 'login_submit_failed',
  RESET_PASSWORD = 'reset_password',

  // PRODUCT
  ANNOUNCEMENT_SECTION = 'announcement_section',
  INFO = 'info',
  CTA_BUTTON = 'cta_button',

  // PROFILE
  FIND_US = 'find_us',

  // REGISTER
  CONTACT_US_SELECTED = 'contact_us_selected',
  CONTACT_US_FORM_STEPPER_VIEW = 'contact_us_form_stepper_view',
  REGISTER = 'register',

  // PAGE VIEW
  PAGE_VIEW = 'page_view',

  // TRANSACTION
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_PAYMENT_INFO_MULTIPLE = 'add_payment_info_multiple',
  ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE = 'add_payment_info_domestic_multiple',
  ADD_PAYMENT_INFO_DOMESTIC_SINGLE = 'add_payment_info_domestic_single',
  ADD_QUOTATION_DETAIL = 'add_quotation_detail',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_CART_MULTIPLE = 'add_to_cart_multiple',
  ADD_TO_CART_DOMESTIC = 'add_to_cart_domestic',
  ADD_TO_CART_DOMESTIC_MULTIPLE = 'add_to_cart_domestic_multiple',
  ADD_TO_CART_DOMESTIC_SINGLE = 'add_to_cart_domestic_single',
  ADD_TO_CART_FAILED = 'failed_add_to_cart',
  ADD_TO_CART_FAILED_MULTIPLE = 'failed_add_to_cart_multiple',
  BACK_BUTTON = 'back_button',
  BEGIN_CHECKOUT = 'begin_checkout',
  BEGIN_CHECKOUT_MULTIPLE = 'begin_checkout_multiple',
  BEGIN_CHECKOUT_DOMESTIC_MULTIPLE = 'begin_checkout_domestic_multiple',
  BEGIN_CHECKOUT_DOMESTIC_SINGLE = 'begin_checkout_domestic_single',
  CANCEL_PURCHASE = 'cancel_purchase',
  CANCEL_PURCHASE_MULTIPLE = 'cancel_purchase_multiple',
  CHECK_PAYMENT_STATUS = 'check_payment_status',
  CHOOSE_BANK = 'choose_bank',
  CONFIRM_PAYMENT = 'purchase',
  PURCHASE_MULTIPLE = 'purchase_multiple',
  DOWNLOAD_INVOICE = 'download_invoice',
  PURCHASE_DOMESTIC_MULTIPLE = 'purchase_domestic_multiple',
  PURCHASE_DOMESTIC_SINGLE = 'purchase_domestic_single',
  REFRESH_TIMER = 'refresh_timer',
  TRANSACTION_SELECTED = 'transaction_selected',
  TRANSACTION_SELECTED_DETAIL = 'transaction_selected_detail',
  VIEW_COUNTRY_CURRENCY = 'view_country_currency',
  VIEW_COUNTRY_CURRENCY_MULTIPLE = 'view_country_currency_multiple',
  VIEW_ITEM = 'view_item',
  TRANSACTION_TRACK = 'transaction_track',
  SHARE_TRACKING = 'share_tracking',
  VIEW_ITEM_MULTIPLE = 'view_item_multiple',

  // VIEW
  PAGE_VIEW_DOMESTIC = 'page_view_domestic',
  NOTIFICATION = 'notification',

  // WALLET
  WALLET_TOPUP = 'wallet_topup',
  WALLET_WITHDRAW = 'wallet_withdraw',
  WALLET_CANCEL_TOPUP = 'wallet_cancel_topup',
}

export interface AtlasAnalyticTriggerPayloads
  extends AbadPayload,
    ActivityPayload,
    AffiliatePayload,
    LoginPayload,
    ProductPayload,
    ProfilePayload,
    RegisterPayload,
    TransactionPayload,
    ViewPayload,
    WalletPayload {}

interface AbadPayload {
  [AtlasAnalyticTriggerNames.CHECK_ACCOUNT]: {
    [Variables.ERROR]: string;
  };
  [AtlasAnalyticTriggerNames.CHOOSE_OTHER_BANK]: {
    [Variables.BANK]: string;
  };
  [AtlasAnalyticTriggerNames.SUBMIT_KYC_ABAD_FAILED]: {
    [Variables.ERROR]: string;
  };
}
interface ActivityPayload {
  [AtlasAnalyticTriggerNames.FILTER_ACTIVITY_LIST]: {
    [Variables.STATUS]: string;
    [Variables.VALUE]: string;
    [Variables.SERVICE]: string;
  };
}
interface AffiliatePayload {
  [AtlasAnalyticTriggerNames.AFFILIATE_BALANCE_STATEMENT_FILTER]: {
    [Variables.TYPE]: string;
    [Variables.DATE_BEFORE]: string;
    [Variables.DATE_AFTER]: string;
  };
  [AtlasAnalyticTriggerNames.AFFILIATE_INVITE]: {
    [Variables.METHOD]: string;
  };
}
interface LoginPayload {
  [AtlasAnalyticTriggerNames.LOGIN]: {
    [Variables.METHOD]: string;
  };
  [AtlasAnalyticTriggerNames.LOGIN_SUBMIT_FAILED]: {
    [Variables.ERROR]: string;
    [Variables.SOURCE]: 'ON_SUBMIT_LOGIN_SCREEN';
    [Variables.METHOD]: IdentifierType | 'unsupported';
  };
  [AtlasAnalyticTriggerNames.RESET_PASSWORD]: {
    [Variables.ERROR]?: string;
  };
}
interface ProductPayload {
  [AtlasAnalyticTriggerNames.ANNOUNCEMENT_SECTION]: {
    [Variables.NAME]: string;
    [Variables.SOURCE]: string;
  };
  [AtlasAnalyticTriggerNames.INFO]: {
    [Variables.TYPE]: string;
    [Variables.SOURCE]: string;
    [Variables.SECTION]: string;
    [Variables.VALUE]: string;
  };
  [AtlasAnalyticTriggerNames.CTA_BUTTON]: {
    [Variables.SOURCE]: string;
    [Variables.TYPE]: string;
    [Variables.NAME]: string;
  };
}
interface ProfilePayload {
  [AtlasAnalyticTriggerNames.FIND_US]: {
    [Variables.SOURCE]: AnalyticPagesNames.FOOTER | AnalyticPagesNames.PROFILE;
    [Variables.SOCMED]: EFoundUsSocialMedia;
  };
}
interface RegisterPayload {
  [AtlasAnalyticTriggerNames.REGISTER]: {
    [Variables.USER_ID]: string;
    [Variables.MEMBER_ID]: string;
    [Variables.PHONE_NUMBER]: string;
  };
  [AtlasAnalyticTriggerNames.CONTACT_US_FORM_STEPPER_VIEW]: {
    [Variables.NAME]: string;
    [Variables.CURRENT_STEP]: number;
  };
  [AtlasAnalyticTriggerNames.CONTACT_US_SELECTED]: never;
}
interface TransactionPayload {
  [AtlasAnalyticTriggerNames.ADD_TO_CART]: { [Variables.REF_ID]: string };
  [AtlasAnalyticTriggerNames.ADD_TO_CART_DOMESTIC]: {
    [Variables.BANK]: string;
    [Variables.PREV_BANK]?: string;
    [Variables.SERVICE]: 'Instant Transfer' | 'Bank Transfer';
    [Variables.AMOUNT]: number;
    [Variables.NOTE]?: string;
  };
  [AtlasAnalyticTriggerNames.ADD_TO_CART_DOMESTIC_SINGLE]: {
    [Variables.REF_ID]: string;
  };
  [AtlasAnalyticTriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE]: {
    [Variables.REF_ID]: string;
  };
  [AtlasAnalyticTriggerNames.ADD_TO_CART_FAILED]: {
    [Variables.REF_ID]?: string;
    [Variables.ERROR]: FailedAddToCartError | string;
    [Variables.TYPE]: TransactionRecipientType;
    [Variables.ITEM_TYPE]: TransactionType;
  };
  [AtlasAnalyticTriggerNames.BEGIN_CHECKOUT]: {
    [Variables.TRANSACTION_ID]: string | number;
    [Variables.TYPE]: TransactionType;
  };
  [AtlasAnalyticTriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE]: {
    [Variables.TRANSACTION_ID]: string;
  };
  [AtlasAnalyticTriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
  };
  [AtlasAnalyticTriggerNames.ADD_PAYMENT_INFO]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: 'BCA' | 'BRI' | 'Mandiri' | 'BNI' | 'Permata' | 'others';
  };
  [AtlasAnalyticTriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType.DOMESTIC;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: string;
  };
  [AtlasAnalyticTriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType.DOMESTIC;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: string;
  };
  [AtlasAnalyticTriggerNames.CHECK_PAYMENT_STATUS]: {
    [Variables.METHOD]: PaymentMethod;
    [Variables.STATUS]: PaymentStatus;
  };
  [AtlasAnalyticTriggerNames.BACK_BUTTON]: {
    [Variables.SOURCE]: SourcePages;
    [Variables.DESTINATION]: DestinationPages;
  };
  [AtlasAnalyticTriggerNames.DOWNLOAD_INVOICE]: {
    [Variables.BATCH_ID]?: string;
    [Variables.TOPUP_ID]?: string;
    [Variables.SOURCE]: PageDownloadInvoice;
    // Note: Type is for download in what page.
    [Variables.TYPE]: DownloadInvoiceType;
    // Note: Download type is consist of invoice or receipt.
    [Variables.DOWNLOAD_TYPE]: DownloadType;
    [Variables.STATUS]: string;
  };
  [AtlasAnalyticTriggerNames.CHOOSE_BANK]: {
    [Variables.REF_ID]?: string;
    [Variables.BATCH_ID]?: string;
    [Variables.STATUS]: string;
    [Variables.BANK]: string;
  };
  [AtlasAnalyticTriggerNames.CONFIRM_PAYMENT]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };

  [AtlasAnalyticTriggerNames.PURCHASE_DOMESTIC_SINGLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };

  [AtlasAnalyticTriggerNames.PURCHASE_DOMESTIC_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };
  [AtlasAnalyticTriggerNames.CANCEL_PURCHASE]: {
    [Variables.SOURCE]:
      | AnalyticPagesNames.TRANSACTION_DETAIL
      | AnalyticPagesNames.TRANSACTION_MAKE_PAYMENT;
  };
  [AtlasAnalyticTriggerNames.CANCEL_PURCHASE_MULTIPLE]: {
    [Variables.SOURCE]:
      | AnalyticPagesNames.TRANSACTION_DETAIL
      | AnalyticPagesNames.TRANSACTION_MAKE_PAYMENT;
  };
  [AtlasAnalyticTriggerNames.ADD_QUOTATION_DETAIL]: {
    [Variables.REF_ID]?: string;
    [Variables.SECTION]: 'service' | 'recipient' | 'phonenumber';
    [Variables.ERROR]: 'service' | 'recipient' | 'phonenumber' | '';
    [Variables.ITEM_TYPE]: TransactionType;
  };
  [AtlasAnalyticTriggerNames.VIEW_ITEM]: {
    [Variables.ITEM_ID]: string;
    [Variables.ITEM_NAME]: string;
    [Variables.ITEM_TYPE]: TransactionType;
    [Variables.PREV_ITEM_NAME]?: string;
  };
  [AtlasAnalyticTriggerNames.VIEW_COUNTRY_CURRENCY]: {
    [Variables.CURRENCY]: string;
    [Variables.COUNTRY]: string;
    [Variables.PREV_COUNTRY]?: string;
    [Variables.PREV_CURRENCY]?: string;
  };
  [AtlasAnalyticTriggerNames.REFRESH_TIMER]: {
    [Variables.REF_ID]?: string;
    [Variables.STATUS]?: TransactionStatus;
  };
  [AtlasAnalyticTriggerNames.TRANSACTION_SELECTED]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TRANSACTION_ACTION]: any;
    [Variables.MEMBER_ID]?: string;
    [Variables.STATUS]: TransactionStatus;
  };
  [AtlasAnalyticTriggerNames.TRANSACTION_SELECTED_DETAIL]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TRANSACTION_ACTION]: any;
    [Variables.MEMBER_ID]?: string;
    [Variables.STATUS]: TransactionStatus;
  };
  [AtlasAnalyticTriggerNames.TRANSACTION_TRACK]: {
    [Variables.REF_ID]: string;
    [Variables.STATUS]: string;
    [Variables.SOURCE]: TransactionTrackSource;
  };
  [AtlasAnalyticTriggerNames.SHARE_TRACKING]: {
    [Variables.REF_ID]: string;
    [Variables.TRANSACTION_STATUS]: string;
    [Variables.METHOD]: ShareTrackingMethod;
  };
  [AtlasAnalyticTriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE]: {
    [Variables.CURRENCY]: string;
    [Variables.COUNTRY]: string;
    [Variables.PREV_COUNTRY]?: string;
    [Variables.PREV_CURRENCY]?: string;
  };
  [AtlasAnalyticTriggerNames.ADD_PAYMENT_INFO_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: BankName;
  };
  [AtlasAnalyticTriggerNames.ADD_TO_CART_MULTIPLE]: {
    [Variables.REF_ID]: string;
  };
  [AtlasAnalyticTriggerNames.PURCHASE_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };
  [AtlasAnalyticTriggerNames.BEGIN_CHECKOUT_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string | number;
    [Variables.TYPE]: TransactionType;
  };
  [AtlasAnalyticTriggerNames.CANCEL_PURCHASE_MULTIPLE]: {
    [Variables.SOURCE]:
      | AnalyticPagesNames.TRANSACTION_DETAIL
      | AnalyticPagesNames.TRANSACTION_MAKE_PAYMENT;
  };
  [AtlasAnalyticTriggerNames.ADD_TO_CART_FAILED_MULTIPLE]: {
    [Variables.REF_ID]?: string;
    [Variables.ERROR]: FailedAddToCartError | string;
    [Variables.TYPE]: TransactionRecipientType;
    [Variables.ITEM_TYPE]: TransactionType;
  };

  [AtlasAnalyticTriggerNames.VIEW_ITEM_MULTIPLE]: {
    [Variables.ITEM_ID]: string;
    [Variables.ITEM_NAME]: string;
    [Variables.ITEM_TYPE]: TransactionType;
    [Variables.PREV_ITEM_NAME]?: string;
  };
}
interface ViewPayload {
  [AtlasAnalyticTriggerNames.PAGE_VIEW_DOMESTIC]: {
    [Variables.SOURCE]: string;
    [Variables.PREV_SOURCE]: string;
  };
  [AtlasAnalyticTriggerNames.PAGE_VIEW]: {
    [Variables.PAGE_TITLE]: string;
    [Variables.PAGE_LOCATION]: string;
    [Variables.PAGE_PATH]: string;
  };
  [AtlasAnalyticTriggerNames.NOTIFICATION]: {
    [Variables.ID]: string;
  };
}
interface WalletPayload {
  [AtlasAnalyticTriggerNames.WALLET_CANCEL_TOPUP]: {
    [Variables.SOURCE]: SourceWalletHistory;
  };
  [AtlasAnalyticTriggerNames.WALLET_TOPUP]: {
    [Variables.SOURCE]: SourceWallet;
  };
  [AtlasAnalyticTriggerNames.WALLET_WITHDRAW]: {
    [Variables.SOURCE]: SourceWallet;
  };
}
