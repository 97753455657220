import { useGetAccountInfo } from '@api-hooks/common';
import * as Sentry from '@sentry/browser';
import {
  isNativeApp,
  sendNativeAnalyticQueue,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { LandingTriggerNames } from '@topremit/shared-web/typings/analytics-landing.model';
import { AnalyticPendingEvents, db } from 'landing/db';
import { useRouter } from 'next/router';
import { createContext, ReactNode, useContext, useEffect } from 'react';

import usePermission from '../use-permission';
import {
  AnalyticsBusiness,
  analyticsBusiness,
} from './business/AnalyticsBusiness';
import TracksBusiness from './business/TracksBusiness';
import {
  AnalyticsPersonal,
  analyticsPersonal,
} from './personal/AnalyticsPersonal';
import TracksPersonal, {
  TrackConfirmPaymentPayload,
} from './personal/TracksPersonal';

interface IAnalyticsProviderProps {
  children: ReactNode;
}

/**
 * P: Personal
 * B: Business
 * Both: Personal and Business
 * P/B: Personal or Business
 */
export type PageViewType = 'P' | 'B' | 'Both' | 'P/B';

type TrackerPersonalBusiness = (TracksPersonal | TracksBusiness) & {
  queuePurchaseEvent(payload: TrackConfirmPaymentPayload): void;
  getQueuePurchaseEvent(): Promise<AnalyticPendingEvents[] | undefined>;
  syncPendingPurchaseEvents(purchasedIds: string[]): Promise<void>;
};

interface IAnalyticsContext {
  analyticsPersonal: AnalyticsPersonal;
  analyticsBusiness: AnalyticsBusiness;
  onPageView: (value?: PageViewType) => void;
  trackerPersonalBusiness?: TrackerPersonalBusiness;
}

const AnalyticsContext = createContext<IAnalyticsContext>({
  analyticsPersonal,
  analyticsBusiness,
  onPageView: () => {},
});

function AnalyticsProvider({ children }: IAnalyticsProviderProps) {
  const { data: accountInfo } = useGetAccountInfo();
  const { isBusinessAccount } = usePermission();
  const router = useRouter();

  const baseTrack = isBusinessAccount
    ? analyticsBusiness.tracksBusiness
    : analyticsPersonal.tracksPersonal;

  const trackerPersonalBusiness = Object.assign(baseTrack, {
    queuePurchaseEvent(payload: TrackConfirmPaymentPayload) {
      if (isNativeApp()) {
        return sendNativeAnalyticQueue({
          params: {
            isBatch: payload.isBatch,
            isDomestic: payload.isDomestic,
            transactionId: payload.transaction_id,
          },
        });
      }
      db.analyticPendingEvents.add({
        name: LandingTriggerNames.CONFIRM_PAYMENT,
        createdAt: new Date(),
        params: payload,
        isBusiness: isBusinessAccount,
      });
    },
    async getQueuePurchaseEvent(): Promise<
      AnalyticPendingEvents[] | undefined
    > {
      try {
        return await db.analyticPendingEvents.toArray();
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setLevel('error');
          scope.setTags({
            section: 'analytics/purchased',
            fnName: 'getQueuePurchaseEvent',
          });
          Sentry.captureException(error);
        });
      }
    },
    async syncPendingPurchaseEvents(completedTrxIds: string[]) {
      try {
        const completedEvents = db.analyticPendingEvents.filter(
          (e) => completedTrxIds?.includes(String(e.params.transaction_id)),
        );

        await completedEvents.each((e) => {
          const {
            isBatch,
            isDomestic,
            transaction_id: trxId,
            isBusiness,
          } = e.params as TrackConfirmPaymentPayload;

          const tracker = isBusiness
            ? analyticsBusiness.tracksBusiness
            : analyticsPersonal.tracksPersonal;

          tracker.purchaseEvent({
            isBatch,
            isDomestic,
            trxId,
          });
        });

        await completedEvents.delete();
      } catch (error) {
        Sentry.withScope((scope) => {
          scope.setLevel('error');
          scope.setTags({
            section: 'analytics/purchased',
            fnName: 'syncPendingPurchaseEvents',
          });
          Sentry.captureException(error);
        });
      }
    },
  });

  function onPageView(pageViewType: PageViewType) {
    if (!pageViewType) {
      return;
    }
    const pageViewParam = {
      page_title: document.title,
      page_location: window.location.href,
      page_path: router.asPath,
    };

    if (pageViewType === 'P') {
      analyticsPersonal.tracksPersonal.pageView({ ...pageViewParam });
    } else if (pageViewType === 'B') {
      analyticsBusiness.tracksBusiness.pageView({ ...pageViewParam });
    } else if (pageViewType === 'Both') {
      analyticsPersonal.tracksPersonal.pageView({ ...pageViewParam });
      analyticsBusiness.tracksBusiness.pageView({ ...pageViewParam });
    } else {
      // Note: (pageViewType === 'P/B')
      if (isBusinessAccount) {
        analyticsBusiness.tracksBusiness.pageView({ ...pageViewParam });
        return;
      }
      analyticsPersonal.tracksPersonal.pageView({ ...pageViewParam });
    }
  }

  useEffect(() => {
    // Note: identify only once because no plugin
    if (accountInfo?.data.userId && accountInfo?.data.email) {
      analyticsPersonal.instance.identify(String(accountInfo.data.userId), {
        email: accountInfo.data.email,
      });
      analyticsBusiness.instance.identify(String(accountInfo.data.userId), {
        email: accountInfo.data.email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo]);

  return (
    <AnalyticsContext.Provider
      value={{
        analyticsPersonal,
        analyticsBusiness,
        onPageView,
        trackerPersonalBusiness,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
}

export default function useAnalytics() {
  return useContext(AnalyticsContext);
}

export { useAnalytics, AnalyticsProvider };
