import DropdownItemChild from '@layouts/DropdownItemChild';
import {
  BankFill,
  BuildingFill,
  CubeFill,
  DollarCoinFill,
  FaqFill,
  FlagFill,
  InterrogationFill,
  List,
  MobileTopUpFill,
  Search,
  SwiftLogo,
} from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { ReactNode } from 'react';

interface Return {
  title: string;
  items: {
    label: ReactNode;
    url: string;
    externalLink?: boolean;
  }[];
}

const primaryBlue = 'var(--blue-500)';

export function useGetNavbarMenu(): Return[] {
  const { t, lang } = useTranslation();

  const products = [
    {
      label: (
        <DropdownItemChild
          icon={<DollarCoinFill fill={primaryBlue} />}
          title={t('international_transfer')}
          description={t('header.products_description.international_transfer')}
        />
      ),
      url: '/',
    },
    {
      label: (
        <DropdownItemChild
          icon={<BankFill fill={primaryBlue} />}
          title={t('local_transfer')}
          description={t('header.products_description.local_transfer')}
        />
      ),
      url: '/local-transfer',
    },
    {
      label: (
        <DropdownItemChild
          icon={<BuildingFill fill={primaryBlue} />}
          title={t('topremit_business')}
          description={t('header.products_description.topremit_business')}
        />
      ),
      url: '/business',
    },
    {
      label: (
        <DropdownItemChild
          icon={<MobileTopUpFill fill={primaryBlue} />}
          title={t('mobile_topup')}
          description={t('header.products_description.mobile_topup')}
        />
      ),
      url: '/mobile-top-up',
    },
    {
      label: (
        <DropdownItemChild
          icon={<SwiftLogo />}
          title={t('swift')}
          description={t('header.products_description.swift')}
        />
      ),
      url: `/swift-transfer`,
    },
  ];

  const resources = [
    {
      label: (
        <DropdownItemChild
          icon={<FlagFill fill={primaryBlue} />}
          title={t('check_available_countries')}
        />
      ),
      url: '/send-money',
    },
    {
      label: (
        <DropdownItemChild
          icon={<List fill={primaryBlue} />}
          title={t('track_transaction')}
        />
      ),
      url: '/track',
    },
    {
      label: (
        <DropdownItemChild
          icon={<Search fill={primaryBlue} />}
          title={t('swift_code_checker')}
        />
      ),
      url: '/swift-code',
    },
    {
      label: (
        <DropdownItemChild
          icon={<CubeFill fill={primaryBlue} />}
          title={t('cbm_calculator')}
        />
      ),
      url: '/cbm-calculator',
    },
    {
      label: (
        <DropdownItemChild
          icon={<FaqFill fill={primaryBlue} />}
          title={t('blog')}
        />
      ),
      url: 'https://blog.topremit.com',
    },
    {
      label: (
        <DropdownItemChild
          icon={<InterrogationFill fill={primaryBlue} />}
          title={t('faq')}
        />
      ),
      url: `https://help.topremit.com/${lang}/support/home`,
    },
  ];

  return [
    {
      title: t('products'),
      items: products,
    },
    {
      title: t('resources'),
      items: resources,
    },
  ];
}
