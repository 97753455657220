import { NotesStatus } from 'shared';

import { LandingTriggerNames as TriggerNames } from './analytics-landing.model';
import {
  AfCustomEvents,
  AfStandartEvents,
  FbCustomEvents,
  FbStandartEvents,
  GaCustomEvents,
} from './analytics-platform.model';
import { S2SEventType, S2SIdentifiers } from './analytics.model';
import { IdType } from './profile.model';

/**
 * Interface representing the bridge between the web app and native mobile web view.
 * this might be temporary use only until mobile app code fully rebase to new project.
 *
 */
export interface NativeWebViewBridge {
  /**
   * Indicates whether the app is being opened in a native mobile web view.
   * - `true` if the app is opened in a native web view.
   *    It's unlikely that false will be shown because from a mobile web view,
   *    it won't be explicitly set to false.
   */
  isNative?: boolean;

  ReactNativeWebView?: {
    /**
     * Sends a message from the web app to the native app.
     * @param payload The paylod to send in the message (usually a JSON string).
     */
    postMessage: (payload: string) => void;
  };
}

type WebViewEventPayload<T> = {
  eventName: T | TriggerNames;
  payload?: { [key: string]: string | number | undefined };
};
export interface NativeWebviewTrackConfig {
  s2s?: {
    eventType: S2SEventType;
    payload?: S2SIdentifiers;
  };
  fb?: WebViewEventPayload<FbCustomEvents | FbStandartEvents>;
  moe?: WebViewEventPayload<TriggerNames>;
  firebase?: WebViewEventPayload<GaCustomEvents>;
  appsFlyer?: WebViewEventPayload<AfCustomEvents | AfStandartEvents>;
}

export enum NativeWebViewBridgeEventName {
  CLOSE = 'CLOSE',
  BACK = 'BACK',
  RELOAD = 'RELOAD',

  OPEN_KYC_REJECTED_REASON = 'OPEN_KYC_REJECTED_REASON',
  OPEN_KYC_DISCLAIMER = 'OPEN_KYC_DISCLAIMER',
  OPEN_ID_TYPE = 'OPEN_ID_TYPE',
  OPEN_KYC_STEP = 'OPEN_KYC_STEP',

  OPEN_HOMEPAGE = 'OPEN_HOMEPAGE',
  OPEN_ACTIVITIES = 'OPEN_ACTIVITIES',
  OPEN_FRESHCHAT = 'OPEN_FRESHCHAT',
  OPEN_REFUND_DETAIL = 'OPEN_REFUND_DETAIL',
  OPEN_TRANSACTION_DETAIL = 'OPEN_TRANSACTION_DETAIL',
  OPEN_SETTING = 'OPEN_SETTING',
  OPEN_WHATSAPP = 'OPEN_WHATSAPP',

  REFUND_ANALYTICS = 'REFUND_ANALYTICS',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  REFRESH_TOKEN_INVALID = 'REFRESH_TOKEN_INVALID',

  OPEN_PROFILE = 'OPEN_PROFILE',

  ANALYTICS = 'ANALYTICS',
  ANALYTIC_QUEUE_PURCHASE = 'ANALYTIC_QUEUE_PURCHASE',
  OPEN_NOTIF = 'OPEN_NOTIF',
  ALERT = 'ALERT',

  REPLACE_TO_ACTIVITIES = 'REPLACE_TO_ACTIVITIES',
  COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD',

  REFUND_REQUEST_ANALYTICS = 'REFUND_REQUEST_ANALYTICS',

  CREATE_PIN = 'CREATE_PIN',
  CAMERA_PERMISSION = 'CAMERA_PERMISSION',
  REQUEST_CAMERA_PERMISSION = 'REQUEST_CAMERA_PERMISSION',
  FETCH_ME = 'FETCH_ME',

  /**
   * Example:
   *
   * ACTION_1 = "ACTION_1"
   *
   */
}

export enum ActivitiesPage {
  TRANSACTION = 0,
  REFUND = 1,
}

export type NativeWebViewBridgeIncludedEvent =
  | NativeWebViewBridgeEventName.REFRESH_TOKEN
  | NativeWebViewBridgeEventName.REFUND_REQUEST_ANALYTICS
  | NativeWebViewBridgeEventName.FETCH_ME;

export type NativeWebViewBridgeEvent<T extends NativeWebViewBridgeEventName> = {
  [K in T]: K extends keyof NativeWebViewBridgePayload
    ? {
        name: K;
        data: NativeWebViewBridgePayload[K];
      }
    : {
        name: K;
      };
}[T];

export interface NativeWebViewBridgePayload {
  /**
   * Example:
   *
   *  [NativeWebViewBridgeEventName.ACTION_1]: {
   *    someMessage: string;
   *  };
   */
  [NativeWebViewBridgeEventName.ANALYTICS]: NativeWebviewTrackConfig;
  [NativeWebViewBridgeEventName.OPEN_KYC_STEP]: {
    idType: IdType;
  };
  [NativeWebViewBridgeEventName.REFRESH_TOKEN]: {
    refreshToken: string;
    accessToken: string;
    expireIn: number;
  };
  [NativeWebViewBridgeEventName.ALERT]: {
    message: string;
    variant?: NotesStatus;
  };
  [NativeWebViewBridgeEventName.OPEN_REFUND_DETAIL]: {
    refundId: string;
  };
  [NativeWebViewBridgeEventName.OPEN_TRANSACTION_DETAIL]: {
    transactionId: string;
  };
  [NativeWebViewBridgeEventName.REPLACE_TO_ACTIVITIES]: {
    page: ActivitiesPage;
  };
  /**
   * On Android web view navigator.clipboard.writeText required permission
   * which is we can't request the permission on web view
   * so we need workaround to copy to value to the android device
   */
  [NativeWebViewBridgeEventName.COPY_TO_CLIPBOARD]: {
    message: string;
  };
  [NativeWebViewBridgeEventName.REFUND_REQUEST_ANALYTICS]: {
    source?: string;
  };
  [NativeWebViewBridgeEventName.FETCH_ME]: undefined;
}

export enum PermissionValueEnum {
  GRANTED = 'granted',
  DENIED = 'denied',
  UNDETERMINED = 'undetermined',
}

export enum NativeMessageNameEnum {
  DEVICE_PERMISSIONS = 'DEVICE_PERMISSIONS',
  CAMERA_PERMISSION = 'CAMERA_PERMISSION',
}

export interface DevicePermissionsNativeData {
  camera: PermissionValueEnum;
}
export interface ReceiveNativeMessage {
  name?: NativeMessageNameEnum;
  data?: DevicePermissionsNativeData | string;
}
