import { css } from '@emotion/react';

import { screenSize } from '../common/size';

export default css`
  .ant-popover {
    z-index: 1;
    pointer-events: all !important;
    max-width: 21.875rem;
    filter: drop-shadow(0px 0px 40px rgba(46, 72, 101, 0.08));
    min-width: 21rem;
    font-family: var(--font-family);
    @media (max-width: ${screenSize.mobileSm}px) {
      min-width: 19.7rem;
      max-width: 19.7rem;
    }
    .ant-popover-arrow::after {
      background: var(--blue-100);
    }
    .ant-popover-content {
      .ant-popover-inner {
        z-index: 1;
        background-color: var(--neutral-0);
        border-radius: 14px;
        box-shadow:
          0 3px 0px -4px rgb(0 0 0 / 3%),
          0 1px 6px 0 rgb(0 0 0 / 2%),
          0 0px 19px 3px rgb(0 0 0 / 4%);
        padding: 0;
        .ant-popover-title {
          border: 0;
          padding: 0;
        }
        .ant-popover-inner-content {
          padding: 0;
        }
      }
    }
  }
`;
