import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

interface InfiniteStaticListProps<TData = any> {
  options: TData[];
  renderItem: (params: { data: TData; index: number }) => React.ReactNode;

  showSize?: number;
}

export default function InfiniteStaticList<TData = any>(
  props: InfiniteStaticListProps<TData>,
) {
  const [page, setPage] = useState(1);
  const { options, renderItem, showSize = 15 } = props;

  const [list, setList] = useState<TData[]>(
    options?.slice(0, Math.min(1 * showSize, options?.length)),
  );

  useEffect(() => {
    const startIndex = 0;
    const endIndex = Math.min(1 * showSize, options.length);

    setList(options?.slice(startIndex, endIndex));
  }, [options, showSize]);

  const { ref } = useInView({
    triggerOnce: true, // Only trigger once when it comes into view
    threshold: 0.3,
    onChange(inView) {
      if (inView && list.length < options.length) {
        const startIndex = page * showSize;
        const endIndex = Math.min((page + 1) * showSize, options.length);

        setList((prev) => prev.concat(options?.slice(startIndex, endIndex)));
        setPage((prev) => prev + 1);
      }
    },
  });

  return (
    <>
      {list?.map((cur, idx) => {
        if (idx === list?.length - 1) {
          return (
            <div ref={ref} key={`obs-${idx}`}>
              {renderItem({
                data: cur,
                index: idx,
              })}
            </div>
          );
        }

        return renderItem({
          data: cur,
          index: idx,
        });
      })}
    </>
  );
}
