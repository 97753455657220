import { css, cx } from '@emotion/css';
import { screenSize } from '@global-common/size';
import { CommandItem } from '@global-elements-utils/command';
import { useMediaQuery } from '@global-hooks';
import { RadioActive, RadioIcon } from '@global-shapes';
import * as React from 'react';

import { useSelect } from './select-provider';

/**
 *  SelectItem
 *
 * @description
 * `SelectItem` component is a select item that can be used in the `SelectList` component.
 *
 * @param value - The value of the select item.
 * @param keywords - The keywords of the select item that will be used to filter the select items.
 * @param children - The children of the select item.
 * @param className - The class name of the select item.
 *
 * @example
 * <SelectItem value="light" keywords={['terang', 'putih']}>
 *   Light
 * </SelectItem>
 */
export const SelectItem = React.forwardRef<
  React.ElementRef<typeof CommandItem>,
  Omit<React.ComponentPropsWithoutRef<typeof CommandItem>, 'onChange'>
>(({ children, className, value, ...props }, ref) => {
  const { value: selectedValue, onClose, onChange } = useSelect();
  const isMobile = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);
  const isSelected = selectedValue === value;

  return (
    <CommandItem
      ref={ref}
      value={value}
      onSelect={() => {
        onChange?.(value || '');
        onClose();
      }}
      style={{ cursor: 'pointer' }}
      className={cx(styled.item(isSelected), className)}
      {...props}
    >
      <span className="select-item-content">{children}</span>

      {isMobile ? (
        selectedValue === value ? (
          <RadioActive />
        ) : (
          <RadioIcon />
        )
      ) : null}
    </CommandItem>
  );
});

const styled = {
  item: (isSelected: boolean) => css`
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 0.5rem;
    background-color: ${isSelected ? '#F4FAFF' : 'unset'};
    color: ${isSelected ? '#30A6FF' : 'unset'};

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.875rem;
      padding: 1rem 1.5rem;
    }
  `,
};
