import { css } from '@emotion/react';

export default css`
  .ant-input[disabled] {
    cursor: default;
  }

  .ant-input-affix-wrapper {
    padding: 0;
    height: 32px;
  }

  .ant-input-number {
    width: 100%;
    .ant-input-number-handler-wrap {
      display: block;
      user-select: none;
      pointer-events: none;
      .ant-input-number-handler {
        display: none;
      }
      width: 100%;
      opacity: unset;
      background-color: transparent;
    }
    .ant-input-number-input {
      font-family: var(--font-family);
      font-size: 1rem;
      color: var(--text-primary);
    }
    .ant-input-number-input-wrap > .ant-input-number-input,
    .ant-input-number-handler-wrap > .ant-input-number-input {
      padding: 0;
    }
    &.ant-input-number-disabled {
      background-color: transparent;
    }
  }

  .ant-input-suffix {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0.3125rem;
    transform: translate(100%, -50%);
  }

  .ant-input-affix-wrapper {
    position: unset;
  }
`;
