import { useEffect, useState } from 'react';

const useCameraPermission = () => {
  const [permissionStatus, setPermissionStatus] = useState<
    'prompt' | 'granted' | 'denied' | 'no-camera'
  >('prompt');
  const [stream, setStream] = useState<MediaStream | null>(null);

  async function checkCameraAvailability() {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasCamera = devices.some((device) => device.kind === 'videoinput');

      if (!hasCamera) {
        setPermissionStatus('no-camera');
      } else {
        checkPermissionStatus();
      }
    } catch (error) {
      console.error('Gagal memeriksa perangkat kamera:', error);
      setPermissionStatus('no-camera');
    }
  }

  async function checkPermissionStatus() {
    try {
      const permission = await navigator.permissions.query({
        name: 'camera' as PermissionName,
      });
      setPermissionStatus(permission.state);

      permission.onchange = () => setPermissionStatus(permission.state);
    } catch {
      setPermissionStatus('prompt');
    }
  }

  async function requestCameraPermission() {
    if (permissionStatus === 'prompt') {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setStream(mediaStream);
        setPermissionStatus('granted');
      } catch {
        setPermissionStatus('denied');
      }
    }
  }

  async function openCamera() {
    if (permissionStatus === 'granted') {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        setStream(mediaStream);
      } catch (error) {
        console.error('Gagal membuka kamera:', error);
      }
    } else {
      console.warn('Izin kamera belum diberikan atau kamera tidak tersedia');
    }
  }

  function closeCamera() {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  }

  useEffect(() => {
    checkCameraAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    permissionStatus,
    requestCameraPermission,
    openCamera,
    closeCamera,
    stream,
  };
};

export default useCameraPermission;
