import { getBrowserInfo } from '@topremit/shared-web/common/fingerprint';

export const CS_NUMBER = '6282361960569';

export type PrefillTextType =
  | 'transaction'
  | 'refund'
  | 'email'
  | 'account'
  | 'bulk'
  | 'storage_permission';

export type MessageTranslation = {
  en: string;
  id: string;
};

type PrefillTextPayload = {
  [key in PrefillTextType]: MessageTranslation;
};
export function prefillText(payload: string = '-'): PrefillTextPayload {
  const browserInfo = getBrowserInfo();

  const browserVersion = getBrowserInfo({
    versionOnly: true,
  });

  return {
    transaction: {
      en: `Hi, I need help with my transaction. The transaction ID is: ${payload}.\n\nHere are my device details:\n${
        getBrowserInfo() && `Browser info: ${getBrowserInfo()}\n\n`
      }I'd like to ask about `,
      id: `Hai, saya butuh bantuan untuk transaksi saya dengan ID transaksi: ${payload}.\n\nBerikut detail perangkat saya:\n${
        getBrowserInfo() && `Informasi browser: ${getBrowserInfo()}\n\n`
      }Saya mau menanyakan tentang `,
    },
    bulk: {
      en: `Hi, I need help with my transaction. The bulk ID is: ${payload}.\n\nHere are my device details:\n${
        getBrowserInfo() && `Browser info: ${getBrowserInfo()}\n\n`
      }I'd like to ask about `,
      id: `Hai, saya butuh bantuan untuk transaksi saya dengan ID bulk: ${payload}.\n\nBerikut detail perangkat saya:\n${
        getBrowserInfo() && `Informasi browser: ${getBrowserInfo()}\n\n`
      }Saya mau menanyakan tentang `,
    },
    refund: {
      en: `Hi, I need help with my refund. The refund ID is: ${payload}.\n\nHere are my device details:\n${
        getBrowserInfo() && `Browser info: ${getBrowserInfo()}\n\n`
      }I'd like to ask about `,
      id: `Hai, saya butuh bantuan untuk refund saya dengan ID refund: ${payload}.\n\nBerikut detail perangkat saya:\n${
        getBrowserInfo() && `Informasi browser: ${getBrowserInfo()}\n\n`
      }Saya mau menanyakan tentang `,
    },
    email: {
      en: `Hi, I need help with my account. Here's my email: ${payload}\n\nI'd like to ask about `,
      id: `Hai, saya butuh bantuan mengenai akun saya dengan email: ${payload}\n\nSaya mau menanyakan tentang `,
    },
    account: {
      en: `Hi, I need help with my account. Here's my email/phone number: ${payload}`,
      id: `Hai, saya butuh bantuan mengenai akun saya dengan email/nomor HP:${payload} `,
    },
    storage_permission: {
      en: `Hi, I need help with my browser permission issue.\n\nHere are my device details:\n${
        !!browserInfo &&
        `Browser: ${browserInfo}\nVersion: ${browserVersion}\n\n`
      }I'd like to ask about `,
      id: `Hai, saya butuh bantuan untuk masalah perizinan browser.\n\nBerikut detail perangkat saya:\n${
        !!browserInfo &&
        `Informasi browser: ${browserInfo}\nVersion: ${browserVersion}\n\n`
      }Saya mau menanyakan tentang `,
    },
  };
}
