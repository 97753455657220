import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { PickerRef } from 'rc-picker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { IRangePickerInputProps } from './types';
import { callAllFunctions } from '../../../common/helper';
import { screenSize } from '../../../common/size';
import { useMediaQuery, useTranslation } from '../../../hooks';
import { color } from '../../../styles/color';
import { Arrow, Calendar } from '../../shapes';

const DatePickerDateFns = generatePicker<Date>(dateFnsGenerateConfig);
const { RangePicker } = DatePickerDateFns;
const { tabletMd } = screenSize;

export interface RangePickerRef {
  focus(): void;
}

function RangePickerInputRef(
  props: IRangePickerInputProps,
  ref: Ref<RangePickerRef>,
) {
  const isMobileSize = useMediaQuery(`(max-width:${tabletMd}px)`);
  const { t } = useTranslation();
  const pickerRef = useRef<PickerRef>(null);

  const {
    name,
    rules,
    disabled,
    className,
    allowClear,
    helperText,
    format = 'dd MMM yyyy',
    popUpTitle = t('common:choose_date'),
    placeholder = [t('common:start_date_label'), t('common:end_date_label')],
    onBlur,
    onFocus,
    onChange,
    onOpenChange,
    ...resProps
  } = props;

  const formContext = useFormContext();
  const { control } = formContext;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...(rules && rules),
    },
  });

  const [isFocus, setIsFocus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isError = !!error?.message;

  const wrapperRef = useRef<any>(null);
  const inputClass = cx('input-control', styled.inputClass(isError), 'sm-text');

  const separator = <Arrow fill={isError ? color.red500 : color.neutral500} />;

  function handleChange(dates: Date, dateStrings: [string, string]) {
    // If dates is null, can cause unexpected behaviour in the form yup schema
    if (dates === null) {
      field.onChange();
      onChange && onChange(undefined as any, dateStrings);
      return;
    }
    field.onChange(dates);
    onChange && onChange(dates as any, dateStrings);
  }

  function renderCustomPanel(originPanel) {
    return (
      <>
        <div className={styled.titlePopupWrapper}>
          <span className={styled.titlePopup}>{popUpTitle}</span>
        </div>
        {originPanel}
      </>
    );
  }

  useImperativeHandle(ref, () => ({
    focus() {
      pickerRef.current?.focus();
      setIsFocus(true);
    },
  }));

  return (
    <FieldGroup
      bordered
      isFocus={isFocus}
      isError={isError}
      disabled={disabled}
      helper={helperText}
      className={className}
      error={error?.message}
      inputWrapperRef={wrapperRef}
      style={{
        zIndex: isOpen ? 10 : 1,
        minHeight: isOpen && isMobileSize ? '300px' : 0,
      }}
      inputWrapperClassName={styled.inputWrapper}
    >
      <div className="form-control" style={{ display: 'flex' }}>
        <RangePicker
          inputReadOnly
          format={format}
          ref={pickerRef}
          bordered={false}
          disabled={disabled}
          value={field.value}
          separator={separator}
          className={inputClass}
          allowClear={allowClear}
          placeholder={placeholder}
          popupClassName={styled.popUp}
          getPopupContainer={() => wrapperRef.current}
          suffixIcon={
            <Calendar
              width={16}
              height={16}
              fill={`${
                isError && !disabled ? 'var(--red-500)' : 'var(--neutral-500)'
              }`}
            />
          }
          onBlur={callAllFunctions(onBlur, () => setIsFocus(false))}
          onFocus={callAllFunctions(onFocus, () => setIsFocus(true))}
          panelRender={renderCustomPanel}
          onChange={handleChange}
          onOpenChange={callAllFunctions(onOpenChange, (open) =>
            setIsOpen(open),
          )}
          {...(resProps as any)}
        />
      </div>
    </FieldGroup>
  );
}

const RangePickerInput = forwardRef<RangePickerRef, IRangePickerInputProps>(
  RangePickerInputRef,
);

const styled = {
  inputWrapper: css`
    min-height: 3.875rem !important;
    border: none;
    height: 100%;
    .ant-picker-dropdown {
      width: fit-content;
      > .ant-picker-range-wrapper {
        min-width: unset !important;
      }
    }
    .ant-picker-panels {
      @media (max-width: ${screenSize.tabletSm}px) {
        flex-direction: column;
        z-index: 1000;
      }
    }
  `,
  inputClass: (isError: boolean) => css`
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    .ant-picker-input {
      input {
        ::placeholder {
          color: ${isError ? 'var(--red-500)' : 'var(--neutral-500)'};
        }
      }
    }

    .ant-picker-clear {
      margin-left: 0.5rem;
    }

    .ant-picker-range-separator {
      margin-right: 1rem !important;
    }
  `,
  popUp: css`
    inset: 62.3px auto auto 0px !important;
    .ant-picker-cell-in-view.ant-picker-cell-range-start
      .ant-picker-cell-inner {
      background-color: var(--blue-500) !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: var(--blue-500) !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today
      .ant-picker-cell-inner::before {
      border: 1px solid var(--blue-500) !important;
    }

    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
        .ant-picker-cell-range-start-single
      )::before,
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
        .ant-picker-cell-range-end-single
      )::before,
    .ant-picker-time-panel-column
      > li.ant-picker-time-panel-cell-selected
      .ant-picker-time-panel-cell-inner {
      background-color: var(--blue-100) !important;
    }
    @media (max-width: ${screenSize.tabletSm}px) {
      width: 100% !important;
      .ant-picker-panel-container {
        width: 100% !important;
        max-height: 300px !important;
        overflow-y: scroll;
        .ant-picker-panel-layout {
          > div {
            width: 100% !important;
            > div {
              width: 100% !important;
              .ant-picker-date-panel {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  `,
  titlePopupWrapper: css`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
  `,
  titlePopup: css`
    text-align: center;
    color: var(--text-primary);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  `,
};

export default RangePickerInput;
