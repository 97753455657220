import { ClassNamesArg, css, cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode; // <VStepperHeader />, <VStepperTitle />, <VStepperDescription />

  className?: ClassNamesArg;
}

export default function VStepperContent({ children, className }: Props) {
  return (
    <Flex column className={cx(styled.root, className)}>
      {children}
    </Flex>
  );
}

const styled = {
  root: css`
    /** expected has padding-bottom */
    padding-bottom: 1rem;
  `,
};
