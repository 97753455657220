import { SVGProps } from 'react';
const Thunder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9}
    height={12}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#30A6FF"
        d="M4.154 11.469c-.152 0-.302-.034-.44-.097a1.003 1.003 0 0 1-.508-.494.977.977 0 0 1-.049-.7l.725-2.355H2.175c-.217 0-.432-.05-.626-.145a1.386 1.386 0 0 1-.493-.407A1.355 1.355 0 0 1 .841 6.06l1.403-4.558a1.36 1.36 0 0 1 .503-.704c.24-.175.532-.268.832-.267h1.713a1.224 1.224 0 0 1 .99.51 1.172 1.172 0 0 1 .14 1.09L5.64 4.177h1.187c.253 0 .5.068.717.196.217.128.394.311.513.53a1.347 1.347 0 0 1-.078 1.409l-2.97 4.694a1.036 1.036 0 0 1-.855.463Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.781.531H8.22V11.47H.781z" />
      </clipPath>
    </defs>
  </svg>
);
export default Thunder;
