import { ConfigProvider } from 'antd';

import { SKELETON_CONSTANT } from './constant/Skeleton';

interface Props {
  children: React.ReactNode;
}

export default function AntdTheme({ children }: Props) {
  return (
    <ConfigProvider
      theme={{
        components: {
          Skeleton: SKELETON_CONSTANT,
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
}
