import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { setItemToCookies } from '../common/helper';

export default function useChangeLocale() {
  const router = useRouter();
  return useCallback(
    (locale: string) => {
      setItemToCookies('NEXT_LOCALE', locale);
      router.replace(
        {
          pathname: router.pathname,
          query: router.query,
        },
        router.asPath,
        { locale },
      );
      // Freshchat Change Language
      if (window?.fcWidget?.isInitialized()) {
        window.fcWidget.user.setLocale(locale);
      }
    },
    [router],
  );
}
