import { css, cx } from '@emotion/css';
import { zIndex } from '@global-common/size';
import { BottombarProvider } from '@global-hooks';
import { Children, cloneElement } from 'react';

export default function BottomBar({ children }) {
  const childrenCount = Children.count(children.filter((child) => child.key));
  const maxWidthClass = css`
    max-width: ${100 / childrenCount}%;
  `;
  return (
    <nav className={cx(styled.nav, 'bottom-bar')}>
      <div className={styled.menu(childrenCount)}>
        <BottombarProvider>
          {Children.map(children, (child) =>
            cloneElement(child, {
              className: cx(maxWidthClass, child.className),
            }),
          )}
        </BottombarProvider>
      </div>
    </nav>
  );
}

const styled = {
  nav: css`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 77px;
    box-shadow: 0px 0px 8px rgba(46, 72, 101, 0.1);
    background: var(--neutral-0);
    padding-left: var(--dashboard-layout-outer-space);
    padding-right: var(--dashboard-layout-outer-space);
    z-index: ${zIndex.layout};
  `,
  menu: (childrenCount) => css`
    display: grid;
    grid-template-columns: repeat(${childrenCount}, minmax(0, 1fr));
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
  `,
};
