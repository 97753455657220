import { css } from '@emotion/css';
import { useAuth } from '@hooks';
import { Button } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

export default function Logout() {
  const { t } = useTranslation('dashboard');
  const { logout } = useAuth();

  const closeDialog = useDialogStore((store) => store.close);

  return (
    <div className={styled.root}>
      <h4>{t('logout_now')}</h4>
      <span>{t('logout_message')}</span>
      <Button
        fullWidth
        className="btn-logout"
        onClick={() => {
          logout();
          closeDialog();
        }}
      >
        {t('logout_yes')}
      </Button>
      <Button
        fullWidth
        type="invert"
        className="btn-cancel"
        onClick={closeDialog}
      >
        {t('common:cancel')}
      </Button>
    </div>
  );
}

const styled = {
  root: css`
    text-align: center;
    button {
      width: 100%;
    }
    .btn-logout {
      margin-top: 1.5rem;
    }
    .btn-cancel {
      margin-top: 1rem;
    }
  `,
};
