import { ClassNamesArg, css, cx } from '@emotion/css';
import { zIndex, navbarSize, screenSize } from '@global-common/size';
import { ReactNode } from 'react';

export const TOPBAR_HEIGHT = 70; //px

interface TopBarProps {
  children?: ReactNode;
  contentClassname?: ClassNamesArg;
}

export default function TopBar({ children, contentClassname }: TopBarProps) {
  return (
    <header className={styled.header}>
      <nav className={cx(styled.nav, contentClassname)}>{children}</nav>
    </header>
  );
}

const styled = {
  header: css`
    position: relative;
    background: var(--neutral-0);
    z-index: ${zIndex.layout};
  `,
  nav: css`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid var(--neutral-200);
    background: var(--neutral-0);
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    position: fixed;
    top: 0;
    left: 0;
    height: ${navbarSize.md}px;
    @media (max-width: ${screenSize.tabletMd}px) {
      height: ${navbarSize.sm}px;
    }
  `,
};
