import { css, cx } from '@emotion/css';
import Image, { ImageProps } from 'next/image';

type TopMenuLogoProps = ImageProps;

export default function TopMenuLogo({ className, ...props }: TopMenuLogoProps) {
  return (
    <div className={cx(styled.logo, className)}>
      <Image loading="eager" {...props} />
    </div>
  );
}

const styled = {
  logo: css`
    position: absolute;
    left: var(--dashboard-layout-outer-space);
    cursor: pointer;
  `,
};
