import { useGetAccountInfo } from '@api-hooks/common';
import { requestFn } from '@common/client';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  callAllFunctions,
  formatDate,
} from '@topremit/shared-web/common/helper';
import { useNotification } from '@topremit/shared-web/hooks';
import { UseMutationOptions, useMutation } from 'react-query';

import {
  CancelTopupParams,
  TopUpDetail,
  TopupParams,
  UploadReceiptParams,
  WithdrawalDetail,
  WithdrawParams,
} from './wallet.model';

export function useCreateTopUp(
  options?: UseMutationOptions<ApiResult<TopUpDetail>, ApiError, TopupParams>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useMutation<ApiResult<TopUpDetail>, ApiError, TopupParams>(
    ['create-top-ups'],
    async (param: TopupParams) =>
      await requestFn(
        {
          path: 'top-ups',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    {
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useCancelTopUp(
  options?: UseMutationOptions<ApiResult, ApiError, CancelTopupParams>,
) {
  const { addNotification } = useNotification();
  const { onError, onSuccess, ...resOption } = options || {};
  return useMutation<ApiResult, ApiError, CancelTopupParams>(
    ['create-top-ups'],
    async (param: CancelTopupParams) =>
      await requestFn(
        {
          path: `top-ups/${param.id}/cancel`,
          method: 'post',
        },
        {
          json: param,
        },
      ),
    {
      onSuccess: callAllFunctions(onSuccess, ({ message }) => {
        addNotification({ message, type: 'success' });
      }),
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useDownloadTopUpReceipt(
  options?: UseMutationOptions<{ blob: BlobPart }, ApiError, { id: string }>,
) {
  const { addNotification } = useNotification();
  const { onError, onSuccess, ...resOption } = options || {};
  // Note:doesn't wrap ApiResult, cause by backend that doesn't wrap with data
  const response = useMutation<{ blob: BlobPart }, ApiError, { id: string }>(
    ['top-ups', 'topup-receipt'],
    async (param: { id: string }) =>
      await requestFn({
        path: `top-ups/${param.id}/receipt`,
        method: 'get',
      }),
    {
      onSuccess: callAllFunctions(onSuccess, ({ message }) => {
        addNotification({ message, type: 'success' });
      }),
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
  return response;
}

export function useUploadReceipt(
  options?: UseMutationOptions<ApiResult, ApiError, UploadReceiptParams>,
) {
  return useMutation<ApiResult, ApiError, UploadReceiptParams>(
    async (param: UploadReceiptParams) => {
      const { attachments } = param;
      // Note: This will be filtered only attachments send to BE
      const attachmentsUrl = attachments
        .map((file) => file.url)
        .filter(Boolean);

      return await requestFn(
        {
          path: `top-ups/${param.id}/confirm-payment`,
          method: 'post',
        },
        {
          json: { attachments: attachmentsUrl },
        },
      );
    },
    options,
  );
}

export function useCreateWithdraw(
  options?: UseMutationOptions<
    ApiResult<WithdrawalDetail>,
    ApiError,
    WithdrawParams
  >,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useMutation<ApiResult<WithdrawalDetail>, ApiError, WithdrawParams>(
    ['create-withdraw'],
    async (param: WithdrawParams) =>
      await requestFn(
        {
          path: 'withdraws',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    {
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useDownloadWalletHistory(
  options?: UseMutationOptions<
    { blob: Blob; fileName: string },
    ApiError,
    { date: string[] }
  >,
) {
  const { addNotification } = useNotification();
  const { data: accountInfo } = useGetAccountInfo();
  const { onError, onSuccess, ...resOption } = options || {};
  const response = useMutation<
    { blob: Blob; fileName: string },
    ApiError,
    { date: string[] }
  >(
    ['wallet-history', 'download'],
    async (param: { date: string[] }) => {
      const [startDate, endDate] = param.date || [];
      return await requestFn(
        {
          path: `wallets/export`,
          method: 'get',
        },
        {
          searchParams: {
            'filter[created_at_after]': `${formatDate({
              date: startDate,
              timeZone: accountInfo?.data.timezone,
              showTimeZoneName: true,
            })}`,
            'filter[created_at_before]': `${formatDate({
              date: endDate,
              timeZone: accountInfo?.data.timezone,
              showTimeZoneName: true,
            })}`,
          },
        },
      );
    },
    {
      onSuccess: callAllFunctions(onSuccess, ({ message }) => {
        addNotification({ message, type: 'success' });
      }),
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
  return response;
}

export function useActivateAutoWithdraw(
  options?: UseMutationOptions<null, ApiError>,
) {
  return useMutation<null, ApiError>(
    () =>
      requestFn({
        path: 'auto-withdrawal/activate',
        method: 'patch',
      }),
    options,
  );
}

export function useSkipAutoWithdraw(
  options?: UseMutationOptions<null, ApiError>,
) {
  return useMutation<null, ApiError>(
    () =>
      requestFn({
        path: 'auto-withdrawal/skip',
        method: 'patch',
      }),
    options,
  );
}
