import { css } from '@emotion/css';
import { ReactNode } from 'react';

import { Badge } from './Badge';
import Chip from './Chip';
import { useFilter, useTranslation } from '../../hooks';
import { useDialogStore } from '../../stores';
import { FilterOutline } from '../shapes';

interface Props {
  dialog: ReactNode;
  isLoading?: boolean;
  isSelected?: boolean;
  label?: string;
}

export default function FilterButton({
  dialog,
  isLoading,
  isSelected,
  label,
}: Props) {
  const { t } = useTranslation();
  const showDialog = useDialogStore((store) => store.show);

  const { filterCount } = useFilter();

  const isFilterApplied = filterCount > 0;

  function onClick() {
    showDialog({
      className: styled.filterModal,
      body: dialog,
    });
  }

  return (
    <Chip
      isDisabled={isLoading}
      label={label || t('common:filter')}
      isSelected={isSelected}
      rightIcon={
        isFilterApplied && (
          <Badge count={filterCount} color="blue" style={{ margin: 0 }} />
        )
      }
      leftIcon={<FilterOutline size={24} fill="var(--neutral-500)" />}
      onClick={onClick}
    />
  );
}

const styled = {
  filterModal: css`
    max-width: 42.625rem;
  `,
};
