import { ClassNamesArg } from '@emotion/css';
import Divider, { DividerColor } from '@global-elements-utils/Divider';

type Type = 'solid' | 'dashed';
type Color = 'blue' | 'gray' | 'lightGray';

const dividerColor: Record<Color, DividerColor> = {
  blue: 'blue500',
  gray: 'neutral200',
  lightGray: 'neutral200',
};

interface Props {
  type?: Type;
  color?: Color;
  className?: ClassNamesArg;
}

export default function HStepperDivider({
  type = 'solid',
  color = 'gray',
  className,
  ...props
}: Props) {
  return (
    <Divider variant={type} color={dividerColor[color]} size={2} {...props} />
  );
}
