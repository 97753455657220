import { css } from '@emotion/react';

export default css`
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    width: fit-content;
    &:not(.disabled):hover {
      .ant-radio-inner {
        border-color: var(--blue-500);
      }
    }
    .ant-radio-inner {
      width: 1.5rem;
      height: 1.5rem;
      ::after {
        width: 0.6875rem;
        height: 0.6875rem;
      }
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: var(--blue-500);
      background: var(--blue-500);
      &::after {
        transform: translate(-50%, -50%);
      }
    }
  }

  .ant-radio-button-wrapper {
    color: var(--text-primary);
  }

  .ant-radio {
    top: 0;
    .ant-radio-input {
      width: 100%;
      height: 100%;
    }
    .ant-radio-inner {
      &::after {
        transform: scale(1) translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: calc(100% / 2);
        height: calc(100% / 2);
        margin-left: 0;
        margin-top: 0;
      }
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: var(--blue-500);
      background-color: var(--blue-500);
      &::after {
        background-color: var(--neutral-0);
      }
    }
  }

  .ant-radio-wrapper-disabled {
    * {
      cursor: default !important;
    }
    cursor: default;
  }

  .ant-radio-disabled {
    * {
      cursor: default !important;
    }
  }
`;
