import { css } from '@emotion/css';
import { useGetNavbarMenu } from '@hooks';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
  Flex,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { useRouter } from 'next/router';

import NavLink from './NavLink';

interface DesktopNavbarMenuProps {
  hasBackground?: boolean;
}

export default function DesktopNavbarMenu({
  hasBackground = true,
}: DesktopNavbarMenuProps) {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const menus = useGetNavbarMenu();

  return (
    <Flex className={styled.menuWrapper}>
      {menus.map(({ title, items }, idx) => {
        return (
          <Dropdown key={idx}>
            <DropdownButton
              className={styled.button(hasBackground)}
              hasBackground={hasBackground}
            >
              {title}
            </DropdownButton>
            <DropdownList
              totalItem={items.length}
              className={styled.dropdownOption}
            >
              {items.map((item, idx) => (
                <DropdownItem
                  key={idx}
                  href={item.url}
                  externalLink={item.externalLink}
                >
                  {item.label}
                </DropdownItem>
              ))}
            </DropdownList>
          </Dropdown>
        );
      })}
      <NavLink
        href="/about-us"
        isActive={pathname === '/about-us'}
        className={styled.button(hasBackground || pathname === '/about-us')} // should return true when accessing the About Us page
      >
        {t('about_us')}
      </NavLink>
    </Flex>
  );
}

const styled = {
  menuWrapper: css`
    width: unset;
    > :not(:last-child) {
      margin-right: 0.5rem;
    }
  `,
  button: (hasBackground?: boolean) => css`
    background-color: ${hasBackground ? '' : 'transparent'};
    color: ${hasBackground ? '' : color.neutral0};
  `,
  dropdownOption: css`
    .dropdown-item {
      width: 19.75rem;
      .dropdown-item-detail-wrapper {
        .icon {
          width: unset;
          margin-right: 1rem;
        }
        .dropdown-item-detail {
          align-items: flex-start;
          color: var(--neutral-800);
          flex: 1;
          .description {
            margin-top: 0.25rem;
            color: var(--neutral-500);
          }
        }
      }
    }
  `,
};
