import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import getConfig from 'next/config';
import Image from 'next/image';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { useRef, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { IDatePickerInputProps } from './types';
import { callAllFunctions } from '../../../common/helper';
import { screenSize } from '../../../common/size';
import { useTranslation } from '../../../hooks';
import { Calendar } from '../../shapes';

const DatePickerDateFns = generatePicker<Date>(dateFnsGenerateConfig);

const { publicRuntimeConfig } = getConfig();

function DatePickerInput(props: IDatePickerInputProps) {
  const { t } = useTranslation();

  const {
    name,
    rules,
    label,
    value,
    disabled,
    className,
    allowClear,
    helperText,
    format = 'dd MMM yyyy',
    popUpTitle = t('common:choose_date'),
    onBlur,
    onFocus,
    onChange,
    defaultPickerValue,
    ...resProps
  } = props;

  const formContext = useFormContext();
  const { control } = formContext;

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...(rules && rules),
    },
  });

  const [isFocus, setIsFocus] = useState(false);

  const isFilled = !!field.value || !!value;
  const isError = !!error?.message;

  const wrapperRef = useRef<any>(null);
  const inputClass = cx('input-control', styled.inputClass(isError), 'sm-text');

  const formControlClass = cx('form-control', styled.datePicker, {
    focus: isFocus,
    filled: isFilled,
    error: isError,
  });

  const separator = (
    <Image
      src={`${
        publicRuntimeConfig?.staticFolder
          ? publicRuntimeConfig?.staticFolder
          : ''
      }/images/svg/arrow-right.svg`}
      width={16}
      height={16}
      alt="arrow right"
    />
  );

  function handleChange(dates: Date, dateStrings: [string, string]) {
    // If dates is null, can cause unexpected behaviour in the form yup schema
    if (dates === null) {
      field.onChange('');
      onChange && onChange('' as any, dateStrings);
      return;
    }
    field.onChange(dates);
    onChange && onChange(dates as any, dateStrings);
  }

  function renderCustomPanel(originPanel) {
    return (
      <>
        <div className={styled.titlePopupWrapper}>
          <span className={styled.titlePopup}>{popUpTitle}</span>
        </div>
        {originPanel}
      </>
    );
  }

  return (
    <FieldGroup
      bordered
      label={label}
      isFilled={isFilled}
      isFocus={isFocus}
      isError={isError}
      disabled={disabled}
      helper={helperText}
      className={className}
      error={error?.message}
      inputWrapperRef={wrapperRef}
      style={{ zIndex: isFocus ? 10 : 1 }}
      inputWrapperClassName={styled.inputWrapper}
    >
      <div className={formControlClass}>
        <DatePickerDateFns
          format={format}
          value={field.value}
          placeholder=""
          disabled={disabled}
          variant="borderless"
          separator={separator}
          className={inputClass}
          allowClear={allowClear}
          popupClassName={styled.popUp}
          getPopupContainer={() => wrapperRef.current}
          suffixIcon={
            <Calendar
              width={16}
              height={16}
              fill={`${
                isError && !disabled ? 'var(--red-500)' : 'var(--neutral-500)'
              }`}
            />
          }
          onBlur={callAllFunctions(onBlur, () => setIsFocus(false))}
          onFocus={callAllFunctions(onFocus, () => setIsFocus(true))}
          panelRender={renderCustomPanel}
          onChange={handleChange}
          inputReadOnly
          defaultPickerValue={defaultPickerValue}
          {...(resProps as any)}
        />
      </div>
    </FieldGroup>
  );
}

const styled = {
  inputWrapper: css`
    min-height: 3.875rem !important;
    border: none;
    height: 100%;
    .ant-picker-dropdown {
      width: fit-content;
      > .ant-picker-range-wrapper {
        min-width: unset !important;
      }
    }
    .ant-picker-panels {
      @media (max-width: ${screenSize.tabletSm}px) {
        flex-direction: column;
        z-index: 1000;
      }
    }
  `,

  // eslint-disable-next-line handle-callback-err
  inputClass: (isError: boolean) => css`
    color: var(--text-primary);
    .ant-picker-input {
      input {
        margin-top: 20px;
        ::placeholder {
          color: ${isError ? 'var(--red-500)' : 'var(--neutral-500)'};
        }
      }
      > .ant-picker-suffix,
      > .ant-picker-clear {
        margin-right: 1rem;
      }
    }
  `,
  datePicker: css`
    transition: padding ease 0.2s;
    padding: 15px 20px 15px 20px;
  `,
  popUp: css`
    inset: 72.3px auto auto 0px !important;

    .ant-picker-cell-inner::before {
      border: 1px solid var(--blue-500) !important;
    }
    .ant-picker-cell-selected {
      .ant-picker-cell-inner {
        background: var(--blue-500) !important;
      }
    }

    @media (max-width: ${screenSize.tabletSm}px) {
      width: 100% !important;
      .ant-picker-panel-container {
        width: 100% !important;
        .ant-picker-panel-layout {
          > div {
            width: 100% !important;
            > div {
              width: 100% !important;
              .ant-picker-date-panel {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  `,
  titlePopupWrapper: css`
    display: flex;
    margin-top: 1rem;
    justify-content: center;
  `,
  titlePopup: css`
    text-align: center;
    color: var(--text-primary);
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  `,
};

export default DatePickerInput;
