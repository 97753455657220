import Dexie, { type EntityTable } from 'dexie';

interface AnalyticPendingEvents {
  id: number;
  name: string;
  params: { [key: string]: string | number | boolean };
  createdAt: Date;
  /**
   * stored in the database when in business / personal account.
   */
  isBusiness: boolean;
}

interface ImageKycData {
  id: string;
  image: string | Blob;
  type: 'selfie' | 'identity';
}

const IDB_VERSION = 1;

const db = new Dexie('TopremitIDB') as Dexie & {
  analyticPendingEvents: EntityTable<
    AnalyticPendingEvents,
    'id' // primary key "id" (for the typings only)
  >;
  imageKycData: EntityTable<ImageKycData, 'id'>; // Add ImageKycData table with "id" as primary key
};

// Schema declaration:
db.version(IDB_VERSION).stores({
  analyticPendingEvents: '++id, name, params, createdAt, isBusiness', // primary key "id" (for the runtime!)
  imageKycData: 'id, type, uploadedAt',
});

export type { AnalyticPendingEvents, ImageKycData };
export { db, IDB_VERSION };
