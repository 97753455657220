import { css } from '@emotion/react';

export default css`
  .ant-table-wrapper {
    table {
      .ant-table-placeholder > td:first-of-type {
        width: 100%;
      }
    }
    .ant-table {
      color: var(--text-primary);
    }
    .ant-table-thead > tr > th {
      color: var(--text-primary);
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: unset;
    }

    .ant-spin-nested-loading {
      > div {
        > .ant-spin {
          max-height: unset;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .ant-pagination {
      .ant-pagination-item {
        font-family: var(--font-family);
      }
      > li {
        &:hover,
        .ant-pagination-item-link {
          color: #30a6ff;
          border-color: transparent;
        }
        > button.ant-pagination-item-link {
          border: 0.0625rem solid transparent;
        }
        .ant-pagination-item {
          background-color: var(--red-500);
        }

        border: 0.0625rem solid transparent;
        &.ant-pagination-item-active {
          > a {
            color: var(--neutral-0);
          }
          border: 0.0625rem solid #ffffff;
          box-sizing: border-box;
          border-radius: 6.25rem;
          background-color: #30a6ff;
        }
      }
    }
  }

  .custom-table-card-list {
    table {
      display: flex;
      table-layout: fixed;
      > tbody {
        display: grid;
        row-gap: 1.25rem;
        width: 100%;
      }
      tr {
        .ant-table-cell {
          :first-of-type {
            vertical-align: top;
            border-left: 1px solid rgba(46, 72, 101, 0.1);
            border-top-left-radius: 0.875rem;
            border-bottom-left-radius: 0.875rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          :last-child {
            border-right: 1px solid rgba(46, 72, 101, 0.1);
            border-top-right-radius: 0.875rem;
            border-bottom-right-radius: 0.875rem;
          }
          border-top: 1px solid rgba(46, 72, 101, 0.1);
          border-bottom: 1px solid rgba(46, 72, 101, 0.1);
        }

        &:hover > .ant-table-cell,
        &.ant-table-row-selected > .ant-table-cell {
          background-color: transparent;
        }
      }
    }
  }

  .custom-table-expand-list {
    width: 100%;
    height: auto;

    table {
      position: relative;
      display: flex;
      flex-direction: column;
      border-collapse: separate;
      .ant-table-thead {
        tr {
          border-bottom: 1px solid rgba(46, 72, 101, 0.1);
          > .ant-table-cell {
            background: transparent;
            border: none;

            padding: 5px 1rem 5px 1rem;
          }
          > .ant-table-row-expand-icon-cell {
            display: none !important;
          }
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          border-bottom: 1px solid rgba(46, 72, 101, 0.1);
          &:hover > td,
          &.ant-table-row-selected > .ant-table-cell {
            background-color: transparent;
          }

          .ant-table-cell {
            border: none;
            padding: 1.25rem 1rem 1.25rem 1rem;
          }

          > .ant-table-row-expand-icon-cell {
            display: none !important;
          }
        }
        .ant-table-expanded-row {
          border-bottom: 1px solid rgba(46, 72, 101, 0.1);
          .ant-table-cell {
            border-bottom: 0;
            padding: 0px 16px 16px 60px;
            background-color: transparent;
          }
        }

        .ant-table-placeholder > td:first-of-type {
          grid-column: 1 / max;
        }
      }
      .no-border-bottom {
        border: none !important;
      }
    }
  }

  .custom-table-default {
    table {
      display: flex;
      table-layout: fixed;
      > tbody {
        display: grid;
        row-gap: 1.25rem;
        width: 100%;
      }
      tr {
        .ant-table-cell {
          :first-of-type {
            vertical-align: top;
            border-left: 1px solid rgba(46, 72, 101, 0.1);
            border-top-left-radius: 0.875rem;
            border-bottom-left-radius: 0.875rem;
            padding-left: 1rem;
            padding-right: 1rem;
          }

          :last-child {
            border-right: 1px solid rgba(46, 72, 101, 0.1);
            border-top-right-radius: 0.875rem;
            border-bottom-right-radius: 0.875rem;
          }
          border-top: 1px solid rgba(46, 72, 101, 0.1);
          border-bottom: 1px solid rgba(46, 72, 101, 0.1);
        }

        &:hover > .ant-table-cell,
        &.ant-table-row-selected > .ant-table-cell {
          background-color: transparent;
        }
      }
    }
  }
`;
