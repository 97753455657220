import dynamic from 'next/dynamic';

export const AddBankAccountDetails = dynamic(
  () => import('./AddBankAccountDetails'),
);
export const AddBankAccountDetailsForm = dynamic(
  () => import('./AddBankAccountDetailsForm'),
);
export const AddOtherBankAccountDetailsForm = dynamic(
  () => import('./AddOtherBankAccountDetailsForm'),
);
export const BankAccountDetails = dynamic(() => import('./BankAccountDetails'));
export const VerifyingBankAccountDetails = dynamic(
  () => import('./VerifyingBankAccountDetails'),
);
export const BankAccountSettingsModal = dynamic(
  () => import('./BankAccountSettingsModal'),
);
