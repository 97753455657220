import { css, cx } from '@emotion/css';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import {
  Children,
  cloneElement,
  ReactElement,
  useState,
  useEffect,
  memo,
  ReactNode,
} from 'react';

import SideMenuGroupTitle from './SideMenuGroupTitle';
import SideMenuItem from './SideMenuItem';
import {
  ISideMenuGroupProviderValue,
  SideMenuGroupProvider,
  useSidebar,
} from '../../../../hooks';
import { AnimatedWrapper } from '../../../elements';
import { Angle } from '../../../shapes';

interface ISideMenuGroupBag
  extends Omit<ISideMenuGroupProviderValue, 'setIsActive' | 'setIsExpand'> {}

interface SideMenuGroupProps {
  children:
    | ((args: ISideMenuGroupBag) => ReactElement | ReactElement[])
    | ReactElement
    | ReactElement[];
  groupURLPath?: string;
  bordered?: boolean;
  category?: ReactNode;
  categoryArrow?: boolean;
  hasSubmenu?: boolean;
  className?: string;
}

function SideMenuGroup({
  children,
  groupURLPath,
  bordered,
  category,
  categoryArrow = false,
  hasSubmenu = true,
  className,
}: SideMenuGroupProps) {
  const router = useRouter();
  const [{ isExpand: isExpandSidebar }, { setIsExpand: setIsExpandSidebar }] =
    useSidebar();
  const isMenuActive =
    !!groupURLPath && new RegExp(groupURLPath).test(router.asPath);
  const [isActive, setIsActive] = useState<boolean>(isMenuActive);
  const [isExpand, setIsExpand] = useState<boolean>(isMenuActive);

  const sideMenuGroupProviderValue: ISideMenuGroupProviderValue = {
    isExpand,
    setIsExpand,
    isActive,
    setIsActive,
  };

  const render =
    typeof children === 'function'
      ? children({ isActive, isExpand })
      : children;

  const titleNode = Children.map(render, (child) => {
    if (typeof children === 'function') {
      // filter elements in indirect children / wrapper (usually wrapped with react fragment)
      return Children.map(child.props.children, (grandChild) =>
        grandChild.type === SideMenuGroupTitle ? grandChild : null,
      );
    }
    // filter elements elements in direct children
    return child.type === SideMenuGroupTitle ? child : null;
  })[0];

  const subItemNode = Children.map(render, (child) => {
    if (typeof children === 'function') {
      // filter elements in indirect children / wrapper (usually wrapped with react fragment)
      return Children.map(child.props.children, (grandChild) =>
        grandChild.type === SideMenuItem
          ? cloneElement(grandChild, {
              className: cx(styled.subItem, styled.noMarginLink),
            })
          : null,
      );
    }
    // filter elements elements in direct children
    return child.type === SideMenuItem
      ? cloneElement(child, {
          className: styled.subItem,
        })
      : null;
  });

  const itemLinks = Children.map(subItemNode, (child) => child.props.href);
  const hideAngleIcon = isExpandSidebar && hasSubmenu;

  function handleTitleClick() {
    setIsExpand((prev) => !prev || !isExpandSidebar);
    setIsExpandSidebar(true);
  }

  useEffect(
    function searchActiveLink() {
      const _isMenuActive = isMenuActive || itemLinks.includes(router.asPath);
      setIsActive(_isMenuActive);
      _isMenuActive && setIsExpand(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.asPath],
  );

  return (
    <li className={cx(styled.outerWrapper(!!bordered), className)}>
      <SideMenuGroupProvider value={sideMenuGroupProviderValue}>
        <div
          className={cx(styled.item, {
            [styled.animateIcon]: !isExpandSidebar,
          })}
          {...(hasSubmenu && { onClick: () => handleTitleClick() })}
        >
          {isExpandSidebar && category && (
            <div className={styled.categoryWrapper}>
              <div className="category">{category}</div>
              {hideAngleIcon && categoryArrow && (
                <div className={styled.angleWrapper}>
                  <AnimatePresence>
                    <AnimatedWrapper
                      htmlTag="div"
                      animate={{
                        rotate: isExpand ? 180 : 0,
                      }}
                    >
                      <Angle direction="down" size="small" height="20" />
                    </AnimatedWrapper>
                  </AnimatePresence>
                </div>
              )}
            </div>
          )}
          <div className={styled.flexRow(!!bordered, isActive)}>
            {titleNode}
            {hideAngleIcon && !categoryArrow && (
              <div className={styled.angleWrapper}>
                <AnimatePresence>
                  <AnimatedWrapper
                    htmlTag="div"
                    animate={{
                      rotate: isExpand ? 180 : 0,
                    }}
                  >
                    <Angle direction="down" size="small" height="20" />
                  </AnimatedWrapper>
                </AnimatePresence>
              </div>
            )}
          </div>
        </div>

        <AnimatePresence>
          {isExpand && isExpandSidebar && (
            <AnimatedWrapper
              htmlTag="div"
              transition={{ duration: 0.08 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <ul className={styled.subMenu}>{subItemNode}</ul>
            </AnimatedWrapper>
          )}
        </AnimatePresence>
      </SideMenuGroupProvider>
    </li>
  );
}

export default memo(SideMenuGroup);

const styled = {
  outerWrapper: (bordered: boolean) => css`
    border: ${bordered ? '1px solid var(--blue-200)' : 'none'};
    border-radius: 12px;
  `,
  flexRow: (bordered: boolean, isActive: boolean) => css`
    display: flex;
    flex-direction: row;
    width: 100%;
    > div:first-of-type {
      // Note: this's for handle bordered (Wallet) no need to be blue when active
      color: ${bordered || !isActive
        ? 'var(--neutral-500)'
        : 'var(--link-active-text)'} !important;
    }
  `,
  categoryWrapper: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
    > .category {
      width: 100%;
    }
  `,
  item: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: var(--sm-font-size);
    color: var(--text-secondary);
    border-radius: 12px;
    min-height: 54px;
    min-width: 54px;
    padding: 14px;
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        background: var(--link-active-bg);
      }
    }
  `,
  animateIcon: css`
    &:hover [data-type='icon'] {
      transition: all 0.25s;
      transform: scale(1.25);
    }
  `,
  subMenu: css`
    list-style-type: none;
    padding: 0;
    margin: 0;
  `,
  subItem: css`
    margin: 8px 12px 8px 32px;
  `,
  selected: css`
    background: var(--link-active-bg);
  `,
  noMarginLink: css`
    a {
      margin: 0;
    }
  `,
  angleWrapper: css`
    margin-left: auto;
    display: flex;
    align-items: center;
    > div {
      display: flex;
    }
  `,
};
