interface CookieParams {
  name: string;
  value: string;
  age?: number;
  path?: string;
  httpOnly?: boolean;
  secure?: boolean;
}

export const AUTH_FLAG_KEY = 'fg_a';

export const IS_MOBILE_APP_KEY = 'is_mobile_app';

export const NEXT_LOCALE_KEY = 'NEXT_LOCALE';

export const TR_MEMBER_ID = 'tr_member_id';

/**
 * @param {string} name - cookie key
 * @param {string} value - cookie value
 * @param {number} age - cookie age in second, `1 day` by default
 * @param {number} path - cookie path `/` by default
 * @param {boolean} httpOnly - cookie httpOnly `false` by default
 * @param {boolean} secure - cookie secure `false` by default
 */
export function setCookie({
  name,
  value,
  age = 24 * 60 * 60,
  path = '/',
}: CookieParams) {
  document.cookie = `${name}=${value}; max-age=${age}; path=${path};`;
}
