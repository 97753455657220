function RadioIcon({
  size = 24,
  borderColor = 'var(--neutral-200)',
}: {
  size?: number;
  borderColor?: string;
}) {
  return (
    <svg width={size} height={size} fill="none">
      <rect
        x={0.5}
        y={0.5}
        width={23}
        height={23}
        rx={11.5}
        fill="var(--neutral-0)"
      />
      <rect
        x={0.5}
        y={0.5}
        width={23}
        height={23}
        rx={11.5}
        stroke={borderColor}
      />
    </svg>
  );
}

export default RadioIcon;
