import { TimeLeftResponse } from '@api-hooks/forget-password';
import { requestFn } from '@common/client';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { useMutation, UseMutationOptions } from 'react-query';

import {
  ChangeEmailRequestModel,
  ChangePasswordRequestModel,
  ChangePasswordResponseModel,
  ChangePhoneNumberRequestModel,
  ChangePinParam,
  ChangeVerificationResponseModel,
  ChatIdModel,
  CheckOldPinParam,
  CheckPhoneNumberRequestModel,
  CreatePinParam,
  RequestResetPinResponse,
  RequestVerificationResponseModel,
  ResetPinParam,
  PatchMembersModel,
  PatchMembersParam,
  ValidatePinParam,
  VerifyPasswordRequestModel,
  VerifyPhoneNumberRequestModel,
  VerifyResetPinParam,
} from './dashboard.model';

export function usePostRequestChangeEmail(
  options?: UseMutationOptions<
    ApiResult<RequestVerificationResponseModel>,
    ApiError,
    ChangeEmailRequestModel
  >,
) {
  return useMutation<
    ApiResult<RequestVerificationResponseModel>,
    ApiError,
    ChangeEmailRequestModel
  >(
    async (param: ChangeEmailRequestModel) =>
      await requestFn(
        {
          path: 'profiles/verifications/email/change/requests',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    { ...options, retry: false },
  );
}

export function usePostCheckPhoneNumber(
  options?: UseMutationOptions<
    ApiResult<CheckPhoneNumberRequestModel>,
    ApiError,
    CheckPhoneNumberRequestModel
  >,
) {
  return useMutation<
    ApiResult<CheckPhoneNumberRequestModel>,
    ApiError,
    CheckPhoneNumberRequestModel
  >(
    async (param: CheckPhoneNumberRequestModel) =>
      await requestFn(
        {
          path: 'profiles/verifications/phone-number/check',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function usePostChangePhoneNumber(
  options?: UseMutationOptions<
    ApiResult<RequestVerificationResponseModel>,
    ApiError,
    ChangePhoneNumberRequestModel
  >,
) {
  return useMutation<
    ApiResult<RequestVerificationResponseModel>,
    ApiError,
    ChangePhoneNumberRequestModel
  >(
    async (param: ChangePhoneNumberRequestModel) =>
      await requestFn(
        {
          path: 'profiles/verifications/requests',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function usePostVerifyPhoneNumber(
  options?: UseMutationOptions<
    ApiResult<ChangeVerificationResponseModel>,
    ApiError,
    VerifyPhoneNumberRequestModel
  >,
) {
  return useMutation<
    ApiResult<ChangeVerificationResponseModel>,
    ApiError,
    VerifyPhoneNumberRequestModel
  >(
    async (param: VerifyPhoneNumberRequestModel) =>
      await requestFn(
        {
          path: 'profiles/verifications',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    { ...options, retry: false },
  );
}

export function usePostVerifyCurrentPassword(
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  return useMutation<ApiResult, ApiError, any>(
    async (param: VerifyPasswordRequestModel) =>
      await requestFn(
        {
          path: 'members/change-password/current',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    { ...options, retry: false },
  );
}
export function usePatchMember(
  options?: UseMutationOptions<ApiResult<PatchMembersModel>, ApiError>,
) {
  return useMutation<ApiResult<PatchMembersModel>, ApiError, any>(
    async (param: PatchMembersParam) =>
      await requestFn(
        {
          path: 'members',
          method: 'patch',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function usePostRequestChangePassword(
  options?: UseMutationOptions<
    ApiResult<ChangePasswordResponseModel>,
    ApiError
  >,
) {
  return useMutation<ApiResult<ChangePasswordResponseModel>, ApiError, any>(
    async (param: ChangePasswordRequestModel) =>
      await requestFn(
        {
          path: 'members/change-password',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    { ...options, retry: false },
  );
}

export function useUpdateChatId(
  options?: UseMutationOptions<ApiResult<ChatIdModel>, ApiError>,
) {
  return useMutation<ApiResult<ChatIdModel>, ApiError, any>(
    async (param: ChatIdModel) =>
      await requestFn(
        {
          path: 'me',
          method: 'patch',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useValidatePin(
  options?: UseMutationOptions<null, ApiError, ValidatePinParam>,
) {
  return useMutation<null, ApiError, ValidatePinParam>(
    async (param: ValidatePinParam) =>
      await requestFn(
        {
          path: 'pin-code/validate',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useCreatePin(
  options?: UseMutationOptions<ApiResult, ApiError, CreatePinParam>,
) {
  return useMutation<ApiResult, ApiError, CreatePinParam>(
    async (param: CreatePinParam) =>
      await requestFn(
        {
          path: 'pin-code',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useCheckOldPin(
  options?: UseMutationOptions<null, ApiError, CheckOldPinParam>,
) {
  return useMutation<null, ApiError, CheckOldPinParam>(
    async (param: CheckOldPinParam) =>
      await requestFn(
        {
          path: 'pin-code/check',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useChangePin(
  options?: UseMutationOptions<ApiResult, ApiError, ChangePinParam>,
) {
  return useMutation<ApiResult, ApiError, ChangePinParam>(
    async (param: ChangePinParam) =>
      await requestFn(
        {
          path: 'pin-code',
          method: 'put',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useRequestResetPin(
  options?: UseMutationOptions<ApiResult<TimeLeftResponse>, ApiError>,
) {
  return useMutation<ApiResult<TimeLeftResponse>, ApiError>(
    async () =>
      await requestFn({
        path: 'pin-code/reset/request',
        method: 'post',
      }),
    options,
  );
}

export function useVerifyResetPin(
  options?: UseMutationOptions<
    ApiResult<RequestResetPinResponse>,
    ApiError,
    VerifyResetPinParam
  >,
) {
  return useMutation<
    ApiResult<RequestResetPinResponse>,
    ApiError,
    VerifyResetPinParam
  >(
    async (param: VerifyResetPinParam) =>
      await requestFn(
        {
          path: 'pin-code/reset/verify',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}

export function useResetPin(
  options?: UseMutationOptions<ApiResult, ApiError, ResetPinParam>,
) {
  return useMutation<ApiResult, ApiError, ResetPinParam>(
    async (param: ResetPinParam) =>
      await requestFn(
        {
          path: 'pin-code/reset/complete',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}
