import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

/**
 * Put below FormProvider for automatically scroll to error field
 * @param {ReactDOM} [children]
 * @returns
 */

const FormOnErrorScroll = ({ children }) => {
  const form = useFormContext();

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0) {
      const firstErrorKey = Object.keys(form.formState.errors)[0];
      const errorElement = document.getElementsByName(firstErrorKey)[0];

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [JSON.stringify(form.formState.errors)]);

  return children;
};

export default FormOnErrorScroll;
