import { UpdateDataStatusType, useGetMe } from '@api-hooks/common';
import { useGetUpdateDataDraft } from '@api-hooks/member';
import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { usePermission } from '@hooks';
import DashboardContainer from '@modules/dashboard/DashboardContainer';
import DashboardContentCard from '@modules/dashboard/DashboardContentCard';
import {
  UpdateDataFormBusiness,
  UpdateDataFormPersonal,
} from '@modules/dashboard/profile';
import { areObjEqual } from '@topremit/shared-web/common/helper';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import useMediaQuery from '@topremit/shared-web/hooks/use-media-query';
import usePersistentState from '@topremit/shared-web/hooks/use-persistent-state';
import useTranslation from '@topremit/shared-web/hooks/use-translation';
import { useDialogStore } from '@topremit/shared-web/stores/use-dialog-store';
import { color } from '@topremit/shared-web/styles/color';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { UPDATE_DATA_DRAFT_KEY } from './constants';
import { mapToUpdateFormData } from './helper/mappers';
import {
  updateBusinessDataSchema,
  updatePersonalDataSchema,
} from './helper/update-data-schema';
import ConfirmUpdateProfileModal from './modal/ConfirmUpdateProfileModal';
import ConfirmationLeaveModal from './modal/ConfirmationLeaveModal';
import { UpdateDataForm } from './types';

function UpdateData() {
  const router = useRouter();
  const { data: draftData, refetch } = useGetUpdateDataDraft({
    onSuccess: (data) => {
      localStorage.removeItem(UPDATE_DATA_DRAFT_KEY);
      reset(mapToUpdateFormData(data?.data));
    },
  });

  const [formData, setFormData] =
    usePersistentState<Partial<UpdateDataForm> | null>(
      UPDATE_DATA_DRAFT_KEY,
      draftData?.data ? mapToUpdateFormData(draftData?.data) : null,
    );
  const { data: meData } = useGetMe();
  const { isPersonalAccount } = usePermission();
  const { show: showDialog } = useDialogStore((state) => state);
  const { t } = useTranslation('profile');
  const isTabletSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);

  const method = useForm<UpdateDataForm>({
    mode: 'onSubmit',
    resolver: yupResolver(
      isPersonalAccount
        ? (updatePersonalDataSchema(t) as any)
        : (updateBusinessDataSchema(t, draftData?.meta?.documentTypes) as any),
    ) as any,
    defaultValues: formData ?? {},
  });
  const { handleSubmit, watch, getValues, clearErrors, reset } = method;

  const onSubmit = (data: UpdateDataForm) => {
    showDialog({
      body: <ConfirmUpdateProfileModal data={data} />,
    });
  };

  function onClickBackButton() {
    showDialog({
      body: <ConfirmationLeaveModal data={getValues()} />,
    });
  }

  /**
   * This function listens to changes in the form data, processes updates with debouncing to local storage,
   * and ensures that empty arrays in `documents` do not overwrite existing values if they were previously populated.
   *
   * @function
   */

  useEffect(() => {
    /**
     * Debounced function to update form data to local storage.
     */
    const debouncedUpdate = _.debounce((value) => {
      if (!value) return;

      setFormData((prevFormData) => {
        const updatedValue = { ...value } as Partial<UpdateDataForm>;

        // Ensure empty arrays in `documents` do not override existing values
        if (updatedValue?.documents) {
          updatedValue.documents = Object.entries(
            updatedValue.documents,
          ).reduce((acc, [key, val]) => {
            const previousValue = prevFormData?.documents?.[key];

            acc[key] =
              Array.isArray(val) &&
              val.length === 0 &&
              Array.isArray(previousValue)
                ? previousValue
                : val;

            return acc;
          }, {});
        }

        // Check if the new value has any difference before updating state
        const hasChanged = !areObjEqual(updatedValue, prevFormData ?? {});

        return hasChanged ? updatedValue : prevFormData;
      });
    }, 500);

    // Subscribe to form changes and trigger debounced updates
    const subscription = watch(debouncedUpdate);

    return () => {
      // Cleanup: Cancel debounced function and unsubscribe from form updates
      debouncedUpdate.cancel();
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (draftData?.data && !formData) {
      const data = mapToUpdateFormData(draftData?.data);
      reset(data);
      clearErrors();
    }
  }, [draftData?.data, formData, method]);

  useEffect(() => {
    refetch();
    const redirectPage =
      meData?.data?.updateData?.status === UpdateDataStatusType.VERIFYING;
    const isNotUpdateData = meData?.data && !meData?.data?.updateData;

    if (redirectPage || isNotUpdateData) {
      router.replace('/profile');
    }
  }, [meData?.data]);

  return (
    <DashboardContainer>
      <DashboardContentCard
        maxWidth={isPersonalAccount ? undefined : '50rem'}
        type="fit-content"
        className={styled.root}
        hasBackButton
        hasChatUsButton={isTabletSize}
        title={t('form.title')}
        description={t('form.subtitle')}
        onClickBackButton={onClickBackButton}
      >
        <FormProvider {...method}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {isPersonalAccount ? (
              <UpdateDataFormPersonal />
            ) : (
              <UpdateDataFormBusiness setFormData={setFormData} />
            )}
          </form>
        </FormProvider>
      </DashboardContentCard>
    </DashboardContainer>
  );
}
export default UpdateData;

const styled = {
  root: css`
    .button-wrapper {
      flex-direction: column;
    }
    .accordion-wrapper {
      margin-bottom: 0 !important;
    }
    .error-text {
      color: var(--red-500) !important;
      margin: 1rem 0;
      display: block;
    }
    .title {
      @media (min-width: ${screenSize.desktopMd}px) {
        font-size: 1.375rem !important;
      }
      font-size: 1.125rem !important;
    }
    .title-sub {
      font-size: ${typography.body3Bold.fontSize}rem !important;
      font-weight: ${typography.body3Bold.fontWeight};
      @media (min-width: ${screenSize.desktopMd}px) {
        font-size: 1rem !important;
      }
    }
    .rejected-note {
      margin-bottom: 0.75rem;
    }
    .accordion {
      padding: 1rem;
      margin: 1.5rem 0;
      box-shadow: 0px 2px 8px 0px #2e486529;
      border-radius: 0.5rem;
      .uploader {
        margin-top: 1.5rem;
      }
      .accordion-title {
        > .text {
          font-size: ${typography.smallBold.fontSize}rem !important;
          font-weight: ${typography.smallBold.fontWeight};
          @media (min-width: ${screenSize.desktopMd}px) {
            font-size: 1rem !important;
          }
        }
        margin-right: 1rem;
      }
      .error-text {
        color: var(--red-500);
      }
    }
    .ant-radio-group {
      > * + * {
        margin-top: 1rem;
      }
    }
    .pep-list {
      color: var(--blue-500);
      cursor: pointer;
      font-weight: var(--bold-font-weight);
    }
    .regulation {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin: 1rem auto;
    }
    .description {
      font-size: ${typography.smallMedium.fontSize}rem;
      font-weight: ${typography.smallMedium.fontWeight};
      @media (min-width: ${screenSize.desktopMd}px) {
        font-size: 0.875rem !important;
      }
      color: ${color.neutral500};
      > .here {
        color: var(--blue-500);
        cursor: pointer;
        font-weight: var(--bold-font-weight);
      }
    }
    .grid-wrapper {
      @media (min-width: ${screenSize.desktopMd}px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      .form-group {
        margin: 0;
      }
      margin-bottom: 1.25rem;
    }
    .container {
      .wrapper {
        .content-card-container {
          .content {
            display: unset;
          }
        }
      }
    }
  `,
};
