import { css } from '@emotion/css';
import { usePermission } from '@hooks';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { zIndex } from '@topremit/shared-web/common/size';
import { NavbarDrawerProvider, useMounted } from '@topremit/shared-web/hooks';
import dynamic from 'next/dynamic';
import { forwardRef } from 'react';

import NavigationBar from './NavigationBar';

const Announcement = dynamic(() =>
  import('./announcement').then((m) => m.Announcement),
);

interface IDefaultHeader {
  showAnnouncement?: boolean;
  hasBackground?: boolean;
}

const DefaultHeader = (
  { showAnnouncement, hasBackground = true }: IDefaultHeader,
  ref: any,
) => {
  const mounted = useMounted();
  const { isBusinessAccount } = usePermission();
  const _showAnnouncement = showAnnouncement && mounted;
  const showAfBanner = mounted && !isNativeApp() && !isBusinessAccount;

  return (
    <header className={styled.header} ref={ref}>
      <NavbarDrawerProvider>
        <NavigationBar hasBackground={hasBackground} />
      </NavbarDrawerProvider>
      {showAfBanner && <div id="af-banner" />}
      {_showAnnouncement && <Announcement />}
    </header>
  );
};

export default forwardRef<any, IDefaultHeader>(DefaultHeader);

const styled = {
  header: css`
    position: fixed;
    right: 0;
    left: 0;
    z-index: ${zIndex.header};
    #af-banner {
      position: relative;
      > div:first-of-type {
        height: 0 !important;
        display: none;
      }
      .responsive-wrapper {
        position: relative !important;
        z-index: unset !important;
      }
    }
  `,
};
