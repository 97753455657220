import { handleMobileEvent } from '@common/native-web-view-bridge';
import { isValidObject } from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { useEffect } from 'react';

export default function useServiceWorker() {
  useEffect(() => {
    async function registerServiceWorker() {
      const isHttps = window.location.protocol === 'https:';
      if ('serviceWorker' in navigator && isHttps) {
        try {
          const registration = await navigator.serviceWorker.register('/sw.js');
          console.info(
            'ServiceWorker registration successful with scope: ',
            registration.scope,
          );
        } catch (error) {
          console.error('ServiceWorker registration failed: ', error);
        }
      }
    }

    function onMessageNativeEvent(nativeEvent) {
      if (isNativeApp() && isValidObject(nativeEvent.data)) {
        const event = JSON.parse(nativeEvent.data);
        handleMobileEvent(event);
      }
    }

    function declareGlobalVariables() {
      //https:stackoverflow.com/questions/72720387/cant-find-variable-autofillcallbackhandler
      if (typeof window !== 'undefined') {
        window._AutofillCallbackHandler =
          window._AutofillCallbackHandler || function () {};
      }
    }

    declareGlobalVariables();

    // This is added for handle sw run in csr and this is for additional safeguard
    window.addEventListener('load', registerServiceWorker);
    window.addEventListener('message', onMessageNativeEvent);
    return () => {
      window.removeEventListener('load', registerServiceWorker);
      window.removeEventListener('message', onMessageNativeEvent);
    };
  }, []);
}
