import { DashboardLayoutContextValues } from '@layouts';
import { assertContextCalledInProvider } from '@topremit/shared-web/common/assertion';
import { createContext, useContext } from 'react';

const DashboardLayoutContext = createContext<DashboardLayoutContextValues>([
  {},
  function setDashboardConfig() {},
]);

export const DashboardLayoutProvider = DashboardLayoutContext.Provider;

export function useDashboardLayout() {
  const ctx = useContext(DashboardLayoutContext);

  assertContextCalledInProvider<DashboardLayoutContextValues>(ctx);

  return ctx;
}
