import { AnimatedHeightWrapper } from '@elements';
import { css } from '@emotion/css';
import { usePin } from '@hooks';
import { Button, PinInput } from '@topremit/shared-web';
import { typography } from '@topremit/shared-web/common/size';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { useRouter } from 'next/router';
import { ReactNode, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Pin from 'react-pin-input';

const PIN_LENGTH = 6;

interface PinFormValues {
  pin: string;
}

interface VerifyPinDialogProps {
  title?: ReactNode;
  description?: ReactNode;
  onSubmit: (pin: string) => Promise<void>;
}

export function VerifyPinDialog({
  onSubmit,
  title,
  description,
}: VerifyPinDialogProps) {
  const { t } = useTranslation();
  const router = useRouter();

  const pinRef = useRef<Pin>(null);

  const formMethods = useForm<PinFormValues>({
    defaultValues: {
      pin: '',
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { forgot: forgotPin } = usePin();

  const backUrl = router.asPath;

  async function handleOnSubmit(pin: string) {
    setIsSubmitting(true);

    try {
      const res = await onSubmit(pin);
      setIsSubmitting(false);
      return res;
    } catch (error) {
      setIsSubmitting(false);
      pinRef.current?.clear();
      setTimeout(() => {
        pinRef.current?.focus();
      }, 50);
      formMethods.setError('pin', {
        message: error.message,
      });
      throw error;
    }
  }

  return (
    <div className={styled.root}>
      <h2 className={styled.title}>{title ?? t('pin_input_dialog.title')}</h2>
      <p className={styled.description}>
        {description ?? t('pin_input_dialog.description')}
      </p>
      <FormProvider {...formMethods}>
        <AnimatedHeightWrapper>
          <PinInput
            focus
            secret
            name="pin"
            ref={pinRef}
            length={PIN_LENGTH}
            disabled={isSubmitting}
            onChange={() => {
              formMethods.clearErrors('pin');
            }}
            onComplete={(value) => {
              handleOnSubmit(value);
            }}
          />
        </AnimatedHeightWrapper>
      </FormProvider>
      <div className={styled.footer}>
        <Button
          size="medium"
          type="tertiary"
          onClick={() => {
            forgotPin({ from: backUrl });
          }}
        >
          {t('dashboard:pin.forgot')}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    text-align: center;
  `,
  title: css`
    margin: 0 0 0.5rem;
    font-size: ${typography.body2Bold.fontSize}rem;
    font-weight: ${typography.body2Bold.fontWeight};
    line-height: ${typography.body2Bold.lineHeight}px;
  `,
  description: css`
    margin: 0 0 1rem;
    color: ${color.neutral800};
    font-size: ${typography.body3Medium.fontSize}rem;
    font-weight: ${typography.body3Medium.fontWeight};
    line-height: ${typography.body3Medium.lineHeight}px;
  `,
  footer: css`
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
  `,
};
