import { css } from '@emotion/css';
import { SideMenuIcon } from '@topremit/shared-web';
import { PaperPlaneFill } from '@topremit/shared-web/components/shapes';
import { domAnimation, LazyMotion, m } from 'framer-motion';

export default function AnimatedSidebarIcon() {
  return (
    <LazyMotion features={domAnimation}>
      <m.span
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
          transition: {
            duration: 0.2, // depands on button shape update duration
          },
        }}
        exit={{ opacity: 0, transition: { duration: 0 } }}
      >
        <SideMenuIcon className={styled.icon}>
          <PaperPlaneFill fill="var(--neutral-0)" />
        </SideMenuIcon>
      </m.span>
    </LazyMotion>
  );
}
const styled = {
  icon: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 0 12px 0 0px;

    svg {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
    }
  `,
};
