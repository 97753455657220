import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import * as React from 'react';
import { Controller, FieldValues } from 'react-hook-form';

import NumberInputBase from './NumberInputBase';
import { INumberInputV2Props } from './types';

/**
 * `NumberInputV2` is a component that renders a number input field.
 * it's a component that extends `NumberInputBase`
 * It supports both controlled and uncontrolled modes.
 *
 * @example
 * `without react-hook-form`
 * const [value, setValue] = useState<INumberInputValue>();
 * <NumberInputV2
 *  name="test"
 *  value={value}
 *  onChange={setValue}
 *  label="new number input"
 * />
 *
 * OR
 *
 * `with react-hook-form`
 * const methods = useForm<FormValues>();
 * <NumberInputV2
 *  name="newNumber"
 *  label="new number input"
 *  control={methods.control}
 * />
 */
const NumberInputV2 = React.forwardRef(function NumberInputV2<
  T extends FieldValues,
>(props: INumberInputV2Props<T>, ref: React.Ref<HTMLInputElement>) {
  const {
    name,
    error,
    control,
    disabled,
    className,
    showError,
    helperText,
    leftSection,
    rightSection,
    bordered = true,
    onChange: onChangeProps,
    ...restProps
  } = props;

  if (!control) {
    return (
      <div className={cx(styles.root, className)}>
        <FieldGroup
          error={error}
          isError={!!error}
          bordered={bordered}
          helper={helperText}
          disabled={disabled}
          showError={showError}
          leftIcon={leftSection}
          className={styles.group}
          rightIcon={rightSection}
          showLeftIcon={!!leftSection}
          showRightIcon={!!rightSection}
          rightIconClassName={styles.rightElement}
        >
          <NumberInputBase {...props} ref={ref} className="" bordered={false} />
        </FieldGroup>
      </div>
    );
  }

  return (
    <Controller
      name={name}
      render={({
        field: { onChange: fieldOnChange, ...restField },
        fieldState,
      }) => (
        <div className={cx(styles.root, className)}>
          <FieldGroup
            bordered
            disabled={disabled}
            helper={helperText}
            showError={showError}
            leftIcon={leftSection}
            className={styles.group}
            rightIcon={rightSection}
            showLeftIcon={!!leftSection}
            showRightIcon={!!rightSection}
            error={fieldState.error?.message}
            isError={!!fieldState.error?.message}
            rightIconClassName={styles.rightElement}
          >
            <NumberInputBase
              {...restProps}
              {...restField}
              ref={ref}
              onChange={(val) => {
                fieldOnChange(val);
                if (onChangeProps) {
                  onChangeProps(val);
                }
              }}
              bordered={false}
              disabled={disabled}
              error={fieldState.error?.message}
            />
          </FieldGroup>
        </div>
      )}
    />
  );
}) as <T extends FieldValues>(
  props: INumberInputV2Props<T> & { ref?: React.Ref<HTMLInputElement> },
) => JSX.Element;

const styles = {
  root: css`
    width: 100%;
  `,
  group: css`
    margin: 0 !important;
    background-color: transparent !important;
  `,
  rightElement: css`
    padding-right: 20px;
  `,
};

export default NumberInputV2;
