import { ClassNamesArg, css, cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode; // <VStepperItem />

  className?: ClassNamesArg;
}

/**
 * VStepper
 *
 * @description
 * `VStepper` component is use for show step/timeline
 *  should be use with correct order
 *
 * @example 1
 * <VStepper>
 *  <VStepperItem>
 *    <VStepperConnector />
 *    <VStepperContent>
 *      <VStepperHeader>Header</VStepperHeader>
 *      <VStepperTitle>Title</VStepperTitle>
 *      <VStepperDescription>Description</VStepperDescription>
 *    </VStepperContent>
 *  </VStepperItem>
 * </VStepper>
 * 
 * @example 2: Track transaction
 * <VStepper>
 *  <VStepperItem key={idx}>
      <VStepperConnector
        iconType="check"
        showDivider={!isLast}
        showAnimation={isFirst}
        color={isFirst ? 'blue' : 'gray'}
      />
      <VStepperContent>
        <VStepperHeader color={isFirst ? 'blue500' : 'neutral500'}>
          {date}
        </VStepperHeader>
        <VStepperTitle color={isFirst ? 'neutral800' : 'neutral500'}>
          {title}
        </VStepperTitle>
        <VStepperDescription color={isFirst ? 'neutral500' : 'neutral300'}>
          {description}
        </VStepperDescription>
      </VStepperContent>
    </VStepperItem>
  </VStepper>
 */

export default function VStepper({ children, className }: Props) {
  return (
    <Flex column className={cx(styled.root, className)}>
      {children}
    </Flex>
  );
}

const styled = {
  root: css`
    gap: 4px;
  `,
};
