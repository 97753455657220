import { useEffect, useState } from 'react';

const useDebounce = (functionCallback, inputs: any[], delay) => {
  useEffect(() => {
    const handler = setTimeout(() => {
      functionCallback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [...inputs]);
};

const useDebouncedValue = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Set a timeout to update the debounced value after the delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup the timeout if value changes or component unmounts
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export { useDebounce, useDebouncedValue };
