import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

import { DropdownProvider, useDropdown } from '../../../hooks';

interface IDropdownProps {
  className?: string;
  children: ReactNode;
}

function DropdownContent({ className, children }) {
  const { ref, onHover, onLeave } = useDropdown();

  return (
    <div
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
      ref={ref}
      className={cx(styled.root, className)}
    >
      {children}
    </div>
  );
}

export default function Dropdown({ className, children }: IDropdownProps) {
  return (
    <DropdownProvider>
      <DropdownContent className={className}>{children}</DropdownContent>
    </DropdownProvider>
  );
}

const styled = {
  root: css`
    position: relative;
  `,
};
