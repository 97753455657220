import { useCurrentAccountStore } from '@stores';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { useTranslation } from '@topremit/shared-web/hooks';
import {
  QueryFunction,
  QueryKey,
  useQuery as useQueryDefault,
  UseQueryOptions,
} from 'react-query';

/**
 * Extended useQuery with query key for **lang** and **account id**,
 * This hook is designed to automatically refetch data whenever the user changes
 * their language or switches accounts, preventing caching of outdated information.
 *
 * @param {Object} cache - Configuration for extending the query key.
 * @param {boolean} cache.hasLang - If `true`, includes the current language (`lang`) in the query key.
 * @param {boolean} cache.hasAccountId - If `true`, includes the current account ID (`accountId`) in the query key.
 *
 */
export function useQuery<
  TApiResult = unknown,
  TError = ApiError,
  TData = ApiResult<TApiResult>,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  requestFn: QueryFunction<TData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >,
  cache: { hasLang?: boolean; hasAccountId?: boolean } = {},
) {
  const { hasLang = true, hasAccountId = false } = cache;

  const { lang } = useTranslation();
  const currentAccountId = useCurrentAccountStore(
    (state) => state.currentAccount?.id,
  );

  const _queryKey = typeof queryKey === 'string' ? [queryKey] : [...queryKey];

  const defaultQueryKey = [..._queryKey];

  if (hasLang) {
    defaultQueryKey.push(lang);
  }

  if (hasAccountId && currentAccountId) {
    defaultQueryKey.push(currentAccountId);
  }

  return useQueryDefault<TData, TError, TData, TQueryKey>(
    defaultQueryKey as unknown as TQueryKey,
    requestFn,
    options,
  );
}
