import dynamic from 'next/dynamic';

export * from './bank-account-details';
export { default as CompanyProfile } from './CompanyProfile';
export { default as Logout } from './Logout';
export { default as UpdateData } from './UpdateData';

export const Profile = dynamic(() => import('./Profile'));
export const MyProfile = dynamic(() => import('./MyProfile'));
export const OtherInformation = dynamic(() => import('./OtherInformation'));
export const NeedVerification = dynamic(() => import('./NeedVerification'));
export const Settings = dynamic(() => import('./Settings'));
export const ChangeAccountDataModalBody = dynamic(
  () => import('./modal/ChangeAccountDataModalBody'),
);
export const PepListModal = dynamic(() => import('./modal/PepListModal'));
export const ChangeEmail = dynamic(() => import('./form/ChangeEmail'));
export const ChangePhoneNumber = dynamic(
  () => import('./form/ChangePhoneNumber'),
);
export const ChangePhoneNumberForm = dynamic(
  () => import('./form/ChangePhoneNumberForm'),
);
export const VerificationMethod = dynamic(
  () => import('./form/VerificationMethod'),
);
export const VerificationOTP = dynamic(() => import('./form/VerificationOTP'));
export const ResendEmail = dynamic(() => import('./ResendEmail'));
export const EmailVerifyForm = dynamic(() => import('./form/EmailVerifyForm'));
export const ChangeEmailForm = dynamic(() => import('./form/ChangeEmailForm'));
export const ChangeVerificationMethodModalBody = dynamic(
  () => import('./modal/ChangeVerificationMethodModalBody'),
);
export const EmailVerification = dynamic(
  () => import('./form/EmailVerification'),
);
export const PersonalDetails = dynamic(() => import('./PersonalDetails'));
export const ChangePassword = dynamic(() => import('./form/ChangePassword'));
export const CurrentPassword = dynamic(() => import('./form/CurrentPassword'));
export const CreateNewPassword = dynamic(
  () => import('./form/CreateNewPassword'),
);
export const PepForm = dynamic(() => import('./form/PepForm'));
export const ProfileListMenuField = dynamic(
  () => import('./ProfileListMenuField'),
);
export const Language = dynamic(() => import('./Language'));
export const Timezone = dynamic(() => import('./Timezone'));
export const Feedback = dynamic(() => import('./Feedback'));

export const UpdateDataFormPersonal = dynamic(
  () => import('./form/UpdateDataFormPersonal'),
);
export const UpdateDataFormBusiness = dynamic(
  () => import('./form/UpdateDataFormBusiness'),
);
