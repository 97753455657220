import { VerificationMedia } from '@topremit/shared-web/typings/auth.model';

export const METHODS: VerificationMedia[] = [
  VerificationMedia.SMS,
  VerificationMedia.WHATSAPP,
  VerificationMedia.MISCALL,
];
export const PHONE_NUMBER_PAGE_STATE_KEY = 'phone-number-page-state';
export const VERIFICATION_PHONE_NUMBER_KEY = 'verification-phone-number';
export const VERIFICATION_MEDIA_KEY = 'verification-media';
export const UPDATE_DATA_DRAFT_KEY = 'update-data-draft';

export const changeEmailInitialValues = {
  currentEmail: '',
  newEmail: '',
};

export const changePhoneNumberInitialValues = {
  currentPhoneNumber: '',
  currentDialIsoCode: '',
  newPhoneNumber: '',
  newDialIsoCode: '',
  selectnewPhoneNumber: '',
  otp: '',
};

export const verifyEmailInitialValues = {
  email: '',
};

export const currentPasswordInitialValues = {
  currentPassword: '',
};

export const changePasswordInitialValues = {
  newPassword: '',
  confPassword: '',
};

export const otpInitialValues = {
  otp: '',
  phoneNumber: '',
};

export const addBankAccountDetailsInitialValues = {
  bank: '',
  accountNumber: '',
  accountName: '',
  country: '',
  currency: '',
};

export const addOtherBankAccountDetailsInitialValues = {
  otherBank: '',
  accountNumber: '',
  accountName: '',
};
