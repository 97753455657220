/**
 * **SessionStorage implementation**
 */
class SessionStorage {
  setItem(key: string, value: string) {
    window.sessionStorage.setItem(key, value);
  }
  getItem(key: string) {
    return window.sessionStorage.getItem(key);
  }
  removeItem(key: string) {
    window.sessionStorage.removeItem(key);
  }
  clear() {
    window.sessionStorage.clear();
  }
  key(index: number) {
    return window.sessionStorage.key(index);
  }
  get length() {
    return window.sessionStorage.length;
  }
}

/**
 * **LocalStorage Polyfill**
 *
 * This polyfill is used to support webview in mobile apps that doesn't support local storage.
 * To support local storage in webview, we are using session storage as a fallback.
 *
 * @example
 * import localStoragePolyfill from 'local-storage-polyfill';
 *
 * // Put this line at the top of your code in _app.tsx
 * localStoragePolyfill.init();
 */

/** Add default fallback so the web didn't crash if session storage also not available */
class NoStorageFallback {
  getItem() {
    return '';
  }
  setItem() {}
  removeItem() {}
  clear() {}
  isDisabled() {
    return true;
  }
}

export default {
  /** Initialize the polyfill */
  init: () => {
    if (typeof window !== 'undefined') {
      try {
        // Local store can throw QuotaExceededError
        // check if local storage exist and is writable
        // by setting and removing a test key
        const testKey = '__check_local_storage_polyfill__';
        window.localStorage.setItem(testKey, '*');
        window.localStorage.removeItem(testKey);
        return;
      } catch (_) {
        // Fallback to session storage
        // when local storage is not available (error accessing local storage)
        // or when local storage is not writable (quota exceeded)
        let localStorageFallback;

        try {
          const _session = new SessionStorage();

          const testKey = '__check_local_storage_polyfill__';
          _session.setItem(testKey, '*');
          _session.removeItem(testKey);

          localStorageFallback = _session;
        } catch (_) {
          localStorageFallback = new NoStorageFallback();
        }

        Object.defineProperty(window, 'localStorage', {
          value: localStorageFallback,
          writable: false,
        });
      }
    }
  },
};
