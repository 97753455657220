import humps from 'humps';
import { useQuery, UseQueryOptions } from 'react-query';

import { GetApiParam, GetApiUrlResponse } from '.';
import { ApiError, ApiResult } from '../api.model';

/**
 * TODO: Will be removed soon
 */
export function useGetApiUrl(
  params?: GetApiParam,
  options?: UseQueryOptions<ApiResult<GetApiUrlResponse[]>, ApiError>,
) {
  const resp = useQuery<ApiResult<GetApiUrlResponse[]>, ApiError>(
    [params?.url, params?.url, humps.decamelizeKeys(params)],
    {
      retry: (failureCount, error) => {
        const queryBlackListStatusCode = [400, 401, 404, 422];
        return (
          failureCount < 2 &&
          !queryBlackListStatusCode.some((code) => code === error?.statusCode)
        );
      },
      ...options,
    },
  );

  const { data: rawData, ...restProps } = resp;

  // TODO : typing
  const formatted = rawData?.data?.map((it) => ({
    label: it.name,
    value: it.id || it.code,
  }));

  return { data: formatted, ...restProps };
}
