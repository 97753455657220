import { AuthorizationRules } from './rules';

export const HOME_BASE_PATH = '/home';
export const QUOTATION_BASE_PATH = '/quote';
export const ACTIVITY_BASE_PATH = '/activity';
export const AFFILIATE_BASE_PATH = '/affiliate';
export const MANAGE_MEMBER_BASE_PATH = '/manage-member';
export const PROFILE_BASE_PATH = '/profile';
export const SEND_MONEY_BASE_PATH = '/send-money';
export const WALLET_BASE_PATH = '/wallet';

export const routePermission = {
  [`${ACTIVITY_BASE_PATH}/transaction`]: AuthorizationRules.TransactionsIndex,
  [`${ACTIVITY_BASE_PATH}/track-payment`]:
    AuthorizationRules.TrackPaymentsIndex,
  [QUOTATION_BASE_PATH]: AuthorizationRules.MenusSendMoney,
  [AFFILIATE_BASE_PATH]: AuthorizationRules.MenusAffiliate,
  [SEND_MONEY_BASE_PATH]: AuthorizationRules.MenusSendMoney,
  [MANAGE_MEMBER_BASE_PATH]: AuthorizationRules.MembersIndex,
  [WALLET_BASE_PATH]: AuthorizationRules.MenusWalletHistory,
  [`${WALLET_BASE_PATH}/top-up`]: AuthorizationRules.MenusTopUp,
  [`${WALLET_BASE_PATH}/withdraw`]: AuthorizationRules.MenusWithdraw,
  [`${WALLET_BASE_PATH}/wallet-history`]: AuthorizationRules.MenusWalletHistory,
  [`${PROFILE_BASE_PATH}/bank-account-details/add`]:
    AuthorizationRules.BankAccountsStore,
  [`${PROFILE_BASE_PATH}/bank-account-details/add-form`]:
    AuthorizationRules.BankAccountsStore,
};

export const businessOnlyRoutes = [
  WALLET_BASE_PATH,
  `${WALLET_BASE_PATH}/top-up`,
  `${WALLET_BASE_PATH}/withdraw`,
  `${WALLET_BASE_PATH}/wallet-history`,
  MANAGE_MEMBER_BASE_PATH,
];
export const personalOnlyRoutes = [];
