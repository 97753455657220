import { PaymentMethod } from '@api-hooks/transaction';
import { TransactionType } from '@topremit/shared-web/api-hooks/transaction';
import { getItemInCookies } from '@topremit/shared-web/common/helper';
import {
  AtlasAnalyticTriggerNames as TriggerNames,
  AtlasAnalyticTriggerPayloads as TriggerPayloads,
} from '@topremit/shared-web/typings/analytics-atlas.model';
import {
  BankName,
  GlobalTrackAttribute,
} from '@topremit/shared-web/typings/analytics.model';
import { AnalyticsInstance } from 'analytics';

class TracksBusiness {
  private stateContext;
  protected attribute: Omit<GlobalTrackAttribute, 'identifier_type'>;
  instance: AnalyticsInstance;
  private track: (eventName: string, payload: any) => void;

  constructor(instance: AnalyticsInstance) {
    const user_id = getItemInCookies('tr_member_id') || '';
    this.instance = instance;
    this.stateContext = this.instance.getState('context');
    this.attribute = {
      app: this.stateContext.app,
      version: this.stateContext.version,
      user_id,
    };

    this.track = (eventName, payload) => {
      this.instance.track(eventName, {
        ...payload,
        ...this.attribute,
      });

      window.dataLayerBusiness?.push({
        event: eventName,
        ...payload,
        ...this.attribute,
      });
    };
  }

  // #region ABAD
  checkAccount(payload?: TriggerPayloads[TriggerNames.CHECK_ACCOUNT]) {
    this.track(TriggerNames.CHECK_ACCOUNT, payload);
  }
  chooseOtherBank(payload: TriggerPayloads[TriggerNames.CHOOSE_OTHER_BANK]) {
    this.track(TriggerNames.CHOOSE_OTHER_BANK, payload);
  }
  submitAbadFailed(
    payload: TriggerPayloads[TriggerNames.SUBMIT_KYC_ABAD_FAILED],
  ) {
    this.track(TriggerNames.SUBMIT_KYC_ABAD_FAILED, payload);
  }
  // #endregion ABAD

  // #region ACTIVITY
  filterActivityList(
    payload: TriggerPayloads[TriggerNames.FILTER_ACTIVITY_LIST],
  ) {
    this.track(TriggerNames.FILTER_ACTIVITY_LIST, payload);
  }
  // #endregion ACTIVITY

  // #region AFFILIATE
  affiliateInviteCTA(payload: TriggerPayloads[TriggerNames.AFFILIATE_INVITE]) {
    this.track(TriggerNames.AFFILIATE_INVITE, payload);
  }
  affiliateBalanceStatementFilter(
    payload: TriggerPayloads[TriggerNames.AFFILIATE_BALANCE_STATEMENT_FILTER],
  ) {
    this.track(TriggerNames.AFFILIATE_BALANCE_STATEMENT_FILTER, payload);
  }
  affiliateContactUs() {
    this.track(TriggerNames.AFFILIATE_CONTACT_US, null);
  }
  affiliateJoin() {
    this.track(TriggerNames.AFFILIATE_JOIN, null);
  }
  // #endregion AFFILIATE

  // #region LOGIN
  login(payload: TriggerPayloads[TriggerNames.LOGIN]) {
    this.track(TriggerNames.LOGIN, payload);
  }
  loginSubmitFailed(
    payload: TriggerPayloads[TriggerNames.LOGIN_SUBMIT_FAILED],
  ) {
    this.track(TriggerNames.LOGIN_SUBMIT_FAILED, payload);
  }
  resetPassword(payload?: TriggerPayloads[TriggerNames.RESET_PASSWORD]) {
    this.track(TriggerNames.RESET_PASSWORD, payload);
  }
  // #endregion LOGIN

  // #region PRODUCT
  announcementSection(
    payload: TriggerPayloads[TriggerNames.ANNOUNCEMENT_SECTION],
  ) {
    this.track(TriggerNames.ANNOUNCEMENT_SECTION, payload);
  }
  info(payload: TriggerPayloads[TriggerNames.INFO]) {
    this.track(TriggerNames.INFO, payload);
  }
  ctaButton(payload: TriggerPayloads[TriggerNames.CTA_BUTTON]) {
    this.track(TriggerNames.CTA_BUTTON, payload);
  }
  // #endregion PRODUCT

  // #region PROFILE
  foundUsSocialMedia(payload: TriggerPayloads[TriggerNames.FIND_US]) {
    this.track(TriggerNames.FIND_US, payload);
  }
  // #endregion PROFILE

  // #region REGISTER
  contactUsSelected(
    payload?: TriggerPayloads[TriggerNames.CONTACT_US_SELECTED],
  ) {
    this.track(TriggerNames.CONTACT_US_SELECTED, payload);
  }
  contactUsFormStepperView(
    payload: TriggerPayloads[TriggerNames.CONTACT_US_FORM_STEPPER_VIEW],
  ) {
    this.track(TriggerNames.CONTACT_US_FORM_STEPPER_VIEW, payload);
  }
  register(payload: TriggerPayloads[TriggerNames.REGISTER]) {
    this.track(TriggerNames.REGISTER, payload);
  }
  // #endregion REGISTER

  // #region PAGE VIEW
  pageView(payload: TriggerPayloads[TriggerNames.PAGE_VIEW]) {
    this.track(TriggerNames.PAGE_VIEW, payload);
  }
  // #endregion PAGE VIEW

  // #region TRANSACTION

  purchaseEvent({
    trxId,
    isBatch,
    isDomestic,
  }: {
    trxId: string;
    isBatch: boolean;
    isDomestic: boolean;
  }) {
    if (isDomestic) {
      if (isBatch) {
        this.purchaseDomesticMultiple({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      } else {
        this.purchaseDomesticSingle({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      }
    } else {
      if (isBatch) {
        this.purchaseMultiple({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      }
      this.confirmPayment({
        transaction_id: trxId,
        value: 1,
        currency: 'IDR',
      });
    }
  }

  addPaymentInfoEvent({
    trxId,
    isBatch,
    isDomestic,
    method,
    bank,
  }: {
    trxId: string;
    isBatch?: boolean;
    isDomestic?: boolean;
    method: PaymentMethod;
    bank: BankName;
  }) {
    if (isDomestic) {
      if (isBatch) {
        this?.addPaymentInfoDomesticMultiple({
          transaction_id: trxId,
          type: TransactionType.DOMESTIC,
          method,
          bank,
        });
      } else {
        this?.addPaymentInfoDomesticSingle({
          transaction_id: trxId,
          type: TransactionType.DOMESTIC,
          method,
          bank,
        });
      }
    } else {
      if (isBatch) {
        this.addPaymentInfoMultiple({
          transaction_id: trxId,
          type: TransactionType.REMIT,
          method,
          bank,
        });
      } else {
        this?.choosePaymentMethod({
          transaction_id: trxId,
          type: TransactionType.REMIT,
          method,
          bank,
        });
      }
    }
  }

  transactionTrack(payload: TriggerPayloads[TriggerNames.TRANSACTION_TRACK]) {
    this.track(TriggerNames.TRANSACTION_TRACK, payload);
  }

  shareTrackingLink(payload: TriggerPayloads[TriggerNames.SHARE_TRACKING]) {
    this.track(TriggerNames.SHARE_TRACKING, payload);
  }

  choosePaymentMethod(payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO]) {
    this.track(TriggerNames.ADD_PAYMENT_INFO, payload);
  }
  addPaymentInfoDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE, payload);
  }
  addPaymentInfoDomesticSingle(
    payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE, payload);
  }
  addQuotationDetail(
    payload: TriggerPayloads[TriggerNames.ADD_QUOTATION_DETAIL],
  ) {
    this.track(TriggerNames.ADD_QUOTATION_DETAIL, payload);
  }
  addToCart(payload: TriggerPayloads[TriggerNames.ADD_TO_CART]) {
    this.track(TriggerNames.ADD_TO_CART, payload);
  }
  addToCartDomestic(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_DOMESTIC],
  ) {
    this.track(TriggerNames.ADD_TO_CART_DOMESTIC, payload);
  }
  addToCartDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE, payload);
  }
  addToCartDomesticSingle(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_DOMESTIC_SINGLE, payload);
  }
  failedAddToCart(payload: TriggerPayloads[TriggerNames.ADD_TO_CART_FAILED]) {
    this.track(TriggerNames.ADD_TO_CART_FAILED, payload);
  }
  backButton(payload: TriggerPayloads[TriggerNames.BACK_BUTTON]) {
    this.track(TriggerNames.BACK_BUTTON, payload);
  }
  beginCheckout(payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT]) {
    this.track(TriggerNames.BEGIN_CHECKOUT, payload);
  }
  beginCheckoutDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE, payload);
  }
  beginCheckoutDomesticSingle(
    payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE, payload);
  }
  cancelTransaction(payload: TriggerPayloads[TriggerNames.CANCEL_PURCHASE]) {
    this.track(TriggerNames.CANCEL_PURCHASE, payload);
  }
  checkPaymentStatus(
    payload: TriggerPayloads[TriggerNames.CHECK_PAYMENT_STATUS],
  ) {
    this.track(TriggerNames.CHECK_PAYMENT_STATUS, payload);
  }
  choosePayment(payload: TriggerPayloads[TriggerNames.CHOOSE_BANK]) {
    this.track(TriggerNames.CHOOSE_BANK, payload);
  }
  confirmPayment(payload: TriggerPayloads[TriggerNames.CONFIRM_PAYMENT]) {
    this.track(TriggerNames.CONFIRM_PAYMENT, payload);
  }
  downloadInvoice(payload: TriggerPayloads[TriggerNames.DOWNLOAD_INVOICE]) {
    this.track(TriggerNames.DOWNLOAD_INVOICE, payload);
  }
  purchaseDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.PURCHASE_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.PURCHASE_DOMESTIC_MULTIPLE, payload);
  }
  purchaseDomesticSingle(
    payload: TriggerPayloads[TriggerNames.PURCHASE_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.PURCHASE_DOMESTIC_SINGLE, payload);
  }
  refreshTimer(payload: TriggerPayloads[TriggerNames.REFRESH_TIMER]) {
    this.track(TriggerNames.REFRESH_TIMER, payload);
  }
  transactionSelected(
    payload: TriggerPayloads[TriggerNames.TRANSACTION_SELECTED],
  ) {
    this.track(TriggerNames.TRANSACTION_SELECTED, payload);
  }
  transactionSelectedDetail(
    payload: TriggerPayloads[TriggerNames.TRANSACTION_SELECTED_DETAIL],
  ) {
    this.track(TriggerNames.TRANSACTION_SELECTED_DETAIL, payload);
  }
  viewCountryCurrency(
    payload: TriggerPayloads[TriggerNames.VIEW_COUNTRY_CURRENCY],
  ) {
    this.track(TriggerNames.VIEW_COUNTRY_CURRENCY, payload);
  }
  viewItem(payload: TriggerPayloads[TriggerNames.VIEW_ITEM]) {
    this.track(TriggerNames.VIEW_ITEM, payload);
  }

  // #endregion TRANSACTION

  // #region VIEW
  pageViewDomestic() {
    const payload: TriggerPayloads[TriggerNames.PAGE_VIEW_DOMESTIC] = {
      source: location.href,
      prev_source: document.referrer,
    };
    this.track(TriggerNames.PAGE_VIEW_DOMESTIC, payload);
  }
  readNotification(payload: TriggerPayloads[TriggerNames.NOTIFICATION]) {
    this.track(TriggerNames.NOTIFICATION, payload);
  }
  // #endregion VIEW

  // #region WALLET
  walletTopup(payload: TriggerPayloads[TriggerNames.WALLET_TOPUP]) {
    this.track(TriggerNames.WALLET_TOPUP, payload);
  }
  walletWithdraw(payload: TriggerPayloads[TriggerNames.WALLET_WITHDRAW]) {
    this.track(TriggerNames.WALLET_WITHDRAW, payload);
  }
  walletCancelTopup(
    payload: TriggerPayloads[TriggerNames.WALLET_CANCEL_TOPUP],
  ) {
    this.track(TriggerNames.WALLET_CANCEL_TOPUP, payload);
  }
  // #endregion WALLET

  //#region TRANSACTION MULTIPLE

  viewCountryCurrencyMultiple(
    payload: TriggerPayloads[TriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE],
  ) {
    this.track(TriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE, payload);
  }

  addToCartMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_MULTIPLE, payload);
  }

  addPaymentInfoMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_PAYMENT_INFO_MULTIPLE, payload);
  }

  purchaseMultiple(payload: TriggerPayloads[TriggerNames.PURCHASE_MULTIPLE]) {
    this.track(TriggerNames.PURCHASE_MULTIPLE, payload);
  }

  beginCheckoutMultiple(
    payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT_MULTIPLE],
  ) {
    this.track(TriggerNames.BEGIN_CHECKOUT_MULTIPLE, payload);
  }

  failedAddToCartMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_FAILED_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_FAILED_MULTIPLE, payload);
  }

  viewItemMultiple(payload: TriggerPayloads[TriggerNames.VIEW_ITEM_MULTIPLE]) {
    this.track(TriggerNames.VIEW_ITEM_MULTIPLE, payload);
  }

  cancelPurchaseMultiple(
    payload?: TriggerPayloads[TriggerNames.CANCEL_PURCHASE_MULTIPLE],
  ) {
    this.track(TriggerNames.CANCEL_PURCHASE_MULTIPLE, payload);
  }

  //#endregion TRANSACTION MULTIPLE
}

export default TracksBusiness;
