import {
  clientOnboardingPageType,
  clientOnboardingStep,
} from '@modules/client-onboarding/types';
import { create } from 'zustand';

import { SetStateStore, setStore } from './helper';

interface IClientOnboardingStore {
  currentStep: clientOnboardingStep;
  currentPage: clientOnboardingPageType;
  isAddOtherBank: boolean;
  isCloseClientCard: boolean;
  selectedTab: string;
  showStatementLetterDialog: boolean;
  isErrorFromVerification: boolean;
  setCurrentStep: (value: SetStateStore<clientOnboardingStep>) => void;
  setCurrentPage: (value: SetStateStore<clientOnboardingPageType>) => void;
  setIsAddOtherBank: (value: SetStateStore<boolean>) => void;
  setIsCloseClientCard: (value: SetStateStore<boolean>) => void;
  setSelectedTab: (value: SetStateStore<string>) => void;
  setShowStatementLetterDialog: (value: SetStateStore<boolean>) => void;
  setIsErrorFromVerification: (value: SetStateStore<boolean>) => void;
  resetClientOnboardingStore: () => void; //Handle account changes when a user is undergoing KYC for another account.
}

const initialState = {
  currentStep: clientOnboardingStep.DEFAULT,
  isAddOtherBank: false,
  currentPage: 'ADD' as clientOnboardingPageType,
  selectedTab: '',
  isCloseClientCard: false,
  isCloseSaveAsDraft: false,
  showStatementLetterDialog: false,
  isErrorFromVerification: false,
};

export const useClientOnboardingStore = create<IClientOnboardingStore>(
  (set) => ({
    ...initialState,
    setCurrentStep: (value) =>
      set((state) => setStore(state, { currentStep: value })),
    setIsAddOtherBank: (value) =>
      set((state) => setStore(state, { isAddOtherBank: value })),
    setIsCloseClientCard: (value) =>
      set((state) => setStore(state, { isCloseClientCard: value })),
    setCurrentPage: (value) =>
      set((state) => setStore(state, { currentPage: value })),
    setSelectedTab: (value) =>
      set((state) => setStore(state, { selectedTab: value })),
    setShowStatementLetterDialog: (value) =>
      set((state) => setStore(state, { showStatementLetterDialog: value })),
    setIsErrorFromVerification: (value) =>
      set((state) => setStore(state, { isErrorFromVerification: value })),
    resetClientOnboardingStore: () =>
      set(() => ({
        ...initialState,
      })),
  }),
);
