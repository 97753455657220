import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

import CountrySelect from './CountrySelect';
import { handleInputCondition, mergeRefs } from '../../../../common/helper';
import { IPhoneNumberInputPropsV2 } from '../types';

const variants = {
  default: {
    padding: '15px 20px 15px 20px',
  },
  focus: {
    padding: '25px 20px 5px 20px',
  },
  filled: {
    padding: '25px 20px 5px 20px',
  },
};

const PhoneNumberInputV2Internal = forwardRef(
  (props: IPhoneNumberInputPropsV2) => {
    const {
      name,
      label,
      value,
      className,
      helperText,
      defaultValue,
      error,
      bordered = true,
      dialCodeOptions: _dialCodeOptions,
      isSearchable = true,
      inputWrapperClassName,
      selectRef: selectRefProp,
      onChange,
      onBlur,
      onChangeCountry,

      disabled,
      disabledSelectCountry = false,
    } = props;

    const [dialCode, setDialCode] = useState<string | undefined>();
    const [inputNumber, setInputNumber] = useState<string | undefined>();
    const [isTriggered, setIsTriggered] = useState(false);

    const [isFocusInput, setIsFocusInput] = useState(false);

    const inputRef = useRef<any>(null);
    const selectRef = useRef<any>(null);
    const fieldGroupRef = useRef<any>();
    const wrapperRef = useRef<any>(null);
    const rightIconWrapperRef = useRef<any>(null);

    const dialCodeOptions = useMemo(
      () =>
        _dialCodeOptions
          ? Array.isArray(_dialCodeOptions)
            ? _dialCodeOptions
            : [_dialCodeOptions]
          : undefined,
      [_dialCodeOptions],
    );

    const isError = !!error;
    const isFilled = !!inputNumber;

    const fieldGroupClass = cx(className, { disabled });
    const inputWrapperClass = cx(inputWrapperClassName);
    const inputClass = cx(
      styled.inputField(disabled),
      'input-control',
      'regular-text',
      {
        error: isError,
      },
    );
    const inputFormControlProps = {
      variants,
      className: 'form-control',
      animate: handleInputCondition({
        isFocus: isFocusInput,
        isFilled,
      }),
      onClick: () => inputRef.current.focus(),
    };

    const selectedCountry = useMemo(
      () => dialCodeOptions?.find((option) => option?.dialCode === dialCode),
      [dialCodeOptions, dialCode],
    );

    function handleChangeDialCode(value) {
      setDialCode(value);

      const isoCode =
        dialCodeOptions?.find((option) => option?.dialCode === value)
          ?.isoCode || '';

      onChangeCountry?.({
        dialCode: value,
        isoCode,
        value: inputNumber || '',
      });

      let _value = '';

      if (inputNumber) {
        _value = `+${value}${inputNumber || ''}`;
      }

      onChange?.(_value);
    }

    function handleChangeInputNumber(value) {
      setInputNumber(value);

      let _value = '';

      if (value) {
        _value = `+${dialCode}${value || ''}`;
      }

      onChange?.(_value);
    }

    /** Set default selected if dialCodeDefaultCountry exist */
    useEffect(() => {
      if (!dialCodeOptions) return;
      if (!defaultValue || dialCode) {
        if (!value) return;
        if (
          dialCode &&
          value &&
          inputNumber &&
          value === `+${dialCode}${inputNumber}`
        ) {
          return;
        }
      }

      const currentValue = value || defaultValue?.value;
      let countryCallingCode: string | undefined = undefined;

      if (currentValue || defaultValue?.dialCodeCountry) {
        countryCallingCode = value
          ? parsePhoneNumberFromString(value! as any)?.countryCallingCode
          : undefined;

        const defaultCountry = dialCodeOptions.find(
          (option) => option?.isoCode === defaultValue?.dialCodeCountry,
        );

        const currentValueCountry = dialCodeOptions.find(
          (option) => option?.dialCode === countryCallingCode,
        );

        const currentDialCode =
          currentValueCountry?.dialCode || defaultCountry?.dialCode;

        if ((!dialCode || dialCode !== currentDialCode) && currentDialCode) {
          setDialCode((prev) =>
            prev !== currentDialCode ? currentDialCode : prev,
          );
        }

        const _value = currentValue
          ? currentValue?.split?.(`+${currentDialCode}`)?.[1]
          : undefined;

        setInputNumber((prev) => (prev !== _value ? _value : prev));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue, dialCodeOptions]);

    useEffect(() => {
      if (isTriggered) return;

      if (dialCode) {
        const isoCode =
          dialCodeOptions?.find((option) => option?.dialCode === dialCode)
            ?.isoCode || '';

        onChangeCountry?.({
          dialCode: dialCode || '',
          isoCode: isoCode || '',
          value: inputNumber || '',
        });

        setIsTriggered(true);
      }
    }, [
      dialCode,
      dialCodeOptions,
      inputNumber,
      isTriggered,
      onChangeCountry,
      value,
    ]);

    return (
      <FieldGroup
        isError={isError}
        disabled={disabled}
        isFilled={isFilled}
        ref={fieldGroupRef}
        bordered={bordered}
        helper={helperText}
        error={error}
        isFocus={isFocusInput}
        borderStyle={{ zIndex: 2 }}
        className={fieldGroupClass}
        labelClassName={styled.label}
        inputWrapperClassName={inputWrapperClass}
        rightIconWrapperRef={rightIconWrapperRef}
        inputWrapperRef={mergeRefs(wrapperRef, selectRefProp)}
      >
        <CountrySelect
          ref={selectRef}
          name="phone-country"
          value={dialCode}
          label={label}
          selectedCountry={selectedCountry}
          options={dialCodeOptions || []}
          onChange={handleChangeDialCode}
          container={wrapperRef.current}
          isSearchable={isSearchable}
          disabled={disabled || disabledSelectCountry}
        />
        <FieldGroup
          name={name}
          label={label}
          isFilled={isFilled}
          disabled={disabled}
          isFocus={isFocusInput}
          formControlProps={inputFormControlProps}
          className={styled.inputFieldGroup(isError, disabled)}
        >
          <NumericFormat
            type="tel"
            value={inputNumber}
            allowLeadingZeros
            decimalSeparator=""
            displayType="input"
            disabled={disabled}
            allowNegative={false}
            getInputRef={inputRef}
            onBlur={() => {
              setIsFocusInput(false);
              onBlur?.();
            }}
            onFocus={() => setIsFocusInput(true)}
            className={cx(inputClass, 'numeric-format-base')}
            onValueChange={(e) => {
              handleChangeInputNumber(e.formattedValue);
            }}
          />
        </FieldGroup>
      </FieldGroup>
    );
  },
);

const styled = {
  label: css`
    margin-left: 100px !important;
  `,
  inputFieldGroup: (isError: boolean, isDisabled?: boolean) => css`
    margin: 0 !important;
    flex: 2;
    min-width: unset !important;
    background-color: transparent;
    .form-label {
      color: ${isDisabled
        ? 'var(--text-disabled)'
        : isError
          ? 'var(--red-500)'
          : 'var(--text-secondary)'}!important;
    }
  `,
  inputField: (isDisabled?: boolean) => css`
    color: ${isDisabled
      ? 'var(--text-disabled)'
      : 'var(--text-primary)'}!important;
  `,
};

const PhoneNumberInputV2 = ({
  control,
  name,
  ...props
}: IPhoneNumberInputPropsV2 & {
  control?: Control<any>;
}) => {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        render={({
          field: { value, onChange: defaultOnChange, onBlur },
          fieldState,
        }) => (
          <PhoneNumberInputV2Internal
            value={value}
            {...props}
            name={name}
            error={fieldState?.error?.message}
            onChange={(value) => {
              defaultOnChange?.(value);
              props?.onChange?.(value);
            }}
            onBlur={onBlur}
          />
        )}
      />
    );
  }

  return <PhoneNumberInputV2Internal name={name} {...props} />;
};

PhoneNumberInputV2.displayName = 'PhoneNumberInputV2';

export default PhoneNumberInputV2;
