import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

import { callAllFunctions } from '../../../common/helper';
import { useDrawer } from '../../../hooks';
import Chip from '../Chip';

interface IDrawerButton {
  leftIcon?: ReactNode;
  className?: string;
  children: string;
  onClick?: () => void;
}

export default function DrawerButton({
  leftIcon,
  className,
  children,
  onClick,
}: IDrawerButton) {
  const { onOpen } = useDrawer();
  return (
    <Chip
      label={children}
      leftIcon={leftIcon}
      className={cx(styled.drawerChip, className)}
      onClick={callAllFunctions(onOpen, onClick)}
    />
  );
}

const styled = {
  drawerChip: css`
    padding: 0.5rem 1rem;
    height: fit-content;
  `,
};
