import { css, cx } from '@emotion/css';

import { handleLongText } from '../../common/helper';
interface ILabelValue {
  className?: string;
  label: string;
  value: string | string[];
  type: 'inline' | 'updown';
  minWidthLabel?: number;
  longText?: number;
}
/**
 * @param type : "inline" | "updown";
 * @param minWidthLabel : number; - This props need to be use when type is "in-line" , default value is 0 and the value in rem.
 */
export default function LabelValue(props: ILabelValue) {
  const {
    type,
    label,
    value,
    className,
    minWidthLabel = 0,
    longText = 0,
  } = props;
  if (type === 'inline' && minWidthLabel === 0) {
    throw Error(
      `Max length Label is needed (Default 0) when type is 'in-line' in Field label ${label}`,
    );
  }
  return (
    <div
      className={cx(className, styled.labelValue, {
        [styled.inline(minWidthLabel)]: type === 'inline',
        [styled.updown]: type === 'updown',
      })}
    >
      <span className="label">{label}</span>
      {Array.isArray(value) ? (
        value.map((val, idx) => {
          return (
            <span className="value value-padding" key={idx}>
              {longText ? handleLongText(val, longText) : val}
            </span>
          );
        })
      ) : (
        <span className="value">
          {longText ? handleLongText(value, longText) : value}
        </span>
      )}
    </div>
  );
}

const styled = {
  labelValue: css`
    display: flex;
    padding-bottom: 1.25rem;
    .label .value {
      text-wrap: wrap;
    }
    .value-padding {
      padding-bottom: 0.5rem;
    }
  `,
  inline: (maxWidth: number) => css`
    display: grid;
    grid-template-columns: ${maxWidth}px 1fr;

    .value {
      margin-left: 0.5rem;
      text-wrap: wrap;
    }
  `,
  updown: css`
    flex-direction: column;
  `,
};
