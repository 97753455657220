import { css } from '@emotion/react';

import { screenSize } from '../common/size';

export default css`
  .ant-select {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: var(--font-family);
    > .ant-select-selector {
      color: var(--text-primary);
    }

    &.disabled {
      > .ant-select-selector > .ant-select-selection-placeholder {
        color: var(--text-disabled);
      }
    }

    > .ant-select-selector {
      height: 2rem;
      pointer-events: none;

      @media (max-width: ${screenSize.mobileLg}px) {
        font-size: 0.875rem;
      }
    }

    &.ant-select-single {
      .ant-select-selector {
        font-size: 1rem;
      }
      &:not(.ant-select-customize-input) {
        .ant-select-selector {
          padding: 0 0;
          .ant-select-selection-item {
            display: flex;
            > * {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      .ant-select-selection-search {
        left: 0;
        width: 100%;
        > .ant-select-selection-search-input {
          pointer-events: none;
          user-select: text;
        }
      }
    }
    &.center {
      &.ant-select-show-arrow {
        .ant-select-selection-search {
          left: 10% !important;
        }
        .ant-select-selection-item {
          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }

  .ant-select + .ant-input-suffix {
    pointer-events: none;
    user-select: none;
  }

  .ant-select-dropdown {
    border-radius: 8px;
    width: 100% !important;
    padding: 0;
    padding-inline-start: unset;
    inset: 72.3px auto auto 0px !important;
    .ant-select-item {
      padding: unset;
      min-height: 46px;
      font-size: 1rem;
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        overflow: visible !important;
        white-space: normal !important;
      }
    }
    .ant-select-item-option-grouped {
      padding-inline-start: unset;
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
      background: var(--neutral-100);
      color: var(--text-primary);
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: var(--blue-500);
      background: var(--blue-100);
    }
    .rc-virtual-list-holder {
      overflow-x: hidden;
    }
    .rc-virtual-list-scrollbar {
      width: 6px !important;
      border-radius: 10px !important;
    }
    .rc-virtual-list-scrollbar-thumb {
      width: 6px !important;
      background: var(--neutral-200) !important;
      border-radius: 10px !important;
    }
  }

  .ant-select-item,
  .ant-select-item-group {
    min-height: unset;
  }

  .ant-select-item,
  .ant-select-item-empty {
    padding: 0;
  }

  .ant-select-item-group {
    height: 1.375rem;
    padding: 0 1.25rem;
  }
  .ant-select-item-empty {
    .dropdown-item {
      cursor: default;
      text-align: center;
    }
  }

  .ant-select-item {
    padding: 0.875rem 1.25rem !important;
    &.ant-select-item-option {
      > .ant-select-item-option-content > * {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &.ant-select-item-option-active {
      background-color: var(--blue-100);
      color: var(--blue-500);
      font-weight: var(--regular-font-weight);
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    transition: unset;
  }
`;
