import { cx } from '@emotion/css';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

import { callAllFunctions } from '../../../common/helper';
import { useDropdown } from '../../../hooks';
import Flex from '../Flex';

export interface IDropdownItemProps {
  children: ReactNode;
  value?: string;
  href?: string;
  externalLink?: boolean;
  className?: string;
  onClick?: () => void;
}

export default function DropdownItem({
  value,
  href,
  externalLink,
  className,
  children,
  onClick,
}: IDropdownItemProps) {
  const { pathname } = useRouter();

  const { selectedValue, onChangeValue } = useDropdown();

  function handleSelectItem(value?: string) {
    if (value && selectedValue !== value) {
      onChangeValue(value);
    }
  }

  return (
    <>
      {href ? (
        <Link
          href={href}
          className={cx('dropdown-item', className, {
            selected: pathname === href, // Note: this need to be here , not all component used this dropdown item has url/href
          })}
          onClick={onClick}
          {...(externalLink && { target: '_blank' })}
        >
          {children}
        </Link>
      ) : (
        <Flex
          onClick={callAllFunctions(() => handleSelectItem(value), onClick)}
          className={cx('dropdown-item', className, {
            selected: selectedValue === value,
          })}
        >
          {children}
        </Flex>
      )}
    </>
  );
}
