import { usePostS2SIdentifier } from '@api-hooks/analytic-identifier';
import { setCookie, TR_MEMBER_ID } from '@topremit/shared-web/common/cookie';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { S2S_META_DATA_KEY } from '@topremit/shared-web/common/storage';
import {
  S2SIdentifierPayload,
  S2SIdentifiers,
} from '@topremit/shared-web/typings/analytics.model';
import CryptoJS from 'crypto-js';
import addDays from 'date-fns/addDays';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import usePermission from './use-permission';

interface InitIdentifiersArgs {
  memberId?: string;
}

const S2S_EXCLUDED_SOURCES = ['brevo', 'sendinblue'] as const;

/**
 * TODO:

 * - move this to Topremit analytics library (refinement)
 */
export default function useS2SAnalytic() {
  const { mutateAsync } = usePostS2SIdentifier();
  const { isBusinessAccount } = usePermission();

  /**
   * function to harvest all identifiers from web cookies
   * and other source like query params
   */
  function initIdentifiers({ memberId }: InitIdentifiersArgs) {
    const { _fbp, _fbc, _ga, ttclid: tiktokClientId, afUserId } = getCookies();
    const ga4ClientId = _ga?.substring(6);
    const gaSessionId = _ga?.slice(-10);
    const metaExternalId = CryptoJS.SHA256(_fbp).toString(CryptoJS.enc.Hex); // depend on GTM variable {{hash fbp}}

    const searchParams = new URLSearchParams(window.location.search);

    if (includes(S2S_EXCLUDED_SOURCES, searchParams.get('utm_source'))) {
      return; // skip if source is excluded from s2s tracking
    }

    const utmAttributes = {
      utmMedium: searchParams.get('utm_medium'),
      utmSource: searchParams.get('utm_source'),
      utmCampaign: searchParams.get('utm_campaign'),
      utmContent: searchParams.get('utm_content'),
      gadSource: searchParams.get('gad_source'),
      gclid: searchParams.get('gclid'),
      meta_fbc: _fbc,
      meta_fbp: _fbp,
    };

    const prevIdentifiers = getLocalS2SIdentifiers();
    const utmPayloads = omitBy(utmAttributes, isNil);

    // used as variable (tr_member_id) at GTM
    setCookie({ name: TR_MEMBER_ID, value: memberId || '' });
    // store identifiers
    setLocalS2SIdentifiers({
      ...prevIdentifiers,
      metaExternalId,
      ga4ClientId,
      gaSessionId,
      appsflyerId: null, // only available for mobile
      afUserId,
      tiktokClientId,
      memberId: typeof memberId !== 'undefined' ? String(memberId) : undefined,
      ...utmPayloads,
    });
  }

  function setLocalS2SIdentifiers(
    payload: Omit<S2SIdentifiers, 'expiredAt'> & { expiredAt?: string },
  ) {
    return localStorage.setItem(
      S2S_META_DATA_KEY,
      JSON.stringify({
        ...payload,
        expiredAt: addDays(new Date(), 1),
      }),
    );
  }

  function getLocalS2SIdentifiers() {
    const prevIdentifiers = localStorage.getItem(S2S_META_DATA_KEY);

    return prevIdentifiers
      ? (JSON.parse(prevIdentifiers) as S2SIdentifiers)
      : null;
  }

  function trackS2S(payload: S2SIdentifierPayload) {
    if (isNativeApp()) {
      return;
    }

    const prevIdentifiers = getLocalS2SIdentifiers();

    mutateAsync({
      device: isBusinessAccount ? 'WEB_BUSINESS' : 'WEB_PERSONAL',
      ...prevIdentifiers,
      ...payload,
    });
  }

  function getCookies() {
    const cookiesString = document.cookie;
    const cookies = cookiesString.split(';');
    const result: {
      [key: string]: any;
    } = {};
    cookies.map((cookie) => {
      const _cookie = cookie.trim();
      const name = _cookie.split('=')[0];
      const value = _cookie.split('=')[1];
      if (name && value) {
        result[name] = value;
      }
    });
    return result;
  }

  return { trackS2S, initIdentifiers };
}
