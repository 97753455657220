import * as React from 'react';
import { SVGProps } from 'react';
const ChatUsFill = (props: SVGProps<SVGSVGElement>) => {
  const { fill = 'var(--neutral-200)', width = 24, height = 24 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 2H7C4.24 2 2 4.23112 2 6.98249V12.9655V13.966C2 16.7174 4.24 18.9485 7 18.9485H8.5C8.77 18.9485 9.13 19.1286 9.3 19.3487L10.8 21.3397C11.46 22.2201 12.54 22.2201 13.2 21.3397L14.7 19.3487C14.89 19.0985 15.19 18.9485 15.5 18.9485H17C19.76 18.9485 22 16.7174 22 13.966V6.98249C22 4.23112 19.76 2 17 2ZM8 12.005C7.44 12.005 7 11.5548 7 11.0045C7 10.4542 7.45 10.004 8 10.004C8.55 10.004 9 10.4542 9 11.0045C9 11.5548 8.56 12.005 8 12.005ZM12 12.005C11.44 12.005 11 11.5548 11 11.0045C11 10.4542 11.45 10.004 12 10.004C12.55 10.004 13 10.4542 13 11.0045C13 11.5548 12.56 12.005 12 12.005ZM16 12.005C15.44 12.005 15 11.5548 15 11.0045C15 10.4542 15.45 10.004 16 10.004C16.55 10.004 17 10.4542 17 11.0045C17 11.5548 16.56 12.005 16 12.005Z"
        fill={fill}
      />
    </svg>
  );
};
export default ChatUsFill;
