import { useGetAccountInfo } from '@api-hooks/common';
import { AccountInfoBusiness, KycStatus } from '@api-hooks/common/common.model';
import { AuthorizationRules } from '@common/rules';
import { usePermission } from '@hooks';
import bolyFailedImage from '@public/images/svg/boly-failed.svg';
import bolyNotFoundImage from '@public/images/svg/boly-not-found.svg';
import bolyVerificationImage from '@public/images/svg/boly-verification.svg';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

export function useGetAffiliateBlockingModalProps() {
  const { t } = useTranslation();
  const { can } = usePermission();
  const closeDialog = useDialogStore((store) => store.close);

  const isAffiliatedClient = can(AuthorizationRules.MenusAffiliate);

  const { data: accountInfo } = useGetAccountInfo<AccountInfoBusiness>();

  const kycStatus = accountInfo?.data?.kycStatus?.value;

  if (!isAffiliatedClient) {
    return {
      title: t('home:client_blocking_modal.no_access.title'),
      description: t('home:client_blocking_modal.no_access.desc'),
      buttonLabel: t('okay_got_it'),
      imgSrc: bolyNotFoundImage,
      imgStyle: { width: 200, height: 200 },
      onClick: () => {
        closeDialog();
      },
    };
  } else if (
    kycStatus !== KycStatus.COMPLIANCE_REJECTED &&
    kycStatus !== KycStatus.DATA_APPROVED
  ) {
    return {
      title: t('home:client_blocking_modal.need_verification.title'),
      description: t('home:client_blocking_modal.need_verification.desc'),
      buttonLabel: t('okay_got_it'),
      imgSrc: bolyVerificationImage,
      imgStyle: { width: 200, height: 200 },
      onClick: () => {
        closeDialog();
      },
    };
  } else if (kycStatus === KycStatus.COMPLIANCE_REJECTED) {
    return {
      title: t('home:client_blocking_modal.rejected.title'),
      description: t('home:client_blocking_modal.rejected.desc'),
      buttonLabel: t('okay_got_it'),
      imgSrc: bolyFailedImage,
      imgStyle: { width: 200, height: 200 },
      onClick: () => {
        closeDialog();
      },
    };
  }
  return null;
}
