import { css } from '@emotion/css';

import { getBorder } from './helper';
import { fontWeight, screenSize } from '../../../../common/size';
import { color } from '../../../../styles/color';

export const styles = {
  root: ({
    bordered,
    disabled,
    isFocus,
    isHover,
    error,
  }: {
    error: boolean;
    isFocus: boolean;
    isHover: boolean;
    disabled: boolean | undefined;
    bordered: boolean | undefined;
  }) => {
    return css`
      width: 100%;
      height: 64px;
      border: ${getBorder(bordered, disabled, isFocus, isHover, error)};
      border-radius: 0.5rem;
      overflow: hidden;
    `;
  },
  triggerButton: ({
    disabled,
    hasValue,
  }: {
    hasValue: boolean;
    disabled: boolean | undefined;
  }) => css`
    width: 100%;
    text-align: left;
    padding: ${hasValue ? '12px 20px' : '15px 20px'};
    background-color: ${disabled ? 'var(--neutral-100)' : 'var(--neutral-0)'};
  `,
  triggerContent: css`
    position: relative;
    height: 100%;
  `,
  label: ({
    disabled,
    hasValue,
    error,
    showLabelWhenHasValue,
  }: {
    hasValue: boolean;
    disabled: boolean | undefined;
    error: boolean;
    showLabelWhenHasValue: boolean | undefined;
  }) => css`
    display: ${hasValue ? (showLabelWhenHasValue ? '' : 'none') : ''};
    color: ${disabled
      ? 'var(--text-disabled)'
      : error
        ? color.red500
        : 'var(--text-secondary)'};
    position: absolute;
    top: ${hasValue ? '-5px' : '50%'};
    transform: ${hasValue ? 'none' : 'translateY(-50%)'};
    font-size: ${hasValue ? '14px' : '16px'};
    cursor: pointer;
    transition:
      top 0.3s ease,
      font-size 0.3s ease;

    @media (max-width: ${screenSize.tabletMd}px) {
      top: ${hasValue ? '-3px' : '50%'};
      font-size: ${hasValue ? '12px' : '14px'};
    }
  `,
  choosedOption: css`
    position: relative;
    margin-right: 0.625rem;
  `,
  arrow: css`
    position: absolute;
    right: 9px;
    top: 50%;
    transform: translateY(-50%);
  `,

  labelInput: css`
    font-size: 22px;
    font-weight: ${fontWeight.bold};
    margin-top: 0;
    margin-bottom: 1rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 18px;
    }
  `,
  labelWrapper: ({
    hasValue,
    showLabelWhenHasValue,
  }: {
    hasValue: boolean;
    showLabelWhenHasValue: boolean | undefined;
  }) => css`
    display: flex;
    flex-direction: column;
    justify-content: end;
    height: ${hasValue && !showLabelWhenHasValue ? 'auto' : '100%'};
  `,
  searchBar: css`
    padding: 1rem;
    margin: unset !important;
    .input-wrapper {
      .form-control {
        padding-top: 0 !important;
      }
    }
  `,
  optionWrapper: css`
    display: flex;
    width: 100%;
    flex-direction: column;
  `,
  labelOptionGroup: css`
    text-align: left;
    font-size: 16px;
    color: ${color.neutral500};
    font-weight: ${fontWeight.bold};
    cursor: default;
    font-weight: ${fontWeight.bold};
    font-size: 16px;
  `,
  labelAllOptionGroup: css`
    margin-top: 0.75rem;
  `,
  dialogOptionsWrapperContainer: css`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    contain: strict;
  `,
  dialogOptionsWrapper: (height) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
    position: relative;
    height: ${height}px;
  `,
  dialogVirtualWrapper: (translate) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(${translate ?? 0}px);
  `,
  searchInput: css`
    margin: 0.5rem 0 !important;
  `,
  headerWrapper: css`
    width: 100%;
    position: sticky;
    z-index: 99999;
    background: ${color.neutral0};
  `,
  dialogContentWrapper: css`
    ::-webkit-scrollbar {
      display: none !important;
    }
  `,
  emptyState: css`
    padding: 1.5rem 1.5rem 0;
    cursor: default;
    > .bold-text {
      color: var(--text-primary);
    }
  `,
  requestCountryCurrency: css`
    cursor: default;
    > .notes {
      margin-top: 16px;
      margin-bottom: 0;
      > .ant-alert-content {
        display: flex;
        text-align: left;
        white-space: normal;
      }
    }
    @media (max-width: ${screenSize.tabletMd}px) {
      padding: 0;
    }
  `,
  flagSkeleton: css`
    align-self: center;
    width: 1.75rem;
    height: 1.5rem;
    background: var(--neutral-300);
    animation: blink 1.5s infinite;
    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
  `,
};
