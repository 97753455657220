import { color } from '@topremit/shared-web/styles/color';

export function getArrowColor(
  isDisabled: boolean | undefined,
  error: string | undefined,
) {
  if (isDisabled) {
    return color.neutral300;
  }
  if (error) {
    return color.red500;
  }
  return color.neutral500;
}

export function getBorder(
  bordered: boolean | undefined,
  disabled: boolean | undefined,
  isFocus: boolean,
  isHover: boolean,
  error: boolean,
) {
  if (!bordered) {
    return 'none';
  }
  if (disabled) {
    return '1px solid var(--neutral-200)';
  }
  if (error) {
    return '2px solid var(--red-500)';
  }
  if (isFocus) {
    return '2px solid var(--blue-500)';
  }
  if (isHover) {
    return '1px solid var(--neutral-500)';
  }

  return '1px solid var(--neutral-200)';
}
