import { css, cx } from '@emotion/css';
import Image, { ImageProps } from 'next/image';

import Flex from './Flex';

interface IResponsiveImageProps extends Omit<ImageProps, 'width' | 'height'> {
  ratio: number;
  className?: string;
  maxWidth?: number;
  dataNosnippet?: boolean;
}

export default function ResponsiveImage({
  ratio,
  className,
  maxWidth,
  dataNosnippet,
  ...props
}: IResponsiveImageProps) {
  return (
    <Flex
      className={cx(className, styled.root(ratio, maxWidth))}
      {...(dataNosnippet && { 'data-nosnippet': true })}
    >
      <Image fill {...props} />
    </Flex>
  );
}

const styled = {
  root: (ratio: number, maxWidth?: number) => css`
    position: relative;
    aspect-ratio: ${ratio};
    max-width: ${maxWidth ? `${maxWidth}px` : 'unset'};
  `,
};
