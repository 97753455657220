import { PaymentMethod } from '@api-hooks/transaction';
import { TransactionType } from '@topremit/shared-web/api-hooks/transaction';
import { getItemInCookies } from '@topremit/shared-web/common/helper';
import {
  isNativeApp,
  sendNativeAnalytic,
} from '@topremit/shared-web/common/native-web-view-bridge';
import {
  LandingTriggerNames as TriggerNames,
  LandingTriggerPayloads as TriggerPayloads,
} from '@topremit/shared-web/typings/analytics-landing.model';
import {
  AfCustomEvents,
  AfStandartEvents,
  FbCustomEvents,
  FbStandartEvents,
  GaCustomEvents,
} from '@topremit/shared-web/typings/analytics-platform.model';
import {
  AnalyticPagesNames,
  BankName,
  GlobalTrackAttribute,
  S2SEventType,
} from '@topremit/shared-web/typings/analytics.model';
import { AnalyticsInstance } from 'analytics';

/* 
  How to choose between regular(traditional) function or arrow function?
  source: https://javascript.plainenglish.io/traditional-versus-arrow-functions-in-javascript-classes-35f958b1a492
*/

export type TrackConfirmPaymentPayload =
  TriggerPayloads[TriggerNames.CONFIRM_PAYMENT] & {
    isDomestic: boolean;
    isBatch: boolean;
    isBusiness: boolean;
  };

class TracksPersonal {
  private stateContext;
  protected attribute: GlobalTrackAttribute;
  instance: AnalyticsInstance;
  private track: (eventName: string, payload: any) => void;

  constructor(instance: AnalyticsInstance) {
    const user_id = getItemInCookies('tr_member_id') || '';
    this.instance = instance;
    this.stateContext = this.instance.getState('context');
    this.attribute = {
      app: this.stateContext.app,
      version: this.stateContext.version,
      identifier_type: 'MEMBER_ID',
      user_id,
    };

    this.track = (eventName, payload) => {
      if (!isNativeApp()) {
        const ecommerce = payload;

        this.instance.track(eventName, {
          ecommerce,
          ...this.attribute,
        });

        window.dataLayerPersonal?.push({
          event: eventName,
          ...payload,
          ...this.attribute,
        });
      }
    };
  }

  //#region PAGE VIEW
  pageView(payload: TriggerPayloads[TriggerNames.PAGE_VIEW]) {
    this.track(TriggerNames.PAGE_VIEW, payload);
  }
  //#endregion PAGE VIEW

  //#region ABAD
  chooseOtherBank(
    payload: TriggerPayloads[TriggerNames.ABAD_CHOOSE_OTHER_BANK],
  ) {
    this.track(TriggerNames.ABAD_CHOOSE_OTHER_BANK, payload);
  }
  checkAccount(payload?: TriggerPayloads[TriggerNames.ABAD_CHECK_ACCOUNT]) {
    this.track(TriggerNames.ABAD_CHECK_ACCOUNT, payload);
  }
  submitAbadFailed(payload: TriggerPayloads[TriggerNames.ABAD_SUBMIT_FAILED]) {
    this.track(TriggerNames.ABAD_SUBMIT_FAILED, payload);
  }
  //#endregion ABAD

  //#region ACTIVITY
  filterActivityList(
    payload: TriggerPayloads[TriggerNames.ACTIVITY_FILTER_LIST],
  ) {
    this.track(TriggerNames.ACTIVITY_FILTER_LIST, payload);
  }
  //#endregion ACTIVITY

  //#region AFFILIATE
  affiliateInviteCTA(payload: TriggerPayloads[TriggerNames.AFFILIATE_INVITE]) {
    this.track(TriggerNames.AFFILIATE_INVITE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.AFFILIATE_INVITE,
        payload,
      },
    });
  }

  affiliateBalanceStatementFilter(
    payload: TriggerPayloads[TriggerNames.AFFILIATE_BALANCE_FILTER],
  ) {
    this.track(TriggerNames.AFFILIATE_BALANCE_FILTER, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.AFFILIATE_BALANCE_FILTER,
        payload,
      },
    });
  }

  affiliateContactUs() {
    this.track(TriggerNames.AFFILIATE_CONTACT_US, null);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.AFFILIATE_CONTACT_US,
        payload: undefined,
      },
    });
  }

  affiliateJoin() {
    this.track(TriggerNames.AFFILIATE_JOIN, null);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.AFFILIATE_JOIN,
        payload: undefined,
      },
    });
  }
  //#endregion AFFILIATE

  //#region KYC
  submitKyc() {
    this.track(TriggerNames.SIGN_UP, null); // disabled in GTM
  }

  submitKycFailed(payload: TriggerPayloads[TriggerNames.KYC_SUBMIT_FAILED]) {
    this.track(TriggerNames.KYC_SUBMIT_FAILED, payload);
  }

  submitKycAbad() {
    this.track(TriggerNames.SUBMIT_KYC_ABAD, null);
  }

  submitKycCompleted() {
    sendNativeAnalytic({
      fb: { eventName: FbStandartEvents.COMPLETE_REGISTRATION },
    });
  }

  viewKycForm(
    eventName:
      | TriggerNames.KYC_VIEW_ADDRESS
      | TriggerNames.KYC_VIEW_SELFIE
      | TriggerNames.KYC_VIEW_IDENTITY
      | TriggerNames.KYC_VIEW_PROFILE
      | TriggerNames.KYC_VIEW_DISCLAIMER,
  ) {
    this.track(eventName, null);
  }

  chooseIdType(payload: TriggerPayloads[TriggerNames.KYC_CHOOSE_ID_TYPE]) {
    this.track(TriggerNames.KYC_CHOOSE_ID_TYPE, payload);
  }

  chooseOtherAddressKyc() {
    this.track(TriggerNames.KYC_CANT_FIND_ADDRESS, null);
  }

  beginKycQrCode() {
    this.track(TriggerNames.KYC_QR_CODE, null);
  }

  beginKycChooseIdType() {
    this.track(TriggerNames.KYC_CHOOSE_ID_TYPE, null);
  }

  photoRetake(payload: TriggerPayloads[TriggerNames.KYC_PHOTO_RETAKE]) {
    this.track(TriggerNames.KYC_PHOTO_RETAKE, payload);
  }

  //#endregion KYC

  //#region LOGIN
  login(payload: TriggerPayloads[TriggerNames.LOGIN]) {
    this.track(TriggerNames.LOGIN, payload);
  }

  loginSubmitFailed(
    payload: TriggerPayloads[TriggerNames.LOGIN_SUBMIT_FAILED],
  ) {
    this.track(TriggerNames.LOGIN_SUBMIT_FAILED, payload);
  }

  resetPassword(payload?: TriggerPayloads[TriggerNames.RESET_PASSWORD]) {
    this.track(TriggerNames.RESET_PASSWORD, payload);
  }

  //#endregion LOGIN

  //#region PROFILE
  foundUsSocialMedia(payload: TriggerPayloads[TriggerNames.FIND_US]) {
    this.track(TriggerNames.FIND_US, payload);
  }
  //#endregion PROFILE

  //#region REGISTER
  register(payload: TriggerPayloads[TriggerNames.REGISTER]) {
    this.track(TriggerNames.REGISTER, payload);
  }
  registerOtp(payload: TriggerPayloads[TriggerNames.REGISTER_OTP]) {
    this.track(TriggerNames.REGISTER_OTP, payload);
  }
  registerReferral(payload: TriggerPayloads[TriggerNames.REGISTER_REFERRAL]) {
    this.track(TriggerNames.REGISTER_REFERRAL, payload);
  }
  registerVerificationMethod(
    payload: TriggerPayloads[TriggerNames.REGISTER_VERIFICATION],
  ) {
    this.track('register_verification', payload);
  }

  registerChangedNumber(
    payload: TriggerPayloads[TriggerNames.REGISTER_CHANGED_NUMBER],
  ) {
    this.track(TriggerNames.REGISTER_CHANGED_NUMBER, payload);
  }

  registerFailed(payload: TriggerPayloads[TriggerNames.REGISTER_FAILED]) {
    this.track(TriggerNames.REGISTER_FAILED, payload);
  }

  registerDomestic(payload: TriggerPayloads[TriggerNames.REGISTER]) {
    this.track(TriggerNames.REGISTER_DOMESTIC, payload);
  }
  //#endregion REGISTER

  //#region TRANSACTION

  viewCountryCurrency(
    payload: TriggerPayloads[TriggerNames.VIEW_COUNTRY_CURRENCY],
  ) {
    this.track(TriggerNames.VIEW_COUNTRY_CURRENCY, payload);
    sendNativeAnalytic({
      fb: {
        eventName: TriggerNames.VIEW_COUNTRY_CURRENCY,
        payload,
      },
      firebase: {
        eventName: TriggerNames.VIEW_COUNTRY_CURRENCY,
        payload,
      },
    });
  }

  addQuotationDetail(
    payload: TriggerPayloads[TriggerNames.ADD_QUOTATION_DETAIL],
  ) {
    this.track(TriggerNames.ADD_QUOTATION_DETAIL, payload);
  }

  transactionTrack(payload: TriggerPayloads[TriggerNames.TRANSACTION_TRACK]) {
    this.track(TriggerNames.TRANSACTION_TRACK, payload);
  }

  shareTrackingLink(payload: TriggerPayloads[TriggerNames.SHARE_TRACKING]) {
    this.track(TriggerNames.SHARE_TRACKING, payload);
  }

  choosePaymentMethod(payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO]) {
    this.track(TriggerNames.ADD_PAYMENT_INFO, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.ADD_PAYMENT_INFO,
        payload,
      },
      fb: {
        eventName: FbStandartEvents.ADD_PAYMENT_INFO,
        payload,
      },
      appsFlyer: {
        eventName: AfStandartEvents.ADD_PAYMENT_INFO,
        payload,
      },
      moe: {
        eventName: TriggerNames.ADD_PAYMENT_INFO,
        payload,
      },
    });
  }

  purchaseEvent({
    trxId,
    isBatch,
    isDomestic,
  }: {
    trxId: string;
    isBatch: boolean;
    isDomestic: boolean;
  }) {
    if (isDomestic) {
      if (isBatch) {
        this.purchaseDomesticMultiple({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      } else {
        this.purchaseDomesticSingle({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      }
    } else {
      if (isBatch) {
        this.purchaseMultiple({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      } else {
        this.confirmPayment({
          transaction_id: trxId,
          value: 1,
          currency: 'IDR',
        });
      }
    }
  }

  addPaymentInfoEvent({
    trxId,
    isBatch,
    isDomestic,
    method,
    bank,
  }: {
    trxId: string;
    isBatch?: boolean;
    isDomestic?: boolean;
    method: PaymentMethod;
    bank: BankName;
  }) {
    if (isDomestic) {
      if (isBatch) {
        this?.addPaymentInfoDomesticMultiple({
          transaction_id: trxId,
          type: TransactionType.DOMESTIC,
          method,
          bank,
        });
      } else {
        this?.addPaymentInfoDomesticSingle({
          transaction_id: trxId,
          type: TransactionType.DOMESTIC,
          method,
          bank,
        });
      }
    } else {
      if (isBatch) {
        this?.addPaymentInfoMultiple({
          transaction_id: trxId,
          type: TransactionType.BATCH_REMIT,
          method,
          bank,
        });
      } else {
        this?.choosePaymentMethod({
          transaction_id: trxId,
          type: TransactionType.REMIT,
          method,
          bank,
        });
      }
    }
  }

  confirmPayment(payload: TriggerPayloads[TriggerNames.CONFIRM_PAYMENT]) {
    this.track(TriggerNames.CONFIRM_PAYMENT, payload);
    /**
     * No sendNativeAnalytic due to
     * the queue will be added to mobile
     * */
  }

  transactionTrackerRequest(
    payload: TriggerPayloads[TriggerNames.TRANSACTION_TRACKER_REQUEST],
  ) {
    this.track(TriggerNames.TRANSACTION_TRACKER_REQUEST, payload);
  }

  addToCart(payload: TriggerPayloads[TriggerNames.ADD_TO_CART]) {
    this.track(TriggerNames.ADD_TO_CART, payload);
    sendNativeAnalytic({
      firebase: { eventName: TriggerNames.ADD_TO_CART, payload },
      fb: { eventName: FbStandartEvents.ADD_TO_CART, payload },
      appsFlyer: { eventName: AfStandartEvents.ADD_TO_CART, payload },
      moe: { eventName: TriggerNames.ADD_TO_CART, payload },
    });
  }

  beginCheckout(payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT]) {
    this.track(TriggerNames.BEGIN_CHECKOUT, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.BEGIN_CHECKOUT,
        payload,
      },
      fb: { eventName: FbStandartEvents.INITIATED_CHECKOUT, payload },
      appsFlyer: { eventName: AfStandartEvents.INITIATED_CHECKOUT, payload },
    });
  }

  failedAddToCart(payload: TriggerPayloads[TriggerNames.ADD_TO_CART_FAILED]) {
    this.track(TriggerNames.ADD_TO_CART_FAILED, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.ADD_TO_CART_FAILED,
        payload,
      },
    });
  }

  viewItem(payload: TriggerPayloads[TriggerNames.VIEW_ITEM]) {
    this.track(TriggerNames.VIEW_ITEM, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.VIEW_ITEM,
        payload,
      },
      fb: { eventName: FbStandartEvents.CONTENT_VIEW, payload },
      appsFlyer: {
        eventName: AfStandartEvents.CONTENT_VIEW,
        payload,
      },
    });
  }

  cancelTransaction(payload?: TriggerPayloads[TriggerNames.CANCEL_PURCHASE]) {
    this.track(TriggerNames.CANCEL_PURCHASE, payload);
    if (payload?.source === AnalyticPagesNames.TRANSACTION_DETAIL) {
      sendNativeAnalytic({
        firebase: {
          eventName: TriggerNames.CANCEL_PURCHASE,
          payload,
        },
        fb: { eventName: TriggerNames.CANCEL_PURCHASE, payload },
        appsFlyer: {
          eventName: TriggerNames.CANCEL_PURCHASE,
          payload,
        },
      });
    }
  }

  refund(payload: TriggerPayloads[TriggerNames.REQUEST_REFUND]) {
    this.track(TriggerNames.REQUEST_REFUND, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.REQUEST_REFUND,
        payload,
      },
      s2s: {
        eventType: S2SEventType.REFUND,
      },
    });
  }

  checkPaymentStatus(
    payload: TriggerPayloads[TriggerNames.CHECK_PAYMENT_STATUS],
  ) {
    this.track(TriggerNames.CHECK_PAYMENT_STATUS, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.CHECK_PAYMENT_STATUS,
        payload,
      },
    });
  }

  backButton(payload: TriggerPayloads[TriggerNames.BACK_BUTTON]) {
    this.track(TriggerNames.BACK_BUTTON, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.BACK_BUTTON,
        payload,
      },
    });
  }

  addToCartDomestic(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_DOMESTIC],
  ) {
    this.track(TriggerNames.ADD_TO_CART_DOMESTIC, payload);
  }

  downloadInvoice(payload: TriggerPayloads[TriggerNames.DOWNLOAD_INVOICE]) {
    this.track(TriggerNames.DOWNLOAD_INVOICE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.DOWNLOAD_INVOICE,
        payload,
      },
    });
  }
  //#endregion TRANSACTION

  //#region TRANSACTION MULTIPLE

  viewCountryCurrencyMultiple(
    payload: TriggerPayloads[TriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE],
  ) {
    this.track(TriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE, payload);
    sendNativeAnalytic({
      fb: {
        eventName: TriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE,
        payload,
      },
    });
  }

  addToCartMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_MULTIPLE, payload);
    sendNativeAnalytic({
      firebase: { eventName: TriggerNames.ADD_TO_CART_MULTIPLE, payload },
      fb: { eventName: FbCustomEvents.ADD_TO_CART_MULTIPLE, payload },
      appsFlyer: { eventName: AfCustomEvents.ADD_TO_CART_MULTIPLE, payload },
      moe: { eventName: TriggerNames.ADD_TO_CART_MULTIPLE, payload },
    });
  }

  addPaymentInfoMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_PAYMENT_INFO_MULTIPLE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.ADD_PAYMENT_INFO_MULTIPLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.ADD_PAYMENT_INFO_MULTIPLE,
        payload,
      },
      appsFlyer: {
        eventName: AfCustomEvents.ADD_PAYMENT_INFO_MULTIPLE,
        payload,
      },
    });
  }

  purchaseMultiple(payload: TriggerPayloads[TriggerNames.PURCHASE_MULTIPLE]) {
    this.track(TriggerNames.PURCHASE_MULTIPLE, payload);
    sendNativeAnalytic({
      fb: {
        eventName: FbCustomEvents.PURCHASE_MULTIPLE,
        payload,
      },
    });
  }

  beginCheckoutMultiple(
    payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT_MULTIPLE],
  ) {
    this.track(TriggerNames.BEGIN_CHECKOUT_MULTIPLE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.BEGIN_CHECKOUT_MULTIPLE,
        payload,
      },
      fb: { eventName: FbCustomEvents.INITIATED_CHECKOUT_MULTIPLE, payload },
      appsFlyer: {
        eventName: AfCustomEvents.INITIATED_CHECKOUT_MULTIPLE,
        payload,
      },
    });
  }

  failedAddToCartMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_FAILED_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_FAILED_MULTIPLE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.ADD_TO_CART_FAILED_MULTIPLE,
        payload,
      },
    });
  }

  viewItemMultiple(payload: TriggerPayloads[TriggerNames.VIEW_ITEM_MULTIPLE]) {
    this.track(TriggerNames.VIEW_ITEM_MULTIPLE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.VIEW_ITEM_MULTIPLE,
        payload,
      },
      fb: { eventName: FbCustomEvents.CONTENT_VIEW_MULTIPLE, payload },
      appsFlyer: {
        eventName: AfCustomEvents.CONTENT_VIEW_MULTIPLE,
        payload,
      },
    });
  }

  cancelPurchaseMultiple(
    payload?: TriggerPayloads[TriggerNames.CANCEL_PURCHASE_MULTIPLE],
  ) {
    this.track(TriggerNames.CANCEL_PURCHASE_MULTIPLE, payload);
    if (payload?.source === AnalyticPagesNames.TRANSACTION_DETAIL) {
      sendNativeAnalytic({
        firebase: {
          eventName: TriggerNames.CANCEL_PURCHASE_MULTIPLE,
          payload,
        },
        fb: { eventName: TriggerNames.CANCEL_PURCHASE_MULTIPLE, payload },
        appsFlyer: {
          eventName: TriggerNames.CANCEL_PURCHASE_MULTIPLE,
          payload,
        },
      });
    }
  }

  //#endregion TRANSACTION MULTIPLE

  //#region TRANSACTION SINGLE DOMESTIC
  addToCartDomesticSingle(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_DOMESTIC_SINGLE, payload);
    sendNativeAnalytic({
      appsFlyer: {
        eventName: AfCustomEvents.ADD_TO_CART_DOMESTIC_SINGLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.ADD_TO_CART_DOMESTIC_SINGLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.ADD_TO_CART_DOMESTIC_SINGLE,
        payload,
      },
    });
  }

  beginCheckoutDomesticSingle(
    payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE, payload);

    sendNativeAnalytic({
      appsFlyer: {
        eventName: AfCustomEvents.INITIATED_CHECKOUT_DOMESTIC_SINGLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.CHECKOUT_DOMESTIC_SINGLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE,
        payload,
      },
    });
  }

  addPaymentInfoDomesticSingle(
    payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE, payload);

    sendNativeAnalytic({
      appsFlyer: {
        eventName: AfCustomEvents.ADD_PAYMENT_INFO_DOMESTIC_SINGLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.ADDPAYMENTINFO_DOMESTIC_SINGLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE,
        payload,
      },
    });
  }

  purchaseDomesticSingle(
    payload: TriggerPayloads[TriggerNames.PURCHASE_DOMESTIC_SINGLE],
  ) {
    this.track(TriggerNames.PURCHASE_DOMESTIC_SINGLE, payload);
    /**
     * No sendNativeAnalytic due to
     * the queue will be added to mobile
     * */
  }

  pageViewDomestic() {
    const payload: TriggerPayloads[TriggerNames.PAGE_VIEW_DOMESTIC] = {
      source: location.href,
      prev_source: document.referrer,
    };

    this.track(TriggerNames.PAGE_VIEW_DOMESTIC, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: GaCustomEvents.SCREEN_VIEW_DOMESTIC,
        payload,
      },
    });
  }
  //#endregion TRANSACTION SINGLE DOMESTIC

  //#region TRANSACTION MULTIPLE DOMESTIC
  addToCartDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE, payload);
    sendNativeAnalytic({
      appsFlyer: {
        eventName: AfCustomEvents.ADD_TO_CART_DOMESTIC_MULTIPLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.ADD_TO_CART_DOMESTIC_MULTIPLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE,
        payload,
      },
    });
  }

  beginCheckoutDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE, payload);

    sendNativeAnalytic({
      appsFlyer: {
        eventName: AfCustomEvents.INITIATED_CHECKOUT_DOMESTIC_MULTIPLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.CHECKOUT_DOMESTIC_MULTIPLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE,
        payload,
      },
    });
  }

  addPaymentInfoDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE, payload);

    sendNativeAnalytic({
      appsFlyer: {
        eventName: AfCustomEvents.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE,
        payload,
      },
      fb: {
        eventName: FbCustomEvents.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE,
        payload,
      },
      firebase: {
        eventName: TriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE,
        payload,
      },
    });
  }

  purchaseDomesticMultiple(
    payload: TriggerPayloads[TriggerNames.PURCHASE_DOMESTIC_MULTIPLE],
  ) {
    this.track(TriggerNames.PURCHASE_DOMESTIC_MULTIPLE, payload);
    /**
     * No sendNativeAnalytic due to
     * the queue will be added to mobile
     * */
  }
  //#endregion TRANSACTION MULTIPLE DOMESTIC ANALYTICS

  //#region TR COINS
  trCoinsHistory() {
    this.track(TriggerNames.TRCOINS_HISTORY, null);
  }

  trCoinsWithdraw(payload: TriggerPayloads[TriggerNames.TRCOINS_WITHDRAW]) {
    this.track(TriggerNames.TRCOINS_WITHDRAW, payload);
  }
  //#endregion TR COINS

  //#region VIEW
  homepageSection(payload: TriggerPayloads[TriggerNames.HOMEPAGE_SECTION]) {
    this.track(TriggerNames.HOMEPAGE_SECTION, payload);
  }
  readNotification(payload: TriggerPayloads[TriggerNames.NOTIFICATION]) {
    this.track(TriggerNames.NOTIFICATION, payload);
  }
  viewBlocking(payload: TriggerPayloads[TriggerNames.BLOCKING]) {
    this.track(TriggerNames.BLOCKING, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.BLOCKING,
        payload,
      },
    });
  }
  //#endregion VIEW

  //#region PRODUCT
  coachmark(payload: TriggerPayloads[TriggerNames.COACHMARK]) {
    this.track(TriggerNames.COACHMARK, payload);
  }

  announcementSection(
    payload: TriggerPayloads[TriggerNames.ANNOUNCEMENT_SECTION],
  ) {
    this.track(TriggerNames.ANNOUNCEMENT_SECTION, payload);
  }

  infoPayment(payload: TriggerPayloads[TriggerNames.INFO_PAYMENT]) {
    this.track(TriggerNames.INFO_PAYMENT, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.INFO_PAYMENT,
        payload,
      },
    });
  }

  ctaButton(payload: TriggerPayloads[TriggerNames.CTA_BUTTON]) {
    this.track(TriggerNames.CTA_BUTTON, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.CTA_BUTTON,
        payload,
      },
    });
  }
  //#endregion PRODUCT

  //#region RATE ALERT
  rateAlert(payload: TriggerPayloads[TriggerNames.RATEALERT]) {
    this.track(TriggerNames.RATEALERT, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.RATEALERT,
        payload,
      },
    });
  }

  rateAlertCreated(payload: TriggerPayloads[TriggerNames.RATEALERT_CREATED]) {
    this.track(TriggerNames.RATEALERT_CREATED, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.RATEALERT_CREATED,
        payload,
      },
    });
  }

  rateAlertFailed(payload: TriggerPayloads[TriggerNames.RATEALERT_FAILED]) {
    this.track(TriggerNames.RATEALERT_FAILED, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.RATEALERT_FAILED,
        payload: {
          error_in: payload?.error,
          source: payload?.source,
        },
      },
    });
  }

  rateAlertEdit(payload: TriggerPayloads[TriggerNames.RATEALERT_EDIT]) {
    this.track(TriggerNames.RATEALERT_EDIT, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.RATEALERT_EDIT,
        payload,
      },
    });
  }
  rateAlertDelete(payload: TriggerPayloads[TriggerNames.RATEALERT_DELETE]) {
    this.track(TriggerNames.RATEALERT_DELETE, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.RATEALERT_DELETE,
        payload,
      },
    });
  }
  rateAlertOff(payload: TriggerPayloads[TriggerNames.RATEALERT_OFF]) {
    this.track(TriggerNames.RATEALERT_OFF, payload);
    sendNativeAnalytic({
      firebase: {
        eventName: TriggerNames.RATEALERT_OFF,
        payload,
      },
    });
  }
  //#endregion RATE ALERT
}

export default TracksPersonal;
