export const color = {
  // blue
  blue900: '#003761',
  blue800: '#005494',
  blue700: '#0071C7',
  blue600: '#008EFA',
  blue500: '#30A6FF',
  blue400: '#61B8FF',
  blue300: '#94CFFF',
  blue200: '#C7E6FF',
  blue100: '#F4FAFF',

  // green
  green900: '#041B17',
  green800: '#093A31',
  green700: '#0D594B',
  green600: '#16937C',
  green500: '#1ECBAC',
  green400: '#49E4C8',
  green300: '#83EDDA',
  green200: '#BCF5EB',
  green100: '#E9FCF8',

  // yellow
  yellow900: '#473700',
  yellow800: '#7A5F01',
  yellow700: '#987701',
  yellow600: '#B78F01',
  yellow500: '#EAB601',
  yellow400: '#FECD20',
  yellow300: '#FEDD67',
  yellow200: '#FFE899',
  yellow100: '#FFF8E1',

  // red
  red900: '#611414',
  red800: '#8B1D1D',
  red700: '#B62626',
  red600: '#D63838',
  red500: '#DF6464',
  red400: '#E78D8D',
  red300: '#F0B7B7',
  red200: '#F9E1E1',
  red100: '#FFF6F6',

  // orange
  orange900: '#291100',
  orange800: '#5C2700',
  orange700: '#8F3C00',
  orange600: '#C25100',
  orange500: '#F46700',
  orange400: '#FF8329',
  orange300: '#FF974C',
  orange200: '#FFBE8F',
  orange100: '#FFF9F5',

  // neutral
  neutral900: '#18191B',
  neutral800: '#2E4865',
  neutral700: '#3E6189',
  neutral600: '#4E7AAC',
  neutral500: '#8295B5',
  neutral400: '#92AECE',
  neutral300: '#B5C8DD',
  neutral200: '#E7ECF3',
  neutral100: '#F7F9FC',
  neutral50: '#F8FAFC',
  neutral0: '#FFFFFF',

  // black
  black0: '#000000',

  skeletonGradientStart: '#F8FAFC',
  skeletonGradientEnd: '#E7ECF3',
} as const;
