export type FontSize =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'p'
  | 'sm'
  | 'xs'
  | 'xxs';

export const fontWeight = {
  black: 900,
  bold: 700,
  regular: 500,
};

export const lineHeight: Record<FontSize, number> = {
  h1: 70,
  h2: 54,
  h3: 38,
  h4: 30,
  h5: 24,
  p: 22,
  sm: 20,
  xs: 16,
  xxs: 14,
};

//rem
export const fontSize: Record<FontSize, number> = {
  h1: 4, //64px
  h2: 3, //48px
  h3: 2, //32px
  h4: 1.375, //22px
  h5: 1.125, //18px
  p: 1, //16px
  sm: 0.875, //14px
  xs: 0.75, ///12px
  xxs: 0.625, //10px
};

export const typography = {
  heading1: {
    fontSize: fontSize.h1,
    lineHeight: lineHeight.h1,
    fontWeight: fontWeight.bold,
  },
  heading2: {
    fontSize: fontSize.h2,
    lineHeight: lineHeight.h2,
    fontWeight: fontWeight.bold,
  },
  heading3: {
    fontSize: fontSize.h3,
    lineHeight: lineHeight.h3,
    fontWeight: fontWeight.bold,
  },
  body1Bold: {
    fontSize: fontSize.h4,
    lineHeight: lineHeight.h4,
    fontWeight: fontWeight.bold,
  },
  body1Medium: {
    fontSize: fontSize.h4,
    lineHeight: lineHeight.h4,
    fontWeight: fontWeight.regular,
  },
  body2Bold: {
    fontSize: fontSize.h5,
    lineHeight: lineHeight.h5,
    fontWeight: fontWeight.bold,
  },
  body2Medium: {
    fontSize: fontSize.h5,
    lineHeight: lineHeight.h5,
    fontWeight: fontWeight.regular,
  },
  body3Bold: {
    fontSize: fontSize.p,
    lineHeight: lineHeight.p,
    fontWeight: fontWeight.bold,
  },
  body3Medium: {
    fontSize: fontSize.p,
    lineHeight: lineHeight.p,
    fontWeight: fontWeight.regular,
  },
  smallBold: {
    fontSize: fontSize.sm,
    lineHeight: lineHeight.sm,
    fontWeight: fontWeight.bold,
  },
  smallMedium: {
    fontSize: fontSize.sm,
    lineHeight: lineHeight.sm,
    fontWeight: fontWeight.regular,
  },
  extraSmallBold: {
    fontSize: fontSize.xs,
    lineHeight: lineHeight.xs,
    fontWeight: fontWeight.bold,
  },
  extraSmallMedium: {
    fontSize: fontSize.xs,
    lineHeight: lineHeight.xs,
    fontWeight: fontWeight.regular,
  },
} as const;

export const screenSize = {
  mobileSm: 321,
  mobileMd: 376,
  mobileLg: 430,

  tabletSm: 576,
  tabletMd: 768,
  tabletLg: 840,

  desktopSm: 992,
  desktopMd: 1024,
  desktopLg: 1240,
  desktopXl: 1400,
};

export const breakpoint = {
  navbarBurger: 1049,
};

export const sidebarSize = {
  expand: 317,
  shrink: 88,
};

export const headerSize = {
  height: 72,
};

export const bannerSize = {
  height: 48,
};

export const navbarSize = {
  sm: 68,
  md: 80,
};

export const zIndex = {
  /* Header */
  header: 999,
  navigationBar: 11,
  dropdownMenuNavigationBar: 10,
  /* Element */
  notification: 999999,
  bottomSheet: 3000,
  modal: 2000,
  tooltip: 1900,
  dropdown: 1000,
  layout: 999,
};

export const space = {
  space4: 4,
  space8: 8,
  space10: 10,
  space12: 12,
  space16: 16,
  space24: 24,
  space32: 32,
  space40: 40,
  space48: 48,
  space64: 64,
};
