import { css, cx } from '@emotion/css';
import { motion } from 'framer-motion';
import { Children, ReactNode, useEffect, useRef, useState } from 'react';

import Card from './Card';
import Flex from './Flex';
import { screenSize } from '../../common/size';
import { useMediaQuery, useTranslation } from '../../hooks';
import { Angle } from '../shapes';

interface IListDropdownProps {
  title: ReactNode;
  content: ReactNode;
  minItems: number;
  className?: string;
  contentClassName?: string;
}

const MotionCard = motion(Card);

const { tabletMd, tabletSm } = screenSize;

export default function ListDropdown({
  title,
  content,
  minItems,
  className,
  contentClassName,
}: IListDropdownProps) {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery(`(max-width: ${tabletSm}px)`);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [canExpand, setCanExpand] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement>(null);

  function renderList() {
    if (canExpand) {
      return (
        <button
          className="arrow-button xs-bold-text"
          onClick={() => setIsExpanded((prev) => !prev)}
        >
          {isMobileView && (
            <div className="label">{t(isExpanded ? 'hide' : 'show_all')}</div>
          )}
          <Angle
            direction={isExpanded ? 'up' : 'down'}
            size="small"
            width={20}
            height={20}
          />
        </button>
      );
    }
    if (!isMobileView) {
      return <div className="arrow-button" />;
    }
    return <></>;
  }

  useEffect(() => {
    const itemCount = Children.count(content);
    setCanExpand(itemCount > minItems);
  }, []);

  return (
    <MotionCard
      initial={false}
      animate={{ height: 'auto' }}
      transition={{
        ease: 'easeInOut',
        duration: 0.3,
      }}
      variant="outline"
      layout="preserve-aspect"
      ref={contentRef}
      className={cx(styled.list, className)}
    >
      <Flex className="content-wrapper">
        <div className="title">{title}</div>
        <div className={cx('content', contentClassName)}>
          {Children.map(content, (contentItem, index) => (
            <div key={index} hidden={!isExpanded && index >= minItems}>
              {contentItem}
            </div>
          ))}
        </div>
      </Flex>
      {renderList()}
    </MotionCard>
  );
}

const styled = {
  list: css`
    display: flex;
    width: 100%;
    padding: 0.5rem 1.5rem;
    @media (max-width: ${tabletSm}px) {
      flex-direction: column;
      padding: 0.5rem;
    }
    .content-wrapper {
      @media (max-width: ${tabletSm}px) {
        height: auto;
        flex-direction: column;
      }
      .title {
        display: flex;
        height: 3.75rem;
        align-items: center;
        min-width: 5.25rem;
        margin-right: 2.75rem;
        font-weight: var(--bold-font-weight);
        @media (max-width: ${tabletMd}px) {
          margin-right: 2rem;
        }
        @media (max-width: ${tabletSm}px) {
          margin-right: unset;
          padding: 0.5rem;
        }
      }
      .content {
        flex: 1;
      }
    }
    .arrow-button {
      display: flex;
      width: 1.5rem;
      height: 3.75rem;
      align-items: center;
      @media (max-width: ${tabletSm}px) {
        width: 100%;
        justify-content: center;
        .label {
          margin-right: 0.25rem;
        }
      }
    }
  `,
};
