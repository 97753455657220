import { SVGProps } from 'react';

import { color } from '../../styles/color';

const TransactionFill = ({
  width = 24,
  height = 24,
  fill = color.neutral500,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2330_9665)">
      <path
        d="M2 4.72727C2 3.22104 3.22104 2 4.72727 2H15.6364C17.1426 2 18.3636 3.22104 18.3636 4.72727V22H6.54545C4.03507 22 2 19.9649 2 17.4545V4.72727Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6364 3.81818H4.72727C4.2252 3.81818 3.81818 4.2252 3.81818 4.72727V17.4545C3.81818 18.9608 5.03922 20.1818 6.54545 20.1818H16.5455V4.72727C16.5455 4.2252 16.1384 3.81818 15.6364 3.81818ZM4.72727 2C3.22104 2 2 3.22104 2 4.72727V17.4545C2 19.9649 4.03507 22 6.54545 22H18.3636V4.72727C18.3636 3.22104 17.1426 2 15.6364 2H4.72727Z"
        fill={fill}
      />
      <path
        d="M5.63635 8.36368C5.63635 7.8616 6.04337 7.45459 6.54544 7.45459H13.8182C14.3202 7.45459 14.7273 7.8616 14.7273 8.36368V8.36368C14.7273 8.86576 14.3202 9.27277 13.8182 9.27277H6.54544C6.04337 9.27277 5.63635 8.86576 5.63635 8.36368V8.36368Z"
        fill="white"
      />
      <path
        d="M5.63635 11.9999C5.63635 11.4978 6.04337 11.0908 6.54544 11.0908H13.8182C14.3202 11.0908 14.7273 11.4978 14.7273 11.9999V11.9999C14.7273 12.502 14.3202 12.909 13.8182 12.909H6.54544C6.04337 12.909 5.63635 12.502 5.63635 11.9999V11.9999Z"
        fill="white"
      />
      <path
        d="M5.63635 15.6364C5.63635 15.1343 6.04337 14.7273 6.54544 14.7273H10.1818C10.6839 14.7273 11.0909 15.1343 11.0909 15.6364C11.0909 16.1385 10.6839 16.5455 10.1818 16.5455H6.54544C6.04337 16.5455 5.63635 16.1385 5.63635 15.6364Z"
        fill="white"
      />
      <path
        d="M16.5454 13.8181H20.1818C21.1859 13.8181 22 14.6321 22 15.6363V18.3636C22 20.3719 20.3719 21.9999 18.3636 21.9999H16.5454V13.8181Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1818 16.6363C20.1818 16.084 19.7341 15.6363 19.1818 15.6363H18.3636L18.3636 18.1818C18.3636 19.2863 19.4824 20.1725 19.9843 19.1885C20.1106 18.9409 20.1818 18.6606 20.1818 18.3636V16.6363ZM16.5454 13.8181V21.9999H18.3636C20.3719 21.9999 22 20.3719 22 18.3636V15.6363C22 14.6321 21.1859 13.8181 20.1818 13.8181H16.5454Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_460_3">
        <rect width={20} height={20} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TransactionFill;
