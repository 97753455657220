import { TRCoinHistoryType, TRCoinsHistoryModel } from '@api-hooks/tr-coins';
import { Translate } from 'next-translate';

export const LABEL = {
  IDR: 'IDR',
  TR_COINS: 'TR Coins',
};

export const MINIMUM_WITHDRAWAL_AMOUNT = 10000;

export const TR_COINS_ABOUT_HREF = '/tr-coins/about';
export const TR_COINS_HISTORY_HREF = '/tr-coins/history';
export const TR_COINS_ADD_BANK_HREF = '/tr-coins/add-bank';
export const TR_COINS_WITHDRAWAL_HREF = '/tr-coins/withdrawal';
export const TR_COINS_VERIFYING_WITHDRAWAL = '/tr-coins/withdrawal/verifying';
export const TR_COINS_CHOOSE_BANK_HREF = '/tr-coins/choose-bank';
export const TRANSACTION_DETAIL_HREF = '/activity/transaction/detail';
export const WITHDRAW_DETAIL_HREF = '/tr-coins/withdrawal/detail';
export const BANK_ACCOUNT_DETAILS_HREF = '/profile/bank-account-details';
export const ADD_BANK_ACCOUNT_HREF = '/profile/bank-account-details/add-form';

export const PREV_ROUTE = 'PREV_ROUTE';
export const TR_COINS_WITHDRAWAL_AMOUNT = 'TR_COINS_WITHDRAWAL_AMOUNT';
export const TR_COINS_BANK_ACC_ORDER = 'TR_COINS_BANK_ACC_ORDER';

export const historyDummyData = (t: Translate): TRCoinsHistoryModel[] => [
  {
    amount: 238,
    createdAt: '6 Apr 2021 18:32',
    description: t('history.dummy.coins_from_transaction'),
    icon: 'plus-small-outline',
    recipients: ['Charles'],
    transactionableId: 'c3eff767-cd52-4d7f-9139-a81b0c589820',
    type: 'debit',
    link: TRCoinHistoryType.TRANSACTION,
  },
  {
    amount: 10000,
    createdAt: '6 Apr 2021 18:32',
    description: t('history.dummy.coins_withdrawal'),
    icon: 'withdraw-outline',
    recipients: ['John'],
    transactionableId: 'c3eff767-cd52-4d7f-9139-a81b0c589821',
    type: 'credit',
    link: TRCoinHistoryType.TRANSACTION,
  },
  {
    amount: 10000,
    createdAt: '6 Apr 2021 18:32',
    description: t('history.dummy.coins_refund_for_failed_transaction'),
    icon: 'withdraw-outline',
    recipients: ['Sandy Wales'],
    transactionableId: 'c3eff767-cd52-4d7f-9139-a81b0c589822',
    type: 'credit',
    link: TRCoinHistoryType.TRANSACTION,
  },
  {
    amount: 238,
    createdAt: '6 Apr 2021 18:32',
    description: t('history.dummy.coins_from_transaction'),
    icon: 'plus-small-outline',
    recipients: ['Sandy Wales'],
    transactionableId: 'c3eff767-cd52-4d7f-9139-a81b0c589823',
    type: 'debit',
    link: TRCoinHistoryType.TRANSACTION,
  },
];
