import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { color } from '@topremit/shared-web/styles/color';

const CONTENT_CARD_MARGIN = 40;

export const contentCard = {
  root: ({
    fullHeight,
    nativeTopInset,
    transparentBody,
  }: {
    fullHeight?: boolean;
    transparentBody?: boolean;
    nativeTopInset: number;
  }) => css`
    width: 564px;
    display: flex;
    border-radius: 16px;
    flex-direction: column;
    background-color: ${transparentBody ? 'transparent' : color.neutral0};
    margin: ${CONTENT_CARD_MARGIN}px auto;
    padding-top: ${transparentBody ? 0 : '32px'};
    height: ${fullHeight
      ? `calc(100% - ${CONTENT_CARD_MARGIN}px - ${CONTENT_CARD_MARGIN}px)` // 100% - margin top - margin bottom
      : 'auto'};
    @media (max-width: ${screenSize.tabletMd}px) {
      margin: 0;
      padding-top: calc(20px + ${nativeTopInset}px);
      border: none;
      width: 100vw;
      height: ${fullHeight ? '100%' : 'auto'};
      border-radius: 0;
    }
  `,
  header: css`
    flex-shrink: 0; // Header sticks to the top
    border-radius: 16px 16px 0 0;
    padding: 0 32px 16px 32px;
    @media (max-width: ${screenSize.tabletMd}px) {
      border-radius: 0;
      padding: 0 24px 20px;
    }
  `,
  button: css`
    width: auto;
    border: none;
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    background: none;
    align-items: center;
    padding: 0 !important;
  `,
  back: css`
    margin-right: 16px;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin-right: 8px;
    }
  `,
  title: css`
    flex-grow: 1;
    display: flex;
    margin: 0 16px 0 0;
    align-items: center;
    font-size: 22px;
    font-weight: var(--bold-font-weight);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 18px;
    }
  `,
  titleBody: css`
    font-size: 22px;
    margin-bottom: 8px;
    color: ${color.blue900};
    font-weight: var(--bold-font-weight);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 18px;
    }
  `,
  description: css`
    color: ${color.neutral500};
    font-size: var(--regular-font-size);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
  `,
  marginLeftAuto: css`
    margin-left: auto;
  `,
  chatUs: css`
    margin-left: auto;
  `,
  body: (isTransparent?: boolean) => css`
    flex-grow: 1; // Body takes the rest of the space
    overflow-y: auto;
    padding: ${isTransparent ? 0 : '0 32px 32px 32px'};
    @media (max-width: ${screenSize.tabletMd}px) {
      padding: 0 24px 24px 24px;
    }
  `,
  footer: css`
    flex-shrink: 0; // Footer sticks to the bottom
    padding: 24px 32px;
    border-radius: 0 0 16px 16px;
    box-shadow: 0px 2px 8px 0px rgba(46, 72, 101, 0.1);
    @media (max-width: ${screenSize.tabletMd}px) {
      border-radius: 0;
      padding: 16px 24px 24px;
    }
  `,
};
