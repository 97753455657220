import { css } from '@emotion/css';

import Status from '../Status';

export interface ISpeedDetail {
  fee: string;
  title: string;
  speed: string;
  arrivalTime: string;
}

export default function SpeedInfo({
  fee,
  title,
  speed,
  arrivalTime,
}: ISpeedDetail) {
  return (
    <div className={styled.root}>
      <div className="xs-text title-modal">{title}</div>
      <div className="speed-detail">
        <div>
          <div className="sm-bold-text">{speed}</div>
          <div className="xs-text time">{arrivalTime}</div>
        </div>
        <Status message={fee} color="blue" className="pills" />
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    .title-modal {
      color: var(--text-secondary);
      margin-bottom: 0.5rem;
    }
    .speed-detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .time {
        color: var(--text-secondary);
        margin-top: 0.25rem;
      }
      .pills {
        margin-right: 0;
        .ant-tag {
          max-height: 2.875rem;

          font-weight: 700;
          .strikethrough {
            font-weight: 100;
          }
        }
      }
    }
  `,
};
