import { AuthResponse } from '@api-hooks/auth';
import { deleteAllLocalToken } from '@hooks';
import packageInfo from '@package-json';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { isFormData } from '@topremit/shared-web/common/assertion';
import {
  getBrowserInfo,
  getUUID,
} from '@topremit/shared-web/common/fingerprint';
import {
  encryptString,
  getURLLastSegment,
  moveToNextTick,
} from '@topremit/shared-web/common/helper';
import { HTTP_UNAUTHORIZED } from '@topremit/shared-web/common/http';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import humps from 'humps';
import ky, { Options } from 'ky';
import { locales } from 'landing/i18n';
import Router from 'next/router';
import qs from 'qs';
import { QueryClient } from 'react-query';

import { isAuthRequired } from './auth';
import { ACCESS_TOKEN_KEY, API_VERSION, REFRESH_TOKEN_KEY } from './storage';
import { useCurrentAccountStore } from '../stores/use-current-account-store';

const ENCRYPT_DEBUG = 'topremit_debug';

const configDev: Options =
  typeof window !== 'undefined' &&
  process.env.NODE_ENV === 'development' &&
  window.location.hostname === 'localhost'
    ? { credentials: 'include' }
    : {};

function leadify(fn: <T extends unknown>(...args) => Promise<T>) {
  let subs: any[] = [];
  return function () {
    return new Promise((resolve, reject) => {
      if (subs.length === 0) {
        fn()
          .then((result) => {
            subs.forEach((_cb) => {
              _cb?.(result);
            });
          })
          .catch((e) => reject(e))
          .finally(() => (subs = []));
      }
      subs.push((result) => {
        resolve(result);
      });
    });
  };
}

export const renewAccessToken = async () => {
  if (typeof window !== 'undefined') {
    const existingRefreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

    try {
      const result = await requestFn(
        {
          path: 'auth/refresh',
          method: 'post',
        },
        {
          json: {
            refresh_token: existingRefreshToken,
          },
        },
      );

      const {
        data: {
          expiresIn: newExpiresIn,
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        },
      } = result;
      const isValidResponse = Boolean(
        newExpiresIn && newAccessToken && newRefreshToken,
      );

      if (isValidResponse) {
        if (isNativeApp()) {
          sendNativeMessage({
            name: NativeWebViewBridgeEventName.REFRESH_TOKEN,
            data: {
              refreshToken: newRefreshToken,
              accessToken: newAccessToken,
              expireIn: newExpiresIn,
            },
          });
        }

        localStorage.setItem(ACCESS_TOKEN_KEY, String(newAccessToken));
        localStorage.setItem(REFRESH_TOKEN_KEY, String(newRefreshToken));
      }

      return result;
    } catch (e) {
      throw e;
    }
  }
  return undefined;
};

const _r = leadify(renewAccessToken);

export function handleAuthRequired(
  encrypt?: string,
  hasRefreshToken?: boolean,
) {
  const currentLang = String(Router.locale);
  const hasCurrentLang = locales.includes(currentLang);

  if (isAuthRequired(Router.pathname)) {
    // Note: to ensure window location href not running before clean local storage
    moveToNextTick(() => {
      window.location.href = `${window.location.origin}/${
        hasCurrentLang ? currentLang : 'en'
        // Note: Added query param to detect redirected from
      }/login?_r=${hasRefreshToken ? 'c_1' : 'c_2'}${
        encrypt ? `&_p=${encrypt}` : ''
      }`;
    });
  }
}

export const client = ky.create({
  prefixUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/api`,
  timeout: 15000,
  headers: {
    Accept: 'application/json',
  },
  retry: {
    limit: 3,
    methods: ['get', 'post'],
    statusCodes: [HTTP_UNAUTHORIZED],
  },
  hooks: {
    beforeRetry: [
      async ({ request, error }) => {
        const { statusCode } = (error as unknown as ApiError) || {};
        const url = new URL(request.url);
        const prevPath = url.pathname;
        const encrypt = encryptString(prevPath, ENCRYPT_DEBUG);
        const refreshToken =
          typeof window !== 'undefined'
            ? localStorage.getItem(REFRESH_TOKEN_KEY)
            : null;

        if (!refreshToken) {
          if (isNativeApp()) {
            sendNativeMessage({
              name: NativeWebViewBridgeEventName.REFRESH_TOKEN_INVALID,
            });

            return;
          }

          deleteAllLocalToken();
          handleAuthRequired(encrypt, false);
          throw error;
        }
        if (statusCode === HTTP_UNAUTHORIZED) {
          try {
            const result = (await _r()) as ApiResult<AuthResponse>;
            const {
              data: { accessToken },
            } = result;
            const setAccessToken = (token) => {
              request.headers.set('Authorization', token);
            };
            if (accessToken) {
              setAccessToken(accessToken);
            }
          } catch (error) {
            if ([400, 422].includes(error.statusCode)) {
              if (isNativeApp()) {
                sendNativeMessage({
                  name: NativeWebViewBridgeEventName.REFRESH_TOKEN_INVALID,
                });

                return;
              }
              queryClient.removeQueries();
              deleteAllLocalToken();
              handleAuthRequired(encrypt, true);
            }
            throw error;
          }
        } else {
          throw error;
        }
      },
    ],
    beforeRequest: [
      async (request) => {
        const accessToken =
          typeof window !== 'undefined'
            ? localStorage.getItem(ACCESS_TOKEN_KEY) ||
              sessionStorage.getItem(ACCESS_TOKEN_KEY)
            : null;
        const apiEndPoint = getURLLastSegment(request.url);
        const excludedEndPoint = [''];
        const { currentAccount } = useCurrentAccountStore.getState() || {};

        if (!excludedEndPoint.includes(apiEndPoint)) {
          request.headers.set(
            'x-selected-profile-id',
            currentAccount
              ? currentAccount.type === 'BUSINESS'
                ? currentAccount.id
                : ''
              : '',
          );
          const DVUUID = getUUID();
          const browserInfo = getBrowserInfo();
          if (DVUUID) {
            request.headers.set('x-device-fingerprint', DVUUID);
          }
          if (browserInfo) {
            request.headers.set('x-device-model', browserInfo);
          }

          if (typeof document !== 'undefined') {
            request.headers.set(
              'accept-language',
              document.documentElement.lang || 'en',
            );
          }
          if (accessToken) {
            request.headers.set('Authorization', `Bearer ${accessToken}`);
          }
          request.headers.set('X-APP-VERSION', packageInfo.version);
          request.headers.set('X-API-VERSION', API_VERSION);
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        const rsp = response.clone();

        if (response.headers.get('content-type') !== 'application/json') {
          return response;
        }
        const data = await rsp.json();
        const body = humps.camelizeKeys(data);

        body.statusCode = response.status;
        if (body.statusCode === 401) {
          throw body;
        } else if (body.statusCode < 200 || body.statusCode >= 400) {
          throw body;
        }
        return response;
      },
    ],
  },
  ...configDev,
});

async function queryFn({ queryKey }): Promise<any> {
  let params = '';
  if (queryKey[2]) {
    params = qs.stringify(queryKey[2]);
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await client.get(queryKey[1], {
        searchParams: params,
      });
      if (response.headers.get('content-type') === 'application/json') {
        if (queryKey[1] === 'upload-url') {
          resolve(await response.json());
        }
        resolve(humps.camelizeKeys(await response.json()));
      } else {
        resolve(response.blob());
      }
    } catch (e) {
      reject(e);
    }
  });
}

export function requestFn(
  {
    path,
    method,
  }: {
    path: string;
    method: 'get' | 'put' | 'delete' | 'post' | 'patch';
  },
  options?: Options | undefined,
): Promise<any> {
  const dataOptions = options as Options;
  let formData;
  const isServerSide = typeof window === 'undefined';

  if (!isServerSide && isFormData(dataOptions?.body)) {
    formData = new FormData();
    for (const [key, value] of dataOptions?.body.entries()) {
      formData.append(humps.decamelize(key), value);
    }
  }

  if (dataOptions?.json) {
    dataOptions['json'] = dataOptions?.json
      ? humps.decamelizeKeys(dataOptions?.json)
      : undefined;
  } else if (dataOptions?.body) {
    dataOptions['body'] = formData;
  } else if (dataOptions?.searchParams) {
    dataOptions['searchParams'] = dataOptions?.searchParams
      ? humps.decamelizeKeys(dataOptions?.searchParams)
      : undefined;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const response = await client[method](path, dataOptions);

      if (response.headers.get('content-type') === 'application/json') {
        resolve(humps.camelizeKeys(await response.json()));
      } else {
        const fileName = response.headers.get('x-file-name');
        resolve({ blob: await response.blob(), fileName });
      }
    } catch (e) {
      Object.assign(e, {
        payload: dataOptions?.searchParams,
      });

      reject(e);
    }
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn,
      refetchOnWindowFocus: false,
      staleTime: 20 * 60 * 1000,
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
});
