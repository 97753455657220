import { createContext, Dispatch, useContext, useReducer } from 'react';

import { assertContextCalledInProvider } from '../common/assertion';

type GlobalStateAction = 'ADD' | 'REMOVE' | 'ADD_AND_PERSIST';

type State = Map<string, any>;

type Action = { type: GlobalStateAction; payload?: any; key?: string };

type GlobalStateCtx = [State, Dispatch<Action>];

const TOPREMIT_GLOBAL_STORE_KEY = 'TR_G_STORAGE';

const GlobalStateContext = createContext<GlobalStateCtx | null>(null);

function globalReducer(state: State, { type, payload, key }: Action): State {
  const storageKey = key || 'default';
  const localStorageValue: State = new Map(
    Object.entries(
      JSON.parse(
        window?.localStorage.getItem(TOPREMIT_GLOBAL_STORE_KEY) || '{}',
      ),
    ),
  );
  const prevPersistedState = localStorageValue.get(storageKey) || new Map();

  switch (type) {
    case 'ADD':
      state.set(storageKey, payload);
      break;
    case 'REMOVE':
      state.delete(storageKey);
      break;
    case 'ADD_AND_PERSIST':
      state.set(storageKey, payload);

      window.localStorage.setItem(
        TOPREMIT_GLOBAL_STORE_KEY,
        JSON.stringify(payload),
      );
      break;
    default:
      break;
  }
  return new Map([...prevPersistedState, ...state]);
}

export const GlobalStateProvider = ({ children }) => {
  const globalState = useReducer(globalReducer, new Map());

  return (
    <GlobalStateContext.Provider value={globalState}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default function useGlobalState() {
  const globalStateCtx = useContext<GlobalStateCtx | null>(GlobalStateContext);

  assertContextCalledInProvider<GlobalStateCtx>(globalStateCtx);

  return globalStateCtx;
}
