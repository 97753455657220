import { create } from 'zustand';

import { SetStateStore, setStore } from './helper';

export type tabHowToType = 'current_bank' | 'other_bank';

interface IWalletStore {
  isMutating: boolean;
  hideWallet: boolean;
  currentTabHowTo: tabHowToType;
  setHideWallet: (value: SetStateStore<boolean>) => void;
  setIsMutating: (value: SetStateStore<boolean>) => void;
  setCurrentTabHowTo: (value: SetStateStore<tabHowToType>) => void;
}

const initialState = {
  hideWallet: false,
  isMutating: false,
  currentTabHowTo: 'current_bank' as tabHowToType,
};

export const useWalletStore = create<IWalletStore>((set) => ({
  ...initialState,
  setHideWallet: (value) =>
    set((state) => setStore(state, { hideWallet: value })),
  setIsMutating: (value) =>
    set((state) => setStore(state, { isMutating: value })),
  setCurrentTabHowTo: (value) =>
    set((state) => setStore(state, { currentTabHowTo: value })),
  resetStore: () => set(initialState),
}));
