import { setRefundSourceStore } from '@modules/refund/use-refund-source-store';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import {
  NativeWebViewBridgeEvent,
  NativeWebViewBridgeEventName,
  NativeWebViewBridgeIncludedEvent,
} from '@topremit/shared-web/typings/native-web-view-bridge.model';
import { PageAccessRefundRequest } from '@topremit/shared-web/typings/refund-request.model';

import { queryClient } from './client';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './storage';

export function handleMobileEvent(
  event: NativeWebViewBridgeEvent<NativeWebViewBridgeIncludedEvent>,
) {
  switch (event.name) {
    case NativeWebViewBridgeEventName.REFRESH_TOKEN:
      localStorage.setItem(ACCESS_TOKEN_KEY, String(event.data.accessToken));
      localStorage.setItem(REFRESH_TOKEN_KEY, String(event.data.refreshToken));

      break;
    case NativeWebViewBridgeEventName.REFUND_REQUEST_ANALYTICS:
      if (event.data.source) {
        setRefundSourceStore(event.data.source as PageAccessRefundRequest);
      }
      break;
    case NativeWebViewBridgeEventName.FETCH_ME:
      queryClient.invalidateQueries({ queryKey: ['me'] });
      break;
    default:
      break;
  }
}

export function handleNativeOpenActivities() {
  if (isNativeApp())
    sendNativeMessage({
      name: NativeWebViewBridgeEventName.OPEN_ACTIVITIES,
    });
}
