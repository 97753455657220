export enum TRCoinHistoryType {
  BULK = 'Bulk ID',
  TRANSACTION = 'Txn ID',
  WITHDRAW = 'W/D ID',
}

export enum WithdrawStatusType {
  REQUESTED = 'REQUESTED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  REFUNDED = 'REFUNDED',
}

export interface TRCoinInfoModel {
  balance: number;
  canWithdraw: boolean;
  message?: string;
  messageType?: 'info' | 'warning' | 'danger';
}
export interface WithdrawMemberBankAccountModel {
  id: string;
  accountName: string;
  accountNumber: string;
  bank: number;
  payer: {
    id: number;
    name: string;
  };
}
export interface TRCoinWithdrawDetailModel {
  id: string;
  status: WithdrawStatusType;
  amount: string;
  createdAt: string;
  sentAt: string;
  memberBankAccountId: string;
  memberBankAccount: WithdrawMemberBankAccountModel;
}

export interface TRCoinsHistoryModel {
  amount: number;
  createdAt: string;
  description: string;
  icon: 'withdraw-outline' | 'plus-small-outline';
  transactionableId: string;
  recipients?: string[];
  type: 'debit' | 'credit';
  link: TRCoinHistoryType;
}

export interface CreateTRCoinsWithdrawalRequest {
  withdrawalAmount: number;
  pinCode: string;
  memberBankAccountId: string;
}

export interface CreateTRCoinsWithdrawalResponse {
  id: string;
}

export interface TRCoinsBankAccountModel {
  accountName: string;
  accountNumber: string;
  bank: string;
  canDelete: false;
  fee: number;
  id: string;
  matchingStatus: string;
  primary: boolean;
  defaultSelectedBank: boolean;
  rejectedReason?: string;
  verifyAt: string;
}
