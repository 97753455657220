import { css } from '@emotion/react';

export default css`
  .btn-block {
    width: 100% !important;
  }
  .ant-tag {
    display: inline-flex;
    height: 2.5rem;
    padding: 8px;
    align-items: center;
  }

  .ant-tag-close-icon {
    display: flex;
    margin-left: 10px;
  }

  .ant-switch-disabled {
    * {
      cursor: default;
    }
    cursor: default;
  }
  a.ant-btn {
    line-height: normal;
    &.ant-btn-small {
      &.ant-btn-round {
        padding: 0.5rem 1rem !important;
      }
    }
  }

  .ant-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    box-shadow: unset;

    &[disabled] {
      cursor: default !important;
      background-color: unset;
      color: unset;
    }
    &.ant-btn-round {
      display: flex;
      align-items: center;
      padding: 0.675rem 1.34375rem;
      border-radius: 100px;
      max-height: 2.75rem;
    }
    &.ant-btn-small {
      max-height: 2.125rem;
      height: 100%;
      min-width: 1.875rem;
      &.ant-btn-circle {
        width: 2.375rem;
        height: 2.375rem;
        min-height: 2.375rem;
        max-width: 2.375rem;
        max-height: 2.375rem;
        padding: 0 !important;
      }
      &.ant-btn-round {
        padding: 0.5rem 1rem;
      }
    }
  }
`;
