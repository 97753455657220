import * as Sentry from '@sentry/nextjs';
import React, { Component, ReactNode } from 'react';

import ErrorBoundaryPage from './ErrorBoundaryPage';

interface ErrorBoundaryProps {
  children: ReactNode;
  layout?: 'DefaultLayout' | 'DashboardLayout';
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    Sentry.captureException(error);
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error);
    console.error('Error caught in ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryPage layout={this.props.layout} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
