import { css } from '@emotion/css';
import { Drawer as AntdDrawer } from 'antd';
import { ReactNode } from 'react';

import { useDrawer } from '../../../hooks';
import { Close } from '../../shapes';

interface IDrawerListProps {
  title?: string;
  className?: string;
  children: ReactNode;
}

export default function DrawerList({
  title,
  className,
  children,
}: IDrawerListProps) {
  const { isOpen, onClose: closeDrawer } = useDrawer();

  return (
    <AntdDrawer
      title={title}
      open={isOpen}
      onClose={closeDrawer}
      className={className}
      classNames={{ header: styled.headerDrawer, body: styled.bodyDrawer }}
      closeIcon={<Close width={24} height={24} fill="var(--neutral-500)" />}
    >
      {children}
    </AntdDrawer>
  );
}

const styled = {
  headerDrawer: css`
    border-bottom: none !important;
    .ant-drawer-header-title {
      flex-direction: column;
      .ant-drawer-close {
        align-self: end;
        margin-right: 0;
        margin-bottom: 0.75rem;
      }
      .ant-drawer-title {
        align-self: flex-start;
        font-size: var(--h5-font-size);
      }
    }
  `,
  bodyDrawer: css`
    padding: 2rem 1.5rem !important;
    > :not(:last-child) {
      margin-bottom: 1rem;
    }
    .dropdown-item-wrapper {
      display: flex;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
      .icon {
        width: unset;
        margin-right: 1rem;
        padding: 8px 4px;
      }
      .dropdown-item-detail {
        justify-content: flex-start;
      }
      &.selected,
      &:hover {
        background: var(--blue-100);
      }
      &.selected {
        color: var(--blue-500);
      }
    }
  `,
};
