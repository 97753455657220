import { ClassNamesArg, css, cx } from '@emotion/css';
import { useSidebar } from '@global-hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, memo } from 'react';

type SideMenuItemBag = {
  isActive: boolean;
};

interface SideMenuItemProps {
  children: ReactNode | ((args: SideMenuItemBag) => ReactNode);
  href?: string;
  target?: HTMLAnchorElement['target'];
  className?: ClassNamesArg;
}

function SideMenuItem({
  children,
  href,
  className,
  target,
}: SideMenuItemProps) {
  const router = useRouter();
  const [{ isExpand }] = useSidebar();

  const isActive = !!(
    typeof href !== 'undefined' && router.asPath.includes(href)
  );

  const render =
    typeof children === 'function' ? children({ isActive }) : children;

  const linkOrPlainNode =
    typeof href !== 'undefined' ? (
      <Link
        href={href}
        className={cx(styled.flexRow, styled.item, {
          [styled.selected]: isActive,
        })}
        target={target}
      >
        {render}
      </Link>
    ) : (
      render
    );

  return (
    <li
      className={cx(
        { [styled.item]: !href },
        { [styled.itemLink]: !!href },
        { [styled.animateIcon]: !isExpand },
        styled.flexRow,
        styled.outerWrapper,
        className,
      )}
    >
      {linkOrPlainNode}
    </li>
  );
}

export default memo(SideMenuItem);

const styled = {
  flexRow: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  outerWrapper: css``,
  animateIcon: css`
    & > *:hover [data-type='icon'] {
      transition: all 0.25s;
      transform: scale(1.25);
    }
  `,
  item: css`
    user-select: none;
    flex-grow: 1;
    font-size: var(--sm-font-size);
    color: var(--text-secondary);
    padding: 14px;
    border-radius: 8px;
    min-height: 54px;
    a {
      /** prevent underline when click */
      text-decoration: none !important;
    }
  `,
  itemLink: css`
    a:hover {
      background: var(--link-active-bg);
    }

    a {
      cursor: pointer;
    }
  `,
  selected: css`
    font-weight: 700;
    color: var(--link-active-text);
    background: var(--link-active-bg);
  `,
};
