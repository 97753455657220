import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import { Input, InputRef } from 'antd';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { variants } from './constants';
import { IPasswordInputProps } from './types';
import { handleInputCondition } from '../../../common/helper';
import { color } from '../../../styles/color';
import { Eye } from '../../shapes';

const { Password: PasswordAntd } = Input;

const TextInput = forwardRef((props: IPasswordInputProps, ref: any) => {
  const {
    name,
    rules,
    label,
    disabled,
    className,
    helperText,
    bordered = true,
    disabledFocus = false,
    inputWrapperClassName,
    inputRef: inputRefProp,

    onFocus,
    onChange,

    ...resProps
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const formContext = useFormContext();
  const { control } = formContext;

  const inputRef = useRef<InputRef>(null);
  const fieldGroupRef = useRef<HTMLDivElement>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...(rules && rules),
    },
  });

  const isError = !!error;
  const errorMessage = error?.message;

  const isFilled = !!field.value;

  const _disabled = disabled || disabledFocus;

  const inputClass = cx('input-control', { error: isError });

  const formControlProps = {
    variants,
    className: 'form-control',
    onClick: () => {
      if (!disabled) {
        setIsFocus(!disabledFocus);
      }
    },
    animate: handleInputCondition({ isFocus, isFilled }),
  };

  function handleFocus(e) {
    onFocus && onFocus(e);
    setIsFocus(!disabledFocus);
  }

  function handleBlur() {
    field.onBlur();
    setIsFocus(false);
  }

  function handleChange(e) {
    field.onChange(e);
    onChange && onChange(e);
  }

  function renderIcon(visible: boolean) {
    return (
      <Eye
        icontype="outline"
        fill={generateEyeColor()}
        eyetype={visible ? 'open' : 'closed'}
      />
    );
  }

  function generateEyeColor() {
    if (disabled) {
      return color.neutral300;
    }
    if (error) {
      return color.red500;
    }
    return color.neutral500;
  }

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
    blur() {
      inputRef.current?.blur();
    },
    ref: fieldGroupRef.current,
  }));

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
      return;
    }
    inputRef.current?.blur();
  }, [isFocus]);

  useEffect(() => {
    if (disabled) {
      setIsFocus(false);
    }
  }, [disabled]);

  return (
    <FieldGroup
      name={name}
      label={label}
      showRightIcon
      isFocus={isFocus}
      isError={isError}
      bordered={bordered}
      ref={fieldGroupRef}
      helper={helperText}
      isFilled={isFilled}
      disabled={disabled}
      error={errorMessage}
      className={className}
      inputWrapperRef={inputRefProp}
      formControlProps={formControlProps}
      rightIconClassName={styled.rightIconWrapper}
      inputWrapperClassName={inputWrapperClassName}
      onBlur={handleBlur}
    >
      <PasswordAntd
        name={name}
        ref={inputRef}
        variant="borderless"
        disabled={_disabled}
        className={inputClass}
        onChange={handleChange}
        iconRender={renderIcon}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={field.value}
        {...resProps}
      />
    </FieldGroup>
  );
});

const styled = {
  rightIconWrapper: css`
    cursor: pointer;
    max-width: 3rem;
    min-width: 3rem;
  `,
};

export default TextInput;
