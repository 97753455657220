import { Flex, Text } from '@topremit/shared-web/components/elements';
import { ReactNode } from 'react';

interface IDropdownItemLabelProps {
  icon: ReactNode;
  title: string;
  description?: string;
}

export default function DropdownItemChild({
  icon,
  title,
  description,
}: IDropdownItemLabelProps) {
  return (
    <Flex className="dropdown-item-detail-wrapper">
      <Flex justify="center" align="center" className="icon">
        {icon}
      </Flex>
      <Flex
        justify="center"
        align="center"
        column
        className="dropdown-item-detail"
      >
        <Text minSize="sm" maxSize="p">
          {title}
        </Text>
        {description && (
          <Text minSize="xs" maxSize="sm" className="description">
            {description}
          </Text>
        )}
      </Flex>
    </Flex>
  );
}
