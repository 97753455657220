import { useGetAccountInfo } from '@api-hooks/common';
import { cx } from '@emotion/css';
import { usePermission } from '@hooks';
import { getNativeInset } from '@topremit/shared-web/common/helper';
import { Card, Flex, Text } from '@topremit/shared-web/components/elements';
import { Angle, Close } from '@topremit/shared-web/components/shapes';
import { color } from '@topremit/shared-web/styles/color';
import { useRouter } from 'next/router';

import { ContentCardContext, useContentCardContext } from './context';
import { contentCard as styled } from './styles';
import {
  IContentCardBodyProps,
  IContentCardFooterProps,
  IContentCardHeaderProps,
  IContentCardProps,
} from './types';
import ChatUsButton from '../chat-cs/ChatUsButton';

function ContentCard({
  children,
  className,
  fullHeight,
  transparentBody = false,
}: IContentCardProps) {
  const { top: nativeTopInset } = getNativeInset();
  return (
    <ContentCardContext.Provider value>
      <Card
        p={0}
        column
        variant={transparentBody ? 'no-outline' : 'outline'}
        className={cx(
          'content-card',
          styled.root({
            fullHeight,
            nativeTopInset,
            transparentBody,
          }),
          className,
        )}
      >
        {children}
      </Card>
    </ContentCardContext.Provider>
  );
}

function ContentCardHeader({
  title,
  children,
  className,
  hasChatUs,
  onBack,
  prefillType = 'email',
  payload,
  onClose,
}: IContentCardHeaderProps) {
  useContentCardContext();

  const router = useRouter();

  const { data: accountInfo } = useGetAccountInfo();
  const { isPersonalAccount } = usePermission();

  function onBackClick() {
    if (typeof onBack === 'function') {
      onBack();
      return;
    }
    router.back();
  }

  return (
    <Flex
      column
      className={cx('content-card-header', styled.header, className)}
    >
      <Flex className={cx('content-card-header-actions', className)}>
        {onBack && (
          <button
            onClick={onBackClick}
            className={cx(styled.button, styled.back)}
          >
            <Angle direction="left" size="small" />
          </button>
        )}
        {title && <Text className={styled.title}>{title}</Text>}
        {onClose && (
          <button
            onClick={onClose}
            className={cx(styled.button, styled.marginLeftAuto)}
          >
            <Close fill={color.neutral600} />
          </button>
        )}
        {hasChatUs && isPersonalAccount && (
          <ChatUsButton
            prefillType={prefillType}
            payload={
              prefillType === 'email' ? accountInfo?.data?.email : payload
            }
            className={cx(styled.chatUs, className)}
          />
        )}
      </Flex>
      {children}
    </Flex>
  );
}

function ContentCardBody({
  children,
  className,
  title,
  description,
  transparentBody,
}: IContentCardBodyProps) {
  useContentCardContext();
  return (
    <Flex
      column
      className={cx(
        'content-card-container',
        styled.body(transparentBody),
        className,
      )}
    >
      {title && <Text className={styled.titleBody}>{title}</Text>}
      {description && <Text className={styled.description}>{description}</Text>}
      {children}
    </Flex>
  );
}

function ContentCardFooter({ children, className }: IContentCardFooterProps) {
  useContentCardContext();
  return (
    <Flex
      column
      className={cx('content-card-footer', styled.footer, className)}
    >
      {children}
    </Flex>
  );
}

ContentCard.Body = ContentCardBody;
ContentCard.Header = ContentCardHeader;
ContentCard.Footer = ContentCardFooter;

export default ContentCard;
