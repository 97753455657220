import { requestFn } from '@common/client';
import { ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { S2SIdentifierPayload } from '@topremit/shared-web/typings/analytics.model';
import { ApiError } from 'next/dist/server/api-utils';
import { UseMutationOptions, useMutation } from 'react-query';

export function usePostS2SIdentifier(
  options?: UseMutationOptions<
    ApiResult<undefined>,
    ApiError,
    S2SIdentifierPayload
  >,
) {
  return useMutation<ApiResult<undefined>, ApiError, S2SIdentifierPayload>(
    async (param) =>
      await requestFn(
        { path: 'analytic-log', method: 'post' },
        { json: param },
      ),
    options,
  );
}
