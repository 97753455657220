import { ReactNode } from 'react';

export interface IDropdownAccordionProps {
  className?: string;
  children: ReactNode;
}

export default function DropdownAccordion({
  children,
  className,
}: IDropdownAccordionProps) {
  return <div className={className}>{children}</div>;
}
