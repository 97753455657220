import { isNativeApp } from '@global-common/native-web-view-bridge';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

interface IUseBackButton {
  path: string;
  nativeMobileBack?(): void;
}

/**
 * useBackButton is a hook that listens to the browser's back button
 * and navigates to the specified path.
 */

const useBackButton = ({ path, nativeMobileBack }: IUseBackButton) => {
  const router = useRouter();

  const backTo = router.query.back_to;

  useEffect(() => {
    function handleBackButton() {
      nativeMobileBack && nativeMobileBack();
      if (backTo) {
        router.replace(String(backTo));
        return;
      }
      router.replace(path);
    }

    if (router.isReady && isNativeApp()) {
      window.addEventListener('popstate', handleBackButton);
    }

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [router.isReady]);
};

export default useBackButton;
