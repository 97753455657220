import { css, cx } from '@emotion/css';
import { AnimatedWrapper } from '@global-elements-utils/animated-wrapper';
import {
  cloneElement,
  isValidElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { AccordionItem } from './AccordionItem';
import { accordionArrowVariants } from './constants';
import { callAllFunctions } from '../../../common/helper';
import { Angle } from '../../shapes';

interface IAccordionProps {
  expand?: boolean;
  className?: string;
  isDisabled?: boolean;
  children?: ReactNode;
  arrowIcon?: ReactNode;
  defaultExpand?: boolean;
  titleClassName?: string;
  title: string | ReactNode;
  togglerClassName?: string;
  hasActionContent?: boolean;
  accordionItemClassName?: string;
  onClick?: (e: any) => void;
  onItemClick?: (value: any) => void;
  onExpandChange?: (expand: boolean) => void;
  items?: { label: string; value: any; [key: string]: any }[];
  greyBackgroundOnClose?: boolean;
  blueBackgroundOnExpand?: boolean;
}

const styled = {
  greyBackground: css`
    background-color: var(--neutral-100);
  `,
  blueBackground: css`
    background-color: var(--blue-100);
    color: var(--blue-500);
    > .icon-wrapper > svg {
      fill: var(--blue-500);
    }
  `,
  parent: (isDisabled: boolean) => css`
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between !important;
    .title {
      user-select: none;
      pointer-events: none;
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
    }
  `,
  itemWrapper: (expand: boolean) => css`
    pointer-events: ${expand ? 'initial' : 'none'};
    user-select: ${expand ? 'all' : 'none'};
    overflow: hidden;
    > .inner-wrapper > * {
      cursor: pointer;
      user-select: none;
    }
  `,
};

function Accordion(props: IAccordionProps) {
  const {
    defaultExpand = false,
    expand: expandProp,
    items,
    onItemClick,
    title,
    children,
    onClick,
    titleClassName,
    className,
    togglerClassName,
    accordionItemClassName,
    arrowIcon,
    onExpandChange,
    isDisabled = false,
    greyBackgroundOnClose = false,
    hasActionContent = false,
    blueBackgroundOnExpand = false,
  } = props;

  const [expand, setExpand] = useState<boolean>(defaultExpand);

  function handleRenderItem() {
    if (items) {
      return items.map(({ label, value }) => {
        return (
          <AccordionItem key={value} value={value} onClick={onItemClick}>
            {label}
          </AccordionItem>
        );
      });
    }
    return children;
  }

  function handleRenderTitle() {
    if (isValidElement(title)) {
      return cloneElement<any>(title, {
        className: `title${
          title.props?.className ? ` ${title.props.className}` : ''
        }`,
      });
    }

    return (
      <div className={cx('title', expand ? 'bold-text' : 'regular-text')}>
        <span>{title}</span>
      </div>
    );
  }

  function handleRenderArrowIcon() {
    if (arrowIcon) {
      return arrowIcon;
    }

    return <Angle direction="down" fill="#C9D4DB" width={16} height={16} />;
  }

  useEffect(() => {
    if (typeof expandProp !== 'undefined') {
      setExpand(expandProp);
    }
  }, [expandProp]);

  useEffect(() => {
    onExpandChange?.(expand);
  }, [expand]);

  return (
    <AnimatedWrapper
      htmlTag="div"
      className={cx('accordion', className, {
        expand,
        [styled.greyBackground]: greyBackgroundOnClose && !expand,
      })}
      {...(!isDisabled && {
        onClick: callAllFunctions(onClick, (e) => {
          if (e.target?.id === 'floating-button' || e.target?.tagName === 'A') {
            return;
          }
          !hasActionContent && setExpand((prev) => !prev);
        }),
      })}
    >
      <AnimatedWrapper
        htmlTag="div"
        className={cx(
          styled.parent(isDisabled),
          'accordion-toggler-wrapper',
          togglerClassName,
          titleClassName,
          { [styled.blueBackground]: blueBackgroundOnExpand && expand },
        )}
        onClick={() => hasActionContent && setExpand((prev) => !prev)}
      >
        {handleRenderTitle()}
        <AnimatedWrapper
          htmlTag="div"
          initial={expand ? 'expand' : 'collapse'}
          animate={expand ? 'expand' : 'collapse'}
          variants={accordionArrowVariants}
          className="icon-wrapper"
        >
          {handleRenderArrowIcon()}
        </AnimatedWrapper>
      </AnimatedWrapper>
      <AnimatedWrapper
        htmlTag="div"
        initial={expand ? 'h-auto' : 'h-0'}
        className={cx(
          styled.itemWrapper(expand),
          'accordion-item-wrapper',
          accordionItemClassName,
        )}
        animate={expand ? 'h-auto' : 'h-0'}
      >
        <div className="inner-wrapper">{handleRenderItem()}</div>
      </AnimatedWrapper>
    </AnimatedWrapper>
  );
}

export { Accordion, AccordionItem };
