import {
  businessOnlyRoutes,
  personalOnlyRoutes,
  routePermission,
} from '@common/routesPermission';
import { SHOW_BLOCKING_MODAL } from '@common/storage';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useState } from 'react';

import usePermission from './use-permission';

interface Props {
  children: ReactElement;
}

export default function NavigationProvider({ children }: Props) {
  const { asPath, pathname, push, isReady } = useRouter();
  const [loading, setLoading] = useState(true);

  const {
    can,
    permissions: permissionData,
    isBusinessAccount,
    isPersonalAccount,
  } = usePermission();
  const permission = routePermission[asPath] || routePermission[pathname];

  const isBusinessOnly = businessOnlyRoutes.some(
    (routeName) => routeName === asPath,
  );
  const isPersonalOnly = personalOnlyRoutes.some(
    (routeName) => routeName === asPath,
  );

  useEffect(() => {
    if (!isReady || (!permissionData && isBusinessAccount)) {
      return;
    }
    // Note: if the current route need to be validated (exist in routesPermission) then validate it, if not then just allow it by return true
    const allowed = permission ? can(permission) : true;

    if (
      (permissionData && !allowed) ||
      (isBusinessOnly && isPersonalAccount) ||
      (isPersonalOnly && isBusinessAccount)
    ) {
      // handling affiliate special case
      if (pathname === '/affiliate') {
        window.sessionStorage.setItem(SHOW_BLOCKING_MODAL, 'true');
      }

      isBusinessAccount ? push('/profile') : push('/home');
    } else {
      setLoading(false);
    }
  }, [asPath, permissionData, isReady, permission, isBusinessAccount]);

  if (loading) return null;

  return children;
}
