import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { SkeletonWrapper } from '@topremit/shared-web/components/elements';

import { ISectionFieldProps } from './types';

const { tabletSm } = screenSize;

export default function SectionFieldProps({
  label,
  children,
  className,
  isLoading,
}: ISectionFieldProps) {
  return (
    <div className={cx(styled.root, className)}>
      {label && (
        <div className="field-label">
          <span>{label}</span>
        </div>
      )}
      <SkeletonWrapper className={styled.skeletonField} loading={isLoading}>
        <div className="field-value">
          {/**
           * the value with class `notranslate`
           * will not be translated by google translate
           */}
          <span className="notranslate">{children}</span>
        </div>
      </SkeletonWrapper>
    </div>
  );
}

const styled = {
  root: css`
    @media (max-width: ${tabletSm}px) {
      display: flex;
      justify-content: space-between;
    }
    .field-label {
      margin-bottom: 0.25rem;
      color: var(--neutral-500);
      @media (max-width: ${tabletSm}px) {
        flex-basis: 11.25rem;
      }
    }
    .field-value {
      word-break: break-word;
      @media (max-width: ${tabletSm}px) {
        flex-basis: 11rem;
        text-align: right;
      }
    }
  `,
  skeletonField: css`
    width: 7.625rem;
    border-radius: 0.5rem;
  `,
};
