import { css } from '@emotion/react';

export default css`
  .ant-progress {
    line-height: 0;
    * {
      height: inherit;
    }
    > .ant-progress-outer > .ant-progress-inner {
      > .ant-progress-bg {
        height: inherit !important;
        border-radius: 0;
      }
      > .ant-progress-success-bg {
        height: inherit !important;
      }
    }
    &.ant-progress-status-active {
      > .ant-progress-outer > .ant-progress-inner > .ant-progress-bg::before {
        z-index: 1;
      }
    }
  }
`;
