import { css } from '@emotion/css';
import { screenSize } from '@global-common/size';
import { color } from '@topremit/shared-web/styles/color';

import {
  backgroundColor,
  borderColor,
  fontColor,
  outlineSize,
} from './constants';

export const styles = {
  root: css`
    width: 100%;
  `,
  inputContainer: css`
    position: relative;
    display: inline-block;
    width: 100%;
  `,
  input: (error: boolean) => css`
    // Base input styles
    height: 62px;
    width: 100%;
    padding: 1rem 1.25rem;
    border-radius: 8px;
    background-color: ${backgroundColor.default};
    border: ${outlineSize.default} solid ${borderColor.default};
    font-size: 16px;
    font-family: var(--font-family);
    color: var(--text-primary);
    outline: none;
    transition: border-color 0.2s ease-in-out;

    // When hovered, the border changes color based on the error state
    &:hover {
      border: 2px solid ${error ? borderColor.error : color.neutral500};

      // If the placeholder is still visible (means has no value yet) and input is not focused, use a thinner border
      &:placeholder-shown:not(:focus) {
        border: 1px solid ${error ? borderColor.error : color.neutral500};
      }
    }

    // When focused, adjust padding and change border color
    &:focus {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      border: ${outlineSize.active} solid ${borderColor.active};
    }

    // When input is not focused and has a value (not showing the placeholder)
    &:not(:focus):not(:placeholder-shown) {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      border: ${outlineSize.default} solid ${borderColor.default};
    }

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 14px;
    }
  `,

  label: (error: boolean) => css`
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: ${error ? fontColor.error : fontColor.default};
    transition: all 0.2s ease-in-out;
    pointer-events: none;

    input:focus ~ &,
    input:not(:placeholder-shown) ~ & {
      top: 30%;
      font-size: 14px;
      @media (max-width: ${screenSize.tabletMd}px) {
        font-size: 12px;
      }
    }

    input:disabled ~ & {
      color: ${fontColor.disabled};
    }

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 14px;
    }
  `,
  error: css`
    border: ${outlineSize.error} solid ${borderColor.error}!important; // override default border
  `,
  message: css`
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: ${color.neutral500};

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.75rem;
    }
  `,
  borderless: css`
    border: none !important;
  `,
  disabled: css`
    cursor: not-allowed;
    background-color: ${backgroundColor.disabled};
    border: ${outlineSize.disabled} solid ${borderColor.disabled}!important;
  `,
  alignRight: css`
    input,
    label {
      text-align: right;
    }
    label {
      right: 20px;
    }
  `,
};
