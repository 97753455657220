import { ClassNamesArg, css, cx } from '@emotion/css';
import { Angle, RadioActive, RadioIcon } from '@global-shapes';
import getConfig from 'next/config';
import Image from 'next/image';
import { memo, ReactNode } from 'react';

import Divider from './Divider';
import { AnimatedWrapper } from './animated-wrapper';
import { screenSize } from '../../common/size';

type ListMenuType = 'empty' | 'checkbox' | 'radio' | 'arrow' | 'accordion';

type ArrowType = 'primary' | 'secondary';

export interface ListMenuProps {
  type: ListMenuType;

  badgeValue?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  content?: ReactNode;

  arrowType?: ArrowType;
  arrowHeight?: number;
  arrowWidth?: number;

  title?: string | ReactNode;
  description?: string | ReactNode;

  accordionChildren?: ReactNode;
  accordionRightElement?: ReactNode;
  aboveAccordionChildElement?: ReactNode;

  pill?: ReactNode;
  isCenterVerticalPill?: boolean;

  hasBadge?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  hasDivider?: boolean;

  className?: ClassNamesArg;
  iconClassName?: ClassNamesArg;
  titleClassName?: ClassNamesArg;
  descriptionClassName?: ClassNamesArg;

  onClick?: (e?: any) => void;
}

export const accordionArrowVariants = {
  expand: {
    transform: 'rotate(90deg)',
    transition: {
      duration: 0.2,
    },
  },
  collapse: {
    transform: 'rotate(0deg)',
    transition: {
      duration: 0.2,
    },
  },
};

const { publicRuntimeConfig } = getConfig();

function ListMenu({
  pill,
  type,
  title,
  content,
  className,
  leftIcon,
  rightIcon,
  arrowType,
  badgeValue,
  isDisabled,
  description,
  iconClassName,
  titleClassName,
  arrowWidth = 24,
  hasBadge = false,
  arrowHeight = 24,
  accordionChildren,
  isSelected = false,
  hasDivider = false,
  isCenterVerticalPill,
  descriptionClassName,
  accordionRightElement,
  aboveAccordionChildElement,
  onClick,
}: ListMenuProps) {
  if (
    (type === 'accordion' && accordionChildren === undefined) ||
    (accordionChildren !== undefined && type !== 'accordion')
  ) {
    throw new Error('accordionChildren is required when type is "accordion"');
  }

  if (type !== 'accordion' && accordionRightElement !== undefined) {
    throw new Error(
      'props accordionRightElement only can use for type accordion.',
    );
  }

  if (type === 'checkbox' && leftIcon !== undefined) {
    throw new Error('type checkbox shouldnt have icon.');
  }

  if (type === 'arrow' && isSelected) {
    throw new Error('type arrow cannot be selected.');
  }

  if (type !== 'arrow' && arrowType !== undefined) {
    throw new Error('props arrowTime only can use for type arrow.');
  }

  if (
    content !== undefined &&
    (title !== undefined || description !== undefined)
  ) {
    throw new Error(
      'cannot use content simultaneously with title and description.',
    );
  }

  if (isCenterVerticalPill !== undefined && pill === undefined) {
    throw new Error('use isCenterVerticalPill simultaneously with pill.');
  }

  if (hasBadge !== false && badgeValue === undefined) {
    throw new Error('badgeValue is required when hasBadge is true.');
  }

  if (content && (title || description)) {
    throw new Error(
      "If 'content' is provided, 'title' and 'description' cannot be provided.",
    );
  }

  if (rightIcon && type !== 'empty' && type !== 'checkbox') {
    throw new Error('type arrow, accordion, or radio cannot use rightIcon');
  }

  const hasLeftElement = type === 'checkbox' || leftIcon !== undefined;
  const hasRightElement =
    rightIcon !== undefined ||
    type === 'arrow' ||
    type === 'accordion' ||
    type === 'radio';

  function renderLeftElement() {
    if (type === 'checkbox') {
      return (
        <div className={styled.checkbox(isSelected)}>
          {isSelected && (
            <Image
              src={`${
                publicRuntimeConfig?.staticFolder ?? ''
              }/images/svg/check-bold.svg`}
              width={12}
              height={12}
              alt="id"
            />
          )}
        </div>
      );
    }

    if (leftIcon) {
      return <div className={cx('icon', iconClassName)}>{leftIcon}</div>;
    }

    return null;
  }

  function renderContent() {
    if (content) {
      return content;
    }

    return (
      <>
        <div className="col">
          {typeof title === 'string' ? (
            <div className={cx(titleClassName, 'title')}>
              <span>{title}</span>
              {pill && !isCenterVerticalPill && (
                <div className="pills">{pill}</div>
              )}
            </div>
          ) : (
            title
          )}

          {typeof description === 'string' ? (
            <div className={cx(descriptionClassName, 'desc sm-text')}>
              {description}
            </div>
          ) : (
            description
          )}
        </div>
        {pill && isCenterVerticalPill && <div className="pills">{pill}</div>}
      </>
    );
  }

  function renderRightElement() {
    switch (type) {
      case 'radio':
        return isSelected ? <RadioActive /> : <RadioIcon />;
      case 'arrow':
        return (
          <Angle
            size="small"
            fill={
              arrowType === 'primary'
                ? 'var(--blue-500)'
                : 'var(--text-secondary)'
            }
            direction="right"
            width={arrowWidth}
            height={arrowHeight}
          />
        );
      case 'accordion':
        return accordionRightElement ? (
          <span className="sm-text">{accordionRightElement}</span>
        ) : (
          <AnimatedWrapper
            htmlTag="div"
            initial={isSelected ? 'expand' : 'collapse'}
            animate={isSelected ? 'expand' : 'collapse'}
            variants={accordionArrowVariants}
          >
            <Angle
              size="small"
              fill={
                arrowType === 'primary'
                  ? 'var(--blue-500)'
                  : 'var(--text-secondary)'
              }
              direction="right"
              width={arrowWidth}
              height={arrowHeight}
            />
          </AnimatedWrapper>
        );
      default:
        return null;
    }
  }

  function _onClick(e) {
    e.stopPropagation();
    e.preventDefault();

    onClick?.();
  }

  return (
    <>
      <div
        className={cx(
          styled.root(isDisabled),
          {
            [styled.disabled]: isDisabled,
          },
          className,
        )}
        onClick={_onClick}
      >
        {hasBadge && (
          <div className="badge-wrapper">
            {badgeValue}
            <div className="curve-ribbon">
              <div className="back-ribbon" />
            </div>
          </div>
        )}
        <div className="wrapper">
          {hasLeftElement && (
            <div className="left-element">{renderLeftElement()}</div>
          )}
          <div className="content">{renderContent()}</div>
          {hasRightElement && (
            <div className="right-element">
              {rightIcon ? rightIcon : renderRightElement()}
            </div>
          )}
        </div>
        {isSelected && accordionChildren && (
          <div
            className={styled.withAboveElement(!!aboveAccordionChildElement)}
          >
            <div className="above-element">
              {!!aboveAccordionChildElement && aboveAccordionChildElement}
            </div>
            <div
              className={styled.accordionChildren(!!aboveAccordionChildElement)}
            >
              {accordionChildren}
            </div>
          </div>
        )}
      </div>
      {hasDivider && <Divider my={0} />}
    </>
  );
}

const styled = {
  root: (disabled?: boolean) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem 1rem;
    width: 100%;
    //remove highlight effect when click
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    user-select: none; /* Generic */
    position: relative;
    .badge-wrapper {
      position: absolute;
      top: -0.65rem;
      right: -0.3125rem;
      padding: 0.125rem 0.5rem;
      background-color: var(--red-500);
      border-radius: 0.25rem 0.25rem 0;
      color: var(--neutral-0);
      font-size: 0.75rem;
      line-height: 0.975rem;
      .curve-ribbon {
        position: absolute;
        background-color: var(--red-500);
        bottom: -0.125rem;
        right: 0;
        width: 0.3125rem;
        height: 0.125rem;
        .back-ribbon {
          background-color: #b62626;
          width: 0.3125rem;
          height: 0.25rem;
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
    .wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      > .left-element {
        display: flex;
        margin-right: 0.75rem;
        height: 100%;
        .icon {
          align-items: center;
          height: 2rem;
          filter: ${disabled ? 'grayscale(70%)' : 'unset'};
          opacity: ${disabled ? 0.5 : 1};
        }
      }
      > .content {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 100%;
        > .col {
          display: flex;
          flex-direction: column;
          width: 100%;
          > .title,
          > .desc {
            width: 100%;
          }
          > .title {
            display: flex;
            align-items: center;
            color: ${!disabled ? 'var(--text-primary)' : 'var(--neutral-300)'};
            @media (max-width: ${screenSize.tabletSm}px) {
              font-size: 0.875rem;
            }
            .pills {
              width: fit-content;
              margin-left: auto;
            }
          }
          > .desc {
            margin-top: 0.25rem;
            color: ${!disabled
              ? 'var(--text-secondary)'
              : 'var(--neutral-300)'};
            @media (max-width: ${screenSize.tabletSm}px) {
              font-size: 0.75rem;
            }
          }
        }
      }
      > .right-element {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        padding-left: 0.75rem;
      }
    }
  `,
  disabled: css`
    cursor: default;
    pointer-events: none;
    /* background: var(--neutral-100) !important; */
    > * {
      /* background: var(--neutral-100) !important; */
      opacity: 0.6 !important;
    }
  `,
  checkbox: (isSelected: boolean) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    background: ${isSelected ? 'var(--blue-500)' : 'var(--neutral-0)'};
    border: 1px solid var(--neutral-200);
    border-radius: 0.5rem;
  `,
  withAboveElement: (hasUpperElement) => css`
    margin-top: ${hasUpperElement ? '0.75rem' : 'unset'};
    width: 100%;
    .above-element {
      > * {
        width: 100%;
      }
    }
  `,
  accordionChildren: (hasUpperElement) => css`
    padding-top: ${hasUpperElement ? '0.5rem' : '0.75rem'};
    width: 100%;
    > * {
      padding-left: unset !important;
      padding-right: unset !important;
      :last-child {
        padding-bottom: unset;
      }
    }
  `,
};

export default memo(ListMenu);
