import { cx } from '@emotion/css';
import { useMemo, useState } from 'react';

import ChooseCountryCurrencyContent from './ChooseCountryCurrencyContent';
import ChooseCountryCurrencyContentWrapper from './ChooseCountryCurrencyContentWrapper';
import { ChooseCountryCurrencyContext } from './hooks/use-choose-country-currency';
import {
  IChooseCountryCurrencyProps,
  ICountryWithRecent,
  IRawCountryWithRecent,
} from './types';
import { ErrorMessage, Flex } from '../..';

export default function ChooseCountryCurrency(
  props: IChooseCountryCurrencyProps,
) {
  const {
    error,
    options,
    className,
    recentCountries,
    bordered = true,
    disabled = false,
    showLabelWhenHasValue = true,
  } = props;

  // for border color on focus state
  const [isFocus, setIsFocus] = useState(false);
  // for border color on hover state
  const [isHover, setIsHover] = useState(false);

  // merged country currency options and recent transaction country currency
  const countriesWithRecent = useMemo(() => {
    const recentCountriesWithIsRecentLabel = recentCountries?.map(
      (country) => ({
        ...country,
        isRecent: true,
      }),
    );

    const mergedCountries = [
      ...(recentCountriesWithIsRecentLabel ?? []),
      ...(options ?? []),
    ] as IRawCountryWithRecent[];

    return (
      mergedCountries?.map((option): ICountryWithRecent => {
        return {
          flag: option.flag,
          title: option.title,
          country: option.country,
          currencies: option.currencies,
          searchText: option.searchText,
          slug: option.slug || option.urlSlug,
          isRecent: Boolean(option?.isRecent),
        };
      }) || []
    );
  }, [options, recentCountries]);

  return (
    <ChooseCountryCurrencyContext.Provider
      value={{
        ...props,
        isFocus,
        isHover,
        bordered,
        countriesWithRecent,
        showLabelWhenHasValue,
        setIsFocus,
        setIsHover,
      }}
    >
      <Flex
        column
        fullWidth={false}
        className={cx(className, 'choose-country-currency-wrapper')}
      >
        <ChooseCountryCurrencyContentWrapper>
          <ChooseCountryCurrencyContent />
        </ChooseCountryCurrencyContentWrapper>

        {!!error && !disabled && <ErrorMessage>{error}</ErrorMessage>}
      </Flex>
    </ChooseCountryCurrencyContext.Provider>
  );
}
