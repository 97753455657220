import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

import { Card, Flex, ListMenu } from '.';
import { formatToIDRCurrency } from '../../common/helper';
import { useTranslation } from '../../hooks';
import { Lightning } from '../shapes';
import DiscountFeeStatus from './DiscountFeeStatus';
import { ListMenuProps } from './ListMenu';
import Status from './Status';

export interface IServiceCardProps extends Omit<ListMenuProps, 'type'> {
  fee: number;
  note?: string;
  withHighlight?: boolean;
  discountValue?: number | null;
  type?: ListMenuProps['type'];
  highlightIcon?: ReactNode;
  highlightTitle?: string;
  isFirstTransactionFree?: boolean;
}

export default function ServiceCard(props: IServiceCardProps) {
  const { t } = useTranslation();

  const {
    fee,
    highlightIcon = <Lightning />,
    highlightTitle = t('instant_speed'),
    withHighlight = false,
    note,
    className,
    discountValue,
    type = 'empty',
    isFirstTransactionFree,
    ...resProps
  } = props;

  function renderPill() {
    const _fee = formatToIDRCurrency(fee);
    const discountedFee = discountValue ? fee - Number(discountValue) : fee;
    const formattedDiscountedFee = `${t('fee')} ${formatToIDRCurrency(
      discountedFee,
    )}`;

    return (
      <Status
        color="blue"
        message={
          <>
            {formattedDiscountedFee}
            {discountValue && <s className="secondary-text xs-text">{_fee}</s>}
          </>
        }
      />
    );
  }

  return (
    <Card column variant="outline" p={0} className={cx(styled.root, className)}>
      {withHighlight && (
        <div className="highlight">
          <div className="icon-wrapper">{highlightIcon}</div>
          <div className="service-name xs-text">{highlightTitle}</div>
        </div>
      )}
      <Card p={0} className="content">
        <ListMenu
          pill={renderPill()}
          isCenterVerticalPill
          type={type}
          titleClassName="bold-text"
          descriptionClassName="sm-text"
          {...resProps}
        />

        {isFirstTransactionFree && (
          <Flex>
            <DiscountFeeStatus className="discount-fee-status" />
          </Flex>
        )}

        {note && (
          <div className="note xs-text">
            <span>{note}</span>
          </div>
        )}
      </Card>
      <div className="background" />
    </Card>
  );
}

const styled = {
  root: css`
    overflow: hidden;
    width: 100%;
    position: relative;
    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: var(--blue-500);
    }
    > .highlight {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      padding: 0.5rem 1rem;
      color: var(--neutral-0);
      z-index: 1;
      > .icon-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
    > .content {
      position: relative;
      display: flex;
      flex-direction: column;
      background: var(--neutral-0);
      z-index: 9;
      width: 100%;
      border-radius: unset;
      .note {
        border-bottom-left-radius: 12.9px;
        border-bottom-right-radius: 12.9px;
        border-top: 1px solid var(--neutral-200);
        background: var(--neutral-100);
        padding: 0.5rem;
        text-align: center;
        color: var(--text-secondary);
      }

      .discount-fee-status {
        margin: 0 1rem 1rem 1rem;
        flex: 1;
      }
    }
  `,
};
