import { isValidJson } from './helper';

export const OPENED_PAGE_STORAGE_KEY = 'opened-page';

export const MOENGAGE_USER_ID_STORAGE_KEY = 'moengage_user_id';

export const S2S_META_DATA_KEY = 'S2S_META_DATA_KEY';

export function getLocalStorageValue<T>(key: string): T {
  const localStorage = window.localStorage.getItem(key) || '';
  return isValidJson(localStorage) ? JSON.parse(localStorage) : localStorage;
}
export function setLocalStorageValue<T>(key: string, value: T) {
  const stringifyValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifyValue);
}
