import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useDomesticFlowStore } from './use-domestic-flow-store';

const WHITELIST_PATH = [
  '/quote',
  '/voucher',
  '/recipient',
  '/confirm-transaction-details',
];

/**
 * Hook for reset the domestic store value when the path not whitelisted
 */
export default function useDomesticResetValues() {
  const router = useRouter();
  const path = router.asPath;

  const resetStore = useDomesticFlowStore((s) => s.resetStore);

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    // Check if the current path is not in the whitelist
    const isWhitelisted = WHITELIST_PATH.some((whitelistedPath) =>
      path.includes(whitelistedPath),
    );

    if (!isWhitelisted) {
      resetStore();
    }
  }, [path, resetStore, router.isReady]);
}
