import { notification as notificationAntd } from 'antd';
import { ArgsProps } from 'antd/es/notification/interface';
import dynamic from 'next/dynamic';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import usePrevious from './use-previous';
import useTranslation from './use-translation';
import {
  isNativeApp,
  sendNativeMessage,
} from '../common/native-web-view-bridge';
import type { NotesStatus } from '../components/elements';
import { TOPBAR_HEIGHT } from '../components/layouts/navigation/top/TopBar';
import { NativeWebViewBridgeEventName } from '../typings/native-web-view-bridge.model';

const Alert = dynamic(() =>
  import('../components/elements').then((m) => m.Alert),
);
const Notes = dynamic(() =>
  import('../components/elements').then((m) => m.Notes),
);

interface Props {
  children: ReactNode;
}

interface INotificationState extends Omit<ArgsProps, 'type' | 'message'> {
  type: NotesStatus;
  wrapperType?: 'alert' | 'notes';
  showIcon?: boolean;
  /**
   * @description in second
   *
   * @param {number} [duration] 5
   */
  duration?: number;
  /**
   * @param {boolean} [closeable] boolean
   */
  closeable?: boolean;
  message?: string;
  parseHTML?: boolean;
}

interface IErrorHolder {
  isAppError?: boolean;
  message?: string;
  statusCode?: string | number;
  isOnline?: boolean;
}
interface ProviderContext {
  addNotification: (state: INotificationState) => void;
  error: {
    errorHolder: IErrorHolder;
    setErrorHolder: Dispatch<SetStateAction<IErrorHolder>>;
  };
}

/**
 * @workaround : Need to research more about making the wrapper position in the middle
 * of the layout component not the screen.
 */

const NotificationContext = createContext<ProviderContext>({
  addNotification: () => {},
  error: { errorHolder: {}, setErrorHolder: () => {} },
});

const NotificationProvider = ({ children }: Props) => {
  const { t } = useTranslation();
  const { useNotification, destroy } = notificationAntd;

  const [api, contextHolder] = useNotification({
    stack: false,
    top: TOPBAR_HEIGHT + 16,
  });
  const [errorHolder, setErrorHolder] = useState<IErrorHolder>({
    isAppError: false,
    isOnline: true,
  });
  const prevOnline = usePrevious(errorHolder.isOnline);

  function addStack({
    key = `${new Date(Date.now()).getMilliseconds()}`,
    showIcon = false,
    duration = 5,
    type,
    message,
    description,
    onClick,
    closeable = true,
    wrapperType,
    parseHTML = true,
    placement = 'top',
    ...resProp
  }: INotificationState) {
    if (isNativeApp() && typeof message === 'string') {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.ALERT,
        data: { message, variant: type },
      });
    } else {
      api.open({
        key,
        message:
          (message && description) || wrapperType === 'notes' ? (
            <Notes
              showIcon={showIcon}
              status={type}
              title={message}
              message={description}
              onClose={() => destroy(key)}
              onClick={onClick}
              closable={closeable}
            />
          ) : (
            (!description || wrapperType === 'alert') && (
              <Alert
                showIcon={showIcon}
                status={type}
                message={message}
                onClose={() => destroy(key)}
                onClick={onClick}
                closable={closeable}
                parseHTML={parseHTML}
              />
            )
          ),
        duration,
        placement,
        ...resProp,
      });
    }
  }

  useEffect(() => {
    window.addEventListener('online', () => {
      setErrorHolder((prev) => ({ ...prev, isOnline: navigator.onLine }));
    });
    window.addEventListener('offline', () => {
      setErrorHolder((prev) => ({ ...prev, isOnline: navigator.onLine }));
    });
  }, []);

  useEffect(() => {
    if (!errorHolder.statusCode) {
      if (!prevOnline && prevOnline !== undefined) {
        if (errorHolder.isOnline) {
          addStack({
            message: t('error:online.desc'),
            type: 'success',
          });
        }
      } else {
        if (!errorHolder.isOnline) {
          addStack({
            message: t('error:disconnect.desc'),
            type: 'danger',
          });
        }
      }
    }
  }, [errorHolder.isOnline, prevOnline, t]);

  return (
    <NotificationContext.Provider
      value={{
        addNotification: addStack,
        error: { errorHolder, setErrorHolder },
      }}
    >
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  return useContext(NotificationContext);
};

export { NotificationProvider, useNotification };
