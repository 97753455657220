import { cx, css } from '@emotion/css';
import { CSSProperties, useEffect, useState } from 'react';

import { callAllFunctions } from '../../../common/helper';
import { color } from '../../../styles/color';
import { AnimatedWrapper } from '../animated-wrapper';

export interface IIndicatorProps {
  /**
   * @param {number} [size] 5;
   */
  max?: number;
  /**
   * @param {'small' | 'default'} [size] default;
   */
  size?: 'small' | 'default';
  style?: CSSProperties;
  indicatorItemStyle?: CSSProperties;
  /**
   * This will disable user to move the page from indicator
   */
  disableIndicator?: boolean;
  /**
   * current start from 0
   * @param {number} [current] 0;
   */
  current?: number;
  onClick?: (index: number) => void;
  className?: string;
  indicatorItemClassName?: string;
}

const styled = {
  indicatorWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  default: css`
    width: 8px;
    height: 8px;
  `,
  small: css`
    width: 6px;
    height: 6px;
  `,
  indicatorItem: (disabled: boolean) => css`
    cursor: ${disabled ? 'default' : 'pointer'};
    clip-path: circle();
    margin: 0 4px;
    &::first-child {
      margin-left: 0;
    }
    &::last-child {
      margin-right: 0;
    }
  `,
};

const indicatorWrapperMinHeight = (size: 'small' | 'default' = 'default') => {
  const itemSize = size === 'default' ? 8 : 6;
  return css`
    min-height: ${itemSize + 2}px;
  `;
};

const variants = (size: 'small' | 'default' = 'default') => {
  const itemSize = size === 'default' ? 8 : 6;
  return {
    active: {
      backgroundColor: color.blue500,
      width: `${itemSize + 2}px`,
      height: `${itemSize + 4}px`,
    },

    default: {
      backgroundColor: color.neutral200,
      width: `${itemSize}px`,
      height: `${itemSize + 2}px`,
    },
  };
};

function Indicator(props: IIndicatorProps) {
  const {
    current = 0,
    onClick,
    size = 'default',
    max = 5,
    style,
    indicatorItemStyle,
    indicatorItemClassName,
    className,
    disableIndicator = false,
  } = props;

  const [active, setActive] = useState(current);

  const indicatorWrapperClass = cx(
    indicatorWrapperMinHeight(size),
    styled.indicatorWrapper,
    className,
  );
  const indicatorItemClass = cx(
    styled.indicatorItem(disableIndicator),
    indicatorItemClassName,
  );

  function range(size: number, startAt: number = 0) {
    return [...Array(size).keys()].map((i) => i + startAt);
  }

  function handleClick(index: number) {
    if (!disableIndicator) {
      callAllFunctions(setActive, onClick)(index);
    }
  }

  useEffect(() => {
    setActive(current);
  }, [current]);

  return (
    <div style={style} className={indicatorWrapperClass}>
      {range(max).map((item) => (
        <AnimatedWrapper
          htmlTag="div"
          key={item}
          style={indicatorItemStyle}
          initial="default"
          animate={active === item ? 'active' : 'default'}
          onClick={() => handleClick(item)}
          variants={variants(size)}
          className={indicatorItemClass}
        />
      ))}
    </div>
  );
}

export { Indicator };
export default Indicator;
