var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"dDfUcZWLiqOe5vyZcWFMc"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import packageJSON from './package.json';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const EXCLUDED_EXCEPTION_TYPES = [
  'NotFoundError',
  'TimeoutError',
  'NotReadableError',
  'UnhandledRejection',
];

const IGNORE_ERRORS = [
  /^Error: Java /,
  /^Error: UnknownError: /,
  /^Error: AbortError: /,
  /^Error: Failed to load script:/,
  /^Error: NotAllowedError: /,
  /^Error: Invariant: attempted to hard navigate to the same URL/,
  /^ReferenceError: Can't find variable:/,
  /^SecurityError: Failed to read a named property 'document' from 'Window'/,
  /^SecurityError: Blocked a frame with origin "https:\/\/topremit\.com" from accessing a cross-origin frame/,
  /^TypeError: Moengage/,
  '<unknown>',
  'HTTPError: 403',
  'TypeError: Load failed',
  'TypeError: Failed to fetch',
  "TypeError: 'getPrototypeOf' on proxy: trap returned neither object nor null",
  'TypeError: Illegal invocation',
  "TypeError: 'set' on proxy: trap returned falsish for property '_events'",
  "TypeError: Cannot destructure property 'value' of 'n.target' as it is undefined.",
  'TypeError: u.add_unique_user_id is not a function',
  "TypeError: undefined is not an object (evaluating 'e.getInitialProps')",
  "TypeError: Cannot read properties of undefined (reading 'getInitialProps')",
  "TypeError: null is not an object (evaluating 'this.displayManager.campaignRenderMap')",
  "TypeError: null is not an object (evaluating 'Be.get(r.WEB_SETTINGS).configs')",
  "TypeError: undefined is not an object (evaluating 'window.webkit.messageHandlers')",
  "TypeError: undefined is not a constructor (evaluating 'new Intl.PluralRules(E?void 0:A)')",
  'ErrorPage',
  'ErrorPage.getInitialProps',
  "SyntaxError: Unexpected token '='. Expected an opening '(' before a method's parameter list.",
  "SyntaxError: Failed to execute 'measure' on 'Performance': The mark 'beforeRender' does not exist.",
  'Error: Cancel rendering route',
  'Error: InvalidStateError: Object store cannot be found in the backing store',
  'Error: jb',
  'Error: Route did not complete loading: /_error',
  'Error: Worker was terminated',
  // 'TypeError: Cannot convert undefined or null to object',
  // 'SyntaxError: "[object Object]" is not valid JSON',
  // 'Error: getUserMedia is not implemented in this browser',
  // "TypeError: Cannot read property 'document' of null",
  // "TypeError: Cannot read properties of null (reading 'click')",
  // "TypeError: Cannot read properties of null (reading 'postMessage')",
  // "TypeError: Cannot read properties of null (reading 'content')",
  // "TypeError: Cannot read properties of null (reading 'document')",
  // /^TypeError: Cannot read properties of (undefined|null) \(reading/,
  // /^TypeError: .* is not an? function$/,
  // /^TypeError: (undefined|null) is not an? /,
  // /^TypeError: Cannot read properties of undefined \(reading/,
  // /^TypeError: Cannot read properties of null \(reading/,
  // /^TypeError: .* is not a function$/,
  // /^TypeError: .* is not an function$/,
  // /^TypeError: navigator.mediaDevices.enumerateDevices is not a function/,
  // /^TypeError: window.lintrk is not a function/,
  // /^TypeError: undefined is not a/,
  // /^TypeError: null is not an object/,
  // /^TypeError: undefined is not an/,
  // 'Error: NotAllowedError: Permission denied',
  // 'Error: NotAllowedError: Write permission denied.',
  // 'Error: Java object is gone',
  // 'Error: UnknownError: An internal error was encountered in the Indexed Database server',
  // 'Error: UnknownError: Connection to Indexed Database server lost. Refresh the page to try again',
  // Error: UnknownError: Connection is closing.,
  // 'Error: UnknownError: Database deleted by request of the user',
  // 'Error: AbortError: The operation was aborted.',
  // 'Error: Java object is gone',
  // 'Error: UnknownError: Attempt to delete range from database without an in-progress transaction',
  // 'Error: Java bridge method invocation error',
  // 'Error: UnknownError: Attempt to open a cursor in database without an in-progress transaction',
  // "ReferenceError: Can't find variable: IntersectionObserver",
  // "ReferenceError: Can't find variable: Notification",
];

const EXCLUDED_EXCEPTION_MESSAGE_PATTERN = RegExp(
  `(?:${EXCLUDED_EXCEPTION_TYPES.join('|')}):`,
);

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://7100343e5d8f453d9af8b4625da59add@o318945.ingest.sentry.io/5175283',
  ignoreErrors: IGNORE_ERRORS,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  enabled: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  beforeSend(event) {
    const exception = event?.exception?.values?.[0];

    if (
      EXCLUDED_EXCEPTION_TYPES.includes(exception?.type) ||
      EXCLUDED_EXCEPTION_MESSAGE_PATTERN.test(exception?.value)
    ) {
      return null;
    }

    if (/moengage(?!.+null is not an object)/.test(exception?.value)) {
      return null;
    }

    // Instagram error that Meta isn't fixing but is not impacting users
    // See https://developers.facebook.com/community/threads/320013549791141/
    if (
      exception?.type === 'ReferenceError' &&
      exception?.value?.includes('_AutofillCallbackHandler')
    ) {
      return null;
    }

    // Otherwise, send the event
    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

Sentry.setTag('app_name', packageJSON.name);
Sentry.setTag('app_version', packageJSON.version);
