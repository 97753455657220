import { NextRouter } from 'next/router';

import {
  NativeWebViewBridgeEvent,
  NativeWebViewBridgeEventName,
  NativeWebviewTrackConfig,
} from '../typings/native-web-view-bridge.model';

export function isNativeApp(router?: NextRouter) {
  const isMobileApp = Boolean(router?.query.isMobileApp);

  /**
   * type safe checking for Next.js, in case executed on server
   */
  if (typeof window === 'undefined') {
    return isMobileApp;
  }
  return !!window?.isNative;
}

export function sendNativeMessage<T extends NativeWebViewBridgeEventName>(
  payload: NativeWebViewBridgeEvent<T>,
) {
  if (isNativeApp() && window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify(payload));
  }
}

export function sendNativeAnalytic(config: NativeWebviewTrackConfig) {
  if (isNativeApp() && window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        name: NativeWebViewBridgeEventName.ANALYTICS,
        data: config,
      }),
    );
  }
}

export function sendNativeAnalyticQueue(config: {
  params: {
    transactionId: string;
    isDomestic: boolean;
    isBatch: boolean;
  };
}) {
  if (isNativeApp() && window.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        name: NativeWebViewBridgeEventName.ANALYTIC_QUEUE_PURCHASE,
        data: config,
      }),
    );
  }
}
