import { AffiliateWithdrawInfo } from '@api-hooks/affiliate';
import { usePrevious } from '@topremit/shared-web/hooks';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

export enum AffiliateWithdrawStep {
  ADD = 'ADD',
  EDIT = 'EDIT',
  PAYERS = 'PAYERS',
}

interface IAffiliateWithdrawContext {
  step: AffiliateWithdrawStep;
  prevStep: AffiliateWithdrawStep;
  payerId: number | null;
  affiliateWithdrawInfo: AffiliateWithdrawInfo | null;
  showMaintenancesNotes: boolean;
  setStep: Dispatch<SetStateAction<AffiliateWithdrawStep>>;
  setPayerId: Dispatch<SetStateAction<number | null>>;
  setAffiliateWithdrawInfo: Dispatch<
    SetStateAction<AffiliateWithdrawInfo | null>
  >;
  setShowMaintenancesNotes: Dispatch<SetStateAction<boolean>>;
}

const AffiliateWithdrawContext = createContext({});

export function AffiliateWithdrawProvider({ children }) {
  const [step, setStep] = useState<AffiliateWithdrawStep>(
    AffiliateWithdrawStep.ADD,
  );
  const prevStep = usePrevious(step);
  const [payerId, setPayerId] = useState<number | null>(null);
  const [showMaintenancesNotes, setShowMaintenancesNotes] =
    useState<boolean>(false);
  const [affiliateWithdrawInfo, setAffiliateWithdrawInfo] =
    useState<AffiliateWithdrawInfo | null>(null);

  return (
    <AffiliateWithdrawContext.Provider
      value={{
        step,
        prevStep,
        payerId,
        affiliateWithdrawInfo,
        showMaintenancesNotes,
        setStep,
        setPayerId,
        setAffiliateWithdrawInfo,
        setShowMaintenancesNotes,
      }}
    >
      {children}
    </AffiliateWithdrawContext.Provider>
  );
}

export function useAffiliateWithdraw() {
  return useContext(AffiliateWithdrawContext) as IAffiliateWithdrawContext;
}
