import { css } from '@emotion/react';

export default css`
  .ant-alert-close-icon {
    margin: 0;
  }

  .ant-alert-icon {
    margin-right: 0.625rem;
  }

  .ant-alert-close-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 0.875rem;
    > .ant-alert-close-text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-alert-with-description .ant-alert-message {
    color: var(--text-primary);
  }
`;
