import { css, cx } from '@emotion/css';

import { ISectionDetailsWrapperProps } from './types';

const styled = {
  root: css`
    background-color: var(--neutral-100) !important;
    height: fit-content !important;
    .header {
      display: flex;
      align-items: center;
      margin: 0.5rem 1rem;
      > .icon-wrapper {
        display: flex;
        margin-right: 0.5rem;
      }
    }
    > .content {
      border-radius: 0.875rem;
      background-color: var(--neutral-0);
      padding: 1rem;
      outline: 0.0625rem solid var(--neutral-200);
    }
  `,
};

export default function SectionDetailsWrapper({
  className,
  title,
  children,
  icon,
}: ISectionDetailsWrapperProps) {
  return (
    <div
      className={cx(
        styled.root,
        className,
        'card card-secondary section-details-wrapper',
      )}
    >
      <div className="header">
        {icon && <span className="icon-wrapper">{icon}</span>}
        <span className="bold-text">{title}</span>
      </div>
      <div className="content">{children}</div>
    </div>
  );
}
