import { isNativeApp } from '@global-common/native-web-view-bridge';
import {
  DevicePermissionsNativeData,
  NativeMessageNameEnum,
  ReceiveNativeMessage,
} from '@global-typings/native-web-view-bridge.model';
import { useMobilePermissionStore } from '@stores';
import React, { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
}

export default function MobilePermission(props: Props) {
  const { children } = props;

  const { setPermission } = useMobilePermissionStore((s) => s);

  useEffect(
    function receivedNativePermission() {
      if (!isNativeApp()) return;

      function receiveNativeMessage(message: ReceiveNativeMessage) {
        if (message?.data) {
          const messagePayload = JSON.parse(message?.data as unknown as string);

          if (
            messagePayload.name === NativeMessageNameEnum.DEVICE_PERMISSIONS
          ) {
            const data = messagePayload.data as DevicePermissionsNativeData;
            Object.keys(data)?.forEach((key) => {
              const value = data[key];
              setPermission(key as keyof DevicePermissionsNativeData, value);
            });
          }
        }
      }

      window.addEventListener('message', receiveNativeMessage, true);
      return () => {
        window.removeEventListener('message', receiveNativeMessage, true);
      };
    },
    [setPermission],
  );

  return <>{children}</>;
}
