import { BlacklistReason, KycStatus } from '@api-hooks/common';
import { BankAccountMatchingStatus } from '@topremit/shared-web/api-hooks/common';

import { KycAbadStatus, KycStep, Step } from './types';

// Note: to handle mixed status between Kyc and Abad
export function getStatusKycAbad(
  kycStatus: KycStatus,
  abadStatus: BankAccountMatchingStatus,
  blacklistReason?: BlacklistReason,
  rejectedReason?: string,
) {
  const emptyAbadStatus = abadStatus === null;

  // Check for BLACKLIST
  if (rejectedReason && rejectedReason.includes('BLACKLIST')) {
    if (kycStatus === KycStatus.BLACKLIST) {
      if (
        blacklistReason === BlacklistReason.DATA_BLACKLIST_SPAM ||
        blacklistReason === BlacklistReason.DATA_BLACKLIST_PROHIBITED
      ) {
        return KycAbadStatus.BLACKLIST_SPAM_PROHIBITED;
      }
      if (blacklistReason === BlacklistReason.DATA_BLACKLIST_OTHER) {
        return KycAbadStatus.BLACKLIST_OTHER;
      }
    }
    if (blacklistReason && kycStatus === KycStatus.DATA_REJECTED) {
      return KycAbadStatus.BLACKLIST_REMOVED;
    }
  }

  // Check for REJECTED_COMPLIANCE_KYC
  if (kycStatus === KycStatus.COMPLIANCE_REJECTED) {
    return KycAbadStatus.REJECTED_COMPLIANCE_KYC;
  }

  // Check for UNVERIF_KYC_ABAD & UNVERIF_ABAD
  if (emptyAbadStatus && kycStatus === KycStatus.PENDING) {
    return KycAbadStatus.UNVERIF_KYC_ABAD;
  }
  if (emptyAbadStatus && kycStatus === KycStatus.COMPLIANCE_APPROVED) {
    return KycAbadStatus.UNVERIF_ABAD;
  }

  // Check for REJECTED_KYC_EMPTY_ABAD
  if (
    emptyAbadStatus &&
    [KycStatus.DATA_REJECTED, KycStatus.DATA_EXPIRED].includes(kycStatus)
  ) {
    return KycAbadStatus.REJECTED_KYC_EMPTY_ABAD;
  }

  // Check for VERIFYING_KYC_ABAD
  if (
    abadStatus === BankAccountMatchingStatus.WATCHED ||
    kycStatus === KycStatus.DATA_WATCHED ||
    (emptyAbadStatus && kycStatus === KycStatus.SUBMITTED) ||
    ([
      BankAccountMatchingStatus.HOLD,
      BankAccountMatchingStatus.APPROVED,
      BankAccountMatchingStatus.REJECTED,
    ].includes(abadStatus) &&
      [
        KycStatus.SUBMITTED,
        KycStatus.DATA_APPROVED,
        KycStatus.DATA_SUSPICIOUS,
        KycStatus.COMPLIANCE_WATCHED,
      ].includes(kycStatus))
  ) {
    return KycAbadStatus.VERIFYING_KYC_ABAD;
  }

  // Check for VERIFYING_ABAD
  if (
    abadStatus === BankAccountMatchingStatus.HOLD &&
    kycStatus === KycStatus.COMPLIANCE_APPROVED
  ) {
    return KycAbadStatus.VERIFYING_ABAD;
  }

  // Check for REJECTED_KYC
  if (
    [
      BankAccountMatchingStatus.HOLD,
      BankAccountMatchingStatus.APPROVED,
      BankAccountMatchingStatus.REJECTED,
    ].includes(abadStatus) &&
    [KycStatus.DATA_REJECTED, KycStatus.DATA_EXPIRED].includes(kycStatus)
  ) {
    return KycAbadStatus.REJECTED_KYC;
  }

  //Check for Abad status REJECTED_ABAD
  if (
    abadStatus === BankAccountMatchingStatus.REJECTED &&
    kycStatus === KycStatus.COMPLIANCE_APPROVED
  ) {
    return KycAbadStatus.REJECTED_ABAD;
  }

  //Check for APPROVE_KYC_ABAD
  if (
    abadStatus === BankAccountMatchingStatus.APPROVED &&
    kycStatus === KycStatus.COMPLIANCE_APPROVED
  ) {
    return KycAbadStatus.APPROVE_KYC_ABAD;
  }
  return null;
}
// Note: to handled dynamic steps from status Kyc Abad and Rejected Error from Data Me
export function getKycAbadSteps({
  rejectedError,
  statusKycAbad,
}: {
  rejectedError?: string;
  statusKycAbad?: KycAbadStatus | null;
}): Step[] {
  const steps: Step[] = [];
  const subDataSteps: string[] = [];

  if (!rejectedError || !statusKycAbad) {
    return [];
  }

  switch (statusKycAbad) {
    case KycAbadStatus.UNVERIF_KYC_ABAD:
    case KycAbadStatus.BLACKLIST_REMOVED:
      steps.push(
        { step: KycStep.IDENTITY },
        { step: KycStep.DATA, subStep: [KycStep.DATA, KycStep.ADDRESS_DATA] },
        { step: KycStep.SELFIE },
        { step: KycStep.ABAD },
      );
      break;

    case KycAbadStatus.UNVERIF_ABAD:
      steps.push({ step: KycStep.ABAD });
      break;

    case KycAbadStatus.REJECTED_KYC:
    case KycAbadStatus.REJECTED_COMPLIANCE_KYC:
    case KycAbadStatus.REJECTED_ABAD: {
      // Handle IDENTITY
      if (rejectedError.includes('IDENTITY')) {
        steps.push({ step: KycStep.IDENTITY });
      }

      // Handle DATA and ADDRESS_DATA
      if (rejectedError.includes('DATA')) {
        subDataSteps.push(KycStep.DATA);
      }
      if (rejectedError.includes('ADDRESS_DATA')) {
        subDataSteps.push(KycStep.ADDRESS_DATA);
      }
      if (subDataSteps.length > 0) {
        steps.push({ step: KycStep.DATA, subStep: subDataSteps });
      }

      // Handle SELFIE
      if (rejectedError.includes('SELFIE')) {
        steps.push({ step: KycStep.SELFIE });
      }

      // HANDLE ABAD
      if (rejectedError.includes('ABAD')) {
        steps.push({ step: KycStep.ABAD });
      }
      break;
    }

    case KycAbadStatus.REJECTED_KYC_EMPTY_ABAD: {
      // Handle IDENTITY
      if (rejectedError.includes('IDENTITY')) {
        steps.push({ step: KycStep.IDENTITY });
      }

      // Handle DATA and ADDRESS_DATA
      if (rejectedError.includes('DATA')) {
        subDataSteps.push(KycStep.DATA);
      }
      if (rejectedError.includes('ADDRESS_DATA')) {
        subDataSteps.push(KycStep.ADDRESS_DATA);
      }
      if (subDataSteps.length > 0) {
        steps.push({ step: KycStep.DATA, subStep: subDataSteps });
      }

      // Handle SELFIE
      if (rejectedError.includes('SELFIE')) {
        steps.push({ step: KycStep.SELFIE });
      }

      // Handle ABAD , this must push to STEP because status REJECTED_KYC_EMPTY_ABAD
      steps.push({ step: KycStep.ABAD });
      break;
    }
  }

  return steps;
}
// Note: to handled auto redirect to
export function getPathKyc(
  statusKycAbad: KycAbadStatus | null,
  isMobileSize?: boolean,
) {
  switch (statusKycAbad) {
    case KycAbadStatus.UNVERIF_KYC_ABAD:
    case KycAbadStatus.BLACKLIST_REMOVED: {
      if (isMobileSize) {
        return { pathname: '/kyc/disclaimer' };
      }
      return { pathname: '/kyc/suggestion' };
    }
    case KycAbadStatus.UNVERIF_ABAD:
    case KycAbadStatus.REJECTED_ABAD: {
      return {
        pathname: '/kyc',
        query: { step: 1 },
      };
    }
    case KycAbadStatus.REJECTED_KYC:
    case KycAbadStatus.REJECTED_KYC_EMPTY_ABAD: {
      return { pathname: '/kyc/rejected-reason' };
    }
    default: {
      return { pathname: '/' };
    }
  }
}
