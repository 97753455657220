import { css } from '@emotion/css';
import { TransactionType } from '@topremit/shared-web/api-hooks/transaction';
import { isStringEmpty } from '@topremit/shared-web/common/helper';
import { fontWeight } from '@topremit/shared-web/common/size';
import { Button } from '@topremit/shared-web/components/elements';
import {
  useDebounce,
  useSidebar,
  useTranslation,
} from '@topremit/shared-web/hooks';
import {
  animate,
  AnimatePresence,
  domAnimation,
  LazyMotion,
  m,
  useMotionValue,
  ValueAnimationTransition,
} from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import AnimatedSidebarIcon from './AnimatedSidebarIcon';
import AnimatedSidebarText from './AnimatedSidebarText';

const sendBtnAnimateConfig: ValueAnimationTransition<number> = {
  type: 'tween',
};

export default function AnimatedSidebarSendSquareButton() {
  const { t } = useTranslation();
  const [{ isExpand }] = useSidebar();
  const buttonWidth = useMotionValue(0);
  const [buttonShape, setButtonShape] = useState<string>();
  const btnRef = useRef(null);
  const router = useRouter();

  const { tab } = router.query;

  const isOnQuoteRemitPage =
    router.pathname === '/quote' &&
    (tab === TransactionType.REMIT || isStringEmpty(tab));

  useDebounce(
    () => setButtonShape(isExpand ? 'round' : 'circle'),
    [isExpand],
    isExpand ? 0 : 200, // depands on icon transition duration
  );

  useEffect(() => {
    if (isExpand) {
      animate(buttonWidth, 215 - 43 - 32, sendBtnAnimateConfig);
    } else {
      animate(buttonWidth, 0, sendBtnAnimateConfig);
    }
  }, [isExpand]);

  return (
    <Link
      href="/quote"
      className={styled.sendButtonSquare}
      onClick={(e) => isOnQuoteRemitPage && e.preventDefault()}
    >
      <Button
        size="medium"
        ref={btnRef}
        shape={buttonShape === 'circle' ? 'round' : undefined}
        leftIcon={
          !isExpand ? (
            <AnimatePresence>
              {buttonShape === 'circle' ? <AnimatedSidebarIcon /> : null}
            </AnimatePresence>
          ) : null
        }
      >
        {buttonShape === 'round' ? (
          <LazyMotion features={domAnimation}>
            <m.div
              className={styled.sendButtonSquareInnerWrapper}
              style={{ width: buttonWidth }}
            >
              <AnimatePresence>
                {isExpand ? <AnimatedSidebarIcon /> : null}
              </AnimatePresence>
              <AnimatedSidebarText className={styled.text}>
                {t('send_money')}
              </AnimatedSidebarText>
            </m.div>
          </LazyMotion>
        ) : null}
      </Button>
    </Link>
  );
}

const styled = {
  text: css`
    font-size: var(--sm-font-size);
    font-weight: ${fontWeight.bold};
  `,
  sendButtonSquare: css`
    width: 100%;
    button {
      display: flex;
      flex-grow: 1;
      min-height: 48px;
    }
    margin: 8px 16px;
  `,
  sendButtonSquareInnerWrapper: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
};
