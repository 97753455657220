import { css, cx } from '@emotion/css';
import Text from '@global-elements-utils/Text';
import { CommandEmpty } from '@global-elements-utils/command';
import BolyNotFound from '@global-elements-utils/supports/BolyNotFound';
import { useTranslation } from '@global-hooks';
import * as React from 'react';

interface SelectEmptyProps {
  image?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
}

export function SelectEmpty({ title, description, image }: SelectEmptyProps) {
  const { t } = useTranslation();

  return (
    <CommandEmpty className={styled.root}>
      {image ? image : <BolyNotFound width={180} height={180} />}
      <Text as="span" maxSize="p" minSize="p" className={styled.title}>
        {title || t('common:not_found_options')}
      </Text>
      <Text
        as="span"
        maxSize="sm"
        minSize="sm"
        className={cx('secondary-text', styled.description)}
      >
        {description || t('common:try_other_keyword')}
      </Text>
    </CommandEmpty>
  );
}

const styled = {
  root: css`
    padding: 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
  `,
  image: css``,
  title: css`
    color: var(--text-primary);
    font-weight: var(--bold-font-weight);
  `,
  description: css`
    text-align: center;
    margin-bottom: 1.5rem;
  `,
};
