import { useGetAccountInfo } from '@api-hooks/common';
import { useMounted } from '@global-hooks';
import { useCurrentAccountStore } from '@stores';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import usePermission from './use-permission';

const BUSINESS_PROTECTED_ROUTES = [
  '/profile/company-profile',
  '/manage-member',
  '/client-onboarding',
  '/wallet',
  '/track-payment',
];

const PERSONAL_PROTECTED_ROUTES = [
  '/profile/personal-details',
  '/airtime',
  '/change-phone-number',
  '/home',
  '/kyc',
  '/rate-alert',
  '/refunds',
  '/tr-coins',
  '/verify/phone-number',
  '/voucher',
];

export default function useDashboardRedirect() {
  const router = useRouter();
  const mounted = useMounted();
  const { pathname, asPath } = router;
  const { removeCurrentAccount, currentAccount, setCurrentAccount } =
    useCurrentAccountStore((s) => s);
  const { isBusinessAccount, isPersonalAccount, isRemittanceAccount } =
    usePermission();

  const { data: accountInfo } = useGetAccountInfo({
    onError: (error) => {
      /**
       * the statusCode from HTTPError from Ky,
       * access directly from error.statusCode will result undefined.
       * The reason why is not sure, but this logic is expected.
       *
       * Case: when Owner deactive member, then if the member is still logged in,
       * BE will return error 423 (Locked) and FE should redirect to choose account page,
       * since the account is not exist.
       * Otherwise, the page is just showing blank white screen with a cute spinner.
       * */

      if (error.response?.status === 423) {
        removeCurrentAccount();
      }
    },
  });

  useEffect(
    function redirectUser() {
      if (!mounted) {
        return;
      }
      /**
       * ℹ️ accountFromQuery
       * sometimes currentAccount delayed to get data from storage that cause
       * user redirected to choose-account in webview
       **/
      const currentAccountId = localStorage.getItem('currentAccountId');

      const accountId = currentAccountId || currentAccount?.id;
      const allowedPathWithoutAccount = ['/choose-account', '/notification'];

      if (!accountId && !allowedPathWithoutAccount.includes(pathname)) {
        router.push({ pathname: '/choose-account', query: router.query });
      }
    },
    [currentAccount?.id, pathname, router, mounted],
  );

  useEffect(() => {
    // Note: this is for push notification , this will automatically change account id from the link
    const targetAccountId = router.query.account_id;
    const targetAccount = accountInfo?.data.accounts.find((account) => {
      return account.id === targetAccountId;
    });

    if (!targetAccount) {
      return;
    }

    setCurrentAccount(targetAccount);
    delete router.query.account_id;
    router.replace({
      pathname: router.pathname,
      query: router.query,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountInfo?.data.accounts, router.query.account_id, setCurrentAccount]);

  useEffect(() => {
    // Note: prevent to run when in Mobile app
    if (isNativeApp()) {
      return;
    }

    const cleanPathname = asPath.split('?')[0];

    // Note: prevent user specific type to access specific page
    if (
      isPersonalAccount &&
      BUSINESS_PROTECTED_ROUTES.includes(cleanPathname)
    ) {
      if (cleanPathname !== '/home') {
        router.replace('/home');
        return;
      }
    }
    if (
      isBusinessAccount &&
      (PERSONAL_PROTECTED_ROUTES.includes(cleanPathname) ||
        (isRemittanceAccount && cleanPathname === '/quote'))
    ) {
      if (cleanPathname !== '/profile') {
        router.replace('/profile');
      }
    }
  }, [
    asPath,
    isPersonalAccount,
    isBusinessAccount,
    router,
    isRemittanceAccount,
  ]);

  return {
    hasAccountInfo: router.isReady && accountInfo !== undefined,
  };
}
