import { SVGProps } from 'react';

import { color } from '../../styles/color';

const CreditCardOutline = (props: SVGProps<SVGSVGElement>) => {
  const { width = 24, height = 24, fill = color.neutral500 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.58337 16.1665C7.27373 16.1665 7.83337 15.6069 7.83337 14.9165C7.83337 14.2261 7.27373 13.6665 6.58337 13.6665C5.89302 13.6665 5.33337 14.2261 5.33337 14.9165C5.33337 15.6069 5.89302 16.1665 6.58337 16.1665Z"
        fill={fill}
      />
      <path
        d="M17.8333 4.5H6.16667C3.83333 4.5 2 6.33333 2 8.66667V15.3333C2 17.6667 3.83333 19.5 6.16667 19.5H17.8333C20.1667 19.5 22 17.6667 22 15.3333V8.66667C22 6.33333 20.1667 4.5 17.8333 4.5ZM6.16667 6.16667H17.8333C19.25 6.16667 20.3333 7.25 20.3333 8.66667H3.66667C3.66667 7.25 4.75 6.16667 6.16667 6.16667ZM17.8333 17.8333H6.16667C4.75 17.8333 3.66667 16.75 3.66667 15.3333V10.3333H20.3333V15.3333C20.3333 16.75 19.25 17.8333 17.8333 17.8333Z"
        fill={fill}
      />
    </svg>
  );
};
export default CreditCardOutline;
