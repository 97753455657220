import * as React from 'react';

/**
 * State
 *
 * @description
 * Represents the state of a component.
 *
 * @typedef {('default' | 'disabled' | 'active' | 'error')} State
 *
 * @property {'default'} default - The default state of the component.
 * @property {'disabled'} disabled - The state when the component is disabled and not interactive.
 * @property {'active'} active - The state when the component is active (open).
 * @property {'error'} error - The state when the component has encountered an error.
 */
export type State = 'default' | 'disabled' | 'active' | 'error';

export const SelectProvider = React.createContext<{
  items: React.ReactNode[];
  value: string;
  isOpen: boolean;
  fullWidth: boolean;
  label?: string;
  name?: string;
  error?: string;
  helperText?: string;
  state: State;
  renderValue?: (value: string) => React.ReactNode;
  onClose: () => void;
  onChange: (value: string) => void;
} | null>(null);

/**
 *  useSelect
 *
 * @description
 * `useSelect` is a custom hook that allows you to use the SelectProvider context
 * to get access to the select state and methods.
 */
export function useSelect() {
  const context = React.useContext(SelectProvider);
  if (!context) {
    throw new Error('useSelect must be used within a SelectProvider');
  }
  return context;
}
