import { css, cx } from '@emotion/css';
import { useDialogStore } from '@global-stores';
import { ModalProps } from 'antd';
import { ReactNode, useEffect, useRef } from 'react';
import { Angle, Close } from 'shared';

import useDialogBack from './hooks/useDialogBack';
import { ObjToCssString } from '../../common/helper';
import { screenSize } from '../../common/size';

const iconFill = 'rgb(130, 149, 181)';

export interface INewDialog extends ModalProps {
  body: ReactNode;
}

export interface IDialogProps {
  title?: string;
  className?: string;
  fullHeight?: boolean;
  disableEscKey?: boolean;

  body?: ReactNode;
  footer?: ReactNode;

  onBack?: (() => void) | boolean; //if TRUE then default ONLY pop dialog
  onClose?: (() => void) | false; //if TRUE then default ONLY close dialog

  onBackBrowser?: boolean;
}

const DIALOG_HEADER_HEIGHT = '2rem';

export default function Dialog() {
  const isOpenDialog = useDialogStore((state) => state.isOpen);
  const dialogProps = useDialogStore((state) => state.props);
  const isCloseable = useDialogStore((state) => state.isCloseable);
  const popDialog = useDialogStore((state) => state.pop);

  const contentRef = useRef<HTMLDivElement>(null);

  const {
    body,
    footer,
    title,
    onBack,
    onClose,
    className,
    fullHeight,
    onBackBrowser = true,
    disableEscKey,
  } = dialogProps || {};

  // const backable = Boolean(onBack) && dialogStack.length > 1;
  const backable = Boolean(onBack);
  const closeable = onClose !== false;

  const hasFooter = Boolean(footer);

  useDialogBack({
    onBackBrowser,
  });

  function _onBack() {
    if (!isCloseable) return;
    if (typeof onBack === 'undefined') {
      return;
    }

    if (typeof onBack === 'boolean') {
      popDialog();
      return;
    }

    onBack();
  }

  function _onClose() {
    if (!isCloseable) return;
    if (!onClose) {
      popDialog();
      return;
    }

    onClose();
  }

  function renderHeader() {
    return (
      <div className={cx(styled.header(backable), 'modal-header')}>
        {backable && (
          <button className="btn btn-back" onClick={_onBack}>
            <Angle direction="left" size="small" fill={iconFill} />
          </button>
        )}
        {title && <h1 className="title">{title}</h1>}
        {closeable && (
          <button className="btn btn-close" onClick={_onClose}>
            <Close fill={iconFill} />
          </button>
        )}
      </div>
    );
  }

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (
        e.key === 'Escape' &&
        isOpenDialog &&
        closeable &&
        isCloseable &&
        !disableEscKey
      ) {
        _onClose();
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpenDialog, closeable, isCloseable, disableEscKey]);

  useEffect(() => {
    if (isOpenDialog) {
      document.body?.setAttribute(
        'style',
        ObjToCssString({
          overflowY: 'hidden',
        }),
      );
      return () => {
        document.body?.setAttribute(
          'style',
          ObjToCssString({
            overflowY: 'auto',
          }),
        );
      };
    }
  }, [isOpenDialog]);

  if (!isOpenDialog) {
    return null;
  }

  return (
    <div>
      <div className={styled.overlay} />
      <div
        role="dialog"
        aria-modal
        className={cx(styled.wrapper(fullHeight), className)}
      >
        {renderHeader()}
        <div ref={contentRef} className={cx(styled.content, 'modal-body')}>
          {body}
        </div>
        {hasFooter && (
          <div className={cx(styled.footer, 'modal-footer')}>{footer}</div>
        )}
      </div>
    </div>
  );
}

const styled = {
  overlay: css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2000;
    position: fixed;
    background: rgba(0, 0, 0, 0.45);
  `,
  wrapper: (fullHeight?: boolean) => css`
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    width: 100%;
    z-index: 2000;
    position: fixed;
    height: fit-content;
    background: white;
    max-width: 25.875rem;
    border-radius: 0.875rem;
    transform: translate(-50%, -50%);
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
    padding: 1rem 0;
    height: ${fullHeight ? '75vh' : 'auto'};
    // increase to 85vh from previous value (75vh)
    // to give space to dialog header and other paddings
    max-height: 85vh;
    @media (max-width: ${screenSize.tabletMd}px) {
      bottom: 0;
      top: unset;
      left: unset;
      max-width: unset;
      transform: unset;
      border-bottom-left-radius: unset;
      border-bottom-right-radius: unset;
      animation: slideUp 0.3s ease-out;
    }
    @keyframes slideUp {
      from {
        transform: translateY(75vh);
      }
      to {
        transform: translateY(0);
      }
    }
  `,
  header: (backable: boolean) => css`
    display: flex;
    align-items: center;
    min-height: 1.25rem;
    height: ${DIALOG_HEADER_HEIGHT};

    padding: 0 1.25rem;
    margin-bottom: 0.5rem;
    > .btn {
      display: flex;
      cursor: pointer;
    }
    > .title {
      font-size: 1.125rem;
      font-weight: var(--bold-font-weight);
      margin-left: ${backable ? '0.5rem' : 'unset'};
    }
    > .btn-close {
      margin-left: auto;
    }
  `,
  content: css`
    display: flex;
    overflow-y: auto;
    padding: 0 1.5rem 0.5rem;
    width: 100%;
    height: calc(100% - ${DIALOG_HEADER_HEIGHT});
    margin-bottom: auto;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    * {
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
      ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  `,
  footer: css`
    margin-top: 0.5rem;
    padding: 1rem 1.5rem 0;
    box-shadow: 0px -1px 4px -1px var(--neutral-200);
  `,
};
