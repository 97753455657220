import { SVGProps } from 'react';

export default function CardBall({
  type,
  height,
  ...props
}: SVGProps<SVGSVGElement> & {
  type: 'info' | 'danger' | 'primary' | 'invert';
}) {
  const typeOption = () => {
    switch (type) {
      case 'primary':
        return 'var(--neutral-0)';
      case 'danger':
        return 'var(--red-500)';
      case 'invert':
        return 'var(--neutral-500)';
      default:
        return 'var(--blue-500)';
    }
  };
  const fill = typeOption();

  return (
    <svg
      width={144}
      height={height}
      viewBox="0 0 144 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_3397_17812)">
        <rect
          x={206.478}
          y={22.3562}
          width={65.1348}
          height={238.42}
          transform="rotate(60 206.478 22.3562)"
          fill={`url(#paint0_linear_3397_17812_${type})`}
        />
        <path
          d="M164.89 46.7881C161.573 41.0434 157.157 36.0082 151.895 31.9701C146.632 27.932 140.626 24.9699 134.218 23.2531C127.811 21.5362 121.128 21.0982 114.551 21.9641C107.975 22.8299 101.633 24.9826 95.8885 28.2993C90.1438 31.616 85.1087 36.0317 81.0705 41.2943C77.0324 46.5569 74.0703 52.5633 72.3535 58.9707C70.6367 65.378 70.1986 72.0608 71.0645 78.6374C71.9303 85.214 74.083 91.5557 77.3997 97.3003L121.145 72.0442L164.89 46.7881Z"
          fill={`url(#paint1_linear_3397_17812_${type})`}
        />
      </g>
      <defs>
        <linearGradient
          id={`paint0_linear_3397_17812_${type}`}
          x1={239.045}
          y1={22.3562}
          x2={238.513}
          y2={318.28}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} stopOpacity={0} />
          <stop
            offset={type === 'danger' ? 0.115 : 1}
            stopColor={fill}
            stopOpacity={type === 'danger' ? 0.2 : 0.23}
          />
        </linearGradient>
        <linearGradient
          id={`paint1_linear_3397_17812_${type}`}
          x1={389.147}
          y1={-79.3569}
          x2={64.3107}
          y2={88.5822}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={fill} />
          <stop offset={1} stopColor={fill} stopOpacity={0} />
        </linearGradient>
        <clipPath id="clip0_3397_17812">
          <rect
            width={161}
            height={128}
            fill="white"
            transform="translate(0 -2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
