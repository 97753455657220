import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';
import { useCallback, useEffect, useState } from 'react';

import useInterval from './use-interval';

export default function useTimer() {
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [isRunning, setRunning] = useState(false);
  const [duration, setDuration] = useState('');

  const { setInterval, clearInterval } = useInterval();

  const startTimer = useCallback((expiredTime: Date | string) => {
    setEndTime(parseISO(expiredTime as string));
    setRunning(true);
    setDuration('');
  }, []);

  const resetTimer = useCallback(() => {
    setEndTime(null);
    setRunning(false);
    setDuration('00:00');
    clearInterval();
  }, [clearInterval]);

  function addLeadingZero(text: string | number) {
    return String(text).length === 1 ? `0${text}` : text;
  }

  function formatText(hours, minutes, seconds) {
    const hoursText = hours ? `${addLeadingZero(hours)}:` : '';
    const minutesText = `${addLeadingZero(minutes)}:`;
    const secondsText = addLeadingZero(seconds);
    return `${hoursText}${minutesText}${secondsText}`;
  }

  function getDuration() {
    const currentTime = new Date();
    if (endTime && isBefore(currentTime, endTime)) {
      const milliseconds = Math.abs(
        differenceInMilliseconds(currentTime, endTime),
      );
      const hours = Math.floor(milliseconds / 1000 / 3600);
      const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
      const seconds = Math.floor((milliseconds / 1000) % 60);
      setDuration(formatText(hours, minutes, seconds));
    } else {
      resetTimer();
    }
  }

  useEffect(() => {
    if (isRunning) {
      setInterval(getDuration, 1000);
    }

    return () => clearInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, setInterval]);

  return {
    startTimer,
    duration,
    isRunning,
  };
}
