import { css } from '@emotion/react';

import { screenSize } from '../common/size';

export default css`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smooth: never;
    ::-webkit-scrollbar {
      width: 6px !important;
      height: 6px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 10px !important;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      pointer-events: all;
      background: var(--neutral-200) !important;
      border-radius: 10px !important;
    }
  }
  html,
  body {
    height: auto;
    margin: 0;
    font-size: 16px;
  }

  html {
    --scroll-bar: unset !important;
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--font-family), sans-serif;
    scroll-behavior: smooth;
    font-weight: 500;
    overflow-x: hidden;
  }

  [class^='ant-btn'],
  [class^='ant-tag'],
  [class^='ant-input'],
  [class^='ant-radio'],
  [class^='ant-select'],
  [class^='ant-alert'],
  [class^='ant-badge'],
  [class^='ant-table'],
  [class^='ant-col'],
  [class^='ant-checkbox'],
  [class^='ant-picker'],
  [class^='ant-layout'],
  [class^='ant-row'],
  [class^='ant-upload'],
  [class^='ant-steps'],
  [class^='ant-segmented'],
  [class^='ant-divider'],
  [class^='ant-drawer'] {
    font-family: var(--font-family);
  }

  .ant-divider-horizontal.ant-divider-with-text {
    color: var(--text-primary);
  }

  [class^='ant-layout'],
  [class*=' ant-layout'] {
    font-size: 1rem;
  }

  .ant-upload-drag-container {
    padding: 0.5rem;
  }

  .ant-pagination .ant-pagination-item a {
    color: var(--text-secondary);
  }

  .ant-upload-wrapper .ant-upload-drag {
    border: unset;
    background: unset;
  }

  .ant-slide-up-enter,
  .ant-slide-up-appear {
    transform: unset;
    transform-origin: unset;
  }

  .ant-image .ant-image-img {
    vertical-align: unset;
  }

  a {
    display: inline-flex;
    color: var(--blue-500);
    &:active {
      color: var(--blue-500);
    }
  }

  .link {
    color: var(--blue-500);
    font-weight: var(--bold-font-weight);
    &:not(:disabled) {
      &:active {
        color: var(--blue-500);
        text-decoration: underline;
      }
    }
  }

  b {
    font-weight: bold;
  }

  ul {
    margin-block-start: unset;
    margin-block-end: unset;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  [class^='ant-input'] {
    color: var(--text-primary);
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-block-start: unset;
    margin-block-end: unset;
    margin-bottom: 0.5rem;
  }

  h1 {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
    font-weight: var(--h1-font-weight);
  }

  h2 {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
    font-weight: var(--h2-font-weight);
  }

  h3 {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
    font-weight: var(--h3-font-weight);
  }

  h4 {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
    font-weight: var(--h4-font-weight);
  }

  h5 {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
    font-weight: var(--h5-font-weight);
  }

  .disabled-text {
    color: var(--text-disabled);
    transition: color ease-in 0.2s;
  }

  .regular-text {
    font-weight: 500;
    font-size: 1rem;
    line-height: var(--regular-line-height);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.875rem;
    }
  }

  .bold-text {
    font-size: 1rem;
    font-weight: 700;
    line-height: var(--regular-line-height);
  }

  .sm-bold-text {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 19.6px;
  }

  .sm-text {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 19.6px;
  }

  .xs-text {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 16.8px;
  }

  .xs-bold-text {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 16.8px;
  }

  .btn-text {
    font-weight: 700;
    line-height: 20.08px;
  }

  .emphasized-text {
    font-weight: 700;
  }

  .secondary-text {
    color: var(--text-secondary);
  }

  .card {
    height: auto;
    background-color: var(--neutral-0);
    border-radius: 14px;
    transform: translateZ(0);
    &.card-main {
      box-shadow: 0px 2px 8px rgba(46, 72, 101, 0.1);
    }
    &.card-secondary {
      border: 1px solid var(--neutral-200);
    }
    &.card-neutral {
      border: 1px solid rgba(213, 224, 232, 0.5);
    }
    &.card-dash {
      border: 1px dashed var(--neutral-200);
    }
  }

  .disabled {
    cursor: default !important;
    * {
      cursor: inherit !important;
    }
  }

  .error-text,
  .helper-text {
    font-size: 0.875rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.75rem;
    }
  }

  .error-text {
    color: var(--red-500);
  }

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    &.circle-info {
      background-color: var(--blue-100);
    }
    &.circle-warning {
      background-color: var(--yellow-100);
    }
    &.circle-success {
      background-color: var(--green-100);
    }
    &.circle-danger {
      background-color: var(--red-100);
    }
    &.circle-orange {
      background-color: var(--orange-100);
    }
    &.circle-primary {
      background-color: var(--blue-500);
    }
    &.circle-disabled {
      background-color: var(--neutral-100);
    }
  }
  .freshchat-wrapper {
    bottom: 4.8125rem !important;
    padding-top: 0.3125rem;
  }
  .ant-skeleton-input {
    width: 100% !important;
  }
  .object-fit-contain {
    object-fit: contain;
  }
  .object-fit-cover {
    object-fit: cover;
  }
  .pincode-input-text {
    color: var(--text-primary);
    font-family: var(--font-family);
    font-size: 1.375rem;
  }
  .ant-avatar {
    width: fit-content;
    height: auto;
    background: unset;
  }

  .list-menu-no-bg {
    background: unset !important;
    > * {
      background: unset !important;
    }
  }
  .full-width {
    width: 100%;
  }
  .strikethrough {
    color: var(--text-secondary);
    text-decoration: line-through;
  }

  .btn-link {
    justify-content: center;
    padding: 0.75rem;
  }

  .hide-dialog-padding {
    > :nth-child(2) {
      padding: unset;
    }
  }

  .btn-wrapper {
    > button {
      :first-child {
        margin-left: auto;
      }
      :not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }

  .numeric-format-base {
    border: none;
    background: transparent;
    font-family: var(--font-family);
    font-size: 1rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
    color: var(--text-primary);
    :focus {
      outline: none;
    }
  }

  /* unstyled the html primitive button */
  button {
    display: inline-flex;
    cursor: pointer;
    text-align: center;
    font-family: var(--font-family);
  }

  /* visually hidden but accessible to screen readers
  and still visible at DOM */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
`;
