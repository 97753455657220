import { css, cx } from '@emotion/css';
import { useBanner } from '@hooks';
import { Close, Text } from '@topremit/shared-web';
import { callAllFunctions } from '@topremit/shared-web/common/helper';
import { fontWeight } from '@topremit/shared-web/common/size';
import { ReactNode } from 'react';

export interface IBannerProps {
  message: string | ReactNode;
  colorScheme?: 'neutral' | 'red' | 'blue' | 'yellow' | 'green';
}

export default function Banner({
  message,
  colorScheme = 'neutral',
}: IBannerProps) {
  const { hideBanner, showCloseButton, onClickClose } = useBanner();

  return (
    <div id="banner-wrapper" className={cx(styled.root, styled[colorScheme])}>
      <Text className="text">{message}</Text>
      {showCloseButton && (
        <div
          className="close"
          onClick={callAllFunctions(hideBanner, onClickClose)}
        >
          <Close width={15} height={15} />
        </div>
      )}
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: fit-content;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0.625rem;
    z-index: 1;
    h5 {
      width: 100%;
      margin-bottom: 0;
    }
    .close {
      margin-left: 1rem;
    }
    .text {
      font-weight: ${fontWeight.bold};
    }
  `,
  neutral: css`
    background: #edf2f7;
  `,
  red: css`
    background: var(--red-100);
  `,
  blue: css`
    background: var(--blue-100);
  `,
  yellow: css`
    background: var(--yellow-100);
  `,
  green: css`
    background: var(--green-100);
  `,
};
