import { createContext, useContext, useState } from 'react';

interface DrawerContextProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}

const DrawerContext = createContext({});

export function DrawerProvider({ children }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function onOpen() {
    setIsOpen(true);
  }

  function onClose() {
    setIsOpen(false);
  }

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        onOpen,
        onClose,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
}

export function useDrawer() {
  return useContext(DrawerContext) as DrawerContextProps;
}
