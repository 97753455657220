import { css } from '@emotion/css';
import { Button } from '@global-elements';

import { RoutingChannelType } from '../../../api-hooks/transaction';
import { useTranslation } from '../../../hooks';
import { TranslationKeys } from '../../../translation-keys';

interface ISpeedChangedAutomaticallyModalBody {
  supportedRoutingChannel: RoutingChannelType;
  onOk: () => void;
}

function SpeedChangedAutomaticallyModalBody({
  supportedRoutingChannel,
  onOk,
}: ISpeedChangedAutomaticallyModalBody) {
  const { t } = useTranslation('calculator' as keyof TranslationKeys);
  return (
    <div className={styled.root}>
      <h5 className="title">{t('conflict_modal.speed_changed.title')}</h5>
      <div className="description">
        <span>
          {t('conflict_modal.speed_changed.description', {
            type: t(
              `conflict_modal.routing_channel_type.${supportedRoutingChannel.toLowerCase()}`,
            ),
          })}
        </span>
      </div>
      <Button fullWidth className="modal-btn" onClick={onOk}>
        {t('conflict_modal.speed_changed.okay')}
      </Button>
    </div>
  );
}

const styled = {
  root: css`
    .description {
      margin-bottom: 1rem;
      color: var(--text-secondary);
    }
  `,
};

export default SpeedChangedAutomaticallyModalBody;
