export type SetStateStore<T> = T | ((prev: T) => T);

export function setStore<T = any>(
  state: T,
  obj: {
    [key in keyof Partial<T>]: SetStateStore<T[key]>;
  },
) {
  const _state = Object.entries(obj).reduce((acc, [key, value]) => {
    const _value = typeof value === 'function' ? value(state[key]) : value;
    return { ...acc, [key]: _value };
  }, state);
  return _state;
}
