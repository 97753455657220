import { create } from 'zustand';

// Define the type for our Zustand store
interface FunctionQueueStore {
  queue: { id: string; fn: () => void }[]; // Array of functions
  add: (id: string, fn: () => void) => void; // Add a function to the queue
  next: () => void; // Remove and run the next function in the queue
  clear: () => void; // Clear the function queue
  queueList: () => { id: string; fn: () => void }[]; // Get the list of functions
  runQueue: () => void; // Run the current function in the queue
}

export const useGlobalQueueStore = create<FunctionQueueStore>((set, get) => ({
  queue: [],

  add: (id, fn) => {
    set((state) => {
      if (!state.queue.some((item) => item.id === id)) {
        return { queue: [...state.queue, { id, fn }] };
      }
      return state;
    });
  },

  next: () => {
    const { queue } = get();
    if (queue.length > 1) {
      const [, nextFn, ...rest] = queue;
      set({ queue: [nextFn, ...rest] });
      nextFn.fn();
    } else if (queue.length === 1) {
      set({ queue: [] });
    }
  },

  clear: () => {
    set({ queue: [] });
  },

  queueList: () => {
    return get().queue;
  },

  runQueue: () => {
    const { queue } = get();
    if (queue.length > 0) {
      const { fn } = queue[0];
      fn();
    }
  },
}));
