import { css, cx } from '@emotion/css';
import { useTranslation } from '@global-hooks';

import Status from './Status';

/**
 * Info to let `new user` know about free discount fee
 *
 */
export default function DiscountFeeStatus({
  className,
}: {
  className?: string;
}) {
  const { t } = useTranslation('calculator');

  return (
    <Status
      className={cx(styled.status, className)}
      color="blue"
      size="small"
      message={t('first_transaction_free')}
    />
  );
}

const styled = {
  status: css`
    justify-content: center;
    border: 1px solid var(--blue-200);
  `,
};
