import { VerifyPinDialog } from '@modules/dashboard/activity/transaction/create/modal/VerifyPinDialog';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { useDialogStore } from '@topremit/shared-web/stores';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';

/**
 * Interface for the `onSubmit` callback function used in `verify`.
 */
interface IVerifyPin {
  /**
   * A function that takes a PIN as a string and returns a Promise.
   * @param {string} pin - The PIN entered by the user.
   * @returns {Promise<void>} A promise that resolves when the PIN has been verified.
   */
  onSubmit: (pin: string) => Promise<void>;
  /**
   * The title of the dialog.
   */
  title?: ReactNode;
  /**
   * The description of the dialog.
   */
  description?: ReactNode;
}

/**
 * Interface for the optional parameters used in navigation functions.
 */
interface IActionParams {
  /**
   * The previous page to navigate back to after the PIN action is complete.
   * @description Used to navigate back to the previous page.
   */
  from?: string;
}

/**
 * Custom hook to handle PIN-related actions such as creating, changing, and resetting a PIN.
 * @returns {Object} An object containing functions to create, change, reset, and verify a PIN.
 *
 * @example
 * const { create, change, forgot, verify } = usePin();
 *
 * // Navigate to create PIN page
 * create({ from: '/profile' });
 * OR
 * create()
 *
 * // Navigate to change PIN page
 * change({ from: '/profile' });
 * OR
 * change()
 *
 * // Navigate to reset PIN page
 * forgot({ from: '/profile' });
 * OR
 * forgot()
 *
 * // Show dialog Verify PIN with a custom submit handler
 * verify({
 *   onSubmit: async (pin) => {
 *     try {
 *        await yourAsyncFunction()
 *     } catch {
 *        // catch error
 *     }
 *   }
 * });
 * OR
 * verify({
 *   onSubmit: async (pin) => {
 *      wait yourAsyncFunction()
 *   }
 * });
 */
export default function usePin() {
  const router = useRouter();

  const showDialog = useDialogStore((s) => s.show);
  const closeDialog = useDialogStore((s) => s.close);

  function navigateToPinPage(
    type: 'CREATE' | 'FORGOT' | 'CHANGE',
    params?: IActionParams,
  ) {
    if (isNativeApp() && type === 'CREATE') {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.CREATE_PIN,
      });
      return;
    }

    const from = params?.from;

    function getPathname() {
      if (type === 'CREATE') {
        return '/profile/create-pin';
      }
      if (type === 'FORGOT') {
        return '/profile/reset-pin';
      }
      if (type === 'CHANGE') {
        return '/profile/change-pin';
      }
    }

    closeDialog();
    router.push({
      pathname: getPathname(),
      query: {
        ...(from && { back: from }),
      },
    });
  }

  function verify({ onSubmit, description, title }: IVerifyPin) {
    showDialog({
      body: (
        <VerifyPinDialog
          title={title}
          onSubmit={onSubmit}
          description={description}
        />
      ),
    });
  }

  return {
    /**
     * Navigates to a create PIN page.
     * @param {IActionParams} [params] - Optional parameters for navigation.
     *
     * @example
     * // Navigate to the create PIN page and return to the profile
     * create({ from: '/profile' });
     * OR
     * create();
     */
    create: (params?: IActionParams) => navigateToPinPage('CREATE', params),
    /**
     * Navigates to a change PIN page.
     * @param {IActionParams} [params] - Optional parameters for navigation.
     *
     * @example
     * // Navigate to the change PIN page and return to the profile
     * change({ from: '/profile' });
     * OR
     * change();
     */
    change: (params?: IActionParams) => navigateToPinPage('CHANGE', params),
    /**
     * Navigates to a reset PIN page.
     * @param {IActionParams} [params] - Optional parameters for navigation.
     *
     * @example
     * // Navigate to the reset PIN page and return to the profile
     * forgot({ from: '/profile' });
     * OR
     * forgot();
     */
    forgot: (params?: IActionParams) => navigateToPinPage('FORGOT', params),
    /**
     * Opens a dialog to verify the PIN entered by the user.
     * @param {IVerifyPin} options - Options for PIN verification, including the `onSubmit` callback.
     *
     * @example
     * verify({
     *   onSubmit: async (pin) => {
     *     try {
     *        await yourAsyncFunction()
     *     } catch {
     *        // catch error
     *     }
     *   }
     * });
     * OR
     * verify({
     *   onSubmit: async (pin) => {
     *      await yourAsyncFunction()
     *   }
     * });
     */
    verify,
  };
}
