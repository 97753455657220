import { css, cx } from '@emotion/css';
import { parseHtml } from '@topremit/shared-web/components/elements';
import { Alert as AlertAntd, AlertProps } from 'antd';
import { AnimatePresence } from 'framer-motion';
import { ReactNode } from 'react';

import { color } from '../../styles/color';
import { Close, Info } from '../shapes';
import { NotesStatus } from './Notes';
import { AnimatedWrapper } from './animated-wrapper';

export interface IAlertProps extends Omit<AlertProps, 'description'> {
  /**
   * @param {'info' | 'warning' | 'success' | 'danger' | 'invert'} [status] other;
   */
  status?: NotesStatus;
  closable?: boolean;
  showIcon?: boolean;
  icon?: ReactNode;
  message: string | ReactNode;
  parseHTML?: boolean;
}

const styled = {
  alert: css`
    width: fit-content;
    padding: 10px 14px;
    border-radius: 8px;
    color: var(--blue-500);
    display: flex;
    flex-direction: row;
  `,
};

const colors = {
  info: {
    bg: css`
      background-color: var(--blue-100);
      border: 1px solid var(--blue-100);
    `,
    fontColor: css`
      color: var(--text-primary);
    `,
    icon: color.neutral800,
  },
  warning: {
    bg: css`
      background-color: var(--yellow-100);
      border: 1px solid var(--yellow-100);
    `,
    fontColor: css`
      color: var(--text-primary);
    `,
    icon: color.neutral800,
  },
  success: {
    bg: css`
      background-color: var(--green-100);
      border: 1px solid var(--green-100);
    `,
    fontColor: css`
      color: var(--text-primary);
    `,
    icon: color.neutral800,
  },
  danger: {
    bg: css`
      background-color: var(--red-100);
      border: 1px solid var(--red-100);
    `,
    fontColor: css`
      color: var(--text-primary);
    `,
    icon: color.neutral800,
  },
  invert: {
    bg: css`
      background-color: var(--neutral-0);
      border: 1px solid var(--neutral-200);
    `,
    fontColor: css`
      color: var(--text-primary);
    `,
    icon: color.neutral800,
  },
};

const alertVariants = {
  expand: {
    height: 'auto',
    opacity: 1,
  },
  collapse: {
    height: 0,
    opacity: 0,
  },
};

function Alert(props: IAlertProps) {
  const {
    message,
    status = 'other',
    icon,
    closable,
    className,
    parseHTML = true,
    ...resProps
  } = props;

  const alertClass = cx(styled.alert, colors[status].bg, className);
  const messageClass = cx(colors[status].fontColor, 'sm-text');

  function renderMessage() {
    if (typeof message === 'string') {
      return (
        <span className={messageClass}>
          {parseHTML ? parseHtml(message) : message}
        </span>
      );
    }

    return message;
  }

  return (
    <AnimatePresence>
      {!!message && (
        <AnimatedWrapper
          initial="collapse"
          animate="expand"
          exit="collapse"
          htmlTag="div"
          variants={alertVariants}
        >
          <AlertAntd
            icon={
              icon ? (
                icon
              ) : (
                <Info width={24} height={24} fill={color.neutral800} />
              )
            }
            className={alertClass}
            message={renderMessage()}
            closable={closable}
            closeIcon={
              closable && (
                <Close width={22} height={22} fill={color.neutral800} />
              )
            }
            {...resProps}
          />
        </AnimatedWrapper>
      )}
    </AnimatePresence>
  );
}

export { Alert };
