import { Options } from 'ky';

export function assertContextCalledInProvider<T>(ctx): asserts ctx is T {
  if (typeof ctx === 'undefined' || ctx === null) {
    // throw new AssertionError({
    //   message: `context have to be called inside provider`,
    // });
  }
}

export function isString(val): val is string {
  return typeof val === 'string';
}

export function isFormData(payload: Options['body']): payload is FormData {
  return payload instanceof FormData;
}

export function isArrayOfObject(items): items is object[] {
  return (
    Array.isArray(items) && items.every((item) => typeof item === 'object')
  );
}
