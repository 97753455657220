import {
  AccountType,
  useGetAccountInfo,
  useGetAccountPermissions,
} from '@api-hooks/common';
import { AuthorizationRules } from '@common/rules';
import { usePrevious } from '@topremit/shared-web/hooks';
import { useEffect, useState } from 'react';

import { useCurrentAccountStore } from '../stores/use-current-account-store';

const usePermission = () => {
  const [isErrorPermission, setIsErrorPermission] = useState(false);
  const { currentAccount } = useCurrentAccountStore.getState();
  const { data: accountInfo } = useGetAccountInfo();
  const prevCurrentAccountType = usePrevious(currentAccount?.type);
  const isBusinessAccount = currentAccount?.type === AccountType.BUSINESS;
  const isPersonalAccount = currentAccount?.type === AccountType.PERSONAL;

  const isRemittanceAccount = Boolean(
    accountInfo?.data.accounts?.find(
      (account) => account.id === currentAccount?.id,
    )?.isRemittance,
  );

  const { data: permissions, refetch: refetchPermission } =
    useGetAccountPermissions({
      onError: () => setIsErrorPermission(true),
      onSuccess: () => setIsErrorPermission(false),
    });

  const can = (rule: AuthorizationRules) => {
    if (isPersonalAccount) {
      return true;
    }
    if (!permissions?.data) {
      return false;
    }

    if (
      rule === AuthorizationRules.NoAuthorization &&
      process.env.NODE_ENV === 'development'
    ) {
      return true;
    }
    /* TODO: Handle not finished page on production not shown */
    if (
      rule === AuthorizationRules.NoAuthorization &&
      process.env.NODE_ENV === 'production'
    ) {
      return false;
    }

    if (rule !== AuthorizationRules.NoAuthorization) {
      return permissions.data.includes(rule);
    }
  };

  useEffect(() => {
    if (
      (isBusinessAccount && prevCurrentAccountType !== currentAccount?.type) ||
      (isBusinessAccount && !permissions?.data)
    ) {
      refetchPermission();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount]);

  return {
    can,
    permissions: isErrorPermission ? [] : permissions,
    isBusinessAccount,
    isPersonalAccount,
    isRemittanceAccount,
  };
};

export default usePermission;
