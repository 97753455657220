import { useGetAccountInfo } from '@api-hooks/common';
import { usePatchMember } from '@api-hooks/dashboard';
import { css } from '@emotion/css';
import { ListMenu } from '@topremit/shared-web/components/elements';
import {
  useChangeLocale,
  useNotification,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface IlanguageProps {
  languageMenu: {
    title: string;
    value: string;
  }[];
}

export default function Language({ languageMenu }: IlanguageProps) {
  const { t, lang } = useTranslation('dashboard');
  const { locale } = useRouter();
  const closeDialog = useDialogStore((store) => store.close);

  const [click, setClicked] = useState<string>(locale || '');

  const changeLocale = useChangeLocale();
  const { addNotification } = useNotification();
  const { refetch: refetchAccountInfo } = useGetAccountInfo();

  const { mutate: mutatePatchMember } = usePatchMember({
    onSuccess: () => {
      refetchAccountInfo();
    },
    onError: ({ message }) => {
      addNotification({ message, type: 'danger', closeable: false });
    },
  });

  return (
    <div className={styled.root}>
      <h4>{t('language')}</h4>
      {languageMenu.map(({ title, value }) => (
        <ListMenu
          onClick={() => {
            // Note: handle not run onclick when already same language
            if (value === lang) {
              return;
            }
            mutatePatchMember({ locale: value });
            changeLocale(value);
            setClicked(value);
            closeDialog();
          }}
          hasDivider
          className="list-menu"
          title={title}
          type="radio"
          isSelected={value === click}
          key={`list-menu-${value}`}
        />
      ))}
    </div>
  );
}

const styled = {
  loading: css`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  root: css`
    .list-menu {
      padding: 1.0625rem 0;
    }
  `,
};
