import { css } from '@emotion/react';

export default css`
  .fc-widget-small {
    z-index: 1029 !important;
  }
  .fc-open {
    z-index: 1050 !important;
  }
`;
