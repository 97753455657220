import { css } from '@emotion/css';
import { fontWeight, screenSize } from '@global-common/size';
import { Flex } from '@global-elements';
import { color as globalColor } from '@global-styles/color';
import { ReactNode } from 'react';

import HStepperDivider from './HStepperDivider';

type Color = 'blue' | 'red' | 'gray' | 'lightGray';
type DividerType = 'solid' | 'dashed';
type DividerColor = 'blue' | 'lightGray';

interface Props {
  children: ReactNode;
  icon: ReactNode;
  color: Color;
  showDivider?: boolean;
  dividerType?: DividerType;
  dividerColor?: DividerColor;

  className?: string;
}

const background = {
  blue: globalColor.blue100,
  red: globalColor.red100,
  gray: globalColor.neutral100,
  lightGray: globalColor.neutral100,
};

const fontColor = {
  blue: globalColor.blue500,
  red: globalColor.red500,
  gray: globalColor.neutral500,
  lightGray: globalColor.neutral300,
};

const { mobileLg } = screenSize;

export default function HStepperItem({
  children,
  icon,
  color,
  showDivider,
  dividerType = 'solid',
  dividerColor = 'blue',
  className,
}: Props) {
  return (
    <Flex className={className}>
      <Flex column align="center" gap={8} style={{ width: 'fit-content' }}>
        <Flex className={styled.icon(color)}>{icon}</Flex>
        <Flex className={styled.content(color)}>{children}</Flex>
      </Flex>
      {showDivider && (
        <Flex className={styled.dividerWrapper}>
          <HStepperDivider color={dividerColor} type={dividerType} />
        </Flex>
      )}
    </Flex>
  );
}

const styled = {
  icon: (color: Color) => css`
    width: 3rem;
    height: 3rem;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    background: ${background[color]};
    color: ${globalColor.neutral0};
    border-radius: 100%;
    @media (max-width: ${mobileLg}px) {
      width: 2.5rem;
      height: 2.5rem;
    }
  `,
  content: (color: Color) => css`
    justify-content: center;
    font-weight: ${fontWeight.bold};
    color: ${fontColor[color]};
  `,
  dividerWrapper: css`
    align-items: center;
    height: 3rem;
    width: 100%;
    min-width: 1rem;
    margin: 0 0.75rem;
    @media (max-width: ${mobileLg}px) {
      margin: 0 0.25rem;
    }
  `,
};
