import { css, cx } from '@emotion/css';
import {
  useState,
  useRef,
  useEffect,
  useCallback,
  Children,
  createElement,
  isValidElement,
  HTMLAttributes,
  DetailedHTMLProps,
  ReactNode,
  CSSProperties,
  UIEvent,
  useMemo,
} from 'react';

import { cloneButton } from './helper';
import { mergeRefs } from '../../../common/helper';
import { useCallbackRef, useMeasure } from '../../../hooks';

export interface IHorizontalSliderProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    'onScroll'
  > {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
  onScroll?: (
    e: UIEvent<HTMLDivElement, UIEvent>,
    isScrolledToEnd: boolean,
  ) => void;
  nextButton?: Element;
  prevButton?: Element;
  /**
   * @param {number} [scrollInterval] 250
   */
  scrollInterval?: number;
  firstThreshold?: number;
  lastThreshold?: number;
}

const styles = {
  cardSlider: css`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;

    .slider-content-wrapper {
      width: fit-content;
      display: inline-flex;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none !important;
      background-color: transparent;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 3px !important;
      height: 0px !important;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  `,
};

let snappingFn;

/**
 * @deprecated use HorizontalSliderV2 instead
 */
const HorizontalSlider = ({
  children,
  style,
  className,
  ref,
  onScroll,
  nextButton,
  prevButton,
  scrollInterval = 250,
  firstThreshold = 0.3, // 30% (position threshold), position start from left to right
  lastThreshold = 0.7, // 70% (position threshold to the right), position start from left to right
  ...resProps
}: IHorizontalSliderProps) => {
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [isDown, setIsDown] = useState(false);
  const sliderRef = useRef<any>(null);
  const contentRef = useRef<any>(null);
  const bounds = useMeasure(contentRef);
  const [firstChildStateRef, firstChildStateRefFn] = useCallbackRef();
  const [lastChildStateRef, lastChildStateRefFn] = useCallbackRef();
  const firstChildWidth =
    firstChildStateRef?.getBoundingClientRect().width || 0;
  const lastChildWidth = lastChildStateRef?.getBoundingClientRect().width || 0;
  const firstChildPosition = 0;
  const firstChildThresholdPosition = firstChildWidth * firstThreshold;
  const lastChildPosition = (bounds?.width || 0) - lastChildWidth;
  const lastChildThresholdPosition = lastChildPosition * lastThreshold;

  // Note: memo because possible render heavy children
  const filteredChildren = useMemo(
    () => Children.toArray(children).filter((child) => Boolean(child)),
    [children],
  );
  const childrenCount = Children.count(filteredChildren);
  const childrenNodes = Children.map(filteredChildren, (child, idx) => {
    const isFirstNode = idx === 0;
    const isLastNode = idx === childrenCount - 1;

    if (!isValidElement(child) || (!isFirstNode && !isLastNode)) {
      return child;
    }

    /**
     * add element wrapper to measure child width
     */
    if (isFirstNode) {
      const wrappedChild = createElement('div', {
        ref: firstChildStateRefFn,
        children: child,
        className: styled.measurerWrapper,
      });

      return wrappedChild;
    } else if (isLastNode) {
      const wrappedChild = createElement('div', {
        ref: lastChildStateRefFn,
        children: child,
        className: styled.measurerWrapper,
      });

      return wrappedChild;
    }
  });
  const isEmptyChildren = Children.count(filteredChildren) === 0;

  function onMouseDown(e) {
    setIsDown(true);
    sliderRef.current && sliderRef.current.classList.add('active');
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  }

  function onMouseLeave() {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  }

  function onMouseUp() {
    setIsDown(false);
    sliderRef.current.classList.remove('active');
  }

  function onMouseMove(e) {
    if (!isDown) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    sliderRef.current.scrollLeft = scrollLeft - walk;
  }

  function checkScrollPosition() {
    const ele = sliderRef.current;

    const isScrolledToEnd =
      Math.ceil(ele?.scrollLeft + ele?.clientWidth) >= ele?.scrollWidth;

    return { isScrolledToEnd, position: ele?.scrollLeft };
  }

  const handleButton = useCallback(
    (isScrolledToEnd, position) => {
      if (nextButton) {
        cloneButton(nextButton, { disabled: isScrolledToEnd });
      }

      if (prevButton) {
        cloneButton(prevButton, { disabled: position < 1 });
      }
    },
    [nextButton, prevButton],
  );

  function scroll(scrollOffset) {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += scrollOffset;
    }
  }

  useEffect(() => {
    const ele = sliderRef.current;

    function handleScroll(e) {
      const { isScrolledToEnd, position } = checkScrollPosition();

      handleButton(isScrolledToEnd, position);

      onScroll && onScroll(e, isScrolledToEnd);

      function snapToEdge(_position) {
        if (_position !== 0 && _position < firstChildThresholdPosition) {
          sliderRef.current.scrollLeft = firstChildPosition;
        } else if (
          _position !== lastChildThresholdPosition &&
          _position > lastChildThresholdPosition
        ) {
          sliderRef.current.scrollLeft = bounds?.width;
        }
      }
      clearTimeout(snappingFn);
      snappingFn = setTimeout(() => snapToEdge(position), 200);
    }

    function handleResize() {
      const { isScrolledToEnd, position } = checkScrollPosition();
      handleButton(isScrolledToEnd, position);
    }

    if (ele) {
      window.addEventListener('resize', handleResize);
      ele?.addEventListener('scroll', handleScroll);
    }
    return () => {
      ele && ele?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [
    bounds?.width,
    firstChildThresholdPosition,
    handleButton,
    lastChildPosition,
    lastChildThresholdPosition,
    onScroll,
  ]);

  useEffect(() => {
    const { isScrolledToEnd, position } = checkScrollPosition();
    if (nextButton) {
      cloneButton(nextButton, {
        onClick: () => scroll(scrollInterval),
        disabled: bounds?.width > 0 ? isScrolledToEnd : true,
      });
    }

    if (prevButton) {
      cloneButton(prevButton, {
        onClick: () => scroll(-scrollInterval),
        disabled: position < 1,
      });
    }
  }, [nextButton, prevButton, bounds, scrollInterval]);

  return (
    <div
      className={cx(styles.cardSlider, className, {
        [styled.emptyChildren]: isEmptyChildren,
      })}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      style={style}
      ref={mergeRefs(sliderRef, ref)}
      {...resProps}
    >
      <div className="slider-content-wrapper" ref={contentRef}>
        {childrenNodes}
      </div>
    </div>
  );
};

export { HorizontalSlider };

const styled = {
  measurerWrapper: css`
    display: flex;
  `,
  emptyChildren: css`
    display: none;
  `,
};
