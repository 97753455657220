import { useMounted } from '@global-hooks';
import { useEffect, useState } from 'react';

export function useGetInnerWidth(element: any) {
  const [innerWidth, setInnerWidth] = useState<number | string>(0);
  const isMounted = useMounted();

  useEffect(() => {
    if (element && isMounted) {
      let computed = window.getComputedStyle(element),
        padding =
          parseFloat(computed.paddingLeft) + parseFloat(computed.paddingRight),
        clientWidth = element.clientWidth;

      window.addEventListener('resize', () => {
        computed = window.getComputedStyle(element);
        padding =
          parseFloat(computed.paddingLeft) + parseFloat(computed.paddingRight);
        clientWidth = element.clientWidth;
        setInnerWidth(clientWidth - padding);
      });

      setInnerWidth(clientWidth - padding);
    } else {
      setInnerWidth('100%');
    }
  }, [element, isMounted]);

  return innerWidth;
}
