import { css } from '@emotion/css';
import { InterrogationOutline, SideMenuIcon } from '@topremit/shared-web';
import { useTranslation } from '@topremit/shared-web/hooks';
import Link from 'next/link';

import AnimatedSidebarText from './AnimatedSidebarText';

export default function SideBarHelpButton() {
  const { t, lang } = useTranslation();

  return (
    <Link
      className={styled.wrapper}
      href={`https://help.topremit.com/${lang}/support/home`}
      target="_blank"
      prefetch={false}
    >
      <SideMenuIcon>
        <InterrogationOutline />
      </SideMenuIcon>
      <AnimatedSidebarText>{t('help')}</AnimatedSidebarText>
    </Link>
  );
}

const styled = {
  wrapper: css`
    display: flex;
    flex-grow: 1;
    align-items: center;

    &,
    &:hover {
      color: var(--text-secondary);
    }
  `,
};
