import { css, cx } from '@emotion/css';
import { CountryModel } from '@global-api-hooks/common';
import { screenSize } from '@global-common/size';
import { Flag } from '@global-elements';
import {
  Select,
  SelectList,
  SelectSearch,
} from '@global-elements-utils/select';
import { useTranslation } from '@global-hooks';
import { useCommandState } from 'cmdk';
import * as React from 'react';

import SelectOption from './SelectOption';
import { CountrySelectProps } from './type';
import InfiniteStaticList from '../../../../elements/InfiniteStaticList';

function SelectedValue({
  selectedCountry,
  disabled,
}: {
  selectedCountry?: CountryModel;
  disabled?: boolean;
}) {
  if (!selectedCountry) return null;

  return (
    <div className={cx(styled.selectedDialCodeWrapper)}>
      <div className="flag-icon">
        <Flag code={selectedCountry.flagCode} />
      </div>
      <div>
        <span
          className={cx(
            'dial-code regular-text',
            disabled && styled.disabledText,
          )}
        >
          +{selectedCountry?.dialCode}
        </span>
      </div>
    </div>
  );
}

function CountrySelectContent(props: CountrySelectProps) {
  const { options, isSearchable = true } = props;

  const { t } = useTranslation();

  const search = useCommandState((s) => s.search);

  const _options = React.useMemo(() => {
    if (search && isSearchable) {
      return options?.filter(
        (option) =>
          option.name?.toLowerCase().includes(search.toLowerCase()) || // search by country name
          option.dialCode?.includes(search), // search by dial code
      );
    }

    return options;
  }, [isSearchable, options, search]);

  return (
    <>
      {isSearchable && (
        <SelectSearch
          placeholder={t('search_country_code')}
          className="select-searchbar"
        />
      )}
      <SelectList className={({ count }) => styled.list(!count)}>
        <InfiniteStaticList
          options={_options || []}
          renderItem={({ data: option }) => {
            return (
              <SelectOption
                name={option?.name}
                flagCode={option?.flagCode}
                dialCode={option?.dialCode}
                key={option?.name}
                value={option?.dialCode!}
                keywords={[option?.name, option?.dialCode! || '']}
              />
            );
          }}
        />
      </SelectList>
    </>
  );
}

function CountrySelect(props) {
  const { options, container, onChange, selectedCountry, label, ...restProps } =
    props;

  return (
    <Select
      value={selectedCountry?.dialCode}
      onChange={onChange}
      renderValue={(value) => {
        const selectedCountry = options?.find(
          (option) => option.dialCode === value,
        );
        return (
          <SelectedValue
            selectedCountry={selectedCountry}
            disabled={restProps?.disabled}
          />
        );
      }}
      props={{
        content: {
          className: styled.content(container?.clientWidth),
        },
        command: {
          shouldFilter: false,
        },
      }}
      styles={{
        inputContainer: styled.inputContainer,
        button: styled.selectButton(restProps.disabled),
        rightIconContainer: styled.rightIconContainer,
      }}
      mobileLabel={label}
      {...restProps}
    >
      <CountrySelectContent {...props} />
    </Select>
  );
}

const styled = {
  inputContainer: css`
    padding: unset !important;
    display: flex;
    flex: 1;
    align-items: center;
  `,
  rightIconContainer: css`
    padding-right: 12px !important;
    border-right: 1px solid #ced4da !important;
  `,
  selectButton: (disabled) => css`
    display: flex;
    min-width: 8rem;
    outline: none !important;
    border: unset !important;
    padding: 15px 0 !important;
    padding-left: 16px !important;
    gap: 0.5rem;
    ${disabled && `background-color: unset`}
  `,
  content: (width) => css`
    width: ${width}px !important;
  `,
  list: (empty) => css`
    min-height: ${empty ? 'unset' : '300px'};
  `,
  selectedDialCodeWrapper: css`
    display: flex;
    align-items: center;
    > * {
      user-select: none;
      pointer-events: none;
    }
    .flag-icon {
      width: fit-content;
      min-width: fit-content;
      height: 1.75rem;
      min-height: 1.75rem;
      display: flex;
      align-items: center;
      margin-right: 0.625rem;
    }

    @media (max-width: ${screenSize.mobileLg}px) {
      font-size: 0.875rem;
    }
  `,
  disabledText: css`
    color: rgba(0, 0, 0, 0.25);
  `,
};

export default React.forwardRef<HTMLButtonElement, CountrySelectProps>(
  CountrySelect,
);
