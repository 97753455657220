import { useGetMe } from '@api-hooks/common';
import { useAnalytics } from '@hooks/use-analytics';
import useShowChatUsDialog from '@hooks/use-show-chat-us-dialog';
import { KycAbadStatus } from '@modules';
import BlockingModal from '@modules/landing/jumbotron/modal-body/BlockingModal';
import bolyDisclaimerImage from '@public/images/svg/boly-disclaimer.svg';
import bolyFailedImage from '@public/images/svg/boly-failed.svg';
import bolyVerificationImage from '@public/images/svg/boly-verification.svg';
import { callAllFunctions } from '@topremit/shared-web/common/helper';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { screenSize } from '@topremit/shared-web/common/size';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { BlockingPageType } from '@topremit/shared-web/typings/analytics.model';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import { IdType } from '@topremit/shared-web/typings/profile.model';
import { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';

import useGetKycAbad from './use-get-kyc-abad';

type BlockingModalProps = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  imgSrc?: StaticImageData | string;
  buttonLabel?: string;
  imgStyle?: {
    width?: number;
    height?: number;
  };
};

export default function useShowKycBlocking() {
  const { t } = useTranslation();
  const {
    analyticsPersonal: { tracksPersonal },
  } = useAnalytics();
  const router = useRouter();
  const { statusKycAbad, hasKycIdentityOrSelfie } = useGetKycAbad();
  const closeDialog = useDialogStore((state) => state.close);
  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const isReferralPage = router.pathname === '/referral';
  const isCalculatorPage = router.pathname === '/quote';
  const isProfilePage = router.pathname === '/profile';
  const isKycPage = router.pathname === '/kyc';

  const pageSources: { condition: boolean; value: BlockingPageType }[] = [
    { condition: isReferralPage, value: 'referral' },
    { condition: isCalculatorPage, value: 'calculator' },
    { condition: isProfilePage, value: 'profile' },
    { condition: isKycPage, value: 'kyc' },
  ];
  const source = pageSources.find((page) => page.condition)?.value || null;

  const { data } = useGetMe();
  const me = data?.data;
  const { showChatDialog } = useShowChatUsDialog('email', me?.email);

  function handleClickToSuggestionOrDisclaimer() {
    // Note: handle click to disclaimer or suggestion page kyc
    closeDialog();
    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.OPEN_KYC_DISCLAIMER,
      });
      return;
    }
    if (isMobileSize) {
      router.push('/kyc/disclaimer');
      return;
    }
    router.push('/kyc/suggestion');
  }

  function handleClickStepKyc() {
    // Note: handle click to kyc step
    closeDialog();
    const idType = me?.idType?.value;
    if (isNativeApp() && idType) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.OPEN_KYC_STEP,
        data: {
          idType: idType as IdType,
        },
      });
      return;
    }
    router.push('/kyc', undefined, { shallow: true });
  }

  function handleClickChooseIdType() {
    // Note: handle click to choose id type
    closeDialog();
    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.OPEN_ID_TYPE,
      });
      return;
    }
    if (hasKycIdentityOrSelfie && !isMobileSize) {
      router.push('/kyc/suggestion');
      return;
    }
    router.push('/kyc/choose-id-type');
  }

  function handleClickVerifyingKycAbad() {
    // Note: handle click to home page
    closeDialog();
    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.BACK,
      });
      return;
    }
    router.push('/home');
  }

  function handleClickRejectedReason() {
    // Note: handle click to home page
    closeDialog();
    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.OPEN_KYC_REJECTED_REASON,
      });
      return;
    }
    router.push('/kyc/rejected-reason');
  }

  function handleClickOpenFreshChat() {
    // Note: handle click to open freshchat
    closeDialog();
    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.OPEN_FRESHCHAT,
      });
      return;
    }
    showChatDialog();
  }

  function getBlockingModalProps(): BlockingModalProps {
    switch (statusKycAbad) {
      case KycAbadStatus.UNVERIF_KYC_ABAD: {
        return {
          title: t('home:jumbotron.block_calculator.unverified.kyc_abad.title'),
          description: t(
            'home:jumbotron.block_calculator.unverified.kyc_abad.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.unverified',
          ),
          onClick: callAllFunctions(handleClickToSuggestionOrDisclaimer, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'need_kyc',
              source,
            });
          }),
          imgSrc: bolyVerificationImage,
          imgStyle: { width: 200, height: 200 },
        };
      }
      case KycAbadStatus.UNVERIF_ABAD: {
        return {
          title: t('home:jumbotron.block_calculator.unverified.abad.title'),
          description: t(
            'home:jumbotron.block_calculator.unverified.abad.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.unverified',
          ),
          onClick: callAllFunctions(handleClickStepKyc, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'need_abad',
              source,
            });
          }),
          imgSrc: bolyVerificationImage,
          imgStyle: { width: 200, height: 200 },
        };
      }
      case KycAbadStatus.VERIFYING_KYC_ABAD:
        return {
          title: t('home:jumbotron.block_calculator.verifying.kyc_abad.title'),
          description: !isReferralPage
            ? t(
                'home:jumbotron.block_calculator.verifying.kyc_abad.description',
              )
            : t(
                'home:jumbotron.block_calculator.verifying_referral.kyc_abad.description',
              ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.back_to_home',
          ),
          onClick: handleClickVerifyingKycAbad,
          imgSrc: bolyDisclaimerImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.VERIFYING_ABAD:
        return {
          title: t('home:jumbotron.block_calculator.verifying.abad.title'),
          description: !isReferralPage
            ? t('home:jumbotron.block_calculator.verifying.abad.description')
            : t(
                'home:jumbotron.block_calculator.verifying_referral.abad.description',
              ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.back_to_home',
          ),
          onClick: handleClickVerifyingKycAbad,
          imgSrc: bolyDisclaimerImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.REJECTED_ABAD:
        return {
          title: t('home:jumbotron.block_calculator.rejected_abad.title'),
          description: me?.bankAccountVerificationInfo?.rejectedReason || '',
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.reverify',
          ),
          onClick: callAllFunctions(handleClickStepKyc, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'abad_verification_rejected',
              source,
            });
          }),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
        };

      case KycAbadStatus.REJECTED_KYC:
        return {
          title: t('home:jumbotron.block_calculator.rejected_kyc.title'),
          description: t(
            'home:jumbotron.block_calculator.rejected_kyc.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.see_why',
          ),
          onClick: callAllFunctions(handleClickRejectedReason, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'kyc_verification_rejected',
              source,
            });
          }),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.REJECTED_KYC_EMPTY_ABAD:
        return {
          title: t('home:jumbotron.block_calculator.rejected_kyc.title'),
          description: t(
            'home:jumbotron.block_calculator.rejected_kyc.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.see_why',
          ),
          onClick: callAllFunctions(handleClickRejectedReason, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'kyc_verification_rejected',
              source,
            });
          }),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.REJECTED_COMPLIANCE_KYC:
        return {
          title: t(
            'home:jumbotron.block_calculator.rejected_kyc_compliance.title',
          ),
          description: t(
            'home:jumbotron.block_calculator.rejected_kyc_compliance.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.contact_cs',
          ),
          onClick: callAllFunctions(handleClickOpenFreshChat, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'kyc_compliance_rejected',
              source,
            });
          }),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.BLACKLIST_SPAM_PROHIBITED:
        return {
          title: t(
            'home:jumbotron.block_calculator.not_eligible_blacklist.title',
          ),
          description: t(
            'home:jumbotron.block_calculator.not_eligible_blacklist.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.contact_cs',
          ),
          onClick: callAllFunctions(handleClickOpenFreshChat, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'kyc_blacklist',
              source,
            });
          }),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.BLACKLIST_OTHER:
        return {
          title: t(
            'home:jumbotron.block_calculator.not_eligible_after_approved_blacklist.title',
          ),
          description: t(
            'home:jumbotron.block_calculator.not_eligible_after_approved_blacklist.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.contact_cs',
          ),
          onClick: callAllFunctions(handleClickOpenFreshChat, () => {
            if (source === null) {
              return;
            }
            tracksPersonal.viewBlocking({
              section: 'kyc',
              name: 'kyc_blacklist',
              source,
            });
          }),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
        };
      case KycAbadStatus.BLACKLIST_REMOVED:
        return {
          title: t('home:jumbotron.block_calculator.blacklist_removed.title'),
          description: t(
            'home:jumbotron.block_calculator.blacklist_removed.description',
          ),
          buttonLabel: t(
            'home:jumbotron.block_calculator.action_label.reverify',
          ),
          onClick: handleClickChooseIdType,
        };
      default: {
        return {};
      }
    }
  }

  function handleKycBlockingModal() {
    if (!statusKycAbad) {
      return;
    }
    const hasBlockingProps = Object.keys(getBlockingModalProps()).length > 0;
    if (hasBlockingProps) {
      return <BlockingModal {...getBlockingModalProps()} />;
    }
  }
  return {
    getBlockingModalProps,
    handleKycBlockingModal,
  };
}
