import {
  ISelectedPurpose,
  ISelectedRecipient,
  IValidateRecipientBatchesResponse,
} from '@api-hooks/domestic';
import { DeliverySpeedValue } from '@elements';
import { TransactionMode } from '@topremit/shared-web/api-hooks/common';
import { RemitQuotationModel } from '@topremit/shared-web/api-hooks/transaction';
import { SetStateStore, setStore } from 'landing/src/stores';
import { create } from 'zustand';

export interface IRecipientDocumentStore {
  note: string;
  type: TransactionMode;
  hardLimitMessage: string;
  purpose: ISelectedPurpose;
  amount: number | undefined;
  isLoadingQuotation: boolean;
  errorServicesMessage: string;
  errorRecipientMessage: string;
  errorRecipientBatchesIds: number[];
  deliverySpeed: DeliverySpeedValue;
  recipient: ISelectedRecipient | null;
  quotation: RemitQuotationModel | null;
  bulkRecipients: (ISelectedRecipient | null)[];
  validatedRecipientBatches: IValidateRecipientBatchesResponse | null;
  setField: <T extends keyof Omit<IRecipientDocumentStore, 'setField'>>(
    field: T,
    value: SetStateStore<IRecipientDocumentStore[T]>,
  ) => void;
  resetStore: () => void;
}

const initialState: Omit<IRecipientDocumentStore, 'setField' | 'resetStore'> = {
  note: '',
  recipient: null,
  quotation: null,
  amount: undefined,
  purpose: { id: 0 },
  bulkRecipients: [],
  hardLimitMessage: '',
  errorServicesMessage: '',
  isLoadingQuotation: false,
  errorRecipientMessage: '',
  type: TransactionMode.SINGLE,
  deliverySpeed: {
    serviceId: undefined,
    routingChannel: undefined,
  },
  validatedRecipientBatches: null,
  errorRecipientBatchesIds: [],
};

export const useDomesticFlowStore = create<IRecipientDocumentStore>((set) => ({
  ...initialState,
  setField: (field, value) =>
    set((state) => setStore(state, { [field]: value })),
  resetStore: () => set(initialState),
}));
