import { css, cx } from '@emotion/css';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';

import Button from './Button';
import { screenSize } from '../../common/size';
import { color } from '../../styles/color';

const Tooltip = dynamic(() => import('./Tooltip').then((m) => m.Tooltip));
const Info = dynamic(() => import('../shapes').then((m) => m.Info));

export type CardNotesStatus =
  | 'info'
  | 'warning'
  | 'success'
  | 'danger'
  | 'invert';

export interface ICardNotesProps {
  /**
   * @param {'info' | 'warning' | 'success' | 'danger' | 'invert'} [status] other;
   */
  status?: CardNotesStatus;
  icon?: ReactNode;
  title: string | ReactNode;
  description?: string | ReactNode;
  className?: string;
  showButton?: boolean;
  buttonText?: string;
  buttonOnClick?: () => void;
  showTooltipInfo?: boolean;
  tooltipInfoText?: string;
  buttonDisabled?: boolean;
}

const { tabletSm } = screenSize;

const styled = {
  root: css`
    display: flex;
    align-items: center;
    width: 100%;
    .icon {
      margin-right: 1rem;
    }
    .circle {
      width: 2rem;
      height: 2rem;
    }
    .content {
      display: flex;
      align-items: center;
      @media (max-width: ${tabletSm}px) {
        flex-direction: column;
        align-items: flex-start;
      }
      flex: 1;
      .text-wrapper {
        flex: 1;
        .title-wrapper {
          display: flex;
          margin-bottom: 0.25rem;
          .title-text {
            margin-right: 0.5rem;
          }
          > div {
            display: flex;
            align-items: center;
          }
        }
      }
      .button-wrapper {
        justify-content: flex-end;
        @media (max-width: ${tabletSm}px) {
          margin-top: 0.5rem;
        }
      }
    }
    .secondary-text {
      color: var(--neutral-500);
    }
  `,
  tooltipCard: css`
    div {
      div {
        div {
          div.ant-popover-inner {
            .ant-popover-title {
              min-height: 0rem;
              height: 0.75rem;
            }
            .ant-popover-inner-content {
              > div {
                font-size: 0.875rem;
                line-height: 19.6px;
                font-weight: var(--regular-font-weight);
              }
            }
          }
        }
      }
    }
  `,
};

export function CardNotes(props: ICardNotesProps) {
  const {
    icon,
    title,
    className,
    showButton,
    buttonText,
    description,
    buttonDisabled,
    status = 'info',
    showTooltipInfo,
    tooltipInfoText,
    buttonOnClick,
  } = props;
  return (
    <div className={cx(styled.root, className)}>
      <div className={`circle circle-${status} icon`}>{icon}</div>
      <div className="content">
        <div className="text-wrapper">
          <div className="title-wrapper">
            <div className="title-text sm-text secondary-text">{title}</div>
            {showTooltipInfo && (
              <Tooltip
                placement="bottomRight"
                className={styled.tooltipCard}
                item={{
                  content: tooltipInfoText,
                }}
              >
                <Info width={16} height={16} fill={color.neutral500} />
              </Tooltip>
            )}
          </div>
          <div className="desc bold-text">{description}</div>
        </div>
        <div className="button-wrapper">
          {showButton && (
            <Button
              type="secondary"
              size="small"
              onClick={buttonOnClick}
              disabled={buttonDisabled}
            >
              {buttonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
