import type { I18n, Translate } from 'next-translate';
import useTranslationDefault from 'next-translate/useTranslation';

// TranslationKeys is automatically generated by generateTranslationKeys.js script
import { TranslationKeys } from '../translation-keys';

type Tail<T> = T extends [unknown, ...infer Rest] ? Rest : never;

type TranslationNamespace = keyof TranslationKeys;

export interface TranslateFunction<Namespace extends TranslationNamespace> {
  (
    key: TranslationKeys[Namespace],
    ...rest: Tail<Parameters<Translate>>
  ): string;
  (key: string, ...rest: Tail<Parameters<Translate>>): string;
}

interface TypeSafeTranslate<Namespace extends TranslationNamespace>
  extends Omit<I18n, 't'> {
  t: TranslateFunction<Namespace>;
}

// Re-declare the module to add the type-safe version of the hook
declare module 'next-translate/useTranslation' {
  export default function useTranslation<
    Namespace extends TranslationNamespace,
  >(namespace: Namespace): TypeSafeTranslate<Namespace>;
}

/**
 * @description This is a custom hook that extends the default `useTranslation`
 * hook from `next-translate` to provide a type-safe version of the `t` function.
 * @source https://github.com/aralroca/next-translate/blob/master/docs/type-safety.md
 */
export default function useTranslation<Namespace extends TranslationNamespace>(
  namespace: Namespace = 'common' as Namespace,
): TypeSafeTranslate<Namespace> {
  return useTranslationDefault(namespace);
}
