import { css, cx } from '@emotion/css';
import { useState } from 'react';

import { Chip, Flex } from '.';

interface ChipsOptionsProps {
  value?: string;
  className?: string;
  options: { value: string; label: string }[];
  onSelect?: (value: string) => void;
}

function ChipsOptions({
  options,
  value,
  className,
  onSelect,
}: ChipsOptionsProps) {
  const [selected, setSelected] = useState(value);

  function onClick(value: string) {
    setSelected(value);
    onSelect && onSelect(value);
  }

  return (
    <Flex className={cx(styled.root, className)}>
      {options.map(({ value, label }) => (
        <Chip
          key={value}
          label={label}
          isSelected={value === selected}
          onClick={() => onClick(value)}
        />
      ))}
    </Flex>
  );
}

const styled = {
  root: css`
    gap: 1rem;
  `,
};

export default ChipsOptions;
