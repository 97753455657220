import { client } from '@common/client';
import { useAnalytics } from '@hooks';
import { ApiError } from '@topremit/shared-web/api-hooks/api.model';
import humps from 'humps';
import { AnalyticPendingEvents } from 'landing/db';
import { useQuery } from 'react-query';

import { GetPaidTrxResponse } from './analytic.model';

interface GetPaidTrxArgs {
  getQueueFn(): Promise<AnalyticPendingEvents[] | undefined>;
}

async function getPaidTrx({
  getQueueFn,
}: GetPaidTrxArgs): Promise<GetPaidTrxResponse> {
  const searchParams = new URLSearchParams();
  const queue = await getQueueFn();
  const refIds = queue?.map((v) => v.params.transaction_id);

  if (!refIds?.length) return new Promise(() => []);

  refIds?.forEach((v) => searchParams.append('ref_ids[]', String(v)));

  return client
    .get('transactions/track-purchase', { searchParams })
    .json<GetPaidTrxResponse>();
}

/**
 * A custom React hook that fetches and synchronizes paid transaction analytics data.
 * It uses the `react-query` library to handle data fetching and caching.
 *
 * @param params - The parameters required to fetch the paid transaction data.
 * @returns A `useQuery` object from `react-query` that contains the fetched data and loading/error states.
 *
 * @remarks
 * The hook fetches the paid transaction data from the API using the `getPaidTrx` function.
 * It then calls the `syncPendingPurchaseEvents` method from the `analytics.tracks` object with the fetched IDs.
 * The hook refreshes the data every 5 minutes.
 */
export function useSyncPaidTrxAnalytic() {
  const { trackerPersonalBusiness } = useAnalytics();

  return useQuery<GetPaidTrxResponse, ApiError>({
    queryKey: ['analytic', 'paid-transaction'],
    queryFn: () =>
      getPaidTrx({
        getQueueFn: async () =>
          await trackerPersonalBusiness?.getQueuePurchaseEvent(),
      }),
    onSuccess(rsp) {
      const { purchasedIds } = humps.camelizeKeys(rsp.data);

      trackerPersonalBusiness?.syncPendingPurchaseEvents(purchasedIds);
    },
    refetchInterval: 5 * 60 * 1000, // 5 minutes
  });
}
