import { UpdateDataStatusType, useGetMe } from '@api-hooks/common';
import {
  useDeleteUpdateDataDraft,
  useGetUpdateDataDraft,
  usePostUpdateData,
} from '@api-hooks/member';
import { css } from '@emotion/css';
import { usePermission } from '@hooks';
import { useGlobalQueueStore } from '@stores';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button } from '@topremit/shared-web/components/elements';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useRouter } from 'next/router';

import { UPDATE_DATA_DRAFT_KEY } from '../constants';
import { mapToUpdateFormDataPayload } from '../helper/mappers';
import { UpdateDataForm } from '../types';

const { tabletSm } = screenSize;

export default function ConfirmationLeaveModal({
  data,
}: {
  data: UpdateDataForm;
}) {
  const { isPersonalAccount } = usePermission();
  const { t } = useTranslation('profile');
  const { refetch: refetchUpdateData } = useGetUpdateDataDraft();
  const router = useRouter();
  const { next } = useGlobalQueueStore();
  const closeDialog = useDialogStore((store) => store.close);
  const { data: meData, refetch: refetchMe } = useGetMe({ enabled: false });
  const translationKey = `confirmation_leave_modal_${
    isPersonalAccount ? 'personal' : 'business'
  }`;
  const { addNotification } = useNotification();
  const showRejectedCopy =
    meData?.data?.updateData?.status === UpdateDataStatusType.REJECTED;

  const { mutate: mutateDeleteUpdateDataDraft } = useDeleteUpdateDataDraft({
    onSuccess: async (response) => {
      addNotification({ message: response.message, type: 'success' });
      closeDialog();
      await refetchMe();
      next();
      localStorage.removeItem(UPDATE_DATA_DRAFT_KEY);
      router.replace('/');
    },
  });
  const { mutate: saveDraft } = usePostUpdateData({
    onSuccess: async ({ message }) => {
      addNotification({ message, type: 'success' });
      await refetchMe();
      await refetchUpdateData();
      next();
      closeDialog();
      localStorage.removeItem(UPDATE_DATA_DRAFT_KEY);
      router.replace('/');
    },
  });

  function onClickSaveDraft() {
    saveDraft(mapToUpdateFormDataPayload({ ...data, saveAsDraft: true }));
  }
  function onClickCancel() {
    closeDialog();
  }

  async function onClickLeave() {
    const isRejected =
      meData?.data?.updateData?.status === UpdateDataStatusType.REJECTED;
    if (isRejected) {
      localStorage.removeItem(UPDATE_DATA_DRAFT_KEY);
      router.replace('/');
      closeDialog();
      return;
    }
    mutateDeleteUpdateDataDraft();
  }

  return (
    <div className={styled.root}>
      <h5>{t(`${translationKey}.title`)}</h5>
      <div className="description">
        {showRejectedCopy
          ? t(`${translationKey}.desc`)
          : t(`${translationKey}.desc_first_leave`)}
      </div>
      <div className="footer">
        <Button
          className="btn-confirmation"
          size="large"
          onClick={
            isPersonalAccount
              ? showRejectedCopy
                ? onClickLeave
                : onClickCancel
              : onClickSaveDraft
          }
        >
          {showRejectedCopy
            ? t(`${translationKey}.action1`)
            : t(`${translationKey}.action1_first_leave`)}
        </Button>
        <Button
          type="invert"
          size="large"
          className="btn-confirmation"
          onClick={
            isPersonalAccount && showRejectedCopy ? onClickCancel : onClickLeave
          }
        >
          {showRejectedCopy
            ? t(`${translationKey}.action2`)
            : t(`${translationKey}.action2_first_leave`)}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media (max-width: ${tabletSm}px) {
      padding-bottom: 1.25rem;
    }
    .description {
      margin-bottom: 1.5rem;
    }
    .footer {
      width: 100%;
      > :not(:last-child) {
        margin-bottom: 1rem;
      }
      .btn-confirmation {
        width: 100%;
      }
    }
  `,
};
