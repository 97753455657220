export enum AuthorizationRules {
  MenusWalletHistory = 'business.menus.wallet-history',
  MenusTopUp = 'business.menus.top-up',
  MenusWithdraw = 'business.menus.withdraw',
  MenusSendMoney = 'business.menus.send-money',
  MenusSingleTransaction = 'business.menus.single-transaction',
  MenusBatchTransaction = 'business.menus.batch-transaction',
  MenusAffiliate = 'business.menus.affiliate',
  MenusTransactionHistory = 'business.menus.transaction-history',
  MenuRecipientRequests = 'business.menus.recipient-requests',
  MenusSettings = 'business.menus.settings',
  MenusBankAccounts = 'business.menus.bank-accounts',
  MenusCompanyProfile = 'business.menus.company-profile',
  MenusManageMember = 'business.menus.manage-member',
  BankAccountsIndex = 'business.bank-accounts.index',
  BankAccountsStore = 'business.bank-accounts.store',
  BankAccountsDestroy = 'business.bank-accounts.destroy',
  MembersIndex = 'business.members.index',
  MembersShow = 'business.members.show',
  MembersStore = 'business.members.store',
  MembersUpdate = 'business.members.update',
  MembersDestroy = 'business.members.destroy',
  MembersDestroyMany = 'business.members.destroy-many',
  MembersReactivate = 'business.members.reactivate',
  MembersResendActivation = 'business.members.resend-activation',
  MembersRoles = 'business.members.roles',
  MemberRequestsIndex = 'business.member-requests.index',
  MemberRequestsStore = 'business.member-requests.store',
  MemberRequestsUpdateMany = 'business.member-requests.update-many',
  MemberRequestsActions = 'business.member-requests.actions',
  MemberRequestsHistory = 'business.member-requests.history',
  TopUpsIndex = 'business.top-ups.index',
  TopUpsShow = 'business.top-ups.show',
  TopUpsStore = 'business.top-ups.store',
  TopUpsCancel = 'business.top-ups.cancel',
  TopUpsConfirmPayment = 'business.top-ups.confirm-payment',
  TopUpsReceiptPdf = 'business.top-ups.receipt-pdf',
  WithdrawsIndex = 'business.withdraws.index',
  WithdrawsShow = 'business.withdraws.show',
  WithdrawsStore = 'business.withdraws.store',
  WalletsIndex = 'business.wallets.index',
  WalletsExport = 'business.wallets.export',
  TransactionsIndex = 'business.transactions.index',
  TransactionsShow = 'business.transactions.show',
  TransactionsStore = 'business.transactions.store',
  TransactionsExport = 'business.transactions.export',
  TransactionsUpdate = 'business.transactions.update',
  TransactionsApprove = 'business.transactions.approve',
  TransactionsApprovePayment = 'business.transactions.approve-payment',
  TransactionsRefresh = 'business.transactions.refresh',
  TransactionsReject = 'business.transactions.reject',
  TransactionsTrack = 'business.transactions.track',
  TransactionsInvoicePdf = 'business.transactions.invoice-pdf',
  TransactionsHistory = 'business.transactions.history',
  QuotationsStore = 'business.quotations.store',
  QuotationsShow = 'business.quotations.show',
  QuotationsUpdate = 'business.quotations.update',
  RecipientsIndex = 'business.recipients.index',
  RecipientsStore = 'business.recipients.store',
  RecipientsShow = 'business.recipients.show',
  RecipientsUpdate = 'business.recipients.update',
  RecipientsDestroy = 'business.recipients.destroy',
  RecipientsDestroyMany = 'business.recipients.destroy-many',
  RecipientsForm = 'business.recipients.form',
  TransactionBatchesIndex = 'business.transaction-batches.index',
  TransactionBatchesShow = 'business.transaction-batches.show',
  TransactionBatchesTemplate = 'business.transaction-batches.template',
  TransactionBatchesImport = 'business.transaction-batches.import',
  TransactionBatchesApprove = 'business.transaction-batches.approve',
  TransactionBatchesApprovePayment = 'business.transaction-batches.approve-payment',
  TransactionBatchesRefresh = 'business.transaction-batches.refresh',
  TransactionBatchesReject = 'business.transaction-batches.reject',
  TransactionBatchesInvoicePdf = 'business.transaction-batches.invoice-pdf',
  CompanyProfileIndex = 'business.company-profile.index',
  MenusTrackPayments = 'business.menus.track-payments',
  TrackPaymentsIndex = 'business.track-payments.index',
  TrackPaymentsShow = 'business.track-payments.show',
  TrackPaymentsExport = 'business.track-payments.export',
  RecipientRequestsIndex = 'business.recipient-requests.index',
  RecipientRequestsStore = 'business.recipient-requests.store',
  RecipientRequestsCheckEmail = 'business.recipient-requests.check-email',
  RecipientRequestsShow = 'business.recipient-requests.show',
  RecipientRequestsUpdate = 'business.recipient-requests.update',
  RecipientRequestsDestroyMany = 'business.recipient-requests.destroy-many',
  RecipientRequestsReinvite = 'business.recipient-requests.reinvite',
  NoAuthorization = 'noauth',
}
