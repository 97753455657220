import { SVGProps } from 'react';

import { color } from '../../styles/color';

const ShopFill = ({
  width = 24,
  height = 24,
  fill = color.neutral500,
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2330_9816)">
      <path
        d="M15.3333 12.8335C14.9452 13.351 14.4419 13.771 13.8634 14.0603C13.2848 14.3496 12.6468 14.5002 12 14.5002C11.3531 14.5002 10.7152 14.3496 10.1366 14.0603C9.55802 13.771 9.05476 13.351 8.66665 12.8335C8.34 13.2738 7.92897 13.6447 7.4575 13.9245C6.98604 14.2043 6.46359 14.3874 5.92062 14.4632C5.37765 14.539 4.82502 14.5059 4.29496 14.3659C3.76491 14.2258 3.26803 13.9817 2.83331 13.6477V17.8335C2.83464 18.9382 3.27405 19.9972 4.05516 20.7783C4.83628 21.5594 5.89532 21.9988 6.99998 22.0002H17C18.1046 21.9988 19.1637 21.5594 19.9448 20.7783C20.7259 19.9972 21.1653 18.9382 21.1666 17.8335V13.6452C20.7321 13.9793 20.2353 14.2236 19.7054 14.3639C19.1754 14.5041 18.6228 14.5374 18.0799 14.4618C17.5369 14.3863 17.0144 14.2034 16.5429 13.9238C16.0713 13.6443 15.6601 13.2736 15.3333 12.8335V12.8335Z"
        fill={fill}
      />
      <path
        d="M20.0833 4.60923C19.922 3.8668 19.5104 3.20239 18.9175 2.72736C18.3246 2.25232 17.5864 1.99554 16.8266 2.00006H16.1666V4.50006C16.1666 4.72107 16.0788 4.93303 15.9226 5.08931C15.7663 5.24559 15.5543 5.33339 15.3333 5.33339C15.1123 5.33339 14.9003 5.24559 14.7441 5.08931C14.5878 4.93303 14.5 4.72107 14.5 4.50006V2.00006H9.49998V4.50006C9.49998 4.72107 9.41218 4.93303 9.2559 5.08931C9.09962 5.24559 8.88766 5.33339 8.66665 5.33339C8.44563 5.33339 8.23367 5.24559 8.07739 5.08931C7.92111 4.93303 7.83331 4.72107 7.83331 4.50006V2.00006H7.17331C6.41347 1.99558 5.67518 2.25248 5.08225 2.72768C4.48932 3.20288 4.07778 3.86748 3.91665 4.61006L2.85165 9.41672L2.83331 10.3501C2.83441 10.6784 2.90016 11.0032 3.0268 11.3061C3.15345 11.609 3.33852 11.884 3.57144 12.1154C4.04184 12.5827 4.67861 12.8439 5.34165 12.8417C5.66995 12.8406 5.99483 12.7749 6.29772 12.6482C6.60062 12.5216 6.8756 12.3365 7.10697 12.1036C7.33834 11.8707 7.52157 11.5945 7.6462 11.2907C7.77083 10.987 7.83441 10.6617 7.83331 10.3334C7.83331 10.1124 7.92111 9.90042 8.07739 9.74414C8.23367 9.58786 8.44563 9.50006 8.66665 9.50006C8.88766 9.50006 9.09962 9.58786 9.2559 9.74414C9.41218 9.90042 9.49998 10.1124 9.49998 10.3334C9.49998 10.9964 9.76337 11.6323 10.2322 12.1012C10.7011 12.57 11.3369 12.8334 12 12.8334C12.663 12.8334 13.2989 12.57 13.7677 12.1012C14.2366 11.6323 14.5 10.9964 14.5 10.3334C14.5 10.1124 14.5878 9.90042 14.7441 9.74414C14.9003 9.58786 15.1123 9.50006 15.3333 9.50006C15.5543 9.50006 15.7663 9.58786 15.9226 9.74414C16.0788 9.90042 16.1666 10.1124 16.1666 10.3334C16.1666 10.9964 16.43 11.6323 16.8989 12.1012C17.3677 12.57 18.0036 12.8334 18.6666 12.8334C19.3297 12.8334 19.9656 12.57 20.4344 12.1012C20.9033 11.6323 21.1666 10.9964 21.1666 10.3334V9.58923L20.0833 4.60923Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2330_9653">
        <rect
          width={width}
          height={height}
          fill="white"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ShopFill;
