import { SVGProps } from 'react';

export interface UserProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const User = ({
  size = 24,
  fill = 'var(--neutral-500)',
  ...props
}: UserProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
      fill={fill}
    />
    <path
      d="M12 13.6667C10.0116 13.669 8.10518 14.4598 6.69914 15.8659C5.29309 17.2719 4.50221 19.1783 4.5 21.1667C4.5 21.3878 4.5878 21.5997 4.74408 21.756C4.90036 21.9123 5.11232 22.0001 5.33333 22.0001H18.6667C18.8877 22.0001 19.0996 21.9123 19.2559 21.756C19.4122 21.5997 19.5 21.3878 19.5 21.1667C19.4978 19.1783 18.7069 17.2719 17.3009 15.8659C15.8948 14.4598 13.9884 13.669 12 13.6667Z"
      fill={fill}
    />
  </svg>
);

export default User;
