import dynamic from 'next/dynamic';

export const AngleDouble = dynamic(() => import('./AngleDouble'));
export const BankTransfer = dynamic(() => import('./BankTransfer'));
export const BankOutline = dynamic(() => import('./BankOutline'));
export const Calendar = dynamic(() => import('./Calendar'));
export const Canceled = dynamic(() => import('./Canceled'));
export const CashPickup = dynamic(() => import('./CashPickup'));
export const Check = dynamic(() => import('./Check'));
export { default as Close } from './Close';
export const Copy = dynamic(() => import('./Copy'));
export const Copyright = dynamic(() => import('./Copyright'));
export const CrossCircle = dynamic(() => import('./CrossCircle'));
export const CubeFill = dynamic(() => import('./CubeFill'));
export const CubeOutline = dynamic(() => import('./CubeOutline'));
export const EWallet = dynamic(() => import('./EWallet'));
export const Exclamation = dynamic(() => import('./Exclamation'));
export const Eye = dynamic(() => import('./Eye'));
export const ExchangeArrow = dynamic(() => import('./ExchangeArrow'));
export const FaqFill = dynamic(() => import('./FaqFill'));
export const Home = dynamic(() => import('./Home'));
export const HomeDelivery = dynamic(() => import('./HomeDelivery'));
export const Info = dynamic(() => import('./Info'));
export const InfoOutline = dynamic(() => import('./InfoOutline'));
export const InstanTransfer = dynamic(() => import('./InstantTransfer'));
export const LanguageGlobe = dynamic(() => import('./LanguageGlobe'));
export const List = dynamic(() => import('./List'));
export const Minus = dynamic(() => import('./Minus'));
export const MinusSmallOutline = dynamic(() => import('./MinusSmallOutline'));
export const Pinpoint = dynamic(() => import('./Pinpoint'));
export const Plus = dynamic(() => import('./Plus'));
export const PlusSmallOutline = dynamic(() => import('./PlusSmallOutline'));
export const Point = dynamic(() => import('./Point'));
export { default as Refresh } from './Refresh';
export const ShieldCheck = dynamic(() => import('./ShieldCheck'));
export const ShutterButton = dynamic(() => import('./ShutterButton'));
export const SwiftLogo = dynamic(() => import('./SwiftLogo'));
export const SwitchCamera = dynamic(() => import('./SwitchCamera'));
export const Time = dynamic(() => import('./Time'));
export const TimeCheck = dynamic(() => import('./TimeCheck'));
export const TimeQuarterTo = dynamic(() => import('./TimeQuarterTo'));
export const TimeQuarterPast = dynamic(() => import('./TimeQuarterPast'));
export const Trash = dynamic(() => import('./Trash'));
export const TrackingIndicator = dynamic(() => import('./TrackingIndicator'));
export const TicketOutline = dynamic(() => import('./TicketOutline'));
export const Interrogation = dynamic(() => import('./Interrogation'));
export const WalletFill = dynamic(() => import('./WalletFill'));
export const WalletOutline = dynamic(() => import('./WalletOutline'));
export const ThumbsUpFill = dynamic(() => import('./ThumbsUpFill'));
export const ThumbsUpOutline = dynamic(() => import('./ThumbsUpOutline'));
export const ClockFill = dynamic(() => import('./ClockFill'));
export const ClockOutline = dynamic(() => import('./ClockOutline'));
export const MegaphoneFill = dynamic(() => import('./MegaphoneFill'));
export const MegaphoneOutline = dynamic(() => import('./MegaphoneOutline'));
export const EnvelopeFill = dynamic(() => import('./EnvelopeFill'));
export const EnvelopeOutline = dynamic(() => import('./EnvelopeOutline'));
export const ShareFill = dynamic(() => import('./ShareFill'));
export const ShareOutline = dynamic(() => import('./ShareOutline'));
export const UserAddFill = dynamic(() => import('./UserAddFill'));
export const UserAddOutline = dynamic(() => import('./UserAddOutline'));
export const TicketFill = dynamic(() => import('./TicketFill'));
export const WhatsappFill = dynamic(() => import('./WhatsappFill'));
export const WhatsappOutline = dynamic(() => import('./WhatsappOutline'));
export const MailFill = dynamic(() => import('./MailFill'));
export const MailOutline = dynamic(() => import('./MailOutline'));
export const HomeFill = dynamic(() => import('./HomeFill'));
export const HomeOutline = dynamic(() => import('./HomeOutline'));
export const PaperPlaneFill = dynamic(() => import('./PaperPlaneFill'));
export const PaperPlaneOutline = dynamic(() => import('./PaperPlaneOutline'));
export const GiftFill = dynamic(() => import('./GiftFill'));
export const GiftOutline = dynamic(() => import('./GiftOutline'));
export const ProfileFill = dynamic(() => import('./ProfileFill'));
export const ProfileOutline = dynamic(() => import('./ProfileOutline'));
export const InterrogationFill = dynamic(() => import('./InterrogationFill'));
export const SignOut = dynamic(() => import('./SignOut'));
export const Warning = dynamic(() => import('./Warning'));
export const InterrogationOutline = dynamic(
  () => import('./InterrogationOutline'),
);
export const Rate = dynamic(() => import('./Rate'));
export const HourglassOutline = dynamic(() => import('./HourglassOutline'));
export const Coin = dynamic(() => import('./Coin'));
export const DocumentFill = dynamic(() => import('./DocumentFill'));
export const ConfettiFill = dynamic(() => import('./ConfettiFill'));
export const Commission = dynamic(() => import('./Commission'));
export const ShareLink = dynamic(() => import('./ShareLink'));
export const ShareLinkId = dynamic(() => import('./ShareLinkId'));
export const OnTime = dynamic(() => import('./OnTime'));
export const Rocket = dynamic(() => import('./Rocket'));
export const FileDocument = dynamic(() => import('./FileDocument'));
export const UserDone = dynamic(() => import('./UserDone'));
export const IdBadgeOutline = dynamic(() => import('./IdBadgeOutline'));
export const MarkerOutline = dynamic(() => import('./MarkerOutline'));
export const Ellipse = dynamic(() => import('./Ellipse'));
export const FilterOutline = dynamic(() => import('./FilterOutline'));
export const StatsOutline = dynamic(() => import('./StatsOutline'));
export const Cashback = dynamic(() => import('./Cashback'));
export const Verified = dynamic(() => import('./Verified'));

export * from './logo';
// expected directly import
export { default as RadioIcon } from './RadioIcon';
export { default as RadioActive } from './RadioActive';
export { default as Search } from './Search';

// fill icons
export const AddFill = dynamic(() => import('./AddFill'));
export const AlarmClockFill = dynamic(() => import('./AlarmClockFill'));
export const AppsFill = dynamic(() => import('./AppsFill'));
export const BankFill = dynamic(() => import('./BankFill'));
export const BankFill2 = dynamic(() => import('./BankFill2'));
export const BagFill = dynamic(() => import('./BagFill'));
export const BellFill = dynamic(() => import('./BellFill'));
export const BookmarkFill = dynamic(() => import('./BookmarkFill'));
export const BoxFill = dynamic(() => import('./BoxFill'));
export const BuildingFill = dynamic(() => import('./BuildingFill'));
export const CalendarFill = dynamic(() => import('./CalendarFill'));
export const CameraFill = dynamic(() => import('./CameraFill'));
export const CheckBoxFill = dynamic(() => import('./CheckBoxFill'));
export const CoinFill = dynamic(() => import('./CoinFill'));
export const CommentFill = dynamic(() => import('./CommentFill'));
export const CopyFill = dynamic(() => import('./CopyFill'));
export const CopyrightFill = dynamic(() => import('./CopyrightFill'));
export const CrossCircleFill = dynamic(() => import('./CrossCircleFill'));
export const DashboardFill = dynamic(() => import('./DashboardFill'));
export const DelayFill = dynamic(() => import('./DelayFill'));
export const DeleteFill = dynamic(() => import('./DeleteFill'));
export const DollarCoinFill = dynamic(() => import('./DollarCoinFill'));
export const ExclamationFill = dynamic(() => import('./ExclamationFill'));
export const EyeCrossedFill = dynamic(() => import('./EyeCrossedFill'));
export const EyeFill = dynamic(() => import('./EyeFill'));
export const FlagFill = dynamic(() => import('./FlagFill'));
export const FlagFill2 = dynamic(() => import('./FlagFill2'));
export const FolderFill = dynamic(() => import('./FolderFill'));
export const HandshakeFill = dynamic(() => import('./HandshakeFill'));
export const HeartFill = dynamic(() => import('./HeartFill'));
export const IdBadgeFill = dynamic(() => import('./IdBadgeFill'));
export const InfoFill = dynamic(() => import('./InfoFill'));
export const LabelFill = dynamic(() => import('./LabelFill'));
export const LightningFill = dynamic(() => import('./LightningFill'));
export const LockFill = dynamic(() => import('./LockFill'));
export const MarkerFill = dynamic(() => import('./MarkerFill'));
export const MobileTopUpFill = dynamic(() => import('./MobileTopUpFill'));
export const ModeLandscapeFill = dynamic(() => import('./ModeLandscapeFill'));
export const ModePortraitFill = dynamic(() => import('./ModePortraitFill'));
export const PencilFill = dynamic(() => import('./PencilFill'));
export const PersonalUser = dynamic(() => import('./PersonalUser'));
export const PhoneFill = dynamic(() => import('./PhoneFill'));
export const PictureFill = dynamic(() => import('./PictureFill'));
export const PinFill = dynamic(() => import('./PinFill'));
export const PlayFill = dynamic(() => import('./PlayFill'));
export const PrintFill = dynamic(() => import('./PrintFill'));
export const ReceiptFill = dynamic(() => import('./ReceiptFill'));
export const RocketFill = dynamic(() => import('./RocketFill'));
export const SettingsSlidersFill = dynamic(
  () => import('./SettingsSlidersFill'),
);
export const SettingsFill = dynamic(() => import('./SettingsFill'));
export const ShieldCheckFill = dynamic(() => import('./ShieldCheckFill'));
export const ShieldExclamationFill = dynamic(
  () => import('./ShieldExclamationFill'),
);
export const ShieldInterrogationFill = dynamic(
  () => import('./ShieldInterrogationFill'),
);
export const ShieldPlusFill = dynamic(() => import('./ShieldPlusFill'));
export const ShieldFill = dynamic(() => import('./ShieldFill'));
export const ShoppingBagAddFill = dynamic(() => import('./ShoppingBagAddFill'));
export const ShoppingBagFill = dynamic(() => import('./ShoppingBagFill'));
export const ShoppingCartAddFill = dynamic(
  () => import('./ShoppingCartAddFill'),
);
export const ShoppingCartCheckFill = dynamic(
  () => import('./ShoppingCartCheckFill'),
);
export const ShoppingCartFill = dynamic(() => import('./ShoppingCartFill'));
export const SmartPhoneFill = dynamic(() => import('./SmartPhoneFill'));
export const StarFill = dynamic(() => import('./StarFill'));
export const StickerFill = dynamic(() => import('./StickerFill'));
export const ThumbsDownFill = dynamic(() => import('./ThumbsDownFill'));
export const TimeAddFill = dynamic(() => import('./TimeAddFill'));
export const TimeCheckFill = dynamic(() => import('./TimeCheckFill'));
export const TimeDeleteFill = dynamic(() => import('./TimeDeleteFill'));
export const TimeFastFill = dynamic(() => import('./TimeFastFill'));
export const TimeFastFill2 = dynamic(() => import('./TimeFastFill2'));
export const TimeHalfPastFill = dynamic(() => import('./TimeHalfPastFill'));
export const TimeOclockFill = dynamic(() => import('./TimeOclockFill'));
export const TimeQuarterPastFill = dynamic(
  () => import('./TimeQuarterPastFill'),
);
export const TimeQuarterToFill = dynamic(() => import('./TImeQuarterToFill'));
export const TransformFill = dynamic(() => import('./TransformFill'));
export const TrashFill = dynamic(() => import('./TrashFill'));
export const TrackingVerifyFill = dynamic(() => import('./TrackingVerifyFill'));
// export const UserAddFill = dynamic(() => import('./UserAddFill'));
export const UserFill = dynamic(() => import('./UserFill'));
export const UsersFill = dynamic(() => import('./UsersFill'));
export const TrophyFill = dynamic(() => import('./TrophyFill'));
export const UserDeleteFill = dynamic(() => import('./UserDeleteFill'));
export const UserRemoveFill = dynamic(() => import('./UserRemoveFill'));
export const VerifiedFill = dynamic(() => import('./VerifiedFill'));
export const VerifyFill = dynamic(() => import('./VerifyFill'));
export const VolumeFill = dynamic(() => import('./VolumeFill'));
export const WifiAltFill = dynamic(() => import('./WifiAltFill'));
export const ZoomInFill = dynamic(() => import('./ZoomInFill'));
export const ZoomOutFill = dynamic(() => import('./ZoomOutFill'));
export const DocumentPlusFill = dynamic(() => import('./DocumentPlusFill'));
export const DocumentScanFill = dynamic(() => import('./DocumentScanFill'));
export const CheckFill = dynamic(() => import('./CheckFill'));

export const AddOutline = dynamic(() => import('./AddOutline'));
export const AlarmClockOutline = dynamic(() => import('./AlarmClockOutline'));
export const AppsOutline = dynamic(() => import('./AppsOutline'));
export const BellOutline = dynamic(() => import('./BellOutline'));
export const BookmarkOutline = dynamic(() => import('./BookmarkOutline'));
export const BoxOutline = dynamic(() => import('./BoxOutline'));
export const BuildingOutline = dynamic(() => import('./BuildingOutline'));
export const CalendarOutline = dynamic(() => import('./CalendarOutline'));
export const CameraOutline = dynamic(() => import('./CameraOutline'));
export const CheckBoxOutline = dynamic(() => import('./CheckBoxOutline'));
export const ClipOutline = dynamic(() => import('./ClipOutline'));
export const CloudCheckOutline = dynamic(() => import('./CloudCheckOutline'));
export const CoinOutline = dynamic(() => import('./CoinOutline'));
export const ContainerOutline = dynamic(() => import('./ContainerOutline'));
export const CopyOutline = dynamic(() => import('./CopyOutline'));
export const CopyrightOutline = dynamic(() => import('./CopyrightOutline'));
export const CrossCircleOutline = dynamic(() => import('./CrossCircleOutline'));
export const DashboardOutline = dynamic(() => import('./DashboardOutline'));
export const DeleteOutline = dynamic(() => import('./DeleteOutline'));
export const DocumentOutline = dynamic(() => import('./DocumentOutline'));
export const DownloadOutline = dynamic(() => import('./DownloadOutline'));
export const ExclamationOutline = dynamic(() => import('./ExclamationOutline'));
export const ExpandOutline = dynamic(() => import('./ExpandOutline'));
export const EyeCrossedOutline = dynamic(() => import('./EyeCrossedOutline'));
export const EyeOutline = dynamic(() => import('./EyeOutline'));
export const FingerprintOutline = dynamic(() => import('./FingerprintOutline'));
export const FlagOutline = dynamic(() => import('./FlagOutline'));
export const FolderOutline = dynamic(() => import('./FolderOutline'));
export const GlobeOutline = dynamic(() => import('./GlobeOutline'));
export const GraduationCapOutline = dynamic(
  () => import('./GraduationCapOutline'),
);
export const HeartOutline = dynamic(() => import('./HeartOutline'));
export const LabelOutline = dynamic(() => import('./LabelOutline'));
export const LinkOutline = dynamic(() => import('./LinkOutline'));
export const LockOutline = dynamic(() => import('./LockOutline'));
export const ManageMemberOutline = dynamic(
  () => import('./ManageMemberOutline'),
);
export const ManageMemberFill = dynamic(() => import('./ManageMemberFill'));
export const MenuBurgerOutline = dynamic(() => import('./MenuBurgerOutline'));
export const MenuDotsOutline = dynamic(() => import('./MenuDotsOutline'));
export const ModeLandscapeOutline = dynamic(
  () => import('./ModeLandscapeOutline'),
);
export const ModePotraitOutline = dynamic(() => import('./ModePotraitOutline'));
export const PencilOutline = dynamic(() => import('./PencilOutline'));
export const PhoneOutline = dynamic(() => import('./PhoneOutline'));
export const PictureOutline = dynamic(() => import('./PictureOutline'));
export const PinOutline = dynamic(() => import('./PinOutline'));
export const PrintOutline = dynamic(() => import('./PrintOutline'));
export const ReceiptOutline = dynamic(() => import('./ReceiptOutline'));
export const RefreshOutline = dynamic(() => import('./RefreshOutline'));
export const RocketOutline = dynamic(() => import('./RocketOutline'));
export const ScaleOutline = dynamic(() => import('./ScaleOutline'));
export const SettingsOutline = dynamic(() => import('./SettingsOutline'));
export const ShieldOutline = dynamic(() => import('./ShieldOutline'));
export const SmartphoneOutline = dynamic(() => import('./SmartphoneOutline'));
export const TrackPaymentOutline = dynamic(
  () => import('./TrackPaymentOutline'),
);
export const TrackPaymentFill = dynamic(() => import('./TrackPaymentFill'));
export const TrashOutline = dynamic(() => import('./TrashOutline'));
export const TrophyOutline = dynamic(() => import('./TrophyOutline'));
export const UploadOutline = dynamic(() => import('./UploadOutline'));
export const UserOutline = dynamic(() => import('./UserOutline'));
export const UsersOutline = dynamic(() => import('./UsersOutline'));
export const VerifiedOutline = dynamic(() => import('./VerifiedOutline'));
export const VolumeOutline = dynamic(() => import('./VolumeOutline'));
export const WifiAltOutline = dynamic(() => import('./WifiAltOutline'));
export const WithdrawFill = dynamic(() => import('./WithdrawFill'));
export const WithdrawOutline = dynamic(() => import('./WithdrawOutline'));
export const ZoomInOutline = dynamic(() => import('./ZoomInOutline'));
export const ZoomOutOutline = dynamic(() => import('./ZoomOutOutline'));
export const StarOutline = dynamic(() => import('./StarOutline'));
export const Facebook = dynamic(() => import('./Facebook'));
export const Instagram = dynamic(() => import('./Instagram'));
export const LinkedIn = dynamic(() => import('./LinkedIn'));
export const Tiktok = dynamic(() => import('./Tiktok'));
export const Twitter = dynamic(() => import('./Twitter'));
export const Youtube = dynamic(() => import('./Youtube'));
export const PlayStore = dynamic(() => import('./PlayStore'));
export const Appstore = dynamic(() => import('./AppStore'));

export { default as Pencil } from './Pencil';
export { default as Lightning } from './Lightning';
export { default as CreditCardFill } from './CreditCardFill';
export { default as CreditCardOutline } from './CreditCardOutline';
export { default as InvestmentFill } from './InvestmentFill';
export { default as InvestmentOutline } from './InvestmentOutline';
export { default as ShopFill } from './ShopFill';
export { default as ShopOutline } from './ShopOutline';
export { default as TransactionFill } from './TransactionFill';
export { default as TransactionOutline } from './TransactionOutline';
export { default as User } from './User';
export { default as Users } from './Users';
export { default as ChatUsFill } from './ChatUsFill';
export { default as CommentOutline } from './CommentOutline';
export { default as Thunder } from './Thunder';
export { default as Arrow } from './Arrow';
export { default as Cross } from './Cross';
export { default as Angle } from './Angle';
