export function cloneButton(
  button: Element,
  props?: { onClick?: () => void; disabled?: boolean },
) {
  button.addEventListener('click', function () {
    props?.onClick?.();
  });
  if (props?.disabled) {
    button.setAttribute('disabled', '');
  } else {
    button.removeAttribute('disabled');
  }
}
