import { css, cx } from '@emotion/css';
import { screenSize } from '@global-common/size';
import { motion } from 'framer-motion';
import { ReactNode, useEffect, useRef, useState } from 'react';

import Flex from './Flex';

interface SegmentedTabsProps<T = string> {
  className?: string;
  fullWidth?: boolean;
  options: {
    label: ReactNode;
    value: T;
  }[];
  value?: T;
  onChange?: (value: T) => void;
  children?: (tab: T) => ReactNode;
}

/**
 * Segmented Tabs Component with Animated Background
 */
function SegmentedTabs<T = string>(props: SegmentedTabsProps<T>) {
  const {
    value,
    options,
    className,
    fullWidth = false,
    children,
    onChange,
  } = props;
  const buttonRefs = useRef<HTMLButtonElement[]>([]);
  const [selectedTab, setSelectedTab] = useState<T>(options[0]?.value);
  const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 });

  function handleChangeTab(value: T) {
    setSelectedTab(value);
    onChange?.(value);
  }

  useEffect(() => {
    if (value) {
      setSelectedTab(value);
    }
  }, [value]);

  useEffect(() => {
    const selectedIndex = options.findIndex((opt) => opt.value === selectedTab);
    const selectedButton = buttonRefs.current[selectedIndex];

    if (selectedButton) {
      setIndicatorStyle({
        width: selectedButton.offsetWidth,
        left: selectedButton.offsetLeft,
      });
    }
  }, [selectedTab, options]);

  return (
    <Flex column className={className}>
      <Flex className={cx(styled.tabs, { [styled.fullWidth]: fullWidth })}>
        <motion.div
          className={styled.indicator}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          animate={{ width: indicatorStyle.width, x: indicatorStyle.left }}
        />
        {options.map(({ label, value }, idx) => (
          <button
            key={idx}
            ref={(el) => {
              if (el) buttonRefs.current[idx] = el;
            }}
            onClick={() => handleChangeTab(value)}
            className={cx(styled.tab, {
              [styled.fullWidth]: fullWidth,
              [styled.tabActive]: value === selectedTab,
            })}
          >
            {label}
          </button>
        ))}
      </Flex>
      <Flex column className={styled.children}>
        {children?.(selectedTab)}
      </Flex>
    </Flex>
  );
}

const styled = {
  fullWidth: css`
    width: 100%;
  `,
  tabs: css`
    position: relative;
    padding: 4px;
    border-radius: 24px;
    background: var(--color-neutral-100);
    width: fit-content;
    display: flex;
  `,
  indicator: css`
    left: 0;
    top: 4px;
    bottom: 4px;
    position: absolute;
    border-radius: 100px;
    height: calc(100% - 8px);
    background: var(--color-blue-500);
  `,
  tab: css`
    align-items: center;
    color: var(--color-neutral-500);
    border-radius: 100px;
    padding: 8px 12px;
    position: relative;
    z-index: 1;
    justify-content: center;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 14px;
    }
  `,
  tabActive: css`
    color: var(--color-neutral-0);
    font-weight: var(--bold-font-weight);
  `,
  children: css`
    margin-top: 24px;
  `,
};

export default SegmentedTabs;
