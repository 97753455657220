import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { forwardRef, ReactNode, Ref } from 'react';

interface IDashboardContainer {
  children: ReactNode;
  className?: string;
}

const DashboardContainer = forwardRef(
  (props: IDashboardContainer, ref: Ref<HTMLInputElement>) => {
    const { children, className } = props;
    return (
      <div
        ref={ref}
        className={cx(styled.root, className, 'dashboard-container')}
      >
        {children}
      </div>
    );
  },
);

const styled = {
  root: css`
    @media (max-width: ${screenSize.tabletMd}px) {
      margin: 0;
    }
  `,
};

export default DashboardContainer;
