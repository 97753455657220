import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import Text from '@global-elements-utils/Text';
import { Searchbar } from '@topremit-ui/searchbar';
import { Select as SelectAntd } from 'antd';
import {
  forwardRef,
  Fragment,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';

import {
  callAllFunctions,
  isStringEmpty,
  mergeRefs,
  scrollToElement,
} from '../../../../common/helper';
import { screenSize } from '../../../../common/size';
import { useMeasure, useMediaQuery, useTranslation } from '../../../../hooks';
import { useDialogStore } from '../../../../stores';
import { color } from '../../../../styles/color';
import { Angle } from '../../../shapes';
import BolyNotFound from '../../supports/BolyNotFound';
import { ISelectInputProps } from '../types';
import BottomSheetSelectContent from './BottomSheetSelectContent';

const whiteListClassName = [
  'cross',
  'ant-input',
  'right-wrapper',
  'dropdown-item',
  'ant-select-item',
  'ant-select-item-group',
  'ant-select-selection-search-input',
];

const SelectInput = forwardRef((props: ISelectInputProps, ref: any) => {
  const { t } = useTranslation();

  const {
    id,
    name,
    label,
    value,
    options,
    disabled,
    className,
    helperText,
    allowClear,
    clickCount,
    searchLabel,
    defaultValue,
    virtual = true,
    initialValue,
    searchAutoFocus,
    notFoundContent,
    bordered = true,
    offset = [0, 55],
    withRadio = true,
    showArrow = true,
    listHeight = 328,
    stackBottomSheet,
    nonClickableOption,
    bottomSheetContent,
    showSearch = false,
    filterOption = true,
    bottomSheetClassName,
    inputWrapperClassName,
    disabledFocus = false,
    withBottomSheet = true,
    selectRef: selectRefProp,
    searchValue: _searchValue,
    autoCloseBottomSheet = true,
    autoCloseOnClickEnter = true,
    alwaysDialog,
    notFoundOption,

    onClick,
    onChange,
    onSearch,
    onKeyDown,
    onSelectItem,
    optionsRender,
    dropdownRender,
    onDropdownVisibleChange,

    ...resProps
  } = props;

  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const pushDialog = useDialogStore((store) => store.push);
  const popDialog = useDialogStore((store) => store.pop);
  const showDialog = useDialogStore((store) => store.show);

  const [searchValue, setSearchValue] = useState(_searchValue);
  const [isFocus, setIsFocus] = useState(false);
  /**
   * isActive state is used to prevent popDialog
   * from being called when we not focus on the select input itself
   *
   * The issue is when other dialog rather than the select input dialog is shown
   * and we resize the screen to mobile size, the dialog will be pop
   */
  const [isActive, setIsActive] = useState(false);

  const formContext = useFormContext();
  const { control } = formContext;

  const searchRef = useRef<any>(null);
  const selectRef = useRef<any>(null);
  const fieldGroupRef = useRef<any>();
  const wrapperRef = useRef<any>(null);
  const rightIconWrapperRef = useRef<any>(null);
  const bottomSheetOptionRef = useRef<any>(null);

  const wrapperBounds = useMeasure(wrapperRef);
  const rightIconWrapperBounds = useMeasure(rightIconWrapperRef);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: initialValue,
  });

  const isError = !!error;
  const errorMessage = error?.message;

  const isFilled = !!field.value || !!value;

  const _withBottomSheet = (isMobileSize || alwaysDialog) && withBottomSheet;

  const fieldGroupClass = cx(className, { disabled });
  const rightIconWrapperClass = cx(
    styled.rightIconWrapper,
    { disabled },
    'right-icon-wrapper',
  );
  const inputWrapperClass = cx(inputWrapperClassName, styled.dropdown(isFocus));
  const inputClass = cx(styled.selectInput, 'input-control', {
    error: isError,
  });

  const maxWidth =
    wrapperRef.current && rightIconWrapperRef.current
      ? (wrapperBounds.width - rightIconWrapperBounds.width) / 16
      : 0;

  const formControlClass = cx(
    'form-control',
    styled.formControl(Number(maxWidth)),
    styled.select,
    {
      focus: isFocus,
      filled: isFilled,
      error: isError,
      disabled,
    },
  );

  const _options = useMemo(() => {
    if (!options) {
      return [];
    }
    if (!showSearch || !searchValue || !filterOption) {
      return options;
    }

    if (searchValue) {
      const filteredOptions = options.filter((option) => {
        return option.label
          ?.toString()
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });

      // If no options match the search, add the "Other" option
      if (filteredOptions.length === 0 && notFoundOption?.value) {
        return [...filteredOptions, notFoundOption];
      }

      return filteredOptions;
    }
  }, [options, searchValue, showSearch, notFoundOption]);

  const _notFoundContent = notFoundContent || (
    <div className={styled.notFound} onClick={(e) => e.stopPropagation()}>
      <BolyNotFound width={180} height={180} />
      <Text as="span" maxSize="p" minSize="p" className="title">
        {t('common:not_found_options')}
      </Text>
      <Text
        as="span"
        maxSize="sm"
        minSize="sm"
        className="description secondary-text"
      >
        {t('common:try_other_keyword')}
      </Text>
    </div>
  );

  function handleChange(value: any, option: any) {
    if (!disabled && autoCloseOnClickEnter) {
      if (!(isMobileSize || alwaysDialog)) {
        /**
         * only close dropdown desktop
         */
        setIsFocus(false);
      }
      onChange?.(value, option);
      field.onChange(value, option);
    }
  }

  function handleClick(e) {
    if (!disabled && !disabledFocus) {
      if (
        !whiteListClassName.some(
          (className) =>
            String(e.target.className).includes(className) ||
            String(e.target.offsetParent?.className).includes(className),
        )
      ) {
        if (isFocus) {
          selectRef?.current.blur();
        } else {
          selectRef?.current.focus();
        }
        setIsFocus(!isFocus);
      }
    }
  }

  const handleOptionRender = useCallback(
    (option, info) => {
      if (!optionsRender?.(_options || [])) {
        return option.label;
      }
      if (isValidElement(optionsRender?.(_options || []))) {
        const {
          props: { children },
        } = optionsRender?.(_options || []) as any;

        if (children?.length === 0) {
          return children[info.index];
        }

        /** Comment because trigger unexpected behaviour calculator airtime */
        // if (isStringEmpty(children?.[info.index]?.props?.value)) {
        //   throw new Error('Use OptionWrapper component to wrap the option');
        // }

        return children[info.index];
      }

      throw new Error('optionsRender must return a valid React Element');
    },
    [_options],
  );

  function renderBottomSheetContent() {
    if (bottomSheetContent) {
      return bottomSheetContent;
    }

    function handleChange(value) {
      onChange?.(value, {});
      onSelectItem?.(value);
      field.onChange(value);
      if (autoCloseBottomSheet) {
        setIsFocus(false);
      }
      popDialog();
    }

    return (
      <BottomSheetSelectContent
        label={label}
        options={options}
        optionsRender={(options) => optionsRender?.(options || [])}
        value={field.value}
        virtual={virtual}
        showSearch={showSearch}
        searchValue={searchValue}
        notFoundContent={_notFoundContent}
        searchLabel={searchLabel || t('search_here')}
        searchAutoFocus={searchAutoFocus}
        onChange={handleChange}
        onSearch={onSearch}
        allowClear={allowClear}
        onClearClick={() => {
          setSearchValue('');
          onSearch?.('');
        }}
        dropdownRender={dropdownRender}
        notFoundOption={notFoundOption}
      />
    );
  }

  function _dropdownRender(menus) {
    return (
      <>
        {showSearch && (
          <div className={styled.searchBar}>
            <Searchbar
              ref={searchRef}
              value={searchValue || ''}
              placeholder={searchLabel || t('search_here')}
              onChange={(value) => {
                setSearchValue(value);
                onSearch?.(value);
              }}
              onClear={() => {
                setSearchValue('');
                onSearch?.('');
              }}
            />
          </div>
        )}
        {dropdownRender ? dropdownRender(menus) : menus}
        {!!nonClickableOption && nonClickableOption}
      </>
    );
  }

  function generateArrowColor() {
    if (disabled) {
      return color.neutral300;
    }
    if (error) {
      return color.red500;
    }
    return color.neutral500;
  }

  useImperativeHandle(ref, () => ({
    focus() {
      setIsFocus(true);
    },
    blur() {
      setIsFocus(false);
    },
    ref: fieldGroupRef.current,
  }));

  useEffect(() => {
    function handleClickOutside(event) {
      const desktopBlur =
        !(isMobileSize || alwaysDialog) &&
        wrapperRef.current &&
        !bottomSheetOptionRef.current &&
        !wrapperRef.current.contains(event.target) &&
        event.target.className !== 'rc-virtual-list-holder';

      const mobileBlur =
        (isMobileSize || alwaysDialog) &&
        bottomSheetOptionRef.current &&
        !bottomSheetOptionRef.current.contains(event.target);

      if (desktopBlur || mobileBlur) {
        if (isFocus) {
          setIsFocus(false);
        }
      }
    }
    document.addEventListener('mouseup', handleClickOutside);

    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [isFocus, isMobileSize, alwaysDialog]);

  useEffect(() => {
    if (isFocus && (isMobileSize || alwaysDialog)) {
      return pushDialog({
        className: cx(
          styled.bottomSheet(showSearch, stackBottomSheet),
          bottomSheetClassName,
          {
            hideRadio: !withRadio,
          },
        ),
        fullHeight: showSearch,
        body: renderBottomSheetContent(),
        onClose: () => {
          setIsFocus(false);
          popDialog();
        },
      });
    }
  }, [isFocus, clickCount, field.value, isMobileSize, alwaysDialog, virtual]);

  useEffect(() => {
    if (isFocus && (isMobileSize || alwaysDialog)) {
      return showDialog({
        className: cx(
          styled.bottomSheet(showSearch, stackBottomSheet),
          bottomSheetClassName,
          {
            hideRadio: !withRadio,
          },
        ),
        fullHeight: showSearch,
        body: renderBottomSheetContent(),
        onClose: () => {
          setIsFocus(false);
          popDialog();
        },
      });
    }
  }, [searchValue, bottomSheetContent, virtual]);

  useEffect(() => {
    if (!isFocus) {
      return;
    }

    const dropdown = document.querySelector(`.dropdown-${name}`);
    const rcVirtualHolder = dropdown?.querySelector('.rc-virtual-list-holder');
    const firstOption = document.querySelector(
      '.ant-select-item-option-selected',
    );

    if (rcVirtualHolder && firstOption) {
      scrollToElement(rcVirtualHolder, firstOption);
    }
  }, [isFocus]);

  useEffect(() => {
    if (
      !isFocus &&
      (isMobileSize || alwaysDialog) &&
      autoCloseBottomSheet &&
      isActive
    ) {
      popDialog();
      setIsActive(false);
    }
  }, [isFocus, isMobileSize, alwaysDialog]);

  useEffect(() => {
    if (isFocus) {
      selectRef?.current?.focus();
      setIsActive(true);
      return;
    }
    selectRef?.current?.blur();
    setIsActive(false);
  }, [isFocus]);

  useEffect(() => {
    if (isFocus && !(isMobileSize || alwaysDialog)) {
      setTimeout(() => {
        searchRef?.current?.focus();
      }, 50);
    }
    searchRef?.current?.blur();
  }, [isFocus, isMobileSize, alwaysDialog]);

  useEffect(() => {
    if (defaultValue) {
      field.onChange(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value) {
      field.onChange(value);
    }
  }, [value]);

  // Check if the value is exist in the options
  useEffect(() => {
    if (!_options || !field.value || !isStringEmpty(searchValue)) {
      return;
    }

    const isOptionsHasValueProp = _options.some((option) => option.value);
    if (!isOptionsHasValueProp) {
      return;
    }

    const isValueExist = _options.some(
      (option) => String(option.value) === String(field.value),
    );

    if (!isValueExist) {
      field.onChange('');
    }
  }, [field.value, _options, searchValue]);

  useEffect(() => {
    if (field.value && isFocus && autoCloseBottomSheet && !stackBottomSheet) {
      setIsFocus(false);
    }
  }, [field.value]);

  useEffect(() => {
    if (!onDropdownVisibleChange) {
      return;
    }
    onDropdownVisibleChange(isFocus);
  }, [isFocus]);

  useEffect(() => {
    if ((optionsRender?.(_options || []) as any)?.type === Fragment) {
      throw new Error(
        'optionsRender must return a valid Element and not Fragment',
      );
    }
    optionsRender?.(_options || []);
  }, [_options]);

  useEffect(() => {
    if (disabled) {
      setIsFocus(false);
    }
  }, [disabled]);

  return (
    <FieldGroup
      name={name}
      label={label}
      isError={isError}
      isFocus={isFocus}
      disabled={disabled}
      isFilled={isFilled}
      ref={fieldGroupRef}
      bordered={bordered}
      helper={helperText}
      error={errorMessage}
      showRightIcon={showArrow}
      className={fieldGroupClass}
      formControlProps={{
        className: formControlClass,
      }}
      style={{ zIndex: isFocus ? 10 : 'unset' }}
      inputWrapperClassName={inputWrapperClass}
      rightIconWrapperRef={rightIconWrapperRef}
      rightIconClassName={rightIconWrapperClass}
      inputWrapperRef={mergeRefs(wrapperRef, selectRefProp)}
      onClick={callAllFunctions(handleClick, onClick)}
    >
      <SelectAntd
        id={id}
        ref={selectRef}
        virtual={virtual}
        suffixIcon={null}
        options={_options}
        value={field.value}
        disabled={disabled}
        variant="borderless"
        className={inputClass}
        listHeight={listHeight}
        notFoundContent={_notFoundContent}
        popupClassName={`dropdown-${name}`}
        open={!_withBottomSheet && isFocus}
        getPopupContainer={() => wrapperRef.current}
        onChange={handleChange}
        optionRender={handleOptionRender}
        dropdownAlign={{
          offset: [offset[0] - 20, offset[1] - 40],
        }}
        dropdownRender={_dropdownRender}
        {...resProps}
      />

      {showArrow && (
        <div className="ant-input-suffix">
          <Angle
            width={24}
            height={24}
            size="small"
            direction="down"
            fill={generateArrowColor()}
          />
        </div>
      )}
    </FieldGroup>
  );
});

const styled = {
  dropdown: (isFocus: boolean) => css`
    cursor: pointer;
    .ant-input-suffix {
      position: absolute;
      top: 50%;
      right: -1.5rem;
      height: 1.5rem;
      transform: translateY(-50%) rotate(${isFocus ? '180deg' : '0deg'});
      transition: transform 0.3s ease;
    }
    #rc_select_1_list {
      display: none;
    }
  `,
  formControl: (maxWidth: number) => css`
    max-width: ${maxWidth}rem;
  `,
  select: css`
    transition: padding ease 0.2s;
    padding: 15px 20px 15px 20px;
    &.focus,
    &.filled {
      padding: 25px 20px 5px 20px;
    }
    > .ant-select {
      > .ant-select-selector {
        > .ant-select-selection-item {
          font-weight: var(--regular-font-weight);
          font-size: 1rem;
          @media (max-width: ${screenSize.tabletMd}px) {
            font-size: var(--sm-font-size);
          }
        }
      }
    }
  `,
  selectInput: css`
    background: transparent;
    font-size: var(--sm-font-size);
  `,
  rightIconWrapper: css`
    cursor: pointer;
    max-width: 3.375rem;
    min-width: 3.375rem;
  `,
  searchBar: css`
    padding: 1.5rem;
    .form-control {
      .ant-input-suffix {
        padding-left: unset;
        right: -1.5rem;
      }
    }
  `,
  bottomSheet: (showSearch: boolean, stackBottomSheet?: boolean) => css`
    &.hideRadio {
      .ant-radio-wrapper {
        display: none;
      }
    }
    .bottom-sheet {
      height: ${showSearch ? '100%' : 'auto'};
      padding-top: ${stackBottomSheet ? '0' : '1.5rem'};
    }
  `,
  notFound: css`
    padding: 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    > .title {
      color: var(--text-primary);
      font-weight: var(--bold-font-weight);
    }
    > .description {
      text-align: center;
      margin-bottom: 1.5rem;
    }
  `,
};

export default SelectInput;
