import { css } from '@emotion/css';
import { isEmptyObject, isStringEmpty } from '@global-common/helper';
import { CSSProperties, forwardRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import Pin from 'react-pin-input';

import ErrorMessage from './ErrorMessage';
import Flex from './Flex';
import { fontWeight } from '../../common/size';
import { color } from '../../styles/color';

/** react-pin-input does not export default types */
/** source:  https://github.com/arunghosh/react-pin-input/blob/master/typings/react-pin-input.d.ts*/
interface PinInputDefaultProps {
  length?: number;
  initialValue?: number | string;
  type?: 'numeric' | 'custom';
  inputMode?: 'text' | 'numeric';
  secret?: boolean;
  secretDelay?: number;
  disabled?: boolean;
  focus?: boolean;
  onChange?: (value: string, index: number) => void;
  onComplete?: (value: string, index: number) => void;
  style?: CSSProperties;
  inputStyle?: CSSProperties;
  inputFocusStyle?: CSSProperties;
  validate?: (value: string) => string;
  autoSelect?: boolean;
  regexCriteria?: any;
  ariaLabel?: string;
  placeholder?: string;
}

interface PinInputProps
  extends Omit<
    PinInputDefaultProps,
    'type' | 'style' | 'inputStyle' | 'inputFocusStyle' | 'setCurrentPin'
  > {
  mask?: boolean;
  name: string;
}

const PinInput = forwardRef((props: PinInputProps, ref) => {
  const {
    name,
    mask,
    length = 6,
    disabled = false,
    inputMode = 'numeric',
    ...resProps
  } = props;

  const { control } = useFormContext();

  const {
    field,
    formState: { errors },
  } = useController({ name, control, defaultValue: '' });

  const isError = !isEmptyObject(errors);
  const errorMessage = String(errors[name]?.message || '');

  return (
    <Flex column>
      <Pin
        ref={(e) => {
          field.ref(e);
          if (typeof ref === 'function') ref(e);
          else if (ref) ref.current = e;
        }}
        type="numeric"
        initialValue={field.value}
        length={length}
        inputMode={inputMode}
        focus={isError}
        onChange={(value: string) => field.onChange(value)}
        style={style}
        disabled={disabled}
        inputStyle={inputStyle(isError, disabled)}
        secretDelay={mask ? 300 : undefined}
        {...resProps}
      />
      {!isStringEmpty(errorMessage) && (
        <ErrorMessage className={styled.error}>{errorMessage}</ErrorMessage>
      )}
    </Flex>
  );
});

const style: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
};

const inputStyle = (isError: boolean, disabled: boolean): CSSProperties => ({
  border: 'unset',
  fontWeight: fontWeight.bold,
  borderBottom: `1px solid ${isError ? color.red500 : color.neutral200}`,
  width: `clamp(2.625rem, 2.625rem + 0vw, 2.625rem)`,
  height: `clamp(2.375rem, 2.375rem + 0vw, 2.375rem)`,
  color: disabled
    ? color.neutral300
    : isError
      ? color.red500
      : color.neutral900,
});

const styled = {
  error: css`
    margin-top: 16px;
    text-align: left;
  `,
};

export default PinInput;
