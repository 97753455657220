export const variants = {
  default: {
    padding: '15px 20px 15px 20px',
  },
  focus: {
    padding: '25px 20px 5px 20px',
  },
  filled: {
    padding: '25px 20px 5px 20px',
  },
};
