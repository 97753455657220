import { cx } from '@emotion/css';
import { Flex } from '@global-elements';

import { useChooseCountryCurrency } from './hooks/use-choose-country-currency';
import { styles } from './styles';

export default function ChooseCountryCurrencyContentWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const { isFocus, isHover, bordered, disabled, error } =
    useChooseCountryCurrency();

  return (
    <Flex
      className={cx(
        styles.root({
          isFocus,
          isHover,
          bordered,
          disabled,
          error: !!error,
        }),
        'choose-country-currency-trigger-wrapper',
      )}
    >
      {children}
    </Flex>
  );
}
