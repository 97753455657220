import { css, cx } from '@emotion/css';
import { getItemInCookies } from '@global-common/helper';
import { navbarSize, screenSize, zIndex } from '@global-common/size';
import { AnimatedWrapper } from '@global-elements';
import {
  ISidebarProviderValue,
  SidebarProvider,
  useMediaQuery,
  useMounted,
  useSidebar,
} from '@global-hooks';
import {
  Variants as AnimationVariants,
  m,
  LazyMotion,
  domAnimation,
} from 'framer-motion';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Children, useEffect, useState } from 'react';

import SideBarFooter from './SideBarFooter';
import SideBarHeader from './SideBarHeader';
import SideMenuGroup from './SideMenuGroup';
import SideMenuItem from './SideMenuItem';

const animationVariants: AnimationVariants = {
  expand: {
    width: '100%',
    maxWidth: '248px',
    minWidth: '248px',
    transition: { duration: 0.2 },
  },
  collapse: {
    width: '100%',
    maxWidth: '83px',
    minWidth: '83px',
    transition: { duration: 0.15 },
  },
};

function SidebarLogo() {
  const router = useRouter();
  const [{ isExpand }] = useSidebar();
  const accountType = getItemInCookies('account_type');

  function onClickLogo() {
    if (accountType === 'PERSONAL') {
      router.push('/home');
    } else {
      router.push('/profile');
    }
  }
  return (
    <div className={styled.logoWrapper}>
      {isExpand && (
        <AnimatedWrapper
          htmlTag="div"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className={styled.pointer}
          onClick={onClickLogo}
        >
          <Image
            priority
            src="/images/webp/topremit.webp"
            alt="topremit logo"
            width={127}
            height={22}
            placeholder="empty"
            loading="eager"
          />
        </AnimatedWrapper>
      )}
      {!isExpand && (
        <AnimatedWrapper
          htmlTag="div"
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.3,
            delay: 0.2,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className={styled.pointer}
          onClick={onClickLogo}
        >
          <Image
            src="/images/png/topremit-icon.png"
            alt="topremit logo"
            width={21}
            height={21}
            placeholder="empty"
            loading="eager"
          />
        </AnimatedWrapper>
      )}
    </div>
  );
}

const { desktopMd } = screenSize;

export default function SideBar({ children }) {
  const isScreenDesktop = useMediaQuery(`(min-width:${desktopMd}px)`);
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const sidebarContextBag: ISidebarProviderValue = [
    { isExpand },
    { setIsExpand },
  ];

  const isMounted = useMounted();

  const headerItemsNode = Children.map(children, (child) =>
    child?.type === SideBarHeader ? child : null,
  );

  const menuItemsNode = Children.map(children, (child) =>
    child?.type === SideMenuItem || child?.type === SideMenuGroup
      ? child
      : null,
  );

  const footerItemsNode = Children.map(children, (child) =>
    child?.type === SideBarFooter ? child : null,
  );

  useEffect(() => {
    if (isMounted && isScreenDesktop && !isExpand) {
      setIsExpand(isScreenDesktop);
    }
  }, [isMounted, isScreenDesktop]);

  return (
    <LazyMotion features={domAnimation}>
      <m.aside
        defaultValue={isScreenDesktop ? 'expand' : 'collapse'}
        className={cx(styled.wrapper, 'side-bar')}
        id="side-bar"
        variants={animationVariants}
        animate={isExpand ? 'expand' : 'collapse'}
      >
        <SidebarProvider value={sidebarContextBag}>
          <SidebarLogo />
          <nav className={styled.nav}>
            <ul className={styled.menu}>{headerItemsNode}</ul>
            <ul className={styled.scrollableMenu}>
              <ul className={cx(styled.menu, styled.menuList)}>
                {menuItemsNode}
              </ul>
              <ul className={cx(styled.menu, styled.footer)}>
                {footerItemsNode}
              </ul>
            </ul>
          </nav>
        </SidebarProvider>
      </m.aside>
    </LazyMotion>
  );
}

const styled = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: var(--neutral-0);
    border-right: 1px solid var(--neutral-200);
    z-index: ${zIndex.layout + 1};
  `,
  logoWrapper: css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${navbarSize.md}px;
    min-height: ${navbarSize.md}px;
    @media (max-width: ${screenSize.tabletMd}px) {
      height: ${navbarSize.sm}px;
      min-height: ${navbarSize.sm}px;
    }
  `,
  nav: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    flex-direction: column;
    ul {
      width: 100%;
    }
  `,
  scrollableMenu: css`
    height: calc(100vh - ${navbarSize.md}px - 5.0625rem);
    overflow-y: scroll;
    padding: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
      display: none;
    }
    @media (max-width: ${screenSize.tabletMd}px) {
      height: calc(100vh - ${navbarSize.sm}px - 5.0625rem);
    }
  `,
  menu: css`
    list-style-type: none;
    padding: 0;
    margin: 0;
  `,
  menuList: css`
    padding: 16px 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  footer: css`
    margin-top: auto;
    margin-bottom: 24px;
    flex-grow: 0;
    padding: 0 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  `,
  pointer: css`
    cursor: pointer;
  `,
};
