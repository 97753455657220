import {
  createContext,
  Dispatch,
  ProviderProps,
  SetStateAction,
  useContext,
} from 'react';

import { assertContextCalledInProvider } from '../common/assertion';

export interface ISideMenuGroupProviderValue {
  isActive: boolean;
  isExpand: boolean;
  setIsActive: Dispatch<SetStateAction<boolean>>;
  setIsExpand: Dispatch<SetStateAction<boolean>>;
}

export const SideMenuGroupContext = createContext<ISideMenuGroupProviderValue>({
  isActive: false,
  isExpand: false,
  setIsActive: () => {},
  setIsExpand: () => {},
});

export function SideMenuGroupProvider({
  children,
  value,
}: ProviderProps<ISideMenuGroupProviderValue>) {
  return (
    <SideMenuGroupContext.Provider value={value}>
      {children}
    </SideMenuGroupContext.Provider>
  );
}

export function useSideMenuGroup() {
  const ctx = useContext(SideMenuGroupContext);

  assertContextCalledInProvider<ISideMenuGroupProviderValue>(ctx);

  return ctx;
}
