import { css } from '@emotion/css';
import { Searchbar } from '@topremit-ui/searchbar';
import { Radio } from 'antd';
import { cloneElement, useCallback, useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';

import { ISelectInputProps } from '../types';

interface IBottomSheetContentProps {
  label: ISelectInputProps['label'];
  value?: ISelectInputProps['value'];
  options: ISelectInputProps['options'];
  virtual: ISelectInputProps['virtual'];
  allowClear?: ISelectInputProps['allowClear'];
  showSearch?: ISelectInputProps['showSearch'];
  searchValue?: ISelectInputProps['searchValue'];
  searchLabel?: ISelectInputProps['searchLabel'];
  searchAutoFocus?: ISelectInputProps['searchAutoFocus'];
  notFoundContent?: ISelectInputProps['notFoundContent'];
  notFoundOption?: ISelectInputProps['notFoundOption'];

  onChange: (value) => void;
  onClearClick?: () => void;
  onSearch?: ISelectInputProps['onSearch'];
  optionsRender?: ISelectInputProps['optionsRender'];
  dropdownRender?: ISelectInputProps['dropdownRender'];
}

const LIST_HEIGHT = 1000;
const ITEM_HEIGHT = 59;

function BottomSheetSelectContent(props: IBottomSheetContentProps) {
  const {
    value,
    label,
    options,
    showSearch,
    searchLabel,
    notFoundContent,
    virtual = true,
    searchValue: _searchValue,
    notFoundOption,
    dropdownRender,
    optionsRender,
    onClearClick,
    onSearch,
    onChange,
  } = props;

  const [searchValue, setSearchValue] = useState(_searchValue || '');

  const _options = useMemo(() => {
    if (!options) {
      return [];
    }
    if (!showSearch) {
      return options;
    }

    const filteredOptions = options.filter((option) => {
      return option.label
        ?.toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });

    // If no options match the search, add the "Other" option
    if (filteredOptions.length === 0 && notFoundOption) {
      return [...filteredOptions, notFoundOption];
    }

    return filteredOptions;
  }, [options, searchValue, showSearch, notFoundOption]);

  const hasCustomOptions = !!optionsRender?.(_options || []);

  function handleSearch(value: string) {
    setSearchValue(value);
    onSearch?.(value);
  }

  function handleClear() {
    setSearchValue('');
    onSearch?.('');
    onClearClick?.();
  }

  const Row = useCallback(
    ({ index, style }) => {
      const option = _options[index];
      const lastOption = index === _options.length - 1;

      return (
        <div
          style={style}
          key={option.value}
          className={styled.option(lastOption, true)}
          onClick={() => onChange(option.value)}
        >
          <span>{option.label}</span>
          <Radio checked={String(value) === String(option.value)} />
        </div>
      );
    },
    [_options, onChange, optionsRender, value],
  );

  function renderCustomOptions() {
    const {
      props: { children },
    } = optionsRender?.(_options || []) as any;

    return Object.entries(children).map(([_, child]: any, index) => {
      const lastOption = index === children.length - 1;

      return (
        <div
          key={child.props.value}
          className={styled.option(lastOption, false)}
          onClick={() => onChange(child.props.value)}
        >
          {cloneElement(child, {})}
          <Radio checked={String(value) === String(child.props.value)} />
        </div>
      );
    });
  }

  function renderContent() {
    const optionsExist = _options?.length > 0;
    const optionsContent = optionsExist ? (
      <div className={styled.optionsWrapper(showSearch)}>
        {hasCustomOptions ? (
          renderCustomOptions()
        ) : virtual ? (
          <List
            height={LIST_HEIGHT}
            itemCount={_options.length}
            itemSize={ITEM_HEIGHT}
            width="100%"
          >
            {Row}
          </List>
        ) : (
          _options.map((option, index) => {
            const lastOption = index === _options.length - 1;
            return (
              <div
                key={option.value}
                className={styled.option(lastOption, true)}
                onClick={() => onChange(option.value)}
              >
                <span>{option.label}</span>
                <Radio checked={String(value) === String(option.value)} />
              </div>
            );
          })
        )}
      </div>
    ) : (
      <div className={styled.optionsWrapper(showSearch)}>{notFoundContent}</div>
    );

    return dropdownRender ? dropdownRender(optionsContent) : optionsContent;
  }

  return (
    <div className={styled.contentWrapper}>
      <h5 className={styled.label}>{label}</h5>
      {showSearch && (
        <Searchbar
          value={searchValue}
          placeholder={searchLabel}
          className={styled.searchInput}
          onChange={handleSearch}
          onClear={handleClear}
        />
      )}
      {renderContent()}
    </div>
  );
}

const styled = {
  contentWrapper: css`
    display: flex;
    flex-direction: column;
    overflow-y: hidden !important;
  `,
  label: css`
    margin-top: 0;
    margin-bottom: 1.25rem;
  `,
  searchInput: css`
    margin: 0 0 0.5rem 0 !important;
  `,
  optionsWrapper: (showSearch) => css`
    height: ${showSearch
      ? 'calc(calc(var(--vh, 1vh) * 75) - calc(1rem + 1.575rem + 2.75rem + 3rem))'
      : 'auto'} !important; // 1rem padding top, 1.575rem label, 2.75rem search input
    display: flex;
    flex-direction: column;
    overflow-y: auto !important;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
  option: (lastOption, withPadding) => css`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    gap: 0.5rem;
    padding: ${withPadding ? '1.0625rem 0' : '0'};
    border-bottom: ${lastOption ? 'none' : '1px solid var(--neutral-200)'};
  `,
};

export default BottomSheetSelectContent;
