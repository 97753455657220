import { motion } from 'framer-motion';

import { angleVariant } from './constants';
import { Angle } from '../shapes';

interface Props {
  isExpand: boolean;
  fill?: string;
}

export default function AnimatedDropdownArrow({ isExpand, fill }: Props) {
  return (
    <motion.div
      animate={isExpand ? 'open' : 'close'}
      variants={angleVariant}
      className="arrow"
    >
      <Angle direction="down" size="small" fill={fill} />
    </motion.div>
  );
}
