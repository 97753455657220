import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

interface IAccordionItemProps {
  children?: ReactNode;
  onClick?: (...args) => any;
  value: any;
  className?: string;
}

const styled = {
  accordionItem: css`
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  `,
};

function AccordionItem(props: IAccordionItemProps) {
  const { children, value, onClick, className } = props;
  return (
    <div
      className={cx(styled.accordionItem, className)}
      onClick={() => onClick && onClick(value)}
    >
      {children}
    </div>
  );
}

export { AccordionItem };
