import { css } from '@emotion/react';

import { screenSize } from '../common/size';

const { mobileLg } = screenSize;

export default css`
  .ant-picker-dropdown {
    .ant-picker-date-panel .ant-picker-content th {
      color: var(--text-secondary);
    }
    .ant-picker-header-view button,
    .ant-picker-content th,
    .ant-picker-cell-in-view {
      color: var(--text-primary);
      &.ant-picker-cell-today .ant-picker-cell-inner::before {
        border-radius: 0.5rem;
        border: 1px solid var(--blue-500);
      }
      &.ant-picker-cell-range-start .ant-picker-cell-inner,
      :where(.css-dev-only-do-not-override-1wazalj).ant-picker-dropdown
        .ant-picker-cell-in-view.ant-picker-cell-range-end
        .ant-picker-cell-inner {
        background: var(--blue-500);
      }
      &.ant-picker-cell-range-end .ant-picker-cell-inner {
        background: var(--blue-500);
      }
    }
    .ant-picker-cell-disabled::before {
      background: var(--neutral-100);
    }
  }

  .ant-picker {
    font-family: var(--font-family);
    color: var(--text-primary);
    .ant-picker-input {
      > input {
        font-family: var(--font-family);
        color: var(--text-primary);
        font-size: 1rem;
      }
    }
    > div:not(:last-child) {
      margin-right: 0.5rem;
    }
    &.ant-picker-range {
      > .ant-picker-active-bar {
        margin-left: 0;
        display: none;
      }
    }
    .ant-picker-suffix,
    .ant-picker-range-separator {
      width: fit-content;
      padding: 0;
      margin: 0;
      > * {
        min-height: 1rem;
        min-width: 1rem;
      }
    }
  }

  @media (max-width: ${mobileLg}px) {
    .ant-picker {
      > .ant-picker-input {
        > input {
          font-size: 0.875rem;
        }
      }
    }
  }
`;
