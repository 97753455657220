import { format, isValid, parse } from 'date-fns';
import DOMPurify from 'dompurify';
import { useRouter } from 'next/router';
import { ParsedUrlQuery, ParsedUrlQueryInput } from 'querystring';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { isEmptyObject } from '../common/helper';

enum FilterPeriodOptions {
  THIS_MONTH = 'THIS_MONTH',
  LAST_90_DAYS = 'LAST_90_DAYS',
  CUSTOM_DATE = 'CUSTOM_DATE',
}

// if you want to prevent certain keys from being added in filter params
const EXCLUDED_KEYS = ['refid', 'id'];

// if you want to prevent certain keys from being counted in the filter count, add it here
const EXCLUDED_COUNT_KEYS = ['tab', 'date'];

const COUNT_FILTER_KEYS = [
  'status',
  'types',
  'service',
  'period',
  'activity',
  'roles',
  'sort',
  'date',
  'type',
];

// if you want the params returned as array always include it here
const ARRAY_KEY_PARAM = [
  'service',
  'type',
  'types',
  'status',
  'date',
  'roles',
  'status',
];

function getFilterCount<T extends object>(filters: T) {
  return Object.keys(filters).reduce((curr, key) => {
    const value = filters[key];
    if (EXCLUDED_COUNT_KEYS.includes(key)) {
      return curr;
    }
    // Check if the value is an array or string with a length property
    if (
      !EXCLUDED_COUNT_KEYS.includes(key) &&
      COUNT_FILTER_KEYS.includes(key) &&
      (Array.isArray(value) || typeof value === 'string') &&
      value.length > 0
    ) {
      return curr + 1;
    }
    return curr;
  }, 0);
}

export function getUrlValue(filter: ParsedUrlQuery): ParsedUrlQueryInput {
  const result: ParsedUrlQueryInput = {
    createdAtAfter: '',
    createdAtBefore: '',
  };

  for (const key in filter) {
    if (EXCLUDED_KEYS.includes(key)) {
      continue;
    }

    const value = filter[key];

    if (ARRAY_KEY_PARAM.includes(key)) {
      if (typeof value === 'string' && value.length) {
        result[key] = value.split(',').map((item) => {
          return key === 'date' && item.length
            ? parse(item, 'yyyy-MM-dd', new Date())
            : DOMPurify.sanitize(item.trim());
        });
      } else {
        result[key] = [];
      }
    } else {
      if (typeof value === 'string') {
        result[key] = DOMPurify.sanitize(value);
      } else {
        result[key] = '';
      }
    }
  }

  const date = result?.date;
  const DATE_FORMAT = 'yyyy-MM-dd z';

  if (isValid(date?.[0]) && isValid(date?.[1])) {
    result.createdAtAfter =
      date?.[0] && isValid(date?.[0]) && format(date[0], DATE_FORMAT);
    result.createdAtBefore =
      date?.[1] && isValid(date?.[1]) && format(date[1], DATE_FORMAT);
    result.date = '';
  }

  return result;
}

interface IFilterContext<T> {
  filterValue: T;
  filterCount: number;
  resetFilter: () => void;
  setFilterValue: (val: Partial<T>) => void;
  setFilterCount: (val: number) => void;
  syncUrlToFilterValue: () => void;
}

interface FilterValue {
  [key: string]: string | string[] | Date[];
}
type FormValues<T> = {
  [K in keyof T]: T[K];
};

const FilterContext = createContext({});

export function FilterProvider({ children }) {
  const router = useRouter();
  const [filterValue, setFilterValue] = useState<FilterValue>({});

  function resetFilter() {
    setFilterValue({});
  }

  const syncUrlToFilterValue = () => {
    if (
      isEmptyObject(router.query) ||
      getUrlValue(router.query) !== filterValue
    ) {
      setFilterValue({});
    }
  };

  function storeUrl(query: FilterValue) {
    const mappedQuery: ParsedUrlQueryInput = {};

    for (const [key, value] of Object.entries(query)) {
      if (key === 'date') {
        if (Array.isArray(value)) {
          mappedQuery[key] = value
            .map((date: string | Date) => format(new Date(date), 'yyyy-MM-dd'))
            .join(',');
        }
      } else if (Array.isArray(value) && value.length) {
        mappedQuery[key] = value.join(',');
      } else if (typeof value === 'string') {
        mappedQuery[key] = value;
      }
    }

    return mappedQuery;
  }

  useEffect(() => {
    if (!isEmptyObject(filterValue)) {
      const excludedKeys = ['createdAtAfter', 'createdAtBefore'];

      const nonEmptyQuery = Object.fromEntries(
        Object.entries(storeUrl(filterValue)).filter(
          ([key, value]) => value !== '' && !excludedKeys.includes(key),
        ),
      );

      router.replace(
        {
          pathname: router.pathname,
          query: nonEmptyQuery,
        },
        undefined,
        {
          shallow: true,
        },
      );
    }
  }, [JSON.stringify(filterValue)]);

  function handleFilter(val: FilterValue) {
    let payload = val;
    const { date, period } = payload ?? {};
    const DAYS_90_IN_MILLIS = 7776000000; // 90 days in milliseconds

    const todayDate = new Date();
    const date90DaysAgo = new Date(todayDate.getTime() - DAYS_90_IN_MILLIS);
    const firstDayOfMonth = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      1,
    );
    const DATE_FORMAT = 'yyyy-MM-dd z';
    const createdAtAfter = date?.[0] && format(date[0] as Date, DATE_FORMAT);
    const createdAtBefore = date?.[1] && format(date[1] as Date, DATE_FORMAT);

    if (period) {
      switch (period) {
        case FilterPeriodOptions.LAST_90_DAYS:
          payload = { ...payload, date: [date90DaysAgo, todayDate] };
          break;
        case FilterPeriodOptions.THIS_MONTH:
          payload = { ...payload, date: [firstDayOfMonth, todayDate] };
          break;
        case FilterPeriodOptions.CUSTOM_DATE:
          if (date?.length === 0) {
            payload = { ...payload, period: '' };
            break;
          }
          payload = { ...payload };
          break;
        default:
          payload = { ...payload };
      }
    }

    setFilterValue({ createdAtAfter, createdAtBefore, ...payload });
  }
  const memoizedFilterCount = useMemo(() => {
    return isEmptyObject(filterValue) && typeof window !== 'undefined'
      ? getFilterCount(router.query)
      : 0;
  }, [router.query]);

  const memoizedFilterValue = useMemo(() => {
    return isEmptyObject(filterValue) && typeof window !== 'undefined'
      ? getUrlValue(router.query)
      : {};
  }, [router.query]);

  return (
    <FilterContext.Provider
      value={{
        filterCount: memoizedFilterCount,
        filterValue: memoizedFilterValue,
        setFilterValue: (val: FilterValue) => {
          handleFilter(val);
        },
        syncUrlToFilterValue,
        resetFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useFilter<T>(defaultValues?: Partial<T>) {
  const router = useRouter();
  // this form method is to handle temp logic before apply filter
  const filterFormMethod = useForm<FormValues<Partial<T>>>();
  const context = useContext(FilterContext) as IFilterContext<Partial<T>>;
  const { reset, watch } = filterFormMethod;
  const { filterValue, syncUrlToFilterValue } = context;

  useEffect(() => {
    if (defaultValues && isEmptyObject(context.filterValue)) {
      context.setFilterValue(defaultValues);
    }
  }, [defaultValues]);

  function getDateBeforeAfter(date?: Date[]) {
    if (!date?.length) return { createdAtAfter: '', createdAtBefore: '' };
    const DATE_FORMAT = 'yyyy-MM-dd z';
    const createdAtAfter = date?.[0] && format(date[0], DATE_FORMAT);
    const createdAtBefore = date?.[1] && format(date[1], DATE_FORMAT);

    return { createdAtAfter, createdAtBefore };
  }

  useEffect(() => {
    if (filterValue !== watch()) {
      reset(filterValue);
    }
  }, [filterValue]);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      syncUrlToFilterValue();
    };
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { ...context, filterFormMethod, getDateBeforeAfter };
}
