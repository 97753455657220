import { css, cx } from '@emotion/css';
import React, { forwardRef, PropsWithChildren } from 'react';

import { screenSize } from '../../common/size';

interface Props {
  className?: string;
}

const { tabletSm, desktopLg } = screenSize;

const styled = {
  container: css`
    margin: 0 auto;
    width: 100%;
    max-width: 72.5rem;
    @media (max-width: ${desktopLg}px) {
      max-width: unset;
      padding: 0 2.5rem;
    }

    @media (max-width: ${tabletSm}px) {
      padding: clamp(1.5rem, 0.0741 * 100vw - 2.7px, 2.5rem);
    }
  `,
};

const Container = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, className }, ref) => {
    return (
      <div ref={ref} className={cx('container', styled.container, className)}>
        {children}
      </div>
    );
  },
);

export { Container };
