import * as React from 'react';
import { SVGProps } from 'react';
const Pencil = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      fill="none"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fill="#8295B5"
          d="M13.032.972a2.014 2.014 0 0 0-2.847 0L1.15 10.007a2.76 2.76 0 0 0-.814 1.964v1.14a.556.556 0 0 0 .556.556h1.14a2.76 2.76 0 0 0 1.964-.813l9.036-9.036a2.015 2.015 0 0 0 0-2.846ZM3.21 12.068a1.679 1.679 0 0 1-1.178.488h-.585v-.585a1.655 1.655 0 0 1 .488-1.178l6.858-6.857 1.277 1.277-6.86 6.855Zm9.036-9.036-1.392 1.393L9.576 3.15l1.393-1.393a.903.903 0 0 1 1.275 1.278l.002-.003Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.336.336h13.333v13.333H.336z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Pencil;
