import { AccountRole, useGetMe, UserBusiness } from '@api-hooks/common';
import {
  useActivateAutoWithdraw,
  useGetWalletBalance,
  useSkipAutoWithdraw,
} from '@api-hooks/wallet';
import { TIMELINE_FULL_DATE_FORMAT } from '@common/constant';
import { Language } from '@topremit/shared-web/api-hooks/common';
import { formatDate } from '@topremit/shared-web/common/helper';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';

export enum AutoWithdrawStatusModal {
  OWNER_EXIST_CLIENT_BELOW_30 = 'OWNER_EXIST_CLIENT_AND_BELOW_30_DAY',
  OWNER_EXIST_CLIENT_PAST_30 = 'OWNER_EXIST_CLIENT_AND_PAST_30_DAY',
  RELEASER_EXIST_CLIENT_BELOW_30 = 'RELEASER_EXIST_CLIENT_AND_BELOW_30_DAY',
  RELEASER_EXIST_CLIENT_PAST_30 = 'RELEASER_EXIST_CLIENT_AND_PAST_30_DAY',
  NEW_CLIENT = 'NEW_CLIENT',
}

export default function useAutoWithdraw() {
  const { lang } = useTranslation();
  const { addNotification } = useNotification();
  const { data: me } = useGetMe<UserBusiness>();
  const { data: walletBalance } = useGetWalletBalance();
  const { mutateAsync: mutateSkipAutoWithdraw } = useSkipAutoWithdraw({
    onError: ({ message }) => {
      addNotification({ message, type: 'danger', closeable: false });
    },
  });
  const { mutateAsync: mutateActivateAutoWithdraw } = useActivateAutoWithdraw({
    onError: ({ message }) => {
      addNotification({ message, type: 'danger', closeable: false });
    },
  });

  const isActiveAutoWithdraw = me?.data.autoWithdrawal?.autoWithdrawActivated;
  const isNewClient = me?.data?.autoWithdrawal?.isNewClient;
  const isShowAutoWithdraw = me?.data.autoWithdrawal?.isShowable;
  const isOwner = me?.data.roles?.includes(AccountRole.OWNER);
  const isReleaser =
    me?.data.roles?.includes(AccountRole.RELEASER) &&
    !me?.data.roles?.includes(AccountRole.OWNER);
  //Note : if auto withdraw date is null , it mean the user pass 30 days not activated auto withdraw yet
  const isEmptyAutoWithdrawalDate =
    me?.data.autoWithdrawal?.activateAutoWithdrawalDateBefore === null;
  const autoWithdrawDate = isEmptyAutoWithdrawalDate
    ? ''
    : formatDate({
        date: me?.data.autoWithdrawal?.activateAutoWithdrawalDateBefore || '',
        formatDate: TIMELINE_FULL_DATE_FORMAT,
        locale: lang as Language,
      });
  const upcomingAutoWithdrawDate =
    formatDate({
      date: walletBalance?.data.upcomingAutoWithdrawal || '',
      formatDate: TIMELINE_FULL_DATE_FORMAT,
      locale: lang as Language,
    }) || '-';
  const isBalanceBelowMinimum = (walletBalance?.data.balance || 0) < 10000;

  function getStatusAutoWithdrawModal(): AutoWithdrawStatusModal | null {
    if (isNewClient) {
      return AutoWithdrawStatusModal.NEW_CLIENT;
    }

    if (isOwner) {
      return isEmptyAutoWithdrawalDate
        ? AutoWithdrawStatusModal.OWNER_EXIST_CLIENT_PAST_30
        : AutoWithdrawStatusModal.OWNER_EXIST_CLIENT_BELOW_30;
    }

    if (isReleaser) {
      return isEmptyAutoWithdrawalDate
        ? AutoWithdrawStatusModal.RELEASER_EXIST_CLIENT_PAST_30
        : AutoWithdrawStatusModal.RELEASER_EXIST_CLIENT_BELOW_30;
    }
    return null;
  }

  return {
    isNewClient,
    isOwner,
    isReleaser,
    isEmptyAutoWithdrawalDate,
    isActiveAutoWithdraw,
    isShowAutoWithdraw,
    isBalanceBelowMinimum,
    autoWithdrawDate,
    upcomingAutoWithdrawDate,
    getStatusAutoWithdrawModal,
    skipAutoWithdraw: mutateSkipAutoWithdraw,
    activateAutoWithdraw: mutateActivateAutoWithdraw,
  };
}
