import { css, cx } from '@emotion/css';
import { forwardRef, useState } from 'react';

import { AnimatedWrapper } from './animated-wrapper';
import {
  isNativeApp,
  sendNativeMessage,
} from '../../common/native-web-view-bridge';
import { useNotification, useTimeout, useTranslation } from '../../hooks';
import { NativeWebViewBridgeEventName } from '../../typings/native-web-view-bridge.model';
import { Copy } from '../shapes';

const styled = {
  button: css`
    padding: 4px 10px;
    border: 1px solid var(--neutral-200);
    border-radius: 0.875rem;
    display: inline-flex;
    color: var(--text-secondary);
  `,
  pointer: css`
    cursor: pointer;
    display: flex;
  `,
  onClick: css`
    opacity: 0.5;
  `,
};

interface ICopyButton {
  type?: 'button' | 'icon';
  size?: number;
  data: string;
  label: string;
  iconColor?: string;
  className?: string;
  children?: JSX.Element;
  closeableToast?: boolean;
  onClick?: () => void;
}

const CopyButton = forwardRef((props: ICopyButton, ref: any) => {
  const {
    type = 'button',
    data,
    className,
    size,
    label,
    iconColor,
    closeableToast = false,
    onClick: onClickProps,
  } = props;

  const [onClick, setOnClick] = useState<boolean>(false);
  const { setTimeout } = useTimeout();
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  function copyText(e: React.MouseEvent) {
    e?.stopPropagation();

    if (typeof data !== 'string') {
      console.error('Data to copy must be a string');
      return;
    }

    // add onclick props to handle additional action from outside
    onClickProps && onClickProps();

    // Note: set click state to trigger animation/visual feedback
    setOnClick(true);

    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.COPY_TO_CLIPBOARD,
        data: { message: data },
      });

      sendNativeMessage({
        name: NativeWebViewBridgeEventName.ALERT,
        data: {
          message: t('copied', {
            label,
          }),
          variant: 'success',
        },
      });
    } else {
      if (!navigator.clipboard) {
        console.error('Clipboard API not supported in this browser');
        return;
      }
      try {
        navigator.clipboard.writeText(data);
        addNotification({
          message: t('copied', {
            label,
          }),
          type: 'success',
          closeable: closeableToast,
        });
      } catch (error) {
        console.error('Clipboard write failed:', error);
        addNotification({
          message: t('failed_copied', {
            label,
          }),
          type: 'danger',
          closeable: closeableToast,
        });
      }
    }

    setTimeout(() => {
      setOnClick(false);
    }, 200);
  }

  const buttonClass = cx(
    'sm-text',
    styled.pointer,
    styled.button,
    onClick && styled.onClick,
    className,
  );
  const iconClass = cx(styled.pointer, onClick && styled.onClick, className);

  switch (type) {
    case 'button':
      return (
        <AnimatedWrapper
          htmlTag="div"
          ref={ref}
          className={buttonClass}
          onClick={copyText}
        >
          {t('copy')}
        </AnimatedWrapper>
      );
    case 'icon':
      return (
        <AnimatedWrapper
          htmlTag="div"
          ref={ref}
          onClick={copyText}
          className={iconClass}
        >
          {props.children ?? (
            <Copy
              size={size || 16}
              type={onClick ? 'fill' : 'outline'}
              fill={iconColor}
            />
          )}
        </AnimatedWrapper>
      );
  }
});

export { CopyButton };
