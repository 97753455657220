import { css, cx } from '@emotion/css';
import { parseHtml } from '@topremit/shared-web/components/elements';
import getConfig from 'next/config';
import Image from 'next/image';

const { publicRuntimeConfig } = getConfig();

export interface IListItemProps {
  type?: 'primary' | 'secondary';
  content: string;
}

export default function ListItem({
  type = 'primary',
  content,
}: IListItemProps) {
  return (
    <div className={styled.root}>
      <div className="img-wrapper">
        <Image
          src={`${
            publicRuntimeConfig?.staticFolder
              ? publicRuntimeConfig?.staticFolder
              : ''
          }/images/svg/bullet-point.svg`}
          width={8}
          height={8}
          alt="bullet point"
        />
      </div>
      <div
        className={cx('content sm-text', {
          secondary: type === 'secondary',
        })}
      >
        <span>{parseHtml(content)}</span>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
    .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 0.5rem;
      height: 19.6px;
      min-width: 19.6px;
    }
    .secondary {
      color: var(--text-secondary);
    }
  `,
};
