import { css } from '@emotion/css';
import { ButtonHTMLAttributes, ReactNode } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: ReactNode;
  onClick?: () => void;
}

export default function IconButton({ icon, onClick, ...props }: Props) {
  return (
    <button className={styled.root} onClick={onClick} {...props}>
      {icon}
    </button>
  );
}

const styled = {
  root: css`
    cursor: pointer;
    width: fit-content;
    background: unset;
    border: unset;
    outline: unset;
    :disabled {
      cursor: default;
      opacity: 0.4;
    }
  `,
};
