import { css, cx } from '@emotion/css';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { useDropdownAccordion } from '../../../hooks';
import { accordionExpandVariants } from '../constants';

interface IDropdownAccordionList {
  id: string;
  totalItem?: number;
  className?: string;
  children: ReactNode;
}

export default function DropdownAccordionList({
  id,
  totalItem,
  className,
  children,
}: IDropdownAccordionList) {
  const { selectedId } = useDropdownAccordion();
  const isExpand = selectedId === id;
  const animateAccordion = isExpand ? 'expand' : 'collapse';

  return (
    <motion.div
      initial="collapse"
      animate={animateAccordion}
      variants={accordionExpandVariants}
      className={cx(styled.root, className)}
    >
      <div className={styled.itemWrapper(totalItem || 0)}>{children}</div>
    </motion.div>
  );
}

const styled = {
  root: css`
    padding: 0.5rem 0;
    cursor: pointer;
  `,
  itemWrapper: (totalItem: number) => css`
    display: grid;
    gap: 0.5rem;
    grid-template-columns: ${totalItem > 4 ? '1fr 1fr' : '1fr'};
    grid-template-rows: ${totalItem > 4 ? 'repeat(4, auto)' : 'auto'};
    .dropdown-item {
      cursor: pointer;
      align-items: center;
      padding: 0.5rem 1rem;
      transition: all 0.2s ease;
      border-radius: 1rem;
      &.selected,
      &:hover {
        background: var(--blue-100);
      }
    }
    .link {
      color: var(--text-primary);
    }
  `,
};
