import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { handleClickOutside } from '../common/helper';

interface DropdownContextProps {
  ref: any;
  isOpen: boolean;
  selectedValue: string;
  onHover: () => void;
  onLeave: () => void;
  onChangeValue: (value: string) => void;
}

const DropdownContext = createContext({});

export function DropdownProvider({ children }) {
  const ref = useRef<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<string>();

  function onHover() {
    setIsOpen(true);
  }

  function onLeave() {
    setIsOpen(false);
  }

  function onChangeValue(value: string) {
    setSelectedValue(value);
  }

  useEffect(() => {
    const handleBlur = (e) => {
      handleClickOutside(ref, isOpen, setIsOpen, e);
    };

    document.addEventListener('mouseup', handleBlur);

    return () => document.removeEventListener('mouseup', handleBlur);
  }, [isOpen]);

  return (
    <DropdownContext.Provider
      value={{
        ref,
        isOpen,
        selectedValue,
        onHover,
        onLeave,
        onChangeValue,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
}

export function useDropdown() {
  return useContext(DropdownContext) as DropdownContextProps;
}
