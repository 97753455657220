import { useRouter } from 'next/router';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { assertContextCalledInProvider } from '../common/assertion';

type IBottombarProviderValue = [
  {
    activePath: string;
  },
  {
    setActivePath: Dispatch<SetStateAction<string>>;
  },
];

const BottombarContext = createContext<IBottombarProviderValue | null>(null);

export function BottombarProvider({ children }) {
  const router = useRouter();
  const [activePath, setActivePath] = useState(router.pathname);

  const bottombarContextBag: IBottombarProviderValue = useMemo(
    () => [{ activePath }, { setActivePath }],
    [activePath],
  );

  useEffect(() => {
    setActivePath(router.asPath);
  }, [router.asPath]);

  return (
    <BottombarContext.Provider value={bottombarContextBag}>
      {children}
    </BottombarContext.Provider>
  );
}

export function useBottombar() {
  const ctx = useContext<IBottombarProviderValue | null>(BottombarContext);

  assertContextCalledInProvider<IBottombarProviderValue>(ctx);

  return ctx;
}
