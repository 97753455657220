import { useGetAccountInfo } from '@api-hooks/common';
import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import {
  ApiError,
  ApiPaginationResult,
  ApiResult,
} from '@topremit/shared-web/api-hooks/api.model';
import { DEFAULT_TABLE_LIMIT } from '@topremit/shared-web/common/constants';
import { formatDate } from '@topremit/shared-web/common/helper';
import { UseQueryOptions } from 'react-query';

import {
  BankAccounts,
  PaymentMethods,
  TopUpDetail,
  TopupFilterCount,
  TopupTable,
  TopupTableParams,
  WalletBalance,
  WalletHistoryTable,
  WalletHistoryTableParams,
  WithdrawalDetail,
  WithdrawalFilterCount,
  WithdrawalTable,
} from './wallet.model';

export function useGetWalletBalance(
  options: UseQueryOptions<ApiResult<WalletBalance>, ApiError> = {},
) {
  return useCustomQuery<WalletBalance>(
    ['wallet', 'balance'],
    async () => await requestFn({ path: 'wallets/balance', method: 'get' }),
    {
      enabled: false,
      ...options,
    },
    { hasAccountId: true, hasLang: false },
  );
}

export function useGetTopupTable(
  {
    page = 1,
    limit = DEFAULT_TABLE_LIMIT,
    params,
  }: {
    page?: number;
    limit?: number;
    params?: TopupTableParams;
  },
  options: UseQueryOptions<ApiPaginationResult<TopupTable>, ApiError> = {},
) {
  const { data: accountInfo } = useGetAccountInfo();
  const response = useCustomQuery<
    TopupTable,
    ApiError,
    ApiPaginationResult<TopupTable>
  >(
    ['top-ups', { page, limit, ...params }],
    async () =>
      await requestFn(
        {
          path: 'top-ups',
          method: 'get',
        },
        {
          searchParams: {
            page,
            limit,
            ...(params?.search && {
              [`filter[search]`]: `${params.search}`,
            }),
            ...(params?.status && {
              [`filter[status]`]: `${params.status}`,
            }),
            ...(params?.createdAtAfter && {
              [`filter[created_at_after]`]: `${formatDate({
                date: params.createdAtAfter,
                timeZone: accountInfo?.data.timezone,
                showTimeZoneName: true,
              })}`,
            }),
            ...(params?.createdAtBefore && {
              [`filter[created_at_before]`]: `${formatDate({
                date: params.createdAtBefore,
                timeZone: accountInfo?.data.timezone,
                showTimeZoneName: true,
              })}`,
            }),
          },
        },
      ),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
  return response;
}

export function useGetTopupFiltersCount(
  options: UseQueryOptions<ApiResult<TopupFilterCount[]>, ApiError> = {},
) {
  return useCustomQuery<TopupFilterCount[]>(
    ['top-ups', 'filter-count'],
    async () =>
      await requestFn({
        path: 'top-ups/filter-count',
        method: 'get',
      }),
    {
      ...options,
    },
    { hasLang: false, hasAccountId: true },
  );
}

export function useGetPaymentMethods(
  options: UseQueryOptions<ApiResult<PaymentMethods>, ApiError> = {},
) {
  const response = useCustomQuery<PaymentMethods>(
    ['top-ups', 'payment-methods'],
    async () =>
      await requestFn({
        path: 'top-ups/payment-methods',
        method: 'get',
      }),
    {
      ...options,
    },
    { hasAccountId: true },
  );
  return response;
}

export function useGetTopUpDetail(
  params?: { id: string },
  options: UseQueryOptions<ApiResult<TopUpDetail>, ApiError> = {},
) {
  const response = useCustomQuery<TopUpDetail>(
    ['top-ups', 'topup-detail', params?.id],
    async () =>
      await requestFn({
        path: `top-ups/${params?.id}`,
        method: 'get',
      }),
    {
      enabled: false,
      ...options,
    },
  );
  return response;
}

export function useGetWithdrawTable(
  {
    page = 1,
    limit = DEFAULT_TABLE_LIMIT,
    params,
  }: {
    page?: number;
    limit?: number;
    params?: TopupTableParams;
  },
  options: UseQueryOptions<ApiPaginationResult<WithdrawalTable>, ApiError> = {},
) {
  const { data: accountInfo } = useGetAccountInfo();
  const response = useCustomQuery<
    WithdrawalTable,
    ApiError,
    ApiPaginationResult<WithdrawalTable>
  >(
    [
      'withdraws',
      {
        page,
        limit,
        ...params,
      },
    ],
    async () =>
      await requestFn(
        {
          path: 'withdraws',
          method: 'get',
        },
        {
          searchParams: {
            page,
            limit,
            ...(params?.search && {
              [`filter[search]`]: `${params.search}`,
            }),
            ...(params?.status && {
              [`filter[status]`]: `${params.status}`,
            }),
            ...(params?.createdAtAfter && {
              [`filter[created_at_after]`]: `${formatDate({
                date: params.createdAtAfter,
                timeZone: accountInfo?.data.timezone,
                showTimeZoneName: true,
              })}`,
            }),
            ...(params?.createdAtBefore && {
              [`filter[created_at_before]`]: `${formatDate({
                date: params.createdAtBefore,
                timeZone: accountInfo?.data.timezone,
                showTimeZoneName: true,
              })}`,
            }),
          },
        },
      ),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
  return response;
}

export function useGetWithdrawFiltersCount(
  options: UseQueryOptions<ApiResult<WithdrawalFilterCount[]>, ApiError> = {},
) {
  const response = useCustomQuery<WithdrawalFilterCount[]>(
    ['withdraws', 'filter-count'],
    async () =>
      await requestFn({
        path: 'withdraws/filter-count',
        method: 'get',
      }),
    {
      ...options,
    },
    { hasLang: false, hasAccountId: true },
  );
  return response;
}

export function useGetBankAccounts(
  options: UseQueryOptions<ApiResult<BankAccounts[]>, ApiError> = {},
) {
  const response = useCustomQuery<BankAccounts[]>(
    ['me', 'bank-accounts'],
    async () =>
      await requestFn(
        {
          path: 'me/bank-account/details',
          method: 'get',
        },
        { searchParams: { type: 'withdraw' } },
      ),
    {
      ...options,
    },
    { hasLang: false, hasAccountId: true },
  );
  return response;
}

export function useGetWithdrawDetail(
  params?: { id: string },
  options: UseQueryOptions<ApiResult<WithdrawalDetail>, ApiError> = {},
) {
  const response = useCustomQuery<WithdrawalDetail>(
    ['withdraws', 'withdraws-detail', params?.id],
    async () =>
      await requestFn({
        path: `withdraws/${params?.id}`,
        method: 'get',
      }),
    {
      enabled: false,
      ...options,
    },
  );
  return response;
}

export function useGetWalletHistoryTable(
  {
    page = 1,
    limit = DEFAULT_TABLE_LIMIT,
    params,
  }: {
    page?: number;
    limit?: number;
    params?: WalletHistoryTableParams;
  },
  options: UseQueryOptions<
    ApiPaginationResult<WalletHistoryTable>,
    ApiError
  > = {},
) {
  const { data: accountInfo } = useGetAccountInfo();
  const response = useCustomQuery<
    WalletHistoryTable,
    ApiError,
    ApiPaginationResult<WalletHistoryTable>
  >(
    ['wallet-history', { page, limit, ...params }],
    async () =>
      await requestFn(
        {
          path: 'wallets',
          method: 'get',
        },
        {
          searchParams: {
            page,
            limit,
            ...(params?.search && {
              [`filter[search]`]: `${params.search}`,
            }),
            ...(params?.types && {
              [`filter[types]`]: `${params.types}`,
            }),
            ...(params?.createdAtAfter && {
              [`filter[created_at_after]`]: `${formatDate({
                date: params.createdAtAfter,
                timeZone: accountInfo?.data.timezone,
                showTimeZoneName: true,
              })}`,
            }),
            ...(params?.createdAtBefore && {
              [`filter[created_at_before]`]: `${formatDate({
                date: params.createdAtBefore,
                timeZone: accountInfo?.data.timezone,
                showTimeZoneName: true,
              })}`,
            }),
          },
        },
      ),
    {
      ...options,
    },
    { hasLang: true },
  );
  return response;
}
