import { css, cx } from '@emotion/css';
import { Divider } from '@global-elements';
import getConfig from 'next/config';
import Image from 'next/image';

import SpeedInfo from './SpeedInfo';
import { ISpeedInfoCardProps } from './type';

const { publicRuntimeConfig } = getConfig();

function SpeedInfoCard({
  currentFee,
  currentSpeed,
  currentSpeedTitle,
  currentSpeedDescription,

  suggestedFee,
  suggestedSpeed,
  suggestedSpeedTitle,
  suggestedSpeedDescription,
}: ISpeedInfoCardProps) {
  const circleArrowUrl = `${
    publicRuntimeConfig?.staticFolder ? publicRuntimeConfig?.staticFolder : ''
  }/images/svg/circle-background-arrow.svg`;

  return (
    <div className={cx(styled.root, 'card card-secondary')}>
      <SpeedInfo
        fee={currentFee}
        speed={currentSpeed}
        title={currentSpeedTitle}
        arrivalTime={currentSpeedDescription}
      />
      <div className="dashed-divider">
        <Divider variant="dashed" />
        <Image
          width={24}
          height={24}
          src={circleArrowUrl}
          className="arrow-circle"
          alt="circle-background-arrow"
        />
      </div>
      <SpeedInfo
        fee={suggestedFee}
        speed={suggestedSpeed}
        title={suggestedSpeedTitle}
        arrivalTime={suggestedSpeedDescription}
      />
    </div>
  );
}

const styled = {
  root: css`
    padding: 1rem;
    .dashed-divider {
      position: relative;
      display: flex;
      margin: 1.25rem 0;
      justify-content: center;
      .arrow-circle {
        position: absolute;
        top: -11.5px;
      }
    }
  `,
};

export default SpeedInfoCard;
