import { css } from '@emotion/css';
import { ReactNode } from 'react';

interface IBottomMenuLabelProps {
  children?: ReactNode;
}

export default function BottomMenuLabel({ children }: IBottomMenuLabelProps) {
  return <span className={styled.label}>{children}</span>;
}

const styled = {
  label: css`
    margin-top: 4px;
    font-size: var(--xs-font-size);
    font-size: clamp(
      var(--xs-font-size),
      0.022727272727272707rem + 3.6363636363636362vw,
      var(--sm-font-size)
    );
  `,
};
