import { DependencyList, useEffect, useRef } from 'react';

import { isDeepEqual } from '../common/object';

/**
 * use-shallow-effect works exactly like useEffect but performs shallow dependencies comparison instead of referential comparison
 * source: https://mantine.dev/hooks/use-shallow-effect/
 */

function shallowCompare(prevValue: DependencyList, currValue: DependencyList) {
  if (!prevValue || !currValue) {
    return false;
  }

  if (prevValue === currValue) {
    return true;
  }

  if (prevValue.length !== currValue.length) {
    return false;
  }

  for (let i = 0; i < prevValue.length; i += 1) {
    if (!isDeepEqual(prevValue[i] as object, currValue[i] as object)) {
      return false;
    }
  }

  return true;
}

function useShallowCompare(dependencies: DependencyList) {
  const ref = useRef<DependencyList>([]);
  const updateRef = useRef<number>(0);

  if (!shallowCompare(ref.current, dependencies)) {
    ref.current = dependencies;
    updateRef.current += 1;
  }

  return [updateRef.current];
}

export default function useShallowEffect(
  cb: () => void,
  dependencies?: DependencyList,
): void {
  useEffect(cb, useShallowCompare(dependencies as any));
}
