import { css, cx } from '@emotion/css';
import { BadgeProps } from 'antd';
import { CSSProperties, useEffect, useState } from 'react';

import { callAllFunctions } from '../../../common/helper';
import { screenSize } from '../../../common/size';
import { Badge } from '../Badge';
import { AnimatedWrapper } from '../animated-wrapper';

export interface ITabsProps extends Omit<BadgeProps, 'size'> {
  id?: string;
  current?: string;
  className?: string;
  style?: CSSProperties;
  tabItemClassName?: string;
  tabItemStyle?: CSSProperties;
  tabItemWrapperStyle?: CSSProperties;
  /**
   * @param {'left' | 'center' | 'right'} [position] left;
   */
  position?: 'left' | 'center' | 'right';
  items: {
    label: string;
    key?: string;
    href?: string;
    name: string;
    hasBadge?: boolean;
    className?: string;
    badgeValue?: string;
    dot?: boolean;
    size?: number;
  }[];
  type?: 'default' | 'button';
  gap?: number;
  onClick?: (name: string) => void;
  /**
   *
   * The value of disabledTab must be the index of tabItem
   *
   * @param {string[]} [disabledTab] ['0', '2', ...]
   * @param {number[]} [disabledTab] [0, 2, ...]
   * @param {string} [disabledTab] '0'
   * @param {number} [disabledTab] 0
   */
  disabledTabs?: string[] | string | number | number[];
}

const styled = {
  tabWrapper: (gap: number) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${gap}rem;
  `,
  tabItemWrapper: (disabled: boolean) => css`
    cursor: ${!disabled ? 'pointer' : 'not-allowed'};
    position: relative;
    color: ${!disabled ? 'var(--text-secondary)' : 'var(--text-disabled)'};
    padding: 0.75rem 0.625rem;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  tabItem: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1rem !important;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.875rem !important;
    }
  `,
  active: css`
    color: var(--blue-500);
  `,
  underline: css`
    position: absolute;
    bottom: 0;
    height: 2px;
    display: block;
    background-color: var(--blue-500);
  `,
  left: css`
    justify-content: flex-start;
  `,
  center: css`
    justify-content: center;
  `,
  right: css`
    justify-content: flex-end;
  `,
  buttonType: (active: boolean) => css`
    padding: 0.5rem 0.75rem;
    border-radius: 6.25rem;
    box-sizing: border-box;
    border: ${!active && '1px solid var(--neutral-200)'};
    background-color: ${active && 'var(--blue-100)'};
  `,
};

const underlineVariants = {
  activeTab: {
    width: '100%',
    left: '0',
  },
  inactiveTab: {
    width: '0',
    left: '50%',
  },
};

const tabTitleVariants = {
  activeTab: {
    fontWeight: 700,
  },
  inactiveTab: {
    fontWeight: 500,
  },
};

function Tabs(props: ITabsProps) {
  const {
    items,
    position = 'left',
    current = '',
    style,
    tabItemStyle,
    tabItemClassName,
    onClick,
    className,
    id,
    disabledTabs,
    type = 'default',
    gap = 0.5,
  } = props;

  const [active, setActive] = useState(current);

  const tabItemClass = (name: string) => {
    return cx(styled.tabItem, {
      'bold-text': name.includes(active),
      'regular-text': !name.includes(active),
    });
  };
  const tabWrapperClass = cx(styled.tabWrapper(gap), className);
  const tabItemWrapperClass = ({
    name,
    disabled,
  }: {
    name: string;
    disabled: boolean;
  }) =>
    cx(
      styled.tabItemWrapper(disabled),
      name.includes(active) && styled.active,
      styled[position],
      tabItemClassName,
    );

  function handleClick(name: string) {
    callAllFunctions(onClick, setActive)(name);
  }

  function checkDisabled(index) {
    if (typeof disabledTabs === 'string' || typeof disabledTabs === 'number') {
      return String(index).includes(String(disabledTabs));
    } else if (Array.isArray(disabledTabs)) {
      return (disabledTabs as any[]).some((item) =>
        String(item).includes(String(index)),
      );
    }
    return false;
  }

  useEffect(() => {
    setActive(current);
  }, [current]);

  return (
    <div id={id} style={style} className={tabWrapperClass}>
      {items.map(
        ({ name, label, hasBadge, badgeValue, className, dot, size }, index) =>
          hasBadge ? (
            <Badge key={name} count={badgeValue} size={size} dot={dot}>
              <div
                style={tabItemStyle}
                className={cx(
                  tabItemWrapperClass({
                    name,
                    disabled: checkDisabled(index),
                  }),
                  className,
                  {
                    [styled.buttonType(name.includes(active))]:
                      type === 'button',
                  },
                )}
                onClick={() => {
                  if (!checkDisabled(index)) {
                    handleClick(name);
                  }
                }}
                key={name}
                role="button"
              >
                <AnimatedWrapper
                  htmlTag="span"
                  animate={name.includes(active) ? 'activeTab' : 'inactiveTab'}
                  variants={tabTitleVariants}
                  className={tabItemClass(name)}
                >
                  {label}
                </AnimatedWrapper>

                <AnimatedWrapper
                  htmlTag="div"
                  animate={name.includes(active) ? 'activeTab' : 'inactiveTab'}
                  variants={underlineVariants}
                  className={styled.underline}
                />
              </div>
            </Badge>
          ) : (
            <div
              style={tabItemStyle}
              className={cx(
                tabItemWrapperClass({
                  name,
                  disabled: checkDisabled(index),
                }),
                className,
                {
                  [styled.buttonType(name.includes(active))]: type === 'button',
                },
              )}
              onClick={() => {
                if (!checkDisabled(index)) {
                  handleClick(name);
                }
              }}
              key={name}
              role="button"
            >
              <AnimatedWrapper
                htmlTag="span"
                animate={name.includes(active) ? 'activeTab' : 'inactiveTab'}
                variants={tabTitleVariants}
                className={tabItemClass(name)}
              >
                {label}
              </AnimatedWrapper>

              {type === 'default' && (
                <AnimatedWrapper
                  htmlTag="div"
                  animate={name.includes(active) ? 'activeTab' : 'inactiveTab'}
                  variants={underlineVariants}
                  className={styled.underline}
                />
              )}
            </div>
          ),
      )}
    </div>
  );
}

export { Tabs };
