export const angleVariant = {
  open: {
    rotate: 180,
  },
  close: {
    rotate: 0,
  },
};

export const dropdownVariant = {
  open: {
    opacity: 1,
    display: 'block',
  },
  close: {
    opacity: 0,
    display: 'none',
  },
};

export const accordionArrowVariants = {
  expand: {
    transform: 'rotate(-180deg)',
    transition: {
      duration: 0.2,
    },
  },
  collapse: {
    transform: 'rotate(0deg)',
    transition: {
      duration: 0.2,
    },
  },
};

export const accordionExpandVariants = {
  expand: {
    height: 'auto',
    display: 'block',
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  collapse: {
    height: 0,
    display: 'none',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
};
