import Router from 'next/router';
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

interface Props {
  children: ReactNode;
}

const LoadingContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([false, () => {}]);

function LoadingProvider({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onLoad() {
    if (!isLoading) {
      setIsLoading(true);
    }
  }

  function onDone() {
    setIsLoading(false);
  }

  useEffect(() => {
    Router.events.on('routeChangeStart', onLoad);

    Router.events.on('routeChangeComplete', onDone);

    Router.events.on('routeChangeError', () => onDone);

    return () => {
      Router.events.off('routeChangeStart', () => onLoad);
      Router.events.off('routeChangeComplete', () => onDone);
      Router.events.off('routeChangeError', () => onDone);
    };
  }, []);

  return (
    <LoadingContext.Provider value={[isLoading, setIsLoading]}>
      {children}
    </LoadingContext.Provider>
  );
}

const useLoading = () => {
  return useContext(LoadingContext);
};

export { LoadingProvider, useLoading };
