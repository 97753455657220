import { css, cx } from '@emotion/css';
import { Button, parseHtml } from '@topremit/shared-web/components/elements';
import Image, { StaticImageData } from 'next/image';
import { ReactNode } from 'react';

export interface IInfoShortModalProps {
  title?: string;
  description?: string | null;
  onClick?: () => void;
  buttonLabel?: string;
  imgSrc?: string | StaticImageData;
  children?: ReactNode;
  className?: string;
  imgStyle?: {
    width?: number;
    height?: number;
  };
}

export default function BlockingModal(props: IInfoShortModalProps) {
  const {
    title,
    description,
    onClick,
    buttonLabel,
    imgSrc,
    children,
    className,
    imgStyle,
  } = props;
  return (
    <div className={cx(styled.root, className)}>
      {!children && imgSrc && (
        <div className="image-title">
          <Image
            alt="icon modal header"
            src={imgSrc}
            width={imgStyle?.width || 32}
            height={imgStyle?.height || 32}
          />
        </div>
      )}
      <div
        className={cx('content', {
          [styled.contentChildren]: !!children,
        })}
      >
        {title && <h5>{parseHtml(title)}</h5>}
        {description && (
          <span className="description">{parseHtml(description ?? '')}</span>
        )}
        {children}
      </div>
      {buttonLabel && (
        <Button onClick={onClick} className="button">
          {parseHtml(buttonLabel)}
        </Button>
      )}
    </div>
  );
}
const styled = {
  root: css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    .image-title {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 2.3125rem;
      margin-bottom: 1rem;
    }
    .content {
      text-align: center;
    }
    .button {
      margin-top: 1rem;
      width: 100%;
    }
  `,
  contentChildren: css`
    text-align: left !important;
  `,
};
