export const DEFAULT_TIMEZONE = 'Asia/Jakarta';

export const TIMELINE_DATE_FORMAT = 'dd MMM yyyy';
export const TIMELINE_FULL_DATE_FORMAT = 'dd MMMM yyyy';
export const TIMELINE_SINGLE_DATE_FORMAT = 'd MMM yyyy';
export const TIMELINE_YEAR_TO_DATE_FORMAT = 'yyyy-MM-dd';
export const TIMELINE_TIME_FORMAT = 'HH:mm';
export const TIMELINE_DATE_TIME_GMT_FORMAT = 'dd MMM yyyy, HH:mm (O)';
export const TIMELINE_DATE_TIME_FORMAT = 'dd MMM yyyy HH:mm';
export const TIMELINE_SINGLE_DATE_TIME_FORMAT = 'd MMM yyyy HH:mm';
