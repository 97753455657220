import { createContext, ReactNode, useContext, useState } from 'react';

type Message = string | number | ReactNode;

type Color = 'neutral' | 'red' | 'blue' | 'yellow' | 'green';

interface IShowBanner {
  message: Message;
  isShowCloseButton?: boolean;
}

interface IBannerContext {
  bannerMessage: Message;
  bannerColor: Color;
  isShowBanner: boolean;
  showCloseButton: boolean;
  showBanner: ({ message }: IShowBanner) => void;
  hideBanner: () => void;
  changeColor: (color: string) => void;
  isClickedCloseButton: boolean;
  onClickClose: () => void;
}

const BannerContext = createContext<IBannerContext>({
  bannerMessage: '',
  bannerColor: 'neutral',
  isShowBanner: false,
  showCloseButton: false,
  showBanner: () => {},
  hideBanner: () => {},
  changeColor: () => {},
  isClickedCloseButton: false,
  onClickClose: () => {},
});

export function BannerProvider({ children }) {
  const [isShowBanner, setIsShowBanner] = useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = useState<Message>();
  const [bannerColor, setBannerColor] = useState<Color>('neutral');
  const [showCloseButton, setShowCloseButton] = useState<boolean>(false);
  const [isClickedCloseButton, setIsClickedCloseButton] =
    useState<boolean>(false);

  function showBanner({ message, isShowCloseButton = false }: IShowBanner) {
    setIsShowBanner(true);
    setBannerMessage(message as Message);
    setShowCloseButton(isShowCloseButton);
  }

  function hideBanner() {
    setIsShowBanner(false);
  }

  function changeColor(color) {
    setBannerColor(color);
  }

  function onClickClose() {
    setIsClickedCloseButton(true);
  }

  return (
    <BannerContext.Provider
      value={{
        isShowBanner,
        bannerMessage,
        showBanner,
        hideBanner,
        changeColor,
        bannerColor,
        showCloseButton,
        isClickedCloseButton,
        onClickClose,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
}

export function useBanner() {
  return useContext(BannerContext);
}
