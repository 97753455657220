import { css } from '@emotion/css';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

interface Props {
  html: string;
  options?: HTMLReactParserOptions;
}

/**
 * This component is used to parse and render HTML text safely.
 * It also applies custom styling for anchor (`<a>`) tags.
 */
export default function Parser({ html, options }: Props) {
  return <div className={styled.root}>{parse(html, options)}</div>;
}

export function parseHtml(html: string, options?: HTMLReactParserOptions) {
  const hasTag = /<[^>]+>/.test(html);
  if (!hasTag) {
    return parse(html, options);
  }
  return <Parser html={html} options={options} />;
}

const styled = {
  root: css`
    a,
    #other-details {
      color: var(--blue-500);
      font-weight: var(--bold-font-weight);
      &:not(:disabled) {
        &:active {
          color: var(--blue-500);
          text-decoration: underline;
        }
      }
    }
  `,
};
