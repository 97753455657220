import { css, cx } from '@emotion/css';
import { fontWeight } from '@topremit/shared-web/common/size';
import { useSidebar } from '@topremit/shared-web/hooks';
import { AnimatePresence, m, LazyMotion, domAnimation } from 'framer-motion';
import { ReactNode } from 'react';

interface AnimatedSidebarTextProps {
  children?: ReactNode;
  bold?: boolean;
  className?: string;
}

export default function AnimatedSidebarText({
  children,
  bold,
  className,
}: AnimatedSidebarTextProps) {
  const [{ isExpand }] = useSidebar();

  return (
    <AnimatePresence>
      {isExpand && (
        <LazyMotion features={domAnimation}>
          <m.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
            className={cx(className ?? styled.animatedText(bold))}
          >
            {children}
          </m.span>
        </LazyMotion>
      )}
    </AnimatePresence>
  );
}

const styled = {
  animatedText: (bold?: boolean) => css`
    font-size: var(--sm-font-size);
    width: 100%;
    font-weight: ${bold ? fontWeight.bold : fontWeight.regular};
  `,
};
