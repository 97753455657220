export const accordionArrowVariants = {
  expand: {
    transform: 'rotate(-180deg)',
    transition: {
      duration: 0.2,
    },
  },
  collapse: {
    transform: 'rotate(0deg)',
    transition: {
      duration: 0.2,
    },
  },
};
