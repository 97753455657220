import * as spinner from '@assets/spinner.json';
import { css } from '@emotion/css';
import dynamic from 'next/dynamic';
import { LottieProps } from 'react-lottie';

const Lottie = dynamic<LottieProps>(() => import('react-lottie'), {
  ssr: false,
});

export default function LoadingView() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: spinner,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={styled.loading}>
      <Lottie options={defaultOptions} height={70} width={70} />
    </div>
  );
}

const styled = {
  loading: css`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  `,
};
