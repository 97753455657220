import { useDashboardLayout } from '@hooks';
import { DashboardLayoutConfig } from '@layouts';
import { useMediaQuery, useMounted } from '@topremit/shared-web/hooks';
import { SizeScreenTabletMd } from '@topremit-ui/design-tokens';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

function useDashboardConfig(config: DashboardLayoutConfig = {}) {
  const mounted = useMounted();
  const router = useRouter();
  const isMobileSize = useMediaQuery(`(max-width:${SizeScreenTabletMd}px)`);
  const [, setDashboardConfig] = useDashboardLayout();
  const stringifyConfig = JSON.stringify(config);

  useEffect(() => {
    if (mounted) {
      setDashboardConfig?.((prev) => ({
        ...prev,
        ...Object.entries(config).reduce((acc, [key, value]) => {
          acc[key] = typeof value === 'function' ? value(isMobileSize) : value;
          return acc;
        }, {}),
      }));
    }

    return () =>
      setDashboardConfig?.((prev) => ({
        ...prev,
        ...Object.keys(config).reduce((acc, key) => {
          acc[key] = false; // Reset all configured properties to `false` on cleanup
          return acc;
        }, {}),
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileSize, mounted, stringifyConfig, router.asPath]);
}

export default useDashboardConfig;
