import { css, cx } from '@emotion/css';
import { Close, Search } from '@global-shapes';
import { color } from '@topremit/shared-web/styles/color';
import { Command as CommandPrimitive } from 'cmdk';
import * as React from 'react';

/**
 * Command
 *
 * @description
 * A Command is a menu component that allows users to search and select from a list of items,
 * its also be used as an combobox
 */
const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive ref={ref} className={className} {...props} />
));
Command.displayName = CommandPrimitive.displayName;

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    clearable?: boolean;
  }
>(({ className, clearable = false, ...props }, ref) => (
  <div className={styled.input.outer}>
    <div className={styled.input.wrapper} cmdk-input-wrapper="">
      <Search className={styled.input.icon} />
      <CommandPrimitive.Input
        ref={ref}
        className={cx(styled.input.field, className)}
        {...props}
      />
      {clearable && (
        <button onClick={() => props.onValueChange?.('')}>
          <Close fill={color.neutral300} />
        </button>
      )}
    </div>
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cx(styled.list, className)}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className={cx(styled.empty, className)}
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cx(styled.separator, className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cx(styled.item, className)}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const styled = {
  input: {
    wrapper: css`
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 0.25rem;
      align-items: center;
      padding: 0 1rem;
      border: 1px solid ${color.neutral200};
      border-radius: 36px;
      height: 44px;
      button {
        display: flex;
        align-items: center;
      }
    `,
    outer: css`
      padding: 0.75rem 1rem;
    `,
    field: css`
      border: none;
      font-size: 1rem;
      width: 100%;
      font-family: inherit;
      &::placeholder {
        color: ${color.neutral300};
      }
      &:focus {
        outline: none;
      }
    `,
    icon: css`
      width: 20px;
      height: 20px;
      opacity: 50%;
    `,
  },
  separator: css`
    margin: 0 -2px;
    height: 1px;
    background-color: ${color.neutral200};
  `,

  item: css`
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 0.5rem;
    padding: 0.875rem 1.25rem;
    font-size: 1rem;
    &[data-selected='true'] {
      background-color: #f7f9fc;
    }
  `,
  list: css`
    overflow-x: hidden;
    overflow-y: auto;
  `,
  empty: css`
    padding: 1rem;
  `,
};

export {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandItem,
  CommandSeparator,
};
