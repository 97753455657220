import { useEffect } from 'react';

const useQuotationRequest = (functionCallback, delay, deps) => {
  useEffect(() => {
    if (deps) {
      const interval = setInterval(
        (function run() {
          functionCallback();
          return run;
        })(),
        delay,
      );
      return () => clearInterval(interval);
    }
  }, [deps]);
};

export { useQuotationRequest };
