import Fuse from 'fuse.js';
import { useCallback, useMemo } from 'react';

import { tree } from '../common/helper';

interface Config extends Fuse.IFuseOptions<unknown> {}

interface FuzzySearch {
  search: (query: string, config?: SearchConfig) => any[];
}

interface SearchConfig {
  /**
   * Returning pure data from Fuse.js
   */
  raw?: boolean;
}

export default function useFuzzySearch(
  options: any,
  config?: Config,
): FuzzySearch {
  // init fuse
  const fuse = useMemo(
    () =>
      new Fuse(
        options?.map((option) => ({
          ...option,
          /* docs: reasons searchText doesn't need to be replace from outside */
          ...(option?.searchText && {
            searchText: option.searchText?.split('|'),
          }),
        })),
        {
          keys: tree(options?.[0]),
          ...config,
        },
      ),
    [config, options],
  );

  const search = useCallback(
    (query: string, { raw }: SearchConfig = {}) => {
      if (query.length > 0) {
        if (raw) return fuse.search(query.toLowerCase());
        return (
          fuse
            .search(query.toLowerCase())
            ?.map((items: any) => ({ ...items.item })) || []
        );
      }
      return options;
    },
    [fuse, options],
  );

  return { search };
}
