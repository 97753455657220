import { css, cx } from '@emotion/css';
import { Checkbox as CheckboxAntd, CheckboxProps } from 'antd';
import { CSSProperties } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { ISelectOptions } from '../../api-hooks/common';
import { isStringEmpty } from '../../common/helper';

export interface ICheckboxProps extends Omit<CheckboxProps, 'indeterminate'> {
  label?: string | React.ReactNode;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  className?: string;
  labelClassName?: string;
  name?: string;
  partial?: boolean;
  multiple?: boolean;
  options?: ISelectOptions[];
  showErrors?: boolean;
  rowReverse?: boolean;
}

const styles = {
  root: (rowReverse) => css`
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    &.column {
      flex-direction: column;
      align-items: flex-start;
    }
    .field-label {
      margin-bottom: 1.25rem;
      color: var(--text-secondary);
    }

    .ant-checkbox {
      margin-right: ${rowReverse ? 0 : '0.625rem'};
    }
    .ant-checkbox-wrapper {
      width: 100%;
      flex-direction: ${rowReverse ? 'row-reverse' : 'row'};
    }
    .helper-text {
      &.error {
        color: var(--red-500);
      }
    }
  `,
  label: css`
    max-width: 100%;
    width: 100%;
  `,
  error: css`
    margin-top: 0.25rem;
    color: var(--red-500);
  `,
};

function CheckboxInput(props: ICheckboxProps) {
  const {
    name,
    label,
    className,
    style,
    labelClassName,
    labelStyle,
    partial = false,
    children,
    multiple = false,
    options = [],
    showErrors = true,
    onChange,
    rowReverse = false,
    ...resProps
  } = props;

  const formContext = useFormContext();
  const { control } = formContext;

  const {
    field,
    fieldState: { error },
  } = useController({
    name: name || '',
    control,
  });

  const labelClass = cx(styles.label, 'regular-text', labelClassName, {
    'disabled-text': resProps.disabled,
  });
  const checkboxClass = cx(styles.root(rowReverse), className);

  function handleChange(value) {
    field.onChange(value);
    onChange?.(value);
  }

  if (multiple) {
    if (isStringEmpty(name)) {
      throw new Error('Property name should not be empty!');
    }

    return (
      <div className={cx(checkboxClass, 'column')}>
        <div className="field-label">
          <span>{label}</span>
        </div>
        <CheckboxAntd.Group
          value={field.value}
          {...{ options, onChange: handleChange, name }}
        />
        {showErrors && error?.message && (
          <div className="helper-text error xs-text ">{error?.message}</div>
        )}
      </div>
    );
  }

  return (
    <>
      <CheckboxAntd
        className={checkboxClass}
        indeterminate={partial}
        children={
          (label || children) && (
            <div className={labelClass} style={labelStyle}>
              {label || children}
            </div>
          )
        }
        onChange={handleChange}
        {...{ style, ...resProps }}
      />
      {showErrors && (
        <div className={cx(styles.error, 'xs-text')}>
          <span>{error?.message}</span>
        </div>
      )}
    </>
  );
}

export default CheckboxInput;
