/* Purpose: so we didn't need to refactor all code */
/* Do not change this code */

import {
  Button as _Button,
  IButtonProps,
  ButtonType,
} from '@topremit-ui/button';
import React, { forwardRef } from 'react';

export interface ButtonProps extends Omit<IButtonProps, 'buttonType'> {
  type?: ButtonType;
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    type?: ButtonType;
  }
>((props, ref) => {
  const { type, ...rest } = props;

  return <_Button ref={ref} buttonType={type} {...rest} />;
});

export default Button;
