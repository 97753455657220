import { css, cx } from '@emotion/css';
import { forwardRef, HTMLAttributes, ReactNode } from 'react';

import { screenSize } from '../../common/size';
import { color } from '../../styles/color';

interface IChipProps extends HTMLAttributes<HTMLElement> {
  label: string;
  className?: string;

  isSelected?: boolean;
  isError?: boolean;
  isDisabled?: boolean;

  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

const style = {
  default: {
    border: `1px solid ${color.neutral200}`,
    background: color.neutral0,
    color: color.neutral500,
  },
  selected: {
    border: 'none',
    background: color.blue100,
    color: color.blue500,
  },
  error: {
    border: 'none',
    background: color.red100,
    color: color.red500,
  },
  disabled: {
    border: 'none',
    background: color.neutral100,
    color: color.neutral500,
  },
};

const Chip = forwardRef(
  (
    {
      label,
      className,
      isSelected,
      isError,
      isDisabled,
      leftIcon,
      rightIcon,
      ...props
    }: IChipProps,
    ref: any,
  ) => {
    const status = (() => {
      if (isDisabled) {
        return 'disabled';
      }

      if (isError) {
        return 'error';
      }

      if (isSelected) {
        return 'selected';
      }

      return 'default';
    })();

    return (
      <div {...props} ref={ref} className={cx(styled.root(status), className)}>
        {leftIcon && <div className="icon">{leftIcon}</div>}
        {label && <div className="label">{label}</div>}
        {rightIcon && <div className="icon">{rightIcon}</div>}
      </div>
    );
  },
);

export default Chip;

const styled = {
  root: (status: 'default' | 'selected' | 'error' | 'disabled') => css`
    cursor: pointer;
    display: flex;
    align-items: center;
    background: ${style[status].background};
    border: ${style[status].border};
    color: ${style[status].color};
    width: fit-content;
    padding: 0.625rem 1.25rem;
    border-radius: 3.125rem;
    height: 2.625rem;
    > .icon {
      display: flex;
      align-items: center;
      > svg {
        fill: ${style[status].color};
      }
      &:first-child {
        margin-right: 0.625rem;
      }
      &:last-child {
        margin-left: 0.625rem;
      }
    }
    > .label {
      height: fit-content;
    }

    @media (max-width: ${screenSize.tabletMd}px) {
      height: 2.25rem;
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
    }
  `,
};
