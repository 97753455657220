import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { callAllFunctions } from '@topremit/shared-web/common/helper';
import { useNotification } from '@topremit/shared-web/hooks';
import { useQuery, UseQueryOptions } from 'react-query';

import {
  KycAbadModel,
  SelectOptionModel,
  PostalCodeModel,
} from './kyc-abad.model';

export function useGetOccupation(
  options?: UseQueryOptions<ApiResult<SelectOptionModel[]>, ApiError>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useCustomQuery<SelectOptionModel[]>(
    ['occupations'],
    async () => await requestFn({ path: 'occupations', method: 'get' }),
    {
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useGetPostalCodes(
  search?: string,
  options?: UseQueryOptions<ApiResult<PostalCodeModel[]>, ApiError>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useCustomQuery<PostalCodeModel[]>(
    ['postal-codes'],
    async () =>
      await requestFn(
        { path: 'postal-codes', method: 'get' },
        { searchParams: { 'filter[search]': search || '' } },
      ),
    {
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      enabled: false,
      ...resOption,
    },
  );
}

export function useGetProvinces(
  isoCode: string,
  options?: UseQueryOptions<ApiResult, ApiError>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useQuery<ApiResult, ApiError>(
    ['states', isoCode],
    async () =>
      await requestFn(
        { path: 'states', method: 'get' },
        {
          searchParams: {
            country_iso_code: isoCode,
          },
        },
      ),
    {
      enabled: !!isoCode,
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useGetCities(
  isoCode: string,
  province: string,
  options?: UseQueryOptions<ApiResult, ApiError>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useQuery<ApiResult, ApiError>(
    ['cities', province],
    async () =>
      await requestFn(
        { path: 'cities', method: 'get' },
        {
          searchParams: {
            country_iso_code: isoCode,
            province,
          },
        },
      ),
    {
      enabled: !!isoCode && !!province,
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useGetKycAbadData(
  options?: UseQueryOptions<ApiResult<KycAbadModel>, ApiError>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useCustomQuery<KycAbadModel>(
    ['kyc-data'],
    async () => await requestFn({ path: 'v2/members/kyc', method: 'get' }),
    {
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
    {
      hasLang: false,
    },
  );
}
