import { HTMLAttributes, useEffect } from 'react';

export interface IOptionWrapperProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'value'> {
  value: string;
}

function OptionWrapper(props: IOptionWrapperProps) {
  const { children } = props;

  useEffect(() => {
    if (!props.value) {
      throw new Error('OptionWrapper must have a value prop');
    }
  }, []);

  return (
    <div id={`option-wrapper-${props.value}`} {...props}>
      {children}
    </div>
  );
}

export default OptionWrapper;
