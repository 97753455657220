import { useGetMe } from '@api-hooks/common';
import { useGetUpdateDataDraft, usePostUpdateData } from '@api-hooks/member';
import { css } from '@emotion/css';
import { usePermission, usePin } from '@hooks';
import { useGlobalQueueStore } from '@stores';
import { Button } from '@topremit/shared-web';
import { typography } from '@topremit/shared-web/common/size';
import { parseHtml } from '@topremit/shared-web/components/elements';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores/use-dialog-store';
import { color } from '@topremit/shared-web/styles/color';
import { useRouter } from 'next/router';

import { UPDATE_DATA_DRAFT_KEY } from '../constants';
import { mapToUpdateFormDataPayload } from '../helper/mappers';
import { UpdateDataForm } from '../types';

export default function ConfirmUpdateProfileModal({
  data,
}: {
  data: UpdateDataForm;
}) {
  const { replace } = useRouter();
  const { isPersonalAccount } = usePermission();
  const { t } = useTranslation('profile');
  const { refetch: refetchUpdateData } = useGetUpdateDataDraft();
  const closeDialog = useDialogStore((store) => store.close);
  const { verify } = usePin();
  const { refetch: refetchMe } = useGetMe({ enabled: false });
  const { addNotification } = useNotification();
  const { next } = useGlobalQueueStore();

  const { mutateAsync: saveDraft } = usePostUpdateData({
    onSuccess: async ({ message }) => {
      addNotification({ message, type: 'success' });
      closeDialog();
      await refetchMe();
      await refetchUpdateData();
      next();
      localStorage.removeItem(UPDATE_DATA_DRAFT_KEY);
      replace(
        isPersonalAccount
          ? '/profile/personal-details'
          : '/profile/company-profile',
      );
    },
  });

  const handleSubmit = () => {
    verify({
      description: t('pin_input.desc'),
      onSubmit: async (code) => {
        await saveDraft(
          mapToUpdateFormDataPayload(
            { ...data, pinCode: code, saveAsDraft: false },
            isPersonalAccount,
          ),
        );
      },
    });
  };

  return (
    <div className={styled.root}>
      <h2 className={styled.title}>{t('confirmation_modal.title')}</h2>
      <p className={styled.description}>
        {parseHtml(
          t(
            isPersonalAccount
              ? 'confirmation_modal.description_personal'
              : 'confirmation_modal.description_business',
          ),
        )}
      </p>
      <div className={styled.footer}>
        <Button onClick={handleSubmit} fullWidth size="large">
          {t('confirmation_modal.submit')}
        </Button>
        <Button fullWidth size="large" type="invert" onClick={closeDialog}>
          {t('confirmation_modal.cancel')}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  title: css`
    font-size: ${typography.body2Bold.fontSize}rem;
    font-weight: ${typography.body2Bold.fontWeight};
    line-height: ${typography.body2Bold.lineHeight}px;
  `,
  description: css`
    margin: 0;
    color: ${color.neutral800};
    font-size: ${typography.body3Medium.fontSize}rem;
    font-weight: ${typography.body3Medium.fontWeight};
    line-height: ${typography.body3Medium.lineHeight}px;
  `,
  footer: css`
    width: 100%;
    margin-top: 1.5rem;
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `,
};
