import { css, cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import { Input as InputAntd, InputRef } from 'antd';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { variants } from './constants';
import { ITextAreaInputProps } from './types';
import { handleInputCondition } from '../../../common/helper';

const { TextArea: TextAreaAntd } = InputAntd;

const TextAreaInput = forwardRef((props: ITextAreaInputProps, ref: any) => {
  const {
    name,
    rules,
    label,
    disabled,
    className,
    maxLength,
    showCount,
    helperText,
    bordered = true,
    isOptional = false,
    disabledFocus = false,
    inputWrapperClassName,
    inputRef: inputRefProp,

    onBlur,
    onFocus,
    onChange,

    ...resProps
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const formContext = useFormContext();
  const { control } = formContext;

  const inputRef = useRef<InputRef>(null);
  const fieldGroupRef = useRef<HTMLDivElement>();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...(rules && rules),
    },
  });

  const isError = !!error;
  const errorMessage = error?.message;

  const isFilled = !!field.value;
  const count = field.value?.length || 0;

  const _disabled = disabled || disabledFocus;

  const inputClass = cx('input-control', styled.textArea, { error: isError });
  const formGroupClass = cx(styled.textAreaFormGroup, className);
  const formControlClass = cx('form-control', styled.formControl);

  const formControlProps = {
    variants,
    className: formControlClass,
    onClick: () => {
      if (!disabled) {
        setIsFocus(!disabledFocus);
      }
    },
    animate: handleInputCondition({ isFocus, isFilled }),
  };

  function handleFocus(e) {
    onFocus && onFocus(e);
    setIsFocus(!disabledFocus);
  }

  function handleBlur(e) {
    field.onBlur();
    setIsFocus(false);
    onBlur && onBlur(e);
  }

  function handleChange(e) {
    field.onChange(e);
    onChange && onChange(e);
  }

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
    blur() {
      inputRef.current?.blur();
    },
    ref: fieldGroupRef.current,
  }));

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
      return;
    }
    inputRef.current?.blur();
  }, [isFocus]);

  useEffect(() => {
    if (disabled) {
      setIsFocus(false);
    }
  }, [disabled]);

  return (
    <FieldGroup
      name={name}
      count={count}
      label={label}
      isFocus={isFocus}
      isError={isError}
      bordered={bordered}
      ref={fieldGroupRef}
      helper={helperText}
      isFilled={isFilled}
      disabled={disabled}
      error={errorMessage}
      maxCount={maxLength}
      isOptional={isOptional}
      className={formGroupClass}
      inputWrapperRef={inputRefProp}
      showCounter={showCount as boolean}
      formControlProps={formControlProps}
      inputWrapperClassName={inputWrapperClassName}
      onBlur={handleBlur}
    >
      <TextAreaAntd
        name={name}
        ref={inputRef}
        value={field.value}
        variant="borderless"
        disabled={_disabled}
        maxLength={maxLength}
        className={inputClass}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...resProps}
      />
    </FieldGroup>
  );
});

const styled = {
  formControl: css`
    height: 7.75rem !important;
    width: 100%;
    .form-label.active {
      top: 15% !important;
    }
    .form-label.inactive {
      top: 20% !important;
    }
    textarea {
      height: 90% !important;
    }
  `,
  textAreaFormGroup: css`
    height: 100%;
    cursor: text;
  `,
  textArea: css`
    border-radius: 0;
  `,
};

export default TextAreaInput;
