/**
 * @note this events just send native to mobile
 */
export enum FbCustomEvents {
  PURCHASE = 'fb_mobile_purchase',
  ADD_TO_CART_DOMESTIC_SINGLE = 'fb_add_to_cart_domestic_single',
  CHECKOUT_DOMESTIC_SINGLE = 'fb_checkout_domestic_single',
  ADDPAYMENTINFO_DOMESTIC_SINGLE = 'fb_addpaymentinfo_domestic_single',
  PURCHASE_DOMESTIC_SINGLE = 'fb_purchase_domestic_single',
  ADD_TO_CART_DOMESTIC_MULTIPLE = 'fb_add_to_cart_domestic_multiple',
  CHECKOUT_DOMESTIC_MULTIPLE = 'fb_checkout_domestic_multiple',
  ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE = 'fb_add_payment_info_domestic_multiple',
  ADD_PAYMENT_INFO_MULTIPLE = 'fb_mobile_add_payment_info_multiple',
  PURCHASE_DOMESTIC_MULTIPLE = 'fb_purchase_domestic_multiple',
  PURCHASE_MULTIPLE = 'fb_mobile_purchase_multiple',
  INITIATED_CHECKOUT_MULTIPLE = 'fb_mobile_initiated_checkout_multiple',
  CONTENT_VIEW_MULTIPLE = 'fb_mobile_content_view_multiple',
  ADD_TO_CART_MULTIPLE = 'fb_mobile_add_to_cart_multiple',
}
export enum FbStandartEvents {
  COMPLETE_REGISTRATION = 'fb_mobile_complete_registration',
  ADD_PAYMENT_INFO = 'fb_mobile_add_payment_info',
  PURCHASE = 'fb_mobile_purchase',
  ADD_TO_CART = 'fb_mobile_add_to_cart',
  INITIATED_CHECKOUT = 'fb_mobile_initiated_checkout',
  CONTENT_VIEW = 'fb_mobile_content_view',
}
export enum AfCustomEvents {
  ADD_TO_CART_DOMESTIC_SINGLE = 'af_add_to_cart_domestic_single',
  INITIATED_CHECKOUT_DOMESTIC_SINGLE = 'af_initiated_checkout_domestic_single',
  ADD_PAYMENT_INFO_DOMESTIC_SINGLE = 'af_add_payment_info_domestic_single',
  ADD_TO_CART_DOMESTIC_MULTIPLE = 'af_add_to_cart_domestic_multiple',
  INITIATED_CHECKOUT_DOMESTIC_MULTIPLE = 'af_initiated_checkout_domestic_multiple',
  ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE = 'af_add_payment_info_domestic_multiple',
  ADD_PAYMENT_INFO_MULTIPLE = 'af_add_payment_info_multiple',
  INITIATED_CHECKOUT_MULTIPLE = 'af_initiated_checkout_multiple',
  CONTENT_VIEW_MULTIPLE = 'af_content_view_multiple',
  ADD_TO_CART_MULTIPLE = 'af_add_to_cart_multiple',
}
export enum AfStandartEvents {
  ADD_PAYMENT_INFO = 'af_add_payment_info',
  ADD_TO_CART = 'af_add_to_cart',
  INITIATED_CHECKOUT = 'af_initiated_checkout',
  CONTENT_VIEW = 'af_content_view',
}

export enum GaCustomEvents {
  SCREEN_VIEW_DOMESTIC = 'screen_view_domestic',
}
