import { css, cx } from '@emotion/css';
import { useShowChatUsDialog } from '@hooks';
import { ChatUsFill } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';

import { PrefillTextType } from './constant';

interface IChatUsButtonProps {
  className?: string;
  prefillType?: PrefillTextType;
  payload?: string;
}

function ChatUsButton({ className, prefillType, payload }: IChatUsButtonProps) {
  const { t } = useTranslation();

  const { showChatDialog } = useShowChatUsDialog(prefillType, payload);

  function onClickChatUs() {
    showChatDialog();
  }

  return (
    <button className={cx(styled.root, className)} onClick={onClickChatUs}>
      <ChatUsFill width={16} height={16} fill="var(--blue-500)" />
      <span>{t('chat_us')}</span>
    </button>
  );
}

const styled = {
  root: css`
    max-height: 2rem;
    border: none;
    background-color: var(--neutral-0);
    display: flex;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 0.25rem;
    border-radius: 6.25rem;
    box-shadow: 0px 2px 8px 0px #2e48651a;
    cursor: pointer;
    span {
      font-size: var(--xs-font-size);
      color: var(--blue-500);
      font-weight: var(--bold-font-weight);
      white-space: nowrap;
    }
  `,
};

export default ChatUsButton;
