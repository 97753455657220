import { ClassNamesArg, css, cx } from '@emotion/css';
import Link from 'next/link';
import { memo, ReactNode } from 'react';

import { isString } from '../../../../common/assertion';
import { useBottombar } from '../../../../hooks';

interface BottomMenuItemBag {
  isActive: boolean;
}

interface BottomMenuItemProps {
  children?: ReactNode | ((args: BottomMenuItemBag) => ReactNode);
  className?: ClassNamesArg;
  href?: string;
}

function BottomMenuItem({ children, className, href }: BottomMenuItemProps) {
  const [{ activePath }, { setActivePath }] = useBottombar();

  const isActive = typeof href !== 'undefined' && activePath.includes(href);

  const render =
    typeof children === 'function' ? children({ isActive }) : children;

  function handleItemClick() {
    isString(href) && setActivePath(href);
  }

  const linkOrPlainNode =
    typeof href !== 'undefined' ? (
      <Link
        href={href}
        onClick={handleItemClick}
        className={cx(styled.item, className, { active: isActive })}
      >
        {render}
      </Link>
    ) : (
      <div className={cx(styled.item, className)}>{render}</div>
    );

  return <>{linkOrPlainNode}</>;
}

export default memo(BottomMenuItem);

const styled = {
  item: css`
    display: flex;
    max-height: unset !important;
    max-width: unset !important;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* active / inactive state */
    & > *,
    :hover,
    :visited {
      color: var(--text-secondary);
      font-weight: var(--regular-font-weight);
    }

    &.active > * {
      color: var(--blue-500);
      font-weight: var(--bold-font-weight);
    }
  `,
};
