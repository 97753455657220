import { SVGProps } from 'react';

function Cross({
  size = 'default',
  ...resProps
}: SVGProps<SVGSVGElement> & {
  size?: 'small' | 'default';
}) {
  return size === 'small' ? (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#8295B5"
      xmlns="http://www.w3.org/2000/svg"
      {...resProps}
    >
      <path d="M17.9999 6C17.8124 5.81253 17.5581 5.70721 17.2929 5.70721C17.0278 5.70721 16.7735 5.81253 16.5859 6L11.9999 10.586L7.41394 6C7.22641 5.81253 6.9721 5.70721 6.70694 5.70721C6.44178 5.70721 6.18747 5.81253 5.99994 6C5.81247 6.18753 5.70715 6.44184 5.70715 6.707C5.70715 6.97216 5.81247 7.22647 5.99994 7.414L10.5859 12L5.99994 16.586C5.81247 16.7735 5.70715 17.0278 5.70715 17.293C5.70715 17.5582 5.81247 17.8125 5.99994 18C6.18747 18.1875 6.44178 18.2928 6.70694 18.2928C6.9721 18.2928 7.22641 18.1875 7.41394 18L11.9999 13.414L16.5859 18C16.7735 18.1875 17.0278 18.2928 17.2929 18.2928C17.5581 18.2928 17.8124 18.1875 17.9999 18C18.1874 17.8125 18.2927 17.5582 18.2927 17.293C18.2927 17.0278 18.1874 16.7735 17.9999 16.586L13.4139 12L17.9999 7.414C18.1874 7.22647 18.2927 6.97216 18.2927 6.707C18.2927 6.44184 18.1874 6.18753 17.9999 6Z" />
    </svg>
  ) : (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#8295B5"
      xmlns="http://www.w3.org/2000/svg"
      {...resProps}
    >
      <path d="M23.707 0.293C23.5195 0.105529 23.2652 0.000213623 23 0.000213623C22.7349 0.000213623 22.4806 0.105529 22.293 0.293L12 10.586L1.70703 0.293C1.5195 0.105529 1.26519 0.000213623 1.00003 0.000213623C0.734866 0.000213623 0.480558 0.105529 0.293031 0.293C0.10556 0.480528 0.000244141 0.734836 0.000244141 1C0.000244141 1.26516 0.10556 1.51947 0.293031 1.707L10.586 12L0.293031 22.293C0.10556 22.4805 0.000244141 22.7348 0.000244141 23C0.000244141 23.2652 0.10556 23.5195 0.293031 23.707C0.480558 23.8945 0.734866 23.9998 1.00003 23.9998C1.26519 23.9998 1.5195 23.8945 1.70703 23.707L12 13.414L22.293 23.707C22.4806 23.8945 22.7349 23.9998 23 23.9998C23.2652 23.9998 23.5195 23.8945 23.707 23.707C23.8945 23.5195 23.9998 23.2652 23.9998 23C23.9998 22.7348 23.8945 22.4805 23.707 22.293L13.414 12L23.707 1.707C23.8945 1.51947 23.9998 1.26516 23.9998 1C23.9998 0.734836 23.8945 0.480528 23.707 0.293Z" />
    </svg>
  );
}

export default Cross;
