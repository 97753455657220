import { css } from '@emotion/css';
import { screenSize } from '@global-common/size';
import { Flag, Flex, Text } from '@global-elements';
import { FlagCode } from '@global-elements-utils/Flag';

interface CountryCurrencyFlagProps {
  flagCode: FlagCode | undefined;
  currencyCode: string | undefined;
  className?: string;
}

/**
 * Component to display a country flag and currency code
 */
export default function CountryCurrencyFlag({
  flagCode,
  currencyCode,
  className,
}: CountryCurrencyFlagProps) {
  return (
    <Flex gap={8} align="center" className={className}>
      <Flag code={flagCode} />
      <Text as="span" className={styles.currency}>
        {currencyCode}
      </Text>
    </Flex>
  );
}

const styles = {
  currency: css`
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
  `,
};
