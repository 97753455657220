export interface GlobalTrackAttribute {
  app: string;
  version: string;
  identifier_type: string;
  user_id?: string;
}

export enum AnalyticPagesNames {
  // AUTH
  FORGOT_PASSWORD_PAGE = 'FORGOT_PASSWORD_PAGE',
  REGISTER_PAGE = 'REGISTER_PAGE',

  // TRANSACTION
  TRANSACTION_DETAIL = 'TRANSACTION_DETAIL_PAGE',
  TRANSACTION_MAKE_PAYMENT = 'TRANSACTION_MAKE_PAYMENT_PAGE',

  // PROFILE
  PROFILE = 'PROFILE_PAGE',

  // COMPONENT
  FOOTER = 'FOOTER',

  // HOME
  HOME = 'HOMEPAGE',
  LANDING_PAGE_DOMESTIC = 'LANDING_PAGE_DOMESTIC',
  AIRTIME = 'AIRTIME',

  // TR COINS
  TR_COINS = 'TRcoins',
  TR_COINS_HISTORY = 'TRCOINS_HISTORY_PAGE',

  // CALCULATOR
  CALCULATOR = 'CALCULATOR',
}

export enum AnalyticKYCStepName {
  ON_CHOOSE_ID_TYPE = 'ON_CHOOSE_ID_TYPE',
  ON_VALIDATE_PERSONAL = 'ON_VALIDATE_PERSONAL',
  ON_VALIDATE_ADDRESS = 'ON_VALIDATE_ADDRESS',
  ON_VALIDATE_SELFIE = 'ON_VALIDATE_SELFIE',
  ON_VALIDATE_IDENTITY_PHOTO = 'ON_VALIDATE_IDENTITY_PHOTO',
}

export interface S2SIdentifiers {
  appsflyerId?: string | null;
  ga4ClientId?: string | null;
  gaSessionId?: string | null;
  metaExternalId?: string | null; // previous named fbClientId
  tiktokClientId?: string | null;
  afUserId?: string | null; // previous named afWebId
  mixpanelDistinctId?: string;
  metaFbc?: string;
  metaFbp?: string;
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  utmContent?: string;
  utmTerm?: string;
  gadSource?: string;
  gclid?: string;
  expiredAt?: string;
  memberId?: string;
  transactionId?: string;
}

export interface S2SIdentifierPayload extends S2SIdentifiers {
  eventType: S2SEventType;
  device?: 'WEB_PERSONAL' | 'WEB_BUSINESS';
}

export enum S2SEventType {
  REFUND = 'REFUND',
  PURCHASE = 'PURCHASE',
  PURCHASE_MULTIPLE = 'PURCHASE_MULTIPLE',
  COMPLETE_REGISTER = 'COMPLETE_REGISTER',
  PURCHASE_DOMESTIC_SINGLE = 'PURCHASE_DOMESTIC_SINGLE',
  PURCHASE_DOMESTIC_MULTIPLE = 'PURCHASE_DOMESTIC_MULTIPLE',
}

export type BankName = 'BCA' | 'BRI' | 'Mandiri' | 'BNI' | 'Permata' | 'others';

export type BlockingPageType = 'referral' | 'calculator' | 'profile' | 'kyc';

export enum PageDownloadInvoice {
  TRANSACTION_DETAILS = 'Transaction details',
  PAYMENT_DETAILS = 'Payment details',
}

export enum DownloadInvoiceType {
  TRANSACTION = 'transaction',
  TOPUP = 'topup',
}

export enum DownloadType {
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
}
export enum TransactionTrackSource {
  TRANSACTION_LIST = 'TRANSACTION_LIST',
  TRANSACTION_DETAIL_PAGE = 'TRANSACTION_DETAIL_PAGE',
  HOMEPAGE_TRANSACTION_LIST = 'HOMEPAGE_TRANSACTION_LIST',
  HOMEPAGE_REFUND_LIST = 'HOMEPAGE_REFUND_LIST',
  REFUND_LIST = 'REFUND_LIST',
  REFUND_DETAIL_PAGE = 'REFUND_DETAIL_PAGE',
  RECIPIENT_LIST = 'RECIPIENT_LIST',
}

export enum ShareTrackingMethod {
  LINK = 'link',
  WHATSAPP = 'whatsapp',
  TWITTER = 'x',
  FACEBOOK = 'facebook',
  ENVELOPE = 'mail',
}
