import { Global, Interpolation, Theme } from '@emotion/react';

import antAlert from './ant-alert';
import antBadge from './ant-badge';
import antButton from './ant-button';
import antCheckbox from './ant-checkbox';
import antInput from './ant-input';
import antNotification from './ant-notification';
import antPicker from './ant-picker';
import antPopover from './ant-popover';
import antProgress from './ant-progress';
import antRadio from './ant-radio';
import antSelect from './ant-select';
import antSteps from './ant-steps';
import antTable from './ant-table';
import antTag from './ant-tag';
import global from './global';
import style from './style';

import '@topremit-ui/design-tokens/dist/css/_variables.css';
import '@topremit-ui/button/dist/index.esm.css';
import '@topremit-ui/text/dist/index.esm.css';

interface GlobalStylesProps {
  styles?: Interpolation<Theme>[];
}

export default function GlobalStyles({ styles }: GlobalStylesProps) {
  function renderOtherStyles() {
    return styles?.map((style, i) => <Global key={i} styles={style} />);
  }

  return (
    <>
      <Global styles={global} />
      <Global styles={style} />
      <Global styles={antButton} />
      <Global styles={antSelect} />
      <Global styles={antTable} />
      <Global styles={antTag} />
      <Global styles={antInput} />
      <Global styles={antAlert} />
      <Global styles={antBadge} />
      <Global styles={antRadio} />
      <Global styles={antCheckbox} />
      <Global styles={antNotification} />
      <Global styles={antPopover} />
      <Global styles={antPicker} />
      <Global styles={antProgress} />
      <Global styles={antSteps} />

      {renderOtherStyles()}
    </>
  );
}
