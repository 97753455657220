import {
  AccountInfo,
  AccountInfoBusiness,
  useGetAccountInfo,
  useGetMe,
} from '@api-hooks/common';
import { css } from '@emotion/css';
import { useAuth, usePermission } from '@hooks';
import { useCurrentAccountStore } from '@stores';
import { breakpoint } from '@topremit/shared-web/common/size';
import { Flex, Status, Text } from '@topremit/shared-web/components/elements';
import {
  Angle,
  PersonalUser,
  SignOut,
} from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { color } from '@topremit/shared-web/styles/color';
import Link from 'next/link';

import { IPillStatus } from './helper';

interface IProfileDetailProps {
  pillsStatus: IPillStatus;
}

function ProfileDetail({ pillsStatus }: IProfileDetailProps) {
  const { t } = useTranslation();

  const { data: accountInfo } = useGetAccountInfo<
    AccountInfo & AccountInfoBusiness
  >();
  const { data: user } = useGetMe();
  const { email } = user?.data || {};

  const { currentAccount } = useCurrentAccountStore.getState() || {};

  const { isBusinessAccount, isPersonalAccount } = usePermission();

  const popDialog = useDialogStore((store) => store.pop);
  const { logout } = useAuth();

  return (
    <Flex column className={styled.root}>
      <Flex column className={styled.profileWrapper}>
        <Text as="h5">{t('profile')}</Text>
        <Flex className="profile">
          <div className="circle circle-info">
            <PersonalUser />
          </div>
          <Flex column className={styled.userDetailWrapper}>
            <Flex column className="user-detail">
              <Flex className="user-name">
                {isBusinessAccount
                  ? currentAccount?.companyName
                  : currentAccount?.name}
              </Flex>
              <Text className="sm-text account-type">
                {isBusinessAccount
                  ? currentAccount?.name
                  : t('personal_account')}
              </Text>
              {isBusinessAccount && (
                <>
                  <Text className="xs-text secondary-text">
                    {email ?? accountInfo?.data?.email}
                  </Text>
                  <Text className="xs-text link">
                    {currentAccount?.roles.join(', ')}
                  </Text>
                </>
              )}
            </Flex>
            {isPersonalAccount && (
              <Status
                size="small"
                status={pillsStatus.status}
                message={pillsStatus.message}
                className={styled.status}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
      <div className={styled.separator} />
      <Link href="/profile" onClick={popDialog} className={styled.goToProfile}>
        <Flex className="label">{t('go_to_profile')}</Flex>
        <Angle direction="right" size="small" />
      </Link>
      <div className={styled.separator} />
      <Flex gap={16} className={styled.btnLogout} onClick={() => logout()}>
        <div className="circle circle-info">
          <SignOut size={20} fill={color.blue500} />
        </div>
        <Text className="label">{t('logout')}</Text>
      </Flex>
    </Flex>
  );
}

export default ProfileDetail;

const styled = {
  root: css`
    .circle {
      width: 2.5rem;
      height: 2.5rem;
    }
  `,
  profileWrapper: css`
    > :not(:last-child) {
      margin-bottom: 1rem;
    }
    .profile {
      > :not(:last-child) {
        margin-right: 1rem;
      }
    }
  `,
  userDetailWrapper: css`
    .user-detail {
      > :not(:last-child) {
        margin-bottom: 0.25rem;
      }
      .user-name {
        font-size: var(--h5-font-size);
        font-weight: var(--bold-font-weight);
        @media (max-width: ${breakpoint.navbarBurger}px) {
          font-size: var(--regular-font-size);
        }
      }
      .account-type {
        color: var(--neutral-500);
        @media (max-width: ${breakpoint.navbarBurger}px) {
          font-size: var(--xs-font-size);
        }
      }
    }
  `,
  status: css`
    margin-top: 0.5rem;
  `,
  goToProfile: css`
    width: 100%;
    justify-content: space-between;
    color: var(--text-primary);
    .label {
      font-weight: var(--bold-font-weight);
      align-items: center;
    }
  `,
  separator: css`
    background-color: var(--neutral-100);
    width: calc(100% + 3rem);
    height: 0.375rem;
    margin: 1rem 0;
    transform: translateX(-24px);
  `,
  btnLogout: css`
    > .label {
      display: flex;
      align-items: center;
    }
  `,
};
