import { css, cx } from '@emotion/css';
import { Flex as AntdFlex, FlexProps } from 'antd';
import { ElementType, forwardRef, ReactNode } from 'react';

export interface IFlexProps
  extends Omit<FlexProps, 'vertical' | 'component' | 'children'> {
  column?: boolean;
  as?: ElementType;
  fullWidth?: boolean;
  children?: ReactNode;
}

const Flex = forwardRef<HTMLElement, IFlexProps>(function Flex(
  { as = 'div', column, className, children, fullWidth = true, ...props },
  ref,
) {
  return (
    <AntdFlex
      ref={ref}
      component={as}
      vertical={column}
      className={cx(styled.root(fullWidth), className)}
      {...props}
    >
      {children}
    </AntdFlex>
  );
});

const styled = {
  root: (fullWidth: boolean) => css`
    width: ${fullWidth ? '100%' : 'unset'};
  `,
};

export default Flex;
