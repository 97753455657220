import { Children, ReactElement } from 'react';

import SideMenuItem from './SideMenuItem';

interface SideBarFooterProps {
  children?: ReactElement | ReactElement[];
}

export default function SideBarFooter({ children }: SideBarFooterProps) {
  const footerItemsNode = Children.map(children, (child) =>
    child?.type === SideMenuItem ? child : null,
  );

  return <>{footerItemsNode}</>;
}
