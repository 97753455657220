import { css, cx } from '@emotion/css';
import { Button } from '@global-elements';
import { useEffect, useState } from 'react';

import SpeedInfoCard from './SpeedInfoCard';
import { IChangeSpeedModalBodyProps } from './type';
import { screenSize } from '../../../common/size';
function ChangeDeliverySpeedModalBody({
  title,
  className,
  description,

  currentFee,
  currentSpeed,
  currentSpeedTitle,
  currentSpeedDescription,

  suggestedFee,
  suggestedSpeed,
  suggestedSpeedTitle,
  suggestedSpeedDescription,

  btnOkLabel,
  btnCancelLabel,

  onOk,
  onCancel,
}: IChangeSpeedModalBodyProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleClickOk() {
    setIsLoading(true);
    onOk();
  }

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <div className={cx(styled.root, 'change-delivery-speed', className)}>
      <h5 className="title">{title}</h5>
      <div className="description">{description}</div>
      <SpeedInfoCard
        currentFee={currentFee}
        suggestedFee={suggestedFee}
        currentSpeed={currentSpeed}
        suggestedSpeed={suggestedSpeed}
        currentSpeedTitle={currentSpeedTitle}
        suggestedSpeedTitle={suggestedSpeedTitle}
        currentSpeedDescription={currentSpeedDescription}
        suggestedSpeedDescription={suggestedSpeedDescription}
      />
      <div className="action-button">
        <Button fullWidth onClick={handleClickOk} isLoading={isLoading}>
          {btnOkLabel}
        </Button>
        {onCancel && (
          <Button fullWidth type="secondary" onClick={onCancel}>
            {btnCancelLabel}
          </Button>
        )}
      </div>
    </div>
  );
}

const styled = {
  root: css`
    .title {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      @media (max-width: ${screenSize.tabletSm}px) {
        font-size: 1.125rem;
      }
    }
    .description {
      color: var(--neutral-500);
      margin-bottom: 1rem;
    }
    .action-button {
      margin-top: 1.5rem;
      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  `,
};

export default ChangeDeliverySpeedModalBody;
