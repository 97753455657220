import { ClassNamesArg, css, cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { Check, Cross } from '@global-shapes';
import { color as globalColor } from '@global-styles/color';
import { ReactNode } from 'react';

type ConnectorColor = 'blue' | 'gray' | 'red' | 'lightGray';

const dotBg = {
  blue: globalColor.blue500,
  gray: globalColor.neutral500,
  lightGray: globalColor.neutral300,
  red: globalColor.red500,
};

const dotOutline = {
  blue: globalColor.blue100,
  gray: globalColor.neutral100,
  lightGray: globalColor.neutral100,
  red: globalColor.red100,
};

const dividerBg = {
  blue: globalColor.blue500,
  gray: globalColor.neutral500,
  lightGray: globalColor.neutral200,
  red: globalColor.red500,
};

const dotIcon = {
  check: <Check width={10} height={10} fill="white" />,
  cross: <Cross width={5} height={5} fill="white" />,
};

interface BaseProps {
  className?: ClassNamesArg;
  showDivider?: boolean;
  showAnimation?: boolean;

  color?: ConnectorColor;
}

/** Allow either `iconType` OR `icon`, but not both */
type Props =
  | (BaseProps & { iconType: keyof typeof dotIcon; icon?: never })
  | (BaseProps & { icon?: ReactNode; iconType?: never });

export default function VStepperConnector({
  icon,
  iconType,
  color = 'blue',
  showDivider = true,
  showAnimation = false,
  className,
}: Props) {
  const hasIcon = !!iconType || !!icon;
  const IconWrapper = hasIcon ? Flex : 'div';

  return (
    <Flex column className={cx(styled.root, className)}>
      <IconWrapper className={styled.icon(color, showAnimation)}>
        {iconType ? dotIcon[iconType] : icon}
      </IconWrapper>
      {showDivider && <div className={styled.divider(color)} />}
    </Flex>
  );
}

const styled = {
  root: css`
    width: fit-content;
    align-items: center;
  `,
  icon: (color: ConnectorColor, showAnimation: boolean) => css`
    min-width: 1rem;
    min-height: 1rem;
    align-items: center;
    justify-content: center;
    background: ${dotBg[color]};
    color: ${globalColor.neutral0};
    border-radius: 100%;
    outline: 0.25rem solid ${dotOutline[color]};
    animation: ${showAnimation ? 'dot 2s infinite' : 'unset'};
    @keyframes dot {
      0% {
        outline: none;
      }
      50% {
        outline: 0.25rem solid ${dotOutline[color]};
      }
      100% {
        outline: none;
      }
    }
  `,
  divider: (color: ConnectorColor) => css`
    width: 2px;
    height: 100%;
    background: ${dividerBg[color]};
    margin: 0.5rem 0;
  `,
};
