import { DefaultLayoutContextValues } from '@layouts';
import { assertContextCalledInProvider } from '@topremit/shared-web/common/assertion';
import { createContext, useContext } from 'react';

const DefaultLayoutContext = createContext<DefaultLayoutContextValues>([
  {},
  function setDefaultConfig() {},
]);

export const DefaultLayoutProvider = DefaultLayoutContext.Provider;

export function useDefaultLayout() {
  const ctx = useContext(DefaultLayoutContext);

  assertContextCalledInProvider<DefaultLayoutContextValues>(ctx);

  return ctx;
}
