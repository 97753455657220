import { css, cx } from '@emotion/css';
import { Alert as AlertAntd, AlertProps } from 'antd';
import { ReactNode } from 'react';

import { screenSize } from '../../common/size';
import { useMediaQuery } from '../../hooks';
import { color } from '../../styles/color';
import { Close, Info } from '../shapes';

export type NotesStatus = 'info' | 'warning' | 'success' | 'danger' | 'invert';

type IconPosition = 'start' | 'center';
export interface INotesProps extends Omit<AlertProps, 'type' | 'showIcon'> {
  /**
   * @param {'info' | 'warning' | 'success' | 'danger' | 'invert'} [status];
   */
  status?: NotesStatus;
  closable?: boolean;
  icon?: ReactNode;
  title: string | ReactNode;
  message?: string | ReactNode;
  fullWidth?: boolean;
  showIcon?: boolean;
  iconPosition?: IconPosition;
}

const styled = {
  fullWidth: css`
    width: 100%;
  `,
  alertIcon: (position: IconPosition) => css`
    align-self: ${position};
  `,
  alert: (description: any, clickable: boolean) => css`
    width: fit-content;
    padding: 0.625rem 0.875rem;
    border-radius: 8px;
    display: flex;
    align-items: ${description ? 'flex-start' : 'center'};
    cursor: ${clickable ? 'pointer' : 'default'};
    max-width: 100%;

    .ant-alert-message {
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }
  `,
  textWrapper: css`
    color: var(--text-primary);
    display: inline;
    width: 100%;
  `,
  message: css`
    margin-bottom: 0.25rem;
  `,
};

const colors = {
  info: {
    bg: css`
      background-color: var(--blue-100);
      border: 1px solid var(--blue-100);
    `,
    icon: color.blue500,
  },
  warning: {
    bg: css`
      background-color: var(--yellow-100);
      border: 1px solid var(--yellow-100);
    `,
    icon: color.yellow500,
  },
  success: {
    bg: css`
      background-color: var(--green-100);
      border: 1px solid var(--green-100);
    `,
    icon: color.green500,
  },
  danger: {
    bg: css`
      background-color: var(--red-100);
      border: 1px solid var(--red-100);
    `,
    icon: color.red500,
  },
  invert: {
    bg: css`
      background-color: var(--neutral-100);
      border: 1px solid var(--neutral-200);
    `,
    icon: color.blue500,
  },
};

function Notes(props: INotesProps) {
  const {
    message,
    status = 'invert',
    closable = false,
    title,
    className,
    onClick,
    icon,
    fullWidth = true,
    showIcon = false,
    iconPosition = 'center',
    ...resProps
  } = props;

  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const notesClass = cx(
    'notes',
    styled.alert(message, !!onClick),
    colors[status].bg,
    className,
    { [styled.fullWidth]: fullWidth },
  );

  const iconContainerClass = cx(styled.alertIcon(iconPosition));

  const messageWrapperClass = cx(
    styled.textWrapper,
    !!message && styled.message,
    'notes-message',
    {
      'xs-text': !message,
      'sm-bold-text': !!message,
    },
  );

  function renderTitle() {
    return (
      <div className={messageWrapperClass}>
        <span>{title}</span>
      </div>
    );
  }

  function renderDescription() {
    if (typeof message === 'string') {
      return (
        <div className={cx(styled.textWrapper, 'xs-text')}>
          <span>{message}</span>
        </div>
      );
    }

    return message;
  }

  return (
    <AlertAntd
      icon={
        icon ? (
          <div className={iconContainerClass}>{icon}</div>
        ) : (
          <div className={iconContainerClass}>
            <Info
              width={isMobileSize ? 20 : 24}
              height={isMobileSize ? 20 : 24}
              fill={colors[status].icon}
            />
          </div>
        )
      }
      className={notesClass}
      description={renderDescription()}
      message={renderTitle()}
      closable={closable}
      onClick={onClick}
      closeText={
        closable && <Close width={22} height={22} fill={color.neutral500} />
      }
      showIcon={showIcon}
      {...resProps}
    />
  );
}

export { Notes };
export default Notes;
