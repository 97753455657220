import { useGetAccountInfo } from '@api-hooks/common';
import { queryClient } from '@common/client';
import { css, cx } from '@emotion/css';
import { DefaultLayoutProvider, useAnalytics, useDefaultConfig } from '@hooks';
import { ObjToCssString } from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { navbarSize, screenSize } from '@topremit/shared-web/common/size';
import {
  useMediaQuery,
  useNotification,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { SizeScreenTabletMd } from '@topremit-ui/design-tokens';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

import DefaultHeader from './DefaultHeader';
import { handleIsMobileConfig } from './helper';
import type { DefaultLayoutConfig, IDefaultLayoutProps } from './layout.typing';

const Footer = dynamic(() => import('./footer/Footer'));
const LoadingScreen = dynamic(() => import('./LoadingScreen'));
const Error = dynamic(() => import('./error/Error'));

export default function DefaultLayout({
  children,
  config,
}: IDefaultLayoutProps) {
  const baseConfig = {
    isFullscreen: false,
    isHideFooter: false,
    isHideHeader: false,
    pageViewType: undefined,
    ...config,
  };
  const headerRef = useRef<HTMLElement>();
  const router = useRouter();
  const { onPageView } = useAnalytics();
  const { lang } = useTranslation();
  const [defaultConfig, setDefaultConfig] =
    useState<DefaultLayoutConfig>(baseConfig);
  const isMobileApp = isNativeApp(router);
  const isMobileSize = useMediaQuery(`(max-width:${SizeScreenTabletMd}px)`); // Note: Handle config when mobile size

  const {
    error: {
      errorHolder: { isAppError, statusCode },
      setErrorHolder,
    },
  } = useNotification();

  const { isFullscreen, isHideFooter, isHideHeader, pageViewType } =
    defaultConfig || {};

  const stringfyConfig = JSON.stringify(config);
  const shouldHideHeader = handleIsMobileConfig(isHideHeader, isMobileSize);
  const shouldHideFooter = handleIsMobileConfig(isHideFooter, isMobileSize);
  const isVisibleHeader = !shouldHideHeader && !isFullscreen && !isMobileApp;
  const isVisibleFooter = !shouldHideFooter && !isFullscreen && !isMobileApp;
  const [isAtTop, setIsAtTop] = useState(true);

  const isBusinessPath = router.pathname === '/business';
  const isAboutUsPath = router.pathname === '/about-us';
  const isTransparentNavbarPath = isBusinessPath || isAboutUsPath;
  const isTransparentNavbar = isTransparentNavbarPath && isAtTop;
  const isErrorPage = isAppError && statusCode && !isMobileApp;

  useGetAccountInfo();
  useDefaultConfig(config);

  useEffect(() => {
    queryClient.invalidateQueries('me');
  }, [lang]);

  useEffect(() => {
    const body = document.querySelector('body');
    body?.setAttribute(
      'style',
      ObjToCssString({
        overflowY: 'auto',
      }),
    );
  }, [router.isReady]);

  useEffect(() => {
    const hasPageViewType = typeof pageViewType !== 'undefined';
    if (router.isReady && hasPageViewType) {
      onPageView(pageViewType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady]);

  // Listen for scroll events and update navbar transparency
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsAtTop(false);
      } else {
        setIsAtTop(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  /** Check storage permission */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isStoragePermissionDisabled = localStorage?.isDisabled?.();

      if (isStoragePermissionDisabled) {
        setErrorHolder({ isAppError: true, statusCode: 502, message: '' });
        setDefaultConfig((defConfig) => ({ ...defConfig, isHideFooter: true }));
      }
    }
  }, [setErrorHolder]);

  useEffect(() => {
    // Note: sync config from page to context
    setDefaultConfig(baseConfig);
  }, [stringfyConfig]);

  return (
    <DefaultLayoutProvider value={[defaultConfig, setDefaultConfig]}>
      {isVisibleHeader && (
        <DefaultHeader
          ref={headerRef}
          showAnnouncement
          hasBackground={!isTransparentNavbar}
        />
      )}
      <LoadingScreen />
      {isErrorPage ? (
        <Error statusCode={statusCode as number} />
      ) : (
        <div className={styled.minimalContent}>
          <main
            className={cx(styled.main(isTransparentNavbarPath, isMobileApp), {
              [styled.noHeaderMargin]: isFullscreen || isMobileApp,
            })}
          >
            {children}
          </main>
        </div>
      )}
      {isVisibleFooter && <Footer />}
    </DefaultLayoutProvider>
  );
}

const styled = {
  main: (isTransparentNavbarPath: boolean, isMobileSite: boolean) => css`
    padding-top: ${isTransparentNavbarPath ? '0' : `${navbarSize.md}px`};
    @media (max-width: ${screenSize.mobileLg}px) {
      padding-top: ${!isMobileSite
        ? isTransparentNavbarPath
          ? '0'
          : navbarSize.sm
        : 0}px;
    }
  `,
  noHeaderMargin: css`
    margin-top: 0 !important;
  `,
  minimalContent: css`
    min-height: calc(80vh - ${navbarSize.md}px);
    @media (max-width: ${screenSize.tabletMd}px) {
      min-height: calc(80vh - ${navbarSize.sm}px);
    }
  `,
};
