import { ClassNamesArg, css, cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { Angle } from '@global-shapes';
import { Button } from '@topremit/shared-web/components/elements';
import { ReactNode, useEffect, useRef, useState } from 'react';

type Direction = 'left' | 'right';

interface Props {
  showButton?: boolean;
  className?: ClassNamesArg;
  scrollIntervalPx?: number;

  prevButton?: ReactNode;
  nextButton?: ReactNode;
  children?: ReactNode;
}

export default function HorizontalSliderV2({
  showButton = true,
  className,
  prevButton,
  nextButton,
  scrollIntervalPx = 312,
  children,
}: Props) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isLeftEnd, setIsLeftEnd] = useState(true);
  const [isRightEnd, setIsRightEnd] = useState(false);

  const showBtnLeft = showButton && !isLeftEnd;
  const showBtnRight = showButton && !isRightEnd;

  const _children = Array.isArray(children) ? children : [children];

  useEffect(() => {
    function checkScrollPosition() {
      if (!wrapperRef.current) return;
      setIsLeftEnd(wrapperRef.current.scrollLeft === 0);
      setIsRightEnd(
        wrapperRef.current.scrollLeft + wrapperRef.current.clientWidth >=
          wrapperRef.current.scrollWidth,
      );
    }

    const element = wrapperRef.current;
    element?.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition();

    return () => {
      element?.removeEventListener('scroll', checkScrollPosition);
    };
    /** use children length to detect wrapper width  */
  }, [_children?.length]);

  function handleClick(direction: Direction) {
    const left = (direction === 'left' ? -1 : 1) * scrollIntervalPx;
    wrapperRef.current?.scrollBy({ left, behavior: 'smooth' });
  }

  return (
    <Flex className={styled.root}>
      <Flex ref={wrapperRef} className={cx(styled.contentWrapper, className)}>
        {children}
      </Flex>

      {showBtnLeft && (
        <div className="btn btn-prev" onClick={() => handleClick('left')}>
          {prevButton || <ArrowButton direction="left" />}
        </div>
      )}

      {showBtnRight && (
        <div className="btn btn-next" onClick={() => handleClick('right')}>
          {nextButton || <ArrowButton direction="right" />}
        </div>
      )}
    </Flex>
  );
}

function ArrowButton({ direction }: { direction: 'left' | 'right' }) {
  return (
    <Button
      size="small"
      type="invert"
      shape="round"
      id={direction}
      leftIcon={<Angle size="small" direction={direction} />}
    />
  );
}

const styled = {
  /** remove after use Button */
  button: css`
    width: 24px;
    height: 24px;
    border: 1px solid #e7ecf3 !important;

    padding: 8px;
    border-radius: 100%;
    background: white;
  `,
  root: css`
    position: relative;
    .btn {
      top: 50%;
      z-index: 9;
      position: absolute;
      height: fit-content;
      transform: translateY(-50%);
    }
    .btn-prev {
      left: 1rem;
    }
    .btn-next {
      right: 1rem;
    }
  `,
  contentWrapper: css`
    overflow-x: scroll;
    padding: 0.25rem;
    gap: 1rem;
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
      display: none; /* Chrome, Safari */
    }
  `,
  btnWrapper: css`
    position: absolute;
    z-index: 9999;
    inset: 0;
    right: 0;
  `,
};
