import { css, cx } from '@emotion/css';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { useDropdown } from '../../../hooks';
import Text from '../Text';
import { dropdownVariant } from '../constants';

interface IDropdownListProps {
  title?: string;
  className?: string;
  totalItem?: number;
  children: ReactNode;
}

export default function DropdownList({
  title,
  className,
  totalItem,
  children,
}: IDropdownListProps) {
  const { isOpen } = useDropdown();

  const animateDropdown = isOpen ? 'open' : 'close';

  if (!children) {
    throw new Error('Dropdown must have at least one child');
  }

  return (
    <AnimatePresence>
      <motion.div
        initial="close"
        animate={animateDropdown}
        variants={dropdownVariant}
        className={cx(styled.root, className)}
      >
        {title && (
          <Text className={cx(styled.title, 'bold-text')}>{title}</Text>
        )}
        <div className={styled.itemWrapper(totalItem || 0)}>{children}</div>
      </motion.div>
    </AnimatePresence>
  );
}

const styled = {
  root: css`
    position: absolute;
    background-color: var(--neutral-0);
    top: 50px;
    border-radius: 1.125rem;
    padding: 0.5rem;
    box-shadow: 0 2px 8px rgba(46, 72, 101, 0.16);
    z-index: 1;
  `,
  title: css`
    padding: 1rem;
    margin-bottom: 0.5rem;
  `,
  itemWrapper: (totalItem: number) => css`
    display: grid;
    gap: 0.5rem;
    grid-auto-flow: ${totalItem > 5 ? 'column' : 'row'};
    grid-template-columns: ${totalItem > 5 ? '1fr 1fr' : '1fr'};
    grid-template-rows: ${totalItem > 5 ? 'repeat(4, auto)' : 'auto'};
    .dropdown-item {
      cursor: pointer;
      align-items: center;
      padding: 0.5rem 1rem;
      transition: all 0.2s ease;
      border-radius: 1rem;
      &.selected,
      &:hover {
        background: var(--blue-100);
      }
    }
    .link {
      color: var(--text-primary);
    }
  `,
};
