import { Suspense, SuspenseProps } from 'react';

import ContainerSpinner from './ContainerSpinner';

export default function SuspenseWithSpinner(props: SuspenseProps) {
  const { children, ...rest } = props;

  return (
    <Suspense fallback={<ContainerSpinner />} {...rest}>
      {children}
    </Suspense>
  );
}
