
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { queryClient } from '@common/client';
import { mulish } from '@common/font';
import { useServiceWorker } from '@hooks';
import { MobilePermission, type LayoutPropsType, type PageWithLayoutType, } from '@layouts';
import GoogleTagManagerBusiness from '@layouts/third-parties/GoogleTagManagerBusiness';
import GoogleTagManagerPersonal from '@layouts/third-parties/GoogleTagManagerPersonal';
import { fontSize, fontWeight, lineHeight, space, } from '@topremit/shared-web/common/size';
import Dialog from '@topremit/shared-web/components/Dialog/Dialog';
import { FilterProvider, GlobalStateProvider, LoadingProvider, MultiRouteCoachmarkProvider, NotificationProvider, useClarity, useTranslation, } from '@topremit/shared-web/hooks';
import GlobalStyle from '@topremit/shared-web/styles/app';
import { color } from '@topremit/shared-web/styles/color';
import { locales } from 'landing/i18n';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { FC } from 'react';
import { Hydrate, QueryClientProvider } from 'react-query';
import DefaultLayout from '../components/layouts/DefaultLayout';
import ErrorBoundary from '../components/layouts/error-boundary/ErrorBoundary';
import { AffiliateWithdrawProvider } from '../hooks/use-affiliate-withdraw';
import { AnalyticsProvider } from '../hooks/use-analytics';
import { AuthProvider } from '../hooks/use-auth';
import { BannerProvider } from '../hooks/use-banner';
import { RecipientProvider } from '../hooks/use-recipient';
import localStoragePolyfill from '../polyfills/local-storage-polyfill';
import AntdTheme from '../styles/AntdTheme';
import localStyles from '../styles/styles';
import '@topremit-ui/design-tokens/dist/css/_variables.css';
import '@topremit-ui/pagination/dist/index.esm.css';
import '@topremit-ui/searchbar/dist/index.esm.css';
localStoragePolyfill.init();
declare global {
    interface Window {
        version: string;
        FreshChat: any;
        Moengage: any;
    }
}
type AppLayoutProps = AppProps & {
    Component: PageWithLayoutType;
};
const Moengage = dynamic(() => import('../components/layouts/third-parties/moengage'), { ssr: false });
const ReactQueryDevtools = dynamic(() => import('react-query/devtools').then((d) => ({
    default: d.ReactQueryDevtools,
})));
const FloatingChatButton = dynamic(() => import('../components/elements/chat-cs/FloatingChatButton'), {
    ssr: false,
});
function cleanUpPathSearch(pathWithSearch: string) {
    const url = new URL(process.env.NEXT_PUBLIC_BASE_URL + pathWithSearch);
    return url.pathname;
}
function MyApp({ Component, pageProps }: AppLayoutProps) {
    const { t, lang } = useTranslation();
    const router = useRouter();
    const Layout = (Component.pageLayout || DefaultLayout) as FC<LayoutPropsType>;
    const config = 'pageLayoutConfig' in Component ? Component?.pageLayoutConfig : undefined;
    const layout = Layout.toString().includes('DefaultLayout')
        ? 'DefaultLayout'
        : 'DashboardLayout';
    const path = cleanUpPathSearch(router.asPath);
    const pathName = path === '/' ? '' : path;
    const alternateLinks = locales.map((locale) => {
        if (locale === 'default') {
            const DEFAULT_LOCALE = 'en';
            return {
                hrefLang: 'x-default',
                href: `${process.env.NEXT_PUBLIC_BASE_URL}/${DEFAULT_LOCALE}${pathName}`,
            };
        }
        return {
            hrefLang: locale,
            href: `${process.env.NEXT_PUBLIC_BASE_URL}/${locale}${pathName}`,
        };
    });
    useClarity();
    useServiceWorker();
    return (<div className={mulish.className}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"/>
        <meta name="apple-mobile-web-app-capable" content="yes"/>
        <meta name="mobile-web-app-capable" content="yes"/>
        <meta name="thumbnail" content={`${process.env.NEXT_PUBLIC_BASE_URL}/images/png/og-meta-thumbnail-${lang}.png`}/>
      </Head>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <LoadingProvider>
            <NotificationProvider>
              <AuthProvider>
                <AnalyticsProvider>
                  <GlobalStateProvider>
                    <RecipientProvider>
                      <AffiliateWithdrawProvider>
                        <FilterProvider>
                          <AntdTheme>
                            <MultiRouteCoachmarkProvider>
                              {/* Load Scripts */}
                              <GoogleTagManagerPersonal />
                              <GoogleTagManagerBusiness />
                              <Moengage />
                              <FloatingChatButton />
                              <BannerProvider>
                                <ErrorBoundary layout={layout}>
                                  <MobilePermission>
                                    <Layout config={config}>
                                      {/* To ensure that the default SEO works effectively in Next.js 13, it should be placed in the _app.js file and positioned above the component.
    Documentation: https://github.com/garmeeh/next-seo/blob/4c69f9b8c9755aa12cb247c59630282508502073/README.md */}
                                      <DefaultSeo defaultTitle={t('meta.default.title')} description={t('meta.default.description')} openGraph={{
            locale: lang,
            type: 'website',
            url: `${process.env.NEXT_PUBLIC_BASE_URL}/${lang}`,
            title: t('meta.default.title'),
            description: t('meta.default.description'),
            images: [
                {
                    url: `${process.env.NEXT_PUBLIC_BASE_URL}/images/png/og-meta-thumbnail-${lang}.png`,
                    alt: t('meta.default.og_alt_image'),
                },
            ],
        }} twitter={{
            site: '@topremit',
            cardType: 'summary',
        }} canonical={`${process.env.NEXT_PUBLIC_BASE_URL}/${lang}${pathName}`} languageAlternates={alternateLinks}/>
                                      <Component {...pageProps}/>
                                      <Dialog />
                                    </Layout>
                                  </MobilePermission>
                                </ErrorBoundary>
                              </BannerProvider>
                            </MultiRouteCoachmarkProvider>
                          </AntdTheme>
                        </FilterProvider>
                      </AffiliateWithdrawProvider>
                    </RecipientProvider>
                  </GlobalStateProvider>
                </AnalyticsProvider>
              </AuthProvider>
            </NotificationProvider>
          </LoadingProvider>
        </Hydrate>
        {process.env.NODE_ENV === 'development' && (<ReactQueryDevtools initialIsOpen/>)}
      </QueryClientProvider>
      <GlobalStyle styles={[localStyles]}/>
      <style jsx global>
        {`
          :root {
            --font-size: 16px;
            --font-family: ${mulish.style.fontFamily};

            --text-primary: ${color.neutral800};
            --text-secondary: ${color.neutral500};
            --text-disabled: ${color.neutral300};

            --primary-bg: ${color.neutral0};
            --secondary-bg: ${color.neutral100};
            --link-active-bg: ${color.blue100};
            --link-active-text: ${color.blue500};

            --h1-font-size: ${fontSize.h1}rem;
            --h1-line-height: ${lineHeight.h1}px;
            --h1-font-weight: ${fontWeight.bold};

            --h2-font-size: ${fontSize.h2}rem;
            --h2-line-height: ${lineHeight.h2}px;
            --h2-font-weight: ${fontWeight.bold};

            --h3-font-size: ${fontSize.h3}rem;
            --h3-line-height: ${lineHeight.h3}px;
            --h3-font-weight: ${fontWeight.bold};

            --h4-font-size: ${fontSize.h4}rem;
            --h4-line-height: ${lineHeight.h4}px;
            --h4-font-weight: ${fontWeight.bold};

            --h5-font-size: ${fontSize.h5}rem;
            --h5-line-height: ${lineHeight.h5}px;
            --h5-font-weight: ${fontWeight.bold};

            --regular-font-size: ${fontSize.p}rem;
            --regular-line-height: ${lineHeight.p}px;
            --regular-font-weight: ${fontWeight.regular};

            --sm-font-size: ${fontSize.sm}rem;
            --sm-line-height: ${lineHeight.sm}px;
            --sm-font-weight: ${fontWeight.regular};

            --xs-font-size: ${fontSize.xs}rem;
            --xs-line-height: ${lineHeight.xs}px;
            --xs-font-weight: ${fontWeight.regular};

            --black-font-weight: ${fontWeight.black};
            --bold-font-weight: ${fontWeight.bold};
            --regular-font-weight: ${fontWeight.regular};

            --blue-900: ${color.blue900};
            --blue-800: ${color.blue800};
            --blue-700: ${color.blue700};
            --blue-600: ${color.blue600};
            --blue-500: ${color.blue500};
            --blue-400: ${color.blue400};
            --blue-300: ${color.blue300};
            --blue-200: ${color.blue200};
            --blue-100: ${color.blue100};

            --green-900: ${color.green900};
            --green-800: ${color.green800};
            --green-700: ${color.green700};
            --green-600: ${color.green600};
            --green-500: ${color.green500};
            --green-400: ${color.green400};
            --green-300: ${color.green300};
            --green-200: ${color.green200};
            --green-100: ${color.green100};

            --yellow-900: ${color.yellow900};
            --yellow-800: ${color.yellow800};
            --yellow-700: ${color.yellow700};
            --yellow-600: ${color.yellow600};
            --yellow-500: ${color.yellow500};
            --yellow-400: ${color.yellow400};
            --yellow-300: ${color.yellow300};
            --yellow-200: ${color.yellow200};
            --yellow-100: ${color.yellow100};

            --red-900: ${color.red900};
            --red-800: ${color.red800};
            --red-700: ${color.red700};
            --red-600: ${color.red600};
            --red-500: ${color.red500};
            --red-400: ${color.red400};
            --red-300: ${color.red300};
            --red-200: ${color.red200};
            --red-100: ${color.red100};

            --orange-900: ${color.orange900};
            --orange-800: ${color.orange800};
            --orange-700: ${color.orange700};
            --orange-600: ${color.orange600};
            --orange-500: ${color.orange500};
            --orange-400: ${color.orange400};
            --orange-300: ${color.orange300};
            --orange-200: ${color.orange200};
            --orange-100: ${color.orange100};

            --neutral-900: ${color.neutral900};
            --neutral-800: ${color.neutral800};
            --neutral-700: ${color.neutral700};
            --neutral-600: ${color.neutral600};
            --neutral-500: ${color.neutral500};
            --neutral-400: ${color.neutral400};
            --neutral-300: ${color.neutral300};
            --neutral-200: ${color.neutral200};
            --neutral-100: ${color.neutral100};
            --neutral-50: ${color.neutral50};
            --neutral-0: ${color.neutral0};

            --black-0: ${color.black0};

            --space-4: ${space.space4}px;
            --space-8: ${space.space8}px;
            --space-10: ${space.space10}px;
            --space-12: ${space.space12}px;
            --space-16: ${space.space16}px;
            --space-24: ${space.space24}px;
            --space-32: ${space.space32}px;
            --space-40: ${space.space40}px;
            --space-48: ${space.space48}px;
            --space-64: ${space.space64}px;

            --dashboard-layout-outer-space: clamp(
              1rem,
              -1.9090909090909092rem + 14.545454545454545vw,
              1.5rem
            );
            --dashboard-icon-menu-size: clamp(
              1.25rem,
              -0.20454545454545459rem + 7.2727272727272725vw,
              1.5rem
            );
            --dashboard-send-btn-size: clamp(
              3.75rem,
              2.2954545454545454rem + 7.2727272727272725vw,
              4rem
            );
          }
        `}
      </style>
    </div>);
}
const __Next_Translate__Page__1961e6880db__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1961e6880db__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  