import { SVGProps } from 'react';

const TransactionOutline = ({
  width = 24,
  height = 24,
  fill = 'var(--neutral-500)',
  ...props
}: SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2330_9664)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6364 3.81818H4.72727C4.2252 3.81818 3.81818 4.2252 3.81818 4.72727V17.4545C3.81818 18.9608 5.03922 20.1818 6.54545 20.1818H16.5455V4.72727C16.5455 4.2252 16.1384 3.81818 15.6364 3.81818ZM4.72727 2C3.22104 2 2 3.22104 2 4.72727V17.4545C2 19.9649 4.03507 22 6.54545 22H18.3636V4.72727C18.3636 3.22104 17.1426 2 15.6364 2H4.72727Z"
        fill={fill}
      />
      <path
        d="M5.63623 8.36368C5.63623 7.8616 6.04324 7.45459 6.54532 7.45459H13.818C14.3201 7.45459 14.7271 7.8616 14.7271 8.36368V8.36368C14.7271 8.86576 14.3201 9.27277 13.818 9.27277H6.54532C6.04324 9.27277 5.63623 8.86576 5.63623 8.36368V8.36368Z"
        fill={fill}
      />
      <path
        d="M5.63623 11.9999C5.63623 11.4978 6.04324 11.0908 6.54532 11.0908H13.818C14.3201 11.0908 14.7271 11.4978 14.7271 11.9999V11.9999C14.7271 12.502 14.3201 12.909 13.818 12.909H6.54532C6.04324 12.909 5.63623 12.502 5.63623 11.9999V11.9999Z"
        fill={fill}
      />
      <path
        d="M5.63623 15.6364C5.63623 15.1343 6.04324 14.7273 6.54532 14.7273H10.1817C10.6838 14.7273 11.0908 15.1343 11.0908 15.6364V15.6364C11.0908 16.1385 10.6838 16.5455 10.1817 16.5455H6.54532C6.04324 16.5455 5.63623 16.1385 5.63623 15.6364V15.6364Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1819 16.6363C20.1819 16.084 19.7342 15.6363 19.1819 15.6363H18.3637L18.3637 18.1818C18.3637 19.2863 19.4825 20.1725 19.9844 19.1885C20.1107 18.9409 20.1819 18.6606 20.1819 18.3636V16.6363ZM16.5455 13.8181V21.9999H18.3637C20.372 21.9999 22.0001 20.3719 22.0001 18.3636V15.6363C22.0001 14.6321 21.1861 13.8181 20.1819 13.8181H16.5455Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2330_9664">
        <rect width={20} height={20} fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default TransactionOutline;
