import { css, cx } from '@emotion/css';
import { Badge as BadgeAntd, BadgeProps } from 'antd';
import { ReactNode } from 'react';

import { color } from '../../styles/color';

export type BadgeColors = 'default' | 'blue' | 'yellow' | 'green';
export interface IBadgeProps extends Omit<BadgeProps, 'size'> {
  /**
   * @param {BadgeColors} [type] default
   */
  color?: BadgeColors;
  children?: ReactNode;
  count?: number | string | ReactNode;
  overflowCount?: number;
  size?: number;
  visible?: boolean;
  variant?: 'badge' | 'standalone';
}

const colors = {
  default: color.red500,
  blue: color.blue500,
  yellow: color.yellow500,
  green: color.green500,
};

const styled = {
  badge: css`
    min-width: 10px;
    min-height: 10px;
    border-radius: 100px;
    width: fit-content;
    overflow: visible !important;
  `,
  badgeStandalone: css`
    min-width: 1.125rem;
    min-height: 1.125rem;
    width: fit-content;
    > sup {
      font-size: 0.875rem !important;
      padding: 0px 6px;
    }
  `,
};

const badgeColorClass = (
  color: 'default' | 'blue' | 'yellow' | 'green' = 'default',
) => {
  return colors[color];
};

const Badge = (props: IBadgeProps) => {
  const {
    color,
    children,
    count,
    dot,
    overflowCount,
    className,
    style,
    size,
    visible = true,
    variant = 'badge',
    ...resProps
  } = props;

  const badgeClass = cx(
    'xs-text',
    { [styled.badge]: variant === 'badge' },
    { [styled.badgeStandalone]: variant === 'standalone' },
    className,
  );

  return visible ? (
    <BadgeAntd
      dot={dot}
      count={count}
      overflowCount={overflowCount}
      style={{
        backgroundColor: badgeColorClass(color),
        width: size,
        height: size,
        ...style,
      }}
      className={badgeClass}
      offset={[0, 8]}
      {...(variant === 'standalone' && children && { offset: [10, 0] })}
      {...resProps}
    >
      {children}
    </BadgeAntd>
  ) : (
    <>{children}</>
  );
};

export { Badge };
