import DefaultLayout from '@layouts/DefaultLayout';
import DashboardLayout from '@layouts/dashboard/DashboardLayout';
import { Error } from '@layouts/error';

interface Props {
  layout?: 'DefaultLayout' | 'DashboardLayout';
}

export default function ErrorBoundaryPage({ layout = 'DefaultLayout' }: Props) {
  const isDashboardLayout = layout === 'DashboardLayout';
  const LayoutComponent = isDashboardLayout ? DashboardLayout : DefaultLayout;

  return (
    <LayoutComponent
      {...(!isDashboardLayout && {
        config: { isHideFooter: true },
      })}
      {...(isDashboardLayout && {
        config: { isHideSideBar: true, isWhiteBackground: true },
      })}
    >
      <Error statusCode={500} />
    </LayoutComponent>
  );
}
