import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';

import { ISectionFieldGroupProps } from './types';

const { tabletSm, tabletMd } = screenSize;

export default function SectionDetailsWrapper({
  className,
  children,
}: ISectionFieldGroupProps) {
  return <div className={cx(styled.root, className)}>{children}</div>;
}

const styled = {
  root: css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
    gap: 0.75rem 1rem;
    @media (max-width: ${tabletMd}px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: ${tabletSm}px) {
      grid-template-columns: 1fr;
    }
  `,
};
