import { create } from 'zustand';

import { SetStateStore } from './type';
import { IDialogProps } from '../components/Dialog/Dialog';

export interface IDialogStore {
  props: IDialogProps;
  stack: IDialogProps[];
  isOpen: boolean;
  isCloseable: boolean; // disable close all dialog close action

  close: () => void; //for pop all stacks
  show: (param: SetStateStore<IDialogProps>) => void; //for only show/rerender last stack
  push: (param: SetStateStore<IDialogProps>) => void; //push new stack
  pop: () => void; //pop last stack
  setCloseable: (closeable: boolean) => void;
}

function setStore<T = any>(
  state: T,
  obj: {
    [key in keyof Partial<T>]: SetStateStore<T[key]>;
  },
) {
  const _state = Object.entries(obj).reduce((acc, [key, value]) => {
    const _value = typeof value === 'function' ? value(state[key]) : value;
    return { ...acc, [key]: _value };
  }, state);

  return _state;
}

export const useDialogStore = create<IDialogStore>((set) => ({
  props: {},
  stack: [],
  isOpen: false,
  isCloseable: true,
  close: () =>
    set((store) =>
      setStore(store, {
        props: {},
        stack: [],
        isOpen: false,
      }),
    ),
  show: (props: IDialogProps) =>
    set((store) => {
      if (store.stack.length === 0) {
        const _stack = [props];
        return setStore(store, {
          props,
          stack: _stack,
          isOpen: true,
        });
      }

      const _stack = store.stack.slice(0, -1);
      const _props = {
        ...(_stack.length > 1 && { onBack: true }),
        ...(typeof props === 'function'
          ? setStore(store, { props }).props
          : props),
      };

      _stack.push(_props);

      return setStore(store, {
        props: _props,
        stack: _stack,
        isOpen: true,
      });
    }),
  push: (props: IDialogProps) =>
    set((store) =>
      setStore(store, {
        props,
        stack: [...store.stack, { ...props, onClose: store.pop }],
        isOpen: true,
      }),
    ),
  pop: () =>
    set((store) => {
      const _stack = store.stack.slice(0, -1);
      const _stackLength = _stack.length;
      const _props = _stackLength - 1 >= 0 ? _stack[_stackLength - 1] : {};
      return setStore(store, {
        props: _props,
        stack: _stack,
        isOpen: _stackLength > 0,
      });
    }),
  setCloseable: (closeable) => set(() => ({ isCloseable: closeable })),
}));
