import { css, cx } from '@emotion/css';
import React from 'react';

function ErrorMessage({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <span className={cx(styled.root, 'error-text', className)}>{children}</span>
  );
}

const styled = {
  root: css`
    display: inline-block;
    margin-top: 0.5rem;
    opacity: 0;
    transform: translateY(-10px);
    animation: fadeInDown 0.5s forwards;

    @keyframes fadeInDown {
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }
  `,
};

export default ErrorMessage;
