import { createContext, useContext } from 'react';

const ContentCardContext = createContext<boolean | null>(null);

function useContentCardContext() {
  const context = useContext(ContentCardContext);
  if (context === null) {
    throw new Error('This component must be used inside a <ContentCard>');
  }
  return context;
}

export { ContentCardContext, useContentCardContext };
