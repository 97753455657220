import * as React from 'react';
import { SVGProps } from 'react';
const Lightning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={14}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M7.987 5.972a.272.272 0 0 0-.1-.143.247.247 0 0 0-.16-.049H5.273l.013-.04A94450.488 94450.488 0 0 1 7.253.377a.267.267 0 0 0-.059-.294A.72.72 0 0 0 7.055 0H6.98a.343.343 0 0 0-.157.112A45829.867 45829.867 0 0 0 .81 7.362a.264.264 0 0 0-.052.109.286.286 0 0 0 .056.228.257.257 0 0 0 .092.073.242.242 0 0 0 .113.022H2.953l-.108.215-2.702 5.38c-.05.101-.095.207-.143.31l.006.098a.323.323 0 0 0 .08.128.298.298 0 0 0 .126.075h.091a.613.613 0 0 0 .119-.076 17765.115 17765.115 0 0 0 7.505-7.696.25.25 0 0 0 .06-.256Z"
    />
  </svg>
);
export default Lightning;
