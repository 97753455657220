import {
  createContext,
  Dispatch,
  ProviderProps,
  SetStateAction,
  useContext,
} from 'react';

import { assertContextCalledInProvider } from '../common/assertion';

export type ISidebarProviderValue = [
  {
    isExpand: boolean;
  },
  { setIsExpand: Dispatch<SetStateAction<boolean>> },
];

export const SidebarContext = createContext<ISidebarProviderValue>([
  {
    isExpand: true,
  },
  { setIsExpand: () => {} },
]);

export function SidebarProvider({
  children,
  value,
}: ProviderProps<ISidebarProviderValue>) {
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}

export function useSidebar() {
  const ctx = useContext(SidebarContext);

  assertContextCalledInProvider<ISidebarProviderValue>(ctx);

  return ctx;
}
