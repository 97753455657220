import { cx, css } from '@emotion/css';
import { Radio as RadioAntd, RadioGroupProps, RadioProps } from 'antd';
import { ReactNode, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { callAllFunctions } from '../../../common/helper';

export type RadioType = 'button' | 'default' | 'card' | 'pills' | 'radioCard';

const { Group, Button } = RadioAntd;

export interface IRadioGroupProps extends Omit<RadioGroupProps, 'optionType'> {
  name: string;
  /**
   * If you want to render custom options, don't use this prop.
   * @description If your options type is ArrayObject please include label and value.
   */
  options?: {
    label: string | ReactNode;
    value: string | boolean;
    [key: string]: any;
  }[];
  label?: string;
  labelClass?: string;
  optionType?: 'default' | 'button' | 'card' | 'pills' | 'radioCard';
  radioClassName?: string;
  rowReverse?: boolean;
}

export interface IRadioProps extends RadioProps {
  name?: string;
  children?: ReactNode;
  /**
   * @param {boolean} [isLoading] false
   */
  isLoading?: boolean;
  type?: RadioType;
  disabled?: boolean;
}

function Radio(props: IRadioProps) {
  const { children, type, className, ...resProps } = props;
  const cardSecondary = cx('card card-secondary', styles.pill, className);

  const RenderRadio = useCallback(() => {
    switch (type) {
      case 'button':
        return (
          <Button className={className} {...resProps}>
            {children && children}
          </Button>
        );

      case 'card':
        return (
          <Button className={cardSecondary} {...resProps}>
            {children && children}
          </Button>
        );

      case 'pills':
        return (
          <Button
            className={cx('card card-secondary', styles.pills, className)}
            {...resProps}
          >
            {children}
          </Button>
        );

      case 'radioCard':
        return (
          <div className={cardSecondary}>
            <RadioAntd className={styles.radioButton} {...resProps}>
              <div className={className}>{children}</div>
            </RadioAntd>
          </div>
        );

      default:
        return (
          <RadioAntd className={className} {...resProps}>
            {children && children}
          </RadioAntd>
        );
    }
  }, [type, className, resProps, children, cardSecondary]);

  return <RenderRadio />;
}

function RadioInput(props: IRadioGroupProps) {
  const {
    id,
    name,
    value,
    onChange,
    className,
    options,
    label,
    labelClass,
    children,
    optionType = 'default',
    radioClassName,
    rowReverse,
    ...resProps
  } = props;

  const formContext = useFormContext();
  const { control } = formContext;

  const { field } = useController({
    name,
    control,
  });

  const { onChange: fieldOnChange, ...resFieldProps } = field;

  const inputClass = cx(className, 'regular-text');

  const radioValue = value || field.value;

  function renderDefaultOptions() {
    if (options) {
      return options.map((option, i) => (
        <Radio
          key={i}
          value={option.value}
          disabled={option.disabled as boolean}
          type={optionType}
          className={radioClassName}
        >
          <span>{option.label}</span>
        </Radio>
      ));
    }

    return children;
  }

  return (
    <div className={cx(styles.root(rowReverse), 'form-group')}>
      {!!label && (
        <div className={cx(styles.inputLabel, labelClass, 'radio-group-label')}>
          {label}
        </div>
      )}
      <Group
        {...resFieldProps}
        id={id}
        className={cx(
          { [styles.checkedRadioCard]: optionType === 'radioCard' },
          inputClass,
        )}
        name={name}
        value={radioValue}
        onChange={callAllFunctions(fieldOnChange, onChange)}
        {...resProps}
      >
        {renderDefaultOptions()}
      </Group>
    </div>
  );
}

const styles = {
  pill: css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10.375rem;
    min-height: 3.875rem;
    &.ant-radio-button-wrapper {
      border-radius: 0.875rem;
    }
    &.ant-radio-button-wrapper-checked:not(
        [class*=' ant-radio-button-wrapper-disabled']
      ) {
      &::before {
        background-color: transparent;
      }
      border: 1px solid var(--blue-500) !important;
      color: var(--blue-500) !important;
    }
    &.ant-radio-button-wrapper:not(:first-child)::before {
      background-color: transparent;
    }
    &:hover:not(&:disabled) {
      color: var(--blue-500);
      border: 1px solid var(--blue-500);
    }
  `,
  pills: css`
    width: fit-content;
    min-height: 2.125rem;
    &.ant-radio-button-wrapper,
    &.ant-radio-button-wrapper:first-child:last-child {
      border-radius: 6.25rem;
      font-size: 0.875rem;
      padding: 0.4375rem 0.875rem;
    }
    &.ant-radio-button-wrapper-checked:not(
        [class*=' ant-radio-button-wrapper-disabled']
      ) {
      &::before {
        background-color: transparent;
      }
      border: 1px solid var(--blue-100) !important;
      color: var(--blue-500) !important;
      background-color: var(--blue-100);
    }
    &.ant-radio-button-wrapper:not(:first-child)::before {
      width: 0;
    }
    &:not(.ant-radio-button-wrapper-checked) {
      color: var(--text-secondary);
    }
    :not(:last-child) {
      margin-right: 1rem;
    }
  `,
  radioButton: css`
    display: flex;
    flex-direction: column;
    margin-right: 0;
    > .ant-radio {
      display: flex !important;
      justify-content: flex-end;
      width: 100%;
    }
    > span:last-child {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `,
  root: (rowReverse) => css`
    margin: 0;
    .ant-radio-button-wrapper-disabled {
      opacity: 0.4;
    }
    .ant-radio {
      margin-left: ${rowReverse ? 'auto' : '0'};
      margin-right: ${rowReverse ? 0 : '0.625rem'};
    }
    .ant-radio-wrapper {
      display: flex;
      width: 100%;
      flex-direction: ${rowReverse ? 'row-reverse' : 'row'};
      span {
        padding: 0;
      }
    }
  `,
  inputLabel: css`
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 8px;
  `,
  checkedRadioCard: css`
    flex-wrap: wrap;
    > div:has(input[type='radio']:checked) {
      border-color: var(--blue-500);
    }
  `,
};

export default RadioInput;
