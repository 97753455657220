import { useDialogStore } from '@global-stores';
import { useEffect } from 'react';

interface Props {
  onBackBrowser?: boolean;
}

const useDialogBack = ({ onBackBrowser }: Props) => {
  const closeDialog = useDialogStore((store) => store.close);
  useEffect(() => {
    function handleBackBrowser() {
      if (onBackBrowser) {
        closeDialog();
      }
    }

    window.addEventListener('popstate', handleBackBrowser);

    return () => {
      window.removeEventListener('popstate', handleBackBrowser);
    };
  }, [closeDialog, onBackBrowser]);
};

export default useDialogBack;
