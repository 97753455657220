const RadioActive = ({
  activeColor = 'var(--blue-500)',
  size = 24,
}: {
  size?: number;
  activeColor?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z"
      fill={activeColor}
    />
    <circle cx={12} cy={12} r={6} fill="white" />
  </svg>
);

export default RadioActive;
