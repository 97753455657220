import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import {
  RequiredDocumentMeta,
  UpdateDataDraftResponse,
} from '@modules/dashboard/profile/types';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { flattenSearchParam } from '@topremit/shared-web/common/helper';
import { UseQueryOptions } from 'react-query';

import { Member, MemberParam, MemberRole } from './member.model';

export function useGetMembers(
  param: MemberParam,
  options?: UseQueryOptions<ApiResult<Member[]>, ApiError>,
) {
  return useCustomQuery<Member[]>(
    ['members', param],
    async () =>
      await requestFn(
        {
          path: 'members/manage',
          method: 'get',
        },
        {
          searchParams: flattenSearchParam(param),
        },
      ),
    { ...options },
  );
}

export function useGetMember(
  id: string,
  options?: UseQueryOptions<ApiResult<Member>, ApiError>,
) {
  return useCustomQuery<Member>(
    ['members', id],
    async () =>
      await requestFn({
        path: `members/manage/${id}`,
        method: 'get',
      }),
    { enabled: !!id, ...options },
  );
}

export function useGetMemberRoles(
  options?: UseQueryOptions<ApiResult<MemberRole[]>, ApiError>,
) {
  return useCustomQuery<MemberRole[]>(
    ['member-role'],
    async () =>
      await requestFn({
        path: 'members/manage/roles',
        method: 'get',
      }),
    { staleTime: Infinity, ...options },
    /** Expected to fetch only once */
  );
}

export function useGetUpdateDataDraft(
  options?: UseQueryOptions<
    ApiResult<UpdateDataDraftResponse, RequiredDocumentMeta>,
    ApiError
  >,
) {
  return useCustomQuery(
    ['update-data'],
    () =>
      requestFn({
        path: 'update-data',
        method: 'get',
      }),
    { ...options },
  );
}
