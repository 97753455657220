import {
  Account,
  AccountInfo,
  AccountInfoBusiness,
  AccountType,
  KycStatus,
  useGetAccountInfo,
  useGetMe,
} from '@api-hooks/common';
import { queryClient } from '@common/client';
import { css, cx } from '@emotion/css';
import { useDashboardLayout, usePermission } from '@hooks';
import LanguageMenu from '@layouts/LanguageMenu';
import { getStatusKycAbad, KycAbadStatus, Logout } from '@modules';
import { useDomesticFlowStore } from '@modules/dashboard/calculator/domestic/hooks/use-domestic-flow-store';
import accountBusinessImage from '@public/images/svg/account-business.svg';
import accountPersonalImage from '@public/images/svg/account-personal.svg';
import topremitLogoImage from '@public/images/webp/topremit.webp';
import { useClientOnboardingStore, useCurrentAccountStore } from '@stores';
import {
  Angle,
  Button,
  Divider,
  Flex,
  IStatusProps,
  ResponsiveImage,
  SkeletonWrapper,
  Status,
  Text,
  TopBar,
  TopMenuItem,
  TopMenuLogo,
} from '@topremit/shared-web';
import { BankAccountMatchingStatus } from '@topremit/shared-web/api-hooks/common';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { screenSize } from '@topremit/shared-web/common/size';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { color } from '@topremit/shared-web/styles/color';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import { StaticImageData } from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import NotificationButton from './NotificationButton';
import ProfileDropdown from './ProfileDropdown';

const { tabletMd } = screenSize;

const accountIcon: Record<AccountType, StaticImageData> = {
  [AccountType.PERSONAL]: accountPersonalImage,
  [AccountType.BUSINESS]: accountBusinessImage,
};

export default function DashboardTopbar() {
  const { t, lang } = useTranslation('dashboard');
  const router = useRouter();
  const isClient = typeof window !== 'undefined';
  const isMobile = useMediaQuery(`(max-width:${tabletMd}px)`);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [{ isHideSideBar, isHideNotifButton }] = useDashboardLayout();
  const { data: me, isLoading: isLoadingMe } = useGetMe();

  const { data: accountInfo } = useGetAccountInfo<
    AccountInfo & AccountInfoBusiness
  >();
  const { isPersonalAccount } = usePermission();

  const showDialog = useDialogStore((store) => store.show);

  const { currentAccount } = useCurrentAccountStore.getState() || {};
  const setCurrentAccount = useCurrentAccountStore(
    (store) => store.setCurrentAccount,
  );
  const resetClientOnboardingStore = useClientOnboardingStore(
    (store) => store.resetClientOnboardingStore,
  );
  const resetDomesticStore = useDomesticFlowStore((s) => s.resetStore);

  const isBusiness = currentAccount?.type === AccountType.BUSINESS;
  const strMe = JSON.stringify(me?.data);
  const isShowLogo = !isClient || isMobile || isHideSideBar;
  const isShowNotificationButton =
    isMobile && isClient && !isHideNotifButton && !isNativeApp();
  const isDesktop = !isMobile && isClient;

  const getKycAbadStatus = getStatusKycAbad(
    me?.data.kycStatus as KycStatus,
    me?.data.bankAccountVerificationInfo
      ?.matchingStatus as BankAccountMatchingStatus,
    me?.data.blacklistReason,
    me?.data.rejectedError,
  );

  const statusProps = useMemo(
    () => ({
      className: 'status',
      size: 'small',
      ...getPillContent(),
    }),
    [strMe, lang],
  );

  function openLogoutDialog() {
    showDialog({
      body: <Logout />,
    });
  }

  function getPillContent() {
    if (
      getKycAbadStatus === KycAbadStatus.UNVERIF_ABAD ||
      getKycAbadStatus === KycAbadStatus.UNVERIF_KYC_ABAD ||
      me?.data.showUnderageError
    ) {
      return {
        message: t('not_verified'),
        status: 'expired',
      };
    }

    if (
      getKycAbadStatus === KycAbadStatus.VERIFYING_ABAD ||
      getKycAbadStatus === KycAbadStatus.VERIFYING_KYC_ABAD
    ) {
      return {
        message: t('verifying'),
        status: 'inProgress',
      };
    }

    if (
      getKycAbadStatus === KycAbadStatus.REJECTED_COMPLIANCE_KYC ||
      getKycAbadStatus === KycAbadStatus.BLACKLIST_SPAM_PROHIBITED ||
      getKycAbadStatus === KycAbadStatus.BLACKLIST_OTHER
    ) {
      return {
        message: t('verification_rejected'),
        status: 'fail',
      };
    }

    if (
      getKycAbadStatus === KycAbadStatus.REJECTED_KYC ||
      getKycAbadStatus === KycAbadStatus.REJECTED_ABAD ||
      getKycAbadStatus === KycAbadStatus.REJECTED_KYC_EMPTY_ABAD ||
      getKycAbadStatus === KycAbadStatus.BLACKLIST_REMOVED
    ) {
      return {
        message: t('verification_failed'),
        status: 'fail',
      };
    }

    if (getKycAbadStatus === KycAbadStatus.APPROVE_KYC_ABAD) {
      return {
        message: t('verified'),
        status: 'success',
      };
    }

    return {};
  }

  function handleAccountClick(account: Account) {
    resetClientOnboardingStore();
    resetDomesticStore();
    setCurrentAccount({
      ...account,
      kycStatus: accountInfo?.data?.kycStatus,
    });

    localStorage.removeItem('CLIENT_ABAD');
    queryClient.invalidateQueries();

    // Redirect based on account type
    router.push({
      pathname: isPersonalAccount ? '/home' : '/profile',
      query: {
        ...(account.hasUnreadNotification && {
          notification: '1',
        }),
      },
    });
  }

  useEffect(() => {
    function handleClick(e: MouseEvent) {
      const profileDropdown = document.querySelector('.profile-dropdown');
      const usernameWrapper = document.querySelector('.username-wrapper');

      if (
        profileDropdown &&
        !profileDropdown.contains(e.target as Node) &&
        !usernameWrapper?.contains(e.target as Node) &&
        isOpenDropdown
      ) {
        setIsOpenDropdown(false);
      }
    }
    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [isOpenDropdown]);

  return (
    <TopBar>
      {isShowLogo && (
        <>
          <TopMenuLogo
            src={topremitLogoImage}
            width={127}
            height={22}
            alt="topremit logo"
            onClick={() => {
              if (isNativeApp()) {
                sendNativeMessage({ name: NativeWebViewBridgeEventName.BACK });
              } else {
                if (currentAccount?.type === AccountType.PERSONAL) {
                  router.push('/home');
                } else {
                  router.push('/profile');
                }
              }
            }}
          />
          {isShowNotificationButton && <NotificationButton />}
        </>
      )}

      {isDesktop && (
        <>
          <TopMenuItem
            className={cx(styled.usernameWrapper, 'username-wrapper')}
            onClick={() => setIsOpenDropdown((prev) => !prev)}
          >
            <SkeletonWrapper active loading={isLoadingMe}>
              {currentAccount ? (
                <>
                  <Flex
                    align="center"
                    justify="center"
                    className={styled.avatar}
                  >
                    <ResponsiveImage
                      ratio={1}
                      alt="avatar"
                      maxWidth={20}
                      src={accountIcon[currentAccount.type]}
                    />
                  </Flex>
                  <Text className={styled.usernameText}>
                    {isBusiness
                      ? currentAccount?.companyName
                      : currentAccount.name}
                  </Text>
                  <Angle
                    size="small"
                    fill={color.neutral500}
                    direction="down"
                    width={24}
                    height={24}
                  />
                </>
              ) : (
                <Button onClick={openLogoutDialog} type="invert" size="medium">
                  {t('common:logout')}
                </Button>
              )}

              {isOpenDropdown && (
                <ProfileDropdown
                  accounts={accountInfo?.data.accounts.map((account) => ({
                    ...account,
                    onClick: () => handleAccountClick(account),
                  }))}
                  statusPill={<Status {...(statusProps as IStatusProps)} />}
                />
              )}
            </SkeletonWrapper>
          </TopMenuItem>

          <div className={styled.divider}>
            <Divider orientation="vertical" mx={8} />
          </div>

          {!isHideNotifButton && (
            <TopMenuItem>
              <NotificationButton />
            </TopMenuItem>
          )}
          <TopMenuItem>
            <LanguageMenu type="button" />
          </TopMenuItem>
        </>
      )}
    </TopBar>
  );
}

const styled = {
  usernameWrapper: css`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  usernameText: css`
    max-width: 11.25rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--neutral-500);
    font-weight: var(--bold-font-weight);
    margin-right: 0.5rem;
  `,
  divider: css`
    height: 2.5rem;
  `,
  avatar: css`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    margin-right: 0.75rem;
    background: var(--blue-100);
  `,
};
