import { SVGProps } from 'react';

function Refresh(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#8295B5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path d="M22.9511 12C22.7065 11.9969 22.4695 12.0852 22.2866 12.2476C22.1037 12.4099 21.9879 12.6348 21.9621 12.878C21.7656 15.1518 20.798 17.2903 19.2195 18.9387C17.6411 20.5872 15.5466 21.6467 13.2835 21.9416C11.0203 22.2365 8.72428 21.7491 6.77611 20.5601C4.82795 19.3712 3.34463 17.5521 2.57207 15.4045C1.79951 13.257 1.78411 10.9098 2.52843 8.75231C3.27274 6.59478 4.73207 4.75643 6.66447 3.54202C8.59686 2.32762 10.8863 1.8101 13.1531 2.07528C15.42 2.34045 17.5281 3.37239 19.1281 5H16.3761C16.1108 5 15.8565 5.10536 15.669 5.29289C15.4814 5.48043 15.3761 5.73478 15.3761 6C15.3761 6.26522 15.4814 6.51957 15.669 6.70711C15.8565 6.89464 16.1108 7 16.3761 7H20.3761C20.9065 7 21.4152 6.78929 21.7903 6.41421C22.1653 6.03914 22.3761 5.53043 22.3761 5V1C22.3761 0.734784 22.2707 0.48043 22.0832 0.292893C21.8956 0.105357 21.6413 0 21.3761 0C21.1108 0 20.8565 0.105357 20.669 0.292893C20.4814 0.48043 20.3761 0.734784 20.3761 1V3.415C18.427 1.51604 15.8902 0.336284 13.1821 0.0694657C10.4741 -0.197353 7.75577 0.464628 5.47362 1.9467C3.19146 3.42877 1.48111 5.64283 0.623428 8.22531C-0.234252 10.8078 -0.188278 13.6051 0.753801 16.158C1.69588 18.7109 3.47806 20.8676 5.80769 22.2739C8.13732 23.6801 10.8759 24.2524 13.5737 23.8968C16.2716 23.5411 18.7683 22.2786 20.6538 20.3167C22.5394 18.3547 23.7018 15.8098 23.9501 13.1C23.963 12.9608 23.9468 12.8205 23.9025 12.6879C23.8582 12.5554 23.7868 12.4335 23.6928 12.33C23.5988 12.2265 23.4844 12.1437 23.3567 12.0869C23.229 12.0301 23.0908 12.0005 22.9511 12Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Refresh;
