import { KycStatus, useGetMe, UserPersonal } from '@api-hooks/common';
import { IdTypeKyc, useGetKycAbadData } from '@api-hooks/kyc';
import { useCameraPermission, usePermission, useAuth } from '@hooks';
import {
  FlattenStep,
  getKycAbadSteps,
  getStatusKycAbad,
  KycAbadStatus,
  REABAD_KEY,
} from '@modules/kyc';
import { useKycStore } from '@stores';
import { BankAccountMatchingStatus } from '@topremit/shared-web/api-hooks/common';
import { generateRandomUUID } from '@topremit/shared-web/common/helper';
import { db } from 'landing/db';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

export default function useGetKycAbad() {
  const router = useRouter();
  const { permissionStatus } = useCameraPermission();
  const isCameraPermissionDenied = permissionStatus === 'denied';
  const isNoCamera = permissionStatus === 'no-camera';

  const [selfieImage, setSelfieImage] = useState<string | null>(null);
  const [identityImage, setIdentityImage] = useState<string | null>(null);

  const { showPreviewPersonalId, setShowPreviewPersonalId } = useKycStore(
    useShallow((state) => state),
  );

  const { isPersonalAccount } = usePermission();
  const { isAuthenticated } = useAuth();
  const { data: me, refetch: refetchMe } = useGetMe<UserPersonal>({
    enabled: (isPersonalAccount && isAuthenticated) ?? false,
  });
  const { data: submittedData, refetch: refetchKycSubmittedData } =
    useGetKycAbadData({
      onSuccess: async ({ data: { identityPath, selfieIdentityPath } }) => {
        if (identityPath) {
          await handleSaveImageIdb({
            imageBase64: identityPath,
            type: 'identity',
          });
        }
        if (selfieIdentityPath) {
          await handleSaveImageIdb({
            imageBase64: selfieIdentityPath,
            type: 'selfie',
          });
        }
      },
      enabled: (isPersonalAccount && isAuthenticated) ?? false,
    });

  const {
    kycStatus,
    bankAccountVerificationInfo,
    blacklistReason,
    rejectedError,
  } = me?.data || {};

  const statusKycAbad = getStatusKycAbad(
    kycStatus as KycStatus,
    bankAccountVerificationInfo?.matchingStatus as BankAccountMatchingStatus,
    blacklistReason,
    rejectedError,
  );

  const isKycAbadStatusReady = statusKycAbad !== null;
  const userIdType = submittedData?.data.idType as IdTypeKyc;
  const kycAbadSteps = getKycAbadSteps({ rejectedError, statusKycAbad });
  const isKycRejectedAll = (rejectedError?.length || 0) >= 4;
  const isKycPage = router.pathname === '/kyc';

  const flattenedSteps: FlattenStep[] = kycAbadSteps.flatMap((item) => {
    if (item.subStep) {
      return item.subStep.map((sub) => ({ step: item.step, subStep: sub }));
    }
    return { step: item.step };
  });

  const kycAbadStepsWithNumberStep = addNumberStep(flattenedSteps) as {
    step: string;
    numberStep: number;
  }[];

  const currentStepQuery = router.query?.step;
  const currentStatusQuery = router.query?.status;
  const queryStepIndex = kycAbadStepsWithNumberStep.findIndex(
    (step) =>
      step.numberStep === Number(currentStepQuery) &&
      step.step === currentStatusQuery,
  );
  const nextStatusQuery =
    Number(currentStepQuery) === kycAbadStepsWithNumberStep?.length
      ? null
      : kycAbadStepsWithNumberStep[Number(queryStepIndex + 1)]?.step;

  const firstStepQuery = {
    step: kycAbadStepsWithNumberStep[0]?.numberStep,
    status: kycAbadStepsWithNumberStep[0]?.step,
  };

  const hasKycIdentityOrSelfie = kycAbadStepsWithNumberStep.some(
    (step) => step.step === 'IDENTITY' || step.step === 'SELFIE',
  );

  function addNumberStep(flattenStep: FlattenStep[]) {
    const stepNumbers = {};
    let currentNumber = 1;

    return flattenStep.map((item) => {
      const stepValue = item.subStep || item.step;
      if (!stepNumbers[item.step]) {
        stepNumbers[item.step] = currentNumber;
        currentNumber++;
      }
      return { step: stepValue, numberStep: stepNumbers[item.step] };
    });
  }

  function handleNextStep(hasReabad?: boolean) {
    refetchKycSubmittedData().finally(() => {
      if (!currentStepQuery || !currentStatusQuery) {
        router.push({ pathname: '/kyc', query: firstStepQuery }, undefined, {
          shallow: true,
        });
      }
    });

    if (currentStepQuery && currentStatusQuery) {
      // Note: if queryStepIndex not -1 (Not Found)
      if (queryStepIndex !== -1) {
        // Note: if it is the last step , push to completed
        if (queryStepIndex + 1 === kycAbadStepsWithNumberStep.length) {
          // Note: Clear all idb image after finish submit and refetch needed data
          db.imageKycData.clear();
          // Note: only handle Reabad case
          if (hasReabad) {
            localStorage.setItem(REABAD_KEY, 'true');
            router.replace('/kyc/re-abad');
            return;
          }
          refetchMe();
          router.replace('/kyc/completed');
          return;
        }
        const nextStepQuery = {
          step: kycAbadStepsWithNumberStep[queryStepIndex + 1]?.numberStep,
          status: kycAbadStepsWithNumberStep[queryStepIndex + 1]?.step,
        };
        router.replace({ pathname: '/kyc', query: nextStepQuery }, undefined, {
          shallow: true,
        });
      }
      // Note: if the step or status not found
      else {
        router.replace({ pathname: '/kyc', query: firstStepQuery }, undefined, {
          shallow: true,
        });
      }
    }
  }
  function handleBackStep() {
    refetchKycSubmittedData();
    const isFirstStep = currentStepQuery === '1';
    if (isFirstStep) {
      router.back();
    } else {
      const prevStepQuery = {
        step: kycAbadStepsWithNumberStep[queryStepIndex - 1]?.numberStep,
        status: kycAbadStepsWithNumberStep[queryStepIndex - 1]?.step,
      };
      const hasPrevStepQuery = prevStepQuery.step && prevStepQuery.status;
      if (hasPrevStepQuery) {
        if (
          prevStepQuery.status === 'IDENTITY' ||
          prevStepQuery.status === 'SELFIE'
        ) {
          router.replace({
            pathname: '/kyc/preview-photo',
            query: prevStepQuery,
          });
          return;
        }
        router.replace({
          pathname: '/kyc',
          query: prevStepQuery,
        });
      }
    }
  }

  async function handleSaveImageIdb({
    imageBase64,
    type,
  }: {
    imageBase64: string;
    type: 'selfie' | 'identity';
  }) {
    const existingImage = await db.imageKycData
      .where('type')
      .equals(type)
      .first();

    if (existingImage) {
      // Note: update the existing record
      await db.imageKycData.update(existingImage.id, {
        image: imageBase64,
      });
      console.info(`${type} image updated successfully.`);
    } else {
      // Note: add a new record
      await db.imageKycData.add({
        id: generateRandomUUID(),
        image: imageBase64,
        type,
      });
      console.info(`${type} image added successfully.`);
    }
  }

  async function getImageIdb(type: 'selfie' | 'identity') {
    return await db.imageKycData.where('type').equals(type).first();
  }

  async function fetchIdbImage() {
    const selfie = await getImageIdb('selfie');
    const identity = await getImageIdb('identity');
    setSelfieImage(String(selfie?.image) || null);
    setIdentityImage(String(identity?.image) || null);
  }

  useEffect(() => {
    // Note: fetch image from index DB
    fetchIdbImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Note: if the query param status and step not found  or empty will redirect to first step
    // Note: this only run when user in /kyc only
    if (!router.isReady || kycAbadStepsWithNumberStep.length === 0) {
      return;
    }
    if (!isKycPage) {
      return;
    }
    if (!currentStepQuery || !currentStatusQuery) {
      router.replace({ pathname: '/kyc', query: firstStepQuery }, undefined, {
        shallow: true,
      });
      return;
    }
    if (queryStepIndex === -1) {
      router.replace({ pathname: '/kyc', query: firstStepQuery }, undefined, {
        shallow: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, currentStepQuery, currentStatusQuery]);

  useEffect(() => {
    // Note: prevent user to go Kyc page when the status below
    if (!statusKycAbad) {
      return;
    }
    if (!isKycPage) {
      return;
    }
    if (
      [
        KycAbadStatus.APPROVE_KYC_ABAD,
        KycAbadStatus.VERIFYING_ABAD,
        KycAbadStatus.VERIFYING_KYC_ABAD,
        KycAbadStatus.BLACKLIST_OTHER,
        KycAbadStatus.BLACKLIST_SPAM_PROHIBITED,
        KycAbadStatus.REJECTED_COMPLIANCE_KYC,
      ].includes(statusKycAbad)
    ) {
      router.push('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady, statusKycAbad]);

  return {
    // Note: Section Value
    kycAbadSteps,
    statusKycAbad,
    userIdType,
    steps: kycAbadStepsWithNumberStep,
    currentStep: Number(currentStepQuery),
    currentStepStatus: String(currentStatusQuery),
    nextStepStatus: String(nextStatusQuery),
    // Note: Handle Navigation
    firstStepQuery,
    handleNextStep,
    handleBackStep,
    // Note: Section Boolean
    isKycAbadStatusReady,
    isKycRejectedAll,
    isCameraPermissionDenied,
    isNoCamera,
    hasKycIdentityOrSelfie,
    // Note: Section Submitted Data
    submittedData,
    refetchKycSubmittedData,
    // Note: Handle passing Image to preview image and persist in while not submit
    showPreviewPersonalId,
    setShowPreviewPersonalId,
    // Note: Handle idb image
    handleSaveImageIdb,
    getImageIdb,
    selfieImage,
    identityImage,
  };
}
