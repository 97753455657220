import { useEffect, useState } from 'react';

import {
  getLocalStorageValue,
  OPENED_PAGE_STORAGE_KEY,
} from '../common/storage';

export type TrackedPages = { [key: string]: { [route: string]: boolean } };

type UsePageOpenedBag = [
  { [route: string]: boolean },
  { isOpen: (pathname: string) => boolean },
];

export default function usePageOpened(trackId = 'default'): UsePageOpenedBag {
  const [openedPage, setOpenedPage] = useState(() => {
    const defaultValue = getLocalStorageValue<TrackedPages>(
      OPENED_PAGE_STORAGE_KEY,
    );

    return defaultValue?.[trackId];
  });

  /**
   * to check is the url opened
   * pathnameWithoutLocale -> eg: `/dashboard/send-money/bulk-transaction/<ref_id>/detail/<ref_id>`
   */
  function isOpen(pathnameWithoutLocale: string): boolean {
    const trackedOpenedPage =
      getLocalStorageValue<TrackedPages>(OPENED_PAGE_STORAGE_KEY)?.[trackId] ||
      [];

    return trackedOpenedPage[`${pathnameWithoutLocale}`];
  }

  useEffect(() => {
    const openPageValue = getLocalStorageValue<TrackedPages>(
      OPENED_PAGE_STORAGE_KEY,
    );
    window.addEventListener('storage', function (e) {
      if (e.storageArea !== window.localStorage) {
        return;
      }
      if (e.key === OPENED_PAGE_STORAGE_KEY) {
        setOpenedPage(openPageValue?.[trackId]);
      }
    });
  }, []);

  return [openedPage, { isOpen }];
}
