import { css, cx } from '@emotion/css';
import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import { callAllFunctions } from '../../common/helper';
import { useBanner } from '../../hooks';
import { Close } from '../shapes';

export interface IBannerProps {
  message: string | ReactNode;
  colorScheme?: 'neutral' | 'red' | 'blue' | 'yellow' | 'green';
}

// Add value to Hide it when in specific pathname
const SHOW_BANNER_WEB = ['single-transaction/'];

let checkResultPathname = false;

export default function Banner({
  message,
  colorScheme = 'neutral',
}: IBannerProps) {
  const { pathname } = useRouter();
  const { hideBanner, showCloseButton, onClickClose } = useBanner();

  //Handle hide Banner
  useEffect(() => {
    checkResultPathname = SHOW_BANNER_WEB.some((val) => pathname.includes(val));
    if (!checkResultPathname) {
      hideBanner();
    }
  }, [pathname]);

  return (
    <div id="banner-wrapper" className={cx(styled.root, styled[colorScheme])}>
      <h5>{message}</h5>
      {showCloseButton && (
        <div
          className="close"
          onClick={callAllFunctions(hideBanner, onClickClose)}
        >
          <Close width={15} height={15} />
        </div>
      )}
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: fit-content;
    padding: 0.75rem 1.25rem;
    position: absolute;
    top: 72px;
    z-index: 1;
    h5 {
      width: 100%;
      margin-bottom: 0;
    }
    .close {
      margin-left: 1rem;
    }
  `,
  neutral: css`
    background: #edf2f7;
  `,
  red: css`
    background: var(--red-100);
  `,
  blue: css`
    background: var(--blue-100);
  `,
  yellow: css`
    background: var(--yellow-100);
  `,
  green: css`
    background: var(--green-100);
  `,
};
