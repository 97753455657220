import dynamic from 'next/dynamic';

export * from './accordion';
export * from './Alert';
export * from './animated-wrapper';
export * from './Avatar';
export * from './Badge';
// workaround: dynamic export for ListItem cause component
// cannot click at first render.
export { default as Banner } from './Banner';
export * from './Breadcrumb';

export { default as Button } from './Button';

export { default as ButtonGroup } from './ButtonGroup';
export * from './CardNotes';
export * from './change-speed-modal';
export { default as CheckboxInput } from './CheckboxInput';
export { default as Chip } from './Chip';
export { default as ChipsOptions } from './ChipsOptions';
export * from './Container';
export { default as ContainerSpinner } from './ContainerSpinner';
export * from './CopyButton';

export { default as Counter } from './Counter';
export type { IDotProps } from './Dot';
export { default as ErrorMessage } from './ErrorMessage';
export * from './fields';
export { default as FilterButton } from './FilterButton';
export { default as Flex } from './Flex';
export { default as FormOnErrorScroll } from './FormOnErrorScroll';
export { default as Grid } from './Grid';
export type { IHorizontalSliderProps } from './horizontal-slider';
export { HorizontalSlider } from './horizontal-slider/HorizontalSlider';
export { default as HorizontalSliderV2 } from './HorizontalSliderV2';
// workaround: dynamic export for ListItem cause component
// cannot click at first render.
export { default as IconButton } from './IconButton';
export { default as LabelValue } from './LabelValue';
export { default as LegacyPinInput } from './LegacyPinInput';
export { default as ListDropdown } from './ListDropdown';
export { default as ListItem } from './ListItem';
export type { IListItemProps } from './ListItem';
export { default as ListMenu } from './ListMenu';
export { default as LoadingView } from './LoadingView';
export * from './navigations'; //no dynamic because need height value on first load
export { default as Notes } from './Notes';
export { default as Radio } from './Radio';
export { default as Divider } from './Divider';
export { default as Flag } from './Flag';
export { default as Card } from './Card';

export { default as Parser } from './Parser';
export * from './Parser';
// types
export type { INotesProps, NotesStatus } from './Notes';
export { default as PinInput } from './PinInput';
export type { IProgressBarProps } from './ProgressBar';
export * from './recaptcha';
export { default as ResponsiveImage } from './ResponsiveImage';
export { default as SearchInput } from './SearchInput';
export { default as SegmentedTabs } from './SegmentedTabs';
export { default as ServiceCard } from './ServiceCard';
export type { IServiceCardProps } from './ServiceCard';
export type { IShareMediaProps } from './ShareMedia';
export { default as SkeletonWrapper } from './SkeletonWrapper';
export * from './speed-not-supported-modal';
export type { IStatusProps, StatusType } from './Status';
export type { IStepsProps } from './Steps';
export { default as Text } from './Text';
export type { IToggleProps } from './Toggle';
export type { ITooltipProps } from './Tooltip';
export { default as VariantCard } from './VariantCard';

// components
export const ProgressBar = dynamic(() => import('./ProgressBar'));
export const ShareMedia = dynamic(() => import('./ShareMedia'));
export const Status = dynamic(() => import('./Status'));
export const Steps = dynamic(() => import('./Steps'));
export const Timeline = dynamic(() => import('./timeline/Timeline'));
export const TimelineItem = dynamic(() => import('./timeline/TimelineItem'));
export const Toggle = dynamic(() => import('./Toggle'));
export const Tooltip = dynamic(() => import('./Tooltip'));
export const SpeedInfo = dynamic(() => import('./speed-info/SpeedInfo'));
export const RemainingTime = dynamic(() => import('./RemainingTime'));

export const PasswordCriteria = dynamic(() => import('./PasswordCriteria'));
export const Dot = dynamic(() => import('./Dot'));
export const CorrespondentFeeWrapper = dynamic(() =>
  import('./transactions/CorrespondentFeeWrapper').then(
    (m) => m.CorrespondentFeeWrapper,
  ),
);
export const AddOnTextField = dynamic(() => import('./AddOnTextField'));
export const DiscountFeeStatus = dynamic(() => import('./DiscountFeeStatus'));

export * from './dropdown';
export * from './drawer';
export * from './v-stepper';
export * from './h-stepper';
