import * as React from 'react';
import { SVGProps } from 'react';

import { color } from '../../styles/color';
const CommentOutline = (props: SVGProps<SVGSVGElement>) => {
  const { width = 24, height = 24, fill = color.neutral500 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2330_9710)">
        <path
          d="M22 11.3725C21.8794 9.4225 21.1909 7.55041 20.0193 5.987C18.8477 4.4236 17.2441 3.23723 15.4064 2.57414C13.5687 1.91106 11.577 1.80026 9.6771 2.25539C7.77715 2.71053 6.05192 3.71171 4.71409 5.1355C3.37626 6.55929 2.48433 8.34345 2.14825 10.268C1.81217 12.1926 2.04663 14.1735 2.82273 15.9664C3.59883 17.7593 4.88264 19.286 6.51589 20.3581C8.14914 21.4302 10.0604 22.001 12.0141 22H17.8333C18.938 21.9989 19.9972 21.5595 20.7784 20.7784C21.5595 19.9972 21.9989 18.938 22 17.8333V11.3725ZM20.3333 17.8333C20.3333 18.4964 20.0699 19.1322 19.6011 19.6011C19.1322 20.0699 18.4963 20.3333 17.8333 20.3333H12.0141C10.8382 20.3328 9.67567 20.0844 8.60221 19.6044C7.52876 19.1244 6.56853 18.4235 5.78413 17.5475C4.99594 16.6719 4.40407 15.6379 4.04821 14.5148C3.69234 13.3917 3.58071 12.2056 3.7208 11.0358C3.94199 9.19077 4.77095 7.4718 6.077 6.14992C7.38304 4.82803 9.09188 3.97841 10.9341 3.73498C11.2935 3.68994 11.6553 3.66712 12.0175 3.66665C13.9595 3.66135 15.8415 4.3399 17.3333 5.58331C18.2046 6.30743 18.9197 7.20094 19.4354 8.20967C19.9511 9.2184 20.2566 10.3213 20.3333 11.4516V17.8333Z"
          fill={fill}
        />
        <path
          d="M8.66671 9.50016H12C12.2211 9.50016 12.433 9.41237 12.5893 9.25608C12.7456 9.0998 12.8334 8.88784 12.8334 8.66683C12.8334 8.44582 12.7456 8.23385 12.5893 8.07757C12.433 7.92129 12.2211 7.8335 12 7.8335H8.66671C8.44569 7.8335 8.23373 7.92129 8.07745 8.07757C7.92117 8.23385 7.83337 8.44582 7.83337 8.66683C7.83337 8.88784 7.92117 9.0998 8.07745 9.25608C8.23373 9.41237 8.44569 9.50016 8.66671 9.50016Z"
          fill={fill}
        />
        <path
          d="M15.3334 11.1665H8.66671C8.44569 11.1665 8.23373 11.2543 8.07745 11.4106C7.92117 11.5669 7.83337 11.7788 7.83337 11.9998C7.83337 12.2209 7.92117 12.4328 8.07745 12.5891C8.23373 12.7454 8.44569 12.8332 8.66671 12.8332H15.3334C15.5544 12.8332 15.7664 12.7454 15.9226 12.5891C16.0789 12.4328 16.1667 12.2209 16.1667 11.9998C16.1667 11.7788 16.0789 11.5669 15.9226 11.4106C15.7664 11.2543 15.5544 11.1665 15.3334 11.1665Z"
          fill={fill}
        />
        <path
          d="M15.3334 14.5H8.66671C8.44569 14.5 8.23373 14.5878 8.07745 14.7441C7.92117 14.9004 7.83337 15.1123 7.83337 15.3333C7.83337 15.5543 7.92117 15.7663 8.07745 15.9226C8.23373 16.0789 8.44569 16.1667 8.66671 16.1667H15.3334C15.5544 16.1667 15.7664 16.0789 15.9226 15.9226C16.0789 15.7663 16.1667 15.5543 16.1667 15.3333C16.1667 15.1123 16.0789 14.9004 15.9226 14.7441C15.7664 14.5878 15.5544 14.5 15.3334 14.5Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_9832">
          <rect
            width={width}
            height={height}
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CommentOutline;
