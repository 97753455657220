import { css, cx } from '@emotion/css';
import { screenSize } from '@global-common/size';
import { Flag } from '@global-elements';
import { SelectItem } from '@global-elements-utils/select';

import { SelectOptionProps } from './type';

export default function SelectOption(props: SelectOptionProps) {
  const { name, dialCode, flagCode, value, keywords, ...rest } = props;

  return (
    <SelectItem value={value ?? ''} keywords={keywords} {...rest}>
      <div className={cx(styled.dialCodeOptionWrapper)} title={name}>
        <div className="flag-icon">
          <Flag code={flagCode} />
        </div>
        <div>
          <span className="country-name">{name} </span>
          <span className="dial-code regular-text">(+{dialCode})</span>
        </div>
      </div>
    </SelectItem>
  );
}

const styled = {
  dialCodeOptionWrapper: css`
    display: flex;
    align-items: center;
    height: 46px;
    width: 100%;
    > * {
      user-select: none;
      pointer-events: none;
    }
    .flag-icon {
      width: fit-content;
      min-width: fit-content;
      height: 1.75rem;
      min-height: 1.75rem;
      display: flex;
      align-items: center;
      margin-right: 0.625rem;
    }

    @media (max-width: ${screenSize.mobileLg}px) {
      font-size: 0.875rem;
    }
  `,
};
