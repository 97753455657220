import { useEffect, useState } from 'react';

import { useMounted } from './use-mounted';

export default function useMediaQuery(
  query: string,
  options?: { default?: boolean },
) {
  const [matches, setMatches] = useState<boolean>(() =>
    options?.default !== undefined
      ? options.default
      : typeof window !== 'undefined' && window.matchMedia(query).matches,
  );

  const mounted = useMounted();

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return mounted ? matches : false;
}
