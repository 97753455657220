import { css } from '@emotion/react';

import { zIndex } from '../common/size';

export default css`
  .ant-notification {
    z-index: ${zIndex.notification};
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: max-content;
    .ant-notification-notice-wrapper {
      margin-inline-start: auto;
      margin-inline-end: auto;
      margin-bottom: 0;
      .ant-notification-notice {
        width: fit-content;
        padding: unset;
        margin: 0 auto;
        .ant-notification-notice-close {
          visibility: hidden;
        }
      }
      .ant-notification-notice.ant-notification-notice-closable
        .ant-notification-notice-message {
        margin-bottom: unset;
        padding-inline-end: unset;
      }
      > div {
        display: flex;
        flex-direction: column;
        .ant-notification-hook-holder {
          margin: 0;
          width: 100%;
          max-width: unset;
          background: transparent;
          box-shadow: unset;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          > .ant-notification-notice {
            padding: 0;

            margin: 0;
            box-shadow: unset;
            background: transparent;
            width: fit-content;
            .ant-notification-notice-content {
              padding: 0;
              .ant-notification-notice-message {
                margin: 0;
              }
            }

            .ant-notification-notice-close {
              display: none;
            }
          }
        }
      }
    }
  }
`;
