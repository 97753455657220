import { css } from '@emotion/css';
import bolyHeadsetImage from '@public/images/svg/boly-headset.svg';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, parseHtml } from '@topremit/shared-web/components/elements';
import {
  CommentOutline,
  WhatsappOutline,
} from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import Image from 'next/image';

import { CS_NUMBER, PrefillTextType, prefillText } from './constant';

function ChatUsDialogContent({
  prefillType,
  payload,
  onClick,
}: {
  prefillType?: PrefillTextType;
  payload?: string;
  onClick?: () => void;
}) {
  const { t, lang } = useTranslation('dashboard');
  const closeDialog = useDialogStore((store) => store.close);
  const message =
    prefillType && lang ? prefillText(payload)[prefillType][lang] : '';

  function onClickLiveChat() {
    closeDialog();
    onClick?.();
    if (isNativeApp()) {
      sendNativeMessage({ name: NativeWebViewBridgeEventName.OPEN_FRESHCHAT });
      return;
    }
    window.fcWidget?.open({ replyText: message ?? '' });
  }

  const preFilledMessage = message ? encodeURIComponent(message) : '';
  const whatsAppClickToChatUrl = `https://wa.me/${CS_NUMBER}?text=${preFilledMessage}`;

  function onClickWhatsApp() {
    closeDialog();
    onClick?.();
    window.open(whatsAppClickToChatUrl, '_blank');
  }

  function generateDescription() {
    /**
     * cx team will be available EVERYDAY from 09.00-17.00 Indonesian timezone
     */
    const workingEndTime = 17;
    const workingStartTime = 9;
    const currentTime = new Date();
    const currentHour = (currentTime.getUTCHours() + 7) % 24;

    const isWorkingHour =
      currentHour >= workingStartTime && currentHour < workingEndTime;

    if (isWorkingHour) {
      return t('chat_us.description.online');
    }
    const nextWorkingHour = `${workingStartTime
      .toString()
      .padStart(2, '0')}.00-${workingEndTime.toString().padStart(2, '0')}.00`;
    return t('chat_us.description.offline', { nextWorkingHour });
  }

  return (
    <div className={styled.root}>
      <div className="image-wrapper">
        <Image
          priority
          width={200}
          height={200}
          alt="boly headset"
          src={bolyHeadsetImage}
        />
      </div>
      <div className="text-wrapper">
        <h5 className="title">{t('chat_us.title')}</h5>
        <p className="description">{parseHtml(generateDescription())}</p>
      </div>
      <div className="button-wrapper">
        <Button
          fullWidth
          onClick={onClickLiveChat}
          leftIcon={
            <CommentOutline width={20} height={20} fill="var(--neutral-0)" />
          }
        >
          Live Chat
        </Button>
        <Button
          fullWidth
          className="whatsapp-button"
          onClick={onClickWhatsApp}
          leftIcon={
            <WhatsappOutline width={20} height={20} fill="var(--neutral-0)" />
          }
        >
          WhatsApp
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    .image-wrapper {
      display: flex;
      justify-content: center;
    }
    .text-wrapper {
      text-align: center;
      .title {
      }
      .description {
        margin: 0;
        font-size: var(--sm-font-size);
      }
    }
    .button-wrapper {
      margin-top: 1.5rem;
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      @media (max-width: ${screenSize.mobileLg}px) {
        flex-direction: column;
        gap: 0.5rem;
      }
      .whatsapp-button {
        background-color: #25d366 !important;
        border-color: #25d366 !important;
        :hover {
          background-color: #1da950 !important;
          border-color: #1da950 !important;
        }
        :active {
          color: var(--color-neutral-0) !important;
        }
      }
      button {
        .regular-text {
          display: flex;
          align-items: center;
          @media (max-width: ${screenSize.tabletSm}px) {
            font-size: var(--sm-font-size);
          }
        }
      }
    }
  `,
};

export default ChatUsDialogContent;
