import {
  UpdateDataStatusType,
  useGetCompanyProfile,
  useGetMe,
} from '@api-hooks/common';
import { ContentCard } from '@elements';
import { css } from '@emotion/css';
import { usePermission } from '@hooks';
import { Notes } from '@topremit/shared-web';
import { screenSize } from '@topremit/shared-web/common/size';
import SkeletonWrapper from '@topremit/shared-web/components/elements/SkeletonWrapper';
import {
  IdBadgeOutline,
  ShopOutline,
} from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import {
  SectionDetailsWrapper,
  SectionFieldGroup,
  SectionFieldProps,
} from 'landing/src/components/elements/section-details';
import { useRouter } from 'next/router';

const { tabletMd } = screenSize;

export default function CompanyProfile() {
  const { t } = useTranslation('dashboard');
  const { data: meData } = useGetMe();
  const router = useRouter();
  const { isBusinessAccount } = usePermission();

  const { data, isLoading } = useGetCompanyProfile({
    enabled: isBusinessAccount,
  });
  const companyProfile = data?.data;

  const companyDetails = [
    {
      label: t('company_name'),
      value: companyProfile?.companyName,
    },
    {
      label: t('company_id'),
      value: companyProfile?.companyDetail.code,
    },
    {
      label: t('company_email'),
      value: companyProfile?.companyDetail.email,
    },
    {
      label: t('company_phone_number'),
      value: companyProfile?.companyDetail.phone,
    },
  ];

  const businessDetails = [
    {
      label: t('entity_type'),
      value: companyProfile?.businessDetail.entityType,
    },
    {
      label: t('entity_name'),
      value: companyProfile?.businessDetail.entityName,
    },
    {
      label: t('entity_established_country'),
      value: companyProfile?.businessDetail.entityEstablishedCountry,
    },
    {
      label: t('entity_established_address'),
      value: companyProfile?.businessDetail.entityEstablishedAddress,
    },
    {
      label: t('entity_domicile_country'),
      value: companyProfile?.businessDetail.entityDomicileCountry,
    },
    {
      label: t('entity_domicile_address'),
      value: companyProfile?.businessDetail.entityDomicileAddress,
    },
    {
      label: t('business_type'),
      value: companyProfile?.businessDetail.businessType,
    },
    {
      label: t('business_description'),
      value: companyProfile?.businessDetail.businessDesc,
    },
    {
      label: t('business_website'),
      value: companyProfile?.businessDetail.website,
    },
  ];

  const { status, note } = meData?.data?.updateData ?? {};

  return (
    <ContentCard className={styled.root}>
      <ContentCard.Header
        title={t('company_profile')}
        onBack={() => router.push('/profile')}
        className={styled.header}
      />
      <ContentCard.Body className={styled.body}>
        {status === UpdateDataStatusType.VERIFYING && (
          <Notes showIcon status="warning" className="notes" title={note} />
        )}
        <div className="card-wrapper">
          <div className="left-column">
            <SkeletonWrapper
              className="identity-details-skeleton"
              loading={isLoading}
            >
              <SectionDetailsWrapper
                title={t('company_details')}
                icon={<IdBadgeOutline width={20} height={20} />}
              >
                <SectionFieldGroup>
                  {companyDetails.map(({ label, value }, index) => (
                    <SectionFieldProps
                      className="field-class"
                      key={index}
                      label={label}
                      isLoading={isLoading}
                    >
                      {value || '-'}
                    </SectionFieldProps>
                  ))}
                </SectionFieldGroup>
              </SectionDetailsWrapper>
            </SkeletonWrapper>
          </div>
          <div className="right-column">
            <SkeletonWrapper
              className="address-details-skeleton"
              loading={isLoading}
            >
              <SectionDetailsWrapper
                title={t('business_details')}
                icon={<ShopOutline width={20} height={20} />}
              >
                <SectionFieldGroup>
                  {businessDetails.map(({ label, value }, index) => (
                    <SectionFieldProps
                      className="field-class"
                      key={index}
                      label={label}
                      isLoading={isLoading}
                    >
                      {value || '-'}
                    </SectionFieldProps>
                  ))}
                </SectionFieldGroup>
              </SectionDetailsWrapper>
            </SkeletonWrapper>
          </div>
        </div>
      </ContentCard.Body>
    </ContentCard>
  );
}
const styled = {
  header: css`
    @media (max-width: ${tabletMd}px) {
      margin-top: 8px;
    }
  `,
  body: css`
    margin-top: 8px;
    @media (max-width: ${tabletMd}px) {
      margin-top: 0;
    }
    .notes {
      margin-bottom: 1rem;
    }
  `,
  root: css`
    width: 95%;
    .card-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
      gap: 1.25rem;
      @media (max-width: ${tabletMd}px) {
        display: unset;
      }
      > .left-column {
        flex: 1;
        @media (max-width: ${tabletMd}px) {
          margin: unset;
        }
        .address-details-skeleton {
          height: 14.25rem;
        }
        .identity-details-skeleton {
          height: 21.25rem;
        }
        > .section-details-wrapper {
          .ant-divider {
            border-color: var(--neutral-200);
            margin: 0.75rem 0;
          }
          .id-address-wrapper,
          .residential-address-wrapper {
            > .title {
              font-size: var(--regular-font-size);
              margin-bottom: 0.5rem;
            }
          }
        }
        > div:not(:last-child) {
          margin-bottom: 1.5rem;
        }
        > .bank-details {
          > .field-group-wrapper {
            display: flex;
            flex-direction: column;
            gap: unset;
            > .bank-name {
              margin-bottom: 0.25rem;
            }
          }
        }
      }
      > .right-column {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: ${tabletMd}px) {
          margin-top: 1rem;
        }
        .address-details-skeleton {
          height: 14.25rem;
        }
        > .section-details-wrapper {
          .ant-divider {
            border-color: var(--neutral-200);
            margin: 0.75rem 0;
          }
          .id-address-wrapper,
          .residential-address-wrapper {
            > .title {
              font-size: var(--regular-font-size);
              margin-bottom: 0.5rem;
            }
          }
        }
        > div:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  `,
};
