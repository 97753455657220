import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

import Flex from './Flex';

interface Props {
  children: ReactNode;
  className?: string;
  column?: boolean;
  justify?: React.CSSProperties['justifyContent'];
}

export default function ButtonGroup({
  children,
  className,
  justify,
  column = false,
}: Props) {
  return (
    <Flex
      className={cx(styled.root, className)}
      justify={justify}
      column={column}
    >
      {children}
    </Flex>
  );
}

const styled = {
  root: css`
    gap: 1rem;
  `,
};
