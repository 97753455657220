import { css, cx } from '@emotion/css';
import { color } from '@topremit/shared-web/styles/color';
import React, { useState } from 'react';

interface RadioProps {
  name?: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
  defaultChecked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Radio component. Supports both controlled and uncontrolled usage.
 *
 * @example
 * <Radio
 *  name="radio"
 *  value="test"
 *  checked={isChecked}
 *  onChange={(e)=> setIsChecked(e.target.checked)}
 */
export default function Radio({
  name,
  value,
  checked: externalChecked,
  disabled,
  className,
  defaultChecked,
  onChange,
}: RadioProps) {
  const [internalChecked, setInternalChecked] = useState(
    defaultChecked || false,
  );

  const isControlled = externalChecked !== undefined;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!isControlled) {
      setInternalChecked(event.target.checked);
    }
    onChange?.(event);
  }

  // Define checked value based on controlled or uncontrolled usage
  const checked = isControlled ? externalChecked : internalChecked;

  return (
    <div className={cx(styled.root, className)}>
      <input
        name={name}
        type="radio"
        value={value}
        checked={checked}
        disabled={disabled}
        className={styled.input}
        onChange={handleChange}
      />
      <span
        className={cx(styled.circle, {
          [styled.circleChecked]: checked,
          [styled.disabled]: disabled,
        })}
      />
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    cursor: pointer;
    align-items: center;
  `,
  input: css`
    display: none;
  `,
  circle: css`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    border: 1px solid ${color.neutral200};

    &:before {
      top: 50%;
      left: 50%;
      opacity: 0;
      content: '';
      width: 12px;
      height: 12px;
      border: none;
      border-radius: 50%;
      position: absolute;
      transition: opacity 0.2s ease;
      transform: translate(-50%, -50%);
      background-color: ${color.neutral0};
    }
  `,
  circleChecked: css`
    border-color: ${color.blue500};
    background-color: ${color.blue500};

    &:before {
      opacity: 1;
    }
  `,
  disabled: css`
    background-color: ${color.neutral100};
  `,
};
