import { css, cx } from '@emotion/css';
import { color } from '@topremit/shared-web/styles/color';
import { forwardRef } from 'react';

import Flex, { IFlexProps } from './Flex';

type Variant = 'primary' | 'no-outline' | 'outline';

type Style = {
  borderRadius?: number;
  p?: number;
};

type BaseProps = IFlexProps & {
  variant?: Exclude<Variant, 'outline'>;
};

type VariantOutline = IFlexProps & {
  variant: 'outline';
  borderWidth?: number;
  borderColor?: string;
};

type Props = (BaseProps | VariantOutline) & Style;

const Card = forwardRef<HTMLDivElement, Props>(
  (
    {
      variant = 'primary',
      children,
      className,
      borderRadius = 12,
      p = 16,
      ...props
    },
    ref,
  ) => {
    const borderWidth =
      variant === 'outline'
        ? (props as VariantOutline)?.borderWidth || 1
        : undefined;

    const borderColor =
      variant === 'outline'
        ? (props as VariantOutline)?.borderColor || color.neutral200
        : undefined;

    return (
      <Flex
        ref={ref}
        className={cx(
          styled.root(p, borderRadius),
          { [styled.primary]: variant === 'primary' },
          { [styled.outline(borderWidth, borderColor)]: variant === 'outline' },
          className,
        )}
        {...props}
      >
        {children}
      </Flex>
    );
  },
);

const styled = {
  root: (padding: number, borderRadius: number) => css`
    padding: ${padding}px;
    border-radius: ${borderRadius}px;
    background: ${color.neutral0};
  `,
  primary: css`
    box-shadow: 0px 2px 8px 0px #2e486529;
  `,
  outline: (
    borderWidth: number = 1,
    borderColor: string = color.neutral200,
  ) => css`
    /** for next approach, prefer to use Card and remove the important */
    border: ${borderWidth}px solid ${borderColor} !important;
  `,
};

export default Card;
