import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

import { useSidebar } from '../../../../hooks';
import { Badge } from '../../../elements';

interface ISideMenuIconProps {
  children?: ReactNode;
  hasRedDot?: boolean;
  expandIcon?: ReactNode;
  className?: string;
}

export default function SideMenuIcon({
  children,
  expandIcon,
  hasRedDot,
  className,
}: ISideMenuIconProps) {
  const [{ isExpand: isExpandSidebar }] = useSidebar();

  return (
    <div
      data-type="icon"
      className={cx(className ?? styled.icon, {
        [styled.collapsed]: !isExpandSidebar,
      })}
    >
      <Badge {...(hasRedDot && { dot: true, offset: [-1, 1], size: 8 })}>
        {expandIcon && isExpandSidebar ? expandIcon : children}
      </Badge>
    </div>
  );
}

const styled = {
  icon: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-items: center;
    margin: 0 12px 0 0px;
    padding-left: 4px;

    svg {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
    }
  `,
  collapsed: css`
    transition: margin 1s;
    margin: 0;
  `,
};
