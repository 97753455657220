import { AccountWithKycStatus } from '@api-hooks/common';
import { CURRENT_ACCOUNT_KEY } from '@common/storage';
import { setCookie } from '@topremit/shared-web/common/cookie';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface ICurrentAccountStore {
  currentAccount: AccountWithKycStatus | null;
  setCurrentAccount: (account: AccountWithKycStatus) => void;
  removeCurrentAccount: () => void;
}

export const useCurrentAccountStore = create<ICurrentAccountStore>()(
  persist(
    (set) => ({
      currentAccount: null,
      setCurrentAccount: (account) => {
        set(() => ({ currentAccount: account }));
        setCookie({ name: 'account_type', value: account.type });
      },
      removeCurrentAccount: () => set(() => ({ currentAccount: null })),
    }),
    {
      name: CURRENT_ACCOUNT_KEY,
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
