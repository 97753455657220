import { type TranslateFunction } from '@topremit/shared-web/hooks/use-translation';
import * as yup from 'yup';

import { RequiredDocument } from '../types';

export function updatePersonalDataSchema(t: TranslateFunction<'profile'>) {
  return yup.object().shape({
    address: yup.string().nullable().required(t('error:field.required_choose')),
    city: yup.string().nullable().required(t('error:field.required_choose')),
    occupation: yup.number().required(t('error:field.required_choose')),
    pep: yup.boolean().nullable().required(t('error:field.required_choose')),
    postalCode: yup
      .string()
      .nullable()
      .required(t('error:field.required_choose')),
    province: yup
      .string()
      .nullable()
      .required(t('error:field.required_choose')),
  });
}

export const updateBusinessDataSchema = (
  t: TranslateFunction<'profile'>,
  requiredDocuments?: RequiredDocument[],
) => {
  const requiredDocumentSchema = {};

  requiredDocuments?.forEach((item) => {
    requiredDocumentSchema[item.value] = yup
      .array()
      .min(1, t('error:field.required_upload'))
      .required(t('error:field.required_upload'));
  });

  return yup.object().shape({
    address: yup.string().nullable().required(t('error:field.required')),
    businessDescription: yup
      .string()
      .nullable()
      .required(t('error:field.required')),
    businessIndustry: yup
      .string()
      .nullable()
      .required(t('error:field.required_choose')),
    city: yup.string().nullable().required(t('error:field.required_choose')),
    country: yup.string().nullable().required(t('error:field.required_choose')),
    pep: yup.boolean().nullable().required(t('error:field.required_choose')),
    structureChanged: yup
      .boolean()
      .nullable()
      .required(t('error:field.required_choose')),
    clientHolders: yup
      .string()
      .nullable()
      .when('pep', {
        is: true,
        then: yup.string().nullable().required(t('error:field.required')),
      }),
    postalCode: yup.string().nullable().required(t('error:field.required')),
    province: yup.string().required(t('error:field.required_choose')),
    documents: yup.object().when('structureChanged', {
      is: true,
      then: yup
        .object()
        .nullable()
        .shape({
          ...requiredDocumentSchema,
        }),
      otherwise: yup.object().notRequired(),
    }),
  });
};
