import { css, cx } from '@emotion/css';
import { parseHtml } from '@topremit/shared-web/components/elements';
import { useEffect, useRef, useState } from 'react';

import Button from './Button';
import Card from './Card';
import { useDialogStore } from '../../stores';
import CardBall from '../shapes/CardBall';

export interface IVariantCardProps {
  type?: 'info' | 'danger' | 'primary' | 'invert';
  content?: React.ReactNode;
  title?: string;
  description?: string;
  hasActionButton?: boolean;
  labelActionButton?: string;
  onClickActionButton?: () => void;
  bodyModal?: JSX.Element;
  className?: string;
}

export default function VariantCard(props: IVariantCardProps) {
  const {
    type = 'info',
    content,
    title,
    description,
    hasActionButton,
    onClickActionButton = () => {},
    labelActionButton = '',
    className,
    bodyModal,
  } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const showDialog = useDialogStore((state) => state.show);

  const [parentHeight, setParentHeight] = useState<number>(0);

  if (content && (title || description)) {
    throw new Error(
      "If 'content' is provided, 'title' and 'description' cannot be provided.",
    );
  }

  useEffect(() => {
    if (divRef.current && divRef.current.offsetHeight > 0) {
      setParentHeight(divRef.current.offsetHeight);
    }
  }, [divRef, props]);

  return (
    <Card
      column
      className={cx(styled.root(parentHeight), className, {
        [styled.invert]: type === 'invert',
      })}
      ref={divRef}
    >
      <div className="content">
        {!content ? (
          <>
            <div
              className={cx('title bold-text', {
                [styled.primaryText]: type === 'primary',
              })}
            >
              <span>{title && parseHtml(title)}</span>
            </div>
            <div
              className={cx('desc sm-text', {
                [styled.primaryText]: type === 'primary',
              })}
            >
              <span>{description && parseHtml(description)}</span>
            </div>
          </>
        ) : (
          content
        )}
      </div>
      {hasActionButton && (
        <div className="btn-wrapper">
          <Button
            fullWidth
            size="medium"
            {...(type !== 'info' && { type })}
            onClick={() => {
              if (bodyModal) {
                return showDialog({ body: bodyModal });
              }
              return onClickActionButton();
            }}
          >
            {labelActionButton}
          </Button>
        </div>
      )}
      <div className="bg-wrapper">
        <CardBall {...{ type }} height={parentHeight} />
      </div>
    </Card>
  );
}

const styled = {
  root: (parentHeight) => css`
    display: flex;
    position: relative;
    width: 22.5rem;
    min-height: fit-content;
    padding: 1.25rem;
    border-radius: 0.625rem;
    align-items: center;
    overflow: hidden;
    justify-content: center;

    .content {
      z-index: 1;
      width: 100%;
      word-wrap: break-word;
      .title {
        margin-bottom: 0.25rem;
      }
    }
    .btn-wrapper {
      z-index: 1;
      margin-top: 1rem;
      width: 100%;
    }
    .bg-wrapper {
      position: absolute;
      bottom: -5px;
      right: 0;
      > svg {
        border-radius: 0 0 0.625rem 0;
        /**
          Notes
          1. 40px maximize the SVG to match container
          2. 144 / 126, from SVG ratio by calculating width based on ratio it'll avoid layout shifting (problem: when VariantCard don't have button)
        */
        width: ${((parentHeight + 40) * 144) / 126}px;
        height: ${parentHeight + 40}px;
      }
    }
  `,
  invert: css`
    background: var(--neutral-100);
  `,
  primaryText: css`
    color: var(--neutral-0);
  `,
};
