import { css, cx } from '@emotion/css';
import { color } from '@topremit/shared-web/styles/color';
import { ElementType, HTMLAttributes } from 'react';

import { clampBuilder, handleLongText } from '../../common/helper';
import { fontSize, FontSize, fontWeight, lineHeight } from '../../common/size';

interface Decoration {
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
  black?: boolean;
  secondary?: boolean;
  breakWord?: boolean;
}

export interface ITextProps
  extends HTMLAttributes<HTMLDivElement>,
    Partial<Decoration> {
  /** Custom component or HTML element to be rendered as. Defaults to 'div'. */
  as?: ElementType;
  /** Minimum font size for responsive typography. */
  minSize?: FontSize;
  /** Maximum font size for responsive typography. */
  maxSize?: FontSize;
  /** Maximum number of lines before truncation with ellipsis. */
  /** Note: use minSize and maxSize, so we don't need to manually add css style and manually calculate clamp */
  maxLine?: number;
  /** Maximum length of the text content before truncation. */
  maxLength?: number;
  /** this props will override minSize and maxSize */
  size?: FontSize;
}

/**
 * A versatile text component with support for responsive font sizes,
 * text truncation, and long text handling.
 *
 * @param {ITextProps} props - The props for the Text component.
 * @returns {JSX.Element} The rendered text component.
 */

export default function Text({
  as: Component = 'div',
  className,
  children,
  minSize,
  maxSize,
  maxLine,
  size,
  maxLength,
  align,
  bold,
  black,
  secondary,
  breakWord = true,
  ...props
}: ITextProps) {
  const _className = cx(
    'text',
    { [styled.ellipsis(maxLine)]: maxLine !== undefined },
    styled.clamp(size, minSize, maxSize),
    styled.decoration({ align, bold, black, secondary, breakWord }),
    className,
  );

  return (
    <Component className={_className} {...props}>
      {maxLength && typeof children === 'string'
        ? handleLongText(children, maxLength)
        : children}
    </Component>
  );
}

const styled = {
  decoration: ({ align, bold, black, secondary, breakWord }: Decoration) => {
    return css`
      ${black !== undefined && `font-weight: ${fontWeight.black};`}
      ${bold !== undefined && `font-weight: ${fontWeight.bold};`}
      color: ${secondary && color.neutral500};
      text-align: ${align};

      /** break to next line when long space and without space */
      word-break: ${breakWord ? 'break-word' : 'unset'};
      overflow-wrap: ${breakWord ? 'break-word' : 'unset'};
    `;
  },
  /**
   * Styling function for text truncation with ellipsis after a specified number of lines.
   *
   * @param {number} [maxLine] - The maximum number of lines before truncation.
   * @returns {string} The generated className for ellipsis styling.
   */
  ellipsis: (maxLine?: number) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${maxLine};
    -webkit-box-orient: vertical;
  `,
  /**
   * Styling function for responsive font sizes using CSS clamp.
   *
   * @param {FontSize} [minSize] - The minimum font size.
   * @param {FontSize} [maxSize] - The maximum font size.
   * @returns {string | undefined} The generated className for responsive font sizes, or undefined if sizes are not provided.
   */
  clamp: (size?: FontSize, minSize?: FontSize, maxSize?: FontSize) => {
    if (size) {
      return css`
        font-size: ${fontSize[size]}rem;
        line-height: ${lineHeight[size]}px;
      `;
    }
    if (minSize && maxSize) {
      return css`
        font-size: ${clampBuilder(fontSize[minSize], fontSize[maxSize])};
        line-height: ${clampBuilder(
          lineHeight[minSize] / 16,
          lineHeight[maxSize] / 16,
        )};
      `;
    }
  },
};
