import { useCallback, useEffect, useRef } from 'react';

export default function useInterval() {
  const interval = useRef<number | undefined>(undefined);

  const _setInterval = useCallback((fn, ms: number) => {
    interval.current = setInterval(fn, ms) as unknown as number;
  }, []);

  const _clearInterval = useCallback(() => clearInterval(interval.current), []);

  useEffect(() => {
    return function cleanup() {
      if (interval) {
        clearInterval(interval.current);
      }
    };
  }, []);

  return {
    setInterval: _setInterval,
    clearInterval: _clearInterval,
  };
}
