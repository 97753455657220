import dynamic from 'next/dynamic';
import { SVGProps } from 'react';

const PersonalLogo = dynamic(() => import('./PersonalLogo'));
const PersonalWhiteLogo = dynamic(() => import('./PersonalWhiteLogo'));

function DefaultLogo(props: SVGProps<SVGSVGElement>) {
  return <PersonalLogo {...props} />;
}

function WhiteLogo(props: SVGProps<SVGSVGElement>) {
  return <PersonalWhiteLogo {...props} />;
}

function Logo({
  type = 'default',
  ...resProps
}: Omit<SVGProps<SVGSVGElement>, 'fill'> & {
  type?: 'default' | 'white';
}) {
  return type === 'default' ? (
    <DefaultLogo {...resProps} />
  ) : (
    <WhiteLogo {...resProps} />
  );
}

export default Logo;
