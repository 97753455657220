import { SVGProps } from 'react';

const TopvalasLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={100}
    height={20}
    viewBox="0 0 100 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1523_19646)">
      <path
        d="M18.546 2.47472L15.8072 11.3478C15.7689 11.4716 15.7692 11.6041 15.8079 11.7278L17.6312 17.5962C17.6672 17.7122 17.7394 17.8138 17.8371 17.886C17.9349 17.9582 18.0531 17.9973 18.1747 17.9976C18.2962 17.998 18.4147 17.9595 18.5128 17.8879C18.611 17.8162 18.6837 17.7151 18.7204 17.5993L23.3904 2.86274C23.4264 2.76596 23.4384 2.66193 23.4254 2.55951C23.4124 2.4571 23.3748 2.35935 23.3159 2.27459C23.2569 2.18984 23.1784 2.1206 23.0869 2.07279C22.9954 2.02497 22.8937 2 22.7905 2H19.1893C19.0454 2 18.9053 2.04609 18.7895 2.13153C18.6738 2.21696 18.5884 2.33723 18.546 2.47472Z"
        fill="#A695F9"
      />
      <path
        d="M15.9108 18H13.0952C12.9651 18 12.8382 17.9604 12.7312 17.8865C12.6243 17.8125 12.5424 17.7078 12.4964 17.5862L8.12605 6.016L4.04779 3.12921C3.9475 3.04462 3.87564 2.93125 3.84192 2.80445C3.80821 2.67766 3.81428 2.54357 3.85931 2.42034C3.90434 2.29711 3.98616 2.19069 4.09368 2.11551C4.2012 2.04032 4.32923 2 4.46043 2H11.3069C11.4433 2 11.576 2.04354 11.6859 2.12429C11.7958 2.20503 11.877 2.31875 11.9177 2.44888L13.6557 8.0032L15.3792 13.5136L16.5217 17.1691C16.5516 17.2649 16.5586 17.3664 16.5421 17.4654C16.5256 17.5645 16.486 17.6582 16.4265 17.7391C16.367 17.82 16.2894 17.8857 16.1998 17.9311C16.1102 17.9764 16.0112 18 15.9108 18Z"
        fill="#A695F9"
      />
      <path
        d="M31.7842 7.73711V11.8424C31.7737 11.9545 31.7867 12.0676 31.8223 12.1744C31.8579 12.2812 31.9154 12.3794 31.9911 12.4627C32.1289 12.5906 32.3612 12.6544 32.6879 12.6543H33.6837V14.4618H32.3358C30.5281 14.4618 29.6243 13.5836 29.6243 11.8271V7.73711H28.6134V5.97555H29.6243V3.87695H31.7842V5.97555H33.6837V7.73711H31.7842Z"
        fill="#423D66"
      />
      <path
        d="M36.7929 14.0558C36.1445 13.6986 35.6107 13.1647 35.2536 12.5162C34.8705 11.812 34.6778 11.0201 34.6943 10.2186C34.678 9.41522 34.8763 8.62205 35.2689 7.92093C35.6377 7.27086 36.1817 6.73744 36.8389 6.3814C37.5248 6.02417 38.2867 5.83765 39.06 5.83765C39.8334 5.83765 40.5953 6.02417 41.2812 6.3814C41.9383 6.73757 42.4822 7.27096 42.8512 7.92093C43.2437 8.62205 43.4421 9.41522 43.4257 10.2186C43.442 11.0239 43.2381 11.8184 42.8359 12.5162C42.457 13.1664 41.9057 13.6992 41.2429 14.0558C40.5528 14.4236 39.7807 14.6106 38.9987 14.5995C38.2289 14.6114 37.4691 14.4241 36.7929 14.0558ZM40.094 12.4473C40.4444 12.25 40.7292 11.9545 40.9136 11.5972C41.1291 11.1704 41.2344 10.6965 41.22 10.2186C41.22 9.41202 41.008 8.79166 40.5842 8.35749C40.3844 8.14698 40.143 7.98036 39.8753 7.86827C39.6076 7.75617 39.3195 7.70104 39.0293 7.7064C38.7413 7.70013 38.4553 7.75491 38.1899 7.86713C37.9246 7.97935 37.6861 8.14647 37.49 8.35749C37.0764 8.79156 36.8695 9.41192 36.8695 10.2186C36.8695 11.0255 37.0712 11.6458 37.4747 12.0797C37.667 12.2913 37.9027 12.459 38.1657 12.5713C38.4287 12.6837 38.7128 12.738 38.9987 12.7308C39.3821 12.732 39.7594 12.6344 40.094 12.4473Z"
        fill="#423D66"
      />
      <path
        d="M48.2891 6.22814C48.8017 5.96105 49.3732 5.82675 49.9511 5.83751C50.6444 5.82888 51.3263 6.01459 51.9195 6.37361C52.5168 6.74226 52.9995 7.27008 53.3135 7.89783C53.6674 8.60833 53.8435 9.3942 53.8266 10.1878C53.8424 10.9862 53.6664 11.7768 53.3135 12.4931C53.0004 13.1296 52.5182 13.6676 51.9195 14.048C51.3297 14.4164 50.6465 14.6077 49.9511 14.5994C49.3788 14.6113 48.8127 14.4797 48.3044 14.2164C47.8539 13.9819 47.4594 13.6527 47.148 13.2514V17.3427H45.0033V5.97533H47.148V7.20079C47.4475 6.79217 47.8382 6.45912 48.2891 6.22814ZM51.322 8.86283C51.1304 8.5083 50.844 8.21397 50.4949 8.01267C50.1567 7.81973 49.7737 7.71932 49.3844 7.72158C48.9991 7.72076 48.6206 7.82397 48.2891 8.02033C47.9392 8.22748 47.653 8.52689 47.4619 8.88579C47.2448 9.29578 47.1367 9.75467 47.148 10.2185C47.1366 10.6822 47.2447 11.1412 47.4619 11.5511C47.6529 11.9101 47.9391 12.2095 48.2891 12.4166C48.6236 12.6135 49.0049 12.7167 49.393 12.7153C49.7811 12.714 50.1618 12.6081 50.4949 12.4089C50.844 12.1974 51.1297 11.8958 51.322 11.5358C51.5403 11.1207 51.6485 10.6566 51.6361 10.1878C51.6479 9.72642 51.5397 9.26985 51.322 8.86283Z"
        fill="#423D66"
      />
      <path
        d="M58.9275 12.4855L61.072 5.97534H63.3543L60.2142 14.4616H57.6101L54.4851 5.97534H56.7829L58.9275 12.4855Z"
        fill="#423D66"
      />
      <path
        d="M64.5261 7.90552C64.8383 7.27451 65.3213 6.74376 65.9201 6.37365C66.5133 6.01458 67.1951 5.82886 67.8884 5.83755C68.4657 5.8245 69.0371 5.95616 69.5504 6.22052C69.9982 6.45287 70.3882 6.78262 70.6917 7.18552V5.97537H72.8515V14.4616H70.6917V13.2208C70.3958 13.6367 70.0044 13.9755 69.5504 14.2088C69.0327 14.477 68.4561 14.6113 67.8731 14.5994C67.1827 14.6064 66.5049 14.415 65.9201 14.048C65.3213 13.6678 64.8389 13.1298 64.5261 12.4932C64.1731 11.7769 63.9971 10.9863 64.0129 10.1879C63.9976 9.39686 64.1737 8.61386 64.5261 7.90552ZM70.3853 8.87818C70.1954 8.5204 69.9087 8.22318 69.5581 8.02037C69.2186 7.82321 68.8326 7.72009 68.44 7.72162C68.0458 7.71768 67.658 7.82202 67.319 8.02325C66.98 8.22449 66.7028 8.51491 66.5175 8.86287C66.2997 9.26986 66.1915 9.72645 66.2034 10.1879C66.1928 10.654 66.3008 11.1153 66.5175 11.5282C66.7081 11.8915 66.994 12.196 67.3447 12.409C67.6746 12.6099 68.0536 12.716 68.44 12.7154C68.8326 12.7169 69.2185 12.6138 69.5581 12.4166C69.9088 12.2139 70.1954 11.9166 70.3853 11.5588C70.5992 11.1452 70.7047 10.684 70.6917 10.2185C70.7046 9.75299 70.5992 9.29182 70.3853 8.87818Z"
        fill="#423D66"
      />
      <path
        d="M77.0946 3.12622V14.4617H74.95V3.12622H77.0946Z"
        fill="#423D66"
      />
      <path
        d="M79.1701 7.90552C79.4823 7.27451 79.9653 6.74376 80.5642 6.37365C81.1573 6.01458 81.8392 5.82886 82.5325 5.83755C83.1097 5.8245 83.6811 5.95616 84.1945 6.22052C84.6422 6.45287 85.0322 6.78262 85.3357 7.18552V5.97537H87.4956V14.4616H85.3357V13.2208C85.0398 13.6367 84.6485 13.9755 84.1945 14.2088C83.6768 14.477 83.1001 14.6113 82.5171 14.5994C81.8268 14.6064 81.1489 14.415 80.5642 14.048C79.9654 13.6678 79.483 13.1298 79.1701 12.4932C78.8171 11.7769 78.6411 10.9863 78.657 10.1879C78.6417 9.39686 78.8177 8.61386 79.1701 7.90552ZM85.0293 8.87818C84.8394 8.5204 84.5528 8.22318 84.2021 8.02037C83.8626 7.82321 83.4766 7.72009 83.084 7.72162C82.6898 7.71768 82.3021 7.82202 81.9631 8.02325C81.6241 8.22449 81.3468 8.51491 81.1615 8.86287C80.9438 9.26986 80.8356 9.72645 80.8475 10.1879C80.8368 10.654 80.9449 11.1153 81.1615 11.5282C81.3521 11.8915 81.6381 12.196 81.9887 12.409C82.3187 12.6099 82.6977 12.716 83.084 12.7154C83.4766 12.7169 83.8626 12.6138 84.2021 12.4166C84.5528 12.2139 84.8395 11.9166 85.0293 11.5588C85.2433 11.1452 85.3487 10.684 85.3357 10.2185C85.3487 9.75299 85.2433 9.29182 85.0293 8.87818Z"
        fill="#423D66"
      />
      <path
        d="M90.9728 14.2241C90.4566 14.0017 90.0058 13.6511 89.6631 13.2055C89.348 12.7945 89.1638 12.2981 89.1345 11.781H91.2944C91.3143 11.9424 91.3675 12.0978 91.4506 12.2376C91.5336 12.3773 91.6448 12.4983 91.777 12.5929C92.0808 12.8147 92.4503 12.9279 92.8262 12.9145C93.163 12.9339 93.4975 12.8483 93.7836 12.6695C93.8894 12.6024 93.9766 12.5096 94.0368 12.3998C94.0971 12.2899 94.1286 12.1666 94.1283 12.0413C94.129 11.9075 94.0891 11.7765 94.0139 11.6658C93.9387 11.5551 93.8317 11.4697 93.707 11.421C93.2746 11.2308 92.826 11.0796 92.3667 10.9691C91.8377 10.8482 91.3181 10.6895 90.8119 10.4943C90.4093 10.3315 90.052 10.074 89.7703 9.74366C89.4792 9.40663 89.3337 8.9522 89.3337 8.38038C89.3309 7.91953 89.4729 7.46943 89.7397 7.09366C90.0353 6.68553 90.4383 6.36739 90.9039 6.1746C91.4685 5.9384 92.0765 5.82358 92.6884 5.83757C93.6994 5.83757 94.5061 6.09033 95.1086 6.59585C95.408 6.84843 95.6512 7.16082 95.8227 7.51298C95.9942 7.86513 96.0901 8.24926 96.1044 8.64069H94.0517C94.042 8.48455 93.9995 8.3322 93.9269 8.19362C93.8543 8.05503 93.7533 7.93333 93.6305 7.83647C93.3402 7.62483 92.986 7.51941 92.6272 7.53789C92.3138 7.51882 92.0018 7.59345 91.7309 7.75226C91.6314 7.81621 91.5502 7.90487 91.4952 8.0096C91.4401 8.11432 91.4132 8.23153 91.417 8.34976C91.4161 8.48852 91.4564 8.62443 91.5327 8.7403C91.6091 8.85617 91.7181 8.94679 91.8459 9.0007C92.2729 9.19806 92.7196 9.34953 93.1786 9.45258C93.6945 9.57527 94.2012 9.73394 94.695 9.92742C95.0959 10.0929 95.4507 10.3531 95.7291 10.6857C96.0348 11.0702 96.1948 11.5503 96.1809 12.0413C96.1869 12.5121 96.0449 12.9729 95.775 13.3587C95.4804 13.766 95.0769 14.0819 94.6108 14.2702C94.0499 14.5003 93.4477 14.6124 92.8416 14.5995C92.1989 14.61 91.5615 14.482 90.9728 14.2241Z"
        fill="#423D66"
      />
    </g>
    <defs>
      <clipPath id="clip0_1523_19646">
        <rect
          width={92.3617}
          height={16}
          fill="white"
          transform="translate(3.81909 2)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default TopvalasLogo;
