import { cx } from '@emotion/css';
import { moveToNextTick } from '@global-common/helper';
import { Flex } from '@global-elements';
import SuspenseWithSpinner from '@global-elements-utils/SuspenseWithSpinner';
import { Angle } from '@global-shapes';
import { useDialogStore } from '@global-stores';
import { lazy } from 'react';

import ChooseCountryCurrencyLabel from './ChooseCountryCurrencyLabel';
import ChooseCountryCurrencyValue from './ChooseCountryCurrencyValue';
import { getArrowColor } from './helper';
import {
  ChooseCountryCurrencyContext,
  useChooseCountryCurrency,
} from './hooks/use-choose-country-currency';
import { styles } from './styles';

const LazyCountryCurrencyOptions = lazy(
  () => import('./CountryCurrencyOptionsDialog'),
);

export default function ChooseCountryCurrencyContent() {
  const chooseCountryCurrencyContext = useChooseCountryCurrency();

  const {
    value,
    error,
    disabled,
    showLabelWhenHasValue,
    setIsFocus,
    setIsHover,
  } = chooseCountryCurrencyContext;

  const showDialog = useDialogStore((s) => s.push);

  const hasValue = !!value.country && !!value.currency;

  const arrowColor = getArrowColor(disabled, error);

  function handleOpenDialog() {
    setIsFocus(false);
    moveToNextTick(() => {
      showDialog({
        body: (
          <SuspenseWithSpinner>
            <ChooseCountryCurrencyContext.Provider
              value={chooseCountryCurrencyContext}
            >
              <LazyCountryCurrencyOptions />
            </ChooseCountryCurrencyContext.Provider>
          </SuspenseWithSpinner>
        ),
        fullHeight: true,
      });
    });
  }

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={handleOpenDialog}
      onBlur={() => setIsFocus(false)}
      onFocus={() => setIsFocus(true)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={cx(
        styles.triggerButton({
          hasValue,
          disabled,
        }),
        'choose-country-currency-trigger',
      )}
    >
      <Flex
        as="span"
        align="center"
        className={cx(
          styles.triggerContent,
          'choose-country-currency-trigger-content',
        )}
      >
        <Flex
          column
          justify="end"
          className={cx(
            styles.labelWrapper({
              hasValue,
              showLabelWhenHasValue,
            }),
            'choose-country-currency-label-wrapper',
          )}
        >
          <ChooseCountryCurrencyLabel />
          {hasValue && <ChooseCountryCurrencyValue />}
          <Angle
            width={24}
            height={24}
            size="small"
            direction="down"
            fill={arrowColor}
            className={styles.arrow}
          />
        </Flex>
      </Flex>
    </button>
  );
}
