import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import useCallbackRef from './use-callback-ref';

/**
 *
 * @param ref ref is required.
 * @param watch watch is optional. Empty watch for not rerender.
 * @returns
 */

export default function useMeasure(ref, watch = false) {
  const [element, attachRef] = useCallbackRef();
  const [bounds, setBounds] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    function onResize([entry]) {
      setBounds({
        height: entry.contentRect.height,
        width: entry.contentRect.width,
      });
    }

    const observer = new ResizeObserver(onResize);

    if (element && element.current) {
      observer.observe(element.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [element, watch]);

  useEffect(() => {
    attachRef(ref);
  }, [attachRef, ref]);

  return bounds;
}
