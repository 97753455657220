import { usePin } from '@hooks';
import passwordImage from '@public/images/svg/password.svg';
import { Flex, ListMenu, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import Image from 'next/image';
import { useRouter } from 'next/router';

const PasswordModal = () => {
  const closeDialog = useDialogStore((store) => store.close);

  const { t } = useTranslation();
  const router = useRouter();
  const { change: changePin } = usePin();

  return (
    <Flex>
      <Text as="h4">{t('dashboard:pin_and_password')}</Text>
      <ListMenu
        hasDivider
        leftIcon={
          <Image src={passwordImage} width={32} height={32} alt="password" />
        }
        content={t('dashboard:pin.change')}
        type="arrow"
        onClick={() => {
          closeDialog();
          changePin();
        }}
      />
      <ListMenu
        leftIcon={
          <Image src={passwordImage} width={32} height={32} alt="password" />
        }
        content={t('dashboard:change_password')}
        type="arrow"
        onClick={() => {
          router.push(`/profile/change-password`);
          closeDialog();
        }}
      />
    </Flex>
  );
};

export default PasswordModal;
