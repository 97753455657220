import { ClassNamesArg, css, cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode; // <VStepperConnector />, <VStepperContent />
  className?: ClassNamesArg;
}

export default function VStepperItem({ children, className }: Props) {
  return <Flex className={cx(styled.root, className)}>{children}</Flex>;
}

const styled = {
  root: css`
    gap: 0.75rem;
  `,
};
