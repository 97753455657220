import { color } from '@topremit/shared-web/styles/color';

import { State } from './types';

export const fontColor: Record<State, string> = {
  error: color.red500,
  disabled: color.neutral300,
  default: color.neutral500,
  active: color.neutral500,
};

export const borderColor: Record<State, string> = {
  active: color.blue500,
  error: color.red500,
  disabled: color.neutral200,
  default: color.neutral200,
};

export const backgroundColor: Record<State, string> = {
  active: color.neutral0,
  error: color.neutral0,
  disabled: color.neutral100,
  default: color.neutral0,
};

export const outlineSize: Record<State, string> = {
  active: '2px',
  error: '2px',
  disabled: '1px',
  default: '1px',
};
