import { css } from '@emotion/css';
import { useAuth } from '@hooks';
import { setMobileCSSHeightProperty } from '@topremit/shared-web/common/helper';
import {
  breakpoint,
  navbarSize,
  screenSize,
} from '@topremit/shared-web/common/size';
import { Container, Flex } from '@topremit/shared-web/components/elements';
import { Logo } from '@topremit/shared-web/components/shapes';
import { useMediaQuery, useNavbarDrawer } from '@topremit/shared-web/hooks';
import {
  SizeScreenDesktopLg,
  SizeScreenMobileLg,
  SizeScreenTabletMd,
} from '@topremit-ui/design-tokens';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect } from 'react';

import ActionMenu from './ActionMenu';
import DesktopNavbarMenu from './DesktopNavbarMenu';
import LanguageMenu from './LanguageMenu';

const BurgerButton = dynamic(() => import('@layouts/BurgerButton'));
const MobileNavbarMenu = dynamic(() => import('./MobileNavbarMenu'));

interface Props {
  hasBackground?: boolean;
}

const { publicRuntimeConfig } = getConfig();

function NavigationBar({ hasBackground: hasBackgroundProps = true }: Props) {
  const isShowBurger = useMediaQuery(
    `(max-width: ${breakpoint.navbarBurger}px)`,
  );
  // Prevent DesktopMenu from showing on mobile with slow internet
  const isShowDesktopMenu = useMediaQuery(
    `(min-width: ${breakpoint.navbarBurger + 1}px)`,
  );
  const isTabletSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletSm}px)`);

  const { isAuthenticated } = useAuth();

  const { isOpen: isOpenDrawer, close: closeDrawer } = useNavbarDrawer();
  const hasBackground =
    hasBackgroundProps || (!hasBackgroundProps && isOpenDrawer);

  const logoRedirectUrl = publicRuntimeConfig?.staticFolder
    ? `/`
    : isAuthenticated
      ? '/quote'
      : '/';

  const isShowLanguageMenu = !isMobileSize || isOpenDrawer;

  useEffect(() => {
    if (isTabletSize) {
      setMobileCSSHeightProperty();
    }
    function onResize() {
      if (isTabletSize) {
        setMobileCSSHeightProperty();
      }
    }
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [isTabletSize]);

  useEffect(() => {
    function onResize() {
      if (isShowBurger) {
        closeDrawer();
      }
    }
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [isShowBurger]);

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (isOpenDrawer) {
        body.style.overflowY = 'hidden';
      } else {
        body.style.overflowY = 'visible';
      }
    }

    return () => {
      if (body) {
        body.style.overflowY = 'visible';
      }
    };
  }, [isOpenDrawer]);

  return (
    <nav className={styled.root(hasBackground)}>
      <Container className={styled.container(isOpenDrawer)}>
        <Flex className={styled.leftWrapper}>
          <Link href={logoRedirectUrl} rel="noopener noreferrer">
            <Logo
              width={isMobileSize ? 116 : 128}
              height={isMobileSize ? 32 : 35}
              className="logo"
              {...(!hasBackground && {
                type: isMobileSize
                  ? hasBackground
                    ? 'default'
                    : 'white'
                  : 'white',
              })}
            />
          </Link>
          {isShowDesktopMenu && (
            <DesktopNavbarMenu hasBackground={hasBackground} />
          )}
        </Flex>
        <Flex className={styled.actionMenuWrapper(isAuthenticated)}>
          {isShowLanguageMenu && (
            <LanguageMenu
              type={isOpenDrawer ? 'drawer' : 'button'}
              hasBackground={hasBackground}
            />
          )}

          {(!isOpenDrawer || isAuthenticated) && (
            <ActionMenu hasBackground={hasBackground} />
          )}

          {isShowBurger && <BurgerButton hasBackground={hasBackground} />}
        </Flex>
        {isOpenDrawer && <MobileNavbarMenu hasBackground={hasBackground} />}
      </Container>
    </nav>
  );
}

const styled = {
  root: (hasBackground) => css`
    transition: all 0.5s;
    height: ${navbarSize.md}px;
    box-shadow: ${hasBackground ? '0 8px 16px rgba(46, 72, 101, 0.1)' : ''};
    justify-content: space-between;
    background-color: ${hasBackground ? 'var(--neutral-0)' : 'transparent'};
    @media (max-width: ${SizeScreenMobileLg}px) {
      height: ${navbarSize.sm}px;
    }
  `,
  container: (isOpenDrawer) => css`
    display: flex;
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
    @media (max-width: ${SizeScreenDesktopLg}px) {
      padding-top: ${isOpenDrawer ? '1.375rem' : '1.25rem'};
      padding-bottom: ${isOpenDrawer ? '1.375rem' : '1.25rem'};
    }
    @media (max-width: ${SizeScreenTabletMd}px) {
      padding-top: ${isOpenDrawer ? '1.375rem' : '1.1875rem'};
      padding-bottom: ${isOpenDrawer ? '1.375rem' : '1.1875rem'};
    }
    @media (max-width: ${SizeScreenMobileLg}px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  `,
  leftWrapper: css`
    align-items: center;
    .logo {
      margin-right: 2.5rem;
      @media (max-width: ${SizeScreenTabletMd}px) {
        margin-right: unset;
      }
    }
  `,
  actionMenuWrapper: (isAuthenticated?: boolean) => css`
    width: unset;
    align-items: center;
    > :not(:last-child) {
      margin-right: ${isAuthenticated ? '1rem' : '2rem'};
      @media (max-width: ${breakpoint.navbarBurger}px) {
        margin-right: 1rem;
      }
    }
  `,
  actionButton: (isAuthenticated?: boolean) => css`
    width: unset;
    align-items: center;
    border-left: ${isAuthenticated && '1px solid #D5E0E8'};
    padding-left: ${isAuthenticated && '1rem'};
    @media (max-width: ${breakpoint.navbarBurger}px) {
      border-left: none;
      padding-left: 0;
    }
    > :not(:last-child) {
      margin-right: 0.5rem;
    }
  `,
};

export default NavigationBar;
