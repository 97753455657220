import { TransactionDirection } from './../../landing/src/api-hooks/transaction/transaction.model';
import {
  AnalyticPagesNames,
  BankName,
  BlockingPageType,
  DownloadInvoiceType,
  DownloadType,
  PageDownloadInvoice,
  ShareTrackingMethod,
  TransactionTrackSource,
} from './analytics.model';
import { IdentifierType, VerificationMedia } from './auth.model';
import { EFoundUsSocialMedia, IdType } from './profile.model';
import { PageAccessRefundRequest } from './refund-request.model';
import {
  FailedAddToCartError,
  HowToMakePaymentVaType,
  ListActionType,
  PaymentMethod,
  TransferBy,
} from './transaction.model';
import {
  PaymentStatus,
  TransactionRecipientType,
  TransactionType,
} from '../api-hooks/transaction';

enum Variables {
  BANK = 'bank',
  BATCH_ID = 'batch_id',
  CHANGE_FIELD = 'change_field',
  COUNTRY = 'country',
  CURRENCY = 'currency',
  CURRENT_STEP = 'current_step',
  DATE_AFTER = 'date_after',
  DATE_BEFORE = 'date_before',
  DATE = 'date',
  DESTINATION = 'destination',
  DIRECTION = 'direction',
  DOWNLOAD_TYPE = 'download_type',
  ERROR = 'error',
  ID = 'id',
  IDENTIFIER_TYPE = 'identifier_type',
  ITEM_ID = 'item_id',
  ITEM_NAME = 'item_name',
  ITEM_TYPE = 'item_type',
  MAX_STEP = 'max_step',
  MEMBER_ID = 'member_id',
  METHOD = 'method',
  NAME = 'name',
  NOTE = 'note',
  NOTIFY = 'notify',
  PAGE_LOCATION = 'page_location',
  PAGE_PATH = 'page_path',
  PAGE_TITLE = 'page_title',
  PHONE_NUMBER = 'phone_number',
  PREV_BANK = 'prev_bank',
  PREV_COUNTRY = 'prev_country',
  PREV_CURRENCY = 'prev_currency',
  PREV_ITEM_NAME = 'prev_item_name',
  PREV_NOTIFY = 'prev_notify',
  PREV_SERVICE = 'prev_service',
  PREV_SOURCE = 'prev_source',
  PREV_STEP = 'prev_step',
  REF_ID = 'ref_id',
  REFERRAL = 'referral',
  SECTION = 'section',
  SERVICE = 'service',
  SOCMED = 'socmed',
  SOURCE = 'source',
  STATUS = 'status',
  TAB = 'tab',
  TOTAL_STEP = 'total_step',
  TOPUP_ID = 'topup_id',
  TRANSACTION_ID = 'transaction_id',
  TRANSACTION_STATUS = 'transaction_status',
  TRANSACTION_SERVICE = 'transaction_service',
  TYPE = 'type',
  USER_ID = 'user_id',
  VALUE = 'value',
  ERROR_IN = 'error_in',
  ERROR_VALUE = 'error_value',
}

export enum LandingTriggerNames {
  //PAGE VIEW
  PAGE_VIEW = 'page_view',

  // ABAD
  ABAD_CHOOSE_OTHER_BANK = 'choose_other_bank',
  ABAD_CHECK_ACCOUNT = 'check_account',
  ABAD_SUBMIT_FAILED = 'submit_kyc_abad_failed',

  // ACTIVITY
  ACTIVITY_FILTER_LIST = 'filter_activity_list',

  // AFFILIATE
  AFFILIATE_INVITE = 'affiliate_invite',
  AFFILIATE_BALANCE_FILTER = 'affiliate_balance_statement_filter',
  AFFILIATE_CONTACT_US = 'affiliate_contact_us',
  AFFILIATE_JOIN = 'join_affiliate',

  // KYC'
  SIGN_UP = 'sign_up', // complete_registration
  KYC_CANT_FIND_ADDRESS = 'kyc_cant_find_address',
  KYC_PHOTO_RETAKE = 'kyc_retake_photo',
  KYC_QR_CODE = 'kyc_qr_code',
  KYC_CHOOSE_ID_TYPE = 'kyc_choose_id_type',

  KYC_VIEW_DISCLAIMER = 'view_kyc_disclaimer',
  KYC_VIEW_ADDRESS = 'view_kyc_address',
  KYC_VIEW_IDENTITY = 'view_kyc_identity',
  KYC_VIEW_PROFILE = 'view_kyc_profile',
  KYC_VIEW_SELFIE = 'view_kyc_selfie',

  KYC_SUBMIT_FAILED = 'kyc_submit_failed',

  SUBMIT_KYC_ABAD = 'submit_kyc_abad',

  // LOGIN
  LOGIN = 'login',
  LOGIN_SUBMIT_FAILED = 'login_submit_failed',
  RESET_PASSWORD = 'reset_password',

  // PROFILE
  FIND_US = 'find_us',

  // REGISTER
  REGISTER = 'register',
  REGISTER_OTP = 'register_otp',
  REGISTER_FAILED = 'register_failed',
  REGISTER_REFERRAL = 'register_referral',
  REGISTER_VERIFICATION = 'register_verification',
  REGISTER_CHANGED_NUMBER = 'register_changed_number',
  REGISTER_DOMESTIC = 'register_domestic',

  // TRANSACTION
  VIEW_COUNTRY_CURRENCY = 'view_country_currency',
  ADD_QUOTATION_DETAIL = 'add_quotation_detail',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_TO_CART = 'add_to_cart',
  ADD_TO_CART_FAILED = 'failed_add_to_cart',
  CONFIRM_PAYMENT = 'purchase',
  TRANSACTION_TRACK = 'transaction_track',
  SHARE_TRACKING = 'share_tracking',
  TRANSACTION_TRACKER_REQUEST = 'transaction_tracker_request',
  BEGIN_CHECKOUT = 'begin_checkout',
  VIEW_ITEM = 'view_item',
  CANCEL_PURCHASE = 'cancel_purchase',
  CHECK_PAYMENT_STATUS = 'check_payment_status',
  BACK_BUTTON = 'back_button',
  INFO_PAYMENT = 'info',
  ADD_TO_CART_DOMESTIC = 'add_to_cart_domestic',

  // TRANSACTION MULTIPLE
  VIEW_COUNTRY_CURRENCY_MULTIPLE = 'view_country_currency_multiple',
  ADD_PAYMENT_INFO_MULTIPLE = 'add_payment_info_multiple',
  ADD_TO_CART_MULTIPLE = 'add_to_cart_multiple',
  ADD_TO_CART_FAILED_MULTIPLE = 'failed_add_to_cart_multiple',
  PURCHASE_MULTIPLE = 'purchase_multiple',
  BEGIN_CHECKOUT_MULTIPLE = 'begin_checkout_multiple',
  VIEW_ITEM_MULTIPLE = 'view_item_multiple',
  CANCEL_PURCHASE_MULTIPLE = 'cancel_purchase_multiple',

  // REFUND
  REFUND = 'refund',

  //REQUEST REFUND
  REQUEST_REFUND = 'request_refund',

  // SINGLE DOMESTIC
  PAGE_VIEW_DOMESTIC = 'page_view_domestic',
  PURCHASE_DOMESTIC_SINGLE = 'purchase_domestic_single',
  ADD_TO_CART_DOMESTIC_SINGLE = 'add_to_cart_domestic_single',
  BEGIN_CHECKOUT_DOMESTIC_SINGLE = 'begin_checkout_domestic_single',
  ADD_PAYMENT_INFO_DOMESTIC_SINGLE = 'add_payment_info_domestic_single',

  //MULTIPLE DOMESTIC
  PURCHASE_DOMESTIC_MULTIPLE = 'purchase_domestic_multiple',
  ADD_TO_CART_DOMESTIC_MULTIPLE = 'add_to_cart_domestic_multiple',
  BEGIN_CHECKOUT_DOMESTIC_MULTIPLE = 'begin_checkout_domestic_multiple',
  ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE = 'add_payment_info_domestic_multiple',

  //RATE ALERT
  RATEALERT = 'ratealert',
  RATEALERT_CREATED = 'ratealert_created',
  RATEALERT_FAILED = 'ratealert_failed',
  RATEALERT_EDIT = 'ratealert_edit',
  RATEALERT_DELETE = 'ratealert_delete',
  RATEALERT_OFF = 'ratealert_off',

  // TR COINS
  TRCOINS_HISTORY = 'trcoins_history',
  TRCOINS_WITHDRAW = 'trcoins_withdraw',

  // VIEW
  HOMEPAGE_SECTION = 'homepage_section',
  NOTIFICATION = 'notification',
  BLOCKING = 'view_blocking',

  // PRODUCT
  COACHMARK = 'coachmark',
  ANNOUNCEMENT_SECTION = 'announcement_section',
  CTA_BUTTON = 'cta_button',

  DOWNLOAD_INVOICE = 'download_invoice',
}

export interface LandingTriggerPayloads
  extends AbadTagPayloads,
    ActivityTagPayloads,
    AffiliateTagPayloads,
    KycTagPayloads,
    LoginTagPayloads,
    ProductTagPayloads,
    ProfileTagPayloads,
    RateAlertTagPayloads,
    RefundsTagPayloads,
    RegisterTagPayloads,
    TransactionTagPayloads,
    TrCoinsTagPayloads,
    ViewTagPayloads,
    PageviewTagPayloads {}

interface PageviewTagPayloads {
  [LandingTriggerNames.PAGE_VIEW]: {
    page_title: string;
    page_location: string;
    page_path: string;
  };
}

interface AbadTagPayloads {
  [LandingTriggerNames.ABAD_CHOOSE_OTHER_BANK]: {
    [Variables.BANK]: string;
  };
  [LandingTriggerNames.ABAD_CHECK_ACCOUNT]: {
    [Variables.ERROR]: string;
  };
  [LandingTriggerNames.ABAD_SUBMIT_FAILED]: {
    [Variables.ERROR]: string;
  };
}
interface ActivityTagPayloads {
  [LandingTriggerNames.ACTIVITY_FILTER_LIST]: {
    [Variables.VALUE]: string;
    [Variables.TRANSACTION_SERVICE]: string;
    [Variables.STATUS]: string;
    [Variables.TAB]: string;
    [Variables.CHANGE_FIELD]: string;
    [Variables.TYPE]: string;
    [Variables.DATE_BEFORE]: string;
    [Variables.DATE_AFTER]: string;
  };
}
interface AffiliateTagPayloads {
  [LandingTriggerNames.AFFILIATE_INVITE]: { [Variables.METHOD]: string };
  [LandingTriggerNames.AFFILIATE_BALANCE_FILTER]: {
    [Variables.TYPE]: string;
    [Variables.DATE_BEFORE]: string;
    [Variables.DATE_AFTER]: string;
  };
}
interface KycTagPayloads {
  [LandingTriggerNames.SIGN_UP]: never;
  [LandingTriggerNames.KYC_SUBMIT_FAILED]: {
    [Variables.ERROR]: string;
    [Variables.SOURCE]: string;
  };
  [LandingTriggerNames.KYC_PHOTO_RETAKE]: {
    [Variables.TYPE]: 'IDENTITY_PHOTO' | 'SELFIE';
  };
  [LandingTriggerNames.SUBMIT_KYC_ABAD]: never;
  [LandingTriggerNames.KYC_QR_CODE]: never;
  [LandingTriggerNames.KYC_CANT_FIND_ADDRESS]: never;
  [LandingTriggerNames.KYC_CHOOSE_ID_TYPE]: {
    [Variables.TYPE]: IdType;
  };
  [LandingTriggerNames.KYC_VIEW_DISCLAIMER]: never;
  [LandingTriggerNames.KYC_VIEW_ADDRESS]: never;
  [LandingTriggerNames.KYC_VIEW_IDENTITY]: never;
  [LandingTriggerNames.KYC_VIEW_PROFILE]: never;
  [LandingTriggerNames.KYC_VIEW_SELFIE]: never;
}
interface LoginTagPayloads {
  [LandingTriggerNames.LOGIN]: {
    [Variables.METHOD]: string;
  };
  [LandingTriggerNames.LOGIN_SUBMIT_FAILED]: {
    [Variables.ERROR]: string;
    [Variables.SOURCE]: 'ON_SUBMIT_LOGIN_SCREEN';
    [Variables.METHOD]: IdentifierType | 'unsupported';
  };
  [LandingTriggerNames.RESET_PASSWORD]: {
    [Variables.ERROR]?: string;
  };
}
interface ProfileTagPayloads {
  [LandingTriggerNames.FIND_US]: {
    [Variables.SOURCE]: AnalyticPagesNames.FOOTER | AnalyticPagesNames.PROFILE;
    [Variables.SOCMED]: EFoundUsSocialMedia;
  };
}
interface RegisterTagPayloads {
  [LandingTriggerNames.REGISTER]: {
    [Variables.MEMBER_ID]: string;
    [Variables.USER_ID]: string;
    [Variables.PHONE_NUMBER]: string;
  };
  [LandingTriggerNames.REGISTER_OTP]: {
    [Variables.STATUS]: 'success' | 'failed' | 'resend';
    [Variables.USER_ID]: string;
  };
  [LandingTriggerNames.REGISTER_FAILED]: {
    [Variables.ERROR]: string;
  };
  [LandingTriggerNames.REGISTER_REFERRAL]: {
    [Variables.REFERRAL]: string;
  };
  [LandingTriggerNames.REGISTER_CHANGED_NUMBER]: {
    [Variables.ERROR]: string;
  };
  [LandingTriggerNames.REGISTER_VERIFICATION]: {
    [Variables.METHOD]: VerificationMedia;
    [Variables.SOURCE]: AnalyticPagesNames;
  };
}
interface TransactionTagPayloads {
  [LandingTriggerNames.VIEW_COUNTRY_CURRENCY]: {
    [Variables.CURRENCY]: string;
    [Variables.COUNTRY]: string;
    [Variables.PREV_COUNTRY]?: string;
    [Variables.PREV_CURRENCY]?: string;
  };
  [LandingTriggerNames.VIEW_COUNTRY_CURRENCY_MULTIPLE]: {
    [Variables.CURRENCY]: string;
    [Variables.COUNTRY]: string;
    [Variables.PREV_COUNTRY]?: string;
    [Variables.PREV_CURRENCY]?: string;
  };
  [LandingTriggerNames.ADD_QUOTATION_DETAIL]: {
    [Variables.REF_ID]?: string;
    [Variables.SECTION]: 'service' | 'recipient' | 'phonenumber';
    [Variables.ERROR]: 'service' | 'recipient' | 'phonenumber' | '';
    [Variables.ITEM_TYPE]: TransactionType;
  };
  [LandingTriggerNames.ADD_PAYMENT_INFO]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: BankName;
  };
  [LandingTriggerNames.ADD_PAYMENT_INFO_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: BankName;
  };
  [LandingTriggerNames.ADD_TO_CART]: {
    [Variables.REF_ID]: string;
  };
  [LandingTriggerNames.ADD_TO_CART_MULTIPLE]: {
    [Variables.REF_ID]: string;
  };
  [LandingTriggerNames.CONFIRM_PAYMENT]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };
  [LandingTriggerNames.PURCHASE_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };
  [LandingTriggerNames.TRANSACTION_TRACKER_REQUEST]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TRANSACTION_STATUS]?: string;
    [Variables.TYPE]?: TransactionDirection;
    [Variables.STATUS]: 'SUCCESS' | 'ERROR';
    [Variables.ERROR]?: 'VERIFICATION' | 'TRANSACTION';
    [Variables.ERROR_VALUE]?: string;
  };
  [LandingTriggerNames.BEGIN_CHECKOUT]: {
    [Variables.TRANSACTION_ID]: string | number;
    [Variables.TYPE]: TransactionType;
  };
  [LandingTriggerNames.BEGIN_CHECKOUT_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string | number;
    [Variables.TYPE]: TransactionType;
  };
  [LandingTriggerNames.CANCEL_PURCHASE]: {
    [Variables.SOURCE]:
      | AnalyticPagesNames.TRANSACTION_DETAIL
      | AnalyticPagesNames.TRANSACTION_MAKE_PAYMENT;
  };
  [LandingTriggerNames.CANCEL_PURCHASE_MULTIPLE]: {
    [Variables.SOURCE]:
      | AnalyticPagesNames.TRANSACTION_DETAIL
      | AnalyticPagesNames.TRANSACTION_MAKE_PAYMENT;
  };
  [LandingTriggerNames.ADD_TO_CART_FAILED]: {
    [Variables.REF_ID]?: string;
    [Variables.ERROR]: FailedAddToCartError | string;
    [Variables.TYPE]: TransactionRecipientType;
    [Variables.ITEM_TYPE]: TransactionType;
  };
  [LandingTriggerNames.ADD_TO_CART_FAILED_MULTIPLE]: {
    [Variables.REF_ID]?: string;
    [Variables.ERROR]: FailedAddToCartError | string;
    [Variables.TYPE]: TransactionRecipientType;
    [Variables.ITEM_TYPE]: TransactionType;
  };
  [LandingTriggerNames.VIEW_ITEM]: {
    [Variables.ITEM_ID]: string;
    [Variables.ITEM_NAME]: string;
    [Variables.ITEM_TYPE]: TransactionType;
    [Variables.PREV_ITEM_NAME]?: string;
  };
  [LandingTriggerNames.VIEW_ITEM_MULTIPLE]: {
    [Variables.ITEM_ID]: string;
    [Variables.ITEM_NAME]: string;
    [Variables.ITEM_TYPE]: TransactionType;
    [Variables.PREV_ITEM_NAME]?: string;
  };
  [LandingTriggerNames.REFUND]: never;
  [LandingTriggerNames.CHECK_PAYMENT_STATUS]: {
    [Variables.METHOD]: PaymentMethod;
    [Variables.STATUS]: PaymentStatus;
  };
  [LandingTriggerNames.BACK_BUTTON]: {
    [Variables.SOURCE]: string;
    [Variables.DESTINATION]: string;
  };

  [LandingTriggerNames.PAGE_VIEW_DOMESTIC]: {
    [Variables.SOURCE]: string;
    [Variables.PREV_SOURCE]?: string;
  };
  [LandingTriggerNames.PURCHASE_DOMESTIC_SINGLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };
  [LandingTriggerNames.ADD_TO_CART_DOMESTIC_SINGLE]: {
    [Variables.REF_ID]: string;
  };
  [LandingTriggerNames.BEGIN_CHECKOUT_DOMESTIC_SINGLE]: {
    [Variables.TRANSACTION_ID]: string;
  };
  [LandingTriggerNames.ADD_PAYMENT_INFO_DOMESTIC_SINGLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType.DOMESTIC;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: string;
  };
  [LandingTriggerNames.ADD_TO_CART_DOMESTIC_MULTIPLE]: {
    [Variables.REF_ID]: string;
  };
  [LandingTriggerNames.BEGIN_CHECKOUT_DOMESTIC_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
  };
  [LandingTriggerNames.ADD_PAYMENT_INFO_DOMESTIC_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.TYPE]: TransactionType.DOMESTIC;
    [Variables.METHOD]: PaymentMethod;
    [Variables.BANK]: string;
  };
  [LandingTriggerNames.PURCHASE_DOMESTIC_MULTIPLE]: {
    [Variables.TRANSACTION_ID]: string;
    [Variables.VALUE]: number;
    [Variables.CURRENCY]: string;
  };
  [LandingTriggerNames.ADD_TO_CART_DOMESTIC]: {
    bank: string;
    prev_bank?: string;
    service: 'Instant Transfer' | 'Bank Transfer';
    amount: number;
    note?: string;
  };
  [LandingTriggerNames.DOWNLOAD_INVOICE]: {
    [Variables.BATCH_ID]?: string;
    [Variables.TOPUP_ID]?: string;
    [Variables.SOURCE]: PageDownloadInvoice;
    // Note: Type is for download in what page.
    [Variables.TYPE]: DownloadInvoiceType;
    // Note: Download type is consist of invoice or receipt.
    [Variables.DOWNLOAD_TYPE]: DownloadType;
    [Variables.STATUS]: string;
  };
  [LandingTriggerNames.TRANSACTION_TRACK]: {
    [Variables.REF_ID]: string;
    [Variables.STATUS]: string;
    [Variables.SOURCE]: TransactionTrackSource;
  };
  [LandingTriggerNames.SHARE_TRACKING]: {
    [Variables.REF_ID]: string;
    [Variables.TRANSACTION_STATUS]: string;
    [Variables.METHOD]: ShareTrackingMethod;
  };
}
interface TrCoinsTagPayloads {
  [LandingTriggerNames.TRCOINS_WITHDRAW]: {
    [Variables.SOURCE]:
      | AnalyticPagesNames.HOME
      | AnalyticPagesNames.TR_COINS_HISTORY;
  };
  [LandingTriggerNames.TRCOINS_HISTORY]: never;
}
interface ViewTagPayloads {
  [LandingTriggerNames.HOMEPAGE_SECTION]: {
    [Variables.NAME]: string;
    [Variables.SECTION]:
      | 'featured_menu'
      | 'promo_banner'
      | 'explore_banner'
      | 'article_banner';
    [Variables.ID]?: string;
  };
  [LandingTriggerNames.NOTIFICATION]: {
    [Variables.ID]: string;
  };
  [LandingTriggerNames.BLOCKING]: {
    [Variables.SECTION]: 'kyc';
    [Variables.NAME]:
      | 'blocking_ocr'
      | 'need_abad'
      | 'need_kyc'
      | 'kyc_verification_rejected'
      | 'kyc_blacklist'
      | 'kyc_compliance_rejected'
      | 'abad_verification_rejected';
    [Variables.SOURCE]: BlockingPageType;
  };
}
interface ProductTagPayloads {
  [LandingTriggerNames.COACHMARK]: {
    [Variables.NAME]:
      | AnalyticPagesNames.HOME
      | AnalyticPagesNames.TR_COINS
      | AnalyticPagesNames.AIRTIME;
    [Variables.CURRENT_STEP]: number;
    [Variables.TOTAL_STEP]: number;
    [Variables.MAX_STEP]: number;
    [Variables.PREV_STEP]: number;
  };
  [LandingTriggerNames.ANNOUNCEMENT_SECTION]: {
    [Variables.NAME]:
      | 'KYC rejected'
      | 'KYC verify account'
      | 'ABAD rejected'
      | 'ABAD verify'
      | 'email verification'
      | 'blacklist'
      | 'blacklist reverify';
    [Variables.SOURCE]: AnalyticPagesNames.PROFILE | AnalyticPagesNames.HOME;
  };
  [LandingTriggerNames.INFO_PAYMENT]: {
    [Variables.TYPE]: ListActionType;
    [Variables.SOURCE]: string;
    [Variables.SECTION]: HowToMakePaymentVaType;
    [Variables.VALUE]: TransferBy;
  };
  [LandingTriggerNames.CTA_BUTTON]: {
    [Variables.SOURCE]: AnalyticPagesNames.LANDING_PAGE_DOMESTIC;
    [Variables.TYPE]: 'Remit - Domestic' | 'Remit - Outbound' | 'Airtime';
    [Variables.NAME]:
      | 'RegisterNow'
      | 'SeeAllBank'
      | 'TransferNow'
      | 'LearnMore';
  };
}

interface RefundsTagPayloads {
  [LandingTriggerNames.REQUEST_REFUND]: {
    [Variables.SOURCE]: PageAccessRefundRequest;
    [Variables.TYPE]: 'SINGLE' | 'MULTIPLE';
    [Variables.ID]: string;
  };
}

interface RateAlertTagPayloads {
  [LandingTriggerNames.RATEALERT]: {
    [Variables.SOURCE]: AnalyticPagesNames.CALCULATOR | AnalyticPagesNames.HOME;
  };
  [LandingTriggerNames.RATEALERT_CREATED]: {
    [Variables.COUNTRY]: string;
    [Variables.CURRENCY]: string;
    [Variables.SERVICE]: string;
    [Variables.NOTIFY]: string;
    [Variables.SOURCE]: AnalyticPagesNames.CALCULATOR | AnalyticPagesNames.HOME;
  };
  [LandingTriggerNames.RATEALERT_FAILED]: {
    [Variables.SOURCE]: AnalyticPagesNames.CALCULATOR | AnalyticPagesNames.HOME;
    [Variables.ERROR]: string;
  };
  [LandingTriggerNames.RATEALERT_EDIT]: {
    [Variables.TYPE]: string;
    [Variables.PREV_COUNTRY]: string;
    [Variables.PREV_CURRENCY]: string;
    [Variables.COUNTRY]: string;
    [Variables.CURRENCY]: string;
    [Variables.PREV_SERVICE]: string;
    [Variables.SERVICE]: string;
    [Variables.PREV_NOTIFY]: string;
    [Variables.NOTIFY]: string;
  };
  [LandingTriggerNames.RATEALERT_DELETE]: {
    [Variables.VALUE]: string;
  };
  [LandingTriggerNames.RATEALERT_OFF]: {
    [Variables.COUNTRY]: string;
    [Variables.CURRENCY]: string;
    [Variables.SERVICE]: string;
    [Variables.NOTIFY]: string;
  };
}
