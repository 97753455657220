import { queryClient, requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import useS2SAnalytic from '@hooks/use-analytic-identify';
import { useCurrentAccountStore } from '@stores';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  BankNameModel,
  Continents,
  ContinentsModel,
  CountryModel,
  ServiceId,
  UploadURLResponse,
} from '@topremit/shared-web/api-hooks/common';
import { TransactionType } from '@topremit/shared-web/api-hooks/transaction';
import { callAllFunctions } from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { UploadFile } from 'antd/es/upload';
import produce from 'immer';
import { useRouter } from 'next/router';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQueries,
  UseQueryOptions,
} from 'react-query';

import {
  Account,
  AccountInfo,
  AccountInfoBusiness,
  Affiliate,
  AffiliateCommission,
  AirasiaPoint,
  AnnouncementMeta,
  ApiNotificationWithMeta,
  BankAccountDetail,
  BankAccountVerified,
  Banner,
  CompanyProfile,
  DirectionType,
  DiscoverySource,
  FeedbackList,
  LandingMeta,
  LandingPageInfoModel,
  NotificationGroup,
  NotificationItem,
  OptionModel,
  Referree,
  RewardsEarned,
  SendMoney,
  ShowFeedback,
  TokenInfo,
  TransactionReview,
  UserBusiness,
  UserMeta,
  UserPersonal,
  VerificationConfigModel,
} from './common.model';

export const defaultQueryResult = {
  data: undefined,
  isLoading: false,
  isFetching: false,
  error: undefined,
  status: 'idle',
  isSuccess: false,
  isError: false,
  dataUpdatedAt: 0,
  errorUpdatedAt: 0,
  failureCount: 0,
  isStale: false,
  refetch: () => {},
  remove: () => {},
};

export function useGetMeta(
  params?: {
    type?: 'swift' | 'remit';
    serviceId?: ServiceId;
    currency?: string;
  },
  options?: UseQueryOptions<ApiResult<LandingMeta>, ApiError>,
) {
  const { type = 'remit', serviceId, currency } = params || {};
  return useCustomQuery<LandingMeta>(
    ['meta-landing', 'meta', type],
    async () =>
      await requestFn(
        { path: 'meta', method: 'get' },
        {
          searchParams: {
            ...(serviceId && { serviceId }),
            ...(currency && { currency }),
          },
        },
      ),
    { ...options },
  );
}

export interface UseGetCountriesParams {
  available?: boolean;
  type?: TransactionType;
  priorityCountry?: string;
}

export function useGetCountries(
  { available, type, priorityCountry }: UseGetCountriesParams = {},
  options?: UseQueryOptions<ApiResult<CountryModel[]>, ApiError>,
) {
  return useCustomQuery<CountryModel[]>(
    ['countries', { available, type, priorityCountry }],
    async () =>
      await requestFn(
        { path: 'countries', method: 'get' },
        {
          searchParams: {
            ...(available && { available }),
            ...(type && { type }),
            ...(priorityCountry && { priorityCountry }),
          },
        },
      ),
    { ...options },
  );
}

export function useGetCountry(
  country?: string,
  options?: UseQueryOptions<ApiResult<CountryModel>, ApiError>,
) {
  const isIsoCode = country?.length === 3;

  return useCustomQuery<CountryModel>(
    ['countries', country],
    () =>
      requestFn(
        { path: `countries/${country}`, method: 'get' },
        {
          searchParams: {
            available: true,
            ...(!isIsoCode && { isSlug: true }),
          },
        },
      ),
    { ...options },
  );
}

export function useGetAirasiaPoints(
  options?: UseQueryOptions<ApiResult<AirasiaPoint[]>, ApiError>,
) {
  return useCustomQuery<AirasiaPoint[]>(
    ['airasia-points'],
    async () => await requestFn({ path: 'airasia/points', method: 'get' }),
    { ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetContinents(
  continent?: Continents | string | null,
  options?: UseQueryOptions<ApiResult<CountryModel[]>, ApiError>,
) {
  return useCustomQuery<CountryModel[]>(
    ['continents', continent],
    async () =>
      await requestFn(
        { path: 'all-countries', method: 'get' },
        {
          searchParams: {
            ...(continent &&
              continent !== Continents.ALL_REGION && { continent }),
            filter: true,
          },
        },
      ),
    { ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetAllContinents(
  options?: UseQueryOptions<ApiResult<ContinentsModel>, ApiError>,
) {
  return useCustomQuery<ContinentsModel>(
    ['continents-landing'],
    async () =>
      await requestFn(
        { path: 'all-countries-landing-page', method: 'get' },
        {
          searchParams: {
            filter: true,
          },
        },
      ),
    { ...options },
  );
}

export function useGetSendMoneyPageData(
  countrySlug?: string,
  options?: UseQueryOptions<ApiResult<SendMoney>, ApiError>,
) {
  return useCustomQuery<SendMoney>(
    ['send-money', countrySlug],
    async () =>
      await requestFn(
        { path: 'send-money', method: 'get' },
        {
          ...(countrySlug && {
            searchParams: {
              country: countrySlug,
            },
          }),
        },
      ),
    { ...options },
  );
}

export function useGetAnnouncement(
  countrySlug?: string,
  options?: UseQueryOptions<ApiResult<AnnouncementMeta[]>>,
) {
  return useCustomQuery<AnnouncementMeta[]>(
    ['announcements', countrySlug],
    async () =>
      await requestFn(
        { path: 'announcements', method: 'get' },
        {
          ...(countrySlug && {
            searchParams: {
              country: countrySlug,
            },
          }),
        },
      ),
    {
      ...options,
    },
  );
}

export function useGetMe<T extends UserPersonal | UserBusiness = UserPersonal>(
  options?: UseQueryOptions<ApiResult<T>, ApiError>,
) {
  const { onSuccess, ...restOptions } = options || {};
  const { initIdentifiers } = useS2SAnalytic();
  const { currentAccount } = useCurrentAccountStore.getState() || {};

  return useCustomQuery<T>(
    ['me'],
    async () =>
      await requestFn({
        path: 'members/me',
        method: 'get',
      }),
    {
      enabled: !!currentAccount,
      onSuccess: (rsp) => {
        const { memberId } = rsp?.data || {};
        initIdentifiers({ memberId });
        onSuccess?.(rsp);
      },
      ...restOptions,
    },
    {
      hasAccountId: true,
    },
  );
}

export function useGetUserMeta(
  options?: UseQueryOptions<ApiResult<UserMeta>, ApiError>,
) {
  const { currentAccount } = useCurrentAccountStore.getState() || {};

  return useCustomQuery<UserMeta>(
    ['user-meta'],
    async () => await requestFn({ path: 'user/meta', method: 'get' }),
    {
      enabled: !!currentAccount,
      /** Prevent unused fetching
       * Uncomment if need to automatic show notif badge
       */
      // staleTime: 1 * 60 * 1000, // 1 minutes in milliseconds
      // refetchInterval: 1 * 60 * 1000, // 1 minutes in milliseconds
      ...options,
    },
    {
      hasAccountId: true,
    },
  );
}

export function useGetAccountInfo<
  T extends AccountInfo | AccountInfoBusiness = AccountInfo,
>(options?: UseQueryOptions<ApiResult<T>, ApiError>) {
  const router = useRouter();

  const { currentAccount, setCurrentAccount } =
    useCurrentAccountStore.getState() || {};
  const removeCurrentAccount = useCurrentAccountStore(
    (store) => store.removeCurrentAccount,
  );

  return useCustomQuery<T>(
    ['account-info'],
    async () =>
      await requestFn({
        path: 'account-info',
        method: 'get',
      }),
    {
      onSuccess: ({ data }) => {
        if (!data?.accounts) {
          return;
        }

        if (isNativeApp()) {
          const activeAccount = data.accounts.find(
            (account) =>
              account.id === localStorage.getItem('currentAccountId'),
          ) as Account;
          setCurrentAccount(activeAccount);
          return;
        }

        if (currentAccount) {
          const activeAccount = data.accounts.find(
            (account) => account.id === currentAccount.id,
          );

          if (!activeAccount) {
            removeCurrentAccount();
            router.push('/choose-account');
            return;
          }

          setCurrentAccount({
            ...activeAccount,
            /**
             * Inject kycStatus to current account
             * only available for business account
             *
             * using object indexed access to prevent typescirpt error
             * because kycStatus is not available in personal account
             */
            kycStatus: data['kycStatus'],
            entityType: data['entityType'],
          });
        }
      },
      ...options,
    },
    {
      hasLang: false,
      hasAccountId: true,
    },
  );
}

export function useGetAccountPermissions(
  options?: UseQueryOptions<ApiResult<string[]>, ApiError>,
) {
  return useCustomQuery<string[]>(
    ['permissions'],
    async () => await requestFn({ path: 'members/permissions', method: 'get' }),
    {
      ...options,
      enabled: false,
    },
    {
      hasLang: false,
      hasAccountId: true,
    },
  );
}

export function useGetTokenInfo(
  token: string,
  options?: UseQueryOptions<ApiResult<TokenInfo>, ApiError>,
) {
  return useCustomQuery<TokenInfo>(
    ['token-info', token],
    async () =>
      await requestFn({
        path: `token-info/${token}`,
        method: 'get',
      }),
    { enabled: false, ...options },
    {
      hasLang: false,
      hasAccountId: false,
    },
  );
}

export function useGetUploadFileUrl(
  files: UploadFile[],
  options?: UseQueryOptions<ApiResult<UploadURLResponse>, ApiError>,
) {
  return useQueries(
    files?.map((file) => ({
      queryKey: ['upload-url', file.uid, { contentType: file.type }],
      queryFn: () =>
        requestFn(
          { path: 'upload-url', method: 'get' },
          { searchParams: { content_type: file.type, uid: file.uid } as any },
        ),
      ...options,
    })),
  );
}

// get banks from indoensia
export function useGetAllBank(
  options?: UseQueryOptions<ApiResult<BankNameModel[]>, ApiError>,
) {
  return useCustomQuery<BankNameModel[]>(
    'banks',
    async () => await requestFn({ path: 'bank-payers', method: 'get' }),
    {
      refetchOnMount: true,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

// get banks by country, currency for transaction
export function useGetBanksByCountryParam(
  params: { destinationCountry?: string; destinationCurrency?: string },
  options?: UseQueryOptions<ApiResult<BankNameModel[]>, ApiError>,
) {
  return useCustomQuery<BankNameModel[]>(
    ['payers', params.destinationCountry, params.destinationCurrency],
    () =>
      requestFn(
        {
          path: 'payers',
          method: 'get',
        },
        { searchParams: { serviceId: 2, ...params } },
      ),
    { ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetCommission(
  options?: UseQueryOptions<ApiResult<AffiliateCommission>, ApiError>,
) {
  return useCustomQuery<AffiliateCommission>(
    ['affiliate-commission-value'],
    async () => await requestFn({ path: 'affiliate-info', method: 'get' }),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetAffiliate(
  options?: UseQueryOptions<ApiResult<Affiliate>, ApiError>,
) {
  const { data: me } = useGetMe();
  return useCustomQuery<Affiliate>(
    ['affiliate'],
    async () => await requestFn({ path: 'me/affiliate', method: 'get' }),
    {
      enabled: me?.data.isAffiliate,
      ...options,
    },
  );
}

export function useGetReferrees(
  options?: UseQueryOptions<ApiResult<Referree[]>, ApiError>,
) {
  return useCustomQuery<Referree[]>(
    ['referrees'],
    async () => await requestFn({ path: 'me/referrees', method: 'get' }),
    {
      ...options,
    },
  );
}

export function useGetRewardsEarned(
  options?: UseQueryOptions<ApiResult<RewardsEarned>, ApiError>,
) {
  return useCustomQuery<RewardsEarned>(
    ['rewards-earned'],
    async () => await requestFn({ path: 'me/rewards-earned', method: 'get' }),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetServicesFilter(
  options?: UseQueryOptions<ApiResult<OptionModel[]>, ApiError>,
) {
  return useCustomQuery<OptionModel[]>(
    ['services-filter'],
    () => requestFn({ path: 'services/filter', method: 'get' }),
    { ...options },
  );
}

export function useGetFeedbackMenu(
  options?: UseQueryOptions<ApiResult<ShowFeedback>, ApiError>,
) {
  return useCustomQuery<ShowFeedback>(
    ['feedback'],
    async () =>
      await requestFn({ path: `me/show-request-form`, method: 'get' }),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetDiscoverySource(
  options?: UseQueryOptions<ApiResult<DiscoverySource[]>, ApiError>,
) {
  return useCustomQuery<DiscoverySource[]>(
    ['discovery-source'],
    async () =>
      await requestFn({ path: 'affiliates/discovery-source', method: 'get' }),
    {
      ...options,
    },
  );
}

export function useGetAllCountry(
  priorityCountry?: string,
  options?: UseQueryOptions<ApiResult<CountryModel[]>, ApiError>,
) {
  const { addNotification } = useNotification();
  const { onError, ...resOption } = options || {};
  return useCustomQuery<CountryModel[]>(
    ['countries-all'],
    async () =>
      await requestFn(
        { path: 'countries', method: 'get' },
        { searchParams: { priorityCountry: priorityCountry || '' } },
      ),
    {
      onError: callAllFunctions(onError, ({ message }) => {
        addNotification({ message, type: 'danger' });
      }),
      ...resOption,
    },
  );
}

export function useGetInfiniteNotifications(
  payload: { segment: string },
  options: UseInfiniteQueryOptions<
    ApiNotificationWithMeta<NotificationGroup>,
    ApiError
  > = {},
) {
  const { lang } = useTranslation();
  const { currentAccount } = useCurrentAccountStore.getState() || {};

  return useInfiniteQuery<ApiNotificationWithMeta<NotificationGroup>, ApiError>(
    ['notifications', currentAccount?.id, payload.segment, lang],
    async ({ pageParam: page = 1 }) => {
      return requestFn({
        path: `v2/me/notifications?filter[segment]=${payload.segment}&page=${page}`,
        method: 'get',
      });
    },
    {
      enabled: !!currentAccount,
      staleTime: 1 * 60 * 1000, // 1 minutes in milliseconds
      refetchInterval: 1 * 60 * 1000, // 1 minutes in milliseconds
      getNextPageParam: (param) => {
        const { meta } = param || {};
        return meta?.currentPage < meta?.lastPage
          ? meta?.currentPage + 1
          : false;
      },
      onSuccess: (result) => {
        queryClient.setQueryData<ApiResult<UserMeta> | undefined>(
          ['user-meta', currentAccount?.id, lang],
          (queryData) => {
            if (!queryData) {
              return undefined;
            }

            const newQueryData = produce(queryData, (draft) => {
              const lastPage = result.pages[result.pages.length - 1];
              draft.data.hasUnreadNotification =
                lastPage.meta.hasUnreadTabNotification.needAction ||
                lastPage.meta.hasUnreadTabNotification.information;
            });

            return newQueryData;
          },
        );
      },
      ...options,
    },
  );
}

export function useGetNotificationById(
  payload: { id: string },
  options?: UseQueryOptions<ApiResult<NotificationItem>, ApiError>,
) {
  return useCustomQuery<NotificationItem>(
    ['notification', payload.id],
    async () => {
      return await requestFn({
        path: `v2/me/notifications/${payload.id}`,
        method: 'get',
      });
    },
    { ...options },
  );
}

export function useGetRecentAirasia(
  options?: UseQueryOptions<ApiResult<string[]>, ApiError>,
) {
  return useCustomQuery<string[]>(
    ['airasia-account'],
    async () => await requestFn({ path: 'airasia-account', method: 'get' }),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetBanners(
  type: string,
  options?: UseQueryOptions<ApiResult<Banner[]>, ApiError>,
) {
  return useCustomQuery<Banner[]>(
    ['banners', type],
    async () =>
      await requestFn(
        { path: 'banners', method: 'get' },
        {
          searchParams: {
            type,
          },
        },
      ),
    {
      ...options,
    },
  );
}

export function useGetTransactionReview(
  options?: UseQueryOptions<ApiResult<TransactionReview[]>, ApiError>,
) {
  return useCustomQuery<TransactionReview[]>(
    ['transaction-reviews'],
    async () =>
      await requestFn({ path: 'transactions/reviews', method: 'get' }),
    {
      ...options,
    },
  );
}

export function useGetTransactionListFeedback(
  direction?: DirectionType,
  options?: UseQueryOptions<ApiResult<FeedbackList>, ApiError>,
) {
  return useCustomQuery<FeedbackList>(
    ['transaction-review-reasons', direction],
    async () => {
      const searchParams =
        direction === 'REMIT' || direction === undefined
          ? undefined
          : { direction };

      return await requestFn(
        { path: 'transaction-review-reasons', method: 'get' },
        {
          searchParams,
        },
      );
    },
    {
      ...options,
    },
  );
}

export function useGetBankAccountDetails(
  options?: UseQueryOptions<ApiResult<BankAccountDetail[]>, ApiError>,
) {
  return useCustomQuery<BankAccountDetail[]>(
    ['bank-account'],
    () => requestFn({ path: 'me/bank-account/details', method: 'get' }),
    {
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetVerificationConfigs(
  options?: UseQueryOptions<ApiResult<VerificationConfigModel>, ApiError>,
) {
  return useCustomQuery<VerificationConfigModel>(
    ['verification_config'],
    async () => await requestFn({ path: `configs`, method: 'get' }),
    { ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetCompanyProfile(
  options?: UseQueryOptions<ApiResult<CompanyProfile>, ApiError>,
) {
  return useCustomQuery<CompanyProfile>(
    ['business-profile', options?.queryKey],
    async () => await requestFn({ path: 'business-profile', method: 'get' }),
    { ...options },
    {
      hasLang: false,
      hasAccountId: true,
    },
  );
}

export function useGetLandingPageInfo() {
  return useCustomQuery<LandingPageInfoModel>(
    ['landing-info'],
    async () => await requestFn({ path: 'landing-page/info', method: 'get' }),
    {},
    {
      hasLang: false,
    },
  );
}

export function useGetBankAccountVerified(
  options?: UseQueryOptions<ApiResult<BankAccountVerified>, ApiError>,
) {
  return useCustomQuery<BankAccountVerified>(
    ['bank-account-verified'],
    () => requestFn({ path: 'me/bank-account/verified', method: 'get' }),
    { ...options },
    {
      hasLang: false,
    },
  );
}
