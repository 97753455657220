import { useSyncPaidTrxAnalytic } from '@api-hooks/analytic/analytic.query';
import { Banner } from '@elements';
import { css, cx } from '@emotion/css';
import {
  useDashboardRedirect,
  DashboardLayoutProvider,
  NavigationProvider,
  useRoutes,
  useBanner,
  useAnalytics,
  useDashboardBlocking,
  useDashboardConfig,
} from '@hooks';
import { handleIsMobileConfig } from '@layouts/helper';
import {
  DashboardLayoutConfig,
  IDashboardLayoutProps,
} from '@layouts/layout.typing';
import { LoadingView } from '@modules';
import useDomesticResetValues from '@modules/dashboard/calculator/domestic/hooks/use-domestic-reset-values';
import {
  ObjToCssString,
  setMobileCSSHeightProperty,
} from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { navbarSize, screenSize } from '@topremit/shared-web/common/size';
import { useDebounce, useMediaQuery } from '@topremit/shared-web/hooks';
import { SizeScreenTabletMd } from '@topremit-ui/design-tokens';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import DashboardTopbar from './topbar/DashboardTopbar';

const NO_LOADING_CHANGE_ROUTES = [
  '/wallet/top-up',
  '/wallet/withdraw',
  '/wallet/wallet-history',
  '/wallet?type=top-up',
  '/wallet?type=withdraw',
  '/wallet?type=wallet-history',
];

const { tabletMd } = screenSize;

const Sidebar = dynamic(() => import('./sidebar/DashboardSideBar'));
const BottomBar = dynamic(() => import('./bottombar/DashboardBottomBar'));

export function DashboardLayout({ children, config }: IDashboardLayoutProps) {
  const router = useRouter();
  const { onPageView } = useAnalytics();
  const isMobileApp = isNativeApp();
  const isMobile =
    useMediaQuery(`(max-width:${SizeScreenTabletMd}px)`) || isMobileApp;

  useDomesticResetValues();

  const { hasAccountInfo } = useDashboardRedirect();
  const { routes } = useRoutes();
  const { isShowBanner, bannerMessage, hideBanner, bannerColor } = useBanner();

  const isClient = typeof window !== 'undefined';
  const isNoLoadingChangeRoute = NO_LOADING_CHANGE_ROUTES.includes(
    router.asPath,
  );

  const baseConfig = {
    isHideBottomBar: false,
    isHideSideBar: false,
    isHideTopBar: isMobileApp || false,
    isWhiteBackground: false,
    isGreyBackground: false,
    isHideNotifButton: false,
    isHideScrollBar: false,
    isBannerDealRate: false,
    pageViewType: undefined,
    ...config,
  };

  const [isSwitchingPage, setIsSwitchingPage] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [dashboardConfig, setDashboardConfig] =
    useState<DashboardLayoutConfig>(baseConfig);

  const {
    isHideBottomBar,
    isHideSideBar,
    isHideTopBar,
    isWhiteBackground,
    isGreyBackground,
    isHideScrollBar,
    isBannerDealRate,
    pageViewType,
  } = dashboardConfig || {};
  const stringfyConfig = JSON.stringify(config);
  const shouldHideBottomBar = handleIsMobileConfig(isHideBottomBar, isMobile);
  const shouldHideSideBar = handleIsMobileConfig(isHideSideBar, isMobile);
  const shouldHideTopBar = handleIsMobileConfig(isHideTopBar, isMobile);
  const shouldHideWhiteBackground = handleIsMobileConfig(
    isWhiteBackground,
    isMobile,
  );
  const shouldHideGreyBackground = handleIsMobileConfig(
    isGreyBackground,
    isMobile,
  );
  const shouldHideScrollBar = handleIsMobileConfig(isHideScrollBar, isMobile);
  const shouldHideBannerDealRate = handleIsMobileConfig(
    isBannerDealRate,
    isMobile,
  );

  const isShowSideBar = isClient && !isMobile && !shouldHideSideBar;
  const isShowBottomBar = isClient && isMobile && !shouldHideBottomBar;
  const isShowTopBar = !isMobileApp && !shouldHideTopBar;

  useSyncPaidTrxAnalytic();
  useDashboardBlocking();
  useDashboardConfig(config);

  useDebounce(
    () => {
      setIsPageLoading(isSwitchingPage);
    },
    [isSwitchingPage],
    200,
  );

  useEffect(() => {
    /**
     * Next.js fast refresh will cause events below run,
     * this will trigger the loading state and unmount the page.
     * Which all the state will be reset and will effect the development progress
     * This code will be disabled the loading by using provided env
     */
    if (
      process.env.NODE_ENV === 'development' &&
      process.env.NEXT_PUBLIC_DISABLE_DASHBOARD_LOADING
    ) {
      return;
    }

    function handleStart(url, { shallow }) {
      const beforeLang =
        (window?.location?.pathname || '')?.split('/')?.[1] || 'en';
      const afterLang = (url || '')?.split('/')?.[1] || 'en';

      if (!shallow && beforeLang === afterLang) {
        setIsSwitchingPage(true);
      }
    }

    function handleComplete() {
      setIsSwitchingPage(false);
    }

    function handleError() {
      setIsSwitchingPage(false);
    }

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleError);
    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleError);
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      setMobileCSSHeightProperty();
    }

    // Note: Initial call on mount
    setMobileCSSHeightProperty();

    // Add resize listener
    window.addEventListener('resize', handleResize);

    // Cleanup on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const body = document.querySelector('body');
    body?.setAttribute(
      'style',
      ObjToCssString({
        overflowY: 'hidden',
      }),
    );
    return () => {
      body?.setAttribute(
        'style',
        ObjToCssString({
          overflowY: 'auto',
        }),
      );
    };
  }, [router.isReady]);

  useEffect(() => {
    if (router.isReady && isMobileApp) {
      const html = document.querySelector('html');
      html?.setAttribute(
        'style',
        ObjToCssString({
          overscrollBehavior: 'none',
        }),
      );
      return () => {
        html?.setAttribute(
          'style',
          ObjToCssString({
            overscrollBehavior: 'auto',
          }),
        );
      };
    }
  }, [router.isReady, router.pathname, isMobileApp]);

  useEffect(() => {
    if (!shouldHideBannerDealRate && isShowBanner) {
      hideBanner();
    }
  }, [shouldHideBannerDealRate]);

  useEffect(() => {
    const hasPageViewType = typeof pageViewType !== 'undefined';
    if (router.asPath && hasPageViewType) {
      onPageView(pageViewType);
    }
  }, [router.asPath]);

  useEffect(() => {
    // Note: sync config from page to context
    setDashboardConfig(baseConfig);
  }, [stringfyConfig]);

  if (!hasAccountInfo) {
    return <LoadingView />;
  }

  return (
    <NavigationProvider>
      <DashboardLayoutProvider value={[dashboardConfig, setDashboardConfig]}>
        <section className={styled.wrapper}>
          {isShowSideBar && <Sidebar routes={routes} />}
          {isShowBottomBar && <BottomBar routes={routes} />}
          <section
            className={cx(styled.rightSection, {
              [styled.whiteBackground]: shouldHideWhiteBackground,
              [styled.greyBackground]: shouldHideGreyBackground,
            })}
          >
            {isShowTopBar && <DashboardTopbar />}
            <main
              className={cx(
                styled.content,
                { [styled.noPadding]: !isShowBottomBar },
                { [styled.noMargin]: isPageLoading },
                { [styled.withTopbar]: isShowTopBar },
                { [styled.noTopbar]: !isShowTopBar },
                { [styled.noScrollBar]: shouldHideScrollBar },
              )}
            >
              {shouldHideBannerDealRate && isShowBanner && (
                <Banner
                  message={bannerMessage}
                  colorScheme={bannerColor || 'neutral'}
                />
              )}
              {isPageLoading &&
              // Note: This added for no loading when change routes within same pages (router.replace with shallow true)
              !isNoLoadingChangeRoute ? (
                <LoadingView />
              ) : (
                children
              )}
            </main>
          </section>
        </section>
      </DashboardLayoutProvider>
    </NavigationProvider>
  );
}

const styled = {
  wrapper: css`
    display: flex;
  `,
  rightSection: css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    background-color: var(--neutral-100);
    @media (max-width: ${tabletMd}px) {
      background-color: var(--neutral-0);
    }
  `,
  withTopbar: css`
    margin-top: ${navbarSize.md}px;
    height: calc(calc(var(--vh, 1vh) * 100) - ${navbarSize.md}px) !important;
    @media (max-width: ${tabletMd}px) {
      margin-top: ${navbarSize.sm}px;
      height: calc(calc(var(--vh, 1vh) * 100) - ${navbarSize.sm}px) !important;
    }
  `,
  noTopbar: css`
    margin-top: 0;
    height: calc(var(--vh, 1vh) * 100) !important;
  `,
  content: css`
    overflow-x: hidden !important;
    overflow-y: auto !important;
    margin-top: ${navbarSize.md}px;
    height: calc(calc(var(--vh, 1vh) * 100) - ${navbarSize.md}px);
    padding-bottom: 0;
    position: relative;

    @media (max-width: ${tabletMd}px) {
      padding-bottom: 77px;
      height: calc(calc(var(--vh, 1vh) * 100) - ${navbarSize.sm}px);
    }
  `,

  noMargin: css`
    margin-left: 0;
    margin-right: 0;
  `,
  noPadding: css`
    @media (max-width: ${tabletMd}px) {
      padding-bottom: 0;
    }
  `,
  whiteBackground: css`
    background-color: var(--neutral-0) !important;
  `,
  greyBackground: css`
    background-color: var(--neutral-100) !important;
  `,
  noScrollBar: css`
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for Chrome, Safari and Opera */
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  `,
  mobileGreyBackground: css`
    @media (max-width: ${tabletMd}px) {
      background-color: var(--neutral-100);
    }
  `,
};

export default DashboardLayout;
