import { useGetMe, useGetUserMeta } from '@api-hooks/common';
import { css } from '@emotion/css';
import { Bell } from '@shapes';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { screenSize } from '@topremit/shared-web/common/size';
import { Badge, Button } from '@topremit/shared-web/components/elements';
import { useMediaQuery } from '@topremit/shared-web/hooks';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';

const NotificationContent = dynamic(() => import('./NotificationContent'));

export default function NotificationButton() {
  const { refetch: refetchMe } = useGetMe({ enabled: false });
  const ref = useRef<any>();
  const router = useRouter();
  const isTabletSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const defaultOpen = router.query.notification === '1';
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const isActiveBtnNotif = isOpen || router.pathname === '/notification';

  const { data: userMeta } = useGetUserMeta();
  const hasNotification = userMeta?.data.hasUnreadNotification === true;

  function handleClickSmallerSize() {
    if (isOpen) {
      setIsOpen(false);
    }

    if (router.pathname !== '/notification') {
      router.push('/notification');
    }

    localStorage.setItem('notification-last-path', router.asPath);
  }

  function onClickBtnNotification() {
    if (isNativeApp()) {
      sendNativeMessage({
        name: NativeWebViewBridgeEventName.OPEN_NOTIF,
      });
      return;
    }

    if (!isTabletSize) {
      setIsOpen((prev) => !prev);
      return;
    }
    handleClickSmallerSize();
  }

  function handleCloseNotificationPopup() {
    refetchMe();
    setIsOpen(false);
    localStorage.setItem('notification-last-path', router.asPath);
  }

  useEffect(() => {
    if (isTabletSize && isOpen) {
      handleClickSmallerSize();
    }
  }, [isTabletSize]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && isOpen) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <div ref={ref} className={styled.root}>
      <Button
        type="tertiary"
        onClick={onClickBtnNotification}
        shape="round"
        leftIcon={
          <Badge
            className={styled.badge}
            dot
            visible={hasNotification}
            size={8}
            offset={[-4, 4]}
          >
            <Bell
              fill={isActiveBtnNotif ? 'primary' : 'secondary'}
              width={24}
              height={24}
            />
          </Badge>
        }
      />
      {isOpen && <NotificationContent onClick={handleCloseNotificationPopup} />}
    </div>
  );
}

const styled = {
  root: css`
    position: relative;
  `,
  badge: css`
    height: 20px;
  `,
};
