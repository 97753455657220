import { css } from '@emotion/css';

export default function LoadingView() {
  return (
    <div className={styled.loading}>
      <span className={styled.dot} />
      <span className={styled.dot} />
      <span className={styled.dot} />
    </div>
  );
}

const styled = {
  loading: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    position: absolute;
    background: var(--blue-500);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: var(--neutral-0);
    border-radius: 8px;
  `,
  dot: css`
    width: 12px;
    height: 12px;
    background: var(--blue-500);
    border-radius: 99999px;
    margin: 0 2px;
    /* dots animation */
    animation: dots;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-duration: 2s;
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.8s;
    }
    @keyframes dots {
      50% {
        opacity: 0;
        transform: scale(0.7) translateY(5px);
      }
    }
  `,
};
