import { css } from '@emotion/css';
import { ReactNode, useEffect, useState } from 'react';

import { useWindowSize } from '../hooks';

interface IFloatingButtonProps {
  isVisible: boolean;
  children: ReactNode;
  onClick: (e: any) => void;
}

function FloatingButton({
  isVisible,
  children,
  onClick,
}: IFloatingButtonProps) {
  const [screenWidth] = useWindowSize();
  const [leftPositionPercent, setLeftPositionPercent] = useState(0);

  function handleScreenSizeChange() {
    if (screenWidth) {
      const main = document.querySelector('main')?.clientWidth ?? 0;
      const aside = document.querySelector('aside')?.clientWidth ?? 0;
      setLeftPositionPercent(((main / 2 + aside) * 100) / screenWidth);
    }
  }

  useEffect(() => {
    handleScreenSizeChange();
  }, [screenWidth]);

  return (
    <div
      className={styled.root({ left: leftPositionPercent, isVisible })}
      {...{ onClick }}
    >
      {children}
    </div>
  );
}

const styled = {
  root: ({ left, isVisible }) => css`
    visibility: ${isVisible ? 'visible' : 'hidden'};
    opacity: ${isVisible ? 1 : 0};
    position: fixed;
    top: 6.375rem;
    left: ${left}%;
    transform: translateX(-${left}%);
    z-index: 100;
    width: fit-content;
  `,
};

export { FloatingButton };
