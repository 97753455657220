export enum IdentifierType {
  EMAIL = 'EMAIL',
  PHONENUMBER = 'PHONE_NUMBER',
}

export enum VerificationMedia {
  SMS = 'sms',
  WHATSAPP = 'whatsapp',
  MISCALL = 'miscall',
  EMAIL = 'email',
}
