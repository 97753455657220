import { queryClient, requestFn } from '@common/client';
import { NotificationSegment } from '@modules/dashboard/notification/types';
import { useCurrentAccountStore } from '@stores';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  AddBankAccountDetailsParam,
  AddBusinessBankAccountDetailsParam,
  CheckAccountNameParam,
  CheckedAccountNameResponse,
  UploadURLResponse,
} from '@topremit/shared-web/api-hooks/common';
import { useTranslation } from '@topremit/shared-web/hooks';
import humps from 'humps';
import produce from 'immer';
import { InfiniteData, UseMutationOptions, useMutation } from 'react-query';

import {
  AccountInfo,
  AddBankAccountDetailsResponse,
  ApiNotificationWithMeta,
  ChangePhoneCreateAccountPersonal,
  CreateAccountPersonalParam,
  HasSeenOnboardingsRequest,
  NotificationGroup,
  NotificationUnreadTab,
  ReadTransactionRule,
  ReadVoucher,
  RequestOtpCreateAccountPersonalParam,
  SetPrimaryBankParam,
  ShowFeedback,
  ShowInviteFriend,
  SubmitReviewParam,
  UserBusiness,
  UserMeta,
  UserPersonal,
  VerifyOtpCreateAccountPersonal,
} from './common.model';

export function useLogout(options?: UseMutationOptions<ApiResult, ApiError>) {
  return useMutation<ApiResult, ApiError>(
    async () => await requestFn({ path: `logout`, method: 'post' }),
    options,
  );
}

export function useAddBankAccountDetails(
  options?: UseMutationOptions<
    ApiResult<AddBankAccountDetailsResponse>,
    ApiError,
    AddBankAccountDetailsParam | AddBusinessBankAccountDetailsParam
  >,
) {
  return useMutation<
    ApiResult<AddBankAccountDetailsResponse>,
    ApiError,
    AddBankAccountDetailsParam | AddBusinessBankAccountDetailsParam
  >(
    async (param) =>
      await requestFn(
        {
          path: 'bank-account',
          method: 'post',
        },
        { json: param },
      ),
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries({
          queryKey: ['tr-coins', 'bank-account'],
        });
        queryClient.invalidateQueries({
          queryKey: ['bank-account'],
        });
        options?.onSuccess && options.onSuccess(data, variables, context);
      },
    },
  );
}

export function useCheckAccountName(
  options?: UseMutationOptions<
    ApiResult<CheckedAccountNameResponse>,
    ApiError,
    CheckAccountNameParam
  >,
) {
  return useMutation<
    ApiResult<CheckedAccountNameResponse>,
    ApiError,
    CheckAccountNameParam
  >(
    async (param) =>
      await requestFn(
        { path: 'v2/account-name', method: 'post' },
        { json: param },
      ),
    options,
  );
}

export function useUpdateTosPp(
  options?: UseMutationOptions<
    ApiResult,
    ApiError,
    {
      payload: {
        tosPpVersion?: string;
      };
    }
  >,
) {
  return useMutation<
    ApiResult,
    ApiError,
    {
      payload: {
        tosPpVersion?: string;
      };
    }
  >(
    async (params: {
      payload: {
        tosPpVersion?: string;
      };
    }) =>
      await requestFn(
        {
          path: `members/tos-pp`,
          method: 'put',
        },
        {
          json: humps.decamelizeKeys(params.payload),
        },
      ),
    options,
  );
}

export function useUploadFile(
  options?: UseMutationOptions<
    any,
    any,
    { params: UploadURLResponse; file: any; contentType: string }
  >,
) {
  return useMutation<
    undefined,
    any,
    { params: UploadURLResponse; file: any; contentType: string }
  >(async ({ params, file }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { action, method } = params.attributes;

        const response = await fetch(action, {
          method,
          body: file.originFileObj,
        });

        const uploadResponseBody = await response.text();

        if (!response.ok) {
          throw new Error(uploadResponseBody);
        }
        // return filename to save to form
        resolve({
          fileName: params.inputs.key,
          uid: file.uid,
        } as any);
      } catch (e) {
        reject(e);
      }
    });
  }, options);
}

export function useUpdateShowInviteFriends(
  options?: UseMutationOptions<ApiResult<ShowInviteFriend>, ApiError>,
) {
  return useMutation<ApiResult<ShowInviteFriend>, ApiError>(
    () => {
      return requestFn({
        path: 'me/update-show-invite-friends',
        method: 'put',
      });
    },
    {
      retry: false,
      ...options,
    },
  );
}

export function useUpdateRequestForm(
  options?: UseMutationOptions<ApiResult<ShowFeedback>, ApiError>,
) {
  return useMutation<ApiResult<ShowFeedback>, ApiError>(
    () => {
      return requestFn({
        path: 'me/show-request-form',
        method: 'put',
      });
    },
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries('feedback');
      },
      ...options,
    },
  );
}

export function useReadAllNotifications(
  payload: {
    segment: string;
  },
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  const { lang } = useTranslation();
  const { currentAccount } = useCurrentAccountStore.getState() || {};

  return useMutation<ApiResult, ApiError>(
    async () => {
      return requestFn(
        {
          path: 'v2/me/notifications/all-read',
          method: 'put',
        },
        { json: { segment: payload.segment } },
      );
    },
    {
      retry: false,
      onMutate: async () => {
        const prevNotification = queryClient.getQueryData<
          InfiniteData<ApiNotificationWithMeta<NotificationGroup>[]>
        >(['notifications', currentAccount?.id, payload.segment, lang]);

        const prevMe = queryClient.getQueryData<
          ApiResult<UserPersonal & UserBusiness>
        >(['me', lang, currentAccount?.id]);

        /**
         * Update cache manually to avoid refetching data.
         *
         * useInfiniteQuery will refetch all pages when the cache is invalidated
         * this cause multiple requests O(n) to the server after read notification
         * where n = number of pages that already fetched
         * manualy update cache will prevent this performance issue
         */
        const optimisticNotifications = queryClient.setQueryData<
          InfiniteData<ApiNotificationWithMeta<NotificationGroup>>
        >(
          ['notifications', currentAccount?.id, payload.segment, lang],
          (queryData) => {
            if (queryData === undefined) {
              return { pages: [], pageParams: [] };
            }

            const newQueryData = produce(queryData, (draft) => {
              for (const page of draft.pages) {
                for (const group of page.data) {
                  for (const notification of group.notifications) {
                    notification.readAt = new Date().toISOString();
                  }
                }
              }
              const lastPage = draft.pages[draft.pages.length - 1];
              lastPage.meta.hasUnreadTabNotification[
                humps.camelize(payload.segment)
              ] = false;
            });
            return newQueryData;
          },
        );

        const otherSegment =
          payload.segment === NotificationSegment.INFORMATION
            ? NotificationSegment.NEED_ACTION
            : NotificationSegment.INFORMATION;

        queryClient.setQueryData<
          InfiniteData<ApiNotificationWithMeta<NotificationGroup>>
        >(
          ['notifications', currentAccount?.id, otherSegment, lang],
          (queryData) => {
            if (queryData === undefined) {
              return { pages: [], pageParams: [] };
            }

            const newQueryData = produce(queryData, (draft) => {
              const lastPage = draft.pages[draft.pages.length - 1];
              lastPage.meta.hasUnreadTabNotification[
                humps.camelize(payload.segment)
              ] = false;
            });
            return newQueryData;
          },
        );

        queryClient.setQueryData<ApiResult<UserMeta> | undefined>(
          ['user-meta', currentAccount?.id, lang],
          (queryData) => {
            if (!queryData) {
              return undefined;
            }

            const newQueryData = produce(queryData, (draft) => {
              const lastPage =
                optimisticNotifications.pages[
                  optimisticNotifications.pages.length - 1
                ];

              draft.data.hasUnreadNotification =
                lastPage.meta.hasUnreadTabNotification?.information ||
                lastPage.meta.hasUnreadTabNotification?.needAction;
            });

            return newQueryData;
          },
        );

        return { prevNotification, prevMe };
      },
      onError: (
        _err,
        _var,
        context: {
          prevNotification: InfiniteData<
            ApiNotificationWithMeta<NotificationGroup>[]
          >;
          prevMe: ApiResult<UserPersonal & UserBusiness>;
          prevAccountInfo: ApiResult<AccountInfo>;
        },
      ) => {
        // Rollback query data
        queryClient.setQueryData(
          ['notifications', currentAccount?.id, payload.segment, lang],
          () => context.prevNotification,
        );

        queryClient.setQueryData(
          ['me', lang, currentAccount?.id],
          () => context.prevMe,
        );
      },
      ...options,
    },
  );
}

export function useReadNotificationById(
  payload: {
    id: string;
    segment: string;
  },
  options?: UseMutationOptions<ApiResult<NotificationUnreadTab>, ApiError>,
) {
  const { lang } = useTranslation();
  const { currentAccount } = useCurrentAccountStore.getState() || {};

  return useMutation<ApiResult<NotificationUnreadTab>, ApiError>(
    async () => {
      return requestFn({
        path: `v2/me/notifications/${payload.id}/read`,
        method: 'put',
      });
    },
    {
      retry: false,
      onMutate: async () => {
        const prevNotification = queryClient.getQueryData<
          InfiniteData<ApiNotificationWithMeta<NotificationGroup>>
        >(['notifications', currentAccount?.id, payload.segment, lang]);

        /**
         * Update cache manually to avoid refetching data.
         *
         * useInfiniteQuery will refetch all pages when the cache is invalidated
         * this cause multiple requests O(n) to the server after read notification
         * where n = number of pages that already fetched
         * manualy update cache will prevent this performance issue
         */
        queryClient.setQueryData<
          InfiniteData<ApiNotificationWithMeta<NotificationGroup>>
        >(
          ['notifications', currentAccount?.id, payload.segment, lang],
          (queryData) => {
            if (queryData === undefined) {
              return { pages: [], pageParams: [] };
            }

            const newQueryData = produce(queryData, (draft) => {
              for (const page of draft.pages) {
                for (const group of page.data) {
                  for (const notification of group.notifications) {
                    if (notification.id === payload.id) {
                      notification.readAt = new Date().toISOString();
                    }
                  }
                }
              }
            });

            return newQueryData;
          },
        );

        return { prevNotification };
      },
      onSuccess: (result) => {
        queryClient.setQueryData<
          InfiniteData<ApiNotificationWithMeta<NotificationGroup>>
        >(
          ['notifications', currentAccount?.id, payload.segment, lang],
          (queryData) => {
            if (queryData === undefined) {
              return { pages: [], pageParams: [] };
            }

            const newQueryData = produce(queryData, (draft) => {
              const lastPage = draft.pages[draft.pages.length - 1];
              lastPage.meta.hasUnreadTabNotification =
                result.data.hasUnreadTabNotification ??
                lastPage.meta.hasUnreadTabNotification;
            });

            return newQueryData;
          },
        );

        queryClient.setQueryData<ApiResult<UserMeta> | undefined>(
          ['user-meta', currentAccount?.id, lang],
          (queryData) => {
            if (!queryData) {
              return undefined;
            }

            const newQueryData = produce(queryData, (draft) => {
              draft.data.hasUnreadNotification =
                result.data.hasUnreadTabNotification?.information ||
                result.data.hasUnreadTabNotification?.needAction;
            });

            return newQueryData;
          },
        );
      },
      onError: (
        _err,
        _var,
        context: {
          prevNotification: InfiniteData<
            ApiNotificationWithMeta<NotificationGroup>
          >;
        },
      ) => {
        // Rollback query data
        queryClient.setQueryData(
          ['notifications', currentAccount?.id, payload.segment, lang],
          () => context.prevNotification,
        );
      },
      ...options,
    },
  );
}

export function useReadTransactionRule(
  options?: UseMutationOptions<ApiResult<ReadTransactionRule>, ApiError>,
) {
  return useMutation<ApiResult<ReadTransactionRule>, ApiError>(
    () => {
      return requestFn({
        path: 'me/show-transaction-rule',
        method: 'put',
      });
    },
    {
      retry: false,
      ...options,
    },
  );
}

export function useReadVoucher(
  options?: UseMutationOptions<ApiResult<ReadVoucher>, ApiError>,
) {
  return useMutation<ApiResult<ReadVoucher>, ApiError>(
    () => {
      return requestFn({
        path: 'me/vouchers/read',
        method: 'put',
      });
    },
    {
      retry: false,
      ...options,
    },
  );
}

export function useDashboardCoachmark(
  options?: UseMutationOptions<ApiResult<boolean>, ApiError>,
) {
  return useMutation<ApiResult<boolean>, ApiError>(
    () => {
      return requestFn({
        path: 'me/show-homepage-coachmark',
        method: 'put',
      });
    },
    {
      retry: false,
      ...options,
    },
  );
}

export function useSubmitReview(
  options?: UseMutationOptions<ApiResult, ApiError, SubmitReviewParam>,
) {
  return useMutation<ApiResult, ApiError, SubmitReviewParam>(
    (param: SubmitReviewParam) => {
      const { id, ...restParam } = param;
      return requestFn(
        {
          path: `transactions/${id}/review `,
          method: 'post',
        },
        { json: restParam },
      );
    },
    {
      retry: false,
      ...options,
    },
  );
}

export function useSetPrimaryBank(
  options?: UseMutationOptions<ApiResult, ApiError, SetPrimaryBankParam>,
) {
  return useMutation<ApiResult, ApiError, SetPrimaryBankParam>(
    async (param) =>
      await requestFn(
        {
          path: 'bank-account/primary',
          method: 'post',
        },
        { json: param },
      ),
    {
      ...options,
      onSuccess(data, variables, context) {
        queryClient.invalidateQueries({
          queryKey: ['tr-coins', 'bank-account'],
        });
        queryClient.invalidateQueries({
          queryKey: ['bank-account'],
        });
        options?.onSuccess && options.onSuccess(data, variables, context);
      },
    },
  );
}

export function useCreateAccountPersonal(
  options?: UseMutationOptions<ApiResult, ApiError, CreateAccountPersonalParam>,
) {
  return useMutation<ApiResult, ApiError, CreateAccountPersonalParam>(
    async (param: CreateAccountPersonalParam) =>
      await requestFn(
        {
          path: 'create/personal',
          method: 'post',
        },
        { json: param },
      ),
    options,
  );
}

export function useRequestOTPCreateAccountPersonal(
  options?: UseMutationOptions<
    ApiResult,
    ApiError,
    RequestOtpCreateAccountPersonalParam
  >,
) {
  return useMutation<ApiResult, ApiError, RequestOtpCreateAccountPersonalParam>(
    async (param: RequestOtpCreateAccountPersonalParam) =>
      await requestFn(
        {
          path: 'create/personal/otp',
          method: 'post',
        },
        { json: param },
      ),
    options,
  );
}

export function useChangePhoneCreateAccountPersonal(
  options?: UseMutationOptions<
    ApiResult,
    ApiError,
    ChangePhoneCreateAccountPersonal
  >,
) {
  return useMutation<ApiResult, ApiError, ChangePhoneCreateAccountPersonal>(
    async (param: ChangePhoneCreateAccountPersonal) =>
      await requestFn(
        {
          path: 'create/personal/change-phone',
          method: 'post',
        },
        { json: param },
      ),
    options,
  );
}

export function useVerifyOTPCreateAccountPersonal(
  options?: UseMutationOptions<
    ApiResult,
    ApiError,
    VerifyOtpCreateAccountPersonal
  >,
) {
  return useMutation<ApiResult, ApiError, VerifyOtpCreateAccountPersonal>(
    async (param: VerifyOtpCreateAccountPersonal) =>
      await requestFn(
        {
          path: 'create/personal/verify',
          method: 'post',
        },
        { json: param },
      ),
    options,
  );
}

export function usePushSubscribe(
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  return useMutation<ApiResult, ApiError>(
    () =>
      requestFn({
        path: 'me/push-subscribe',
        method: 'post',
      }),
    options,
  );
}

export function usePostHasSeenOnboardings(
  options?: UseMutationOptions<ApiResult, ApiError, HasSeenOnboardingsRequest>,
) {
  return useMutation<ApiResult, ApiError, HasSeenOnboardingsRequest>(
    async (param: HasSeenOnboardingsRequest) =>
      await requestFn(
        {
          path: 'onboardings',
          method: 'post',
        },
        {
          json: param,
        },
      ),
    options,
  );
}
