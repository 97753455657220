import { css } from '@emotion/css';
import { ObjToCssString } from '@global-common/helper';
import { screenSize } from '@global-common/size';
import { MinusSmallOutline, PlusSmallOutline } from '@global-shapes';
import { color } from '@topremit/shared-web/styles/color';
import { motion, MotionValue, useSpring, useTransform } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Button, Flex } from '.';

interface CounterProps {
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  displayValueWhenZero?: boolean;
  onChange?: (value: number) => void;
  onIncrement: (value: number) => void;
  onDecrement: (value: number) => void;
}

function Counter({
  value,
  min = 0,
  disabled,
  onChange,
  max = 999,
  onDecrement,
  onIncrement,
  displayValueWhenZero = true,
}: CounterProps) {
  const [count, setCount] = useState(value ?? 0);
  const animatedCount = useSpring(count);

  function handleDecrement() {
    setCount((x) => {
      const newValue = x - 1 < min ? min : x - 1;

      onDecrement?.(newValue);
      onChange?.(newValue);
      return newValue;
    });
  }

  function handleIncrement() {
    setCount((x) => {
      const newValue = max !== undefined && x + 1 > max ? x : x + 1;

      onIncrement?.(newValue);
      onChange?.(newValue);
      return newValue;
    });
  }

  function generateIncrementType() {
    if (!displayValueWhenZero && count === 0) {
      return 'primary';
    }
    return 'secondary';
  }

  useEffect(() => {
    if (value !== undefined) {
      setCount(value);
    }
  }, [value]);

  useEffect(() => {
    animatedCount.set(count);
  }, [animatedCount, count]);

  return (
    <Flex className={styled.root(displayValueWhenZero)}>
      {((!displayValueWhenZero && count > 0) || displayValueWhenZero) && (
        <>
          <Button
            className={styled.button}
            onClick={handleDecrement}
            type="secondary"
            disabled={disabled}
            size="small"
            shape="round"
            leftIcon={
              <MinusSmallOutline
                width={20}
                height={20}
                fill={disabled ? color.neutral400 : color.blue500}
              />
            }
          />
          <motion.div className={styled.valueWrapper}>
            {[...Array(max + 1).keys()].map((i) => (
              <Number mv={animatedCount} number={i} key={i} />
            ))}
          </motion.div>
        </>
      )}
      <Button
        className={styled.button}
        onClick={handleIncrement}
        type={generateIncrementType()}
        disabled={disabled}
        size="small"
        shape="round"
        leftIcon={
          <PlusSmallOutline
            width={20}
            height={20}
            fill={
              disabled
                ? color.neutral400
                : !displayValueWhenZero && count === 0
                  ? color.neutral0
                  : color.blue500
            }
          />
        }
      />
    </Flex>
  );
}

function Number({ mv, number }: { mv: MotionValue; number: number }) {
  const y = useTransform(mv, (latest) => {
    return 24 * (number - latest);
  });

  return (
    <motion.span style={{ y }} className={styled.value}>
      {number}
    </motion.span>
  );
}

const styled = {
  root: (displayValueWhenZero: boolean) => css`
    align-items: center;
    gap: 0.125rem;
    width: auto;
    ${displayValueWhenZero && ObjToCssString({ justifyContent: 'end' })}
  `,
  button: css`
    border-radius: 100%;
    height: 1.5rem !important;
    width: 1.5rem !important;
    min-width: unset !important;
    padding: 0.25rem !important;
  `,
  valueWrapper: css`
    position: relative;
    width: 32px;
    height: 24px;
    overflow: hidden;
    @media (max-width: ${screenSize.tabletMd}px) {
      width: 26px;
    }
  `,
  value: css`
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${color.blue500};
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.875rem;
    }
  `,
};

export default Counter;
