import { cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { FlagCode } from '@global-elements-utils/Flag';
import { useMemo } from 'react';

import CountryCurrencyFlag from './CountryCurrencyFlag';
import { useChooseCountryCurrency } from './hooks/use-choose-country-currency';
import { styles } from './styles';

function ChooseCountryCurrencyValue({ className }: { className?: string }) {
  const { value, countriesWithRecent } = useChooseCountryCurrency();

  const selectedCountry = useMemo(
    () =>
      countriesWithRecent.find((country) => country.country === value.country),
    [countriesWithRecent, value.country],
  );

  const flag = selectedCountry?.flag as FlagCode;

  return (
    <Flex
      align="center"
      fullWidth={false}
      className={cx(styles.choosedOption, 'selected-option', className)}
    >
      <CountryCurrencyFlag
        flagCode={flag}
        currencyCode={value.currency}
        className="country-currency-flag"
      />
    </Flex>
  );
}

export default ChooseCountryCurrencyValue;
