import { CommandInput } from '@global-elements-utils/command';
import { useTranslation } from '@global-hooks';
import * as React from 'react';

export const SelectSearch = React.forwardRef<
  HTMLInputElement,
  React.ComponentPropsWithoutRef<typeof CommandInput>
>(({ placeholder, ...props }, ref) => {
  const { t } = useTranslation();
  const [search, setSearch] = React.useState('');

  return (
    <CommandInput
      ref={ref}
      value={search}
      onValueChange={setSearch}
      clearable={search.length > 0}
      placeholder={placeholder ?? t('common:search_here')}
      {...props}
    />
  );
});
