import { css, cx } from '@emotion/css';
import { color as globalColor } from '@global-styles/color';
import { ReactNode } from 'react';

export type DividerOrientation = 'horizontal' | 'vertical';
export type DividerVariant = 'solid' | 'dashed';
export type DividerColor = keyof typeof globalColor;

interface Margin {
  /** px */
  mt: number;
  mr: number;
  mb: number;
  ml: number;
  mx: number;
  my: number;
}

export interface DividerProps extends Partial<Margin> {
  orientation?: DividerOrientation;
  variant?: DividerVariant;
  children?: ReactNode;
  color?: DividerColor;

  /** px */
  size?: number;
}

export default function Divider({
  orientation = 'horizontal',
  color = 'neutral200',
  variant = 'solid',
  size = 1,
  children,
  ...props
}: DividerProps) {
  const {
    mt,
    mr,
    mb,
    ml,
    mx = 0,
    my = orientation === 'vertical' ? 0 : 20,
  } = props;

  const marginTop = mt !== undefined ? mt : my;
  const marginRight = mr !== undefined ? mr : mx;
  const marginBottom = mb !== undefined ? mb : my;
  const marginLeft = ml !== undefined ? ml : mx;

  return (
    <div
      role="separator"
      className={cx(
        styled[orientation](size),
        styled.line(orientation, variant, color),
        styled.margin(marginTop, marginRight, marginBottom, marginLeft),
      )}
      {...props}
    >
      {!!children && <div className={styled.children}>{children}</div>}
    </div>
  );
}

const styled = {
  horizontal: (size: number) => css`
    width: 100%;
    min-height: ${size}px;
  `,
  vertical: (size: number) => css`
    width: ${size}px;
    height: 100%;
  `,
  margin: (mt: number, mr: number, mb: number, ml: number) => css`
    margin: ${mt}px ${mr}px ${mb}px ${ml}px;
  `,
  line: (
    orientation: DividerOrientation,
    variant: DividerVariant,
    color: DividerColor,
  ) => css`
    position: relative;
    /** generate dashed effect using gradient */
    background: ${variant === 'solid'
      ? globalColor[color]
      : `repeating-linear-gradient(
      ${orientation === 'horizontal' ? 'to right' : 'to bottom'},
      ${globalColor[color]},
      ${globalColor[color]} 6px,
      /* Dash length */ transparent 6px,
      /* Space between dashes */ transparent 12px /* Total repeat pattern */
    )`};
  `,
  children: css`
    position: absolute;
    left: 50%;
    top: -10px;
    width: fit-content;
    background: var(--color-neutral-0);
    transform: translateX(-50%);
    padding: 0 1rem;
  `,
};
