import { css, cx } from '@emotion/css';
import { useDashboardLayout, usePermission } from '@hooks';
import {
  getNativeInset,
  ObjToCssString,
} from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { screenSize } from '@topremit/shared-web/common/size';
import { Container, parseHtml } from '@topremit/shared-web/components/elements';
import { Angle, Close } from '@topremit/shared-web/components/shapes';
import { useMeasure, useMediaQuery } from '@topremit/shared-web/hooks';
import { useEffect, useRef, useState } from 'react';

import { ILegacyContentCardProps } from './types';
import ChatUsButton from '../chat-cs/ChatUsButton';

const { tabletMd } = screenSize;

/**
 * @deprecated use ContentCard instead
 */
export default function LegacyContentCard({
  type = 'fit-content',
  children,
  footer,
  header,
  className,
  contentClassName,
  title,
  description,
  maxWidth,
  hasBackButton = false,
  hasCloseButton = false,
  hasChatUsButton = false,
  onClickBackButton,
  onClickCloseButton,
}: ILegacyContentCardProps) {
  const [hasAnnouncement, setHasAnnouncement] = useState<boolean>(false);
  const [hasDashboardContainer, setHasDashboardContainer] =
    useState<boolean>(false);

  const { top: nativeTopInset } = getNativeInset();

  const withHeader = hasBackButton || hasCloseButton || !!header;
  const withFooter = !!footer;
  const [{ isHideSideBar, isHideBottomBar }] = useDashboardLayout();

  const { isPersonalAccount } = usePermission();

  const isMobileSize = useMediaQuery(`(max-width:${tabletMd}px)`);

  const footerContainerRef = useRef(null);
  const headerRef = useRef(null);
  const { height: footerHeight } = useMeasure(footerContainerRef);
  const { height: headerHeight } = useMeasure(headerRef);

  useEffect(() => {
    const dashboardContainer = document.querySelector('.dashboard-container');
    setHasDashboardContainer(Boolean(dashboardContainer));
  }, []);

  useEffect(() => {
    const contentCard = document.querySelector('.content-card');
    const container = document.querySelector('.content-card > .container');
    const contentCardContainer = document.querySelector(
      '.content-card-container',
    );
    const contentCardFooter = document.querySelector('.content-card-footer');
    const content = document.querySelector(
      '.content-card-container > .content',
    );
    const wrapper = document.querySelector('.container .wrapper');
    const announcement = document.querySelector('.new-container');

    setHasAnnouncement(Boolean(announcement));

    const contentCardContainerPaddingVertical =
      Number(
        typeof window !== 'undefined'
          ? window
              .getComputedStyle(contentCardContainer as Element, '')
              .getPropertyValue('padding-top')
              .slice(0, -2)
          : 0,
      ) +
      Number(
        typeof window !== 'undefined'
          ? window
              .getComputedStyle(contentCardContainer as Element, '')
              .getPropertyValue('padding-bottom')
              .slice(0, -2)
          : 0,
      );

    if (isMobileSize) {
      contentCard?.setAttribute(
        'style',
        ObjToCssString({
          minHeight: withFooter
            ? `calc(calc(var(--vh, 1vh) * 100) - 4.375rem)`
            : `calc(calc(var(--vh, 1vh) * 100) - 4.375rem - 6.75rem)`,
        }),
      );
      if (withFooter) {
        contentCardFooter?.setAttribute(
          'style',
          ObjToCssString({
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            background: 'var(--neutral-0)',
          }),
        );
      }
    } else {
      // reset position to static when no mobile size
      contentCardFooter?.setAttribute(
        'style',
        ObjToCssString({
          position: 'static',
        }),
      );
    }

    if (type === 'max-height') {
      container?.setAttribute(
        'style',
        ObjToCssString({
          height: withFooter
            ? 'calc(calc(var(--vh, 1vh) * 100) - 4.375rem - 2.5rem - 2.5rem )'
            : 'unset',
          minHeight: withFooter
            ? 'unset'
            : 'calc(calc(var(--vh, 1vh) * 100) - 4.375rem - 2.5rem - 2.5rem )',
        }),
      );
      wrapper?.setAttribute(
        'style',
        ObjToCssString({
          height: withFooter
            ? isMobileSize
              ? isNativeApp()
                ? 'calc(var(--vh, 1vh) * 100)'
                : 'unset'
              : `${Number((container?.clientHeight || 0) / 16)}rem`
            : 'unset',
        }),
      );
      contentCardContainer?.setAttribute(
        'style',
        ObjToCssString({
          height: withFooter
            ? `calc(calc(var(--vh, 1vh) * 100) - ${
                withHeader ? headerHeight / 16 - 1 : 5.125 // - 1rem padding top content-card-container
              }rem - 2.5rem - 0.25rem)`
            : 'unset',
          overflowY: withFooter ? 'auto' : 'visible',
          paddingBottom:
            withFooter && isMobileSize
              ? `calc(${Number(footerHeight || 0)}px + 3rem + 2rem)`
              : '2rem',
        }),
      );
      content?.setAttribute(
        'style',
        ObjToCssString({
          minHeight: `calc(calc(var(--vh, 1vh) * 100) - ${
            withHeader ? headerHeight / 16 : 5.125
          }rem - 2.5rem - 2.5rem - ${
            withHeader ? `${headerHeight / 16}rem` : '0rem'
          } - ${
            withFooter
              ? `calc(${Number(footerHeight || 0)}px + 3rem + 2rem)`
              : '0rem'
          }  - ${contentCardContainerPaddingVertical / 16}rem - 2rem)`,
        }),
      );
    } else {
      container?.setAttribute(
        'style',
        ObjToCssString({
          minHeight: 'unset',
        }),
      );
      contentCardContainer?.setAttribute(
        'style',
        ObjToCssString({
          minHeight: 'unset',
          overflowY: withFooter ? 'scroll' : 'visible',
        }),
      );
      content?.setAttribute(
        'style',
        ObjToCssString({
          minHeight: 'unset',
        }),
      );
    }

    if (isHideBottomBar && isHideSideBar) {
      contentCard?.setAttribute(
        'style',
        ObjToCssString({
          minHeight: 'calc(calc(var(--vh, 1vh) * 100) - 4.375rem)',
        }),
      );

      if (withFooter) {
        wrapper?.setAttribute(
          'style',
          ObjToCssString({
            height: 'calc(calc(var(--vh, 1vh) * 100) - 4.375rem)',
          }),
        );
      }
    }
  }, [isMobileSize, footerHeight, headerHeight]);

  return (
    <section
      className={cx(
        styled.root({
          withHeader,
          isMobileSize,
          maxWidth,
          hasAnnouncement,
          hasDashboardContainer,
          nativeTopInset,
        }),
        className,
        'content-card',
      )}
    >
      <Container>
        <div
          className={cx('card card-main wrapper', {
            'footer-wrapper': !!footer,
          })}
        >
          {withHeader && (
            <div className="content-card-header" ref={headerRef}>
              <div className="header-action">
                {hasBackButton && (
                  <div className="back-button" onClick={onClickBackButton}>
                    <Angle
                      role="button"
                      fill="var(--neutral-500)"
                      direction="left"
                      size="small"
                    />
                  </div>
                )}
                {hasCloseButton && (
                  <div className="close-button" onClick={onClickCloseButton}>
                    <Close fill="var(--neutral-500)" />
                  </div>
                )}
                {hasChatUsButton && isPersonalAccount && (
                  <ChatUsButton className="chat-us-button" />
                )}
              </div>
              <div className="header-element">{!!header && header}</div>
            </div>
          )}
          <div className="content-card-container">
            {title && <h4 className="title">{title}</h4>}
            {description && (
              <div className="description">
                <span>{parseHtml(String(description))}</span>
              </div>
            )}
            <div className={cx('content', contentClassName)}>{children}</div>
          </div>
          {footer && (
            <div className="content-card-footer" ref={footerContainerRef}>
              {footer}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}

const styled = {
  root: ({
    withHeader,
    isMobileSize,
    maxWidth,
    hasAnnouncement,
    hasDashboardContainer,
    nativeTopInset,
  }: {
    withHeader: boolean;
    maxWidth?: string;
    isMobileSize?: boolean;
    hasAnnouncement: boolean;
    hasDashboardContainer: boolean;
    nativeTopInset: number;
  }) => css`
    padding: ${hasAnnouncement
        ? '5.5rem'
        : hasDashboardContainer && isMobileSize
          ? '0'
          : '2.5rem'}
      0;
    min-height: calc(100vh - 4.375rem);
    @media (max-width: ${tabletMd}px) {
      width: 100vw;
    }
    > .container {
      width: 100%;
      height: 100%;
      @media (max-width: ${tabletMd}px) {
        padding: 0;
      }
      > .wrapper {
        display: flex;
        flex-direction: column;
        max-width: ${maxWidth ? maxWidth : '35.25rem'};
        margin: 0 auto;
        padding: unset;
        @media (max-width: ${tabletMd}px) {
          width: 100%;
          max-width: unset;
          border-radius: 0;
          box-shadow: none;
        }
        .content-card-header {
          padding: 2rem 2rem 1rem;

          @media (max-width: ${tabletMd}px) {
            padding: calc(1.25rem + ${nativeTopInset}px) 1.5rem 0.5rem;
          }
          .header-action {
            display: flex;
            align-items: center;
            .back-button,
            .close-button,
            .chat-us-button {
              cursor: pointer;
            }
            .close-button,
            .chat-us-button {
              margin-left: auto;
            }
          }
        }
        .content-card-container {
          min-height: inherit;
          height: 100%;
          padding: ${!withHeader ? '2rem' : '1.125rem 2rem 2rem'};
          overflow-y: visible;
          @media (max-width: ${tabletMd}px) {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
          }
          > .title {
            margin-bottom: 0.5rem;
            @media (max-width: ${tabletMd}px) {
              font-size: var(--h5-font-size);
            }
          }
          > .description {
            color: var(--text-secondary);
            margin-bottom: 1.5rem;
          }
          > .content {
            display: flex;
            flex-direction: column;
          }
        }
        .content-card-footer {
          box-shadow: 0 0 2.5rem rgba(46, 72, 101, 0.08);
          padding: 1rem 2rem 2rem;
          @media (max-width: ${tabletMd}px) {
            padding: 1rem 1.5rem 1.5rem;
          }
        }
      }
    }
  `,
};
