import { SVGProps } from 'react';

function Angle({
  size = 'default',
  fill = 'var(--neutral-500)',
  ...props
}: SVGProps<SVGSVGElement> & {
  direction: 'left' | 'right' | 'up' | 'down';
  size?: 'default' | 'small';
}) {
  function handleArrowDirection() {
    switch (props.direction) {
      case 'left':
        return size === 'small' ? (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            {...props}
          >
            <path d="M10.5999 12.707C10.4124 12.5195 10.3071 12.2651 10.3071 12C10.3071 11.7348 10.4124 11.4805 10.5999 11.293L15.1849 6.70698C15.3671 6.51838 15.4679 6.26578 15.4656 6.00358C15.4633 5.74138 15.3581 5.49057 15.1727 5.30516C14.9873 5.11975 14.7365 5.01458 14.4743 5.01231C14.2121 5.01003 13.9595 5.11082 13.7709 5.29298L9.18891 9.87898C8.62649 10.4416 8.31055 11.2045 8.31055 12C8.31055 12.7955 8.62649 13.5584 9.18891 14.121L13.7749 18.707C13.9635 18.8891 14.2161 18.9899 14.4783 18.9877C14.7405 18.9854 14.9913 18.8802 15.1767 18.6948C15.3621 18.5094 15.4673 18.2586 15.4696 17.9964C15.4719 17.7342 15.3711 17.4816 15.1889 17.293L10.5999 12.707Z" />
          </svg>
        ) : (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M15.5749 2.24464L8.7649 9.05381C7.98489 9.83611 7.54688 10.8958 7.54688 12.0005C7.54688 13.1052 7.98489 14.1648 8.7649 14.9471L15.5749 21.7563C15.7321 21.9081 15.9426 21.9921 16.1611 21.9902C16.3796 21.9883 16.5886 21.9007 16.7431 21.7462C16.8976 21.5916 16.9852 21.3826 16.9871 21.1641C16.989 20.9456 16.905 20.7351 16.7532 20.578L9.94323 13.7688C9.47567 13.2991 9.21318 12.6633 9.21318 12.0005C9.21318 11.3377 9.47567 10.7019 9.94323 10.2321L16.7532 3.42298C16.905 3.26581 16.989 3.05531 16.9871 2.83681C16.9852 2.61831 16.8976 2.4093 16.7431 2.25479C16.5886 2.10029 16.3796 2.01265 16.1611 2.01075C15.9426 2.00885 15.7321 2.09284 15.5749 2.24464Z"
              fill="#8295B5"
            />
          </svg>
        );
      case 'right':
        return size === 'small' ? (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill={fill}
            {...props}
          >
            <path d="M15.3999 9.87898L10.8109 5.29298C10.6205 5.12082 10.3712 5.02847 10.1145 5.03506C9.85792 5.04165 9.61367 5.14667 9.43235 5.32837C9.25103 5.51008 9.14653 5.75456 9.14048 6.01118C9.13444 6.26781 9.22731 6.51693 9.39988 6.70698L13.9859 11.293C14.1734 11.4805 14.2787 11.7348 14.2787 12C14.2787 12.2651 14.1734 12.5194 13.9859 12.707L9.39988 17.293C9.21237 17.4806 9.10708 17.7351 9.10718 18.0003C9.10727 18.2656 9.21274 18.52 9.40038 18.7075C9.58802 18.895 9.84246 19.0003 10.1077 19.0002C10.373 19.0001 10.6274 18.8946 10.8149 18.707L15.3999 14.121C15.9623 13.5584 16.2782 12.7955 16.2782 12C16.2782 11.2045 15.9623 10.4416 15.3999 9.87898Z" />
          </svg>
        ) : (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M8.42266 21.7558L15.2327 14.9467C16.0127 14.1644 16.4507 13.1047 16.4507 12C16.4507 10.8953 16.0127 9.83564 15.2327 9.05335L8.42266 2.24418C8.26549 2.09238 8.05499 2.00839 7.83649 2.01029C7.618 2.01218 7.40899 2.09982 7.25448 2.25433C7.09997 2.40884 7.01233 2.61785 7.01043 2.83635C7.00853 3.05484 7.09253 3.26535 7.24433 3.42251L14.0543 10.2317C14.5219 10.7014 14.7844 11.3372 14.7844 12C14.7844 12.6628 14.5219 13.2986 14.0543 13.7683L7.24433 20.5775C7.09253 20.7347 7.00853 20.9452 7.01043 21.1637C7.01233 21.3822 7.09997 21.5912 7.25448 21.7457C7.40899 21.9002 7.618 21.9878 7.83649 21.9897C8.05499 21.9916 8.26549 21.9076 8.42266 21.7558Z"
              fill="#8295B5"
            />
          </svg>
        );
      case 'up':
        return size === 'small' ? (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path d="M17.9999 15.5C17.7347 15.4999 17.4804 15.3945 17.2929 15.207L12.7069 10.621C12.5168 10.4385 12.2635 10.3366 11.9999 10.3366C11.7364 10.3366 11.4831 10.4385 11.2929 10.621L6.70692 15.207C6.51832 15.3891 6.26571 15.4899 6.00352 15.4877C5.74132 15.4854 5.49051 15.3802 5.3051 15.1948C5.11969 15.0094 5.01452 14.7586 5.01224 14.4964C5.00997 14.2342 5.11076 13.9816 5.29292 13.793L9.87892 9.20699C10.4505 8.66152 11.2103 8.35718 12.0004 8.35718C12.7905 8.35718 13.5503 8.66152 14.1219 9.20699L18.7069 13.793C18.8467 13.9328 18.9419 14.111 18.9805 14.305C19.0191 14.4989 18.9993 14.6999 18.9236 14.8826C18.8479 15.0653 18.7198 15.2215 18.5554 15.3314C18.391 15.4413 18.1977 15.4999 17.9999 15.5Z" />
          </svg>
        ) : (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M21.7558 15.5776L14.9467 8.76758C14.1644 7.98757 13.1047 7.54956 12 7.54956C10.8953 7.54956 9.83564 7.98757 9.05335 8.76758L2.24418 15.5776C2.09238 15.7347 2.00839 15.9453 2.01029 16.1637C2.01218 16.3822 2.09982 16.5913 2.25433 16.7458C2.40884 16.9003 2.61785 16.9879 2.83635 16.9898C3.05484 16.9917 3.26535 16.9077 3.42251 16.7559L10.2317 9.94592C10.7014 9.47836 11.3372 9.21587 12 9.21587C12.6628 9.21587 13.2986 9.47836 13.7683 9.94592L20.5775 16.7559C20.7347 16.9077 20.9452 16.9917 21.1637 16.9898C21.3822 16.9879 21.5912 16.9003 21.7457 16.7458C21.9002 16.5913 21.9878 16.3822 21.9897 16.1637C21.9916 15.9453 21.9076 15.7347 21.7558 15.5776Z"
              fill="#8295B5"
            />
          </svg>
        );
      case 'down':
        return size === 'small' ? (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path d="M18.7069 8.20697C18.5194 8.0195 18.2651 7.91418 17.9999 7.91418C17.7348 7.91418 17.4804 8.0195 17.2929 8.20697L12.7069 12.793C12.5168 12.9755 12.2635 13.0774 11.9999 13.0774C11.7364 13.0774 11.4831 12.9755 11.2929 12.793L6.70692 8.20697C6.51832 8.02481 6.26571 7.92402 6.00352 7.9263C5.74132 7.92858 5.49051 8.03374 5.3051 8.21915C5.11969 8.40456 5.01452 8.65537 5.01224 8.91757C5.00997 9.17977 5.11076 9.43237 5.29292 9.62097L9.87792 14.207C10.1565 14.4856 10.4872 14.7066 10.8513 14.8574C11.2153 15.0082 11.6054 15.0858 11.9994 15.0858C12.3934 15.0858 12.7836 15.0082 13.1476 14.8574C13.5116 14.7066 13.8423 14.4856 14.1209 14.207L18.7069 9.62097C18.8944 9.43344 18.9997 9.17913 18.9997 8.91397C18.9997 8.64881 18.8944 8.3945 18.7069 8.20697Z" />
          </svg>
        ) : (
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
          >
            <path
              d="M2.24415 8.42242L9.05332 15.2324C9.83562 16.0124 10.8953 16.4504 12 16.4504C13.1047 16.4504 14.1644 16.0124 14.9467 15.2324L21.7558 8.42242C21.9076 8.26525 21.9916 8.05475 21.9897 7.83625C21.9878 7.61775 21.9002 7.40874 21.7457 7.25424C21.5912 7.09973 21.3822 7.01209 21.1637 7.01019C20.9452 7.00829 20.7347 7.09229 20.5775 7.24408L13.7683 14.0541C13.2986 14.5216 12.6628 14.7841 12 14.7841C11.3372 14.7841 10.7014 14.5216 10.2317 14.0541L3.42249 7.24408C3.26532 7.09229 3.05482 7.00829 2.83632 7.01019C2.61782 7.01209 2.40881 7.09973 2.2543 7.25424C2.0998 7.40874 2.01216 7.61775 2.01026 7.83625C2.00836 8.05475 2.09236 8.26525 2.24415 8.42242Z"
              fill="#8295B5"
            />
          </svg>
        );
    }
  }

  return handleArrowDirection();
}

export default Angle;
