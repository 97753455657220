import { css, cx } from '@emotion/css';
import { screenSize } from '@global-common/size';
import { CommandList, CommandSeparator } from '@global-elements-utils/command';
import { useMediaQuery } from '@global-hooks';
import { useCommandState } from 'cmdk';
import * as React from 'react';
/**
 * SelectList
 *
 * @description
 * `SelectList` component is a list of select items that can be used in the `Select` component.
 * it also have max height and will be scrollable if the items exceed the max height.
 *
 * `SelectList` component children should be a `SelectItem` component.
 *
 * @example
 * <SelectList>
 *   <SelectItem value="light" keywords={['terang', 'putih']}>
 *     Light
 *   </SelectItem>
 *   <SelectItem value="dark" keywords={['gelap', 'hitam']}>
 *     Dark
 *   </SelectItem>
 *   <SelectItem value="system" keywords={['sistem', 'otomatis']}>
 *     System
 *   </SelectItem>
 * </SelectList>
 *
 */

type SelectListClassNameValue = {
  count?: number;
};

export const SelectList = React.forwardRef<
  React.ElementRef<typeof CommandList>,
  Omit<React.ComponentPropsWithoutRef<typeof CommandList>, 'className'> & {
    className?: string | ((value: SelectListClassNameValue) => string);
  }
>(({ children, className, ...props }, ref) => {
  const isMobile = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);

  const count = useCommandState((s) => s?.filtered?.count);

  const childrenWithSeparator = React.Children.map(children, (child, index) => {
    return (
      <>
        {isMobile && index !== 0 ? <CommandSeparator /> : null}
        {child}
      </>
    );
  });

  return (
    <CommandList
      ref={ref}
      className={cx(
        styled.root,
        {
          [styled.onMobile]: isMobile,
          [styled.onDesktop]: !isMobile,
        },
        typeof className === 'function'
          ? className({
              count,
            })
          : className,
      )}
      {...props}
    >
      {childrenWithSeparator}
    </CommandList>
  );
});

const styled = {
  root: css``,
  onMobile: css`
    max-height: unset;
  `,
  onDesktop: css`
    max-height: calc(
      var(--radix-popover-content-available-height) - (68px * 2)
    );
  `,
};
