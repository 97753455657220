import { createContext, useContext, useState } from 'react';

interface DropdownAccordionContextProps {
  selectedId: string;
  onClick: (id: string) => void;
}

const DropdownAccordionContext = createContext({});

export function DropdownAccordionProvider({ children }) {
  const [selectedId, setSelectedId] = useState<string>();

  function onClick(id) {
    if (selectedId === id) {
      setSelectedId(undefined);
      return;
    }
    setSelectedId(id);
  }

  return (
    <DropdownAccordionContext.Provider
      value={{
        selectedId,
        onClick,
      }}
    >
      {children}
    </DropdownAccordionContext.Provider>
  );
}

export function useDropdownAccordion() {
  return useContext(DropdownAccordionContext) as DropdownAccordionContextProps;
}
