import {
  AuthResponse,
  LoginParam,
  useLogin,
  useRevokeToken,
} from '@api-hooks/auth';
import { queryClient } from '@common/client';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@common/storage';
import { UPDATE_DATA_DRAFT_KEY } from '@modules/dashboard/profile/constants';
import {
  TR_COINS_BANK_ACC_ORDER,
  TR_COINS_WITHDRAWAL_AMOUNT,
} from '@modules/dashboard/tr-coins/constant';
import { HIDE_WALLET_KEY } from '@modules/wallet/constants';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { assertContextCalledInProvider } from '@topremit/shared-web/common/assertion';
import { AUTH_FLAG_KEY, setCookie } from '@topremit/shared-web/common/cookie';
import {
  deleteItemInCookies,
  getItemInCookies,
} from '@topremit/shared-web/common/helper';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import { useRouter } from 'next/router';
import { Dispatch, createContext, useContext, useState } from 'react';
import { UseMutationResult } from 'react-query';

interface AuthContextInterface {
  login(values: LoginParam): Promise<ApiResult<AuthResponse>>;
  logout: (nextUrl?: string) => void;
  token: string | null;
  loginQuery: UseMutationResult<
    ApiResult<AuthResponse>,
    ApiError,
    LoginParam,
    unknown
  >;
  /** Undefined means the auth hasnt been checked yet */
  isAuthenticated: boolean | undefined;
  setIsAuthenticated: Dispatch<boolean>;
}

const AUTH_FLAG_KEY_EXPIRATION_AGE = 7776000;

const AuthContext = createContext<AuthContextInterface | null>(null);

export const deleteAllLocalToken = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    window.localStorage.removeItem(TR_COINS_WITHDRAWAL_AMOUNT);
    window.localStorage.removeItem(TR_COINS_BANK_ACC_ORDER);
    deleteItemInCookies(AUTH_FLAG_KEY);
  }
};

export const AuthProvider = ({ children }) => {
  const { t, lang } = useTranslation();
  const router = useRouter();
  const { addNotification } = useNotification();
  const [token, setToken] = useState<string | null>(null);

  const hasAuthFlagKey = !!getItemInCookies(AUTH_FLAG_KEY);

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    hasAuthFlagKey || false,
  );

  const revokeToken = useRevokeToken();

  const loginQuery = useLogin({
    onMutate: () => {
      setToken(null);
      deleteAllLocalToken();
      queryClient.removeQueries({ queryKey: ['me', lang] });
    },
    onSuccess: ({ data: { accessToken, refreshToken, expiresIn } }) => {
      if (accessToken && refreshToken) {
        const newExpirationDate = new Date();

        newExpirationDate.setSeconds(
          newExpirationDate.getSeconds() + expiresIn,
        );

        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

        setCookie({
          name: AUTH_FLAG_KEY,
          value: '1',
          // Set cookie 3 months
          age: AUTH_FLAG_KEY_EXPIRATION_AGE,
        });

        setIsAuthenticated(true);
        setToken(accessToken);

        router.push('/choose-account');
      }
    },
    onError: ({ message, statusCode }) => {
      if (statusCode !== 422) {
        addNotification({
          message,
          type: 'danger',
        });
      }
    },
  });

  function login(values: LoginParam) {
    if (typeof window !== 'undefined') {
      window.fcWidget?.user.clear();
    }
    return loginQuery.mutateAsync(values);
  }

  function logout() {
    revokeToken.mutateAsync().finally(() => {
      localStorage.removeItem(HIDE_WALLET_KEY);
      localStorage.removeItem(UPDATE_DATA_DRAFT_KEY);
      if (isNativeApp()) {
        sendNativeMessage({
          name: NativeWebViewBridgeEventName.REFRESH_TOKEN_INVALID,
        });

        return;
      }
      setToken(null);
      setIsAuthenticated(false);
      deleteAllLocalToken();
      localStorage.removeItem('CLIENT_ABAD');
      // quickfix : handle to reload pages to landing
      queryClient.removeQueries();
      deleteItemInCookies(AUTH_FLAG_KEY);

      addNotification({
        type: 'success',
        message: t('auth:logout_message'),
      });

      // Notes : Clear Freshchat user when logout to be reinitialized
      if (window?.fcWidget?.isInitialized()) {
        window.fcWidget.user.clear();
      }
    });
  }

  const useAuthContextBag: AuthContextInterface = {
    loginQuery,
    login,
    logout,
    token,
    isAuthenticated,
    setIsAuthenticated,
  };

  return (
    <AuthContext.Provider value={useAuthContextBag || {}}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const ctx = useContext(AuthContext);

  assertContextCalledInProvider<AuthContextInterface>(ctx);

  return ctx;
}
