import { useRef, useEffect } from 'react';

interface UsePreviousOptions {
  withInitialValue?: boolean;
}

export default function usePrevious<T>(
  value: T,
  options: UsePreviousOptions = { withInitialValue: true },
): T | any {
  const { withInitialValue = true } = options;
  const ref = useRef<T | null>(withInitialValue ? value : null);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}
