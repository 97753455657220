import {
  AccountRole,
  DASHBOARD_TYPE,
  useGetMe,
  UserBusiness,
} from '@api-hooks/common';
import { useGetWalletBalance } from '@api-hooks/wallet';
import { AuthorizationRules } from '@common/rules';
import { css } from '@emotion/css';
import { usePermission } from '@hooks';
import AnimatedSidebarSendSquareButton from '@layouts/dashboard/sidebar/AnimatedSidebarSendSquareButton';
import SideBarHelpButton from '@layouts/dashboard/sidebar/SideBarHelpButton';
import { RoutesListType } from '@layouts/dashboard/sidebar/type';
import { HIDE_WALLET_KEY } from '@modules/wallet/constants';
import { formatCurrency } from '@modules/wallet/helpers';
import { useWalletStore } from '@stores';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import {
  TransactionFill,
  TransactionOutline,
  GiftFill,
  GiftOutline,
  HomeFill,
  HomeOutline,
  ManageMemberFill,
  ManageMemberOutline,
  PaperPlaneFill,
  PaperPlaneOutline,
  ProfileFill,
  ProfileOutline,
  WalletFill,
  WalletOutline,
  TrackPaymentFill,
  TrackPaymentOutline,
  Eye,
} from '@topremit/shared-web/components/shapes';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useShallow } from 'zustand/react/shallow';

export const useRoutes = () => {
  const { t } = useTranslation();
  const { data: me } = useGetMe<UserBusiness>();
  const { can, isBusinessAccount, isRemittanceAccount } = usePermission();
  const isShowSendMoneyButton = can(AuthorizationRules.MenusSendMoney);
  const { data: _walletBalance, refetch: refetchWalletBalance } =
    useGetWalletBalance();
  const { hideWallet, setHideWallet } = useWalletStore(
    useShallow((state) => state),
  );
  const isTableSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const dashboardType = me?.data?.dashboardType;
  const roles = me?.data?.roles;
  const isReporting = roles?.includes(AccountRole.REPORTING);

  const currency = _walletBalance?.data?.currency || 'IDR';
  const walletBalance = hideWallet
    ? `${currency} •••••`
    : formatCurrency({
        currency,
        amount: _walletBalance?.data?.balance || 0,
      });

  const toggleHideWalletBalance = () => {
    const newValue = !hideWallet;
    setHideWallet(newValue);
    localStorage.setItem(HIDE_WALLET_KEY, JSON.stringify(newValue));
  };

  const canAccessActivity =
    !!can(AuthorizationRules.MenusTransactionHistory) &&
    !!can(AuthorizationRules.MenusTrackPayments) &&
    isTableSize;
  const canAccessWallet = can(AuthorizationRules.WalletsIndex);

  const routes: RoutesListType = {
    PERSONAL: {
      header: <AnimatedSidebarSendSquareButton key="send-money" />,
      body: [
        {
          activeIcon: <HomeFill fill={color.blue500} />,
          href: '/home',
          inactiveIcon: <HomeOutline />,
          label: t('home'),
          permitted: true,
        },
        {
          activeIcon: <TransactionFill fill={color.blue500} />,
          groupURLPath: '/activity',
          href: '/activity',
          inactiveIcon: <TransactionOutline />,
          label: t('activity'),
          permitted: true,
          subMenu: [
            {
              href: '/activity/transaction',
              label: t('transaction'),
              permitted: true,
            },
            {
              href: '/activity/refund',
              label: t('refund'),
              permitted: true,
            },
          ],
        },
        {
          activeIcon: <PaperPlaneFill fill={color.blue500} />,
          bottomBarOnly: true,
          href: '/quote',
          inactiveIcon: <PaperPlaneOutline />,
          label: t('send'),
          permitted: true,
        },
        {
          activeIcon: <GiftFill fill={color.blue500} />,
          href: '/referral',
          inactiveIcon: <GiftOutline />,
          label: t('referral_short'),
          permitted: true,
        },
        {
          activeIcon: <ProfileFill fill={color.blue500} />,
          href: '/profile',
          inactiveIcon: <ProfileOutline />,
          label: t('profile'),
          permitted: true,
        },
      ],
      footer: [<SideBarHelpButton key="help-button" />],
    },
    BUSINESS: {
      header:
        !isRemittanceAccount && isShowSendMoneyButton ? ( //temporary changed to handle remittance account, might be revert back to permission
          <AnimatedSidebarSendSquareButton key="send-money" />
        ) : (
          <></>
        ),
      body: [
        {
          activeIcon: <WalletOutline fill={color.blue500} />,
          bold: true,
          bordered: true,
          category: (
            <Flex>
              <Text>{t('wallet')}</Text>
              <Eye
                style={{ marginLeft: '4px' }}
                eyetype={hideWallet ? 'closed' : 'open'}
                icontype="outline"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleHideWalletBalance();
                }}
                width={16}
                height={16}
              />
            </Flex>
          ),
          // Note: This is for showing angle in Category , if this true , label angle will be hide
          categoryArrow: true,
          className: css`
            > div:first-of-type {
              > div:last-of-type {
                > div {
                  > span {
                    font-size: ${typography.body3Bold.fontSize}rem;
                    line-height: ${typography.body3Bold.lineHeight}px;
                    font-weight: ${typography.body3Bold.fontWeight};
                  }
                }
              }
            }
          `,
          expandIcon: <WalletFill />,
          groupURLPath: '/wallet',
          href: '/wallet',
          inactiveIcon: <WalletOutline />,
          label: walletBalance || '-',
          permitted: !!can(AuthorizationRules.WalletsIndex),
          sidebarOnly: true,
          subMenu: [
            {
              href: '/wallet/top-up',
              label: t('top_up'),
              permitted: !!can(AuthorizationRules.MenusTopUp),
            },
            {
              href: '/wallet/withdraw',
              label: t('withdraw'),
              permitted: !!can(AuthorizationRules.MenusWithdraw),
            },
            {
              href: '/wallet/wallet-history',
              label: t('wallet_history'),
              permitted: !!can(AuthorizationRules.MenusWalletHistory),
            },
          ],
        },
        {
          activeIcon: <WalletFill fill={color.blue500} />,
          bottomBarOnly: true,
          href: '/wallet',
          inactiveIcon: <WalletOutline />,
          label: t('wallet'),
          permitted: !!can(AuthorizationRules.WalletsIndex),
        },
        {
          activeIcon: <TransactionFill fill={color.blue500} />,
          href: '/activity/transaction',
          inactiveIcon: <TransactionOutline />,
          label:
            isReporting && dashboardType === DASHBOARD_TYPE.BOTH
              ? t('activity')
              : t('transaction'),
          permitted:
            !!can(AuthorizationRules.MenusTransactionHistory) &&
            !can(AuthorizationRules.MenusTrackPayments),
        },
        {
          activeIcon: <TransactionFill fill={color.blue500} />,
          href: canAccessActivity ? '/activity' : '/activity/transaction',
          groupURLPath: canAccessActivity
            ? '/activity'
            : '/activity/transaction',
          inactiveIcon: <TransactionOutline />,
          label: t('activity'),
          permitted:
            !!can(AuthorizationRules.MenusTransactionHistory) &&
            !!can(AuthorizationRules.MenusTrackPayments),
          subMenu: [
            {
              href: '/activity/transaction',
              label: t('transaction'),
              permitted: !!can(AuthorizationRules.MenusTransactionHistory),
            },
            {
              href: '/activity/track-payment',
              label: t('track_payment'),
              permitted: !!can(AuthorizationRules.MenusTrackPayments),
            },
          ],
        },
        {
          activeIcon: <TrackPaymentFill fill={color.blue500} />,
          href: '/activity/track-payment',
          inactiveIcon: <TrackPaymentOutline />,
          label: t('track_payment'),
          labelMobile: t('track'),
          permitted:
            !can(AuthorizationRules.MenusTransactionHistory) &&
            !!can(AuthorizationRules.MenusTrackPayments),
        },
        {
          activeIcon: <PaperPlaneFill fill={color.blue500} />,
          bottomBarOnly: true,
          href: '/quote',
          inactiveIcon: <PaperPlaneOutline />,
          label: t('send'),
          permitted: !!can(AuthorizationRules.MenusSendMoney),
        },
        {
          activeIcon: <ManageMemberFill fill={color.blue500} />,
          href: '/manage-member',
          inactiveIcon: <ManageMemberOutline />,
          label: t('manage_members'),
          labelMobile: t('manage'),
          permitted: !!can(AuthorizationRules.MenusManageMember),
        },
        {
          activeIcon: <ProfileFill fill={color.blue500} />,
          href: '/profile',
          inactiveIcon: <ProfileOutline />,
          label: t('profile'),
          permitted: true,
        },
      ],
      footer: [],
    },
  };

  useEffect(() => {
    const localValue = localStorage.getItem(HIDE_WALLET_KEY);
    setHideWallet(localValue === null ? true : localValue === 'true');
  }, []);

  useEffect(() => {
    if (isBusinessAccount && canAccessWallet) {
      refetchWalletBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBusinessAccount, canAccessWallet]);

  useEffect(() => {
    const handleFocusWindow = () => {
      const hasAccessWallet = can(AuthorizationRules.WalletsIndex);
      if (isBusinessAccount && hasAccessWallet) {
        refetchWalletBalance();
      }
    };

    window.addEventListener('focus', handleFocusWindow);

    return () => {
      window.removeEventListener('focus', handleFocusWindow);
    };
  }, [isBusinessAccount, refetchWalletBalance, can]);

  useIdleTimer({
    onIdle: () => {
      // Note: Handle user inactivty for 30 minutes
      if (!hideWallet) {
        toggleHideWalletBalance();
      }
    },
    timeout: 30 * 60 * 1000, // Note: 30 Minutes
    throttle: 500,
  });

  return {
    routes: isBusinessAccount ? routes.BUSINESS : routes.PERSONAL,
  };
};
