import { css, cx } from '@emotion/css';
import { screenSize } from '@global-common/size';
import ErrorMessage from '@global-elements-utils/ErrorMessage';
import { useMeasure } from '@global-hooks';
import { Angle } from '@global-shapes';
import { color } from '@topremit/shared-web/styles/color';
import * as React from 'react';

import { SelectInternalStyle } from './select';
import { State, useSelect } from './select-provider';
const DEFAULT_MIN_WIDTH = 120;

const borderColor: Record<State, string> = {
  active: color.blue500,
  error: color.red500,
  disabled: color.neutral200,
  default: color.neutral200,
};

const backgroundColor: Record<State, string> = {
  active: color.neutral0,
  error: color.neutral0,
  disabled: color.neutral100,
  default: color.neutral0,
};

const borderSize: Record<State, string> = {
  active: '2px',
  error: '2px',
  disabled: '1px',
  default: '1px',
};

const arrowColor: Record<State, string> = {
  active: color.neutral500,
  error: color.red500,
  disabled: color.neutral300,
  default: color.neutral500,
};

export const SelectButton = React.forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void;
    style?: React.CSSProperties;
    className?: string;
    styles?: SelectInternalStyle;
    id?: string;
  }
>(({ onClick, style, className, styles, id }, ref) => {
  const {
    items,
    value,
    name,
    state,
    fullWidth,
    label,
    renderValue,
    isOpen,
    error,
    helperText,
  } = useSelect();
  const labelRef = React.useRef<HTMLLabelElement>(null);
  const valueRef = React.useRef<HTMLDivElement>(null);

  const { width: labelWidth } = useMeasure(labelRef);
  const { width: valueWidth } = useMeasure(valueRef);

  const selectedChild = React.useMemo(() => {
    return items.find(
      (
        item,
      ): item is React.ReactElement<{
        value: string;
        children: React.ReactNode;
      }> =>
        React.isValidElement(item) &&
        String(item.props.value) === String(value),
    )?.props.children;
  }, [items, value]);

  const hasValue = value !== '' && !!selectedChild;
  const minWidth = labelWidth === 0 ? DEFAULT_MIN_WIDTH : labelWidth;

  return (
    <div
      style={style}
      className={cx(className, {
        [styled.fullWidth]: fullWidth,
      })}
    >
      <button
        id={id}
        ref={ref}
        role="combobox"
        aria-expanded={isOpen}
        className={cx(
          styled.button({ minWidth, state }),
          {
            [styled.fullWidth]: fullWidth,
          },
          styles?.button,
        )}
        onClick={state !== 'disabled' ? onClick : undefined}
        type="button"
      >
        <label
          ref={labelRef}
          className={styled.label({ hasValue, state, width: valueWidth })}
        >
          {label}
        </label>

        <input
          type="hidden"
          name={name}
          value={value}
          disabled={state === 'disabled'}
        />
        <div
          ref={valueRef}
          className={cx(
            styled.value({ hasLabel: !!label, state }),
            styles?.inputContainer,
          )}
        >
          {renderValue ? renderValue(value) : selectedChild}
        </div>

        <div
          className={cx(styled.rightIconContainer, styles?.rightIconContainer)}
        >
          <Angle
            direction={isOpen ? 'up' : 'down'}
            size="small"
            fill={arrowColor[state]}
          />
        </div>
      </button>

      {!!error && state !== 'disabled' && <ErrorMessage>{error}</ErrorMessage>}
      {!!helperText && state !== 'error' && (
        <span className={styled.message}>{helperText}</span>
      )}
    </div>
  );
});

const styled = {
  fullWidth: css`
    width: 100%;
  `,
  button: ({ minWidth, state }: { minWidth: number; state: State }) => {
    return css`
      box-sizing: border-box;
      position: relative;
      background-color: ${backgroundColor[state]};
      height: 62px;
      min-width: ${minWidth}px;
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1rem;
      align-items: center;
      justify-items: start;
      padding-right: 1.875rem;
      border: ${borderSize[state]} solid ${borderColor[state]};
      border-radius: 8px;
      cursor: ${state === 'disabled' ? 'not-allowed' : 'pointer'};

      &:hover {
        outline-color: ${state === 'default' ? color.neutral500 : undefined};
      }
    `;
  },
  label: ({
    hasValue,
    state,
    width,
  }: {
    hasValue: boolean;
    state: State;
    width: number;
  }) => {
    const fontColor: Record<State, string> = {
      error: color.red500,
      disabled: color.neutral300,
      default: color.neutral500,
      active: color.neutral500,
    };

    return css`
      position: absolute;
      text-align: start;
      left: 1.25rem;
      color: ${fontColor[state]};
      font-size: ${hasValue ? '0.875rem' : '1rem'};
      top: ${hasValue ? '30%' : '50%'};
      transform: translateY(-50%) translateZ(0px);
      transition: all 300ms;
      white-space: nowrap;

      overflow: hidden;
      text-overflow: ellipsis;
      width: ${width}px;

      @media (max-width: ${screenSize.tabletMd}px) {
        font-size: ${hasValue ? '0.75rem' : '0.875rem'};
      }
    `;
  },
  value: ({ hasLabel, state }: { hasLabel: boolean; state: State }) => css`
    position: relative;
    width: 100%;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1rem;
    padding: ${hasLabel ? '1.5625rem 1.25rem 0.3125rem' : '1.25rem'};
    color: ${state === 'disabled' ? color.neutral300 : color.neutral800};

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.875rem;
    }
  `,
  message: css`
    display: inline-block;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: ${color.neutral500};

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 0.75rem;
    }
  `,
  rightIconContainer: css`
    display: flex;
    align-items: center;
  `,
};
