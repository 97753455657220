import { css, cx } from '@emotion/css';
import { color } from '@topremit/shared-web/styles/color';
import { HtmlHTMLAttributes } from 'react';

export function Dot({
  className,
  ...props
}: HtmlHTMLAttributes<HTMLDivElement>) {
  return <div className={cx(styled.root, className)} {...props} />;
}

export const styled = {
  root: css`
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 1.25rem;
    background: ${color.red500};
  `,
};
