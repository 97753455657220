import { css, cx } from '@emotion/css';
import { Tag, TagProps } from 'antd';
import { ReactNode } from 'react';

import { useTranslation } from '../../hooks';

export type StatusColor =
  | 'yellow'
  | 'green'
  | 'red'
  | 'black'
  | 'gray'
  | 'orange'
  | 'blue';

export type StatusType =
  | 'approval'
  | 'payment'
  | 'inProgress'
  | 'success'
  | 'finished'
  | 'fail'
  | 'expired'
  | 'canceled'
  | 'inReview'
  | 'active';

type Omitted =
  | 'icon'
  | 'closeIcon'
  | 'closeable'
  | 'onClose'
  | 'message'
  | 'color'
  | 'children';

type Size = 'large' | 'medium' | 'small';

type Style = {
  [key in StatusColor]: {
    background: string;
    color: string;
    fontSize: string;
    padding: string;
  };
};

export interface IStatusProps extends Omit<TagProps, Omitted> {
  status?: StatusType; //change the Status style type with by status
  message?: string | ReactNode;
  size?: Size;
  innerClassName?: string;
  color?: StatusColor;
  icon?: ReactNode;
}

function Status(props: IStatusProps) {
  const {
    status = 'approval',
    size = 'medium',
    className,
    innerClassName,
    message,
    color,
    icon,
    ...resProps
  } = props;
  const { t } = useTranslation('transaction');

  const fontSize = (() => {
    switch (size) {
      case 'large':
        return '1rem';
      case 'medium':
        return '0.875rem';
      case 'small':
        return '0.75rem';
    }
  })();

  const padding = (() => {
    switch (size) {
      case 'large':
        return '0.5rem 0.75rem';
      case 'medium':
        return '0.25rem 0.75rem';
      case 'small':
        return '0.25rem 0.5rem';
    }
  })();

  const style: Style = {
    yellow: {
      background: 'var(--yellow-100)',
      color: 'var(--yellow-500)',
      fontSize,
      padding,
    },
    green: {
      background: 'var(--green-100)',
      color: 'var(--green-500)',
      fontSize,
      padding,
    },
    red: {
      background: 'var(--red-100)',
      color: 'var(--red-500)',
      fontSize,
      padding,
    },
    gray: {
      background: 'var(--neutral-100)',
      color: 'var(--neutral-500)',
      fontSize,
      padding,
    },
    orange: {
      background: 'var(--orange-100)',
      color: 'var(--orange-500)',
      fontSize,
      padding,
    },
    black: {
      background: 'var(--neutral-0)',
      color: 'var(--neutral-800)',
      fontSize,
      padding,
    },
    blue: {
      background: 'var(--blue-100)',
      color: 'var(--blue-500)',
      fontSize,
      padding,
    },
  };

  const statusStyle = {
    approval: style.yellow,
    payment: style.yellow,
    inProgress: style.yellow,
    success: style.green,
    finished: style.green,
    fail: style.red,
    expired: style.gray,
    canceled: style.gray,
    inReview: style.orange,
    active: style.blue,
  };

  const _fontSize = color
    ? style[color].fontSize
    : statusStyle[status].fontSize;
  const _color = color ? style[color].color : statusStyle[status].color;
  const _background = color
    ? style[color].background
    : statusStyle[status].background;
  const _padding = color ? style[color].padding : statusStyle[status].padding;

  const messageClass = styled.messageClass(_fontSize, _color);

  const statusClass = cx(
    styled.statusClass(_background, _padding),
    className,
    innerClassName,
  );

  function renderStatusMessage() {
    switch (status) {
      case 'approval':
        return t('transaction.pills.need_approval');
      case 'payment':
        return t('transaction.pills.need_payment');
      case 'inProgress':
        return t('transaction.pills.in_progress');
      case 'success':
        return t('transaction.pills.success');
      case 'finished':
        return t('transaction.pills.finished');
      case 'fail':
        return t('transaction.pills.failed');
      case 'expired':
        return t('transaction.pills.expired');
      case 'canceled':
        return t('transaction.pills.canceled');
      case 'inReview':
        return t('transaction.pills.in_review');
    }
  }

  return (
    <Tag className={statusClass} closable={false} {...resProps}>
      {icon && <div className="icon">{icon}</div>}
      <div className={messageClass}>
        {message ? message : renderStatusMessage()}
      </div>
    </Tag>
  );
}

const styled = {
  statusClass: (background: string, padding: string) => css`
    display: flex;
    width: fit-content;
    border-radius: 3.125rem;
    background: ${background};
    padding: ${padding}!important;
    border: unset;
    .icon {
      display: flex;
      height: fit-content;
      margin-right: 0.25rem;
    }
  `,
  messageClass: (fontSize: string, color: string) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: var(--bold-font-weight);
    font-size: ${fontSize};
    color: ${color};
  `,
};

export { Status };
export default Status;
