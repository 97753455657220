import { css, cx } from '@emotion/css';
import { parseHtml } from '@topremit/shared-web/components/elements';
import { AnimatePresence } from 'framer-motion';
import {
  cloneElement,
  CSSProperties,
  forwardRef,
  HtmlHTMLAttributes,
  isValidElement,
  ReactNode,
  useRef,
  useState,
} from 'react';

import { AnimatedWrapper, ErrorMessage, IAnimatedWrapperProps } from '.';
import { useGetInnerWidth } from './helper';
import {
  callAllFunctions,
  handleInputCondition,
  mergeRefs,
} from '../../common/helper';
import { screenSize } from '../../common/size';
import { useTranslation } from '../../hooks';
import { color } from '../../styles/color';
import { Search } from '../shapes';

export interface IFieldGroupProps extends HtmlHTMLAttributes<HTMLDivElement> {
  className?: string;
  inputWrapperRef?: any;
  inputWrapperClassName?: string;
  labelClassName?: string;
  name?: string;
  label?: string;
  children: ReactNode;
  bordered?: boolean;
  isError?: boolean;
  error?: string | false | undefined;
  style?: CSSProperties;
  isFocus?: boolean;
  isFilled?: boolean;
  helper?: string | ReactNode;
  showCounter?: boolean;
  count?: number;
  maxCount?: number;
  disabled?: boolean;
  leftIcon?: ReactNode;
  showLeftIcon?: ReactNode;
  showRightIcon?: ReactNode;
  borderStyle?: CSSProperties;
  leftIconClassName?: string;
  rightIconClassName?: string;
  isTextArea?: boolean;
  leftIconInnerWrapperClassName?: string;
  rightIcon?: ReactNode;
  rightElement?: ReactNode;
  rightIconWrapperRef?: any;
  leftIconWrapperRef?: any;
  formControlProps?: Omit<IAnimatedWrapperProps<'div'>, 'htmlTag'>;
  isOptional?: boolean;
  showError?: boolean;
}

const styled = {
  formGroup: (bottomText) => css`
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: ${bottomText ? '0' : '8px'};
    border-bottom-right-radius: ${bottomText ? '0' : '8px'};
  `,
  iconWrapper: css`
    flex: 1;
    width: 100%;
    max-height: 64px;
    min-height: 64px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      word-break: unset;
      overflow-wrap: unset;
    }
  `,
  innerIconWrapper: css`
    height: auto;
    min-width: 24px;
    width: fit-content;
    padding-left: 20px;
    display: flex;
    align-items: center;
  `,
  border: css`
    z-index: 1;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-style: solid;
    user-select: none;
    pointer-events: none;
    border-radius: 8px;
  `,
  smallTextWrapper: css`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0.5rem;
  `,
  helperTextWrapper: css`
    flex: 1;
    display: flex;
    align-items: flex-start;
    text-align: left;
    font-size: var(--sm-font-size);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--xs-font-size);
    }
    .error-text {
      margin: 0;
    }
  `,
  helperTextWrapperWithCounter: css`
    margin-right: auto;
  `,
  counterWrapper: css`
    flex: 0;
    display: flex;
    justify-content: flex-end;
  `,
  textOptional: (disabled, isError) => css`
    color: ${disabled
      ? color.neutral300
      : isError
        ? color.red500
        : color.neutral500};
  `,
  rightElement: css`
    width: fit-content !important;
    color: var(--text-secondary);
    margin: 0.125rem 1.25rem 0.125rem 0;
  `,
};

const labelVariants = {
  default: (isTextArea) => ({
    top: isTextArea ? '20%' : '50%',
    y: '-50%',
    transition: {
      duration: 0.05,
    },
  }),
  hovered: (isTextArea) => ({
    top: isTextArea ? '20%' : '50%',
    y: '-50%',
    transition: {
      duration: 0.05,
    },
  }),
  filled: (isTextArea) => ({
    top: isTextArea ? '12%' : '30%',
    y: '-50%',
    transition: {
      duration: 0.05,
    },
  }),
  focus: (isTextArea) => ({
    top: isTextArea ? '12%' : '30%',
    y: '-50%',
    transition: {
      duration: 0.05,
    },
  }),
};

const variants = {
  default: {
    borderWidth: '1px',
    borderColor: color.neutral200,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.2,
    },
  },
  hovered: {
    borderWidth: '1px',
    borderColor: color.neutral500,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.2,
    },
  },
  error: {
    borderWidth: '2px',
    borderColor: color.red500,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.2,
    },
  },
  focus: {
    borderWidth: '2px',
    borderColor: color.blue500,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.2,
    },
  },
};

const smallTextVariants = {
  error: {
    opacity: 1,
    height: 'auto',
    minHeight: '17px',
    color: color.red500,
  },
  helper: {
    opacity: 1,
    height: 'auto',
    minHeight: '17px',
    color: color.neutral500,
  },
  default: {
    opacity: 0,
    height: 0,
    minHeight: 0,
    color: color.neutral800,
  },
};

const counterVariants = {
  visible: {
    opacity: 1,
    width: '100%',
  },
  hidden: {
    opacity: 0,
    width: 0,
  },
};

const FieldGroup = forwardRef((props: IFieldGroupProps, ref: any) => {
  const {
    className,
    inputWrapperClassName,
    label,
    labelClassName,
    children,
    bordered,
    error,
    helper,
    isError,
    style,
    isFocus,
    isFilled,
    showCounter,
    count,
    maxCount,
    disabled,
    leftIcon = <Search width={24} height={24} />,
    showLeftIcon,
    showRightIcon,
    borderStyle,
    onBlur,
    rightIconClassName,
    leftIconClassName,
    leftIconInnerWrapperClassName,
    rightIcon,
    rightElement,
    inputWrapperRef,
    isTextArea = false,
    rightIconWrapperRef,
    leftIconWrapperRef,
    showError = true,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    formControlProps,
    isOptional,
    ...resProps
  } = props;

  const { t } = useTranslation();
  const formControlRef = useRef<any>(null);
  const labelWidth = useGetInnerWidth(formControlRef.current);

  const [isHovered, setHovered] = useState(false);

  const showErrorMessage = isError && !!error && !disabled && showError;

  const groupClass = cx(
    'form-group',
    styled.formGroup(showCounter || !!helper || isError),
    className,
  );
  const inputWrapperClass = cx('input-wrapper', inputWrapperClassName, {
    borderless: !bordered,
    disabled,
  });
  const labelClass = cx(
    'form-label',
    {
      disabled,
      active: isFilled || isFocus,
      inactive:
        (isHovered && !isFilled && !isFocus) ||
        (!isHovered && !isFilled && !isFocus),
      error: isError,
    },
    labelClassName,
  );
  const rigtIconWrapperClass = cx(styled.iconWrapper, rightIconClassName);
  const leftIconWrapperClass = cx(styled.iconWrapper, leftIconClassName);
  const leftInnerIconWrapperClass = cx(
    styled.innerIconWrapper,
    leftIconInnerWrapperClassName,
  );

  function renderLeftIcon() {
    if (leftIcon) {
      let cloned;
      if (isValidElement(leftIcon)) {
        cloned = cloneElement<any>(leftIcon, {
          fill: disabled ? color.neutral300 : color.neutral500,
        });
      }

      return cloned || leftIcon;
    }
  }

  return (
    <div ref={ref} style={{ ...style, ...borderStyle }} className={groupClass}>
      <div
        ref={inputWrapperRef}
        onBlur={onBlur}
        onMouseEnter={callAllFunctions(onMouseEnter, () => setHovered(true))}
        onMouseLeave={callAllFunctions(onMouseLeave, () => setHovered(false))}
        onFocus={onFocus}
        onClick={onClick}
        className={inputWrapperClass}
        {...resProps}
      >
        <AnimatePresence>
          {bordered && (
            <AnimatedWrapper
              initial="default"
              animate={
                disabled
                  ? 'default'
                  : handleInputCondition({
                      isError,
                      isFilled,
                      isFocus,
                      isHovered,
                    })
              }
              exit="exit"
              className={styled.border}
              style={borderStyle}
              variants={variants}
              htmlTag="div"
            />
          )}
        </AnimatePresence>

        <AnimatePresence>
          {showLeftIcon && (
            <AnimatedWrapper
              initial="hidden"
              animate="visible"
              exit="hidden"
              htmlTag="div"
              variants={counterVariants}
              className={leftIconWrapperClass}
            >
              <div
                ref={leftIconWrapperRef}
                className={leftInnerIconWrapperClass}
              >
                {renderLeftIcon()}
              </div>
            </AnimatedWrapper>
          )}
        </AnimatePresence>
        <AnimatedWrapper
          {...formControlProps}
          initial="default"
          innerRef={mergeRefs(formControlRef, formControlProps?.innerRef)}
          htmlTag="div"
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
            ...formControlProps?.style,
          }}
          id="form-control-wrapper"
          onClick={(e) => e.preventDefault()}
        >
          <AnimatePresence>
            {!!label && (
              <AnimatedWrapper
                initial="default"
                htmlTag="label"
                animate={handleInputCondition({
                  isFilled,
                  isFocus,
                  isHovered,
                })}
                style={{
                  width: labelWidth,
                }}
                variants={labelVariants}
                className={labelClass}
                custom={isTextArea}
              >
                {label + ' '}
                {isOptional && (
                  <span className={styled.textOptional(disabled, isError)}>
                    ({t('optional')})
                  </span>
                )}
              </AnimatedWrapper>
            )}
          </AnimatePresence>
          {children}
        </AnimatedWrapper>
        <AnimatePresence>
          {showRightIcon && (
            <AnimatedWrapper
              initial="hidden"
              animate="visible"
              exit="hidden"
              htmlTag="div"
              innerRef={rightIconWrapperRef}
              variants={counterVariants}
              className={rigtIconWrapperClass}
            >
              {rightIcon}
            </AnimatedWrapper>
          )}
          {rightElement && (
            <AnimatedWrapper
              initial="hidden"
              animate="visible"
              exit="hidden"
              htmlTag="div"
              className={styled.rightElement}
            >
              {rightElement}
            </AnimatedWrapper>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {(showErrorMessage || !!helper || showCounter) && (
          <AnimatedWrapper
            htmlTag="div"
            initial="default"
            animate={isError && !disabled ? 'error' : 'helper'}
            exit="default"
            variants={smallTextVariants}
            className={styled.smallTextWrapper}
          >
            <div
              className={cx('helper-text-wrapper', styled.helperTextWrapper, {
                [styled.helperTextWrapperWithCounter]: showCounter,
              })}
            >
              <span className="helper-text">
                {isError && !disabled ? (
                  <ErrorMessage className="error-text">
                    {parseHtml(String(error))}
                  </ErrorMessage>
                ) : (
                  helper
                )}
              </span>
            </div>
            <AnimatePresence>
              {showCounter && (
                <AnimatedWrapper
                  htmlTag="div"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={counterVariants}
                  className={cx(styled.counterWrapper, 'xs-text')}
                >
                  <span>
                    {count}/{maxCount}
                  </span>
                </AnimatedWrapper>
              )}
            </AnimatePresence>
          </AnimatedWrapper>
        )}
      </AnimatePresence>
    </div>
  );
});

export { FieldGroup };
