import { create } from 'zustand';

import { SetStateStore, setStore } from './helper';

interface IKycStore {
  isMutating: boolean;
  showPreviewPersonalId: boolean;
  setIsMutating: (value: SetStateStore<boolean>) => void;
  setShowPreviewPersonalId: (value: SetStateStore<boolean>) => void;
}

const initialState = {
  isMutating: false,
  showPreviewPersonalId: false,
};

export const useKycStore = create<IKycStore>((set) => ({
  ...initialState,
  setIsMutating: (value) =>
    set((state) => setStore(state, { isMutating: value })),
  setShowPreviewPersonalId: (value) =>
    set((state) => setStore(state, { showPreviewPersonalId: value })),
  resetStore: () => set(initialState),
}));
