export const ACCESS_TOKEN_KEY = 'access-token';

export const REFRESH_TOKEN_KEY = 'refresh-token';

export const PHONE_NUMBER_VALUES_KEY = 'phone-number-values';

export const RESET_TOKEN_KEY = 'reset-token';

export const UNCOMPLETED_QUOTATION_KEY = 'tr-uqt';

export const SYNC_FORM_KEY = '_SyncForm';

export const SYNC_AFFILIATE_FORM_KEY = '_SyncFormAffiliate';

export const FORGET_PASSWORD_EMAIL = 'forget-password-email';

export const FORGET_PASSWORD_PHONE_NUMBER = 'forget-password-phone-number';

export const USER_ID_KEY = 'user-id';

export const API_VERSION = '2.0.0';

export const FRESHCHAT_NOTIFICATION_KEY = 'freshchat-notification';

export const COACHMARK = 'coachmark';

export const CURRENT_ACCOUNT_KEY = 'current-account';

export const RATE_ALERT_KEY = 'rate-alert-calc';

export const CREATE_WITHDRAW_KEY = 'create-withdraw-value';

export const SHOW_BLOCKING_MODAL = 'show-blocking-modal';

export const MOBILE_VERSION = 6000269;
