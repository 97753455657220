import {
  UpdateDataDocumentForm,
  UpdateDataDraftResponse,
  UpdateDataForm,
  UpdateDataFormPayload,
} from '../types';

type DocumentType = Record<string, UpdateDataDocumentForm[]>;

export function mapToUpdateFormData(
  data: UpdateDataDraftResponse,
): Partial<UpdateDataForm> {
  const documentObject: DocumentType = {};
  const documentErrorObject: DocumentType = {};

  data?.documents?.forEach((item) => {
    documentObject[item.documentName.value] = item?.url
      ? [
          {
            url: item.rejectReason ? undefined : item.url,
            name: item.rejectReason ? undefined : item.filename,
            uid: item.rejectReason ? undefined : item.url,
            size: item.rejectReason ? undefined : item.size,
            status: item.status?.value ? item.status : undefined,
            rejectReason: item.rejectReason,
          },
        ]
      : [];

    documentErrorObject[item.documentName.value] = item.rejectReason
      ? [{ rejectReason: item.rejectReason }]
      : [];
  });

  const hasDocumentError = data?.documents?.some(
    (document) => !!document.rejectReason,
  );

  return {
    //Personal
    occupation: data.occupation,
    //Business
    businessIndustry: data.businessIndustry,
    businessDescription: data.businessDescription,
    structureChanged: data.structureChanged,
    clientHolders: data.clientHolders,
    documents: documentObject,

    ...(hasDocumentError
      ? {
          documents_error: documentErrorObject,
        }
      : {}),

    //BOTH
    country: data.country,
    province: data.province,
    city: data.city,
    address: data.address,
    postalCode: data.postalCode,
    pep: data.pep,
  };
}

export function mapToUpdateFormDataPayload(
  data: UpdateDataForm,
  isPersonal?: boolean,
): UpdateDataFormPayload {
  const { documents, isOtherAddress, ...postData } = data;
  const documentKeys = Object.keys(documents || {});
  const mappedDocuments = documentKeys.map((key) => {
    const file = data.documents?.[key][0] as UpdateDataDocumentForm;

    return {
      document: key,
      filename: file?.name ?? '',
      size: file?.size ?? 0,
      url: (file?.s3Url || file?.url) ?? '',
    };
  });
  return {
    ...postData,
    ...(isPersonal || !data?.structureChanged
      ? {}
      : { documents: mappedDocuments }),
  };
}
