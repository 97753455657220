import { SVGProps } from 'react';

function Search(props: SVGProps<SVGSVGElement>) {
  const { fill = 'var(--neutral-500)', width = 24, height = 24 } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="#8295B5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2330_9630)">
        <path
          d="M21.7558 20.5776L16.7816 15.6034C18.1371 13.9456 18.8036 11.8301 18.6431 9.6947C18.4827 7.55926 17.5076 5.56717 15.9196 4.1305C14.3316 2.69382 12.2521 1.92247 10.1113 1.976C7.97052 2.02953 5.93219 2.90383 4.41795 4.41807C2.90371 5.93232 2.0294 7.97064 1.97588 10.1114C1.92235 12.2522 2.6937 14.3317 4.13037 15.9197C5.56705 17.5077 7.55913 18.4828 9.69458 18.6433C11.83 18.8037 13.9454 18.1372 15.6033 16.7817L20.5774 21.7559C20.7346 21.9077 20.9451 21.9917 21.1636 21.9898C21.3821 21.9879 21.5911 21.9003 21.7456 21.7457C21.9001 21.5912 21.9878 21.3822 21.9897 21.1637C21.9916 20.9452 21.9076 20.7347 21.7558 20.5776ZM10.3333 17.0001C9.01473 17.0001 7.7258 16.6091 6.62947 15.8765C5.53314 15.144 4.67866 14.1028 4.17408 12.8846C3.66949 11.6664 3.53747 10.326 3.7947 9.03279C4.05194 7.73959 4.68688 6.5517 5.61923 5.61935C6.55158 4.687 7.73947 4.05206 9.03267 3.79483C10.3259 3.53759 11.6663 3.66961 12.8845 4.1742C14.1027 4.67878 15.1439 5.53327 15.8764 6.62959C16.6089 7.72592 16.9999 9.01485 16.9999 10.3334C16.998 12.1009 16.2949 13.7954 15.0451 15.0452C13.7953 16.2951 12.1008 16.9981 10.3333 17.0001Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_9630">
          <rect
            width={20}
            height={20}
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Search;
