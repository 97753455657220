import { Language } from '@global-api-hooks/common';
import { NativeWebViewBridgeEventName } from '@global-typings/native-web-view-bridge.model';
import CryptoJS from 'crypto-js';
import { isBefore } from 'date-fns';
import addSeconds from 'date-fns/addSeconds';
import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import { enUS, id as idLocale } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import Cookies from 'js-cookie';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import Router from 'next/router';
import { Translate } from 'next-translate';

import {
  DEFAULT_TIMEZONE,
  TIMELINE_YEAR_TO_DATE_FORMAT,
} from './../../landing/src/common/constant';
import { isNativeApp, sendNativeMessage } from './native-web-view-bridge';

declare global {
  interface Window {
    [key: string]: any;
  }
}

type DurationToken =
  | 'lessThanXSeconds'
  | 'xSeconds'
  | 'halfAMinute'
  | 'lessThanXMinutes'
  | 'xMinutes'
  | 'aboutXHours'
  | 'xHours'
  | 'xDays';

export function joinText(
  arr: string[],
  separator: string,
  lastSeparator?: string,
) {
  if (lastSeparator) {
    let result = arr.slice(0, arr.length - 1).join(separator);
    return (result += `${separator}${lastSeparator}${arr[arr.length - 1]}`);
  } else {
    return arr.join(separator);
  }
}

const formatDistanceLocale = (t: Translate, token: DurationToken, count) => {
  switch (token) {
    case 'lessThanXSeconds':
    case 'xSeconds':
      return t(`seconds`, { count });
    case 'halfAMinute':
    case 'lessThanXMinutes':
    case 'xMinutes':
      return t(`minutes`, { count });
    case 'aboutXHours':
    case 'xHours':
      return t(`hours`, { count });
    case 'xDays':
      return t(`days`, { count });
  }
};

function customFormatDistance(token: DurationToken, count, options: any = {}) {
  const result = formatDistanceLocale(options.t, token, count);
  return result;
}

export function formatTimeDurationToReadableFormat({
  t,
  start = 0,
  end = 0,
  format,
  delimiter,
  locale,
}: {
  t: Translate;
  start?: number | Date;
  end?: number | Date;
  format: ('hours' | 'minutes' | 'days' | 'seconds')[];
  delimiter?: string;
  locale: string;
}) {
  return formatDuration(
    intervalToDuration({
      start,
      end,
    }),
    {
      format,
      delimiter,
      locale: {
        code: locale,
        formatDistance: (token: DurationToken, count, options) =>
          customFormatDistance(token, count, { ...options, t }),
      },
    },
  );
}

export function formatDate({
  date,
  formatDate = TIMELINE_YEAR_TO_DATE_FORMAT,
  timeZone = DEFAULT_TIMEZONE,
  showTimeZoneName,
  timeZoneWrapper = { start: '', end: '' },
  locale = 'en',
}: {
  date: string | Date;
  formatDate?: string;
  timeZone?: string;
  showTimeZoneName?: boolean;
  timeZoneWrapper?: { start: string; end: string };
  locale?: Language;
}) {
  try {
    if (isStringEmpty(date)) return '';

    const locales = {
      en: enUS, // Locale for English (United States)
      id: idLocale, // Locale for Indonesian
    };

    const parsedDate = new Date(date);

    // Format with timezone if provided
    if (timeZone) {
      // Always use 'en-US' for the time zone name to ensure "GMT", not localized (like "WIB")
      const timeZoneName = new Intl.DateTimeFormat('en-US', {
        timeZone,
        timeZoneName: 'short',
      })
        .formatToParts(parsedDate)
        .find((part) => part.type === 'timeZoneName')?.value;

      const formattedDate = formatInTimeZone(parsedDate, timeZone, formatDate, {
        locale: locales[locale], // Translate the date part only
      });

      return `${formattedDate}${
        showTimeZoneName
          ? ` ${timeZoneWrapper.start}${timeZoneName}${timeZoneWrapper.end}`
          : ''
      }`;
    }

    // Format without timezone
    return format(parsedDate, formatDate, { locale: locales[locale] });
  } catch (e) {
    return String(date);
  }
}

export const callAllFunctions =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn?.(...args));

/**
 * @description documentation source: https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
 */
export const mergeRefs = (...refs) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export function handleInputCondition({
  isError,
  isFocus,
  isFilled,
  isHovered,
}: {
  isError?: boolean;
  isFocus?: boolean;
  isFilled?: boolean;
  isHovered?: boolean;
}) {
  if (isError) {
    return 'error';
  }

  if (isFocus) {
    return 'focus';
  }

  if (isFilled) {
    return 'filled';
  }

  if (isHovered) {
    return 'hovered';
  }

  return 'default';
}

export function isStringEmpty(text: string | any) {
  return text === '' || text === undefined || text === null;
}

export function setMobileCSSHeightProperty() {
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight * 0.01}px`,
  );
}

export function handleClickOutside(ref, state, setState, event) {
  if (ref.current && !ref.current.contains(event.target)) {
    if (state === true) {
      setState(false);
    }
  }
}

export function formatFirstLetter(text: string) {
  const arrStr = text?.split(' ');
  let formattedStr = '';
  for (const str of arrStr) {
    formattedStr +=
      str.charAt(0).toLocaleUpperCase() +
      String(str.substr(1)).toLocaleLowerCase() +
      ' ';
  }
  return formattedStr.trim();
}

export function countMultipleItemLength(state: any[] | { [key: string]: any }) {
  if (Array.isArray(state)) {
    return state.length;
  } else if (typeof state === 'object') {
    let count = 0;
    const keys = Object.keys(state);

    for (const key of keys) {
      count += state[key].length;
    }
    return count;
  }

  return 0;
}

export function detectWrap(className) {
  let prevItem: any = {};
  let currItem: any = {};
  let row: number = 1;
  const items: HTMLCollectionOf<Element> =
    document.getElementsByClassName(className);

  for (let i = 0; i < items.length; i++) {
    currItem = items[i].getBoundingClientRect();
    items[i].classList.forEach((className) => {
      if (className.includes('row-')) {
        items[i].classList.remove(className);
      }
    });
    if (prevItem && prevItem.top < currItem.top) {
      row += 1;
      items[i].classList.add(`row-${row}`);
      // return true;
    } else {
      items[i].classList.add(`row-${row}`);
    }
    prevItem = currItem;
  }

  return { totalRow: row, isWrap: row > 1 };
}

export function handleLongText(text: string, maxLength: number = 75) {
  const textLength = text.length;
  return textLength > maxLength ? `${text.substr(0, maxLength)}...` : text;
}

export function isMobileAndTablet() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
}

export function getItemInCookies(key: string) {
  return Cookies.get(key);
}

export function setItemToCookies(
  key: string,
  value: string,
  options?: { expires: number },
) {
  return Cookies.set(key, value, options);
}

export function deleteItemInCookies(key: string) {
  return Cookies.remove(key);
}

export const tree = (input: object) => {
  const _tree: string[] = [];

  function diveIn(obj: any, path?: string): void {
    path = path || '';
    for (const n in obj) {
      const nextPath = !isEmpty(path) ? path + '.' + n : n;
      const isUnfinished =
        (typeof obj[n] === 'object' || obj[n] instanceof Array) &&
        !isEmpty(obj[n]);

      if (!Object.prototype.hasOwnProperty.call(obj, n)) {
        continue; // skip
      }
      if (isUnfinished) {
        return diveIn(obj[n], nextPath);
      }

      _tree.push(nextPath); // finish
    }
  }
  diveIn(input);

  return _tree;
};

export function runIfFn<T, U>(
  valueOrFn: T | ((...fnArgs: U[]) => T),
  ...args: U[]
): T {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}

export function isFunction<T extends Function = Function>(
  value: any,
): value is T {
  return typeof value === 'function';
}

export function formattedinMinute(seconds) {
  const helperDate = addSeconds(new Date(0), seconds);
  return format(helperDate, 'mm:ss');
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValidJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function isEmptyObject(obj: any) {
  if (!obj) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export function areObjEqual(obj1: object, obj2: object) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function ObjToCssString(
  obj: Partial<Record<keyof CSSStyleDeclaration, number | string>>,
) {
  return Object.keys(obj).reduce(
    (acc, key) =>
      acc +
      key
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase() +
      ':' +
      obj[key] +
      ';',
    '',
  );
}

function getFilterObject(objVal) {
  if (typeof objVal === 'string') return objVal !== '';
  else if (Array.isArray(objVal)) return objVal.length !== 0;
  else if (objVal instanceof Object) return Object.keys(objVal).length !== 0;
  return objVal !== null && objVal !== undefined;
}

export function getNonEmptyStringObject(obj) {
  if (Array.isArray(obj)) {
    return obj
      .map((a) => getNonEmptyStringObject(a))
      .filter((a) => getFilterObject(a));
  } else if (obj instanceof Object) {
    const d = Object.entries(obj)
      .map((a) => [a[0], getNonEmptyStringObject(a[1])])
      .filter((a) => getFilterObject(a[1]));
    return Object.fromEntries(d);
  } else if (typeof obj === 'string') return obj !== '' ? obj : null;
  return obj !== null && obj !== undefined ? obj : null;
}

export function removeHTMLTags(html: string) {
  // Remove html tags including its nested tags and content inside
  return html.replace(/<[^>]*>/g, '');
}

export function getTimeDuration(t: Translate, isoDate: string) {
  const date = new Date(isoDate);
  const now = new Date();
  const timeDifference = date.getTime() - now.getTime();

  if (timeDifference < 0) {
    return null;
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  const labelDays = t('days');
  const labelHours = t('hours');
  const labelMinutes = t('minutes');

  // Add leading zeros using padStart
  const remainingDays = String(days).padStart(2, '0');
  const remainingHours = String(hours).padStart(2, '0');
  const remainingMinutes = String(minutes).padStart(2, '0');

  return {
    [labelDays]: remainingDays,
    [labelHours]: remainingHours,
    [labelMinutes]: remainingMinutes,
  };
}

export function scrollToElement(parent: Element, target: Element) {
  const parentRect = parent.getBoundingClientRect();
  const targetRect = target.getBoundingClientRect();
  const scrollPosition =
    targetRect.top - parentRect.top + parent.scrollTop - 20;
  parent.scrollTo({
    top: scrollPosition,
    behavior: 'smooth',
  });
}

export function isLowSpecDevice() {
  return (
    navigator.hardwareConcurrency <= 8 ||
    ('deviceMemory' in navigator && Number(navigator.deviceMemory) <= 4)
  );
}

/**
 * @note handle if can go back or not for native
 * @returns
 */
export function nativeBack(fallback: () => void) {
  if (isNativeApp()) {
    const nativeEntryPoint = localStorage.getItem('nativeEntryPoint');
    const path = Router.asPath.split('?')[0];
    if (nativeEntryPoint === path) {
      sendNativeMessage({ name: NativeWebViewBridgeEventName.BACK });
      return;
    }
  }
  fallback();
}

export function backOrFallback(fallback: () => void) {
  if (typeof window === 'undefined') return;

  if (window.history.length > 0) {
    return Router.back;
  }
  return fallback;
}

export function formatCurrency({
  amount,
  currency,
  fractionDigits = 0,
}: {
  amount: number;
  currency: string;
  fractionDigits?: number;
}) {
  return new Intl.NumberFormat('en-US', {
    currency,
    style: 'currency',
    currencyDisplay: 'code',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(amount); // e.g: 123123 -> IDR 123,123
}

export function formatToIDRCurrency(value: number, fractionDigits = 0) {
  return formatCurrency({ amount: value, currency: 'IDR', fractionDigits });
}

export function formatIDRToNumber(value: string) {
  return Number(String(value).split(' ')[1].replace(/[.,]/g, '')); // e.g: IDR 123,123 -> 123123
}

export function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function isValidObject(value: string) {
  try {
    const event = JSON.parse(value);
    if (isObject(event)) return true;
  } catch (e) {
    if (e) return false;
  }
}

export function getCurrentDate(timeZone = 'Asia/Jakarta') {
  const date = new Date(
    new Date().toLocaleString('en-US', {
      timeZone,
    }),
  );

  const year = date.getFullYear();
  const month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
  const day = (date.getDate() < 10 ? '0' : '') + date.getDate();

  return `${day}-${month}-${year}`;
}

export function encryptString(str: string, key: string) {
  return CryptoJS.AES.encrypt(str, key).toString();
}

export function decryptString(str: string, key: string) {
  const bytes = CryptoJS.AES.decrypt(str, key);
  return bytes.toString(CryptoJS.enc.Utf8);
}

/**
 * to get device top inset (status bar), bottom inset of iPhone (Home Indicator)
 * @note webview only
 */
export function getNativeInset(): { top: number; bottom: number } {
  if (typeof window === 'undefined') {
    return {
      top: 0,
      bottom: 0,
    };
  }

  return {
    top: window.nativeTopInset || 0,
    bottom: window.nativeBottomInset || 0,
  };
}

export function clampBuilder(
  minSizePx: number,
  maxSizePx: number,
  minWidthPx = 360,
  maxWidthPx = 1440,
) {
  const minWidth = minWidthPx / 16;
  const maxWidth = maxWidthPx / 16;

  const slope = (maxSizePx - minSizePx) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + minSizePx;

  return `clamp(${minSizePx}rem, ${yAxisIntersection}rem + ${
    slope * 100
  }vw, ${maxSizePx}rem)`;
}

export function getURLLastSegment(url: string) {
  // Create a URL object
  const urlObj = new URL(url);

  // Get the pathname from the URL object and split it by '/'
  const pathSegments = urlObj.pathname.split('/');

  // Return the last segment of the path
  return pathSegments[pathSegments.length - 1];
}

export function isEmptyArray(arr?: any[]) {
  return arr?.length === 0;
}

export function getSidebarWidth() {
  if (typeof window === 'undefined') {
    return 0;
  }
  const sideBar = document.getElementById('side-bar');

  const asideWidth = sideBar?.offsetWidth || 0;
  return asideWidth;
}

export function formatAsteriskToBold(input: string): string {
  const rgx = /\*(.*?)\*/g;
  // Replace *...* with <b>...</b>
  return input.replace(rgx, '<b>$1</b>');
}

/**
 * Creates a debounced function that delays invoking the provided function
 * until after the specified wait time has elapsed
 * since the last time the debounced function was invoked.
 *
 * @param func - The function to debounce.
 * @param wait - The number of milliseconds to delay.
 * @returns A debounced version of the provided function.
 */
export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): (key: string, ...args: Parameters<T>) => void {
  const timeouts: { [key: string]: ReturnType<typeof setTimeout> } = {};
  return function (key: string, ...args: Parameters<T>) {
    const later = () => {
      clearTimeout(timeouts[key]);
      func(...args);
    };
    clearTimeout(timeouts[key]);
    timeouts[key] = setTimeout(later, wait);
  };
}

/**
 * Formats a number with thousand separators.
 *
 * @param amount
 * @returns The formatted number eg. 1000000 -> 1,000,000.
 */
export function formatThousandSeparator(amount?: string | number) {
  return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function flattenSearchParam(memberParam: { [key: string]: any }) {
  const flattened = {};

  function flattenHelper(obj, parentKey = '') {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && !!obj[key]) {
        const newKey = parentKey ? `${parentKey}[${key}]` : key;
        if (Array.isArray(obj[key])) {
          flattened[newKey] = obj[key].join(', ');
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          flattenHelper(obj[key], newKey);
        } else {
          flattened[newKey] = obj[key];
        }
      }
    }
  }

  flattenHelper(memberParam);

  if (isEmptyObject(flattened)) {
    /**
     * return undefined if empty object
     * to prevent unused '?` in url
     * */
    return undefined;
  }

  return flattened;

  /**
   * @example
   * Output:
   * {
   *  page: 1,
   *  limit: 20,
   *  sort: 'asc',
   *  'filter[search]': 'example',
   *  'filter[status]': 'pending, accepted',
   *  'filter[roles]': 'admin, user'
   * }
   */
}

export function isDateExpired(date: string): boolean {
  const expirationDate = new Date(date);
  const now = new Date(); // Get the current date and time
  return isBefore(expirationDate, now); // Check if the expiration date is before now
}
type UnitInByte = 'KB' | 'MB' | 'GB';

const unitFactors: { [key in UnitInByte]: number } = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
};

export function convertFromByte(byte: number, toUnit: UnitInByte): number {
  const result = byte / unitFactors[toUnit];
  return result;
}

export function validateDate(value?: string) {
  /**
   * Link Issue: https://topremit.sentry.io/issues/5769986325/?end=2024-10-10T23%3A59%3A59&project=5175283&query=is%3Aunresolved%20issue.priority%3A%5Bhigh%2C%20medium%5D%20user.ip%3A92.38.141.39&referrer=issue-stream&start=2024-10-07T00%3A00%3A00&stream_index=0&utc=true
   * Notes:
   * - try catch method usage to prevent discountValidUntil undefined, because new Date(undefined) will cause invalid date
   * - finally for prevent this function return undefined, so it's expected for try and finally receive the same validation checking.
   */
  const date = value;
  try {
    if (!date) return null;

    return date;
  } catch (_) {
  } finally {
    if (!date) return null;

    return date;
  }
}

export function joinArrayIfArray(value?: string | string[]) {
  return Array.isArray(value) ? value.join(',') : value || '';
}

export function generateRandomUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getFileExtension(mimeType) {
  const mimeToExt = {
    'image/png': 'png',
    'image/jpeg': 'jpg',
    'image/gif': 'gif',
    'application/pdf': 'pdf',
    'text/plain': 'txt',
    'application/json': 'json',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  };

  return mimeToExt[mimeType] || 'bin';
}

export function moveToNextTick(job: () => void) {
  setTimeout(job, 0);
}
