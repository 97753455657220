import useS2SAnalytic from '@hooks/use-analytic-identify';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import Script from 'next/script';

export default function GoogleTagManagerPersonal() {
  const { initIdentifiers } = useS2SAnalytic();
  if (isNativeApp()) {
    return null;
  }

  const gtmAuth = process.env.NEXT_PUBLIC_GTM_AUTH_PERSONAL || '';
  const gtmPreview = process.env.NEXT_PUBLIC_GTM_PREVIEW_PERSONAL || '';
  const gtmContainerId =
    process.env.NEXT_PUBLIC_GTM_CONTAINER_ID_PERSONAL || '';

  return (
    <Script
      id="google-tag-manager-personal"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({'gtm.start': new Date().getTime(),event: 'gtm.js'});
          var f = d.getElementsByTagName(s)[0],j = d.createElement(s),dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x';
          f.parentNode.insertBefore(j, f);})(window, document, 'script', 'dataLayerPersonal', '${gtmContainerId}');`,
      }}
      onReady={() => initIdentifiers({})}
    />
  );
}
