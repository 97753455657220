import { css } from '@emotion/react';

export default css`
  .ant-badge {
    color: var(--text-primary);
  }
  .ant-badge-status-default {
    background-color: var(--red-500);
  }

  .ant-badge-status-error {
    background-color: var(--blue-500);
  }

  .ant-badge-status-success {
    background-color: var(--green-500);
  }

  .ant-badge-status-warning {
    background-color: var(--yellow-500);
  }
`;
