import { css, cx } from '@emotion/css';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import * as React from 'react';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

export interface PopoverContentProps
  extends React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> {}

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Content
    ref={ref}
    align={align}
    sideOffset={sideOffset}
    className={cx(styled.content, className)}
    {...props}
  />
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const styled = {
  content: css`
    z-index: 99999;
    overflow: hidden;
    border-radius: 6px;
    border-width: 0.0625rem;
    outline-style: none;
    width: var(--radix-popper-anchor-width);
    background-color: white;
    right: 0;
    box-shadow:
      0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12),
      0 9px 28px 8px rgba(0, 0, 0, 0.05);
  `,
};

export { Popover, PopoverTrigger, PopoverContent };
