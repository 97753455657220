import { ClassNamesArg, css, cx } from '@emotion/css';
import { cloneElement, memo, ReactNode } from 'react';

import { Badge } from '../../../elements';

interface IBottomMenuIconProps {
  children?: ReactNode;
  hasRedDot?: boolean;
  className?: ClassNamesArg;
}

export function bottomMenuIconSwitcher({ activeIcon, inactiveIcon, isActive }) {
  const activeIconRenderer = cloneElement(activeIcon, {
    className: cx({ [styled.hide]: !isActive }),
  });
  const inactiveIconRenderer = cloneElement(inactiveIcon, {
    className: cx({ [styled.hide]: isActive }),
  });

  return (
    <>
      {activeIconRenderer}
      {inactiveIconRenderer}
    </>
  );
}

function BottomMenuIconInner({
  children,
  hasRedDot,
  className,
}: IBottomMenuIconProps) {
  return (
    <div className={cx(styled.icon, className)}>
      <Badge {...(hasRedDot && { dot: true, offset: [0, 5], size: 8 })}>
        {children}
      </Badge>
    </div>
  );
}

export const BottomMenuIcon = memo(BottomMenuIconInner);

const styled = {
  icon: css`
    position: relative;
    display: flex;

    > span {
      display: flex;
    }

    svg {
      width: var(--dashboard-icon-menu-size);
      height: var(--dashboard-icon-menu-size);
    }
  `,
  hide: css`
    display: none;
  `,
};
