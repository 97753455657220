import { KycAbadStatus } from '@modules';
import { StatusType } from '@topremit/shared-web/components/elements/Status';
import { Translate } from 'next-translate';

export interface IPillStatus {
  message: string;
  status: StatusType;
}

export const angleVariant = {
  open: {
    rotate: 90,
  },
  close: {
    rotate: -90,
  },
};

export const dropdownVariant = {
  open: {
    opacity: 1,
  },
  close: {
    opacity: 0,
  },
};

export function pillsHandler(
  pillsStatus,
  props: {
    kycAbadStatus: KycAbadStatus | null;
    t: Translate;
  },
): IPillStatus {
  const { kycAbadStatus = null, t } = props;
  if (
    kycAbadStatus === KycAbadStatus.UNVERIF_ABAD ||
    kycAbadStatus === KycAbadStatus.UNVERIF_KYC_ABAD
  ) {
    return {
      message: t('dashboard:not_verified'),
      status: 'expired',
    };
  } else if (
    kycAbadStatus === KycAbadStatus.VERIFYING_ABAD ||
    kycAbadStatus === KycAbadStatus.VERIFYING_KYC_ABAD
  ) {
    return {
      message: t('dashboard:verifying'),
      status: 'inProgress',
    };
  } else if (
    kycAbadStatus === KycAbadStatus.REJECTED_KYC ||
    kycAbadStatus === KycAbadStatus.REJECTED_KYC_EMPTY_ABAD ||
    kycAbadStatus === KycAbadStatus.REJECTED_ABAD ||
    kycAbadStatus === KycAbadStatus.BLACKLIST_REMOVED
  ) {
    return {
      message: t('dashboard:verification_failed'),
      status: 'fail',
    };
  } else if (
    kycAbadStatus === KycAbadStatus.REJECTED_COMPLIANCE_KYC ||
    kycAbadStatus === KycAbadStatus.BLACKLIST_SPAM_PROHIBITED ||
    kycAbadStatus === KycAbadStatus.BLACKLIST_OTHER
  ) {
    return {
      message: t('dashboard:verification_rejected'),
      status: 'fail',
    };
  } else {
    return {
      message: t('kyc_status.verified'),
      status: 'success',
    };
  }
}

export const initialPillsStatus: IPillStatus = {
  message: '',
  status: 'expired',
};

export function handleIsMobileConfig(
  config: boolean | ((isMobile: boolean) => boolean) | undefined,
  isMobile: boolean,
): boolean | undefined {
  return typeof config === 'function' ? config(isMobile) : config;
}
