import { css } from '@emotion/react';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

const url = publicRuntimeConfig?.staticFolder || '';

export default css`
  .ant-checkbox-disabled .ant-checkbox-input {
    * {
      cursor: default;
    }
    cursor: default;
  }
  .ant-checkbox-wrapper {
    height: auto;
    width: fit-content;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    color: var(--text-primary);
    > span:last-child {
      flex: 1;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      max-width: 100%;
    }
    &:hover .ant-checkbox {
      .ant-checkbox-inner,
      &:hover .ant-checkbox-inner {
        border-color: var(--neutral-200);
      }
    }
    .ant-checkbox {
      border-radius: 0.5rem;
      top: 0;
      &::after {
        border: none;
      }
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--blue-500) !important;
          border-color: var(--blue-500) !important;
          &::after {
            background-color: transparent;
            border: 0;
            background-image: url('${url}/images/svg/check.svg');
            background-size: 0.875rem;
            border: none;
            width: 0.875rem;
            height: 0.875rem;
            background-repeat: no-repeat;
          }
        }
      }
      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          &::after {
            border: 1px solid var(--neutral-0) !important;
            border-left: 0;
            border-right: 0;
            height: 0;
            background-color: var(--neutral-0);
            width: 0.75rem;
          }
        }
      }
      &.ant-checkbox-disabled {
        opacity: 0.4;
        &.ant-checkbox-checked {
          > .ant-checkbox-inner {
            border-color: var(--blue-500) !important;
          }
        }
        .ant-checkbox-inner {
          background-color: transparent;
          border-color: var(--neutral-200) !important;
        }
      }
      .ant-checkbox-inner {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.5rem;
        border: 1px solid var(--neutral-200);
        &::after {
          top: 50%;
          left: 50%;
          border-radius: 0.0625rem;
          transform: rotate(0deg) scale(1) translate(-50%, -50%);
        }
      }
    }
  }
`;
