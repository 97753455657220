import { css } from '@emotion/react';

import { screenSize } from '../common/size';

export default css`
  a {
    text-decoration: none;
  }

  .nav-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .ant-btn {
      min-width: unset;
    }

    .nav-link {
      cursor: pointer;
      color: var(--text-secondary);
      line-height: var(--regular-line-height);
      &:hover,
      &.active {
        color: var(--text-primary);
      }
    }
    &.service {
      .nav-link {
        font-weight: 700;
        &:hover,
        &.active {
          color: var(--blue-500);
        }
      }
    }
  }

  .form-control {
    position: relative;
    width: 100%;
    border: none;
    border-radius: 8px !important;
    font-size: 1.125rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 0 !important;

    display: flex;
    height: 100%;
  }

  .form-group {
    width: 100%;
    position: relative;
    margin: 1.25rem 0;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--neutral-0);
    &.row {
      flex-direction: row;
      align-items: center;
    }
    > .input-wrapper {
      &.disabled {
        background-color: var(--neutral-100);
      }
      z-index: 1;
      position: relative;
      height: auto;
      min-height: 64px;
      width: 100%;
      border-radius: inherit;
      display: flex;
      justify-content: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
    }

    .form-label {
      user-select: none;
      z-index: -1;
      position: absolute;
      left: 20px;
      color: var(--text-secondary);
      margin: 0;
      transition: all ease 0s;
      user-select: none;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &.inactive {
        font-size: 1rem;
        @media (max-width: ${screenSize.tabletMd}px) {
          font-size: 0.875rem !important;
        }
      }
      &.active {
        font-size: 0.875rem;
        @media (max-width: ${screenSize.tabletMd}px) {
          font-size: 0.75rem !important;
        }
      }
      &.error {
        color: var(--red-500);
      }
      &.disabled {
        color: var(--text-disabled);
      }
    }
  }

  .borderless {
    border: none !important;
  }

  .input-control {
    width: 100%;
    padding: 0;
    height: 32px;
    font-size: 1rem;
    border-radius: unset;
    @media (max-width: ${screenSize.mobileLg}px) {
      font-size: 0.875rem;
    }
  }

  .ant-upload {
    &.ant-upload-drag {
      height: auto;
    }
  }

  .pill-text-icon {
    margin-left: 10px;
  }

  .pill-disable-closable .ant-tag-close-icon {
    cursor: default;
  }

  textarea.ant-input {
    resize: none;
    height: auto;
    width: 100%;
  }

  .pincode-input-container {
    .pincode-input-text {
      &:first-of-type {
        margin-left: 0 !important;
      }
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }

  .pincode-input-container {
    .pincode-input-text {
      &:first-of-type {
        margin-left: 0 !important;
      }
      &:last-of-type {
        margin-right: 0 !important;
      }
    }
  }
`;
