import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';

interface IMultiRouteCoachmarkContext {
  coachmark: ICoachmark;
  setCoachmark: Dispatch<SetStateAction<ICoachmark>>;
}

interface ICoachmark {
  run?: boolean;
  stepIndex?: number;
}

const appState: ICoachmark = {
  run: false,
  stepIndex: 0,
};

const MultiRouteCoachmarkContext = createContext(
  {} as IMultiRouteCoachmarkContext,
);

function MultiRouteCoachmarkProvider({ children }) {
  const [coachmark, setCoachmark] = useState<ICoachmark>(appState);

  return (
    <MultiRouteCoachmarkContext.Provider value={{ coachmark, setCoachmark }}>
      {children}
    </MultiRouteCoachmarkContext.Provider>
  );
}

function useMultiRouteCoachmark() {
  const context = useContext(
    MultiRouteCoachmarkContext,
  ) as IMultiRouteCoachmarkContext;

  return context;
}

export { MultiRouteCoachmarkProvider, useMultiRouteCoachmark };
