import { css, cx } from '@emotion/css';
import { ReactNode } from 'react';

interface Props {
  gap?: string;
  children: ReactNode;
  cols?: number | string;
  className?: string;
}

export default function Grid({
  cols,
  children,
  gap = '1.25rem',
  className,
}: Props) {
  function gridTemplateColumns() {
    if (typeof cols === 'number') {
      return Array(cols).fill('auto').join(' ');
    }

    return cols;
  }

  return (
    <div
      className={cx(
        css`
          display: grid;
          gap: ${gap};
          grid-template-columns: ${gridTemplateColumns()};
        `,
        className,
      )}
    >
      {children}
    </div>
  );
}
