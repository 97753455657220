import { cx } from '@emotion/css';
import * as React from 'react';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { styles } from './styles';
import { INumberInputBaseProps } from './types';

/**
 * `NumberInputBase` is a forwardRef component that extends a `react-number-format` component.
 */
const NumberInputBase = React.forwardRef<
  HTMLInputElement,
  INumberInputBaseProps
>(
  (
    {
      name,
      label,
      style,
      error,
      disabled,
      className,
      align = 'left',
      bordered = true,
      allowDecimal = true,
      inputMode = 'decimal',
      allowNegative = false,
      decimalSeparator = '.',
      thousandSeparator = ',',
      allowLeadingZeros = false,
      withThousandSeparator = false,
      allowedDecimalSeparators = [','],
      thousandsGroupStyle = 'thousand',
      onChange,
      ...props
    },
    ref,
  ) => {
    /**
     * Handles the change event for the number input.
     *
     * @param {NumberFormatValues} values - The values object containing the value, formatted value and float value.
     *
     * If `onChange` is provided, it will be called with the appropriate value based on the `allowLeadingZeros` flag.
     * - If `allowLeadingZeros` is true, `onChange` will be called with the value with type `string` (`values.value`).
     * - Otherwise, `onChange` will be called with the float value (`values.floatValue`).
     */
    function handleChange(values: NumberFormatValues) {
      if (onChange) {
        if (allowLeadingZeros) {
          onChange(values.value);
          return;
        }
        onChange(values.floatValue);
      }
    }

    return (
      <div className={cx(styles.root, className)} style={style}>
        <div
          className={cx(styles.inputContainer, {
            [styles.alignRight]: align === 'right',
          })}
        >
          <NumericFormat
            id={name}
            name={name}
            getInputRef={ref}
            displayType="input"
            disabled={disabled}
            inputMode={inputMode}
            allowNegative={allowNegative}
            decimalSeparator={decimalSeparator}
            allowLeadingZeros={allowLeadingZeros}
            thousandsGroupStyle={thousandsGroupStyle}
            decimalScale={allowDecimal ? undefined : 0}
            allowedDecimalSeparators={allowedDecimalSeparators}
            thousandSeparator={
              withThousandSeparator ? thousandSeparator : undefined
            }
            className={cx(styles.input(!!error), {
              [styles.error]: !!error,
              [styles.disabled]: disabled,
              [styles.borderless]: !bordered,
            })}
            /** required for :placeholder-shown css, do not remove */
            placeholder=" "
            onValueChange={onChange ? handleChange : undefined}
            {...props}
          />
          <label htmlFor={name} className={styles.label(!!error)}>
            {label}
          </label>
        </div>
      </div>
    );
  },
);

export default NumberInputBase;
