import * as React from 'react';
import { SVGProps } from 'react';
const InvestmentOutline = (props: SVGProps<SVGSVGElement>) => {
  const { fill = 'var(--neutral-200)' } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      {...props}
    >
      <path
        fill={fill}
        d="M22.112 15.295a1.7 1.7 0 0 0-2.195-.381l-4 2.383a1.19 1.19 0 0 0-.71-.864l-4.32-1.965a2.165 2.165 0 0 0-1.888.05 8.208 8.208 0 0 1-2.174.654c-.515.04-1.031.053-1.547.038A1.133 1.133 0 0 0 4.153 14H2.625A1.126 1.126 0 0 0 1.5 15.125v5.278a1.126 1.126 0 0 0 1.125 1.125h1.528a1.126 1.126 0 0 0 1.125-1.125v-.472c.588.115 8.492 2.557 8.86 2.373a3.68 3.68 0 0 0 2.119-.671l5.514-3.853a1.72 1.72 0 0 0 .341-2.485ZM4.528 20.403a.376.376 0 0 1-.375.375H2.625a.375.375 0 0 1-.375-.375v-5.278a.375.375 0 0 1 .375-.375h1.528a.375.375 0 0 1 .375.375v5.278Zm16.813-3.238-5.515 3.854a2.937 2.937 0 0 1-2.504.42l-8.044-2.288V15.96c.56.02 1.12.003 1.677-.05a8.955 8.955 0 0 0 2.386-.723 1.413 1.413 0 0 1 1.236-.037l4.319 1.965a.533.533 0 0 1-.317 1.009l-3.948-.739a.375.375 0 0 0-.138.737l3.953.74a1.288 1.288 0 0 0 1.334-.61l4.521-2.694a.958.958 0 0 1 1.04 1.607Z"
      />
      <path
        fill={fill}
        d="M14.523 13.542a5.928 5.928 0 0 0 5.92-5.921c-.325-7.855-11.517-7.853-11.841 0a5.928 5.928 0 0 0 5.92 5.92Zm0-11.092a5.177 5.177 0 0 1 5.17 5.17c-.284 6.86-10.059 6.859-10.341 0a5.177 5.177 0 0 1 5.17-5.17Z"
      />
      <path
        fill={fill}
        d="M14.526 10.02a1.011 1.011 0 0 1-1.01-1.012.375.375 0 0 0-.75.002 1.761 1.761 0 0 0 1.387 1.718v.916a.375.375 0 0 0 .75 0v-.915a1.764 1.764 0 0 0-.377-3.483 1.012 1.012 0 1 1 1.014-1.012.375.375 0 1 0 .75 0 1.762 1.762 0 0 0-1.387-1.718v-.918a.375.375 0 0 0-.75 0v.917a1.76 1.76 0 0 0 .377 3.481 1.012 1.012 0 0 1-.004 2.025Z"
      />
    </svg>
  );
};
export default InvestmentOutline;
