import { css } from '@emotion/css';
import { Avatar as AvatarAntd, AvatarProps } from 'antd';
import getConfig from 'next/config';
import Image from 'next/image';
import { ReactNode } from 'react';

export interface IAvatarProps extends AvatarProps {
  gender: 'male' | 'female';
  src?: string;
  /**
   * @param {'large' | 'default' | 'small' | number} [size] default
   */
  size?: 'large' | 'default' | 'small' | number;
  icon?: ReactNode;
}

const sizes = {
  large: 128,
  default: 64,
  small: 40,
};

const { publicRuntimeConfig } = getConfig();
function Avatar(props: IAvatarProps) {
  const { size = 'default', icon, src, gender = 'male', ...resProps } = props;

  const renderIcon = () => {
    if (src && icon) {
      throw new Error(
        "Please choose between src and icon. You can't use both at the same time",
      );
    }

    if (icon) {
      return icon;
    }

    return (
      <div
        className={styled.imgWrapper(
          typeof size === 'number' ? size : sizes[size],
        )}
      >
        <Image
          src={
            src
              ? src
              : `${
                  publicRuntimeConfig?.staticFolder
                    ? publicRuntimeConfig?.staticFolder
                    : ''
                }/images/svg/avatar-${gender}.svg`
          }
          fill
          sizes={`${typeof size === 'number' ? size : sizes[size]}`}
          alt={`avatar ${gender}`}
        />
      </div>
    );
  };

  return <AvatarAntd icon={renderIcon()} size={size} {...resProps} />;
}

const styled = {
  imgWrapper: (size: number) => css`
    position: relative;
    width: ${size / 16}rem;
    height: ${size / 16}rem;
  `,
};

export { Avatar };
