import { LegacyContentCard } from '@elements';
import { css, cx } from '@emotion/css';
import { typography } from '@topremit/shared-web/common/size';

import { IDashboardContentCardProps } from './types';

export default function DashboardContentCard({
  children,
  className,
  maxWidth,
  ...props
}: IDashboardContentCardProps) {
  return (
    <LegacyContentCard
      maxWidth={maxWidth}
      className={cx(styled.root, className)}
      {...props}
    >
      {children}
    </LegacyContentCard>
  );
}

const styled = {
  root: css`
    .container {
      .notes {
        width: 100%;
      }
      .wrapper {
        .title {
          font-size: ${typography.body3Bold.fontSize}rem;
        }
      }
    }
  `,
};
