import { css, cx } from '@emotion/css';
import { Input as InputAntd } from 'antd';
import { AnimatePresence } from 'framer-motion';
import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FieldGroup } from './FieldGroup';
import Flex from './Flex';
import { AnimatedWrapper } from './animated-wrapper';
import { ITextInputProps } from './fields/types';
import {
  callAllFunctions,
  isLowSpecDevice,
  mergeRefs,
} from '../../common/helper';
import { screenSize } from '../../common/size';
import { useMediaQuery } from '../../hooks';
import { Cross, Search } from '../shapes';

type ButtonSize = 'large' | 'medium' | 'small';
export interface ISearchInputProps
  extends Omit<ITextInputProps, 'name' | 'label'> {
  name?: string;
  label?: string;
  searchRef?: any;
  autoFocus?: boolean;
  onClearClick?: (params: any) => void;
  size?: ButtonSize;
}

const iconVariants = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const SearchInput = forwardRef((props: ISearchInputProps, ref: any) => {
  const {
    id,
    type,
    size,
    label,
    disabled,
    maxLength,
    searchRef,
    className,
    autoFocus,
    helperText,
    allowClear = true,
    leftSection,
    bordered = true,
    inputWrapperClassName,
    onClearClick,
    onChange,
    onFocus,
    onBlur,
    ...resProps
  } = props;

  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const [isFocus, setIsFocus] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const inputRef = useRef<any>();
  const fieldGroupRef = useRef<any>();
  const wrapperRef = useRef<any>(null);
  const rootClassName = cx(styled.root, className);
  const inputClass = cx('input-control', 'sm-text');
  const formControlClass = cx('form-control', styled.formControl);

  const count = searchValue?.length || 0;

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    onChange?.(e);
  }

  function handleFocus(e) {
    setIsFocus(true);
    onFocus?.(e);
  }

  function handleBlur(e) {
    setIsFocus(false);
    onBlur?.(e);
  }

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current.focus();
    },
    blur() {
      inputRef.current.blur();
    },
    ref: fieldGroupRef.current,
  }));

  useEffect(() => {
    if (!autoFocus) return;

    const focusFn = () => {
      setIsFocus(true);
    };

    focusFn();

    let delayedFocus;

    if (isLowSpecDevice()) {
      delayedFocus = setTimeout(focusFn, 800);
    } else {
      focusFn();
    }

    return () => clearTimeout(delayedFocus);
  }, [autoFocus]);

  return (
    <FieldGroup
      showLeftIcon
      showRightIcon
      isFocus={isFocus}
      bordered={bordered}
      disabled={disabled}
      ref={fieldGroupRef}
      className={rootClassName}
      style={{
        display: 'block',
        zIndex: isFocus ? 10 : 1,
      }}
      leftIconClassName={styled.leftIcon}
      borderStyle={{ borderRadius: 100 }}
      leftIcon={<Search width={20} height={20} />}
      inputWrapperRef={mergeRefs(wrapperRef, searchRef)}
      leftIconInnerWrapperClassName={styled.innerLeftIcon}
      rightIconClassName={cx(styled.arrowWrapper, 'right-wrapper')}
      inputWrapperClassName={cx(styled.inputWrapper(size), 'input-wrapper')}
    >
      <div className={formControlClass}>
        <InputAntd
          type={type}
          ref={inputRef}
          placeholder={label}
          disabled={disabled}
          variant="borderless"
          maxLength={maxLength}
          className={inputClass}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...resProps}
        />
        <AnimatePresence>
          {count > 0 && allowClear && !disabled && (
            <AnimatedWrapper
              exit="hidden"
              htmlTag="div"
              initial="hidden"
              animate="visible"
              variants={iconVariants}
              className="ant-input-suffix"
              onClick={callAllFunctions(() => {
                if (!disabled) {
                  setSearchValue('');
                  onChange?.({
                    target: { value: '' },
                  } as ChangeEvent<HTMLInputElement>);
                  if (!isMobileSize) {
                    inputRef.current?.focus();
                  }
                }
              }, onClearClick)}
            >
              <Flex className="icon-wrapper" align="center" justify="center">
                <Cross className="cross" size="small" height={12} width={12} />
              </Flex>
            </AnimatedWrapper>
          )}
        </AnimatePresence>
      </div>
    </FieldGroup>
  );
});

const styled = {
  inputWrapper: (size?: ButtonSize) => {
    const heightSizes: Record<ButtonSize, string> = {
      large: '3rem',
      medium: '2.625rem',
      small: '2.25rem',
    };
    const height = size ? heightSizes[size] : '2.75rem';
    return css`
      min-height: ${height} !important;
      max-height: ${height} !important;
      #form-control-wrapper {
        cursor: default;
      }
      .ant-input-suffix {
        min-height: ${height} !important;
        max-height: ${height} !important;
        > .icon-wrapper {
          background-color: var(--neutral-100);
          border-radius: 6.25rem;
          height: 1.25rem !important;
          left: 50%;
          top: 50%;
          margin-left: 1rem;
          padding: 0.25rem;
          position: absolute;
          transform: translateX(-50%) translateY(-50%);
          width: 1.25rem !important;
          .cross {
            position: relative;
          }
        }
      }
    `;
  },
  formControl: css`
    border-radius: 100px !important;
    max-width: calc(100% - 40px) !important;
    padding: 0 !important;
  `,
  arrowWrapper: css`
    min-height: 2.75rem;
    max-height: 2.75rem;
    width: 0.5rem;
  `,
  leftIcon: css`
    height: 1.25rem;
    min-height: 1.25rem;
    max-height: 1.25rem;
    width: 3.125rem !important;
    min-width: 3.125rem;
    max-width: 3.125rem;
  `,
  innerLeftIcon: css`
    padding-left: 0.625rem;
    min-width: 1.875rem;
  `,
  root: css`
    border-radius: 100px;
  `,
};

export default SearchInput;
