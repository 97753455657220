import { IClientRequestKycRegistrationDocuments } from '@api-hooks/client-onboarding';
import { Translate } from 'next-translate';

export interface IValidationSchemeClientOnboardingParameterProps {
  t: Translate;
}

export enum clientOnboardingStep {
  DEFAULT = 0, //handle blinking page
  COMPANY_DETAILS = 1,
  ADD_BANK_ACCOUNT = 2,
  REGISTRATION_DOCUMENTS = 3,
  CONFIRM_VERIFICATION = 4,
}

export enum statementLetterType {
  EN = 'EN',
  ID = 'ID',
}

export interface IAddBankAccountDetailsProps {
  bank: string;
  accountNumber: string;
  accountName: string;
}

export interface IAddOtherBankAccountDetailsProps {
  otherBank: string;
  accountNumber: string;
  accountName: string;
}

export type clientOnboardingPageType = 'ADD' | 'OTHER';

export interface IValidationScemeClientOnboardingRegistrationDocumentsProps
  extends IValidationSchemeClientOnboardingParameterProps {
  data: IClientRequestKycRegistrationDocuments;
}
