import { createContext, useContext, useState } from 'react';

interface NavbarDrawerContextProps {
  isOpen: boolean;
  close: () => void;
  toggle: () => void;
}

const NavbarDrawerContext = createContext({});

export function NavbarDrawerProvider({ children }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function close() {
    setIsOpen(false);
  }

  function toggle() {
    setIsOpen((prev) => !prev);
  }

  return (
    <NavbarDrawerContext.Provider
      value={{
        isOpen,
        close,
        toggle,
      }}
    >
      {children}
    </NavbarDrawerContext.Provider>
  );
}

export function useNavbarDrawer() {
  return useContext(NavbarDrawerContext) as NavbarDrawerContextProps;
}
