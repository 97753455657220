import { forwardRef, useImperativeHandle, useRef } from 'react';
import DefaultReCAPTCHA from 'react-google-recaptcha';

import { ReCAPTCHAProps, ReCAPTCHARefProps } from './types';

function ReCAPTCHA(
  {
    onCancelRecaptcha,
    size = 'invisible',
    badgePosition = 'bottomright',
    ...resProps
  }: ReCAPTCHAProps,
  ref,
) {
  const recaptchaRef = useRef<any>(null);

  useImperativeHandle(ref, () => ({
    executeAsync,
    execute,
    reset() {
      recaptchaRef.current.reset();
    },
    getValue() {
      return recaptchaRef.current.getValue();
    },
    getWidgetId() {
      return recaptchaRef.current.getWidgetId();
    },
    captcha: recaptchaRef.current.captcha,
  }));

  function execute() {
    initRecaptchaObserver();
    recaptchaRef.current.execute();
  }

  async function executeAsync(): Promise<string> {
    initRecaptchaObserver();
    return await recaptchaRef.current.executeAsync();
  }

  function initRecaptchaObserver() {
    let isClosed = false;
    const frames = [...document.getElementsByTagName('iframe')];
    const recaptchaWindow = frames?.find((el) =>
      el.src.includes('google.com/recaptcha/api2/bframe'),
    )?.parentNode?.parentNode as HTMLDivElement;

    if (recaptchaWindow) {
      new MutationObserver(() => {
        if (recaptchaWindow.style.visibility !== 'visible') {
          if (!isClosed) {
            isClosed = true;
            onCancelRecaptcha?.();
          }
        }
      }).observe(recaptchaWindow, {
        attributeFilter: ['style'],
      });
    }
  }

  return (
    <DefaultReCAPTCHA
      ref={recaptchaRef}
      size={size}
      badge={badgePosition}
      {...resProps}
    />
  );
}

export default forwardRef<ReCAPTCHARefProps, ReCAPTCHAProps>(ReCAPTCHA);
