import { css } from '@emotion/css';
import { Button } from '@global-elements';
import { parseHtml } from '@topremit/shared-web/components/elements';
import { useEffect, useState } from 'react';

import { useTranslation } from '../../../hooks';
import { TranslationKeys } from '../../../translation-keys';

interface IDeliverySpeedNotSupportedModalBody {
  supportedRoutingChannel: string;
  onCancel: () => void;
  onOk: () => void;
  isFromContinueButton?: boolean;
  isMultipleRecipient?: boolean;
}

function DeliverySpeedNotSupportedModalBody({
  supportedRoutingChannel,
  onCancel,
  onOk,
  isFromContinueButton = false,
  isMultipleRecipient = false,
}: IDeliverySpeedNotSupportedModalBody) {
  const { t } = useTranslation('calculator' as keyof TranslationKeys);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleClickOk() {
    setIsLoading(true);
    onOk();
  }

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  return (
    <div className={styled.root}>
      <h5 className="title">{t('conflict_modal.speed_not_supported.title')}</h5>
      <div className="description">
        {parseHtml(
          t(
            isFromContinueButton && isMultipleRecipient
              ? 'conflict_modal.speed_not_supported.description_from_continue'
              : 'conflict_modal.speed_not_supported.description',
            {
              type: t(
                `conflict_modal.routing_channel_type.${supportedRoutingChannel.toLowerCase()}`,
              ),
            },
          ),
        )}
      </div>
      <div className="modal-btn">
        {isFromContinueButton && isMultipleRecipient ? (
          <Button
            fullWidth
            className="choose-btn"
            onClick={onCancel}
            isLoading={isLoading}
          >
            {t('conflict_modal.modal_button.edit_recipient')}
          </Button>
        ) : (
          <>
            <Button
              fullWidth
              className="choose-btn"
              onClick={handleClickOk}
              isLoading={isLoading}
            >
              {t('conflict_modal.modal_button.choose')}
            </Button>
            <Button
              fullWidth
              type="invert"
              className="change-btn"
              onClick={onCancel}
            >
              {t('conflict_modal.modal_button.change')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

const styled = {
  root: css`
    .description {
      color: var(--text-secondary);
      margin-bottom: 1rem;
    }
    .modal-btn {
      .choose-btn {
        margin-bottom: 0.5rem;
      }
    }
  `,
};

export default DeliverySpeedNotSupportedModalBody;
