import { css, cx } from '@emotion/css';
import { Text } from '@global-elements';
import { ITextProps } from '@global-elements-utils/Text';
import { color as globalColor } from '@global-styles/color';
import { PropsWithChildren } from 'react';

type Color = 'lightGray' | 'gray';
interface Props extends PropsWithChildren, ITextProps {
  color?: Color;
}

const fontColor = {
  lightGray: globalColor.neutral300,
  gray: globalColor.neutral500,
};

export default function VStepperDescription({
  children,
  className,
  color = 'lightGray',
  ...props
}: Props) {
  return (
    <Text
      secondary
      size="sm"
      className={cx(styled.root(color), className)}
      {...props}
    >
      {children}
    </Text>
  );
}

const styled = {
  root: (color: Color) => css`
    color: ${fontColor[color]};
  `,
};
