import { requestFn } from '@common/client';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { useMutation, UseMutationOptions } from 'react-query';

import { AuthResponse, LoginParam } from './auth.model';

export function useLogin(
  options: UseMutationOptions<ApiResult<AuthResponse>, ApiError, LoginParam>,
) {
  return useMutation<ApiResult<AuthResponse>, ApiError, LoginParam>(
    (param: LoginParam) => {
      return requestFn({ path: 'login', method: 'post' }, { json: param });
    },
    options,
  );
}

export function useRevokeToken(
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  const response = useMutation<ApiResult, ApiError>(
    async () =>
      await requestFn({
        path: 'auth/revoke',
        method: 'post',
      }),
    options,
  );
  return response;
}
