import UAParser from 'ua-parser-js';
import { v4 as uuidv4 } from 'uuid';

export const DEVICE_UUID_STORAGE_KEY = 'DVUUID';

interface getBrowserInfoOptions {
  versionOnly?: boolean;
}

export function getUUID() {
  if (typeof window === 'undefined') return;

  const key = DEVICE_UUID_STORAGE_KEY;
  const urlParams = new URLSearchParams(window.location.search);
  const dvuuid = urlParams.get('dvuuid');
  const deviceUUID = window.localStorage.getItem(key);

  if (dvuuid) {
    window.localStorage.setItem(key, dvuuid);
    return dvuuid;
  }

  if (!deviceUUID) {
    const newUUID = uuidv4();
    window.localStorage.setItem(key, newUUID);
    return newUUID;
  }
  return deviceUUID;
}

export function getBrowserInfo(
  options?: getBrowserInfoOptions,
): string | undefined {
  const versionOnly = options?.versionOnly ?? false;

  if (typeof window === 'undefined') return;
  const urlParams = new URLSearchParams(window.location.search);
  const deviceType = urlParams.get('deviceType');
  if (deviceType) {
    return deviceType;
  }
  if (!navigator) return;
  const userAgent = navigator.userAgent;
  const parser = new UAParser(userAgent);
  const parserResults = parser.getResult();

  if (versionOnly) {
    return parserResults?.browser?.version || '';
  }

  return `${parserResults?.browser?.name} ${parserResults?.os?.name}`;
}
