import {
  AccountInfoBusiness,
  AccountType,
  DASHBOARD_TYPE,
  KycStatus,
  useGetAccountInfo,
  useGetFeedbackMenu,
  useGetMe,
  UserBusiness,
  useReadTransactionRule,
  useReadVoucher,
  UserPersonal,
  useUpdateRequestForm,
} from '@api-hooks/common';
import { AuthorizationRules } from '@common/rules';
import { ChatUsDialogContent } from '@elements';
import {
  useAnalytics,
  useGetAffiliateBlockingModalProps,
  usePermission,
  useShowKycBlocking,
} from '@hooks';
import { getStatusKycAbad, KycAbadStatus } from '@modules/kyc';
import BlockingModal from '@modules/landing/jumbotron/modal-body/BlockingModal';
import bankImage from '@public/images/svg/bank.svg';
import chatusImage from '@public/images/svg/chatus.svg';
import helpImage from '@public/images/svg/help.svg';
import languageImage from '@public/images/svg/language.svg';
import notesImage from '@public/images/svg/notes.svg';
import passwordImage from '@public/images/svg/password.svg';
import personalImage from '@public/images/svg/personal.svg';
import privacyImage from '@public/images/svg/privacy.svg';
import rateusImage from '@public/images/svg/rateus.svg';
import refundPolicyImage from '@public/images/svg/refund-policy.svg';
import share from '@public/images/svg/share.svg';
import termsImage from '@public/images/svg/terms.svg';
import ticketImage from '@public/images/svg/ticket.svg';
import timeImage from '@public/images/svg/time.svg';
import transactionLimitImage from '@public/images/svg/transaction-limit.svg';
import { useCurrentAccountStore } from '@stores';
import { BankAccountMatchingStatus } from '@topremit/shared-web/api-hooks/common';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useRouter } from 'next/router';

import { Timezone } from '.';
import Language from './Language';
import Logout from './Logout';
import PasswordModal from './modal/PasswordModal';
import { IMenuList } from './types';

const useRenderProfile = () => {
  // hooks
  const { t, lang } = useTranslation();
  const { data, refetch: refetchMe } = useGetMe<UserPersonal & UserBusiness>();
  const { data: accountInfoData } = useGetAccountInfo();

  const me = data?.data;
  const accountInfo = accountInfoData?.data;
  const currentAccount = useCurrentAccountStore(
    (store) => store.currentAccount,
  );

  const { data: feedbackMenu } = useGetFeedbackMenu({
    enabled: !!currentAccount,
  });
  const { trackerPersonalBusiness } = useAnalytics();
  const { mutate: mutateReadVoucher } = useReadVoucher();
  const router = useRouter();
  const { can } = usePermission();
  const affiliateBlockingModalProps = useGetAffiliateBlockingModalProps();

  const closeDialog = useDialogStore((store) => store.close);
  const showDialog = useDialogStore((store) => store.show);

  // mutation funcs
  const { mutateAsync: mutateAsyncUpdateRequestForm } = useUpdateRequestForm();
  const { mutate: mutateReadTransactionRule } = useReadTransactionRule({
    onSuccess: () => refetchMe(),
  });

  const { isPersonalAccount, isBusinessAccount } = usePermission();
  const isShowTransactionRule = me?.showTransactionRule;
  const showUserRequestForm = feedbackMenu?.data.showUserRequestForm;

  const clientProfileStatusApproved =
    (accountInfo as unknown as AccountInfoBusiness)?.kycStatus?.value ===
    KycStatus.DATA_APPROVED;

  const isPersonalKycCompleted =
    me?.kycStatus === KycStatus.COMPLIANCE_APPROVED;
  const isAbadApproved =
    me?.bankAccountVerificationInfo?.matchingStatus ===
    BankAccountMatchingStatus.APPROVED;
  const isBusinessKycCompleted =
    (accountInfo as AccountInfoBusiness)?.kycStatus?.value ===
    KycStatus.DATA_APPROVED;
  const isAccountVerified = isPersonalAccount
    ? isPersonalKycCompleted && isAbadApproved
    : isBusinessKycCompleted;

  const kycAbadStatus = getStatusKycAbad(
    me?.kycStatus as KycStatus,
    me?.bankAccountVerificationInfo
      ?.matchingStatus as BankAccountMatchingStatus,
    me?.blacklistReason,
    me?.rejectedError,
  );

  const { handleKycBlockingModal } = useShowKycBlocking();

  const isAffiliatedClient = can(AuthorizationRules.MenusAffiliate);

  const languageMenu = [
    {
      title: t('language:id'),
      value: 'id',
    },
    {
      title: t('language:en'),
      value: 'en',
    },
  ];

  const otherInfoList: IMenuList[] = [
    {
      isShow: true,
      title: t('dashboard:privacy_policy'),
      image: privacyImage,
      href: '/privacy-policy',
    },
    {
      isShow: true,
      title: t('dashboard:refund_policy'),
      image: refundPolicyImage,
      href: '/refund-policy',
    },
    {
      isShow: isPersonalAccount,
      title: t('dashboard:transaction_limit'),
      image: transactionLimitImage,
      href: '/transaction-limit',
      onClick: () => {
        if (isShowTransactionRule) {
          mutateReadTransactionRule();
        }
        window.open(
          `${window.location.origin}/${lang}/transaction-limit`,
          '_blank',
        );
      },
      isNew: isShowTransactionRule,
    },
    {
      isShow: true,
      title: t('dashboard:terms_of_service'),
      image: termsImage,
      href: '/terms-of-service',
    },
    {
      isShow: me?.dashboardType !== DASHBOARD_TYPE.RECEIVE_MONEY,
      title: t('dashboard:feedback'),
      image: notesImage,
      onClick: () => {
        mutateAsyncUpdateRequestForm();
        router.push('/profile/feedback');
      },
      isNew: showUserRequestForm,
    },
    {
      isShow: isPersonalAccount,
      title: t('dashboard:rate_us'),
      image: rateusImage,
      href: 'https://link.topremit.com/9tlR/f3bf1ac8',
    },
  ];
  const settingList: IMenuList[] = [
    {
      title: isPersonalAccount
        ? t('dashboard:personal_details')
        : t('dashboard:company_profile'),
      image: personalImage,
      href: isPersonalAccount
        ? '/profile/personal-details'
        : '/profile/company-profile',
      isShow: isAccountVerified,
    },
    {
      title: t('dashboard:bank_account_details'),
      image: bankImage,
      href: '/profile/bank-account-details',
      isShow: isBusinessAccount
        ? isAccountVerified &&
          can(AuthorizationRules.MenusBankAccounts) &&
          accountInfo?.dashboardStatus
        : isAccountVerified,
    },
    {
      title: t('dashboard:my_voucher'),
      image: ticketImage,
      onClick: () => {
        mutateReadVoucher();
        router.push(`/profile/voucher-detail`);
      },
      isShow: isPersonalAccount,
    },
    {
      title: t('dashboard:affiliate_program'),
      image: share,
      onClick: () => {
        if (
          currentAccount?.type === AccountType.PERSONAL
            ? kycAbadStatus === KycAbadStatus.REJECTED_COMPLIANCE_KYC ||
              kycAbadStatus === KycAbadStatus.BLACKLIST_SPAM_PROHIBITED
            : !clientProfileStatusApproved
        ) {
          handleBlockingModal();
          return;
        }
        if (me?.isAffiliate) {
          return window.open('/affiliate', '_blank');
        }
        trackerPersonalBusiness?.affiliateJoin();
        return window.open('/become-affiliate', '_blank');
      },
      isShow:
        currentAccount?.type === AccountType.BUSINESS
          ? can(AuthorizationRules.MenusAffiliate)
          : true,
    },
    {
      title: t('dashboard:pin_and_password'),
      image: passwordImage,
      onClick: () => {
        showDialog({
          body: <PasswordModal />,
        });
      },
      isShow: true,
    },
    {
      title: t('dashboard:time_zone'),
      image: timeImage,
      onClick: () => {
        showDialog({
          body: <Timezone />,

          fullHeight: true,
        });
      },
      isShow: true,
    },
    {
      title: t('dashboard:language'),
      image: languageImage,
      onClick: () => {
        showDialog({
          body: <Language languageMenu={languageMenu} />,
        });
      },
      isShow: true,
    },
    {
      title: t('dashboard:chat'),
      image: chatusImage,
      onClick: () => {
        onClickChatUs();
      },
      isShow: isPersonalAccount,
    },
    {
      title: t('dashboard:help'),
      image: helpImage,
      onClick: () => {
        window.open(`https://help.topremit.com/${lang}/support/home`, '_blank');
      },
      isShow: isPersonalAccount,
    },
  ];

  function getAffiliateBodyModal() {
    if (!isAffiliatedClient || !clientProfileStatusApproved) {
      if (!affiliateBlockingModalProps) {
        return null;
      }
      return (
        <BlockingModal {...affiliateBlockingModalProps} onClick={closeDialog} />
      );
    }
  }

  function handleBlockingModal() {
    if (currentAccount?.type === AccountType.PERSONAL && !me) {
      return;
    }
    showDialog({
      body:
        currentAccount?.type === AccountType.PERSONAL
          ? handleKycBlockingModal()
          : getAffiliateBodyModal(),
    });
  }

  function onClickChatUs() {
    showDialog({
      body: <ChatUsDialogContent />,
    });
  }

  function openLogoutDialog() {
    showDialog({
      body: <Logout />,
    });
  }

  return {
    otherInfoList,
    settingList,
    onClickChatUs,
    openLogoutDialog,
    showDialog,
  };
};

export default useRenderProfile;
