import { css, cx } from '@emotion/css';
import AnimatedDropdownArrow from '@global-elements-utils/AnimatedDropdownArrow';
import { ReactNode } from 'react';

import { Chip } from '..';
import { useDropdown } from '../../../hooks';

export interface IDropdownButtonProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  className?: string;
  hasBackground?: boolean;
  children: string;
}

export default function DropdownButton({
  leftIcon,
  rightIcon,
  className,
  children,
  hasBackground = true,
}: IDropdownButtonProps) {
  const { isOpen } = useDropdown();

  return (
    <Chip
      label={children}
      isSelected={isOpen}
      leftIcon={leftIcon}
      rightIcon={
        rightIcon || (
          <AnimatedDropdownArrow
            isExpand={isOpen}
            fill={
              isOpen
                ? 'var(--blue-500)'
                : hasBackground
                  ? 'var(--neutral-500)'
                  : 'var(--neutral-0)'
            }
          />
        )
      }
      className={cx(styled.chip(isOpen), className)}
    />
  );
}

const styled = {
  chip: (isOpen: boolean) => css`
    border: none;
    padding: 0.5rem 1rem;
    color: ${isOpen ? 'var(--blue-500)' : 'var(--neutral-800)'};
    :hover {
      color: var(--blue-500);
      background: var(--blue-100);
    }
    > .icon:last-child {
      margin-left: 0.25rem;
    }
    .arrow {
      display: flex;
    }
  `,
};
