const hoistNonReactStatics = require('hoist-non-react-statics');

module.exports = {
  locales: ['default', 'en', 'id'],
  defaultLocale: 'default',
  pages: {
    '*': [
      'common',
      'language',
      'auth',
      'error',
      'dashboard',
      'member',
      'profile',
    ],
    '/': ['home', 'send-money', 'transaction', 'calculator', 'track-payment'],
    '/about-us': ['about-us'],
    '/affiliate-agreement': ['affiliate'],
    '/become-affiliate': ['affiliate'],
    '/career': ['career'],
    '/cbm-calculator': ['cbm-calculator'],
    '/client-onboarding': ['kyc-abad', 'client-onboarding'],
    '/create-password': ['create-password'],
    '/local-transfer': ['domestic', 'transaction', 'home', 'calculator'],
    '/partnership/airasia': ['airasia'],
    '/privacy-policy': ['privacy-policy'],
    '/referral': ['referral', 'home'],
    '/refund-policy': ['refund-policy'],
    '/send-money': ['countries'],
    '/send-money/[countrySlug]': ['send-money'],
    '/terms-of-service': ['terms-of-service'],
    '/track': ['transaction'],
    '/transaction-limit': ['transaction-limit'],
    '/verify-account': ['client-onboarding'],
    'rgx:^/activity': [
      'calculator',
      'dashboard',
      'home',
      'referral',
      'refund',
      'track-payment',
      'transaction',
    ],
    'rgx:^/affiliate': ['affiliate-dashboard', 'affiliate'],
    'rgx:^/business': ['landing-business', 'client-onboarding', 'register'],
    'rgx:^/create-account': ['register', 'verification', 'otp'],
    'rgx:^/email-verification': ['dashboard'],
    'rgx:^/partnership': ['partnership'],
    '/loyalty/airasia': ['airasia'],
    'rgx:^/register': ['register', 'verification', 'otp'],
    'rgx:^/forget-password': ['register', 'verification', 'otp'],
    'rgx:^/home': ['dashboard', 'home', 'transaction', 'tr-coins'],
    'rgx:^/kyc': ['kyc-abad', 'refund'],
    'rgx:^/profile': [
      'bank-account-details',
      'client-onboarding',
      'dashboard',
      'error',
      'home',
      'send-money',
      'kyc-abad',
    ],
    'rgx:^/profile/voucher-detail': ['voucher-detail'],
    'rgx:^/quote': [
      'calculator',
      'dashboard',
      'home',
      'rate-alert',
      'transaction',
    ],
    'rgx:^/rate-alert': ['rate-alert', 'home', 'calculator'],
    'rgx:^/refunds': ['refund', 'dashboard'],
    'rgx:^/send-money': ['home', 'transaction', 'calculator'],
    'rgx:^/swift-code': ['swift-code', 'home'],
    'rgx:^/tr-coins': ['tr-coins'],
    'rgx:^/verify': ['email', 'verification', 'otp'],
    'rgx:^/wallet': ['wallet', 'transaction'],
    '/mobile-top-up': ['mobile-top-up', 'home'],
    '/swift-transfer': [
      'home',
      'landing-swift',
      'transaction',
      'calculator',
      'send-money',
    ],
  },
  loadLocaleFrom: (lang, namespace) => {
    const locale = lang === 'default' ? 'en' : lang;
    return import(`./public/locales/${locale}/${namespace}`).then(
      (m) => m.default,
    );
  },
  // https://github.com/aralroca/next-translate/issues/717#issuecomment-957315046
  staticsHoc: hoistNonReactStatics,
};
