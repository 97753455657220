import { cx } from '@emotion/css';
import { FieldGroup } from '@global-elements-utils/FieldGroup';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
  NumberFormatValues,
  NumericFormat as NumberInputBase,
} from 'react-number-format';

import { variants } from './constants';
import { INumberInputProps } from './types';
import { handleInputCondition } from '../../../common/helper';

/**
 * NumberInput component
 * @note This component is using react-number-format
 * @see https://s-yadav.github.io/react-number-format/docs/numeric_format
 */

const MAX_VALUE = 9_999_999_999_999;

/**
 * @deprecated This component is deprecated, use NumberInputV2 instead
 */
const NumberInput = forwardRef((props: INumberInputProps, ref: any) => {
  const {
    name,
    rules,
    label,
    disabled,
    className,
    maxLength,
    helperText,
    decimalScale,
    leftSection,
    rightSection,
    bordered = true,
    showError = true,
    isOptional = false,
    allowDecimal = true,
    inputMode = 'decimal',
    disabledFocus = false,
    inputWrapperClassName,
    max = MAX_VALUE,
    inputRef: inputRefProp,
    decimalSeparator = '.',
    thousandSeparator = ',',
    allowLeadingZeros = false,
    allowNegative = false,
    checkMax = true,
    withThousandSeparator = false,
    allowedDecimalSeparators = [','],
    thousandsGroupStyle = 'thousand',

    onBlur,
    onFocus,
    onChange,

    ...resProps
  } = props;

  const [isFocus, setIsFocus] = useState(false);

  const formContext = useFormContext();
  const { control } = formContext;

  const inputRef = useRef<any>(null);
  const fieldGroupRef = useRef<HTMLDivElement>(null);

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: {
      ...(rules && rules),
    },
  });

  const isError = !!error;
  const errorMessage = error?.message;

  const isFilled = !!field.value || field.value === 0;
  const count = field.value?.toString().length || 0;

  const _disabled = disabled || disabledFocus;
  const _thousandSeparator = withThousandSeparator
    ? thousandSeparator
    : undefined;

  const inputClass = cx('numeric-format-base', 'input-control regular-text', {
    error: isError,
  });

  const formControlProps = {
    variants,
    className: 'form-control',
    onClick: () => {
      if (!disabled) {
        setIsFocus(!disabledFocus);
      }
    },
    animate: handleInputCondition({ isFocus, isFilled }),
  };

  function handleFocus(e) {
    onFocus && onFocus(e);
    setIsFocus(!disabledFocus);
  }

  function handleBlur(e) {
    field.onBlur();
    setIsFocus(false);
    onBlur && onBlur(e);
  }

  function handleChange(value: NumberFormatValues) {
    /**
     * If allowLeadingZeros, the value will be a string
     * else it will be a number
     * @see NumberFormatValues
     */

    if (allowLeadingZeros) {
      field.onChange(value.value);
      onChange?.(value.value as any);
      return;
    }
    field.onChange(value.floatValue ?? null);
    onChange?.((value.floatValue as any) ?? null);
  }

  /**
   * handle the max value for the input
   */
  function isAllowed(values: NumberFormatValues) {
    if (!checkMax) {
      return true;
    }
    const { floatValue } = values;
    if (floatValue !== undefined && typeof max === 'number') {
      return floatValue <= max;
    }
    return true;
  }

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current?.focus();
    },
    blur() {
      inputRef.current?.blur();
    },
    ref: fieldGroupRef.current,
  }));

  useEffect(() => {
    if (isFocus) {
      inputRef.current?.focus();
      return;
    }
    inputRef.current?.blur();
  }, [isFocus]);

  useEffect(() => {
    if (disabled) {
      setIsFocus(false);
    }
  }, [disabled]);

  return (
    <FieldGroup
      name={name}
      count={count}
      label={label}
      isFocus={isFocus}
      isError={isError}
      bordered={bordered}
      ref={fieldGroupRef}
      helper={helperText}
      isFilled={isFilled}
      disabled={disabled}
      error={errorMessage}
      maxCount={maxLength}
      className={className}
      showError={showError}
      leftIcon={leftSection}
      isOptional={isOptional}
      rightIcon={rightSection}
      showLeftIcon={!!leftSection}
      inputWrapperRef={inputRefProp}
      showRightIcon={!!rightSection}
      formControlProps={formControlProps}
      inputWrapperClassName={inputWrapperClassName}
    >
      <NumberInputBase
        displayType="input"
        value={field.value}
        disabled={_disabled}
        inputMode={inputMode}
        className={inputClass}
        getInputRef={inputRef}
        isAllowed={isAllowed}
        allowNegative={allowNegative}
        decimalSeparator={decimalSeparator}
        allowLeadingZeros={allowLeadingZeros}
        thousandSeparator={_thousandSeparator}
        thousandsGroupStyle={thousandsGroupStyle}
        decimalScale={allowDecimal ? decimalScale : 0}
        allowedDecimalSeparators={allowedDecimalSeparators}
        onValueChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...resProps}
      />
    </FieldGroup>
  );
});

export default NumberInput;
