import {
  Account,
  AccountInfo,
  AccountInfoBusiness,
  AccountType,
  useGetAccountInfo,
  useGetMe,
} from '@api-hooks/common';
import { Dot } from '@elements';
import { css, cx } from '@emotion/css';
import useRenderProfile from '@modules/dashboard/profile/useRenderProfile';
import accountBusinessImage from '@public/images/svg/account-business.svg';
import accountPersonalImage from '@public/images/svg/account-personal.svg';
import { useCurrentAccountStore } from '@stores';
import { capitalizeFirstLetter } from '@topremit/shared-web/common/helper';
import { navbarSize, screenSize } from '@topremit/shared-web/common/size';
import {
  Flex,
  ResponsiveImage,
  Text,
} from '@topremit/shared-web/components/elements';
import { Check, Plus, SignOut } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { StaticImageData } from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';

export interface _Account extends Account {
  onClick: () => void;
}

interface Props {
  className?: string;
  accounts?: _Account[];
  statusPill: ReactNode;
}

const accountIcon: Record<AccountType, StaticImageData> = {
  [AccountType.PERSONAL]: accountPersonalImage,
  [AccountType.BUSINESS]: accountBusinessImage,
};

export default function ProfileDropdown({ className, ...props }: Props) {
  const { t } = useTranslation();

  const { data: accountInfo } = useGetAccountInfo<
    AccountInfo & AccountInfoBusiness
  >();
  const { data: me } = useGetMe();

  const { currentAccount } = useCurrentAccountStore.getState() || {};

  const { openLogoutDialog } = useRenderProfile();

  const isBusiness = currentAccount?.type === AccountType.BUSINESS;

  if (!currentAccount) {
    return null;
  }

  const _accounts = (() => {
    return props.accounts
      ?.filter((acc) => acc.id !== currentAccount.id)
      .map(
        ({
          id,
          name,
          type,
          roles,
          onClick,
          companyName,
          hasUnreadNotification,
        }) => (
          <Flex key={id} gap={16} onClick={onClick}>
            <Flex className={styled.avatar}>
              <ResponsiveImage
                ratio={1}
                alt="avatar"
                maxWidth={20}
                src={accountIcon[type]}
              />
              {hasUnreadNotification && <Dot className={styled.dot} />}
            </Flex>
            <Flex column>
              <Text className="sm-text" maxLine={2}>
                {companyName ?? name}
              </Text>
              <Text className="xs-text secondary-text">
                {t('account_type', {
                  type: t(type.toLowerCase()),
                })}
              </Text>
              <Text style={{ color: color.blue500 }} className="xs-text">
                {roles.join(', ')}
              </Text>
            </Flex>
          </Flex>
        ),
      );
  })();

  const buttons = (() => {
    const label = (() => {
      const hasPersonalAccount = accountInfo?.data.accounts.some(
        (acc) => acc.type === AccountType.PERSONAL,
      );
      const hasBusinessAccount = accountInfo?.data.accounts.some(
        (acc) => acc.type === AccountType.BUSINESS,
      );

      if (hasPersonalAccount && !hasBusinessAccount) {
        return 'create_business_account';
      } else if (!hasPersonalAccount && hasBusinessAccount) {
        return 'create_another_account';
      } else {
        return 'create_another_business_account';
      }
    })();

    const href = (() => {
      switch (label) {
        case 'create_business_account':
          return '/business/contact-us';
        case 'create_another_account':
          return '/create-account';
        case 'create_another_business_account':
          return '/business/contact-us';
      }
    })();

    return (
      <Link href={href} className={cx('sm-text', styled.btnAdd)}>
        <Flex align="center" justify="center" className={styled.avatar}>
          <Plus size="small" fill={color.blue500} />
        </Flex>
        <Text className={styled.btnLabel}>{t(label)}</Text>
      </Link>
    );
  })();

  return (
    <Flex column className={cx(styled.root, className, 'profile-dropdown')}>
      <Flex column className={styled.currentProfile}>
        <Text className="sm-bold-text title">{t('switch_account')}</Text>
        <Flex gap={16}>
          <Flex align="center" justify="center" className={styled.avatar}>
            <ResponsiveImage
              ratio={1}
              alt="avatar"
              maxWidth={20}
              src={accountIcon[currentAccount.type]}
            />
          </Flex>
          <Flex gap={5} column>
            <Text
              as="h5"
              maxLine={2}
              minSize="p"
              maxSize="h5"
              style={{ margin: 0 }}
            >
              {isBusiness ? currentAccount?.companyName : currentAccount?.name}
            </Text>
            <Text className="sm-text secondary-text">
              {currentAccount.type === AccountType.PERSONAL
                ? `${capitalizeFirstLetter(
                    currentAccount?.type.toLowerCase(),
                  )} Account`
                : currentAccount?.name}
            </Text>
            {currentAccount.type === AccountType.BUSINESS && (
              <>
                <Text className="xs-text secondary-text">
                  {me?.data?.email ?? accountInfo?.data?.email}
                </Text>
                <Text style={{ color: color.blue500 }} className="xs-text">
                  {currentAccount.roles.join(', ')}
                </Text>
              </>
            )}
            {currentAccount.type === AccountType.PERSONAL &&
              'statusPill' in props &&
              props.statusPill}
          </Flex>
          <Check />
        </Flex>
        <Flex column className={styled.accounts}>
          {_accounts}
        </Flex>
        <Flex className={styled.btnWrapper}>{buttons}</Flex>
      </Flex>
      <div className={styled.divider} />
      <Flex
        className={cx(styled.btnLogout, 'sm-text', 'link-text')}
        onClick={openLogoutDialog}
      >
        <Flex align="center" justify="center" className={styled.avatar}>
          <SignOut size={20} fill={color.blue500} />
        </Flex>
        <Text className="label">{t('logout')}</Text>
      </Flex>
    </Flex>
  );
}

const styled = {
  root: css`
    position: fixed;
    right: 1.5rem;
    width: 22.5rem;
    top: calc(${navbarSize.md}px + 1rem);
    box-shadow: 0 0.125rem 0.5rem 0 #2e48651a;
    border-radius: 1rem;
    background: var(--neutral-0);
    max-height: 32.0625rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      height: calc(${navbarSize.sm}px + 1rem);
    }
    .title {
      margin-bottom: 1rem;
    }

    .link-text:hover {
      color: #69b1ff;
    }
  `,
  avatar: css`
    position: relative;
    min-width: 2.5rem;
    max-width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    background: var(--blue-100);
    align-items: center;
    justify-content: center;
  `,
  currentProfile: css`
    padding: 1rem 1.5rem;
    overflow-y: scroll;
    > div:nth-child(2) > * {
      margin-bottom: 0.25rem;
    }
  `,
  accounts: css`
    padding-top: 1rem;
    overflow-y: auto;
    > div:not(:last-child) {
      margin-bottom: 0.75rem;
    }
  `,
  divider: css`
    min-height: 0.375rem;
    background: var(--neutral-100);
  `,
  btnAdd: css`
    display: flex;
    align-items: center;
    color: var(--text-primary);
  `,
  dot: css`
    position: absolute;
    top: -1px;
    right: 3px;
  `,
  btnWrapper: css`
    margin-top: 1rem;
  `,
  btnLogout: css`
    padding: 0.75rem 1.5rem 1rem;
    > .label {
      margin-left: 1rem;
      display: flex;
      align-items: center;
    }
  `,
  btnLabel: css`
    margin-left: 1rem;
  `,
};
