import { useGetAccountInfo } from '@api-hooks/common';
import { usePatchMember } from '@api-hooks/dashboard';
import { css, cx } from '@emotion/css';
import { useAuth } from '@hooks';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Drawer,
  DrawerButton,
  DrawerItem,
  DrawerList,
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownList,
  Flag,
  Flex,
  Text,
} from '@topremit/shared-web/components/elements';
import { FlagCode } from '@topremit/shared-web/components/elements/Flag';
import {
  useChangeLocale,
  useMediaQuery,
  useNotification,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

interface ILanguageMenuProps {
  hasBackground?: boolean;
  type: 'drawer' | 'button';
  className?: string;
}

type Locale = 'en' | 'id';

interface FlagProps {
  locale: Locale;
  isTablet?: boolean;
}

const flagCode: Record<'id' | 'en', FlagCode> = {
  id: 'id',
  en: 'us',
};

function LanguageFlag({ locale, isTablet: _isTablet }: FlagProps) {
  const { t } = useTranslation();
  const isTabletSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);
  const isTablet = isTabletSize || _isTablet;

  return (
    <div className={styled.flag} data-nosnippet>
      <Flag
        code={flagCode[locale]}
        size={isTablet ? 18 : 24}
        imgAlt={t(`image_alt.language.${flagCode[locale]}`)}
      />
    </div>
  );
}

export default function LanguageMenu({
  type,
  hasBackground = true,
}: ILanguageMenuProps) {
  const { t, lang } = useTranslation('language');
  const { locales, locale } = useRouter();
  const { isAuthenticated } = useAuth();

  const changeLocale = useChangeLocale();
  const { addNotification } = useNotification();
  const { refetch: refetchAccountInfo } = useGetAccountInfo();

  const { mutate: mutatePatchMember } = usePatchMember({
    onSuccess: () => {
      refetchAccountInfo();
    },
    onError: ({ message }) => {
      addNotification({ message, type: 'danger', closeable: false });
    },
  });

  // to get flag icon from locale which can be undefined
  const language = (locale === undefined ? 'en' : locale) as Locale;

  const languageOptions = useMemo(
    () =>
      locales
        ?.filter((locale) => locale !== 'default')
        .map((locale) => ({
          icon: <LanguageFlag locale={locale as Locale} />,
          label: t(locale),
          value: locale,
        })),
    [],
  );

  if (type === 'button') {
    return (
      <Dropdown>
        <DropdownButton
          leftIcon={<LanguageFlag locale={language} />}
          className={styled.button(hasBackground)}
          rightIcon={<></>}
        >
          {language.toUpperCase()}
        </DropdownButton>
        <DropdownList title={t('select_language')} className={styled.dropdown}>
          {languageOptions?.map(({ icon, label, value }) => (
            <DropdownItem
              key={value}
              value={value}
              onClick={() => {
                // Note: handle not run onclick when already same language
                if (value === lang) {
                  return;
                }
                if (isAuthenticated) {
                  mutatePatchMember({ locale: value });
                }
                changeLocale(value);
              }}
              className={cx({ selected: locale === value })}
            >
              <Flex justify="center" align="center" className="icon">
                {icon}
              </Flex>
              <Text>{label}</Text>
            </DropdownItem>
          ))}
        </DropdownList>
      </Dropdown>
    );
  }
  if (type === 'drawer') {
    return (
      <Drawer>
        <DrawerButton leftIcon={<LanguageFlag locale={language} />}>
          {language.toUpperCase()}
        </DrawerButton>
        <DrawerList title={t('select_language')}>
          {languageOptions?.map(({ label, value }) => (
            <DrawerItem
              key={value}
              className={cx({
                selected: locale === value,
              })}
              onClick={() => {
                if (isAuthenticated) {
                  mutatePatchMember({ locale: value });
                }
                changeLocale(value);
              }}
            >
              <Flex justify="center" align="center" className="icon">
                <LanguageFlag isTablet locale={value as Locale} />
              </Flex>
              <Text>{label}</Text>
            </DrawerItem>
          ))}
        </DrawerList>
      </Drawer>
    );
  }
  return null;
}

const styled = {
  button: (hasBackground?: boolean) => css`
    height: fit-content;
    color: ${hasBackground ? 'var(--neutral-500)' : 'var(--neutral-0)'};
    background: ${hasBackground ? '' : 'none'};
    border: 0.0625rem solid var(--neutral-200);
    :hover {
      background: none;
      color: ${hasBackground ? 'var(--neutral-500)' : 'var(--neutral-0)'};
    }
  `,
  dropdown: css`
    width: 14.5rem;
    .dropdown-item {
      padding: 0.75rem 1rem;
      &.selected {
        color: var(--blue-500);
      }
      .icon {
        width: unset;
        margin-right: 1rem;
        padding: 8px 4px;
      }
    }
  `,
  flag: css`
    display: flex;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(46, 72, 101, 0.1);
    border-radius: 0.125rem;
    overflow: hidden;
  `,
  link: css`
    color: var(--text-primary);
  `,
};
