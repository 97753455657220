import { css, cx } from '@emotion/css';
import { Text } from '@global-elements';
import { ITextProps } from '@global-elements-utils/Text';
import { color as globalColor } from '@global-styles/color';
import { PropsWithChildren } from 'react';

type Color = 'blue' | 'red' | 'gray' | 'lightGray';

interface Props extends PropsWithChildren, ITextProps {
  color?: Color;
}

const fontColor = {
  blue: globalColor.blue500,
  red: globalColor.red500,
  gray: globalColor.neutral500,
  lightGray: globalColor.neutral500,
};

export default function VStepperHeader({
  children,
  className,
  color = 'blue',
  ...props
}: Props) {
  return (
    <Text size="xs" className={cx(styled.root(color), className)} {...props}>
      {children}
    </Text>
  );
}

const styled = {
  root: (color: Color) => css`
    margin-bottom: 0.25rem;
    color: ${fontColor[color]};
  `,
};
