import {
  UpdateDataStatusType,
  useGetAccountInfo,
  useGetMe,
  UserBusiness,
  UserPersonal,
} from '@api-hooks/common';
import { useGlobalQueueStore } from '@stores';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import { usePrevious } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useAutoWithdraw, { AutoWithdrawStatusModal } from './use-auto-withdraw';
import usePermission from './use-permission';

const UpdateProfileDataModal = dynamic(
  () => import('@modules/dashboard/profile/modal/UpdateProfileDataModal'),
);
const InfoAutoWithdraw = dynamic(() =>
  import('@modules/landing/dialogs').then((file) => file.InfoAutoWithdraw),
);
const InfoCreatePin = dynamic(() =>
  import('@modules/landing/dialogs').then((file) => file.InfoCreatePin),
);
const InfoTosPP = dynamic(() =>
  import('@modules/landing/dialogs').then((file) => file.InfoTosPP),
);

// Note: Add more if there are more blocking modal that automatically show by BE like below
const QUEUE_IDS = {
  CREATE_PIN: 'CREATE_PIN',
  TOS_PP: 'TOS_PP',
  AUTO_WITHDRAW: 'AUTO_WITHDRAW',
  UPDATE_DATA: 'UPDATE_DATA',
};

const EXCLUDE_TO_SHOW_CREATE_PIN = [
  'affiliate-request-form',
  'affiliate',
  'choose-account',
  'create-account',
  'create-pin',
];

const EXCLUDED_TO_SHOW_AUTO_WITHDRAW = [
  'auto-withdraw',
  'auto-withdraw/activated',
  'auto-withdraw/activation',
  'choose-account',
  'create-account',
  'profile/update-data',
];

const EXCLUDED_TO_SHOW_UPDATE_DATE = [
  'auto-withdraw/activated',
  'auto-withdraw/activation',
  'choose-account',
  'create-account',
  'profile/reset-pin',
  'profile/update-data',
];

const EXCLUDED_TO_SHOW_TOS_PP = [
  'choose-account',
  'create-account',
  'create-pin',
];

const EXCLUDE_PATH = {
  [QUEUE_IDS.CREATE_PIN]: EXCLUDE_TO_SHOW_CREATE_PIN,
  [QUEUE_IDS.AUTO_WITHDRAW]: EXCLUDED_TO_SHOW_AUTO_WITHDRAW,
  [QUEUE_IDS.UPDATE_DATA]: EXCLUDED_TO_SHOW_UPDATE_DATE,
  [QUEUE_IDS.TOS_PP]: EXCLUDED_TO_SHOW_TOS_PP,
};

export default function useDashboardBlocking() {
  const router = useRouter();
  const { pathname } = router;
  const { add, queue, runQueue, next, clear } = useGlobalQueueStore();
  const { isBusinessAccount } = usePermission();
  const { data: accountInfo } = useGetAccountInfo();
  const {
    data: me,
    refetch: refetchMe,
    isFetching: isFetchingMe,
  } = useGetMe<UserPersonal | UserBusiness>();
  const { getStatusAutoWithdrawModal, isShowAutoWithdraw, skipAutoWithdraw } =
    useAutoWithdraw();
  const autoWithdrawStatus =
    getStatusAutoWithdrawModal() as AutoWithdrawStatusModal;
  const showDialog = useDialogStore((store) => store.show);
  const closeDialog = useDialogStore((store) => store.close);
  const prevMeId = usePrevious(me?.data?.id);

  // Utility Functions

  const showQueueCreatePin = accountInfo?.data.pinEmpty;
  const showQueueTosPp = me?.data.tosPpAgreement.isShowable;
  const showUpdateProfileData =
    me?.data?.updateData?.status === UpdateDataStatusType.UNVERIFIED;

  const showQueueAutoWithdraw = isBusinessAccount && isShowAutoWithdraw;

  // Add tasks to queue
  useEffect(() => {
    if (isNativeApp()) return;
    if (isFetchingMe) return;
    if (!me?.data.id) return;
    if (me?.data.id === prevMeId) {
      clear();
    }
    if (showQueueCreatePin) {
      add(QUEUE_IDS.CREATE_PIN, () =>
        showDialog({ body: <InfoCreatePin />, onClose: false }),
      );
    }
    if (showQueueTosPp) {
      add(QUEUE_IDS.TOS_PP, () =>
        showDialog({ body: <InfoTosPP />, onClose: false }),
      );
    }
    if (showQueueAutoWithdraw) {
      const isCloseable =
        autoWithdrawStatus ===
        AutoWithdrawStatusModal.OWNER_EXIST_CLIENT_BELOW_30;
      add(QUEUE_IDS.AUTO_WITHDRAW, () =>
        showDialog({
          body: <InfoAutoWithdraw />,
          onClose: isCloseable
            ? () => {
                closeDialog();
                skipAutoWithdraw().then(() => {
                  refetchMe();
                  next();
                });
              }
            : false,
        }),
      );
    }

    if (showUpdateProfileData) {
      add(QUEUE_IDS.UPDATE_DATA, () =>
        showDialog({
          onClose: false,
          body: <UpdateProfileDataModal />,
        }),
      );
    }
  }, [
    autoWithdrawStatus,
    isFetchingMe,
    me?.data.id,
    pathname,
    showQueueAutoWithdraw,
    showQueueCreatePin,
    showQueueTosPp,
    showUpdateProfileData,
  ]);

  // exclude path management
  useEffect(() => {
    const currentQueue = queue[0]?.id;

    let shouldRunQueue = true;

    Object.keys(EXCLUDE_PATH).forEach((queueId) => {
      const excludedPathList = EXCLUDE_PATH[queueId];
      const isExcluded =
        currentQueue === queueId &&
        excludedPathList.some((endPoint) => pathname.includes(endPoint));

      if (isExcluded) {
        shouldRunQueue = false;
        closeDialog();
      }
    });

    if (shouldRunQueue) {
      runQueue();
    }
  }, [queue, pathname]);

  return null;
}
