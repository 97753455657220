import { AdditionalRecipientDetails } from '@api-hooks/recipient';
import { Recipient } from '@api-hooks/transaction';
import {
  QuotationModel,
  RemitQuotationModel,
} from '@topremit/shared-web/api-hooks/transaction';
import { usePrevious } from '@topremit/shared-web/hooks';
import {
  Dispatch,
  useState,
  useContext,
  createContext,
  SetStateAction,
} from 'react';

export enum ChooseRecipientStep {
  ADD = 'ADD',
  EDIT = 'EDIT',
  TYPE = 'TYPE',
  PAYERS = 'PAYERS',
  CHOOSE = 'CHOOSE',
  ADDITIONAL = 'ADDITIONAL',
}

interface Limit {
  minLimit?: number;
  maxLimit?: number;
}

type SetStateAdditionalDetails = Dispatch<
  SetStateAction<AdditionalRecipientDetails | null>
>;
type SetStateActionBoolean = Dispatch<SetStateAction<boolean>>;
type SetStateActionNumber = Dispatch<SetStateAction<number | null>>;

interface IRecipientContext {
  isDisabled: boolean;
  isSubmitted: boolean;
  isSelected: number[];
  payerId: number | null;
  changedAmount: boolean;
  isLimitExceed: boolean;
  hasPayerSpeed: boolean;
  step: ChooseRecipientStep;
  quotation: QuotationModel;
  recipient: Recipient;
  isInvoiceChecked: boolean;
  isDeleteRecipient: boolean;
  selectedRecipientId: number;
  prevStep: ChooseRecipientStep;
  isValidatingBankCode: boolean;
  hasChangedDestinationAmount: boolean;
  deleteSelectedRecipientIdxs: number[];
  additionalDetails: AdditionalRecipientDetails;
  destinationCurrency: string;
  serviceLimit: Limit | null;
  purposeLimit: Limit | null;
  payerLimit: Limit | null;
  showMaintenancesNotes: boolean;

  setPayerId: SetStateActionNumber;
  setIsDisabled: SetStateActionBoolean;
  setIsSubmitted: SetStateActionBoolean;
  setIsLimitExceed: SetStateActionBoolean;
  setHasPayerSpeed: SetStateActionBoolean;
  setChangedAmount: SetStateActionBoolean;
  setIsDeleteRecipient: SetStateActionBoolean;
  setSelectedRecipientId: SetStateActionNumber;
  setIsValidatingBankCode: SetStateActionBoolean;
  setAdditionalDetails: SetStateAdditionalDetails;
  setIsSelected: Dispatch<SetStateAction<number[]>>;
  setHasChangedDestinationAmount: SetStateActionBoolean;
  setIsInvoiceChecked: Dispatch<SetStateAction<boolean>>;
  setShowMaintenancesNotes: SetStateActionBoolean;
  setStep: Dispatch<SetStateAction<ChooseRecipientStep>>;
  setQuotation: Dispatch<SetStateAction<RemitQuotationModel>>;
  setRecipient: Dispatch<SetStateAction<Recipient | null>>;
  setDeleteSelectedRecipientIdxs: Dispatch<SetStateAction<number[]>>;
  setDestinationCurrency: Dispatch<SetStateAction<string>>;
  setServiceLimit: Dispatch<SetStateAction<Limit | null>>;
  setPurposeLimit: Dispatch<SetStateAction<Limit | null>>;
  setPayerLimit: Dispatch<SetStateAction<Limit | null>>;
}

const RecipientContext = createContext({});

export function RecipientProvider({ children }) {
  const [additionalDetails, setAdditionalDetails] =
    useState<AdditionalRecipientDetails | null>(null);
  const [step, setStep] = useState<ChooseRecipientStep>(
    ChooseRecipientStep.CHOOSE,
  );
  const [isValidatingBankCode, setIsValidatingBankCode] =
    useState<boolean>(false);
  const [isLimitExceed, setIsLimitExceed] = useState(false);
  const [hasPayerSpeed, setHasPayerSpeed] = useState(false);
  const [isSelected, setIsSelected] = useState<number[]>([]);
  const [payerId, setPayerId] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [quotation, setQuotation] = useState<RemitQuotationModel>();
  const [deleteSelectedRecipientIdxs, setDeleteSelectedRecipientIdxs] =
    useState<number[]>([]);
  const [showMaintenancesNotes, setShowMaintenancesNotes] =
    useState<boolean>(false);
  const [changedAmount, setChangedAmount] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<Recipient | null>(null);
  const [selectedRecipientId, setSelectedRecipientId] = useState<number>(0);
  const [isDeleteRecipient, setIsDeleteRecipient] = useState<boolean>(false);
  const [hasChangedDestinationAmount, setHasChangedDestinationAmount] =
    useState<boolean>(false);
  const [isInvoiceChecked, setIsInvoiceChecked] = useState<boolean>(false);
  const [destinationCurrency, setDestinationCurrency] = useState<string>('');

  // Add Limit State for Confirmation Detail
  const [serviceLimit, setServiceLimit] = useState<Limit | null>(null);
  const [purposeLimit, setPurposeLimit] = useState<Limit | null>(null);
  const [payerLimit, setPayerLimit] = useState<Limit | null>(null);

  const prevStep = usePrevious(step);

  return (
    <RecipientContext.Provider
      value={{
        step,
        payerId,
        prevStep,
        recipient,
        quotation,
        isDisabled,
        isSelected,
        isSubmitted,
        changedAmount,
        isLimitExceed,
        hasPayerSpeed,
        additionalDetails,
        isDeleteRecipient,
        selectedRecipientId,
        isValidatingBankCode,
        showMaintenancesNotes,
        deleteSelectedRecipientIdxs,
        hasChangedDestinationAmount,
        isInvoiceChecked,
        destinationCurrency,
        serviceLimit,
        purposeLimit,
        payerLimit,
        setStep,
        setPayerId,
        setRecipient,
        setQuotation,
        setIsDisabled,
        setIsSelected,
        setIsSubmitted,
        setHasPayerSpeed,
        setIsLimitExceed,
        setAdditionalDetails,
        setIsDeleteRecipient,
        setChangedAmount,
        setSelectedRecipientId,
        setIsValidatingBankCode,
        setShowMaintenancesNotes,
        setDeleteSelectedRecipientIdxs,
        setHasChangedDestinationAmount,
        setIsInvoiceChecked,
        setDestinationCurrency,
        setServiceLimit,
        setPurposeLimit,
        setPayerLimit,
      }}
    >
      {children}
    </RecipientContext.Provider>
  );
}

export function useRecipient() {
  return useContext(RecipientContext) as IRecipientContext;
}
