import { css, cx } from '@emotion/css';
import { motion } from 'framer-motion';
import { ReactNode } from 'react';

import { useDropdownAccordion } from '../../../hooks';
import { Angle } from '../../shapes';
import Flex from '../Flex';
import { accordionArrowVariants } from '../constants';

interface IDropdownAccordionButton {
  id: string;
  className?: string;
  children: ReactNode;
}

export default function DropdownAccordionButton({
  id,
  className,
  children,
}: IDropdownAccordionButton) {
  const { selectedId, onClick } = useDropdownAccordion();

  const isExpand = selectedId === id;

  return (
    <Flex
      justify="center"
      align="center"
      className={cx(
        styled.root,
        { [styled.blueBackground]: isExpand },
        className,
      )}
      onClick={() => onClick(id)}
    >
      {children}
      <motion.div
        animate={isExpand ? 'expand' : 'collapse'}
        variants={accordionArrowVariants}
        className={styled.icon}
      >
        <Angle
          direction="down"
          size="small"
          fill={isExpand ? 'var(--blue-500)' : 'var(--neutral-500)'}
        />
      </motion.div>
    </Flex>
  );
}

const styled = {
  root: css`
    justify-content: space-between;
    border-radius: 50px;
    padding: 0.75rem 1rem;
    margin: 0.5rem 0;
    font-weight: var(--bold-font-weight);
  `,
  icon: css`
    display: flex;
    align-items: center;
  `,
  blueBackground: css`
    background-color: var(--blue-100);
    color: var(--blue-500);
  `,
};
