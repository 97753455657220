import { css, cx } from '@emotion/css';
import { HTMLAttributes, ReactNode } from 'react';

interface TopMenuItemProps {
  children?: ReactNode;
  className?: HTMLAttributes<'div'>['className'];
  onClick?: () => void;
}

export default function TopMenuItem({
  children,
  className,
  onClick,
}: TopMenuItemProps) {
  return (
    <div className={cx(styled.item, className)} onClick={onClick}>
      {children}
    </div>
  );
}

const styled = {
  item: css`
    margin: 0 8px;
  `,
};
