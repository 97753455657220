import { Children, ReactElement } from 'react';

import SideMenuItem from './SideMenuItem';

interface SideBarHeaderProps {
  children?: ReactElement | ReactElement[];
}

export default function SideBarHeader({ children }: SideBarHeaderProps) {
  const headerItemsNode = Children.map(children, (child) =>
    child?.type === SideMenuItem ? child : null,
  );

  return <>{headerItemsNode}</>;
}
