import { css } from '@emotion/css';
import { LegacyRef, forwardRef, useEffect, useState } from 'react';
import Pin from 'react-pin-input';

import { screenSize } from '../../common/size';

const styles = {
  pinWrapper: (error) => css`
    display: flex;
    flex-direction: column;
    .pincode-input-container {
      display: flex;
      flex: 1;
      text-align: center;
      font-size: 1.375rem;
      justify-content: space-between;
      .pincode-input-text {
        margin: 0 5px !important;
        border: 1px solid var(--neutral-200) !important;
        ${error === true && `border: 1px solid var(--red-500) !important;`}
        width: 3.056875rem !important;
        width: clamp(
          3.056875rem,
          1.0466650763358775rem + 6.870229007633588vw,
          4.744375rem
        ) !important;

        height: 3.375rem !important;
        height: clamp(
          3.375rem,
          1.5559701492537314rem + 7.761194029850746vw,
          5rem
        ) !important;
        min-width: 3.125rem;
        border-radius: 0.875rem !important;
        color: var(--text-primary);
        @media (max-width: ${screenSize.tabletSm}px) {
          margin: 0 4px !important;
        }
      }
      .pincode-input-text:focus {
        border: 1px solid ${error ? 'var(--red-500)' : 'var(--blue-500)'} !important;
      }
    }
    .error-msg {
      margin-top: 1rem;
      color: var(--red-500);
    }
  `,
  disabled: {
    border: '1px solid var(--neutral-200)',
    backgroundColor: 'var(--neutral-100)',
  },
};
interface IPinInput {
  setCurrentPin: (value: string) => void;
  onSubmit: () => void;
  onChange?: () => void;
  inputStyle?: any;
  inputFocusStyle?: any;
  inputMode?: string;
  type?: 'numeric' | 'custom';
  disabled?: boolean;
  error?: boolean;
  focus?: boolean;
  initialValue?: any;
  errorMsg?: string;
  mask?: boolean;
}

const PIN_LENGTH = 6;

/** @deprecated use PinInput instead */
const LegacyPinInput = forwardRef(
  (props: IPinInput, ref: LegacyRef<Pin> | undefined) => {
    const {
      focus,
      errorMsg,
      error = false,
      inputStyle = {},
      type = 'numeric',
      disabled = false,
      inputMode = 'tel',
      inputFocusStyle = {},
      onSubmit,
      onChange = () => {},
      setCurrentPin,
      mask,
    } = props;

    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
      // workaround: removed onComplete because trigger onSubmit with stale value
      if (currentValue.length === PIN_LENGTH) {
        onSubmit();
      }
    }, [currentValue]);

    return (
      <div className={styles.pinWrapper(error)}>
        <Pin
          ref={ref}
          type={type}
          autoSelect={false}
          disabled={disabled}
          length={PIN_LENGTH}
          inputMode={inputMode}
          focus={(error || focus) && false}
          inputStyle={disabled ? styles.disabled : inputStyle}
          inputFocusStyle={disabled ? styles.disabled : inputFocusStyle}
          secretDelay={mask ? 300 : undefined}
          onChange={onChange}
          onComplete={(values) => {
            setCurrentPin(values);
            setCurrentValue(values);
          }}
        />
        {error && <span className="error-msg xs-text">{errorMsg}</span>}
      </div>
    );
  },
);

export default LegacyPinInput;
