import { FlagCode } from '@global-elements-utils/Flag';
import { ICountryCurrencyValue } from '@global-elements-utils/fields/choose-country-currency/types';

import { RoutingChannel } from '../../../landing/src/api-hooks/transaction/transaction.model';
import { NotesStatus } from '../../components/elements';
import { RoutingChannelModel, ServiceModel } from '../common';

export enum TransactionStatus {
  CREATED = 'CREATED',
  WAITING = 'WAITING',
  WAITING_APPROVAL = 'WAITING_APPROVAL',
  PAID = 'PAID',
  PROCESS = 'PROCESS',
  SUSPICIOUS = 'SUSPICIOUS',
  SENT = 'SENT',
  FAIL = 'FAIL',
  EXPIRED = 'EXPIRED',
  NEED_REFUND = 'NEED_REFUND',
  REFUND = 'REFUND',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  REFUND_SUCCESS = 'REFUND_SUCCESS',
  NEED_APPROVAL = 'NEED_APPROVAL',
  NEED_PAYMENT = 'NEED_PAYMENT',
  NEED_PROOF = 'NEED_PROOF',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  IN_REVIEW = 'IN_REVIEW',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  ALL_FINISHED = 'ALL_FINISHED',
  WAITING_PICKUP = 'WAITING_PICKUP',
  PICKUP_CANCELED = 'PICKUP_CANCELED',
  PICKUP_EXPIRED = 'PICKUP_EXPIRED',
  COMPLETED = 'COMPLETED',
}

export enum BatchStatus {
  AWAITING = 'AWAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  AUTHORIZED = 'AUTHORIZED',
  FINISHED = 'FINISHED',
  EXPIRED = 'EXPIRED',
  REDFLAG = 'REDFLAG',
  IN_PROGRESS = 'IN_PROGRESS',
  DEFAULT = 'DEFAULT',
  NEED_PAYMENT = 'NEED_PAYMENT',
  NEED_APPROVAL = 'NEED_APPROVAL',
  CANCELED = 'CANCELED',
}

export enum TransactionType {
  REMIT = 'REMIT',
  BATCH_REMIT = 'BATCH_REMIT',
  AIRTIME = 'AIRTIME',
  DOMESTIC = 'DOMESTIC',
  BATCH_DOMESTIC = 'BATCH_DOMESTIC',
}

/**
 * used for analytics, for transaction use TransactionMode instead
 */
export enum TransactionRecipientType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export enum InvoiceType {
  DEBIT_NOTE = 'DEBIT_NOTE',
  RECEIPT = 'RECEIPT',
}

export enum HardLimitType {
  FREQUENCY = 'FREQUENCY',
  VOLUME = 'VOLUME',
}

export enum SoftLimitType {
  LIMIT_FREQUENCY_INFO = 'LIMIT_FREQUENCY_INFO',
  LIMIT_AMOUNT_INFO = 'LIMIT_AMOUNT_INFO',
  STATUS_EXCEED_YEAR_LIMIT = 'STATUS_EXCEED_YEAR_LIMIT',
  STATUS_EXCEED_QUARTER_LIMIT = 'STATUS_EXCEED_QUARTER_LIMIT',
  STATUS_EXCEED_MONTH_LIMIT = 'STATUS_EXCEED_MONTH_LIMIT',
  STATUS_EXCEED_DAILY_LIMIT = 'STATUS_EXCEED_DAILY_LIMIT',
  STATUS_EXCEED_ACCU_LIMIT = 'STATUS_EXCEED_ACCU_LIMIT',
  STATUS_EXCEED_PERIODIC_COUNT_LIMIT = 'STATUS_EXCEED_PERIODIC_COUNT_LIMIT',
  STATUS_EXCEED_PERIODIC_COUNT_YEAR_HARD_LIMIT = 'STATUS_EXCEED_PERIODIC_COUNT_YEAR_HARD_LIMIT',
  STATUS_EXCEED_PERIODIC_COUNT_QUARTER_HARD_LIMIT = 'STATUS_EXCEED_PERIODIC_COUNT_QUARTER_HARD_LIMIT',
  STATUS_EXCEED_PERIODIC_COUNT_MONTH_HARD_LIMIT = 'STATUS_EXCEED_PERIODIC_COUNT_MONTH_HARD_LIMIT',
  STATUS_EXCEED_PERIODIC_COUNT_DAILY_HARD_LIMIT = 'STATUS_EXCEED_PERIODIC_COUNT_DAILY_HARD_LIMIT',
  HOLIDAY_COMPARISON_RATE_SUPERIOR_INFO = 'HOLIDAY_COMPARISON_RATE_SUPERIOR_INFO',
  HOLIDAY_COMPARISON_RATE_INFERIOR_INFO = 'HOLIDAY_COMPARISON_RATE_INFERIOR_INFO',
}

export enum RoutingChannelType {
  INSTANT = 'INSTANT',
  REGULAR = 'REGULAR',
}

export type PayerErrorType = 'LIMIT' | 'SERVICE';

export type HolidayComparisonType = 'INFERIOR' | 'SUPERIOR';

export interface GetQuotationParam {
  ref_id: string | string[] | undefined;
}

export interface GetTransactionParam {
  transactionId: string | string[] | undefined;
}

export interface BatchTransactionParam {
  batchId: string;
}

export interface TransactionResponse {
  beneficiaryName: string;
  createdAt: Date;
  description: string;
  destinationAmount: number;
  destinationCountryFlagCode: string;
  destinationCountry: string;
  destinationCurrency: string;
  expirationDate: Date;
  fee: number;
  id: number;
  makeBy: string;
  recipientId: number;
  redflagStatus: string;
  refId: string;
  releaseBy: null;
  serviceId: number;
  serviceName: string;
  sourceAmount: number;
  sourceCurrencyIsoCode: string;
  totalToPay: string;
  transactionStatus: string;
  payerName: string;
  accountNumber: string;
}

export interface RecipientModel {
  accountNumber: string;
  destinationCountry: {
    isoCode: string;
    flagCode: string;
    dialCode: string;
    name: string;
  };
  dialCode: string;
  flagCode: string;
  isoCode: string;
  email: string;
  id: number;
  isInvited: boolean;
  name: string;
  firstName: string;
  lastName: string;
  fullname: string;
  fundSource: string;
  payerId: string;
  contactNumber: string;
  payer: string;
  service: string;
  relationship: string;
  idNumber: string;
  idType: string;
  province: string;
  regency: string;
  city: string;
  address: string;
  postalCode: string;
  invoiceNumber?: number;
  intermediaryBankBranchCode: string;
  intermediaryPayerId?: number | null;
  beneficiary: {
    purposeCode: string;
    relationship: string;
    segment: string;
  };
  creditParty: {
    id: number;
    createdAt: string;
    updatedAt: string;
    accountNumber: string;
    bankBranchCode: string;
    bankProvince: string;
    bankCity: string;
  };
  sender: {
    fundSource: string;
  };
}

// TODO: Must convert to Single Transaction Model
export interface TransactionModel {
  discountFee?: {
    discountValue: number;
    validUntil: string;
  };
  normalFee: number;
  estimationArrivalTime: string;
  etaNote?: string;
  additionalFee: number;
  approvedAt: string | null;
  bankTransactionCode: string | null;
  bankTransactionCodeMessage: string | null;
  destinationCountryFlagCode: string | null;
  correspondentFee: number;
  beneficiaryName: string;
  isSendAmount: string;
  invoiceDownload: boolean;
  invoiceType: InvoiceType;
  releasedBy: string;
  rejectedAt?: string;
  recipientId?: string;
  isDiscountFeeInvalid: boolean;
  discountFeeInvalidMessage: string;
  hasDealingCode: boolean;
  dealingCode?: string;
  failedReason?: {
    title: string;
    body: string[];
  };
  beneficiary: {
    id: number;
    transactionId: number;
    createdAt: string;
    updatedAt: string;
    country: string;
    segment: string;
    firstName: string;
    lastName: string;
    fullname: string;
    contactNumber: string | null;
    email: string | null;
    idNumber: string | null;
    idType: string | null;
    relationship: {
      label: string;
      value: string;
    };
    province: {
      label: string;
      value: string;
    };
    city: string | null;
    adrress: string | null;
    postalCode: string | null;
    purposeCode: {
      label: string | null;
      value: string | null;
    };
  };
  canRequestRefund: boolean;
  createdAt: string;
  paidAt: string;
  creditParty: {
    id: number;
    transactionId: number;
    createdAt: string;
    updatedAt: string;
    accountNumber: string;
    bankProvince: string | null;
    bankCity: string | null;
  };
  countryFlagCode: string;
  description: string;
  destinationAmount: number;
  destinationCountry: string;
  destinationCurrency: string;
  discount: number;
  expirationDate: string;
  extendedExpirationDate: string;
  extra: { airasiaPoints: number };
  fee: number;
  feeCurrencyIsoCode: string;
  fxRate: string;
  hasComplianceLimitExceeded: true;
  id: string;
  paid: boolean;
  payer: {
    id: number;
    name: string;
    currency: string;
    note: null;
    map: null;
  };
  payerId: number;
  payerName: string;
  paymentData: null;
  payments: paymentBank[];
  payoutId: null;
  payoutPin: null;
  quotationTotalToPay: string;
  recipientName: string;
  redflagStatus: RedflagStatus;
  redflagSuspect: RedflagSuspect;
  refId: string;
  refundRequest: null;
  reviewRate: null;
  reviewStatus: string;
  savedAmount: string;
  senderName: string;
  serviceId: number;
  serviceName: string;
  sourceAmount: number;
  sourceCountryIsoCode: string;
  sourceCurrencyIsoCode: string;
  statusShort: string;
  isWaitingBankPayment: boolean;
  status: string;
  totalToPay: string;
  totalPayment: string;
  transactionNote: {
    message: string;
    type: NotesStatus;
  };
  routingChannel: string;
  transactionStatus: TransactionStatus;
  type: string;
  uniqueCode: number;
  uniqueDiscount: number;
  voucher: null;
  makeBy: string;
  editedAt: string;
  releaseBy: string;
  recipientDetail: {
    infos: [
      {
        label: string;
        value: string;
      },
    ];
    quotation: [
      {
        label: string;
        value: string;
      },
    ];
    showMoreInfos: [
      {
        label: string;
        value: string;
      },
    ];
  };
  quotationId: number;
  hasBeenApproved: boolean;
  hasPayment: boolean;
}

export interface paymentBank {
  media: string;
  value: string;
}

export interface BatchTransactionModel {
  additionalFee: number;
  countryFlagCode: string;
  createdAt: string;
  expirationDate: string;
  extendedExpirationDate: string;
  expiredCount: number;
  failedCount: number;
  fee: number;
  fxRate: string;
  id: string;
  inProgressCount: number;
  inReviewCount: number;
  makeBy: string;
  needApprovalCount: number;
  needPaymentCount: number;
  isWaitingBankPayment: boolean;
  sentCount: number;
  status: BatchStatus;
  totalToPay: string;
  totalPayment: string;
  transactionsCount: number;
  sourceCurrencyIsoCode: string;
  destinationCurrency: string;
  destinationCountry: string;
  subtotal: string;
  statusShort: string;
  invoiceDownload: boolean;
  invoiceType: InvoiceType;
  description: string;
  canceledCount: number;
  uniqueCode: number;
}

export interface PostRemitQuotationProps {
  amount: number;
  service: number;
  isSendAmount: boolean;
  currency: number;
  country: string;
}

export interface MakePaymentOTPParam {
  otp: string;
  useWallet: boolean;
  paymentProvider?: string;
}
export interface MakeBankPaymentParam {
  useWallet: boolean;
  paymentProvider?: string;
}

export interface GetRecipientParam {
  recipientId?: string | string[];
  destinationCurrency: string;
  destinationCountry: string;
  serviceId: string;
  showInvoice: boolean;
}

export interface PostQuotationModel {
  amount: number;
  service: number;
  isSendAmount: boolean;
  currency: string;
  country: string;
}

/**
 * option label could contain custom html.
 *
 * available custom html: `<re-kyc>...</re-kyc>`,
 * `<customer-support>...</customer-support>`,
 * `<continue>...</continue>` & `<close>...</close>`
 */
export interface QuotationSubmitConfirmation {
  title: string;
  body: string;
  additionalNote?: string;
  optionYesLabel?: string;
  optionNoLabel?: string;
}

export interface GeneratedDynamicFormModel {
  destinationCurrency: string;
  countryFlagCode: string;
  destinationAmount: number;
  serviceName: string;
  form: {
    notes: string[];
    sections: {
      title: string;
      fields: {
        disabled: boolean;
        label: string;
        name: string;
        note: string;
        value?: string;
        dialCode?: string;
        url?: string;
        isoCode?: string;
        fields: string[];
        defaultValue?: string;
        options: {
          label: string;
          value: string;
          checked: boolean;
          disabled: boolean;
        }[];
        required: boolean;
        type: string;
      }[];
    }[];
  };
}

export interface RemitQuotationParam {
  country: string;
  currency: string;
  service: string;
  routingChannel: RoutingChannelType;
  amount: number;
  isSendAmount: boolean;
  currentRate?: string;
}

export interface QuotationNoteModel {
  note: string;
  content: string[] | null;
}

export interface HolidayComparisonRateInfo {
  type: HolidayComparisonType;
  note: string;
  skipReminder: boolean;
}

export interface HolidayCorridor {
  title: string;
  description: string;
}

export interface MaintenanceCorridor extends HolidayCorridor {}

export interface AnnouncementCorridor {
  holiday: HolidayCorridor;
  maintenance: MaintenanceCorridor;
}

export interface RemitQuotationModel {
  fxRate: string;
  serviceId: number;
  serviceName: string;
  referenceId: string;
  serviceOrder: number;
  sourceAmount: number;
  expirationDate: string;
  showLimitWarning: null;
  countryFlagCode: string;
  hasDealingCode: boolean;
  limitType: SoftLimitType;
  correspondentFee?: number;
  destinationAmount: number;
  isServiceChanged: boolean;
  destinationCountry: string;
  destinationCurrency: string;
  routingChannelOrder: number;
  routingChannel: RoutingChannelType;
  quotationNote: QuotationNoteModel[];
  submitConfirmation?: QuotationSubmitConfirmation;
  holidayComparisonRateInfo?: HolidayComparisonRateInfo;
  isSendAmount?: boolean;
  countryAndCurrency?: string;
  multiplier: string | null;
  isAppliedDealingCode?: boolean;
}

export interface GetQuotationSearchParam {
  serviceId: number | string;
  destinationCountry: string;
  destinationCurrency: string;
  amount: number;
  isSendAmount: boolean;
  routingChannel: RoutingChannelType;
  useDealingCode?: boolean;
  currentRate?: string;
}

export interface DealRateQuotationModel {
  countryFlagCode?: string;
  referenceFxRate: string;
  destinationAmount: number;
  destinationCountry: string;
  destinationCurrency: string;
  expirationDate: string;
  fxRate: string;
  limitType: SoftLimitType;
  quotationNote: QuotationNoteModel[];
  referenceId: string;
  routingChannel: RoutingChannelType;
  serviceId: number;
  serviceName?: string;
  showLimitWarning: null;
  sourceAmount: number;
  correspondentFee: number;
}
export interface QuotationModel {
  countryId: number;
  countryName: string;
  destinationAmount: number;
  destinationCurrency: string;
  hasDealingCode: boolean;
  correspondentFee: number;
  expirationDate: string;
  fee: number;
  feeCurrencyIsoCode: string;
  feeNote: null;
  form: {
    notes: string[];
    sections: {
      title: string;
      fields: {
        disabled: boolean;
        label: string;
        name: string;
        note: string;
        businessNote?: string;
        value?: string;
        dialCode?: string;
        url?: string;
        isoCode?: string;
        fields: string[];
        defaultValue?: string;
        options: {
          label: string;
          value: string;
          checked: boolean;
          disabled: boolean;
        }[];
        required: boolean;
        type: string;
        showOptionalLabel?: string;
        limit?: number;
        recipientSegment: 'ALL' | 'BUSINESS' | 'PERSONAL';
        senderSegment: 'ALL' | 'BUSINESS' | 'PERSONAL';
      }[];
    }[];
  };
  destinationCountry: string;
  fxRate: string;
  note?: string;
  quotationNote: string;
  referenceId: string;
  serviceId: number;
  serviceName?: string;
  sourceAmount: number;
  sourceCountryIsoCode: string;
  sourceCurrencyIsoCode: string;
  totalToPay: string;
  countryFlagCode?: FlagCode;
  routingChannel: RoutingChannelType;
  submitConfirmation?: QuotationSubmitConfirmation;
  discountFee: {
    discountValue: number;
    validUntil: string;
  };
  isSendAmount: boolean;
  countryAndCurrency: string;
  mode: 'SOURCE_AMOUNT' | 'DESTINATION_AMOUNT';
  hasMultiplier: boolean;
}

export interface PayersModel {
  id: number;
  map: string;
  name: string;
  note: string;
  logo: string;
  currency: string;
  routingChannels: RoutingChannel[];
}

export interface AvailableServicesModel {
  serviceId: number;
  serviceName: string;
  description: string;
  serviceIcon: string;
}

export interface FAQModel {
  title: string;
  body: string;
}

export interface TrackTransactionModel {
  date: string;
  status: string;
  statusCode: string;
  statusDesc: string;
}

export interface GetApiParam {
  [key: string]: any;
}

export interface GetStatesParam {
  destinationCountry: string;
  destinationCurrency: string;
  serviceId: number;
}

export interface BatchSingleTransactionParam {
  country: string;
  description: string;
  externalId: string;
  file: any;
  templateType: string;
}

export interface ApproveBatchSingleTransactionParam {
  externalId?: string;
  additionalNote?: string;
  id: string;
}

export interface DontShowLimitReminderParam {
  type: SoftLimitType;
}

export enum RedflagStatus {
  PENDING = 'PENDING',
  SUSPECTED = 'SUSPECTED',
  WAITING_DOCUMENT = 'WAITING_DOCUMENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum RedflagSuspect {
  ABBV = 'ABBV',
  SENDER = 'SENDER',
  SENDER_ADDRESS = 'SENDER_ADDRESS',
  BENEFICIARY = 'BENEFICIARY',
  BOTH = 'BOTH',
  LIMIT = 'LIMIT',
  INVOICE = 'INVOICE',

  LIMIT_PARTNER = 'LIMIT_PARTNER',
  BENEFICIARY_PARTNER = 'BENEFICIARY_PARTNER',
  OTHERS_PARTNER = 'OTHERS_PARTNER',
  SENDER_PARTNER = 'SENDER_PARTNER',
  BOTH_PARTNER = 'BOTH_PARTNER',
}

export const filterDefaultInitValue = {
  filter: {
    createdAtAfter: '',
    createdAtBefore: '',
    search: undefined,
    status: 'all',
  },
  sort: '',
};

export interface UseGetBatchTransactionProps {
  id: string;
}

export interface TransactionParam {
  created_at_after: string;
  created_at_before: string;
  search: string;
  status: string;
  sort: string;
  batch_id?: string;
  countries?: any;
  type?: 'SINGLE' | 'BATCH';
}

export interface TopUpTransactionResponse {
  id: string;
  status: string;
  bank: string;
  accountNo: string;
  currency: string;
  accountName: string;
  uniqueCode: number;
  nettAmount: string;
  prefix: string;
  remark: string;
  expirationDate: string;
  createdAt: string;
  createdBy: string;
  invoiceDownload: boolean;
  invoiceType: string | null;
  isRedirect: boolean;
  amount: string;
  imageUrl: string;
  transactionableId: string;
  transactionableType: string;
}

export interface GetApiUrlResponse {
  id: number;
  currency?: string;
  map?: any;
  name?: string;
  note?: string;
  code?: string;
}

export interface QuotationFormValues {
  dealingCode?: string;
  serviceId?: number;
  amountSend: number;
  amountReceive: number;
  isSendAmount: boolean;
  countryAndCurrency: ICountryCurrencyValue;
  destinationCountry: string;
  destinationCurrency: string;
  routingChannel?: RoutingChannelType;
}

export interface ServiceDetailModel
  extends Omit<ServiceModel, 'routingChannels'> {
  routingChannel: RoutingChannelModel;
}

export interface PersonalQuotationFormValues extends QuotationFormValues {}

export interface SelectedServiceModel
  extends Omit<ServiceModel, 'routingChannels'> {
  routingChannel: RoutingChannelModel;
}

export interface CountriesCurrenciesModel {
  title: string;
  country: string;
  currencies: string[];
  flag: FlagCode;
  slug: string;
  searchText: string;
}

export enum PaymentStatus {
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
}
