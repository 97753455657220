import { SVGProps } from 'react';

export interface UsersProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

const Users = ({
  size = 24,
  fill = 'var(--neutral-500)',
  ...props
}: UsersProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_278_2989)">
      <path
        d="M8.25 12.8333C7.50832 12.8333 6.7833 12.6134 6.16661 12.2013C5.54993 11.7893 5.06928 11.2036 4.78545 10.5184C4.50162 9.83318 4.42736 9.07918 4.57206 8.35175C4.71675 7.62432 5.0739 6.95613 5.59835 6.43168C6.1228 5.90724 6.79098 5.55008 7.51841 5.40539C8.24584 5.2607 8.99984 5.33496 9.68506 5.61879C10.3703 5.90262 10.956 6.38326 11.368 6.99995C11.7801 7.61663 12 8.34166 12 9.08334C11.9989 10.0776 11.6035 11.0307 10.9004 11.7338C10.1974 12.4368 9.24422 12.8322 8.25 12.8333ZM13.6667 22H2.83333C2.61232 22 2.40036 21.9122 2.24408 21.7559C2.0878 21.5996 2 21.3877 2 21.1667V20.75C2 19.0924 2.65848 17.5027 3.83058 16.3306C5.00268 15.1585 6.5924 14.5 8.25 14.5C9.9076 14.5 11.4973 15.1585 12.6694 16.3306C13.8415 17.5027 14.5 19.0924 14.5 20.75V21.1667C14.5 21.3877 14.4122 21.5996 14.2559 21.7559C14.0996 21.9122 13.8877 22 13.6667 22ZM16.5833 9.5C15.8417 9.5 15.1166 9.28007 14.4999 8.86801C13.8833 8.45596 13.4026 7.87029 13.1188 7.18506C12.835 6.49984 12.7607 5.74584 12.9054 5.01841C13.0501 4.29098 13.4072 3.6228 13.9317 3.09835C14.4561 2.5739 15.1243 2.21675 15.8517 2.07206C16.5792 1.92736 17.3332 2.00162 18.0184 2.28545C18.7036 2.56928 19.2893 3.04993 19.7013 3.66661C20.1134 4.2833 20.3333 5.00832 20.3333 5.75C20.3322 6.74423 19.9368 7.69741 19.2338 8.40043C18.5307 9.10346 17.5776 9.4989 16.5833 9.5ZM15.3992 11.1842C14.623 11.2883 13.8767 11.5516 13.2071 11.9575C12.5374 12.3635 11.9588 12.9034 11.5075 13.5433C13.3749 14.3914 14.8422 15.9285 15.6025 17.8333H21.1667C21.3877 17.8333 21.5996 17.7455 21.7559 17.5893C21.9122 17.433 22 17.221 22 17V16.9683C21.9991 16.1377 21.8211 15.3168 21.4777 14.5604C21.1344 13.804 20.6336 13.1296 20.0088 12.5821C19.3841 12.0347 18.6498 11.6268 17.8549 11.3857C17.0599 11.1447 16.2227 11.0759 15.3992 11.1842Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_278_2989">
        <rect width={20} height={20} fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

export default Users;
