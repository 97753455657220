import { css, cx } from '@emotion/css';

import Flex from './Flex';

// use for loading spinner inside dynamic imported component
// since it is more lightweight

interface IContainerSpinner {
  className?: string;
  spinnerClassName?: string;
}

export default function ContainerSpinner({
  className,
  spinnerClassName,
}: IContainerSpinner) {
  return (
    <Flex
      align="center"
      justify="center"
      className={cx(styled.root, className)}
    >
      <div className={cx(styled.spinner, spinnerClassName)} />
    </Flex>
  );
}

const styled = {
  root: css`
    flex: 1;
    height: 100%;
    overflow-y: hidden;
    padding: 1.25rem;
  `,
  spinner: css`
    display: flex;
    border: 0.375rem solid var(--neutral-100);
    border-top: 0.375rem solid var(--blue-500);
    border-radius: 50%;
    width: 3.125rem;
    height: 3.125rem;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `,
};
