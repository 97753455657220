import AnalyticsPackage, { AnalyticsInstance } from 'analytics';

import TracksPersonal from './TracksPersonal';
import packageJSON from '../../../../package.json';

const config = {
  app: packageJSON.name,
  version: packageJSON.version, // not yet support by GTM
  plugins: [],
};

class AnalyticsPersonal {
  instance: AnalyticsInstance;
  tracksPersonal: TracksPersonal;

  constructor() {
    this.instance = AnalyticsPackage(config);
    this.tracksPersonal = new TracksPersonal(this.instance);
  }
}

const analyticsPersonal = new AnalyticsPersonal();

export { AnalyticsPersonal, analyticsPersonal };
