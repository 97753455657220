import { ChatUsDialogContent } from '@elements';
import { PrefillTextType } from '@elements/chat-cs/constant';
import { usePermission } from '@hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

/**
 * Custom hook to show chat us dialog for personal user only
 */
export default function useShowChatUsDialog(
  prefillType?: PrefillTextType,
  payload?: string,
) {
  const showDialog = useDialogStore((store) => store.show);
  const closeDialog = useDialogStore((store) => store.close);
  const { isPersonalAccount } = usePermission();

  function showChatDialog() {
    closeDialog();
    isPersonalAccount &&
      showDialog({
        body: (
          <ChatUsDialogContent prefillType={prefillType} payload={payload} />
        ),
      });
  }

  return {
    showChatDialog,
  };
}
