import { PageAccessRefundRequest } from '@topremit/shared-web/typings/refund-request.model';
import { create } from 'zustand';

interface RefundSourceState {
  source?: PageAccessRefundRequest;
  setSource: (source: PageAccessRefundRequest) => void;
  clearSource: () => void;
}

export const useRefundSourceStore = create<RefundSourceState>((set) => ({
  source: undefined,
  setSource: (source) => set({ source }),
  clearSource: () => set({ source: undefined }),
}));

// For external usage
export const refundSourceStore = useRefundSourceStore.getState;
export const setRefundSourceStore = (source: PageAccessRefundRequest) =>
  useRefundSourceStore.getState().setSource(source);
export const clearRefundSourceStore = () =>
  useRefundSourceStore.getState().clearSource();
