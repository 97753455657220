import { css } from '@emotion/css';
import { getNativeInset } from '@global-common/helper';
import { screenSize } from '@global-common/size';
import { Command } from '@global-elements-utils/command';
import {
  Popover,
  PopoverContent,
  PopoverContentProps,
  PopoverTrigger,
} from '@global-elements-utils/popover';
import { useMediaQuery } from '@global-hooks';
import { useDialogStore } from '@global-stores';
import { PopoverPortal } from '@radix-ui/react-popover';
import * as React from 'react';
import { Control, FieldPath, FieldValues, Controller } from 'react-hook-form';

import { SelectButton } from './select-button';
import { SelectEmpty } from './select-empty';
import { SelectList } from './select-list';
import { SelectProvider, State } from './select-provider';

export interface SelectProps<TFieldValues extends FieldValues>
  extends SelectInternalProps {
  name: FieldPath<TFieldValues>;
  control?: Control<TFieldValues>;
}

/**
 * Select
 *
 * @description
 * `Select` component is a dropdown component that allows users to select one option from a list of options.
 *
 * When user device is mobile, the select will be shown as a bottom sheet dialog,
 * otherwise it will be shown as a popover.
 *
 * `Select` component can be used in two ways:
 * 1. Controlled: The value of the select element is controlled by the parent component.
 * 2. Uncontrolled: The value of the select element is controlled by the Select component itself.
 *
 * `Select` component children should have `SelectList` component as one of its children
 *
 * `Select` component has a `SelectSearch` component that can be used to search the select items,
 * the search input will filter the select items based on the `value` and `keywords` props of the select items.
 *
 * `SelectEmpty` component that can be used to show empty state of the select items.
 *
 * @param children - The children of the Select component should be a SelectList component.
 * @param name - The name of the select element.
 * @param value - The value of the select element.
 * @param label - The label of the select element.
 * @param mobileLabel - The bottom sheet mobile label of the select element.
 * @param defaultValue - The default value of the select element.
 * @param className - The class name of the select element.
 * @param error - The error message of the select element.
 * @param helperText - The helper text of the select element.
 * @param disabled - The disabled state of the select element.
 * @param fullWidth - The full width state of the select element.
 * @param renderValue - The render value of the select element to custom selected value.
 * @param onChange - The change event of the select element.
 * @param container - container for portal to render, default: document.body.
 * @param props - Props for other component.
 * @param styles - styles for select input component.
 *
 * @example
 *  <Select name="theme" fullWidth label="Select theme">
      <SelectSearch placeholder="Search theme..." />
      <SelectList>
        <SelectItem value="light" keywords={['terang', 'putih']}>
          <Flex align="center" gap={4}>
            <LayoutDashboardIcon /> Light
          </Flex>
        </SelectItem>
        <SelectItem value="dark" keywords={['gelap', 'hitam']}>
          Dark
        </SelectItem>
        <SelectItem value="system" keywords={['sistem', 'otomatis']}>
          System
        </SelectItem>
      </SelectList>
    </Select>
 */
export function Select<TFieldValues extends FieldValues>({
  children,
  name,
  control,
  onChange,
  id,
  ...props
}: SelectProps<TFieldValues>) {
  if (control) {
    return (
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange: defaultOnChange, ...field },
          fieldState,
        }) => (
          <SelectInternal
            id={id}
            onChange={(value) => {
              defaultOnChange(value);
              if (onChange) {
                onChange(value);
              }
            }}
            error={fieldState.error?.message}
            {...props}
            {...field}
          >
            {children}
          </SelectInternal>
        )}
      />
    );
  }
  return (
    <SelectInternal id={id} name={name} onChange={onChange} {...props}>
      {children}
    </SelectInternal>
  );
}

export interface SelectInternalStyle {
  button?: string;
  inputContainer?: string;
  rightIconContainer?: string;
}
interface SelectInternalProps {
  children: React.ReactNode;
  name?: string;
  value?: string;
  open?: boolean;
  label?: string;
  mobileLabel?: string;
  defaultValue?: string;
  error?: string;
  helperText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  onChange?: (newValue: string) => void;
  onOpenChange?: (open: boolean) => void;
  renderValue?: (value: string) => React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  container?: HTMLElement;
  id?: string;

  props?: {
    content?: PopoverContentProps;
    command?: React.ComponentPropsWithoutRef<typeof Command>;
  };

  styles?: SelectInternalStyle;
}

/**
 * SelectInternal
 *
 * @description Internal core implementation of the Select component.
 */
const SelectInternal = React.forwardRef<HTMLButtonElement, SelectInternalProps>(
  function SelectInternal(
    {
      children,
      name,
      value,
      label,
      mobileLabel,
      defaultValue,
      error,
      open,
      helperText,
      disabled = false,
      fullWidth = false,
      onOpenChange,
      renderValue,
      onChange,
      onClick,
      style,
      className,
      container,
      props: otherProps,
      styles,
      id,
    },
    ref,
  ) {
    const [internalOpen, setInternalOpen] = React.useState(false);
    const [internalValue, setInternalValue] = React.useState(
      defaultValue ?? '',
    );
    const isMobile = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);
    const pushDialog = useDialogStore((state) => state.push);
    const popDialog = useDialogStore((state) => state.pop);

    const isOpenControlled = typeof open != 'undefined';
    const isOpen = isOpenControlled ? open : internalOpen;

    const isValueControlled = typeof value != 'undefined';
    const currentValue = isValueControlled ? value! : internalValue;

    const items = React.Children.toArray(children).flatMap((child) =>
      React.isValidElement(child) && child.type === SelectList
        ? React.Children.toArray(child.props.children)
        : [],
    );

    const hasEmptyState = React.Children.toArray(children).some((child) => {
      if (React.isValidElement(child) && child.type === SelectEmpty) {
        return true;
      }
      return false;
    });

    function handleOpenChange(open: boolean) {
      if (onOpenChange) {
        onOpenChange(open);
      }

      if (!isOpenControlled) {
        setInternalOpen(open);
      }
    }

    function handleSelect(value: string) {
      if (onChange) {
        onChange(value);
      }

      if (!isValueControlled) {
        setInternalValue(value);
      }
    }

    function handleClose() {
      handleOpenChange(false);
      if (isMobile) {
        popDialog();
      }
    }

    function handleFilter(value: string, search: string, keywords?: string[]) {
      const extendValue = value + ' ' + keywords?.join(' ');
      if (extendValue.toLowerCase().includes(search.toLowerCase())) {
        return 1;
      }
      return 0;
    }

    let state: State = 'default';
    if (isOpen) state = 'active';
    if (error) state = 'error';
    if (disabled) state = 'disabled';

    if (isMobile) {
      return (
        <SelectProvider.Provider
          value={{
            state,
            isOpen,
            fullWidth,
            name,
            label,
            error,
            helperText,
            renderValue,
            value: currentValue,
            onClose: handleClose,
            onChange: handleSelect,
            items,
          }}
        >
          <SelectButton
            id={id}
            style={style}
            styles={styles}
            className={className}
            ref={ref}
            onClick={() => {
              if (onClick) {
                onClick();
                return;
              }
              if (!isMobile || disabled) return;
              pushDialog({
                fullHeight: true,
                className: styled.dialog,
                body: (
                  <SelectProvider.Provider
                    value={{
                      state,
                      isOpen,
                      fullWidth,
                      name,
                      label,
                      error,
                      helperText,
                      renderValue,
                      value: currentValue,
                      onClose: handleClose,
                      onChange: handleSelect,
                      items,
                    }}
                  >
                    <Command
                      shouldFilter
                      loop
                      filter={handleFilter}
                      {...otherProps?.command}
                    >
                      <h5 className={styled.dialogTitle}>
                        {mobileLabel || label}
                      </h5>
                      {children}
                      {!hasEmptyState && <SelectEmpty />}
                    </Command>
                  </SelectProvider.Provider>
                ),
              });
            }}
          />
        </SelectProvider.Provider>
      );
    }

    return (
      <SelectProvider.Provider
        value={{
          state,
          isOpen,
          fullWidth,
          name,
          label,
          error,
          helperText,
          renderValue,
          value: currentValue,
          onClose: handleClose,
          onChange: handleSelect,
          items,
        }}
      >
        <Popover
          open={disabled || !!onClick ? false : isOpen}
          onOpenChange={!disabled ? handleOpenChange : undefined}
        >
          <PopoverTrigger asChild>
            <SelectButton
              style={style}
              styles={styles}
              className={className}
              ref={ref}
              onClick={onClick}
            />
          </PopoverTrigger>
          <PopoverPortal container={container}>
            <PopoverContent
              align="start"
              sideOffset={8}
              {...otherProps?.content}
            >
              <Command
                shouldFilter
                loop
                filter={handleFilter}
                {...otherProps?.command}
              >
                {children}
                {!hasEmptyState && <SelectEmpty />}
              </Command>
            </PopoverContent>
          </PopoverPortal>
        </Popover>
      </SelectProvider.Provider>
    );
  },
);

const styled = {
  dialog: css`
    padding-bottom: ${getNativeInset().bottom}px;
    .modal-body {
      padding: 0;
    }
  `,
  dialogTitle: css`
    margin: 0 1.5rem 0.5rem;
  `,
};
