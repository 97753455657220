import { css, cx } from '@emotion/css';
import { Skeleton } from 'antd';
import type { SkeletonProps } from 'antd';
import type { SkeletonButtonProps } from 'antd/es/skeleton/Button';
import type { SkeletonInputProps } from 'antd/es/skeleton/Input';
import type { SkeletonParagraphProps } from 'antd/es/skeleton/Paragraph';
import { FC, ReactNode, memo } from 'react';

export enum SkeletonType {
  AVATAR = 'avatar',
  PARAGRAPH = 'paragraph',
  BUTTON = 'button',
  INPUT = 'input',
}

export interface ISkeletonWrapperProps
  extends SkeletonProps,
    SkeletonButtonProps,
    SkeletonInputProps,
    SkeletonParagraphProps {
  count?: number;
  type?: SkeletonType;
  children?: ReactNode;
  wrapperClassname?: string;
}

const SkeletonWrapper: FC<ISkeletonWrapperProps> = (props) => {
  const {
    children,
    loading = true,
    wrapperClassname,
    className,
    count = 1,
    type = 'input',
    active = true,
    shape,
    size,
    block,
    ...restProps
  } = props;

  const skeletonClass = cx(styled.defaultClass, className);

  const skeletons = Array.from({ length: count }).map((_, idx) =>
    renderSkeletons(idx),
  );

  function renderSkeletons(idx: number) {
    switch (type) {
      case SkeletonType.AVATAR:
        const shapeModified =
          shape === 'default' || shape === 'round' ? 'circle' : shape;
        return (
          <Skeleton.Avatar
            {...restProps}
            key={idx}
            className={skeletonClass}
            shape={shapeModified}
            size={size}
            active={active}
          />
        );
      case SkeletonType.PARAGRAPH:
        return (
          <Skeleton
            {...restProps}
            key={idx}
            className={skeletonClass}
            active={active}
          />
        );
      case SkeletonType.BUTTON:
        return (
          <Skeleton.Button
            {...restProps}
            key={idx}
            className={skeletonClass}
            size={size}
            active={active}
            shape={shape}
            block={block}
          />
        );
      case SkeletonType.INPUT:
      default:
        return (
          <Skeleton.Input
            {...restProps}
            key={idx}
            className={skeletonClass}
            size={size}
            active={active}
          />
        );
    }
  }

  if (loading) {
    return (
      <div className={cx(styled.skeletonWrapper, wrapperClassname)}>
        {skeletons}
      </div>
    );
  }

  if (children) {
    return <>{children}</>;
  }

  return null;
};

const styled = {
  defaultClass: css`
    border-radius: 1.25rem;
    width: auto;
    height: auto;
  `,
  skeletonWrapper: css`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
};

export default memo(SkeletonWrapper);
