import {
  AnimationControls,
  MotionProps,
  TargetAndTransition,
  VariantLabels,
  m,
  LazyMotion,
} from 'framer-motion';
import { CSSProperties, ReactNode, Ref, forwardRef } from 'react';

import { HTMLElements } from './types';
import { mergeRefs } from '../../../common/helper';

const loadFeatureDomMax = () => import('./features').then((res) => res.domMax);
const loadFeatureDomAnimation = () =>
  import('./features').then((res) => res.domAnimation);

export interface IAnimatedWrapperProps<Element extends HTMLElements>
  extends MotionProps {
  inherit?: boolean;
  initial?: boolean | VariantLabels | any;
  animate?: boolean | VariantLabels | AnimationControls | TargetAndTransition;
  exit?: VariantLabels | TargetAndTransition | undefined;
  children?: ReactNode;
  className?: string;
  onClick?: (params: any) => any;
  onBlur?: (params: any) => any;
  onFocus?: (params: any) => any;
  variants?: { [key: string]: any };
  style?: CSSProperties;
  htmlTag: Element;
  innerRef?: JSX.IntrinsicElements[Element]['ref'];
  onMouseDown?: (params: any) => any;
  id?: string;
  role?: any; //AriaRole is missing in react 18,
}

const defaultVariants = {
  fadeIn: {
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  'h-0': {
    height: 0,
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  'h-auto': {
    height: 'auto',
    opacity: 1,
    transition: {
      duration: 0.2,
    },
  },
};

const defaultTransition = {
  duration: 0.2,
};

const BaseAnimatedWrapper = <T extends HTMLElements>(
  props: IAnimatedWrapperProps<T>,
  ref: Ref<T>,
) => {
  const {
    variants = defaultVariants,
    className,
    onClick,
    children,
    initial,
    animate,
    inherit,
    exit,
    style,
    htmlTag,
    innerRef,
    id,
    custom,
    transition,
    drag,
    layout,
    ...restProps
  } = props;

  const Motion = m[htmlTag as HTMLElements];

  // for reduce bundle size , LazyMotion can import specific features.
  // documentation : https://www.framer.com/motion/guide-reduce-bundle-size/
  const hasLayoutAnimation = typeof layout !== 'undefined';
  const hasDragAnimation = typeof drag !== 'undefined';

  const features =
    hasDragAnimation || hasLayoutAnimation
      ? loadFeatureDomMax
      : loadFeatureDomAnimation;

  function addDefaultTransition() {
    if (!variants['transition']) {
      const obj: { [key: string]: any } | any = {};
      Object.keys(variants).forEach((variant) => {
        if (typeof variants[variant] === 'function') {
          const objVariants = variants[variant](custom);
          if (!objVariants['transition']) {
            objVariants['transition'] = defaultTransition;
            obj[variant] = objVariants;
          } else {
            obj[variant] = variants[variant];
          }
        } else {
          if (!variants[variant]['transition']) {
            variants[variant]['transition'] = defaultTransition;
          }
          obj[variant] = variants[variant];
        }
      });
      return obj;
    } else {
      return variants;
    }
  }

  return (
    <LazyMotion features={features}>
      <Motion
        // @ts-expect-error
        ref={mergeRefs(ref, innerRef)}
        id={id}
        style={style}
        inherit={inherit}
        initial={initial}
        animate={animate}
        exit={exit}
        variants={transition ? variants : addDefaultTransition()}
        onClick={onClick}
        className={className}
        transition={transition}
        custom={custom}
        layout={layout}
        drag={drag}
        {...restProps}
      >
        {children}
      </Motion>
    </LazyMotion>
  );
};

/**
 * @deprecated use `motion.div` instead
 */
const AnimatedWrapper = forwardRef(BaseAnimatedWrapper);

export { AnimatedWrapper, defaultVariants };
