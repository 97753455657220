import { cx } from '@emotion/css';
import { ReactNode } from 'react';

import { callAllFunctions } from '../../../common/helper';
import { useDrawer, useNavbarDrawer } from '../../../hooks';
import Flex from '../Flex';

interface IDrawerItemProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export default function DrawerItem({
  className,
  children,
  onClick,
}: IDrawerItemProps) {
  const { onClose: closeDrawer } = useDrawer();
  const { close: closeNavDrawer } = useNavbarDrawer();
  return (
    <Flex
      className={cx('dropdown-item-wrapper', className)}
      onClick={callAllFunctions(closeDrawer, closeNavDrawer, onClick)}
    >
      {children}
    </Flex>
  );
}
