import { ClassNamesArg, css, cx } from '@emotion/css';
import { Flex } from '@global-elements';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode; // <HStepperItem />

  className?: ClassNamesArg;
}

/**
 * @example 1
 * <HStepper>
 *  <HStepperItem>
 *    Title
 *  </HStepperItem>
 * </HStepper>
 * 
 * @example 2
 * <HStepper>
    <HStepperItem showDivider color="blue" icon={<DocumentPlusFill fill={color.blue500} />}>
      Title
    </HStepperItem>
  </HStepper>
 */

export default function HStepper({ children, className }: Props) {
  return <Flex className={cx(styled.root, className)}>{children}</Flex>;
}

const styled = {
  root: css`
    > :last-child {
      width: fit-content;
    }
  `,
};
