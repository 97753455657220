import { css } from '@emotion/react';

export default css`
  .ant-steps {
    color: var(--text-primary);
    .ant-steps-item-process
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot,
    &.ant-steps-dot.ant-steps-small {
      .ant-steps-item-icon .ant-steps-icon-dot {
        width: 1rem;
        height: 1rem;
        background: var(--blue-500);
        .ant-upload {
          background: var(--blue-500);
        }
      }
      .ant-steps-item-process .ant-steps-item-icon {
        width: 1rem;
        height: 1rem;
        margin-inline-start: unset;
      }
    }
    &.ant-steps-small .ant-steps-item-title {
      font-size: 1rem;
    }
    .ant-steps-item-wait
      .ant-steps-item-icon
      > .ant-steps-icon
      .ant-steps-icon-dot {
      background: rgba(0, 0, 0, 0.25);
    }
    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title {
      color: var(--text-primary);
      font-size: 1rem;
    }
    .ant-steps-item-finish > .ant-steps-item-container {
      > .ant-steps-item-tail::after {
        background: var(--blue-500);
      }
      > .ant-steps-item-content > .ant-steps-item-title {
        color: var(--text-primary);
      }
    }
  }
`;
