import { useCallback, useEffect, useRef } from 'react';

export default function useTimeout() {
  const timeout = useRef<number | undefined>(undefined);

  const _setTimeout = useCallback((fn, ms: number) => {
    timeout.current = setTimeout(fn, ms) as unknown as number;
  }, []);

  const _clearTimeout = useCallback(() => clearTimeout(timeout.current), []);

  useEffect(() => {
    return function cleanup() {
      if (timeout) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return {
    setTimeout: _setTimeout,
    clearTimeout: _clearTimeout,
  };
}
