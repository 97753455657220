// ARIA Pattern for Breadcrumb: https://www.w3.org/WAI/ARIA/apg/patterns/breadcrumb

import { css } from '@emotion/css';
import * as React from 'react';

import { color } from '../../styles/color';

interface BreadcrumbProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  // custom Breadcrumb separator
  separator?: React.ReactNode;
}

export function Breadcrumb({ children, separator, ...props }: BreadcrumbProps) {
  const SEPARATOR = separator || <BreadcrumbSeparator />;

  return (
    <nav aria-label="Breadcrumb" {...props}>
      <ol className={styled.list}>
        {React.Children.map(children, (child, index) => {
          const isLastChild = index === React.Children.count(children) - 1;

          if (isLastChild) {
            // mark the last child as current active page in the breadcrumb
            child = React.cloneElement(child as React.ReactElement, {
              isCurrent: true,
            });
          }

          return (
            <React.Fragment key={index}>
              {child}
              {!isLastChild && (
                <li aria-hidden="true" className={styled.separator}>
                  {SEPARATOR}
                </li>
              )}
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
}

interface BreadcrumbItemProps extends React.HTMLAttributes<HTMLLIElement> {
  isCurrent?: boolean;
}

function BreadcrumbItem({
  children,
  isCurrent = false,
  ...props
}: BreadcrumbItemProps) {
  if (isCurrent) {
    children = React.cloneElement(children as React.ReactElement, {
      'aria-current': 'page',
      prefetch: false,
      onClick: (e: React.MouseEvent) => e.preventDefault(),
    });
  }

  return (
    <li className={styled.item} {...props}>
      {children}
    </li>
  );
}

interface BreadcrumbSeparatorProps extends React.SVGAttributes<SVGElement> {}

function BreadcrumbSeparator(props: BreadcrumbSeparatorProps) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.0833 17.0001C9.08335 16.7791 9.17118 16.5672 9.32747 16.4109L13.1491 12.5893C13.3012 12.4308 13.3861 12.2197 13.3861 12.0001C13.3861 11.7805 13.3012 11.5694 13.1491 11.4109L9.32747 7.58926C9.17567 7.43209 9.09167 7.22159 9.09357 7.00309C9.09547 6.7846 9.18311 6.57559 9.33762 6.42108C9.49213 6.26657 9.70114 6.17893 9.91963 6.17703C10.1381 6.17513 10.3486 6.25913 10.5058 6.41093L14.3275 10.2326C14.782 10.7089 15.0356 11.3421 15.0356 12.0005C15.0356 12.6589 14.782 13.2921 14.3275 13.7684L10.5058 17.5893C10.3893 17.7058 10.2408 17.7851 10.0792 17.8172C9.91753 17.8494 9.75001 17.8329 9.59776 17.7698C9.44551 17.7068 9.31537 17.6 9.2238 17.463C9.13223 17.326 9.08334 17.1649 9.0833 17.0001Z"
        fill={color.neutral500}
      />
    </svg>
  );
}

Breadcrumb.Item = BreadcrumbItem;

const styled = {
  list: css`
    display: flex;
    align-items: center;
    list-style: none;
    gap: 0.25rem;
    margin: 0;
    padding: 0;
  `,
  separator: css`
    display: flex;
    align-items: center;
  `,
  item: css`
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500;

    > [aria-current='page'] {
      cursor: default;
      color: ${color.neutral800};
    }

    > :not([aria-current='page']) {
      color: ${color.blue500};
    }
  `,
};
